import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Button, Pagination } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Link as PdfLink,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import converter from "number-to-words";
import { AuthContext } from "../../../context/auth-context";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const auth = useContext(AuthContext);
  const pdfDownloadLinkRef = useRef();
  const [download, setDownload] = useState(false);
  const [show, setShow] = useState(false);
  const [payment, setPayment] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  console.log(auth.user);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            `/user/get_my_payments?filter=${searchTerm}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("auth_token"),
            },
          }
        );
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTerm]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Filter data for current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  //   const lastPostIndex = currentPage * postsPerPage;
  //   const firstPostIndex = lastPostIndex - postsPerPage;
  //   const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  //   const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "40px",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 16,
    },
    headerContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 16,
      textAlign: "center",
    },
    headerText: {
      color: "#FE52EB",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "center",
    },
    subheaderText: {
      fontSize: 12,
      marginTop: 8,
      textAlign: "center",
      padding: "0 20px",
    },
    links: { display: "flex", flexDirection: "row", gap: 10, marginTop: 8 },
    linkText: { textDecoration: "underline", color: "blue", fontSize: "12px" },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 8,
    },
    label: { width: "50%", fontWeight: "bold", fontSize: "14px" },
    value: { width: "50%", fontSize: "14px" },
    footer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      borderTopWidth: 1,
      borderTopColor: "black",
      marginTop: 16,
      paddingTop: 16,
      width: "100%",
    },
    footerText: {
      fontSize: 12,
      fontWeight: "700",
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
    },
    footerTopText: {
      fontSize: 14,
      fontWeight: "700",
      textAlign: "center",
      width: "100%",
      marginBottom: "20px",
    },
    headerImageLeft: {
      width: 50,
      height: 50,
      marginRight: 10,
      float: "left",
    },
    headerImageRight: {
      width: 50,
      height: 50,
      marginLeft: 10,
      float: "right",
    },
    tableHeader: {
      textAlign: "center",
    },
  });

  const MyDoc = ({ payment }) => (
    <Document>
      <Page>
        <View style={styles.container}>
          <View style={styles.header}>
            <Image src="/qr.png" alt="Left" style={styles.headerImageLeft} />
            <View style={styles.headerContent}>
              <Text style={styles.headerText}>Amar Somadha Limited</Text>
              <Text style={styles.subheaderText}>
                Incorporation No: C-159542, Trade License No:
                TRAD/DNCC/009368/2022
              </Text>
              <View style={styles.links}>
                <Text style={styles.linkText}>
                  <PdfLink src="mailto:Somadha247@gmail.com">
                    Somadha247@gmail.com
                  </PdfLink>
                </Text>
                <Text style={styles.linkText}>
                  <PdfLink src="http://www.fb.com/somadhafb" target="_blank">
                    www.fb.com/somadha
                  </PdfLink>
                </Text>
              </View>
            </View>
            <Image
              src="/invoice.png"
              alt="Right"
              style={styles.headerImageRight}
            />
          </View>
          <View style={styles.content}>
            <View style={styles.row}>
              <Text style={styles.label}>Date</Text>
              <Text style={styles.value}>
                :{" "}
                {payment?.createdAt
                  ? new Date(payment.createdAt).toLocaleDateString()
                  : "date"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Transac. No</Text>
              <Text style={styles.value}>
                : {payment?.transaction_id || "id"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Name</Text>
              <Text style={styles.value}>
                : {auth.user.fullname || "sakib"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Email</Text>
              <Text style={styles.value}>
                : {auth?.user.email || "sakibchow202695@gmail.co"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Job Title</Text>
              <Text style={styles.value}>
                : {payment?.job_id.title || "Engineer"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Job Category</Text>
              <Text style={styles.value}>
                : {payment?.job_id.category?.join(", ") || "Engineer"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Paid (BDT)</Text>
              <Text style={styles.value}>: {payment?.amount || "0"}</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.footerTopText}>
              {`Somadha authority confirms that the client has paid BDT ${converter
                .toWords(payment?.amount || 0)
                .toUpperCase()} only for the job titled above.`}
            </Text>
            <Text style={styles.footerText}>
              This electronically generated payment receipt does not require any
              signature.
            </Text>
            <Text style={styles.footerText}>Copyright: © somadha2024.</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div style={{ padding: "20px" }}>
      <h2 className="" style={{ marginBottom: "20px" }}>
        Payments Table
      </h2>
      <input
        type="text"
        placeholder="Search job title"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: "20px" }}
      />
      <table border="1" className="adminTableView__table">
        <thead>
          <tr>
            <th style={styles.tableHeader}>Date</th>
            <th style={styles.tableHeader}>Job Title</th>
            <th style={styles.tableHeader}>Deal</th>
            <th style={styles.tableHeader}>Type</th>
            <th style={styles.tableHeader}>Paid Amount</th>
            <th style={styles.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((payment, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "white " : "#eeeeee",
                textAlign: "center",
              }}
            >
              <td style={{ textAlign: "center" }}>
                {new Date(payment.createdAt).toLocaleDateString()}
              </td>
              <td style={{ textAlign: "center" }}>
                <a
                  style={{
                    textDecoration: "underline",
                  }}
                  className="table-link"
                  href={`/job-details/${payment?.job_id._id}`}
                >
                  {payment?.job_id.title}
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                <a
                  style={{
                    textDecoration: "underline",
                  }}
                  className="table-link"
                  href={`/servicedeal-detail/${payment?.deal_id?._id}`}
                >
                  View Deal
                </a>
              </td>
              <td style={{ textAlign: "center" }}>{payment?.type}</td>
              <td style={{ textAlign: "center" }}>{payment?.amount}</td>
              <td style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    setShow(true);
                    setPayment(payment);
                  }}
                >
                  Generate Invoice
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination>
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => paginate(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      <Modal show={show} onHide={handleClose}>
        <Modal.Title style={{ margin: "10px 0 -10px 15px" }}>
          Payment Invoice
        </Modal.Title>
        <Modal.Body>Invoice ready for download.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleClose} style={{ color: "white" }}>
            <PDFDownloadLink
              document={<MyDoc payment={payment} />}
              fileName={`invoice_${payment?._id}.pdf`}
              style={{ color: "white" }}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download"
              }
            </PDFDownloadLink>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TableComponent;
