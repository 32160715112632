import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  DELETE_DELIVERY_PROVIDER,
  DELETE_PAYMENT_PROVIDER,
  GET_DELIVERY_PROVIDERS,
  GET_GLOBAL_SETTINGS,
  GET_PAYMENT_PROVIDERS,
  UPDATE_GLOBAL_SETTINGS,
} from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import QComponent from "../../shared/QComponent/QComponent";
import Select from "../../shared/Select/Select";
import Editor from "../../Editor/Editor";
import ReactTooltip from "react-tooltip";
import "./GlobalSettingsView.css";
import { FaQuestionCircle } from "react-icons/fa";
import { BiEdit, BiTrash } from "react-icons/bi";
import { deleteConfirmation } from "../../../lib/sweetAlert";
import AddEditPaymentProviderModal from "../../modals/AddEditFaqModal/AddEditPaymentProviderModal";

export default function GlobalSettingsView() {
  const { mutateAsync: updateGlobalSettings } = useMutation(
    UPDATE_GLOBAL_SETTINGS
  );
  const { mutateAsync: deleteDeliveryProvider } = useMutation(
    DELETE_DELIVERY_PROVIDER
  );
  const { mutateAsync: deletePaymentProvider } = useMutation(
    DELETE_PAYMENT_PROVIDER
  );
  const {
    data: globalSettings,
    status,
    error,
  } = useQuery("GET_GLOBAL_SETTINGS", GET_GLOBAL_SETTINGS, {
    refetchOnWindowFocus: false,
  });
  const { data: deliveryProviders, refetch: refetchDeliveryProviders } =
    useQuery("GET_DELIVERY_PROVIDERS", GET_DELIVERY_PROVIDERS, {
      refetchOnWindowFocus: false,
    });
  const { data: paymentProviders, refetch: refetchPaymentProviders } = useQuery(
    "GET_PAYMENT_PROVIDERS",
    GET_PAYMENT_PROVIDERS,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { register, handleSubmit, reset, watch, getValues, setValue } = useForm(
    {
      defaultValues: useMemo(() => globalSettings?.data, [globalSettings]),
    }
  );
  const handleGlobalSettingsSave = useCallback(
    async (values) => {
      try {
        const {
          createdAt,
          state,
          updatedAt,
          __v,
          _id,
          initial_client_rating,
          initial_provider_rating,
          approval_time_limit,
          contact_emails,
          contact_phones,
          ...rest
        } = values;

        // Split the string inputs into arrays
        const emailArray = Array.isArray(contact_emails)
          ? contact_emails
          : contact_emails?.split(",").map((email) => email.trim());

        const phoneArray = Array.isArray(contact_phones)
          ? contact_phones
          : contact_phones?.split(",").map((phone) => phone.trim());

        // Combine the arrays with the rest of the values
        const dataToSend = {
          ...rest,
          contact_emails: emailArray,
          contact_phones: phoneArray,
        };
        await updateGlobalSettings(dataToSend);
        toast.success("Successfully updated global Settings!");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [updateGlobalSettings]
  );
  const handleDeliveryProviderDeletion = useCallback(
    async (_id) => {
      try {
        const { isConfirmed } = await deleteConfirmation();
        if (!isConfirmed) return;
        await deleteDeliveryProvider({ _id });
        await refetchDeliveryProviders();
        toast.success("Delivery providers deleted successfully");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [deleteDeliveryProvider, refetchDeliveryProviders]
  );
  const handlePaymentProviderDeletion = useCallback(
    async (_id) => {
      try {
        const { isConfirmed } = await deleteConfirmation();
        if (!isConfirmed) return;
        await deletePaymentProvider({ _id });
        await refetchPaymentProviders();
        toast.success("Payment providers deleted successfully");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [deletePaymentProvider, refetchPaymentProviders]
  );

  useEffect(() => {
    reset(globalSettings?.data);
  }, [globalSettings, reset]);

  return (
    <QComponent status={status} error={error}>
      <ReactTooltip />
      <div className="globalSettingsView">
        <h3 style={{ fontWeight: "bold" }}>Global Settings</h3>

        <form
          onSubmit={handleSubmit(handleGlobalSettingsSave)}
          className="globalSettingsView__settings"
        >
          {[
            { label: "Job Confirmation Prompt", name: "job_post_prompt" },
            { label: "Job Submit Prompt", name: "job_submit_prompt" },
            {
              label: "Bid Place Prompt",
              name: "bid_place_prompt",
              tooltip: "Prompt that will be shown before placing bid",
            },
            {
              label: "Accept Bid Prompt",
              name: "bid_accept_prompt",
              tooltip: "Prompt that will be shown before accepting bid",
            },
          ].map(({ label, name, tooltip }) => (
            <div key={name}>
              <h5
                style={{
                  color: "gray",
                  fontSize: "16px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                {label}
              </h5>
              <Editor
                defaultValue={getValues(name)}
                onChange={(_, data) => setValue(name, JSON.stringify(data))}
              />
            </div>
          ))}

          <div className="globalSettingsView__row">
            <Input
              tooltip="Initial Credit as a Client after account creation."
              label="Initial Client Credit (BDT)"
              type="number"
              {...register("initial_client_credit")}
            />
            <Input
              tooltip="Initial Credit as a Provider after account creation."
              label="Initial Provider Credit (BDT)"
              type="number"
              {...register("initial_provider_credit")}
            />
            <Input
              tooltip="Percentage of charge that will be paid by the provider before deal initiation."
              label="Advance Charge (%)"
              type="range"
              value={watch("advance_charge")}
              onChange={({ target }) =>
                setValue("advance_charge", target.value)
              }
            />
            <Input
              tooltip="How many times a client can get a low rating before a client is charged."
              label="Client Low Rating Count Limit"
              type="number"
              {...register("client_low_rating_count_limit")}
            />
            <Input
              tooltip="How many times a provider can get a low rating before a provider is charged."
              label="Provider Low Rating Count Limit"
              type="number"
              {...register("provider_low_rating_count_limit")}
            />
            <Input
              tooltip="Lower limit rating before a user is charged with a credit fine. "
              label="Low Rating Limit"
              type="number"
              {...register("low_rating_limit")}
            />
            <label className="d-block mt-3">
              <strong>
                Payment Method
                <span data-tip="By which method customer will pay">
                  <FaQuestionCircle />
                </span>
              </strong>
              <Select variant="ghost_white" {...register("payment_method")}>
                <option value="BKASH">BKASH</option>
                <option value="MANUAL">MANUAL</option>
              </Select>
            </label>
            <Input
              tooltip="After how many days referral will expire "
              label="Referral Time Limit"
              type="number"
              {...register("referral_time_limit")}
            />
            <Input
              tooltip="Amount of credit a user is fined for surpassing Client/Provider Low Rating Count limit."
              label="Low Rating Credit Fine (BDT)"
              type="number"
              {...register("low_rating_credit_fine")}
            />
            <Input
              tooltip="The percentage Somadha will keep from a client for every deal"
              label="Client Charge Per Deal (%)"
              type="range"
              value={watch("client_charge_per_deal")}
              onChange={({ target }) =>
                setValue("client_charge_per_deal", target.value)
              }
            />
            <Input
              tooltip="The percentage Somadha will keep from a provider for every deal"
              label="Provider Charge Per Deal (%)"
              type="range"
              value={watch("provider_charge_per_deal")}
              onChange={({ target }) =>
                setValue("provider_charge_per_deal", target.value)
              }
            />
            <Input
              tooltip="Minimum charge for clients on a printing job."
              label="Minimum Printing Charge for Client (BDT)"
              type="number"
              {...register("min_charge_print_client")}
            />
            <Input
              tooltip="Maximum charge for clients on a printing job."
              label="Maximum Printing Charge for Client (BDT)"
              type="number"
              {...register("max_charge_print_client")}
            />
            <Input
              tooltip="Minimum charge for providers on a printing job."
              label="Minimum Printing Charge for Provider (BDT)"
              type="number"
              {...register("min_charge_print_provider")}
            />
            <Input
              tooltip="Maximum charge for providers on a printing job."
              label="Maximum Printing Charge for Provider (BDT)"
              type="number"
              {...register("max_charge_print_provider")}
            />

            <Input
              tooltip="Client Credit Bonus Per Deal"
              label="Client Credit Bonus Per Deal"
              type="number"
              {...register("client_credit_bonus_per_deal")}
            />
            <Input
              tooltip="Provider Credit Bonus Per Deal"
              label="Provider Credit Bonus Per Deal"
              type="number"
              {...register("provider_credit_bonus_per_deal")}
            />
            <Input
              tooltip="Client Referral Bonus"
              label="Client Referral Bonus"
              type="number"
              {...register("client_referral_bonus")}
            />
            <Input
              tooltip="Provider Referral Bonus"
              label="Provider Referral Bonus"
              type="number"
              {...register("provider_referral_bonus")}
            />

            <label className="d-block mt-3">
              <strong>
                Payment System
                <span data-tip="Decide how frequent the somadha system will pay its users dues.">
                  <FaQuestionCircle />
                </span>
              </strong>
              <Select variant="ghost_white" {...register("pay_policy.system")}>
                <option value="">Select</option>
                {payment_system_options?.map((opt, ind) => (
                  <option key={ind} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            </label>
            <label className="d-block mt-3">
              <strong>
                Pay Day
                <span data-tip="Which day of the week will the users be paid">
                  <FaQuestionCircle />
                </span>
              </strong>
              <Select variant="ghost_white" {...register("pay_policy.pay_day")}>
                <option value="">Select</option>
                {pay_days_options?.map((opt, ind) => (
                  <option key={ind} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            </label>
            <Input
              tooltip="Email Address"
              label="Email Addresses (use comma to add multiple)"
              type="text"
              {...register("contact_emails")}
            />
            <Input
              tooltip="Contact Number"
              label="Contact Numbers (use comma to add multiple)"
              type="text"
              {...register("contact_phones")}
            />
            <Input
              tooltip="Office location"
              label="Office location"
              type="text"
              {...register("contact_address")}
            />
            <Input
              tooltip="Facebook Page"
              label="Facebook Page"
              type="text"
              {...register("fb_link")}
            />
            <Input
              tooltip="Youtube Channel"
              label="Youtube Channel"
              type="text"
              {...register("youtube_link")}
            />
            <Input
              tooltip="Linkedin Page"
              label="Linkedin Page"
              type="text"
              {...register("linkedin_link")}
            />
            <Input
              tooltip="Instagram Page"
              label="Instagram Page"
              type="text"
              {...register("instagram_link")}
            />
            <Input
              tooltip="Twitter Page"
              label="Twitter Page"
              type="text"
              {...register("twitter_link")}
            />
            <Input
              tooltip="Max Category On Homapge"
              label="Max Category On Homepage"
              type="number"
              {...register("max_category_on_homepage")}
            />
            <Input
              tooltip="Max file size (MB)"
              label="Max File Size (MB)"
              type="number"
              {...register("max_file_size")}
            />
            <Input
              tooltip="homepage top provider limit"
              label="Homepage Top Provider Limit"
              type="number"
              {...register("homepage_top_provider_limit")}
            />
            <label className="d-block mt-3">
              <strong>Notify All Users For New Job</strong>
              <Select
                variant="ghost_white"
                {...register("notifyAllUsersOnNewJob")}
              >
                <option value="">Select</option>
                {["On", "Off"].map((opt, ind) => (
                  <option key={ind} value={opt === "On" ? true : false}>
                    {opt}
                  </option>
                ))}
              </Select>
            </label>
            <label className="d-block mt-3">
              <strong>
                Notification Channel
                <span data-tip="Where the notifications will be sent">
                  <FaQuestionCircle />
                </span>
              </strong>
              <Select
                variant="ghost_white"
                {...register("allowed_notification_channel")}
              >
                <option value="">Select</option>
                {["SMS", "Email", "Both"].map((opt, ind) => (
                  <option key={ind} value={opt}>
                    {opt}
                  </option>
                ))}
              </Select>
            </label>
          </div>
          <br />
          <Button variant="primary" type="submit">
            SAVE SETTINGS
          </Button>
        </form>

        <h3
          className="d-flex justify-content-between align-items-center"
          style={{ fontWeight: "bold", marginTop: "2rem" }}
        >
          Payment Providers
          <AddEditPaymentProviderModal>
            <Button variant="primary_outlined" type="button">
              Add New
            </Button>
          </AddEditPaymentProviderModal>
        </h3>
        <div
          style={{
            marginTop: "1rem",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "1rem",
          }}
        >
          {paymentProviders?.data?.data?.map((item) => (
            <div
              key={item._id}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                border: "1px solid lightGray",
                padding: "1rem",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: ".5rem",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <AddEditPaymentProviderModal data={item}>
                  <BiEdit />
                </AddEditPaymentProviderModal>
                <BiTrash
                  onClick={() => handlePaymentProviderDeletion(item._id)}
                />
              </div>
              <img
                width={100}
                src={process.env.REACT_APP_BACKEND_URL + "/" + item.logo}
                alt=""
              />
              <p className="mt-2 mb-0">{item.name}</p>
              <p className="mt-2 mb-0">{item.number}</p>
            </div>
          ))}
        </div>
      </div>
    </QComponent>
  );
}

const payment_system_options = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Bi Weekly", value: "BI-WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

const pay_days_options = [
  { label: "Saturday", value: "0" },
  { label: "Sunday", value: "1" },
  { label: "Monday", value: "2" },
  { label: "Tuesday", value: "3" },
  { label: "Wednesday", value: "4" },
  { label: "Thursday", value: "5" },
  { label: "Friday", value: "6" },
];
