import React, { useContext, useState, useEffect } from 'react';
import './EditExpertise.css';
import Modal from '../../util/Modal';
import { useHttpClient } from '../../../hooks/http-hook';
import ErrorModal from '../../util/ErrorModal';
import LoadingSpinner from '../../util/LoadingSpinner';
import { AuthContext } from '../../../context/auth-context';

const Referal = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [referal, setReferal] = useState('');
  const [newReferal, setNewReferal] = useState('');
  const [copied, setCopied] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const generatePromoCode = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/generate_promo_code'
      );
      if (responseData.code) setReferal(responseData.code);
    } catch (err) {}
  };

  const usePromoCode = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/submit_promo_code',
        'PUT',
        JSON.stringify({ code: newReferal }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setShowSuccess(true);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (auth.user) {
      const length = auth.user.promo_code.length;
      if (length > 0) setReferal(auth.user.promo_code[length - 1]);
    }
  }, [auth.user]);
  return (
    <Modal
      contentOnly
      onCancel={() => {
        props.onCancel();
        setCopied(false);
        setShowSuccess(false);
      }}
      show={props.show}
    >
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='previewfiles__container'>
        <img
          className='exit_edit'
          onClick={() => {
            props.onCancel();
            setCopied(false);
            setShowSuccess(false);
          }}
          src='/assets/cross.svg'
          alt=''
        />
        <h2 className='submit_header'>Referal</h2>
        <div className='top__inpg'>
          <p>
            Your Referal Code{' '}
            <span
              className='generate__p'
              onClick={() => {
                generatePromoCode();
                setCopied(false);
              }}
            >
              Generate new code
            </span>
          </p>
          <div className='referal__input'>
            <input
              type='text'
              value={referal}
              onChange={(e) => {
                setReferal(e.target.value);
              }}
            />
            <div
              onClick={() => {
                navigator.clipboard.writeText(referal);
                setCopied(true);
              }}
              className={`copy__btn ${copied && 'copied'}`}
            >
              <img src='/assets/copy.svg' alt='' />
              <span>{copied ? 'Copied' : 'Copy Code'}</span>
            </div>
          </div>
          <p className='smol__p'>
            This code will work only for one friend.
            <br />
            Generate a new code for everyone you share the code for.
          </p>
        </div>
        <div>
          <p>Enter New Referal Code</p>
          {auth.user && auth.user.used_promo_code ? (
            <p className='smol__p'>you have entered a referal code already.</p>
          ) : (
            <div className='referal__input'>
              <input
                type='text'
                value={newReferal}
                onChange={(e) => {
                  setNewReferal(e.target.value);
                }}
              />
              <div onClick={usePromoCode} className='copy__btn'>
                <span>Apply Code</span>
              </div>
            </div>
          )}
          {showSuccess && (
            <p className='success__p'>
              You have applied your referal code successfully.
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Referal;
