import React from "react";
import "./ProviderCard.css";
import SkillItem from "../items/SkillItem";
const ProviderCard = (props) => {
  return (
    <div
      className="provider__card"
      onClick={() => {
        window.open(`/profile/${props.id}`).focus();
      }}
    >
      <div className="provider__card__header">
        <img src={props.profilePic} alt="" />
        <div className="header__inforight">
          <h3>{props.name}</h3>
          <p>{props.rating} Provider Rating</p>
          <p>{props.completed} Completed Deals</p>
        </div>
      </div>
      <div className="provider__card__footer">
        <span>Expertise: </span>
        {props.expertise.map((item) => {
          return <SkillItem text={item} />;
        })}
      </div>
    </div>
  );
};

export default ProviderCard;
