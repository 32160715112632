import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminLayout from '../../components/AdminLayout';
import LoadingSpinner from '../../components/util/LoadingSpinner';

export default function Admin() {
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setProcessing(false), 3000);

    return () => clearTimeout(timeout);
  }, []);


  if (processing) return <LoadingSpinner asOverlay />;
  return (
    <AdminLayout>
      <Redirect to="/admin/system-report" />
    </AdminLayout>
  )
};