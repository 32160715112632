import React from 'react';
import { Modal as BModal } from 'react-bootstrap';
import "./Modal.css";

export default function Modal({ open, title, onClose, children, ...rest }) {
  return (
    <BModal
      show={open} 
      onHide={onClose}
      {...rest}
    >
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <BModal.Body>{children}</BModal.Body>
    </BModal>
  )
}