/* eslint-disable no-unused-expressions */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-array-index-key */
import { useCallback, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import React from 'react';

export default function StarRatings({
  rating: propsRating,
  onChange,
  size,
  ...rest
}) {
  const [rating, setRating] = useState(propsRating || null);
  const [hoveredStar, setHoveredStar] = useState(null);
  const handleRatings = useCallback(
    ({ target }) => {
      setRating(target.value);
      onChange?.(target.value);
    },
    [onChange]
  );

  return (
    <div className='starRatings' {...rest}>
      {[...Array(5)].map((_, ind) => (
        <label key={ind}>
          <input
            hidden
            type='radio'
            name='rating'
            value={ind + 1}
            onChange={handleRatings}
          />
          <FaStar
            style={{ width: size, height: size}}
            color={ind + 1 <= (hoveredStar || rating) ? '#FFD700' : '#FFED9F'}
            onMouseEnter={() => setHoveredStar(ind + 1)}
            onMouseLeave={() => setHoveredStar(null)}
          />
        </label>
      ))}
    </div>
  );
}
