import React, { useEffect, useState } from "react";
import AuthSection from "../sections/authentication/AuthSection";
import axios from "axios";

const Signup = () => {
  return (
    <div
    >
      <AuthSection variant="signup" />
    </div>
  );
};

export default Signup;
