import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../../context/auth-context";
import ImageUploader from "../../../components/util/ImageUploader";
import { useHttpClient } from "../../../hooks/http-hook";
import "./Edit_Profile.css";
import { Alert, Button, Modal } from "react-bootstrap";
import SkillItem from "../../items/SkillItem";
import Card from "../../../components/util/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../../components/util/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  BUCKET_URL,
  CLOUDFRONT_URL,
  S3_PREASSIGNED_POST_URL,
} from "../../../config";

const mobileBankProviders = ["BKASH", "NAGAD", "ROCKET"];
const image = "https://via.placeholder.com/150/92c952";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const EditProfileN = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [nid, setNid] = useState("");
  const [mobile, setMobile] = useState("");
  const [expertises, setExpertises] = useState(null);
  const [achievements, setAchievements] = useState(null);
  const [degrees, setDegrees] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [showAddDegree, setShowAddDegree] = useState(false);
  const [mobileBank, setMobileBank] = useState(mobileBankProviders[0]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [files, setFiles] = useState([]);
  const [addEdu, setAddEdu] = useState(false);
  const handleEduClose = () => setAddEdu(false);
  const handleEduShow = () => setAddEdu(true);
  const [addAchievement, setAddAchievement] = useState(false);
  const handleAchievementClose = () => setAddAchievement(false);
  const handleAchievementShow = () => setAddAchievement(true);
  const [achTitle, setAchTitle] = useState("");
  const [achDesc, setAchDesc] = useState("");
  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [passing_year, setPassing_year] = useState("");
  const [addedExpertise, setAddedExpertise] = useState("");
  const [notificationDest, setNotificationDest] = useState("email");
  const fileInputRef = useRef();
  const [tagline, setTagline] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passModalShow, setPassModalShow] = useState(false);
  const [reTypePassword, setReTypePassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [location, setLocation] = useState("");
  const [filesUrl, setFilesUrl] = useState("");
  const [load, setLoad] = useState(false);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  const [confirmModal, setConfirmModal] = useState(false);
  useEffect(() => {
    passWordValidator();
  }, [reTypePassword]);

  const passWordValidator = () => {
    if (newPassword !== reTypePassword) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  };

  useEffect(() => {
    setUpdateUser();
  }, [auth.user]);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const setUpdateUser = () => {
    if (auth.user) {
      setName(auth.user.fullname);
      setDob(auth.user.date_of_birth);
      setProfilePic(auth.user.profile_pic);
      setExpertises(auth.user.expertise);
      setAchievements(auth.user.achievement);
      setDegrees(auth.user.degree);
      setNid(auth.user.nid);
      setMobile(auth.user.mobile_banking_phone_number);
      setMobileBank(auth.user.mobile_banking_provider);
      setFilesUrl(auth.user?.portfolio[0]);
      setTagline(auth.user?.tagline);
      setAboutMe(auth.user?.about_me);
      setLocation(auth.user?.location);
    }
  };

  const updateUser = async () => {
    setConfirmModal(false);
    setLoad(true);

    try {
      let dataToSend;
      newPassword.length > 0 && passwordValid
        ? (dataToSend = {
            fullname: name,
            date_of_birth: dob && dob.slice(0, 10),
            nid: nid && nid.toString(),
            new_password: newPassword,
            password: oldPassword,
          })
        : (dataToSend = {
            fullname: name,
            date_of_birth: dob && dob.slice(0, 10),
            nid: nid && nid.toString(),
            password: oldPassword,
            notification_destination: notificationDest,
          });
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/update_info",
        "POST",

        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );

      let res = await mobileBankingInfo();
      let response = await addExtraInfo();

      if (
        responseData.success === "yes" ||
        res.success === "yes" ||
        response.success === "yes"
      ) {
        auth.setUser();
        setOldPassword("");
        setNewPassword("");
        // setShowSuccess(true);
        setUpdateUser();
        setLoad(false);
        notify("success", "Profile updated successfully!");
      } else throw new Error("Something Went Wrong!");
    } catch (err) {
      setLoad(false);
      notify("err", err.message);
    }
  };
  const deleteAchievement = async (title, desc) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/delete_achievement",
        "PUT",
        JSON.stringify({
          name: title,
          description: desc,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        notify("success", "Achievement Deleted!");
      } else throw new Error("Something Went Wrong!");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const confirmUpdate = () => {
    setConfirmModal(true);
  };

  const saveAchievement = async (e) => {
    e.preventDefault();
    // const ach = { achTitle, achDesc };
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_achievement",
        "PUT",
        JSON.stringify({
          name: achTitle,
          description: achDesc,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        handleAchievementClose();
        notify("success", "Achievement Added! ");
      } else {
        notify("err", "Something Went Wrong!");
      }
      setAchTitle("");
      setAchDesc("");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const deleteDegree = async (school, degree, passing_year) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/delete_degree",
        "PUT",
        JSON.stringify({
          school: school,
          degree: degree,
          passing_year: passing_year,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        notify("success", "Degree Deleted!");
      } else {
        notify("err", "Something Went Wrong!");
      }
    } catch (err) {
      notify("err", err.message);
    }
  };
  const saveDegree = async (e) => {
    e.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_degree",
        "PUT",
        JSON.stringify({
          school: school,
          degree: degree,
          passing_year: passing_year,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        handleEduClose();
        notify("success", "Degree Added!");
      } else {
        notify("err", "Something Went Wrong!");
      }
      setSchool("");
      setDegree("");
      setPassing_year("");
    } catch (err) {
      notify("err", err.message);
    }
  };
  const addExpertise = async (e) => {
    e.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_expertise",
        "PUT",
        JSON.stringify({
          expertise: [addedExpertise],
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        auth.setUser();
        notify("success", "Skills Added!");
      } else {
        notify("err", "Something Went Wrong!");
      }
      setAddedExpertise("");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const mobileBankingInfo = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/update_mobile_banking_info",
        "PUT",
        JSON.stringify({
          mobile_banking_phone_number: mobile,
          mobile_banking_provider: mobileBank
            ? mobileBank
            : mobileBankProviders[0],
          password: oldPassword,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        return "yes";
      }
    } catch (err) {
      return err.message;
    }
  };

  const addExtraInfo = async () => {
    try {
      let dataToSend = {
        cover_photo: filesUrl,
        tagline: tagline,
        location: location,
        about_me: aboutMe,
        portfolio: [filesUrl],
      };
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_extra_infos",
        "POST",

        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.success === "yes") {
        return "yes";
      }
    } catch (err) {
      return err.message;
    }
  };

  useEffect(() => {
    fileUpload();
  }, [files]);

  const fileUpload = async () => {
    try {
      if (files.length > 0) {
        for (const file of files) {
          const data = await axios.get(
            `${S3_PREASSIGNED_POST_URL}?size=${
              globalSettings?.max_file_size
            }&fileExtension=${file.name.split(".").pop()}`
          );

          const formData = new FormData();
          Object.entries(data.data[0].fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", file);

          const uploadResponse = await axios.post(BUCKET_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          setFilesUrl([
            CLOUDFRONT_URL + `${data.data[0].fields.Key}`,
            ...filesUrl,
          ]);
        }
      }
    } catch (err) {
      toast.error(`Max file upload size is ${globalSettings?.max_file_size}MB`);
    }
  };

  return (
    <div className="container">
      {showSuccess && (
        <Alert
          variant="success"
          onClose={() => setShowSuccess(false)}
          dismissible
        >
          <Alert.Heading>Profile updated successfully</Alert.Heading>
        </Alert>
      )}
      {load && <LoadingSpinner asOverlay />}
      <h3 className="fw-bold mb-3">
        {" "}
        {auth.language === "en" ? "Edit Profile" : "বৃত্তান্ত সম্পাদনা"}
      </h3>
      <div className="form-section">
        {profilePic ? (
          <img className="profileimg" src={profilePic} alt="" />
        ) : (
          <img className="profileimg" src={image} alt="test_image" />
        )}

        <h4 className="my-3 fw-bold">
          {" "}
          {auth.language === "en" ? "Profile Picture" : "বৃত্তান্ত ছবি"}{" "}
        </h4>
        {/* <button type="button" class="btn btn-primary">
            Upload
          </button> */}
        <ImageUploader
          profile
          fieldName="image"
          url="/user/change_profile_picture"
          title={
            auth.language === "en" ? "Upload New Photo" : "নতুন ছবি আপলোড করুন"
          }
        />
      </div>

      <div className="form-section">
        <label htmlFor="fname">
          {" "}
          {auth.language === "en" ? "Display Name" : "প্রদর্শন নাম"}{" "}
        </label>
        <input
          autoComplete="off"
          type="text"
          name="name"
          placeholder={
            auth.language === "en"
              ? "Your name wil be displayed everywhere, Use full name."
              : "আপনার নাম সর্বত্র প্রদর্শিত হবে, পুরো নাম ব্যবহার করুন।"
          }
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="form-section">
        <label for="ftag">
          {" "}
          {auth.language === "en" ? "Tagline" : "ট্যাগলাইন"}{" "}
        </label>
        <input
          type="text"
          name="tag"
          placeholder={
            auth.language === "en"
              ? "Keep it short, recommended to be keywords."
              : "এটি সংক্ষিপ্ত রাখুন, কীওয়ার্ড হওয়ার পরামর্শ দেওয়া হচ্ছে।"
          }
          value={tagline}
          onChange={(e) => {
            setTagline(e.target.value);
          }}
        />
      </div>

      <div className="form-section">
        <label for="fdesc">
          {" "}
          {auth.language === "en" ? "About Me" : "আমার সম্পর্কে"}{" "}
        </label>
        <textarea
          placeholder={
            auth.language === "en"
              ? "A Short Description about you, this will be public, be creative!"
              : "আপনার সম্পর্কে একটি সংক্ষিপ্ত বিবরণ, এটি সর্বজনীন হবে, সৃজনশীল হবেন!"
          }
          rows="5"
          className="textareaa"
          value={aboutMe}
          onChange={(e) => {
            setAboutMe(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="form-section">
        <label for="bdate">
          {" "}
          {auth.language === "en" ? "Birthday" : "জন্মদিন"}
        </label>
        <input
          autoComplete="off"
          type="date"
          value={dob ? dob.slice(0, 10) : ""}
          onChange={(e) => {
            setDob(e.target.value);
          }}
          name="bdate"
        />
      </div>

      <div className="form-section">
        <label for="nid">
          {" "}
          {auth.language === "en"
            ? "NID or Birth Certificate No"
            : "এনআইডি বা জন্ম সনদ নং"}{" "}
        </label>
        <input
          autoComplete="off"
          type="number"
          name="nid"
          placeholder={
            auth.language === "en"
              ? "Be careful, do not make mistakes"
              : "সাবধান, ভুল করবেন না"
          }
          value={nid}
          onChange={(e) => setNid(e.target.value)}
        />
      </div>
      <div className="form-section">
        <label htmlFor="notifcation_dest">
          {auth.language === "en"
            ? "Notification Destination(email or phone)"
            : "বিজ্ঞপ্তির গন্তব্য (ইমেইল বা ফোন)"}
        </label>
        <div>
          <input
            type="text"
            id="notificationDest"
            name="notificationDest"
            value={notificationDest}
            onChange={(e) => setNotificationDest(e.target.value)}
            placeholder={
              auth.language === "en" ? "email or phone" : "ইমেইল বা ফোন"
            }
          />
        </div>
      </div>

      <div className="form-section">
        <label for="mobile">
          {" "}
          {auth.language === "en"
            ? "Mobile Banking Info"
            : "মোবাইল ব্যাংকিং তথ্য "}{" "}
        </label>
        <div className="d-flex acc-wrapper">
          <input
            className="acc-input"
            autoComplete="off"
            type="number"
            maxLength="14"
            name="mobile"
            placeholder={
              auth.language === "en"
                ? "Mobile Banking Number Here"
                : "মোবাইল ব্যাংকিং নম্বর এখানে"
            }
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <select
            id="selectt"
            value={mobileBank}
            onChange={(e) => {
              setMobileBank(e.target.value);
            }}
            className="acc-serach"
          >
            {mobileBankProviders.map((provider) => {
              return (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="form-section">
        <label for="loc">
          {" "}
          {auth.language === "en" ? "Location" : "অবস্থান"}{" "}
        </label>
        <input
          type="text"
          name="loc"
          placeholder={
            auth.language === "en"
              ? "Select your City/ Town/ Village."
              : "আপনার শহর/শহর/গ্রাম নির্বাচন করুন।"
          }
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
      </div>
      <div className="form-section">
        <label for="cv">
          {" "}
          {auth.language === "en"
            ? "Portfolio/CV Upload"
            : "পোর্টফোলিও/সিভি আপলোড            "}
        </label>
        <div className="inforight">
          <div className="image__uploader file__uploader">
            <div
              className="px-5 py-1"
              style={{
                backgroundColor: "var(--primary_light_color)",
                color: "var(--primary_color)",
                borderRadius: "4px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={(event) => {
                fileInputRef.current.click();
              }}
            >
              <span
                className="upload_txt"
                style={{ color: "var(--primary_color)" }}
              >
                {" "}
                {auth.language === "en" ? "Upload" : "আপলোড করুন"}
              </span>{" "}
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(event) => {
                setFiles(event.target.files[0]);
              }}
            />
          </div>

          {/* <FileUploader
            updatestate={(childFile) => {
              setFiles(childFile);
              
            }}
          /> */}
          {files?.name && (
            <div
              className="d-inline-flex justify-content-center align-items-center px-2 my-2"
              style={{ backgroundColor: "#EEEEEE", borderRadius: "4px" }}
            >
              <p className="d-inline-flex my-2 px-5 py-2 ">{files?.name}</p>
              <img
                onClick={() => {
                  setFiles([]);
                }}
                src="/assets/cross.svg"
                alt=""
                height="20px"
                width="20px"
                className="mx-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="form-section">
        <form onSubmit={addExpertise}>
          <label for="skill">
            {" "}
            {auth.language === "en" ? "Skills" : "দক্ষতা"}
          </label>
          <span className="d-flex justify-content-center align-items-center">
            <input
              type="text"
              name="skill"
              placeholder={
                auth.language === "en"
                  ? "Type you skill name"
                  : "আপনার দক্ষতার নাম টাইপ করুন"
              }
              value={addedExpertise}
              onChange={(e) => setAddedExpertise(e.target.value)}
              className="from-control"
            />

            <button
              type="submit"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                position: "relative",
                left: "-30px",
                color: "#2A41E8",
              }}
            >
              <FontAwesomeIcon icon={faPlus} />{" "}
            </button>
          </span>
        </form>
        <Card>
          {expertises &&
            expertises.map((skill, index) => {
              return <SkillItem key={index} delete text={skill} />;
            })}
        </Card>
      </div>

      <div className="form-section">
        <label for="achievement">
          {" "}
          {auth.language === "en" ? "Achievements" : "অর্জন"}
        </label>
        {achievements &&
          achievements.map((achievement, index) => {
            return (
              <Card key={index}>
                <div
                  className=" px-3 py-2 rounded"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <p
                      className=""
                      style={{ fontSize: "18px", textAlign: "start" }}
                    >
                      <strong>{achievement.name}</strong>
                    </p>
                    <img
                      src="/assets/cross.svg"
                      alt=""
                      onClick={() => {
                        deleteAchievement(
                          achievement.name,
                          achievement.description
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="m-0" style={{ fontSize: "18px" }}>
                    {achievement.description}
                  </p>
                </div>
              </Card>
            );
          })}
        <button
          type="button"
          className="btn fw-bold"
          style={{ color: "var(--primary_color)" }}
          onClick={handleAchievementShow}
        >
          {" "}
          {auth.language === "en" ? "+ Add New" : "+ নতুন যোগ করুন"}
        </button>
      </div>

      {/* achievement modal */}
      <Modal
        show={addAchievement}
        onHide={handleAchievementClose}
        backdrop="static"
        dialogClassName="modal-50w"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ color: "var(--primary_color)" }}>
              {auth.language === "en"
                ? "+ Add New Achievement  "
                : "+ নতুন অর্জন যোগ করুন"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={saveAchievement}>
            <div className="form-section">
              <label for="addAchv">
                {" "}
                {auth.language === "en" ? "Achievement Title" : "শিরোনাম"}
              </label>
              <input
                type="text"
                name="addAchv"
                value={achTitle}
                onChange={(e) => setAchTitle(e.target.value)}
                required
              />

              <p style={{ color: "var(--grey_two)" }}>
                Example: Won a contest, wrote a book, anything professional and
                you're proud of!
              </p>
            </div>

            <div className="form-section">
              <label for="addDesc">
                {" "}
                {auth.language === "en"
                  ? "Achievent Description"
                  : "অর্জনের বিবরণ"}
              </label>
              <textarea
                placeholder={
                  auth.language === "en"
                    ? "Describe what this is"
                    : "এটি কী তা বর্ণনা করুন"
                }
                rows="5"
                className="textareaa"
                value={achDesc}
                onChange={(e) => setAchDesc(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="d-flex justify-content-end align-items-center p-2">
              <Button
                type="submit"
                className="btn fw-bold"
                style={{
                  backgroundColor: "var(--primary_color)",
                  width: "15rem",
                }}
              >
                {auth.language === "en" ? "Save" : "সংরক্ষণ"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="form-section">
        <label for="education">
          {" "}
          {auth.language === "en" ? "Education" : "শিক্ষা"}
        </label>
        {degrees &&
          degrees.map((degree, index) => {
            return (
              <Card key={index}>
                <div
                  className=" px-3 py-2 rounded"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <p
                      className=""
                      style={{ fontSize: "18px", textAlign: "start" }}
                    >
                      <strong>{degree.school}</strong>
                    </p>
                    <img
                      src="/assets/cross.svg"
                      alt=""
                      onClick={() => {
                        deleteDegree(
                          degree.school,
                          degree.degree,
                          degree.passing_year
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="m-0" style={{ fontSize: "18px" }}>
                    {degree.degree + ", Passing " + degree.passing_year}
                  </p>
                </div>

                {/* <div className='acd_hold'>
                  <img
                    src='/assets/cross.svg'
                    alt=''
                    onClick={() => {
                      deleteDegree(
                        degree.school,
                        degree.degree,
                        degree.passing_year
                      );
                    }}
                  />
                  <p className='p_header'>
                    <strong>{degree.school}</strong>
                  </p>
                  <p className='p_description'>
                    {degree.degree + ', Passing Year: ' + degree.passing_year}
                  </p>
                </div> */}
              </Card>
            );
          })}
        <button
          type="button"
          className="btn btn-outline fw-bold"
          style={{ color: "var(--primary_color)" }}
          onClick={handleEduShow}
        >
          {" "}
          {auth.language === "en" ? "+ Add New" : "+ নতুন যোগ করুন"}
        </button>
      </div>

      {/* Edu modal */}
      <Modal
        show={addEdu}
        onHide={handleEduClose}
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ color: "var(--primary_color)" }}>
              {auth.language === "en"
                ? " Add New Degree"
                : "নতুন ডিগ্রী যোগ করুন"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-section">
              <label for="">
                {" "}
                {auth.language === "en" ? "Institute Name" : "প্রতিষ্ঠানের নাম"}
              </label>
              <input
                type="text"
                name=""
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                required
              />
            </div>
            <div className="form-section">
              <label for="">
                {auth.language === "en" ? "Degree Name " : "ডিগ্রির নাম"}
              </label>
              <input
                type="text"
                name=""
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                required
              />
            </div>
            <div className="form-section">
              <label for="">
                {" "}
                {auth.language === "en"
                  ? "Major/Field of Study"
                  : "অধ্যয়নের ক্ষেত্র"}
              </label>
              <input type="text" name="" />
            </div>
            <div className="form-section">
              <label for="">
                {auth.language === "en" ? "Passing Year" : "পাসিং বছর"}
              </label>
              <input
                type="text"
                name=""
                value={passing_year}
                onChange={(e) => setPassing_year(e.target.value)}
                required
              />
            </div>
            {/* <Button
              type='submit'
              class='btn'
              style={{ backgroundColor: '#2A41E8' }}
            >
              Save
            </Button> */}
            <div className="d-flex justify-content-end align-items-center p-2">
              <Button
                type="submit"
                className="btn fw-bold"
                style={{
                  backgroundColor: "var(--primary_color)",
                  width: "15rem",
                }}
                onClick={saveDegree}
              >
                {auth.language === "en" ? "Save" : "সংরক্ষণ"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-start align-items-center flex-wrap submit-btn">
        <div className="">
          <button
            className="btn fw-bold m-2"
            style={{
              backgroundColor: "var(--primary_color)",
              width: "15rem",
              color: "white",
            }}
            onClick={confirmUpdate}
          >
            {auth.language === "en"
              ? "Update My Profile"
              : "আমার বৃত্তান্ত আপডেট করুন"}
          </button>
        </div>

        <div className="">
          <button
            type="button"
            className="btn m-2 fw-bold"
            onClick={() => setPassModalShow(true)}
            style={{
              backgroundColor: "var(--primary_light_color)",
              width: "15rem",
              color: "var(--primary_color)",
            }}
          >
            {auth.language === "en"
              ? "Change Password"
              : "পাসওয়ার্ড পরিবর্তন করুন              "}
          </button>
        </div>
      </div>

      {/*pw modal */}
      <Modal
        show={passModalShow}
        onHide={() => setPassModalShow(false)}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ color: "var(--primary_color)" }}>
              {" "}
              {auth.language === "en"
                ? "Update Password"
                : "পাসওয়ার্ড আপডেট করুন"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-section">
            <label for="">
              {" "}
              {auth.language === "en"
                ? "Current Password"
                : "বর্তমান পাসওয়ার্ড"}
            </label>
            <input
              type="password"
              required
              name=""
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
          <div className="form-section">
            <label for="">
              {auth.language === "en" ? "New Password" : "নতুন পাসওয়ার্ড"}
            </label>
            <input
              type="password"
              name=""
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="form-section">
            <label for="">
              {" "}
              {auth.language === "en"
                ? "Re-type New Password"
                : "নতুন পাসওয়ার্ড পুনরায় টাইপ করুন"}
            </label>
            <input
              type="password"
              name=""
              onChange={(e) => {
                setReTypePassword(e.target.value);
              }}
            />
            {passwordValid === false && (
              <p className="text-danger">
                {" "}
                {auth.language === "en"
                  ? "incorrect new password!"
                  : "নতুন পাসওয়ার্ড ভুল!"}
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPassModalShow(false)}>
            Close
          </Button>
          <Button
            type="button"
            className="btn fw-bold"
            style={{ backgroundColor: "var(--primary_color)" }}
            onClick={() => setPassModalShow(false)}
          >
            {auth.language === "en" ? "Save Changes" : "পরিবর্তনগুলোর সংরক্ষন"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="form-section">
            <h4 className="fw-bold mb-3">
              {" "}
              {auth.language === "en"
                ? "Enter Current Password To Update Changes"
                : "পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন"}
            </h4>
            <input
              type="password"
              required
              name=""
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            {auth.language === "en" ? "Close" : "বন্ধ"}
          </Button>
          <Button
            type="button"
            className="btn fw-bold"
            style={{ backgroundColor: "var(--primary_color)" }}
            onClick={updateUser}
          >
            {auth.language === "en"
              ? " Save Changes "
              : "পরিবর্তনগুলোর সংরক্ষন"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditProfileN;
