import React, { useState } from 'react';
import { useHistory } from 'react-router';
import RateUser from '../modals/Profile/RateUser';
import SuccessModal from '../util/SuccessModal';
import './UserCard.css';
const UserCardTwo = (props) => {
  const history = useHistory();
  const [showRateModal, setShowRateModal] = useState(false);
  const [showRateSuccess, setShowRateSuccess] = useState(false);
  return (
    <React.Fragment>
      <RateUser
        show={showRateModal}
        providerId={props.providerId}
        clientId={props.clientId}
        provider={props.provider}
        dealId={props.dealId}
        onSuccess={() => {
          setShowRateSuccess(true);
        }}
        onCancel={() => {
          setShowRateModal(false);
        }}
      />
      <SuccessModal
        message='Rating was successful. Thank you for your feedback.'
        show={showRateSuccess}
        onClear={() => {
          setShowRateSuccess(false);
        }}
      />
      <div onClick={() => history.push(`/job-details/${props.job._id}`)}>
        <div
          className='user__card__tiny2'
          style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)' }}
        >
          {/* Dynamically --> service or print  */}
          {/* {props.detailed && <p className="green__detail">Service or print Provider</p>} */}
          <div className='head__section'>
            <div className='bottom--text'>
              <h2 className='second-card-title'>{props.title}</h2>
              <h5 className='second-card-cate'>
                {' '}
                {(props.category.length > 0 ? props.category + ' - ' : '') +
                  props.subCategory[0]}
              </h5>
              <h6 className='second-card-dead'>Posted on {props.postDate}</h6>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserCardTwo;
