import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import { Link } from 'react-router-dom';
import Paginator from '../../components/util/Paginator';
const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
const FailedDeals = () => {
  const [deals, setDeals] = useState(null);
  const { isLoading, sendRequest } = useHttpClient();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_failed_deals/?page=${page}`
        );
        setDeals(responseData);
      } catch (err) {}
    };
    fetchDeals();
    //eslint-disable-next-line
  }, [page]);

  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>Failed Deals</h3>
      <div className='manage__box blue'>
        <Link to={`/refunded_deals`}>
          <u className='refund__p'>view refunded deals</u>
        </Link>

        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td>Job Title</td>
              <td>Deal Created At</td>
              <td>Deadline</td>
              <td>State</td>
              <td></td>
            </tr>
            {deals &&
              deals.map((deal) => {
                return (
                  <tr className='t_bottom'>
                    <td className='last_td_gn'>
                      {
                        <Link
                          target='_blank'
                          to={`/job-details/${deal.job_id._id}`}
                        >
                          <u className='provider_name'>{deal.job_id.title}</u>
                        </Link>
                      }
                    </td>
                    <td>
                      {new Date(deal.createdAt).toLocaleString(
                        undefined,
                        dateOptions
                      )}
                    </td>
                    <td>
                      {new Date(deal.deadline).toLocaleString(
                        undefined,
                        dateOptions
                      )}
                    </td>
                    <td>{deal.state}</td>
                    <td className='last_td_gn'>
                      {
                        <Link target='_blank' to={`/deal/${deal._id}`}>
                          <u className='provider_name'>view Deal</u>
                        </Link>
                      }
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default FailedDeals;
