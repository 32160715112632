import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth-context";
import { PROVIDER_ACCEPT_BID } from "../../../lib/api/admin";
import { deleteConfirmation } from "../../../lib/sweetAlert";
import Button from "../../../components/shared/Button/Button";
import Modal from "react-bootstrap/Modal";
import Modal2 from "../../../components/shared/Modal/Modal";
import "./BidCard.css";
import axios from "axios";
import { useHttpClient } from "../../../hooks/http-hook";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

function BidCard({ bid, refetch }) {
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedjob, setLoadedJob] = useState();
  const [globalsVar, setGlobalsVar] = useState();
  const [open, setOpen] = useState(false);
  const [selectedBid, setSelectedBid] = useState({});

  const auth = useContext(AuthContext);
  const { mutateAsync: acceptBidMutation } = useMutation(PROVIDER_ACCEPT_BID);

  const acceptBid = async (bid_id, name) => {
    try {
      await acceptBidMutation({
        bid_id,
      });
      toast.success("Bidder accepted successfully");
      setOpen(false);
      window.location.reload();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [previousClaimedBudget, setPreviousClaimedBudget] = React.useState(0);
  const [previousComment, setPreviousComment] = React.useState("");

  useEffect(() => {
    setPreviousClaimedBudget(bid?.claimed_budget);
    setPreviousComment(bid?.comment);
  }, [bid]);

  const fetchJob = () => {
    axios
      .get(`https://somadha.com/api/job?job_id=630e3e265c3f3c3b90fff1af`)
      .then((responseData) => {
        setLoadedJob(responseData);
      });
  };

  const globals = () => {
    axios
      .get("https://somadha.com/api/user/get_globals", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setGlobalsVar(res?.data);
      });
  };

  useEffect(() => {
    fetchJob();
    globals();
  }, []);

  const updateBid = async () => {
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/provider/update_bid",
        {
          bid_id: bid._id,
          claimed_budget: previousClaimedBudget,
          comment: previousComment,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );

      if (responseData.status === 200) {
        notify("success", "Bid updated!");
        refetch();
        handleClose();
      } else throw new Error(responseData);
    } catch (err) {
      notify("err", err?.response?.data?.msg || "Something Went Wrong!");
    }
  };

  return (
    <div
      className=" bidCard"
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)",
        borderRadius: "4px",
      }}
    >
      <div className=" leftWing">
        <p>
          <span className="fw-bold">
            {auth.language === "en" ? "Claimed Budget: " : "দাবিকৃত বাজেট: "}{" "}
          </span>{" "}
          {bid.claimed_budget}
          {auth.language === "en" ? " BDT " : " টাকা "}
        </p>
        <p>
          <strong>
            {auth.language === "en" ? "Bid For" : "দরপত্র এর জন্য"}
          </strong>
        </p>

        <p>
          <span
            style={{
              width: "100%",
              background: "#E8EAFD",
              borderRadius: "4px",
            }}
            className=" px-5 py-1 text-center text-prime"
          >
            {bid.bid_type}
          </span>
        </p>

        <strong>
          {auth.language === "en" ? "Your Comment: " : "আপনার মন্তব্য: "}
        </strong>

        <p style={{ textAlign: "justify", color: "#666666" }}>{bid.comment}</p>

        <p>
          <strong>
            {auth.language === "en" ? "Bid Status" : "দরপত্রের অবস্থা"}
          </strong>
        </p>
        {bid.state ? (
          <span
            style={{
              width: "100%",
              background: "#FFE5E6",
              borderRadius: "4px",
              textTransform: "uppercase",
            }}
            className=" px-5 py-1 text-center"
          >
            {bid.state}
          </span>
        ) : (
          <span
            style={{
              width: "100%",
              background: "#FFE5E6",
              borderRadius: "4px",
              textTransform: "uppercase",
            }}
            className=" px-5 py-1 text-center"
          >
            {bid.state}
          </span>
        )}
      </div>

      <div className=" rightWing">
        <h5>
          <strong>{bid.job_id.title}</strong>
        </h5>

        <div className="d-flex justify-content-start align-items-center my-4">
          <img width="20px" height="20px" src="/assets/designing.png" alt="" />
          <p className="mx-2 my-0">
            {" "}
            {(bid.job_id.parent_category.length > 0
              ? bid.job_id.parent_category + " - "
              : "") + bid.job_id.category[0]}
          </p>
        </div>
        <div className="d-flex justify-content-start align-items-center my-4">
          <img width="20px" height="20px" src="/assets/clock.png" alt="" />

          <p className="mx-2 my-0">
            {auth.language === "en" ? "Posted on " : "পোস্ট করা হয়েছে "}
            {new Date(bid.createdAt).toLocaleDateString(undefined, dateOptions)}
          </p>
        </div>
        <div className="d-flex justify-content-start align-items-center my-4">
          <img width="20px" height="20px" src="/assets/taka.png" alt="" />
          <p className="mx-2 my-0">
            {auth.language === "en" ? "Max Budget:" : "সর্বোচ্চ বাজেট:"}{" "}
            {bid.job_id.max_budget}{" "}
            {auth.language === "en" ? "BDT: " : "টাকা: "}{" "}
          </p>
        </div>


        <Link to={`/job-details/${bid.job_id._id}`}>
          <button
            type="button"
            style={{
              width: "100%",
            }}
            className="fw-bold   text-center prime-btn btn"
          >
            {auth.language === "en" ? "View Job Details" : "কাজের বিবরণ দেখুন"}
          </button>
        </Link>


        {!bid.closed_by_admin && (
          <button
            type="button"
            style={{
              width: "100%",
            }}
            className="fw-bold mt-2 text-center prime-btn btn"
            onClick={handleShow}
            disabled={bid.state === "SUCCESSFUL"}
          >
            {auth.language === "en" ? "Update Bid" : "দরপত্র হালনাগাদ করুন"}
          </button>
        )}


        {!bid.is_accepted_by_provider && bid.is_accepted_by_client && (
          <button
            type="button"
            style={{
              width: "100%",
            }}
            onClick={() => {
              setOpen(true);
              setSelectedBid({
                id: bid._id,
                name: bid.provider_id.fullname,
              });
            }}
            className="fw-bold text-center prime-btn btn mt-2"
          >
            {auth.language === "en" ? "Accept Bid" : "দরপত্র গ্রহণ করুন"}
          </button>
        )}
      </div>

      <Modal2 open={open} onClose={setOpen} title="Accept Bid">
        <div className="makePaymentForDueAmountModal">
          <div className="confirmPayment__form">
            {globalsVar?.bid_accept_prompt && (
              <div>
                {JSON.parse(globalsVar?.bid_accept_prompt).blocks.map(
                  (block, index) => (
                    <div
                      key={index}
                      className="formatted-content"
                      dangerouslySetInnerHTML={{ __html: block.data.text }}
                    />
                  )
                )}
              </div>
            )}

            {/*<p>
          {delivery && (
            <p>
              "Please be reminded that the job requires you to send hard
              copies via postage. If you don’t post them in a timely manner,
              there will be a penalty." <br /> <br />
            </p>
          )}

          <p>
            Please complete your{" "}
            <strong className="text-primary">
              {watch("payment_method")}
            </strong>{" "}
            job information to submit your work
          </p>
          </p>*/}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="primary"
                type="submit"
                style={{ padding: ".5rem 3rem", margin: "1rem auto" }}
                onClick={() => acceptBid(selectedBid.id, selectedBid.name)}
              >
                Accept
              </Button>
            </div>
          </div>
        </div>
      </Modal2>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Bid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="">Claimed Budget</label>
          <input
            type="number"
            value={previousClaimedBudget}
            onChange={(e) => setPreviousClaimedBudget(e.target.value)}
          />
          <label for="">Comment</label>
          <input
            type="text"
            value={previousComment}
            onChange={(e) => setPreviousComment(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="button"
              style={{
                width: "100%",
              }}
              onClick={handleClose}
              className="fw-bold text-center prime-btn btn me-2"
            >
              Close
            </button>
            <button
              type="button"
              style={{
                width: "100%",
              }}
              onClick={() => updateBid()}
              className="fw-bold text-center prime-btn btn "
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BidCard;
