import React, { useState } from 'react';
import Modal from '../../util/Modal';
import './Payment.css';
import '../Profile/EditExpertise.css';
import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import ErrorModal from '../../util/ErrorModal';
const mobileBankProviders = ['BKASH', 'NAGAD', 'ROCKET'];
const Payment = (props) => {
  const { error, clearError, isLoading, sendRequest } = useHttpClient();
  const [amount, setAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [issuer, setIssuer] = useState('');
  const clearInputs = () => {
    setAmount('');
    setTransactionId('');
    setIssuer('');
  };
  const pay = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/admin/pay_provider`,
        'POST',
        JSON.stringify({
          deal_id: props.deal_id,
          transaction_id: transactionId,
          transaction_issuer: issuer,
          total_amount: amount,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
        props.onCancel();
        clearInputs();
      }
      // if (responseData.ssl_url) {
      // const win = window.open(responseData.ssl_url, "_blank");
      // win.focus();
      // }
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError} />
      <div className='payment_container'>
        <img
          className='cancel__btn'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h3>Pay Provider</h3>

        <div className='pay_box'>
          <p>Pay Amount</p>
          <input
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            className='pay_input'
            type='text'
            placeholder='Pay Amount'
          />
          <p>Transaction ID</p>
          <input
            value={transactionId}
            onChange={(e) => {
              setTransactionId(e.target.value);
            }}
            className='pay_input'
            type='text'
            placeholder='Transaction ID'
          />
          <p>Transaction Issuer</p>
          <select
            className='pay_input'
            value={issuer}
            onChange={(e) => {
              setIssuer(e.target.value);
            }}
          >
            <option value=''>Select One</option>
            {mobileBankProviders.map((provider, ind) => {
              return (
                <option key={ind} value={provider}>
                  {provider}
                </option>
              );
            })}
          </select>
          <button onClick={pay} className='confirm__save'>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Payment;
