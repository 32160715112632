import React from 'react';
import './Paginator.css';
const Paginator = ({ page, setPage }) => {
  const increasePage = () => {
    let newPage = page + 1;
    setPage(newPage);
  };
  const decreasePage = () => {
    if (page > 1) {
      let newPage = page - 1;
      setPage(newPage);
    }
  };
  return (
    <div>
      <div className='navigate__page'>
        <button onClick={decreasePage} className='left_nav'>
          prev
        </button>
        <div className='page_no'>{page}</div>
        <button onClick={increasePage} className='right_nav'>
          next
        </button>
      </div>
    </div>
  );
};

export default Paginator;
