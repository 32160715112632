import React, { useState } from 'react';
import './DotMenu.css';
import Backdrop from '../util/Backdrop';
import { useHttpClient } from '../../hooks/http-hook';
import ErrorModal from '../util/ErrorModal';
import LoadingSpinner from '../util/LoadingSpinner';


const DotMenu = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [menu, setMenu] = useState(false);
  const showMenu = () => {
    setMenu(true);
  };
  const hideMenu = () => {
    setMenu(false);
  };
  const promote = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/elevate_user/?user_id=${props.id}`,
        'PUT',
        JSON.stringify({
          rank: 'ADMIN',
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      props.onSuccess();
    } catch (err) {}
  };

  const demote = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/elevate_user/?user_id=${props.id}`,
        'PUT',
        JSON.stringify({
          rank: 'MANAGER',
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      props.onSuccess();
    } catch (err) {}
  };
  const remove = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/elevate_user/?user_id=${props.id}`,
        'PUT',
        JSON.stringify({
          rank: 'USER',
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      props.onSuccess();
    } catch (err) {}
  };

  const approveJob = async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/approve_job/?job_id=${props.jobID}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (response.success === 'yes') {
        props.refresh();
      }
    } catch (err) {}
  };

  const dissaproveJob = async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/delete_job/?job_id=${props.jobID}`,
        'DELETE',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (response.success === 'yes') {
        props.refresh();
      }
    } catch (err) {}
  };

  return (
    <div className='dot_menu'>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <img onClick={showMenu} src='/assets/three_dots.svg' alt=''></img>
      {menu && <Backdrop onClick={hideMenu} />}
      <div className={menu ? 'dot_card' : 'dotcard_inv'}>
        {props.role === 'ADMIN' && (
          <div onClick={demote} className='p_items'>
            <img className='makeit_down' src='/assets/up_arrow.svg' alt='' />

            <span>Demote to MANAGER</span>
          </div>
        )}
        {props.role === 'MANAGER' && (
          <div onClick={promote} className='p_items'>
            <img src='/assets/up_arrow.svg' alt='' />

            <span>Promote to ADMIN</span>
          </div>
        )}
        {(props.role === 'MANAGER' || props.role === 'ADMIN') && (
          <div onClick={remove} className='p_items'>
            <img src='/assets/cross.svg' alt='' />
            <span>Remove Admin</span>
          </div>
        )}
        {props.role === 'USER' && (
          <div onClick={demote} className='p_items'>
            <img src='/assets/up_arrow.svg' alt='' />

            <span>Promote to MANAGER</span>
          </div>
        )}
        {props.role === 'USER' && (
          <div onClick={promote} className='p_items'>
            <img src='/assets/up_arrow.svg' alt='' />

            <span>Promote to ADMIN</span>
          </div>
        )}

        {props.approve && (
          <div onClick={approveJob} className='p_items'>
            <img src='/assets/up_arrow.svg' alt='' />
            <span>Approve</span>
          </div>
        )}
        {props.disapprove && (
          <div onClick={dissaproveJob} className='p_items'>
            <img src='/assets/cross.svg' alt='' />
            <span>Disapprove</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DotMenu;
