import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth-context";
import useWindowWidth from "../../../hooks/window-width";

const BecomeClientProvider = () => {
  const auth = useContext(AuthContext);
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 768;

  const wrapperStyle = {
    margin: "5rem auto",
    maxWidth: "1400px",
    width: isMobile ? "92vw" : "80vw",
  };

  const becomeContainerStyle = {
    marginTop: "3rem",
    textAlign: "center",
  };

  const providerH2Style = {
    color: "#000",
    fontFamily: "Nunito",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "start",
  };

  const becomeClientStyle = {
    display: "grid",
    gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(5, 1fr)",
    justifyContent: "start",
    listStyle: "none",
    padding: "0",
    gap: "20px",
    marginTop: "40px",
  };

  const clientItemStyle = {
    width: "100%",
    height: isMobile ? "184px" : "140px",
    color: "var(--grey_three)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    background: "var(--WhiteBG, #FFF)",
    boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.10)",
  };

  const clientItemTextStyle = {
    color: "var(--grey_two)",
    textAlign: "center",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    margin: "0",
  };

  const becomeClient = [
    {
      en: "1. Create Account",
      bn: "১. অ্যাকাউন্ট তৈরি করুন",
    },
    {
      en: "2. Post Job",
      bn: "২. কাজ পোস্ট করুন",
    },
    {
      en: "3. Initiate Deal with Bidder",
      bn: "৩. দরপত্র দাতা সাথে চুক্তি শুরু করুন",
    },
    {
      en: "4. Carry on Deal and Keep Contact with Provider",
      bn: "৪. চুক্তি চালিয়ে যান এবং প্রদায়কের সাথে যোগাযোগ রাখুন",
    },
    {
      en: "5. Get Job Done!",
      bn: "৫. কাজ সম্পন্ন করুন!",
    },
  ];

  const becomeProvider = [
    {
      en: "1. Create Account",
      bn: "১. অ্যাকাউন্ট তৈরি করুন",
    },
    {
      en: "2. Find Job Posts",
      bn: "২. কাজের পোস্ট খুঁজুন",
    },
    {
      en: "3. Wait for Client to Initiate Deal",
      bn: "৩. গ্রহক চুক্তি শুরু করার জন্য অপেক্ষা করুন",
    },
    {
      en: "4. Carry on Deal and Keep Contact with Client",
      bn: "৪. চুক্তি চালিয়ে যান এবং গ্রাহকের সাথে যোগাযোগ রাখুন",
    },
    {
      en: "5. Submit Your Work",
      bn: "৫. আপনার কাজ জমা দিন",
    },
  ];

  return (
    <div style={wrapperStyle}>
      <div style={{ ...becomeContainerStyle, marginTop: "3rem" }}>
        <h2 style={providerH2Style}>
          {auth.language === "en"
            ? "How to Become a Client"
            : "কিভাবে ক্লায়েন্ট হতে হবে"}
        </h2>
        <ul style={becomeClientStyle}>
          {becomeClient.map((item, index) => (
            <li key={index} style={clientItemStyle}>
              <p style={clientItemTextStyle}>{item[auth.language]}</p>
            </li>
          ))}
        </ul>
      </div>
      <div style={becomeContainerStyle}>
        <h2 style={providerH2Style}>
          {auth.language === "en"
            ? "How to Become a Provider"
            : "কিভাবে একটি ফ্রিল্যান্সার হতে হবে"}
        </h2>
        <ul style={becomeClientStyle}>
          {becomeProvider.map((item, index) => (
            <li key={index} style={clientItemStyle}>
              <p style={clientItemTextStyle}>{item[auth.language]}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BecomeClientProvider;
