import React, { useEffect } from "react";
import DisapprovedJobs from "../sections/admin/DisapprovedJobs";

const AdminDisapproved = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <DisapprovedJobs />;
};

export default AdminDisapproved;
