import React, { useState } from 'react';
import '../../admin/DotMenu.css';
import Backdrop from '../../util/Backdrop';
import { useHttpClient } from '../../../hooks/http-hook';
import ApplyBid from '../../modals/bid/ApplyBid';
import LoadingSpinner from '../../util/LoadingSpinner';
import ErrorModal from '../../util/ErrorModal';
const ThreeDot = (props) => {
  const { error, clearError, isLoading, sendRequest } = useHttpClient();
  const [menu, setMenu] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const showMenu = () => {
    setMenu(true);
  };
  const hideMenu = () => {
    setMenu(false);
  };
  const onCancel = () => {
    setShowEditor(false);
  };

  const cancelBid = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/provider/cancel_bid/?bid_id=${props.id}`,
        'DELETE',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        hideMenu();
        props.onSuccess();
        //maybe confirm somehow that bid is placed
      }
    } catch (err) {}
  };
  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='dot_menu'>
        <ErrorModal error={error} onClear={clearError} />
        <ApplyBid
          edit
          id={props.id}
          show={showEditor}
          budget={props.jobBudget}
          onCancel={onCancel}
          claimedPrice={props.bidBudget}
          comment={props.comment}
          onSuccess={props.onSuccess}
        />
        <img onClick={showMenu} src='/assets/three_dots.svg' alt=''></img>
        {menu && <Backdrop onClick={hideMenu} />}
        <div className={menu ? 'dot_card white' : 'dotcard_inv'}>
          <div
            onClick={() => {
              setShowEditor(true);
              hideMenu();
            }}
            className='p_items'
          >
            <img className='makeit_down' src='/assets/edit.png' alt='' />

            <span>Edit Bid</span>
          </div>
          <div onClick={cancelBid} className='p_items'>
            <img className='makeit_down' src='/assets/cross.svg' alt='' />

            <span>Cancel Bid</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThreeDot;
