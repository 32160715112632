import { useContext } from "react";
import { AuthContext } from "../context/auth-context";

const Texts = () => {
  const auth = useContext(AuthContext);
  let texts;
  auth.language === "en"
    ? (texts = {
        explore: "Explore Works", //Explore,
        find_provider: "Find Providers", //Explore,
        aboutUs: "About Us",
        otp:"OTP",
        login: "Login",
        signUp: "Sign Up",
        payment: "Payment",
        notifications: "Notifications",
        messages: "Messages",
        profile: "Profile",
        exploreJobs: "Explore Jobs",
        postJob: "Post A Job",
        exploreCatalogs: "Explore the Catalogue of Services!",
        job_posts: "Job Posts",
        bids: "Bids",
        deals: "Deals",
        achievements: "Achievements",
        edit_profile: "Edit Profile",
        preferences: "Preferences",
        logOut: "Log Out",
        personalInfo: "Personal Info",
        background: "Background",
        overview: "Overview",
        badges: "Badges",
        stats: "stats",
        email: "E-Mail",
        password: "Password",
        forgotPassword: "Forgot Password",
        noAccount: "No Account?",
        createAccount: "Create Account",
        orSignSocial: "Or Sign in With...",
        fullName: "Full Name",
        confirmPass: "Confirm Password",
        referralCode: "Referral Code",
        haveAccount: "Have an Account?",
        verificationLink:
          "We have sent you a verification link. Please check your inbox.",
        ok: "Okay",
        errName: "please enter your name",
        errEmail: "Please enter a valid email or phone number",
        errPass: "Password should be at least 8 characters",
        errPassNoMatch: "password not match",
      })
    : (texts = {
        explore: "এক্সপ্লোর",
        otp:"ওটিপি",
        aboutUs: "আমাদের সম্পর্কে",
        payment: "পেমেন্ট",
        find_provider: "প্রোভাইডার খুজুন",
        login: "লগইন",
        signUp: "সাইন আপ",
        notifications: "বিজ্ঞপ্তি",
        messages: "বার্তা",
        profile: "বৃত্তান্ত",
        exploreJobs: "কাজ খুঁজুন",
        postJob: "কাজ দিন",
        exploreCatalogs: "আমাদের পরিষেবা গুলো দেখুন!",
        job_posts: "প্রদত্ত কাজ  সমূহ",
        bids: "দরপত্র সমূহ",
        deals: "চুক্তি সমূহ",
        achievements: "অর্জন",
        edit_profile: "বৃত্তান্ত সম্পাদনা",
        preferences: "পছন্দ সমূহ",
        logOut: "প্রস্থান",
        personalInfo: "ব্যাক্তিগত তথ্য",
        background: "নেপথ্য",
        overview: "পর্যালোচনা",
        badges: "ব্যাজ",
        stats: "পরিসংখ্যান",
        email: "ইমেইল বা ফোন নাম্বার",
        emailorPhone: "ইমেইল বা ফোন নাম্বার",
        password: "পাসওয়ার্ড",
        forgotPassword: "পাসওয়ার্ড ভুলে গেছেন",
        noAccount: "কোন অ্যাকাউন্ট নেই?",
        createAccount: "অ্যাকাউন্ট খুলুন",
        orSignSocial: "অথবা সাইন ইন করুন...",
        fullName: "পুরো নাম",
        confirmPass: "পাসওয়ার্ড নিশ্চিত করুন",
        referralCode: "রেফারেল কোড",
        haveAccount: "একাউন্ট আছে?",
        verificationLink:
          "আমরা আপনাকে একটি যাচাইয়ের লিঙ্ক পাঠিয়েছি। আপনার ইনবক্স চেক করুন।",
        ok: "ঠিক আছে",
        errName: "দয়া করে আপনার নাম ইনপুট দিন",
        errEmail: "একটি বৈধ ইমেইল লিখুন",
        errEmailorPhone: "একটি বৈধ ইমেইল বা ফোন নাম্বার লিখুন",
        errPass: "পাসওয়ার্ড কমপক্ষে ৮ টি অক্ষর হওয়া উচিত",
        errPassNoMatch: "পাসওয়ার্ড মেলে নি",
      });
  return texts;
};
export default Texts;
