import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: true,
  isAdmin: true,
  isManager: true, 
  refresh: false,
  language: 'en',
  uiConfig: {
    color_theme: {
      primary: '#6103ee',
      primary_light: '#f0e6ff',
      secondary: '#9c67f8',
      link_text: '#0000d4',
      positive_feedback: '#01ca1d',
      negative_feedback: '#eb2435',
      job_post: '#ffffff',
      deal: '#ffffff',
      bid: '#ffffff',
      client: '#ffffff',
      provider: '#fcfcfc',
      background: '#ffffff',
      text: '#333333',
    },
    _id: '60422733b2cbd31f2c99dacd',
    __v: 0,
    createdAt: '2021-03-05T12:42:27.001Z',
    updatedAt: '2021-07-29T01:42:55.470Z',
    global_font_bn: 'Mini',
    global_font_en: 'Nunito',
    logo: 'https://somadha.com/api/files/13fc1d70-7de3-11eb-8457-ac75d7ae3aa4.png',
    login_page_banner:"",
    signup_page_banner:"",
    homepage_search_job_banner_desc_bn:
      'সমাধা এমন একটি প্ল্যাটফর্ম যা মানুষজনকে তাদের দৈনন্দিন জীবনের সমস্যা সমাধানে সহায়তা করতে বদ্ধপরিকর ।',
    homepage_search_job_banner_desc_en:
      'Somadha is a platform to assist people needing help in solving their daily life problems.',
    homepage_search_job_banner_desc_font_bn: 'Hind Siliguri',
    homepage_search_job_banner_desc_font_en: 'Nunito',
    homepage_search_job_banner_header_bn: 'সমাধা',
    homepage_search_job_banner_header_en: 'Somadha',
    homepage_search_job_banner_header_font_bn: 'Hind Siliguri',
    homepage_search_job_banner_header_font_en: 'Nunito',
    homepage_post_job_banner_desc_bn: 'হিজি বিজি হিজি বিজি',
    homepage_post_job_banner_desc_en: 'HabbiJabi',
    homepage_post_job_banner_desc_font_bn: 'Hind Siliguri',
    homepage_post_job_banner_desc_font_en: 'Nunito',
    homepage_post_job_banner_header_bn: 'আবারো যায়গা মতো আসছেন',
    homepage_post_job_banner_header_en: 'Catch Phrase',
    homepage_post_job_banner_header_font_bn: 'Hind Siliguri',
    homepage_post_job_banner_header_font_en: 'Nunito',
    jobpostpage_banner_desc_bn: 'এইখানে হিজি বিজি হিজি বিজি',
    jobpostpage_banner_desc_en:
      'Lorem ipsum dolor amet elit something to attract users to post a job',
    jobpostpage_banner_desc_font_bn: 'Hind Siliguri',
    jobpostpage_banner_desc_font_en: 'Nunito',
    jobpostpage_banner_header_bn: 'কিছু করন লাগবো?',
    jobpostpage_banner_header_en: 'Trying to get a work Done?',
    jobpostpage_banner_header_font_bn: 'Hind Siliguri',
    jobpostpage_banner_header_font_en: 'Nunito',
    homepage_search_job_banner:
      'https://somadha.com/api/files/e08aee10-8c6c-11eb-8abe-03f7e4d2fa3c.jpeg',
    homepage_post_job_banner:
      'https://somadha.com/api/files/ef38dd50-8c6c-11eb-861d-a1a7611e830b.jpeg',
  },
  user: {
    date_of_birth: '1997-09-07T00:00:00.000Z',
    timestamp: '2021-01-21T13:33:27.911Z',
    client_rating: 0,
    provider_rating: 0,
    punishment: 10,
    fine: 0,
    total_deal: 0,
    total_post: 7,
    total_bid: 0,
    badges: [],
    is_email_verified: true,
    user_type: 'ADMIN',
    is_blacklisted_client: false,
    is_blacklisted_provider: false,
    language: 'ENGLISH',
    is_public: true,
    is_deleted: false,
    mobile_banking_phone_number: null,
    mobile_banking_provider: null,
    client_target_amount: 0,
    provider_target_amount: 0,
    tag: ['CLIENT'],
    client_credit: 0,
    provider_credit: 0,
    expertise: ['Testing', 'What', 'Writing'],
    desired_category: [],
    lowest_rating_count_provider: 0,
    print_provider_request: false,
    print_provider_approved: false,
    lowest_rating_count_client: 0,
    promo_code: [],
    used_promo_code: false,
    worked_with: [],
    _id: '60099023f8addffa6f21b0b0',
    fullname: 'Shamim Bin Zahid',
    email: 'shamimbinzahid@gmail.com',
    client_rating_array: [],
    provider_rating_array: [],
    degree: [
      {
        _id: '60f81780e1551c1be3500dff',
        school: 'University of Dhaka',
        degree: 'BSc,Computer Science & Engineering',
        passing_year: 2023,
      },
    ],
    achievement: [
      {
        _id: '600991cbf8addffa6f21b0b8',
        name: 'Climbed a hill',
        description: 'yay\n',
      },
    ],
    __v: 1,
    profile_pic:
      'https://somadha.com/api/files/a9b25cb0-7dea-11eb-81f7-b2c749c09efb.png',
    updatedAt: '2021-07-30T00:00:00.009Z',
    createdAt: '2021-06-08T12:40:50.939Z',
  },
  categories: [
    {
      parent: null,
      logo: 'https://somadha.com/api/files/687b3b30-9dda-11eb-8196-98e228c5a62f.svg',
      is_disabled: false,
      min_charge_client: 10000,
      max_charge_client: 100000,
      min_charge_provider: 20000,
      max_charge_provider: 200000,
      print: false,
      delivery: false,
      delay: 5,
      _id: '60454813904efea92ca2aa43',
      name: 'Cleaning',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/7ee21830-9dda-11eb-8b58-b4de3829b820.svg',
      is_disabled: false,
      min_charge_client: 1000,
      max_charge_client: 1500,
      min_charge_provider: 500,
      max_charge_provider: 1000,
      print: false,
      delivery: false,
      delay: 0,
      _id: '604e8e3179f5d33c4a5eb217',
      name: 'Printing',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/ace2fb00-9dda-11eb-8f43-3d1e88c63d17.svg',
      is_disabled: false,
      min_charge_client: 1500,
      max_charge_client: 3000,
      min_charge_provider: 500,
      max_charge_provider: 2000,
      print: false,
      delivery: false,
      delay: 5,
      _id: '604f8d9bbbf6eb83b67b53ae',
      name: 'Composing',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/ce1053e0-9dda-11eb-8732-94ac5524f910.svg',
      is_disabled: false,
      min_charge_client: 1000,
      max_charge_client: 2000,
      min_charge_provider: 100,
      max_charge_provider: 500,
      print: false,
      delivery: false,
      delay: 0,
      _id: '604fe397bbf6eb83b67b53e4',
      name: 'Logo Design',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/f336ac50-9dda-11eb-81f3-fe302207d427.svg',
      is_disabled: false,
      min_charge_client: 15000,
      max_charge_client: 20000,
      min_charge_provider: 3000,
      max_charge_provider: 5000,
      print: false,
      delivery: false,
      delay: 0,
      _id: '6052895089c457932a0c2114',
      name: 'Form Fillup',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/0e86dc50-9ddb-11eb-8847-ce41e63626f3.svg',
      is_disabled: false,
      min_charge_client: 222,
      max_charge_client: 555,
      min_charge_provider: 111,
      max_charge_provider: 666,
      print: false,
      delivery: false,
      delay: 0,
      _id: '605bab48e82081ed8a79e41a',
      name: 'Carpenter',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/4904ebb0-9ddb-11eb-84e9-a1559e792e06.svg',
      is_disabled: false,
      min_charge_client: 222,
      max_charge_client: 555,
      min_charge_provider: 222,
      max_charge_provider: 555,
      print: false,
      delivery: false,
      delay: 0,
      _id: '605bab5de82081ed8a79e41b',
      name: 'Painting',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/71f6a400-9ddb-11eb-804b-027ffcdeaec9.svg',
      is_disabled: false,
      min_charge_client: 222,
      max_charge_client: 555,
      min_charge_provider: 222,
      max_charge_provider: 555,
      print: false,
      delivery: false,
      delay: 0,
      _id: '605bab6ce82081ed8a79e41c',
      name: 'Mover',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/91b1a830-9ddb-11eb-88e0-33b6763d6579.svg',
      is_disabled: false,
      min_charge_client: 222,
      max_charge_client: 555,
      min_charge_provider: 222,
      max_charge_provider: 555,
      print: false,
      delivery: false,
      delay: 0,
      _id: '605bab83e82081ed8a79e41d',
      name: 'Repair',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/61133760-a21e-11eb-84c8-5970d077cee0.png',
      is_disabled: false,
      min_charge_client: 4000,
      max_charge_client: 5000,
      min_charge_provider: 1000,
      max_charge_provider: 2000,
      print: false,
      delivery: false,
      delay: 0,
      _id: '607f45dca52a7b9c1ee9ccf6',
      name: 'aa test',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/850b81d0-a468-11eb-8774-01d9d85a5980.png',
      is_disabled: false,
      min_charge_client: 5000,
      max_charge_client: 10000,
      min_charge_provider: 3000,
      max_charge_provider: 5000,
      print: false,
      delivery: false,
      delay: 0,
      _id: '60831d3ea52a7b9c1ee9cd25',
      name: 'aa test 01',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/c8529330-b04c-11eb-86d7-6a69a8cd706e.png',
      is_disabled: false,
      min_charge_client: 3000,
      max_charge_client: 5000,
      min_charge_provider: 8000,
      max_charge_provider: 10000,
      print: false,
      delivery: false,
      delay: 0,
      _id: '609710ab315016464d00b2ce',
      name: 'charge (in range)',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/536b8640-b050-11eb-8d94-3e590e2ddb48.jpeg',
      is_disabled: false,
      min_charge_client: 3000,
      max_charge_client: 5000,
      min_charge_provider: 2000,
      max_charge_provider: 4000,
      print: false,
      delivery: false,
      delay: 0,
      _id: '6097169c315016464d00b2ff',
      name: 'non-sub',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/2038b5e0-c892-11eb-8572-6daa2c21a9c8.png',
      is_disabled: false,
      min_charge_client: 125,
      max_charge_client: 135,
      min_charge_provider: 4500,
      max_charge_provider: 5000,
      print: false,
      delivery: false,
      delay: 5000,
      _id: '60bfc8ed8bbf10320cfc0a53',
      name: 'hello',
      __v: 0,
    },
    {
      parent: null,
      logo: 'https://somadha.com/api/files/29813900-ca6e-11eb-8132-14927820a37b.png',
      is_disabled: false,
      min_charge_client: 50,
      max_charge_client: 1000,
      min_charge_provider: 50,
      max_charge_provider: 1000,
      print: true,
      delivery: true,
      delay: 0,
      _id: '60c2e79597eab06ba7ab0752',
      name: 'new topic',
      __v: 0,
    },
  ],

  setRefresh: () => {},
  Login: () => {},
  logout: () => {},
  setUser: () => {},
  changeLanguage: () => {},
  loginWithProviders: (token) => {},
});
