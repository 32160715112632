import React from 'react';
import ProviderCardNew from './ProviderCardNew';
import TopProviderCard from './TopProviderCard';

function ShowTopProvider({ providers, loading }) {
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
        gridGap: '20px',
        margin: '40px auto 20px auto',
        width: '100%',
      }}
    >
      {!loading &&
        providers.data &&
        providers.data.map((provider, index) => (
          <ProviderCardNew provider={provider} key={index} />
        ))}
    </div>
  );
}

export default ShowTopProvider;
