import React, { useContext } from "react";
import "./SkillItem.css";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
const SkillItem = (props) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const deleteItem = async () => {
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + "/user/delete_expertise",
      "PUT",
      JSON.stringify({
        expertise: [props.text],
      }),
      {
        "Content-Type": "application/json",
      }
    );
    //maybe check for response data
    if (responseData.success === "yes") {
      auth.setUser();
    }
  };
  return (
    <div className="skill__item">
      <p>{props.text}</p>
      {props.delete && (
        <img onClick={deleteItem} src="/assets/cross.svg" alt="" />
      )}
      {props.delfn && (
        <img onClick={props.deleteFunction} src="/assets/cross.svg" alt="" />
      )}
    </div>
  );
};

export default SkillItem;
