import React, { useState, useRef, useEffect, useContext } from "react";
// import Modal from './Modal';
import { AuthContext } from "../../context/auth-context";
import "./ImageUploader.css";
import { useHttpClient } from "../../hooks/http-hook";
import LoadingSpinner from "../util/LoadingSpinner";
import { Modal } from "react-bootstrap";
import axios from "axios";
import "../../uicomponents/userPanel/editprofile.js/Edit_Profile.css";
import { BUCKET_URL,S3_PREASSIGNED_POST_URL,CLOUDFRONT_URL } from "../../config";
import { toast } from "react-toastify";

const ImageUploader = (props) => {
  const auth = useContext(AuthContext);
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [modal, setModal] = useState(false);
  const fileInputRef = useRef();
  const { isLoading, sendRequest } = useHttpClient();
  const [globalSettings, setGlobalSettings] = useState(null);


  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);


  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result.toString());

        setModal(true);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const onCancel = () => {
    setModal(false);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleUpload = async () => {
    try {
      const data = await axios.get(
        `${S3_PREASSIGNED_POST_URL}?size=${globalSettings?.max_file_size}&fileExtension=${image.name.split('.').pop()}`
      );

      const formData = new FormData();
      Object.entries(data.data[0].fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", image);

      const uploadResponse = await axios.post('https://somadha.s3.amazonaws.com', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      

      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + props.url,
        {
          image: BUCKET_URL+ `${data.data[0].fields.Key}`,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      console.log(responseData);
      // Maybe check for response data
      if (responseData.status === 200) {
        props.profile && auth.setUser();
        onCancel();
      }
    } catch (err) {
      toast.error(`Max file upload size is ${globalSettings?.max_file_size}MB`);
    }
  };

  return (
    <div className="image__uploader">
      {isLoading && <LoadingSpinner asOverlay />}
      <button
        onClick={(event) => {
          fileInputRef.current.click();
        }}
        style={{ color: "var(--primary_color)" }}
        className="cstm-outline-btn "
      >
        <span>{props.title}</span>
      </button>
      <input
        type="file"
        className="d-none"
        ref={fileInputRef}
        accept="image/*"
        onChange={(event) => {
          const file = event.target.files[0];
          if (file && file.type.substr(0, 5) === "image") {
            setImage(file);
          } else {
            setImage(null);
          }
        }}
      />
      <Modal onCaonHidecel={onCancel} show={modal}>
        <Modal.Body>
          <div className="modal__confirmimg">
            <h2 className="fw-bold">Confirm Upload ?</h2>
            <img src={preview} alt="" />
            <div className="upload__btn">
              <button onClick={onCancel} className="btn__cancel">
                Cancel
              </button>
              <button onClick={handleUpload} className="btn__upload">
                Upload
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageUploader;
