import React from "react";
import "./Button.css";

const STYLES = [
  "btn--primary",
  "btn--outline",
  "btn--banner",
  "btn--auth",
  "btn--apply",
];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  isDisabled,
  color,
  spread,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  return (
    <div>
      <button
        className={`btn ${checkButtonStyle} ${color} ${spread && "spread"}`}
        disabled={isDisabled}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </div>
  );
};
