import React from "react";
import Modal from "./Modal";
import ModalButton from "../button/ModalButton";

const SuccessModal = (props) => {
  return (
    <Modal contentOnly onCancel={props.onClear} show={props.show}>
      <div className="error__modal">
        <img onClick={props.onClear} src="/assets/cross.svg" alt="" />
        <h2 className="success__header">Success!</h2>
        <div className="error__content">{props.message}</div>
        <div className="okay__btn">
          <ModalButton buttonStyle="btn--outline" onClick={props.onClear}>
            Okay
          </ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
