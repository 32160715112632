import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import AdminLayout from '../../../components/AdminLayout';
import Editor from '../../../components/Editor/Editor';
import Button from '../../../components/shared/Button/Button';
import AddEditFaqModal from '../../../components/modals/AddEditFaqModal/AddEditFaqModal';
import { CREATE_STATIC, DELETE_FAQ, GET_FAQS, GET_STATIC } from '../../../lib/api/admin';
import { Accordion } from "react-bootstrap";
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

const tabs = {
  0: "Term's & Condition",
  1: 'Privacy Policy',
  2: 'Payment Policy',
  3: 'About Us',
  4: 'FAQ',
  5: 'Consultation',
};

const StaticPages = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { data: staticData, refetch } = useQuery(['GET_STATIC'], GET_STATIC);
  const { data: faqsData, refetch: refetchFaq } = useQuery(['GET_FAQS'], GET_FAQS);
  const [staticStr, setStaticStr] = useState('');
  const { mutateAsync: createStaticMutation } = useMutation(CREATE_STATIC);
  const { mutateAsync: deleteFaqMutation } = useMutation(DELETE_FAQ);

  const getDefaultValue = useMemo(() => {
    switch (currentTab) {
      case 0:
        return staticData?.data?.terms;
      case 1:
        return staticData?.data?.privacy_policy;
      case 2:
        return staticData?.data?.payment_policy;
      case 3:
        return staticData?.data?.about_us;
      case 4:
        return staticData?.data?.faq;
      case 5:
        return staticData?.data?.consultation;
      default:
        break;
    }
  }, [currentTab, staticData]);

  const getPropertyName = useCallback(() => {
    switch (currentTab) {
      case 0:
        return 'terms';
      case 1:
        return 'privacy_policy';
      case 2:
        return 'payment_policy';
      case 3:
        return 'about_us';
      case 4:
        return 'faq';
      case 5:
        return 'consultation';
      default:
        break;
    }
  }, [currentTab]);

  const handleSaveStatic = useCallback(async () => {
    try {
      await createStaticMutation({
        [getPropertyName()]: staticStr,
      });
      await refetch();
      toast.success('Successfully updated static data!');
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  }, [createStaticMutation, getPropertyName, refetch, staticStr]);

  return (
    <AdminLayout>
      <h3 style={{ fontWeight: 'bold' }}>Static Pages</h3>
      <div className='adminTableView__filters'>
        {Object.keys(tabs).map((key) => (
          <Button
            key={key}
            variant={currentTab === Number(key) ? 'primary_outlined' : 'ghost_white'}
            onClick={() => setCurrentTab(Number(key))}
          >
            {tabs[key]}
          </Button>
        ))}
      </div>

      {currentTab !== 4 && (
        <>
          <Editor
            key={`Editor_${currentTab}`}
            defaultValue={getDefaultValue}
            onChange={(_, data) => setStaticStr(JSON.stringify(data))}
          />
          <Button variant='primary_outlined' onClick={handleSaveStatic}>
            Save
          </Button>
        </>
      )}
      
      {currentTab === 4 && (
        <>
          <AddEditFaqModal>
            <Button variant='primary_outlined' style={{
              marginBottom: "1rem"
            }}>
              Add New Question
            </Button>
          </AddEditFaqModal>
          <Accordion>
            {faqsData?.data?.map((faq, faqId) => (
              <Accordion.Item eventKey={faqId} key={faqId}>
                <Accordion.Header>
                  <span>
                    <AddEditFaqModal faq={faq}>
                      <button className="faq__btn"><FaPencilAlt /></button>
                    </AddEditFaqModal>
                    <button onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await deleteFaqMutation({ id: faq._id });
                      await refetchFaq();
                    }} className="faq__btn"><FaTrash /></button>
                  </span>
                  {faq.ques_en}
                </Accordion.Header>
                <Accordion.Body>
                  <Editor
                    defaultValue={faq?.ans_en}
                    readOnly
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </>
      )}
    </AdminLayout>
  );
};

export default StaticPages;
