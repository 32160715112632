import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./ExploreJob.css";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useHistory } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { useHttpClient } from "../../../hooks/http-hook";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../Pagination";
import ShowExpJobs from "./ShowExpJobs";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const ExploreJobNew = () => {
  const [allJobs, setAllJobs] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [selectedCat, setSelectedCat] = useState("all");
  const [searchKey, setSearchKey] = useState("");
  const [rangeValue, setRangeValue] = useState({
    value: { min: 0, max: 10000 },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const { category, key } = useParams();
  let history = useHistory();
  const auth = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [showBidPlace, setShowBidPlace] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { sendRequest, clearError } = useHttpClient();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [allSubCate, setAllSubCate] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState("");

  useEffect(() => {
    if (selectedCat === "") {
      setSelectedCat(category);
    }

    if (searchKey === "") {
      if (key === "all" || key === undefined || key === null) {
        setSearchKey("");
      } else {
        setSearchKey(key);
      }
    }
    getAllJobs();
  }, [selectedCat, searchKey, currentPage, selectedSubCat, rangeValue]);

  useEffect(() => {
    setAllSubCate([]);
    setSelectedSubCat("");
  }, [selectedCat]);

  useEffect(() => {
    getAllCategory();
    setSelectedCat(category);
    console.log(category);
  }, []);

  const getAllJobs = async () => {
    setSpinnerStatus(true);
    let url;

    let tempUrl =
      "https://somadha.com/api/admin/jobs?page=1&sort=%7B%22title%22:%22asc%22%7D&filter=%7B%22is_approved_by_admin%22:true%7D";

    if (selectedCat !== "all") {
      url =
        `${process.env.REACT_APP_BACKEND_URL}/jobs?` +
        `page=${currentPage}&category=${
          selectedSubCat !== "" ? selectedSubCat : selectedCat
        }&is_approved_by_admin=true`;

      if (selectedSubCat === "") {
        setAllSubCate([]);
        getAllSubCate(selectedCat);
      }
    } else {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/jobs?page=${currentPage}&sort=${JSON.stringify({ createdAt: -1 })}`;
      if (rangeValue.value.max - rangeValue.value.min > 0) {
        let newUrl = JSON.stringify({
          max_budget: {
            $gt: parseFloat(rangeValue.value.min),
            $lt: parseFloat(rangeValue.value.max),
          },
          is_approved_by_admin: "true",
        });
        url += "&filter=" + newUrl;
      }
    }
    if (searchKey !== "" && searchKey !== null && searchKey !== undefined) {
      url += `&search=${searchKey}`;
    }

    try {
      const response = await axios.get(url);
      setAllJobs(response);
      setCurrentPage(response.data.page);
      setSpinnerStatus(false);
    } catch (error) {
      setSpinnerStatus(false);
      notify("err", error);
    }
  };
  const getAllCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_parent_categories`
      );
      setAllCategory(response.data.data);
    } catch (error) {
      notify("err", error);
    }
  };

  const getAllSubCate = async (selectedcate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_subcategories?name=${selectedcate}`
      );
      if (response.status === 200) {
        setAllSubCate(response.data);
      } else {
        throw new Error("Something Went Wrong!");
      }
    } catch (error) {
      notify("err", error);
    }
  };

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [selectedJob, setSelectedJob] = useState({
    category: [],
    attachments: [],
  });
  const seeDetails = (job) => {
    handleShow();
    setSelectedJob(job);
  };

  const [claimedBudget, setClaimedBudget] = useState(0);
  const [bidType, setBidType] = useState("NORMAL");
  const [comment, setComment] = useState("");
  const [spinner, setSpinner] = useState(false);

  const submitBid = async () => {
    setSpinner(true);

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/provider/create_bid",
        "POST",
        JSON.stringify({
          job_id: selectedJob._id,
          claimed_budget: claimedBudget,
          bid_type: bidType,
          comment: comment,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        setShowBidPlace(false);
        setSpinner(false);
        notify("success", "Bid Submitted!");
        setClaimedBudget(0);
        setBidType("NORMAL");
        setComment("");
      } else {
        throw new Error("Something went wrong! Please Try Again later");
      }
    } catch (err) {
      setShowBidPlace(false);
      setSpinner(false);
      setClaimedBudget(0);
      setBidType("NORMAL");
      setComment("");
      notify("err", err);
    }
  };

  const optionTextStyle = {
    color: "var(--grey_one, #333)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  const inputStyle = {
    height: "50px",
    padding: "15px",
    borderRadius: "4px",
    background: "var(--WhiteBG, #FFF)",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    border: "none",
    cursor: "pointer",
    appearance: "none",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'><path fill='none' stroke='gray' stroke-width='2' d='M6 9l6 6 6-6'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 15px center",
  };

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <div
        className="py-3 px-3"
        style={{
          maxWidth: "1440px",
          margin: "auto",
        }}
      >
        <p
          className="hide-on-pc"
          style={{
            ...optionTextStyle,
            fontSize: "28px",
            marginBottom: "10px",
          }}
        >
          {auth.language === "en" ? " Job Posts" : "কাজের পোস্ট"}
        </p>
        <div className="my-3 d-flex justify-content-between align-items-start parent gap-3">
          <div className="right px-2">
            <label for="" style={optionTextStyle}>
              {auth.language === "en"
                ? "Select Category"
                : "বিভাগ নির্বাচন করুন"}
            </label>
            <select
              style={inputStyle}
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setSelectedCat(e.target.value)}
            >
              {" "}
              <option value="all">
                {" "}
                {auth.language === "en" ? "all" : "সব"}
              </option>
              {allCategory?.map(
                (c, idx) =>
                  !c.is_disabled && (
                    <option
                      value={c.name}
                      key={idx}
                      selected={selectedCat === c.name ? true : false}
                    >
                      {auth.language === "en" ? c?.name : c?.name_bn}
                    </option>
                  )
              )}
            </select>
            <label for="" style={optionTextStyle}>
              {auth.language === "en"
                ? "Select Sub-Categorys"
                : "সাব-ক্যাটাগরি নির্বাচন করুন "}
            </label>
            <select
              style={inputStyle}
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setSelectedSubCat(e.target.value)}
            >
              <option value="" selected>
                {auth.language === "en" ? "Not selected" : "অনির্বাচিত"}
              </option>
              {Array.isArray(allSubCate?.data) &&
                allSubCate?.data?.map(
                  (dt, idx) =>
                    !dt.is_disabled && (
                      <option key={idx} value={dt.name}>
                        {auth.language === "en" ? dt?.name : dt?.name_bn}
                      </option>
                    )
                )}
            </select>
            {/* <label for=''>
              {' '}
              {auth.language === 'en' ? 'Location' : 'অবস্থান'}
            </label>
            <input
              type='text'
              placeholder='search a location'
              onChange={(e) => setSearchKey(e.target.value)}
              style={{ paddingLeft: '20px' }}
            /> */}

            <div style={{ marginBottom: "20px" }}>
              <label for="" style={optionTextStyle}>
                {auth.language === "en" ? "Budget(Tk)" : "বাজেট (টাকা)"}
              </label>

              <div style={{ display: "flex", gap: "1rem" }}>
                <input
                  type="number"
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    background: "white",
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                    padding: "15px",
                  }}
                  value={rangeValue.value.min}
                  min={0}
                  onChange={(e) =>
                    setRangeValue({
                      value: { min: e.target.value, max: rangeValue.value.max },
                    })
                  }
                />
                <input
                  type="number"
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    background: "white",
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                    padding: "15px",
                  }}
                  value={rangeValue.value.max}
                  min={rangeValue.value.min}
                  onChange={(e) =>
                    setRangeValue({
                      value: { max: e.target.value, min: rangeValue.value.min },
                    })
                  }
                />
              </div>
            </div>

            <button
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFCECC",
                borderRadius: "4px",
                padding: "10px",
              }}
              onClick={() => {
                setSelectedCat("all");
                setSelectedSubCat("");
                setRangeValue({ value: { min: 0, max: 10000 } });
                setSearchKey("");
              }}
            >
              <img
                src="/assets/cross_red.svg"
                alt="Cross Icon"
                style={{
                  height: "16px",
                  width: "16px",
                  position: "absolute",
                  left: "20px",
                }}
              />
              <p
                style={{
                  ...optionTextStyle,
                  color: "#FF3B33",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Reset All Filters
              </p>
            </button>
          </div>
          <div>
            <p
              className="hide-on-mobile"
              style={{
                ...optionTextStyle,
                fontSize: "28px",
                marginBottom: "10px",
              }}
            >
              {auth.language === "en" ? " Job Posts" : "কাজের পোস্ট"}
            </p>
            <label htmlFor="" style={optionTextStyle}>
              {auth.language === "en"
                ? " Search a Job Post"
                : "একটি কাজের পোস্ট অনুসন্ধান করুন"}
            </label>
            <input
              type="text"
              style={{
                width: "100%",
                borderRadius: "4px",
                background: "white",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                padding: "15px",
              }}
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder={
                auth.language === "en"
                  ? "search with name, title etc."
                  : "নাম, শিরোনাম ইত্যাদি দিয়ে অনুসন্ধান করুন"
              }
            />

            <Spinner
              animation="border"
              role="status"
              style={{
                display: spinnerStatus ? "block" : "none",
                margin: "10rem auto",
              }}
            />

            <div className="my-5">
              {allJobs?.data?.data?.length > 0 && spinnerStatus === false
                ? allJobs.data?.data?.map((job, idx) => (
                    <ShowExpJobs ExpJobs={allJobs.data?.data} />
                  ))
                : spinnerStatus === false && (
                    <h2 className="p-5 m-5 text-center text-secondary">
                      No Jobs Found!
                    </h2>
                  )}
            </div>
            <Pagination
              itemsPerPage={10}
              totalItems={allJobs?.data?.total * 10}
              paginate={paginate}
              pageNumber={currentPage}
              style={{ backgroundColor: "var(--primary_color)" }}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <div
          className="p-4 d-flex justify-content-center  flex-column"
          style={{ color: "var(--grey_one)" }}
        >
          <h1 className="mb-3">{selectedJob?.title}</h1>
          <div className="mb-3 d-flex justify-content-start align-items-center">
            <img
              width="20px"
              height="20px"
              src="/assets/designing.png"
              alt=""
            />
            <p className="mx-2 my-0">{selectedJob?.category[0]}</p>
          </div>
          <div className="mb-3 d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex justify-content-between align-items-center">
              <img width="20px" height="20px" src="/assets/clock.png" alt="" />
              <p className="mx-2 my-0">
                {auth.language === "en" ? "Posted on " : "পোস্ট করা হয়েছে "}
                {new Date(selectedJob.createdAt).toLocaleDateString(
                  undefined,
                  dateOptions
                )}
              </p>
            </div>
            <p style={{ color: "var(--negetive_color)", margin: "0" }}>
              {auth.language === "en" ? "Deadline " : "সময়সীমা "}
              {new Date(selectedJob.deadline).toLocaleDateString(
                undefined,
                dateOptions
              )}{" "}
            </p>
          </div>
          <p
            className="my-3"
            style={{ color: "var(--grey_three)", textAlign: "justify" }}
          >
            {selectedJob.description}
          </p>

          <h3 className="">{auth.language === "en" ? "Budget" : "বাজেট"}</h3>
          <h2 className="my-3" style={{ fontWeight: "normal" }}>
            {auth.language === "en" ? "BDT " : "টাকা "}
            {selectedJob.max_budget}
          </h2>
          {selectedJob?.attachments[0] && (
            <a
              href={selectedJob?.attachments[0]}
              target="_blank"
              style={{
                backgroundColor: "var(--grey_six)",
                fontWeight: "bold",
                borderRadius: "4px",
                padding: "5px 20px",
              }}
              className="my-3 text-center "
            >
              {auth.language === "en" ? "Preview Attachment" : "সংযুক্তি"}
            </a>
          )}
          <button
            className="btn prime-btn w-100"
            onClick={() => {
              handleClose();
              setShowBidPlace(true);
            }}
            disabled={auth?.user?._id === selectedJob?.client_id ? true : false}
          >
            {auth.language === "en"
              ? "Apply for Bid "
              : "কাজের জন্য আবেদন করুন"}
          </button>
          {auth?.user?._id === selectedJob?.client_id && (
            <p className="text-danger">You can't bid on your own post</p>
          )}
        </div>
      </Modal>
      <Modal show={showBidPlace} onHide={() => setShowBidPlace(false)}>
        <div className="p-3">
          <label for="" className="mt-3">
            {auth.language === "en" ? "Claimed Budget" : "দাবি করা বাজেট"}
          </label>
          <input
            type="number"
            min={selectedJob.min_budget}
            name=""
            value={claimedBudget}
            onChange={(e) => setClaimedBudget(e.target.value)}
            required
          />{" "}
          <label className="mt-3">
            {" "}
            {auth.language === "en" ? "Bid Type" : "দরপত্রের ধরণ"}
          </label>
          <br />
          <select
            value={bidType}
            onChange={(e) => {
              setBidType(e.target.value);
            }}
            className="w-100"
            style={{
              border: "1px solid  #ABABAB",
              borderRadius: "4px",
              boxShadow: "none",
              cursor: "pointer",
            }}
          >
            <option value="NORMAL" selected>
              {auth.language === "en" ? "NORMAL" : "স্বাভাবিক"}
            </option>
            <option value="PRINT">
              {auth.language === "en" ? "PRINT" : "ছাপা"}
            </option>
          </select>
          <br />
          <label> {auth.language === "en" ? "Comment" : "মন্তব্য করুন"}</label>
          <br />
          <textarea
            placeholder={
              auth.language === "en"
                ? "Anything that you want to know"
                : "আপনি যা জানতে চান"
            }
            rows="5"
            className="textareaa"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          ></textarea>
          <div className="d-flex ">
            <button
              className="btn w-100 me-1"
              style={{ backgroundColor: "var(--negetive_light_color)" }}
              onClick={() => setShowBidPlace(false)}
            >
              {auth.language === "en" ? "Cancel" : "বাতিল করুন"}
            </button>
            <button
              className="btn prime-btn w-100 ms-1"
              onClick={() => submitBid()}
            >
              {auth.language === "en" ? "Confirm" : "নিশ্চিত করুন"}
              {spinner && (
                <Spinner animation="border" size="sm">
                  <span className="visually-hidden" />
                </Spinner>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExploreJobNew;
