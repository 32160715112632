import React from "react";
import BidDetails from "../sections/Bid/BidDetails";

const Bid = () => {
  return (
    <div>
      <BidDetails />
    </div>
  );
};

export default Bid;
