import { useForm } from 'react-hook-form';
import Button from '../shared/Button/Button';
import Input from '../shared/Input/Input';
import React from 'react';
import { GET_PAYMENT_PROVIDERS } from '../../lib/api/admin';
import { deleteConfirmation } from '../../lib/sweetAlert';
import { useQuery } from 'react-query';

const INITIAL_FORM_VALUES = {
  payment_method: 'BKASH',
  personal_number: '',
  transaction_id: '',
  // sent_from: "",
};
export default function ConfirmPayment({ onSubmit }) {
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: INITIAL_FORM_VALUES,
  });
  const { data: paymentProviders } = useQuery(
    'GET_PAYMENT_PROVIDERS',
    GET_PAYMENT_PROVIDERS,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        setValue('payment_method', data?.data?.[0]?.name);
      },
    }
  );


  return (
    <div className='confirmPayment'>
      {/* <h3 className="confirmPayment__title">Confirm Payment</h3> */}

      <div className='confirmPayment__methods'>
        {paymentProviders?.data?.data?.map((item, ind) => (
          <label key={item._id}>
            <input
              type='radio'
              value={item.name}
              onChange={({ target }) => {
                setValue('payment_method', target.value);
              }}
              defaultChecked={ind === 0}
              name='method'
              id='payment_method'
            />{' '}
            <img
              src={process.env.REACT_APP_BACKEND_URL + '/' + item.logo}
              alt=''
            />
          </label>
        ))}
      </div>

      <form className='confirmPayment__form' onSubmit={handleSubmit(onSubmit)}>
        <p>
          Please complete your{' '}
          <strong className='text-primary'>{watch('payment_method')}</strong>{' '}
          payment to{' '}
          <strong className='text-primary'>
            {
              paymentProviders?.data?.data?.find(
                (i) => i.name === watch('payment_method')
              )?.number
            }
          </strong>
          , then fill up the from below
        </p>
        <Input label='Your personal number' {...register('personal_number')} />
        <Input
          label={`${watch('payment_method')} Transaction Id`}
          {...register('transaction_id')}
        />
        {/* <Input
					label={`${watch("payment_method")} number(Sent From)*`}
					{...register("sent_from")}
				/> */}
        <Button variant='primary' type='submit'>
          Submit
        </Button>
      </form>
    </div>
  );
}
