import React from 'react';
import "./Button.css";

export default function Button({ variant, fluid, children, ...rest }) {
  const styles = {
    ...fluid?{ width: "100%" }:{}
  }

  switch (variant) {
    case "primary":
      return <button className="button button-primary" type="button" style={styles} {...rest}>{children}</button>
    case "primary_outlined":
      return <button className="button button-primary-outlined" type="button" style={styles} {...rest}>{children}</button>
    case "ghost_white":
      return <button className="button button-ghost-white" type="button" style={styles} {...rest}>{children}</button>
    default:
      return <button type="button" {...rest}>{children}</button>
  }
}