// src/components/HomeComponent/Consultants/ConsultantCard.js

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
// import "./ConsultantCard.css"; // Uncomment if you have specific styles

const containerStyle = {
  backgroundColor: "var(--WhiteBG, #FFF)",
  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "20px",
};

const profilePicStyle = {
  borderRadius: "4px",
  objectFit: "cover",
};

const headingStyle = {
  color: "var(--grey_one, #333)",
  fontFamily: "Nunito",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  margin: "0",
};

const ProfileContainerStyle = {
  display: "flex",
  alignItems: "start",
  gap: "10px",
};

const StatContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "14px",
};

const TextStyle = {
  color: "var(--grey_two, #666)",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "0",
  paddingRight: "7px",
};

const ValueStyle = {
  color: "var(--grey_one, #333)",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "0",
  paddingRight: "5px",
  wordBreak: "break-all",
};

const taglineStyle = {
  color: "var(--grey_two, #666)",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

const buttonStyle = {
  width: "100%",
  borderRadius: "4px",
  padding: "10px",
  backgroundColor: "#2A41E8",
  border: "none",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const buttonTextStyle = {
  color: "var(--WhiteBG, #FFF)",
  textAlign: "center",
  fontFamily: "Nunito",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
};

const ConsultantCard = ({ consultant }) => {
  const auth = useContext(AuthContext);

  return (
    <div style={containerStyle}>
      <div style={ProfileContainerStyle}>
        <img
          src={consultant.profile_pic || "/assets/default-profile.png"}
          alt="Profile"
          width="110px"
          height="110px"
          style={profilePicStyle}
        />
        <div style={StatContainerStyle}>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            <p style={TextStyle}>
              {auth.language === "en" ? "Provider Rating" : "প্রোভাইডার রেটিং"}
            </p>
            <div className="d-flex align-items-center justify-content-start">
              <p style={ValueStyle}>
                {consultant.provider_rating
                  ? consultant.provider_rating.toFixed(2)
                  : "0.00"}{" "}
              </p>
              <img src="/assets/rater-start.png" alt="Rating" className="" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            <p style={TextStyle}>
              {auth.language === "en" ? "Client Rating" : "ক্লায়েন্ট রেটিং"}
            </p>
            <div className="d-flex align-items-center justify-content-start">
              <p style={ValueStyle}>
                {consultant.client_rating
                  ? consultant.client_rating.toFixed(2)
                  : "0.00"}{" "}
              </p>
              <img src="/assets/rater-start.png" alt="Rating" className="" />
            </div>
          </div>
          <p style={ValueStyle}>
            <img src="/assets/location.png" alt="Location" />{" "}
            {consultant.location || "No location available"}
          </p>
        </div>
      </div>
      <div>
        <p style={headingStyle}>{consultant.fullname || "No Name"}</p>
        <p className="mb-2" style={taglineStyle}>
          {consultant.tagline || "No tagline available"}
        </p>
      </div>
      <Link to={`/profile/${consultant._id}`}>
        <button style={buttonStyle}>
          <span style={buttonTextStyle}>
            {auth.language === "en" ? "View Profile" : "বৃত্তান্ত দেখুন"}
          </span>
        </button>
      </Link>
    </div>
  );
};

export default ConsultantCard;
