import React, { useCallback,useContext, useEffect, useState } from 'react';
import { CgFeed } from "react-icons/cg";
import { FaBalanceScale, FaBug, FaCogs, FaGavel, FaHandshake, FaHistory, FaMagic, FaMoneyCheck, FaPager, FaQuestionCircle, FaThLarge, FaUikit, FaUser, FaUsers } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import "./AdminSidebar.css";
import { AuthContext } from '../../../context/auth-context';

export default function AdminSidebar() {
  const { pathname } = useLocation();
  const auth = useContext(AuthContext);
  const [role,setRole]=useState('');
  const isActiveSidebarItem = useCallback((link) => {
    return pathname === `/admin/${link}`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
	setRole(auth.user.user_type);
  },[auth])

//   console.log(auth);
  return (
    <div className="adminSidebar">
      <div className="adminSidebar__wrapper">
        <div className="adminSidebar__innerWrapper">
          <h3 className="adminSidebar__title">Administration Panel Home</h3>

          <ul className="adminSidebar__list">
            {role==="ADMIN" && adminDashboardLinks.map(({icon: Icon, ...link}, ind) => (
              <li className={`adminSidebar__item ${isActiveSidebarItem(link.link) ? "active" : ""}`} key={ind}>
                <Link to={`/admin/${link.link}`}>
                  {/* <img src="/assets/new/logout-icon.svg" alt="" /> {link.name} */}
                  <Icon /> {link.name}
                </Link>
              </li>
            ))}
            {role!=="ADMIN" && managerDashboardLinks.map(({icon: Icon, ...link}, ind) => (
              <li className={`adminSidebar__item ${isActiveSidebarItem(link.link) ? "active" : ""}`} key={ind}>
                <Link to={`/admin/${link.link}`}>
                  {/* <img src="/assets/new/logout-icon.svg" alt="" /> {link.name} */}
                  <Icon /> {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const adminDashboardLinks = [
	{
		name: "Manage Agents",
		link: "manage-agents",
		icon: FaUsers,
	},
	{
		name: "Manage Admins",
		link: "manage-admins",
		icon: FaUsers,
	},
	{
		name: "Jobs",
		link: "jobs",
		icon: FaBalanceScale,
	},
	{
		name: "Global Settings",
		link: "global-settings",
		icon: FaCogs,
	},
	{
		name: "System Report",
		link: "system-report",
		icon: FaBug,
	},
	{
		name: "System Logs",
		link: "system-logs",
		icon: FaHistory,
	},
	{
		name: "Due Payment",
		link: "due-payment",
		icon: FaMoneyCheck,
	},
	{
		name: "Client Payments",
		link: "client-payments",
		icon: FaMoneyCheck,
	},
	{
		name: "Job Categories",
		link: "job-categories",
		icon: FaThLarge,
	},
	{
		name: "Issues",
		link: "unresolved-issues",
		icon: FaQuestionCircle,
	},
	{
		name: "View reports/feedbacks",
		link: "feedbacks",
		icon: CgFeed,
	},
	{
		name: "Users",
		link: "users",
		icon: FaUser,
	},
	{
		name: "Promotions",
		link: "add-promotions",
		icon: FaMagic,
	},
	{
		name: "Referrals",
		link: "refers",
		icon: FaMagic,
	},
	{
		name: "Bids",
		link: "bids",
		icon: FaGavel,
	},
	{
		name: "Deals",
		link: "deals",
		icon: FaHandshake,
	},
	{
		name: "Static Pages",
		link: "static-pages",
		icon: FaPager,
	},
	{
		name: "UI Configurations",
		link: "ui-configurations",
		icon: FaUikit,
	},
];



const managerDashboardLinks = [
	{
		name: "Jobs",
		link: "unapproved-jobs",
		icon: FaBalanceScale,
	},
	{
		name: "System Report",
		link: "system-report",
		icon: FaBug,
	},
	{
		name: "System Logs",
		link: "system-logs",
		icon: FaHistory,
	},
	{
		name: "Issues",
		link: "unresolved-issues",
		icon: FaQuestionCircle,
	},
	{
		name: "View reports/feedbacks",
		link: "feedbacks",
		icon: CgFeed,
	},
	{
		name: "Failed Bids",
		link: "failed-bids",
		icon: FaGavel,
	},
	{
		name: "Deals",
		link: "failed-deals",
		icon: FaHandshake,
	},
	{
		name: "Static Pages",
		link: "static-pages",
		icon: FaPager,
	},
];
