import React, { useState, useEffect, useContext } from "react";
import BiddingCard from "./BiddingCard";
import ClientCard from "./ClientCard";
import "./JobDetails.css";
import { ToggleButton } from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import { useHttpClient } from "../../../hooks/http-hook";
import LoadingSpinner from "../../../components/util/LoadingSpinner";
import ErrorModal from "../../../components/util/ErrorModal";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import { Link, useHistory } from "react-router-dom";

const JobDetails = (props) => {
  const { isLoading, error, clearError, sendRequest } = useHttpClient();
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { jobID } = useParams();

  const [loadedJob, setLoadedJob] = useState();
  const [refresh, setRefresh] = useState(false);
  const onSuccess = () => {
    setRefresh(!refresh);
  };
  useEffect(() => {
    fetchJob();
  }, [jobID]);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const fetchJob = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${
          props.deleted ? "/admin/get_deleted_job/" : "/user/get_job/"
        }?job_id=${jobID}`
      );
      setLoadedJob(responseData);
    } catch (err) {}
  };

  const upvote = async () => {
    if (!loadedJob?.upvote.includes(auth.user._id)) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/upvote/?job_id=${loadedJob?._id}`,
          "PUT",
          JSON.stringify({}),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.success === "yes") {
          onSuccess();
        }
      } catch (err) {}
    }
  };
  const downvote = async () => {
    if (!loadedJob?.downvote.includes(auth.user._id)) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/downvote/?job_id=${loadedJob?._id}`,
          "PUT",
          JSON.stringify({}),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.success === "yes") {
          onSuccess();
        }
      } catch (err) {}
    }
  };

  const [likeSelected, setLikeSelected] = React.useState(false);
  const [dislikeSelected, setDislikeSelected] = React.useState(false);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div>
        {isLoading && <LoadingSpinner asOverlay />}
        {!isLoading && loadedJob && (
          <div>
            <div id="topBanner">
              <div id="bannerContent">
                <h3>{loadedJob?.title}</h3>
                <div className="row">
                  <div
                    className="col-lg-3"
                    style={{ backgroundColor: "", marginRight: "" }}
                  >
                    <p>
                      <i className="fab fa-gg-circle"></i>{" "}
                      {loadedJob?.parent_category} {loadedJob?.category}
                    </p>
                  </div>
                  <div
                    className="col-lg-4"
                    style={{ backgroundColor: "", marginRight: "" }}
                  >
                    <p>
                      <i className="far fa-clock"></i> Posted On{" "}
                      {" " +
                        new Date(loadedJob?.createdAt).toLocaleDateString(
                          undefined,
                          dateOptions
                        )}
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <p>
                      <i className="fas fa-map-marker-alt"></i> ??{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row profileBody">
              <div className="col-lg-7 col-md-10" style={{ marginLeft: "5%" }}>
                <div style={{ marginBottom: "60px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>
                      Job Post Description
                    </span>
                  </h5>

                  <p
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    {loadedJob?.description}
                  </p>
                </div>

                <div style={{ marginBottom: "60px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Attachments</span>
                  </h5>
                  {loadedJob?.attachments &&
                    loadedJob?.attachments.length === 0 && (
                      <p className="p6">No attachments available</p>
                    )}
                </div>

                <div style={{ marginBottom: "60px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Feedback</span>
                  </h5>

                  {auth.isLoggedIn && !loadedJob?.approval && (
                    <div className="vote--container">
                      <div className="vote--item">
                        <span>
                          {loadedJob?.upvote && loadedJob?.upvote.length}
                        </span>
                        <img
                          onClick={upvote}
                          src={
                            auth.user &&
                            loadedJob?.upvote &&
                            loadedJob?.upvote.includes(auth.user._id)
                              ? "/assets/liked.svg"
                              : "/assets/like.svg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="vote--item">
                        <span>
                          {loadedJob?.downvote && loadedJob?.downvote.length}
                        </span>
                        <img
                          onClick={downvote}
                          src={
                            auth.user &&
                            loadedJob?.downvote &&
                            loadedJob?.downvote.includes(auth.user._id)
                              ? "/assets/disliked.svg"
                              : "/assets/dislike.svg"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-md-10 clientContent">
                <div>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Client Details</span>
                  </h5>
                  <ClientCard
                    clientId={loadedJob?.client_id?._id}
                    clientName={loadedJob?.client_id?.fullname}
                    cilentProfilePic={loadedJob?.client_id?.profile_pic}
                    clientRating={loadedJob?.client_id?.client_rating}
                  />
                </div>

                <div style={{ marginTop: "50px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Bidding</span>
                  </h5>
                  <BiddingCard
                    serviceDeadline=""
                    printDeadline=""
                    budget={loadedJob?.max_budget}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
