import React, { useState } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
const Report = () => {
  const [report, setReport] = useState(null);

  const [days, setDays] = useState('');
  const { isLoading, sendRequest } = useHttpClient();

  const fetchReport = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/system_report/?days=${days}`
      );
      setReport(responseData);
    } catch (err) {}
  };

  //eslint-disable-next-line

  return (
    <div className='manage__container'>
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>System Report</h3>
      <div className='manage__box pink'>
        <p className='pbox'>Explaining what this feature is.</p>

        <div className='report__box'>
          <div className='report__head'>
            <span>Generate Report For</span>
            <input
              className='report_inp'
              type='text'
              placeholder='Last ** Days?'
              value={days}
              onChange={(e) => {
                setDays(e.target.value);
              }}
            />
            <button className='search__btn' onClick={fetchReport}>
              <img src='/assets/right_arrow.svg' alt='' />
            </button>
          </div>
          {report && (
            <div className='report__body'>
              <p className='report_header'>
                System Report for Last {days} Days
              </p>

              <table>
                <tr>
                  <td className='report_td_left'>Jobs Posted</td>
                  <td>{report.no_of_jobs}</td>
                </tr>
                <tr>
                  <td className='report_td_left'>Bids Placed</td>
                  <td>{report.no_of_total_bids}</td>
                </tr>
                <tr>
                  <td className='report_td_left'>Deals Created</td>
                  <td>{report.no_of_deals}</td>
                </tr>
                <tr>
                  <td className='report_td_left'>Money Dealt With</td>
                  <td>{report.amount_of_money_dealt} BDT</td>
                </tr>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
