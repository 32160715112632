import React from 'react';
import AdminLayout from '../../../components/AdminLayout';
import SystemReportView from '../../../components/AdminLayout/SystemReportView/SystemReportView';

export default function SystemReport() {
  return (
    <AdminLayout>
      <SystemReportView />
    </AdminLayout>
  )
}