import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import AddEditPromotionModal from "../../../components/modals/AddEditPromotionModal/AddEditPromotionModal";
import { GET_PROMOTIONS } from "../../../lib/api/admin";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AddPromotions() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState({});
  const [filter, setFilter] = useState({});
  const [bulkSmsForm, setBulkSmsForm] = useState(false);
  const [openPromotionForm, setOpenPromotionForm] = useState(false);
  const {
    data: promotions,
    status,
    error,
  } = useQuery(
    [
      "GET_PROMOTIONS",
      {
        page,
        sort: JSON.stringify(sort),
        filter: JSON.stringify(filter),
        ...(search ? { search: search } : {}),
      },
    ],
    GET_PROMOTIONS
  );

  return (
    <AdminLayout>
      <AddEditPromotionModal
        open={openPromotionForm}
        bulkSmsForm={bulkSmsForm}
        setBulkSmsForm={setBulkSmsForm}
        toggle={() => setOpenPromotionForm(!openPromotionForm)}
      />

      <AdminTableView
        title="Promotions"
        table_name="Promotion"
        query={{ status, error }}
        deleteOpt={true}
        search={{
          title: "Search Promotion",
          placeholder: "Search by target user...",
          btnText: "Search",
          onSearch: (value) => setSearch(value),
        }}
        filters={[
          {
            name: "Active Promotions",
            value: "RUNNING",
            active: filter.state === "RUNNING",
            onClick: (value) =>
              setFilter((values) => ({ ...values, state: value })),
          },
          {
            name: "Expired Promotions",
            value: "ENDED",
            active: filter.state === "ENDED",
            onClick: (value) =>
              setFilter((values) => ({ ...values, state: value })),
          },
          {
            name: "Upcoming Promotions",
            value: "PENDING",
            active: filter.state === "PENDING",
            onClick: (value) =>
              setFilter((values) => ({ ...values, state: value })),
          },
        ]}
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Target User (DESC)", value: "target_user:desc" },
                { label: "Target User (ASC)", value: "target_user:asc" },
                { label: "Start Date (DESC)", value: "start_date:desc" },
                { label: "Start Date (ASC)", value: "start_date:asc" },
                { label: "End Date (DESC)", value: "end_date:desc" },
                { label: "End Date (ASC)", value: "end_date:asc" },
                {
                  label: "Minimum deal price (DESC)",
                  value: "target_amount:desc",
                },
                {
                  label: "Minimum deal price (ASC)",
                  value: "target_amount:asc",
                },
                { label: "Reward Credit (DESC)", value: "offered_point:desc" },
                { label: "Reward Credit (ASC)", value: "offered_point:asc" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Target User", dotsMenu: [], key: "target_user" },
            { name: "Start Date", dotsMenu: [], key: "start_date" },
            { name: "End Date", dotsMenu: [], key: "end_date" },
            { name: "Minimum Deal Price", dotsMenu: [], key: "target_amount" },
            { name: "Reward Credit", dotsMenu: [], key: "offered_point" },
            {
              name: "Actions",
              dotsMenu: [{ name: "Delete" }],
              key: "actions",
            },
          ],
          data: promotions?.data?.data.map((promo) => ({
            _id: promo?._id,
            target_user: promo?.target_user || "N/A",
            state: promo?.state || "N/A",
            start_date: moment(promo?.start_date).format("YYYY-MM-DD") || "N/A",
            end_date: moment(promo?.start_date).format("YYYY-MM-DD") || "N/A",
            target_amount: String(promo?.target_amount) || "N/A",
            offered_point: String(promo?.offered_point) || "N/A",
            // actions: ""
          })),
          pagination: { total: promotions?.data?.total, page, setPage },
        }}
      />
    </AdminLayout>
  );
}
