import CheckList from "@editorjs/checklist";
import Code from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import { default as ImageTool } from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Table from "@editorjs/table";
import Warning from "@editorjs/warning";
import ColorPlugin from "editorjs-text-color-plugin";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import EditorJs from "react-editor-js";
import { http } from "../../lib/axios";
import axios from "axios";
import {
  BUCKET_URL,
  S3_PREASSIGNED_POST_URL,
  CLOUDFRONT_URL,
} from "../../config";
import { toast } from "react-toastify";

export default function Editor({ onChange, defaultValue, readOnly }) {
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  const EDITOR_JS_TOOLS = {
    Color: {
      class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
      config: {
        colorCollections: [
          "#EC7878",
          "#9C27B0",
          "#673AB7",
          "#3F51B5",
          "#0070FF",
          "#03A9F4",
          "#00BCD4",
          "#4CAF50",
          "#8BC34A",
          "#CDDC39",
          "#FFF",
        ],
        defaultColor: "#333333",
        type: "text",
      },
    },
    Marker: {
      class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
      config: {
        defaultColor: "#FFBF00",
        type: "marker",
      },
    },
    Embed: Embed,
    Table: Table,
    Marker: Marker,
    List: List,
    Warning: Warning,
    Code: Code,
    LinkTool: LinkTool,
    Raw: Raw,
    Header: Header,
    Quote: Quote,
    Checklist: CheckList,
    Celimiter: Delimiter,
    InlineCode: InlineCode,
    IimpleImage: SimpleImage,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          async uploadByFile(file) {
            const { data } = await axios.get(
              S3_PREASSIGNED_POST_URL +
                `?size=5&fileExtension=${file.name.split(".").pop()}`
            );

            const formData = new FormData();

            console.log(data);
            Object.entries(data[0].fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", file);

            await axios.post(BUCKET_URL, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });

            const uploadedUrl = CLOUDFRONT_URL + `${data[0].fields.Key}`;

            return {
              success: "1",
              file: { url: uploadedUrl },
            };
          },
        },
      },
    },
  };

  return (
    <div className="editorWrapper">
      <EditorJs
        key={defaultValue}
        tools={EDITOR_JS_TOOLS}
        onChange={onChange}
        readOnly={readOnly}
        data={JSON.parse(defaultValue || JSON.stringify({}))}
      />
    </div>
  );
}
