import React from "react";
import "./Tooltip.css";
const Tooltip = (props) => {
  return (
    <div className="tooltip">
      {props.children}
      <span className="tooltiptext" style={{ width: props.width }}>
        {props.tip}
      </span>
    </div>
  );
};

export default Tooltip;
