import React from 'react';
import './Thread.css';

const Thread = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`thread__item ${props.selected && 'thread__selected'}`}
    >
      <img
        className='icon'
        src={props.image}
        alt=''
        style={{ height: '40px', width: '40px', borderRadius: '30px' }}
      />
      <div className=''>
        <h4 className='ms-2 d-flex justify-content-start align-items-center'>
          <span style={{color:"black",marginLeft:"10px"}}>{props.name}</span>
        </h4>
      </div>
      {props.newMessage && (
        <div className='newmessage__status'>
          <img className='dot' src='/assets/chat_circle.png' alt='' />
        </div>
      )}
    </div>
  );
};

export default Thread;
