import React, { useContext, useState, useEffect } from "react";
import "./Catalogs.css";
import { Link } from "react-router-dom";
import axios from "axios";
import CatalogCard from "../../components/catalog/CatalogCard";
import Texts from "../../util/Texts";
import { AuthContext } from "../../context/auth-context";

const Catalogs = () => {
  const auth = useContext(AuthContext);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);
  const texts = Texts();
  return (
    <div className="catalog__cards">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <p
          style={{
            color: "var(--text)",
            fontSize: "28px",
            fontWeight: "normal",
          }}
        >
          {texts.exploreCatalogs}
        </p>
        <p className="p_right">
          <Link to="/explore/all">
            <span
              style={{
                color: "var(--primary_color)",
                fontSize: "24px",
                fontWeight: "normal",
              }}
            >
              {auth.language === "en" ? (
                <>
                  Browse all categories{" "}
                  <img src="/assets/newIcons/rightArrow.svg" alt="arrow" />
                </>
              ) : (
                <>
                  সব বিভাগ খুঁজুন{" "}
                  <img src="/assets/newIcons/rightArrow.svg" alt="arrow" />
                </>
              )}
              {/* <i class="fas fa-arrow-right"></i> */}
            </span>
          </Link>
        </p>
      </div>

      <div className="cards--container">
        {auth.categories &&
          auth.categories.data
            .filter((catalog) => catalog.is_disabled === false)
            .sort((a, b) => a.serial - b.serial)
            .slice(0, 8)
            .map((catalog, index) => {
              if (index + 1 > globalSettings?.max_category_on_homepage)
                return null;
              return (
                <CatalogCard
                  key={index}
                  to={
                    catalog.name == "consultation"
                      ? `/${catalog.name.toLowerCase()}`
                      : `/explore/${catalog.name.toLowerCase()}`
                  }
                  text={
                    auth.language === "en"
                      ? catalog?.name
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")
                      : catalog?.name_bn
                  }
                  imgSource={catalog.logo && `${catalog.logo}`}
                  jobs_posted={catalog.jobs_posted}
                />
              );
            })}
      </div>
    </div>
  );
};

export default Catalogs;
