import React, { useContext, useEffect, useState } from "react";
import LoginForm from "../../components/authentication/LoginForm";
import SignUpForm from "../../components/authentication/SignUpForm";
import ResetPasswordForm from "../../components/authentication/ResetPasswordForm";
import "./AuthSection.css";
import ResetPassword from "../../components/authentication/ResetPassword";
import { AuthContext } from "../../context/auth-context";
import SetNewPassword from "../../components/authentication/SetNewPassword";
import axios from "axios";
import VerifyEmail from "../../components/VerifyEmail";

const VARIANT = ["login", "signup", "reset", "confirm", "set_new","otpVerification"];

const  AuthSection = (props) => {
  const auth = useContext(AuthContext);
  let render;

  const [url, setUrl] = useState();

  const globals = async () => {
    try {
      const res = await axios.get("https://somadha.com/api/ui-config", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });

      if (props.variant === "signup")
        setUrl(res?.data?.auth_background?.register_background_image);
      else setUrl(res?.data?.auth_background?.login_background_image);
      console.log(props.variant);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    globals();
    window.scrollTo(0, 0);
  }, []);

  // Define imageUrl here based on props.variant
  let imageUrl;
  switch (props.variant) {
    case VARIANT[1]: {
      render = <SignUpForm />;
      imageUrl =
        auth.uiConfig && auth.uiConfig.signup_background_image
          ? `${auth.uiConfig.signup_background_image}`
          : "/assets/auth.jpg";
      break;
    }
    case VARIANT[2]: {
      render = <ResetPasswordForm />;
      imageUrl =
        auth.uiConfig && auth.uiConfig.login_background_image
          ? `${auth.uiConfig.login_background_image}`
          : "/assets/auth.jpg";
      break;
    }
    case VARIANT[3]: {
      render = <ResetPassword />;
      imageUrl =
        auth.uiConfig && auth.uiConfig.login_background_image
          ? `${auth.uiConfig.login_background_image}`
          : "/assets/auth.jpg";
      break;
    }
    case VARIANT[4]: {
      render = <SetNewPassword />;
      imageUrl =
        auth.uiConfig && auth.uiConfig.login_background_image
          ? `${auth.uiConfig.login_background_image}`
          : "/assets/auth.jpg";
      break;
    }
    case VARIANT[5]: {
      render = <VerifyEmail />;
      imageUrl =
        auth.uiConfig && auth.uiConfig.login_background_image
          ? `${auth.uiConfig.login_background_image}`
          : "/assets/auth.jpg";
      break;
    }
    default: {
      render = <LoginForm setupSocket={props.setupSocket} />;
      imageUrl =
        auth.uiConfig && auth.uiConfig.login_background_image
          ? `${auth.uiConfig.login_background_image}`
          : "/assets/auth.jpg";
    }
  }

  return (
    <div>
      <div
        className="auth--container"
        style={{ position: "relative", border: "1px solid transparent" }}
      >
        <img
          src={url}
          alt=""
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "-1",
            height: "100%",
            width: "100%",
          }}
        />
        {render}
      </div>
    </div>
  );
};

export default AuthSection;
