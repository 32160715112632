import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GET_PAYMENT_CALCULATION,
  GET_PAYMENT_PROVIDERS,
  PAY_FOR_DEAL,
  PAY_PROVIDER,
} from "../../../lib/api/admin";
import ConfirmPayment from "../../payment/ConfirmPayment";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import Modal from "../../shared/Modal/Modal";
import Select from "../../shared/Select/Select";
import Alert from "react-bootstrap/Alert";

export default function PayForDealModal({
  children,
  job,
  printBidId,
  bidId,
  refetch,
  type,
  deal,
}) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  // const queryClient = useQueryClient();
  const { mutateAsync: payForDealMutation } = useMutation(PAY_FOR_DEAL);
  const { data: paymentProviders } = useQuery(
    ["GET_PAYMENT_PROVIDERS", {}],
    GET_PAYMENT_PROVIDERS,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: paymentCalculation } = useQuery(
    [
      "GET_PAYMENT_CALCULATION",
      {
        bid_id: bidId,
        ...(printBidId ? { print_bid_id: printBidId } : {}),
      },
    ],
    GET_PAYMENT_CALCULATION,
    {
      refetchOnWindowFocus: false,
    }
  );
  // const { handleSubmit, register } = useForm({});

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await payForDealMutation({
          mobile_no: values.personal_number,
          transaction_id: values.transaction_id,
          job_id: job._id,
          bid_id: bidId,
          ...(printBidId ? { print_bid_id: printBidId } : {}),
          ...(type ? { type: type } : {}),
          ...(deal ? { deal_id: deal._id } : {}),
          method: paymentProviders?.data?.data?.find(
            (i) => i.name === values.payment_method
          )?._id,
        });
        toast.success("Payment successful");
        if (typeof refetch === "function") {
          await refetch?.();
        } else {
          history.push("/user/deals");
        }
        setOpen(false);
      } catch (err) {
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [
      type,
      deal,
      payForDealMutation,
      job,
      bidId,
      printBidId,
      paymentProviders,
      refetch,
      history,
    ]
  );

  return (
    <Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })}
      <Modal open={open} onClose={setOpen} title="Making Payment">
        <div className="makePaymentForDueAmountModal">
          <div
            style={{ display: "flex", flexDirection: "column", gap: ".4rem" }}
          >
            <Alert variant={"danger"}>
              <h5 style={{ color: "red" }}>
                You must pay {paymentCalculation?.data?.initiate_deal_payment}{" "}
                taka to proceed further with this deal.
              </h5>
            </Alert>

            <h3>Bid</h3>
            <h5>
              <b>Total Payable: </b>
              {paymentCalculation?.data?.bid_payment
                ?.total_payable_amount_client || 0}
            </h5>
            <h5>
              <b>Client Charge: </b>
              {paymentCalculation?.data?.bid_payment?.charge_for_client || 0}
            </h5>
            {/* 
            <h5>
              <b>Print Bid Payment: </b>
              {paymentCalculation?.data?.print_bid_payment || 0}
            </h5> */}
          </div>
          <br />
          {paymentCalculation?.data?.print_bid_payment && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".4rem",
              }}
            >
              <h3>Print Bid</h3>
              <h5>
                <b>Total Payable: </b>
                {
                  paymentCalculation?.data?.print_bid_payment
                    ?.total_payable_amount_client
                }
              </h5>
              <h5>
                <b>Client Charge: </b>
                {paymentCalculation?.data?.print_bid_payment?.charge_for_client}
              </h5>
            </div>
          )}
          <br />
          <ConfirmPayment onSubmit={handleSubmit} />
        </div>
      </Modal>
    </Fragment>
  );
}
