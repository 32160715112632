import React, { useContext, Fragment, useEffect, useState } from "react";
import "./ClientPrintDealDetails.css";
import Dropdown from "../Dropdown/Dropdown";
import RateUser from "../modals/Profile/RateUser";
import ReportIssue from "../modals/Profile/ReportIssue";
import SuccessModal from "../util/SuccessModal";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import RateModal from "../../components/modals/RateModal/RateModal";
import Button from "../../components/shared/Button/Button";

export default function ClientDetailsDealDetails(props) {
  const [showRateModal, setShowRateModal] = useState(false);
  const [showRateSuccess, setShowRateSuccess] = useState(false);
  const [showIssueSubmitModal, setShowIssueSubmitModal] = useState(false);
  const [rePortIssueModal, setRePortIssueModal] = useState(false);
  const [id, setId] = useState([]);
  const auth = useContext(AuthContext);
  const { deal } = props;

  const onCancel = () => {
    setShowIssueSubmitModal(false);
  };

  const onSuccess = () => {
    setRePortIssueModal(true);
  };
  useEffect(() => {
    setId([props?.deal?.client_id?._id, props?.deal?.provider_id?._id]);
    console.log("data", props);
  }, [props]);
  return (
    <Fragment>
      <RateUser
        show={showRateModal}
        clientId={props.property?.client_id?._id}
        providerId={props.property?.provider_id?._id}
        provider={props.property?.provider_id}
        deal_id={props?.deal_id}
        onSuccess={() => {
          setShowRateSuccess(true);
        }}
        onCancel={() => {
          setShowRateModal(false);
        }}
      />
      <SuccessModal
        message="Rating was successful. Thank you for your feedback."
        show={showRateSuccess}
        onClear={() => {
          setShowRateSuccess(false);
        }}
      />
      <ReportIssue
        show={showIssueSubmitModal}
        // deal_id={props.dealId}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
      <SuccessModal
        message="Report was successful. Thank you for your feedback."
        show={rePortIssueModal}
        onClear={() => {
          setRePortIssueModal(false);
        }}
      />
      <div className="clientPrintDealDetails__clientDetails">
        <h3>Account Details</h3>
        <div className="clientPrintDealDetails__divider" />
        <p>Client & provider associated with this deal</p>

        <div className="deal_ids">
          <NavLink to={`/profile/${id[0]}`}>
            <div className="clientPrintDealDetails__clientDetailsContainer">
              <h4>Client Details </h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <img
                    src={deal?.client_id?.profile_pic}
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div style={{ flex: "1" }}>
                  <span>
                    <p>
                      Client: <strong>{deal?.client_id?.fullname}</strong>
                    </p>
                    <p>
                      Client Rating:{" "}
                      <strong>{deal?.client_id?.client_rating}/5</strong>
                    </p>
                  </span>
                </div>

                <div>
                  {!props.closedByAdmin && deal?.job_submitted &&
                    deal?.client_id?._id === auth?.user._id && (
                      <RateModal
                        type="Provider"
                        provider_id={deal?.provider_id?._id}
                        deal_id={deal?._id}
                      >
                        <Button variant="primary" type="button">
                          Rate Provider
                        </Button>
                      </RateModal>
                    )}
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink to={`/profile/${id[1]}`}>
            <div className="clientPrintDealDetails__clientDetailsContainer">
              <h4>
                Provider Details{" "}
                {/*<span>
                  <Dropdown
                    options={[
                      {
                        label: "Rate this Provider",
                        onClick: (e) => {
                          e.stopPropagation();
                          setShowRateModal(true);
                        },
                      },
                      {
                        label: "Report this Provider",
                        onClick: (e) => {
                          e.stopPropagation();
                          setShowIssueSubmitModal(true);
                        },
                      },
                    ]}
                  />
                  </span>*/}
              </h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <img
                    src={deal?.provider_id?.profile_pic}
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div style={{ flex: "1" }}>
                  <span>
                    <p>
                      Client: <strong>{deal?.provider_id?.fullname}</strong>
                    </p>
                    <p>
                      Client Rating:{" "}
                      <strong>{deal?.provider_id?.provider_rating}/5</strong>
                    </p>
                  </span>
                </div>

                <div>
                  {deal?.state === "COMPLETE" &&
                    deal?.client_id?._id !== auth?.user?._id && (
                      <RateModal
                        type="Client"
                        client_id={deal?.client_id?._id}
                        deal_id={deal?._id}
                      >
                        <Button variant="primary" type="button">
                          Rate Client
                        </Button>
                      </RateModal>
                    )}
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
}
