import React from 'react';
import './ClientPrintDealDetails.css';

export default function PrintDetailsDealDetails(props) {
  return (
    <div className='clientPrintDealDetails__printDetails'>
      <h3>Delivery Details</h3>
      <div className='clientPrintDealDetails__divider' />
      <br />
      <p>
        Mailing Address:{' '}
        <strong>{props.property?.job_id.mailing_address}</strong>
      </p>
    </div>
  );
}
