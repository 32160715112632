import React, { useState } from 'react';
import { useQuery } from 'react-query';
import AdminLayout from '../../../components/AdminLayout';
import AdminTableView from '../../../components/AdminLayout/AdminComponentView/AdminTableView';
import ViewDuePaymentDetailsModal from '../../../components/modals/ViewDuePaymentDetailsModal/ViewDuePaymentDetailsModal';
import { GET_DUE_PAYMENTS } from '../../../lib/api/admin';

export default function DuePayment() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    total_due: 1,
  });
  const [filter, setFilter] = useState('DUE_PAYMENTS');
  const [search, setSearch] = useState(null);
  const {
    data: duePayments,
    status,
    error,
  } = useQuery(
    [
      'GET_DUE_PAYMENTS',
      {
        page,
        sort: JSON.stringify(sort),
        filter: JSON.stringify({
          total_due: filter === 'DUE_PAYMENTS' ? { $gt: 0 } : { $eq: 0 },
        }),
        ...search?{search}:{}
      },
    ],
    GET_DUE_PAYMENTS
  );
  const [viewDetailsState, setViewDetailsState] = useState(null);

  return (
		<AdminLayout>
			{/* Modals */}
			<ViewDuePaymentDetailsModal
				open={!!viewDetailsState}
				onClose={() => setViewDetailsState(null)}
				title="Due Payment Details"
				data={viewDetailsState}
			/>

			<AdminTableView
				title="Due Payments"
				query={{ status, error }}
				search={{
					title: "Search Payments",
					placeholder: "Search by name, email...",
					btnText: "Search",
					onSearch: (value) => setSearch(value),
				}}
				filters={[
					{
						name: "Due Payments",
						value: "DUE_PAYMENTS",
						active: filter === "DUE_PAYMENTS",
						onClick: (value) => setFilter(value),
					},
					{
						name: "Successful Payments",
						value: "SUCCESSFUL_PAYMENTS",
						active: filter === "SUCCESSFUL_PAYMENTS",
						onClick: (value) => setFilter(value),
					},
				]}
				sort={{
					title: "Sort By",
					width: "250px",
					selects: [
						{
							name: "Sort By",
							options: [
								{ label: "Provider name (DESC)", value: "provider_id.name:-1" },
								{ label: "Provider name (ASC)", value: "provider_id.name:1" },
								{
									label: "Provider Email (DESC)",
									value: "provider_id.email:-1",
								},
								{ label: "Provider Email (ASC)", value: "provider_id.email:1" },
								{ label: "Deals (DESC)", value: "deals:-1" },
								{ label: "Deals (ASC)", value: "deals:1" },
							],
							onSort: ({ target }) => {
								const [key, value] = target?.value?.split(":");
								setSort(() => ({ [key]: +value }));
							},
						},
					],
				}}
				table={{
					headers: [
						{ name: "Provider name", dotsMenu: [], key: "provider_name" },
						{ name: "Provider Email", dotsMenu: [], key: "provider_email" },
						{ name: "Total Due", dotsMenu: [], key: "total_due" },
						{ name: "Total Deals", dotsMenu: [], key: "deals" },
						{
							name: "",
							dotsMenu: [
								{
									name: "View Details",
									onClick: (data) => {
										setViewDetailsState(
											duePayments?.data?.data.find(
												(item) =>
													String(item?.provider_id?._id) === String(data?._id)
											)
										);
									},
								},
							],
							key: "actions",
						},
					],
					data: duePayments?.data?.data.map((item) => ({
						_id: item?.provider_id?._id,
						provider_name: String(item?.provider_id?.fullname) || "N/A",
						provider_email: String(item?.provider_id?.email) || "N/A",
						total_due: String(item?.total_due) || "N/A",
						deals: String(item?.deals) || "N/A",
						actions: "",
					})),
					pagination: { total: duePayments?.data?.total, page, setPage },
				}}
			/>
		</AdminLayout>
	);
}
