import React, { useContext } from "react";
import { AuthContext } from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import useWindowWidth from "../../../hooks/window-width";

//TODO: Location not available in the job object

function ShowExpJobs({ ExpJobs, loading }) {
  const auth = useContext(AuthContext);
  const width = useWindowWidth();

  const isMobile = width <= 768;

  let history = useHistory();

  if (loading) {
    return <h2 className="text-center my-5">Loading...</h2>;
  }

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="">
      <div
        className="my-5"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        {ExpJobs.map((job, idx) => (
          <div
            key={idx}
            className="new-job-card"
            style={{
              backgroundColor: "var(--white_background)",
              boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  color: "var(--grey_one, #333)",
                  textAlign: "left",
                  fontFamily: "Nunito",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                {job.title}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "start",
                  alignItems: isMobile ? "start" : "center",
                  gap: isMobile ? "5px" : "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <img
                    width="15px"
                    height="15px"
                    src="/assets/designing.png"
                    alt=""
                  />
                  <p
                    style={{
                      color: "var(--grey_one, #333)",
                      textAlign: "center",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      margin: "0px",
                    }}
                  >
                    {job.category[0]}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <img
                    width="15px"
                    height="15px"
                    src="/assets/clock.png"
                    alt=""
                  />

                  <p
                    style={{
                      color: "var(--grey_one, #333)",
                      textAlign: "center",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      margin: "0px",
                    }}
                  >
                    {" "}
                    {auth.language === "en"
                      ? "Posted on"
                      : "পোস্ট করা হয়েছে"}{" "}
                    {new Date(job.createdAt).toLocaleDateString(
                      undefined,
                      dateOptions
                    )}
                  </p>
                </div>
                {/* <div className="d-flex justify-content-start align-items-center ">
                  <img
                    width="15px"
                    height="15px"
                    src="/assets/location.png"
                    alt=""
                  />

                  <p className="mx-1 my-0" style={{ color: "var(--grey_one)" }}>
                    Dhaka
                  </p>
                </div> */}
              </div>
              <p
                style={{
                  color: "var(--grey_two, #666)",
                  textAlign: "justify",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                {job.description}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                justifyContent: "start",
                width: isMobile && "100%",
              }}
            >
              <p
                style={{
                  color: "var(--grey_one, #333)",
                  textAlign: "justify",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                {auth.language === "en" ? "Budget" : "বাজেট"}
              </p>
              <p
                style={{
                  color: "var(--grey_one, #333)",
                  textAlign: "justify",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                {auth.language === "en" ? "BDT" : "টাকা"} {job.max_budget}
              </p>
              <p style={{ color: "var(--negetive_color)", margin: "0px" }}>
                {" "}
                {auth.language === "en" ? "Deadline " : "সময়সীমা "}
                {new Date(job.deadline).toLocaleDateString(
                  undefined,
                  dateOptions
                )}
              </p>
              <button
                style={{
                  backgroundColor: "#2A41E8",
                  width: isMobile ? "100%" : "280px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  outline: "none",
                }}
                onClick={() => history.push(`/job-details/${job._id}`)}
              >
                {auth.language === "en" ? "Apply for Bid" : "বিড জমা দিন"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShowExpJobs;
