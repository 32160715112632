import React, { useState, useContext } from "react";
import "./EditExpertise.css";
import Modal from "../../util/Modal";
import SkillItem from "../../items/SkillItem";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import LoadingSpinner from "../../util/LoadingSpinner";
import ModalButton from "../../button/ModalButton";
const EditExpertise = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [inp, setInp] = useState("");
  const [explist, setExplist] = useState([]);
  const addToList = () => {
    if (inp.length > 0) {
      let temp = explist;
      temp.push(inp);
      setExplist(temp);
      setInp("");
    }
  };
  const addExpertises = async () => {
    if (explist.length > 0) {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_expertise",
        "PUT",
        JSON.stringify({
          expertise: explist,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        setExplist([]);
      }
    }
    props.onCancel();
  };

  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="edit_exp_container">
        <img
          className="exit_edit"
          onClick={props.onCancel}
          src="/assets/cross.svg"
          alt=""
        />
        <h2>Edit Expertise List</h2>
        <div className="exp_items">
          {props.expertises &&
            props.expertises.map((expertise) => {
              return <SkillItem key={expertise} delete text={expertise} />;
            })}
          {explist &&
            explist.map((expertise) => {
              return (
                <SkillItem
                  key={expertise}
                  delfn
                  deleteFunction={() => {
                    let temp = explist.filter((val) => {
                      return val !== expertise;
                    });
                    setExplist(temp);
                  }}
                  text={expertise}
                />
              );
            })}
        </div>
        <div className="edit_exp_inpg">
          <input
            value={inp}
            onChange={(e) => setInp(e.target.value)}
            className="item"
            type="text"
            placeholder="Type to add new..."
          />
          <button onClick={addToList} className="item">
            add
          </button>
        </div>
        <div className="modalbtn__container">
          <ModalButton onClick={addExpertises}>save</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default EditExpertise;
