import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import { Link, useHistory } from 'react-router-dom';
import Paginator from '../../components/util/Paginator';

const DisapprovedJobs = () => {
  const [jobs, setJobs] = useState(null);
  const { isLoading, sendRequest } = useHttpClient();
  const history = useHistory();
  const [page, setPage] = useState(1);

  const fetchDisapprovedJobs = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/get_deleted_jobs?page=${page}`
      );
      setJobs(responseData);
    } catch (err) {}
  };
  useEffect(() => {
    fetchDisapprovedJobs();
    //eslint-disable-next-line
  }, [page]);

  const showUnapproved = () => {
    history.push('/manage-jobs');
  };

  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>Jobs Panel</h3>
      <div className='manage__box blue'>
        <p className='pboxe'>These jobs were disapproved.</p>

        <p onClick={showUnapproved} className='view_smth'>
          View Jobs that need Approval
        </p>

        <p className='view_smth'></p>

        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td> Name</td>
              <td>Description</td>
              <td>Category-SubCategory</td>
              <td></td>
            </tr>
            {jobs &&
              jobs.map((job) => {
                return (
                  <tr className='t_bottom'>
                    <td>{job.title}</td>
                    <td>{job.description.substring(0, 20) + '...'}</td>
                    <td>
                      {(job.parent_category.length > 0
                        ? job.parent_category + ' - '
                        : '') + job.category[0]}
                    </td>
                    <td className='last_td_gn'>
                      {
                        <Link target={'_new'} to={`/deleted-job/${job._id}`}>
                          <u>view Job</u>
                        </Link>
                      }
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default DisapprovedJobs;
