import React from "react";
import AuthSection from "../sections/authentication/AuthSection";

const VerifyForgotPass = () => {
  return (
    <div>
      <AuthSection variant="confirm" />
    </div>
  );
};

export default VerifyForgotPass;
