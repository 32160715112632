import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth-context";

function StatCard({
  c_rating,
  p_rating,
  s_ratio,
  a_ratio,
  p_credit,
  c_credit,
}) {
  const auth = useContext(AuthContext);

  const stats = [
    {
      label: auth.language === "en" ? "Client Rating" : "ক্লায়েন্ট রেটিং",
      value: c_rating,
      icon: true,
    },
    {
      label: auth.language === "en" ? "Provider Rating" : "ফ্রিল্যান্সার রেটিং",
      value: p_rating,
      icon: true,
    },
    {
      label: auth.language === "en" ? "Success Ratio" : "সাফল্যের অনুপাত",
      value: `${Number(s_ratio.toFixed(2))}%`,
      icon: false,
    },
    {
      label:
        auth.language === "en" ? "Provider Credit" : "ফ্রিল্যান্সার ক্রেডিট",
      value: p_credit,
      icon: true,
    },
    {
      label: auth.language === "en" ? "Client Credit" : "ক্লায়েন্ট ক্রেডিট",
      value: c_credit,
      icon: true,
    },
    {
      label: "Acceptance Ratio",
      value: a_ratio,
      icon: false,
    },
  ];

  const keyStyle = {
    color: "var(--grey_two, #666)",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    margin: "0px",
  };

  const valueStyle = {
    color: "var(--grey_one, #333)",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  return (
    <div
      style={{
        width: "100%",
        display: "inline-flex",
        padding: "30px",
        flexDirection: "column",
        alignItems: "start",
        gap: "40px",
        borderRadius: "4px",
        background: "#FFF",
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      {stats.map(
        (stat, index) =>
          stat.value && (
            <div key={index} style={{ marginBottom: "12px" }}>
              <p style={keyStyle}>{stat.label}</p>
              <div
                style={{
                  ...valueStyle,
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {stat.icon && (
                  <img
                    src="/assets/rater-start.png"
                    alt=""
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                )}
                {stat.value}
              </div>
            </div>
          )
      )}
    </div>
  );
}

export default StatCard;
