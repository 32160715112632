import ShowBids from './ShowBids';
import Pagination from '../../Pagination';
import LoadingSpinner from '../../../components/util/LoadingSpinner';
import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import { AuthContext } from '../../../context/auth-context';
import { useHistory } from 'react-router';

function Bids(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const auth = useContext(AuthContext);
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedBids, setLoadedBids] = useState();
  const [refr, setRefr] = useState(false);
  const [page, setPage] = useState(1);
  const onSuccess = () => {
    setRefr(!refr);
  };

  useEffect(() => {
    if (auth.user) {
      fetchBids();
    }
  }, [auth.user, refr, page]);

  const fetchBids = async () => {
    setLoading(true);
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/user/bids/?user_id=${auth.user._id}&page=${page}`
      );
      setBids(responseData);
      setLoading(false);
    } catch (err) {}
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBids = bids.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className=' px-4' style={{width: '100%'}}>
      {isLoading && <LoadingSpinner asOverlay />}
      <h3 className=' fw-bold mb-3'>
        {' '}
        {auth.language === 'en'
          ? 'Bids You’ve Placed'
          : 'আপনার স্থাপন করা দরপত্রসমূহ'}
      </h3>
      <ShowBids bids={currentBids} loading={loading} refetch={fetchBids} closed_by_admin={props.closed_by_admin} />

      <div className='d-flex justify-content-center align-items-center my-5'>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={bids.length}
          paginate={paginate}
          pageNumber={currentPage}
          style={{ backgroundColor: '#2A41E8' }}

        />
      </div>
    </div>
  );
}

export default Bids;
