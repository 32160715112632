import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import QComponent from "../../../components/shared/QComponent/QComponent";
import { GET_FAILED_BIDS } from "../../../lib/api/admin";

export default function FailedBids() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState();
  const {
    data: bids,
    status,
    error,
  } = useQuery(
    [
      "GET_FAILED_BIDS",
      {
        page,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        ...(search ? { search } : {}),
      },
    ],
    GET_FAILED_BIDS
  );

  return (
    <AdminLayout>
      <AdminTableView
        title="Bids"
        query={{ status, error }}
        table_name="Bid"
        search={{
          title: "Search",
          placeholder: "Search by job title...",
          btnText: "Search",
          onSearch: (value) => setSearch(value),
        }}
        filters={[
          // {
          //   name: "All Deals",
          //   value: undefined,
          //   active: filter?.state === undefined,
          //   onClick: () =>
          //     setFilter((values) => ({ ...values, state: undefined })),
          // },
          {
            name: "Accepted Bids",
            value: "ACCEPTED",
            active: filter?.state === "ACCEPTED",
            onClick: () =>
              setFilter((values) => ({ ...values, state: "ACCEPTED" })),
          },
          {
            name: "Successful Bids",
            value: "SUCCESSFUL",
            active: filter?.state === "SUCCESSFUL",
            onClick: () =>
              setFilter((values) => ({ ...values, state: "SUCCESSFUL" })),
          },
          {
            name: "Failed Bids",
            value: "FAILED",
            active: filter?.state === "FAILED",
            onClick: () =>
              setFilter((values) => ({ ...values, state: "FAILED" })),
          },
          {
            name: "Pending Bids",
            value: "PENDING",
            active: filter?.state === "PENDING",
            onClick: () =>
              setFilter((values) => ({ ...values, state: "PENDING" })),
          },
        ]}
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Comment (ASC)", value: "comment:asc" },
                { label: "Comment (DESC)", value: "comment:desc" },
                { label: "Job Title (ASC)", value: "job_id.title:asc" },
                { label: "Job Title (DESC)", value: "job_id.title:desc" },
                { label: "Bid Type (DESC)", value: "bid_type:desc" },
                { label: "Bid Type (ASC)", value: "bid_type:asc" },
                { label: "Bid Date (DESC)", value: "createdAt:desc" },
                { label: "Bid Date (ASC)", value: "createdAt:asc" },
                { label: "Budget (DESC)", value: "claimed_budget:desc" },
                { label: "Budget (ASC)", value: "claimed_budget:asc" },
                { label: "State (DESC)", value: "state:desc" },
                { label: "State (ASC)", value: "state:asc" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Job Title", dotsMenu: [], key: "job_title" },
            { name: "Bid Type", dotsMenu: [], key: "bid_type" },
            { name: "Bid Date", dotsMenu: [], key: "createdAt" },
            { name: "Budget", dotsMenu: [], key: "claimed_budget" },
            { name: "State", dotsMenu: [], key: "state" },
            { name: "Comment", dotsMenu: [], key: "comment" },
          ],
          data: bids?.data?.data?.map((bid) => ({
            job_title: bid?.job_id?.title || "N/A",
            bid_type: bid?.bid_type || "N/A",
            createdAt: moment(bid?.createdAt).format("YYYY-MM-DD") || "N/A",
            state: bid?.state || "N/A",
            claimed_budget: bid?.claimed_budget + "" || "N/A",
            comment: bid?.comment + "" || "N/A",
            actions: "",
            _id: bid._id,
          })),
          pagination: { total: bids?.data?.total, page, setPage },
        }}
      />
    </AdminLayout>
  );
}
