import React, { useEffect } from 'react';
import Inbox from '../sections/inbox/Inbox';
export const Messages = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Inbox socket={props.socket} />
    </div>
  );
};
export default Messages;
