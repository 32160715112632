import React, { useEffect } from "react";
import GetDues from "../sections/admin/GetDues";

const GetDuePayments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <GetDues />;
};

export default GetDuePayments;
