import React, { useState } from 'react';
import Modal from '../../util/Modal';
import Input from '../../input/Input';
import { useForm } from '../../../hooks/form-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import { Button } from '../../../components/button/Button';
import { useHttpClient } from '../../../hooks/http-hook';
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
} from '../../../util/Validators';
import './ApplyBid.css';

const ApplyBid = (props) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [response, setResponse] = useState();
  const [bidOption, setBidOption] = useState('');
  const [formState, inputHandler] = useForm(
    {
      price: {
        value: '',
        isValid: false,
      },
      comment: {
        value: '',
        isValid: true,
      },
    },
    false
  );
  const sendBidRequest = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/create_bid',
        'POST',
        JSON.stringify({
          job_id: props.id,
          claimed_budget: formState.inputs.price.value,
          comment: formState.inputs.comment.value,
          bid_type:
            bidOption === 'service'
              ? 'NORMAL'
              : bidOption === 'printDelivery'
              ? 'PRINT'
              : 'NORMAL',
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onCancel();
        props.onSuccess();
      }
    } catch (err) {
      setResponse(err.message);
    }
  };
  const editBid = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/provider/update_bid/?bid_id=${props.id}`,
        'POST',
        JSON.stringify({
          //bid_id: props.id,
          claimed_budget: formState.inputs.price.value,
          comment: formState.inputs.comment.value,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onCancel();
        props.onSuccess();
      }
    } catch (err) {
      setResponse(err.message);
    }
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      <React.Fragment>
        <img
          className='cross__btn'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        {/* <div className="budget__right">
          {isLoading && <LoadingSpinner asOverlay />}
          Budget <strong>{props.budget}</strong> BDT
        </div> */}
        <div className='apply__bid'>
          {isLoading && <LoadingSpinner asOverlay />}
          <h4 className='bid__head'> Apply For Bid</h4>
          <h6 className='budget'>Budget : {props.budget}</h6>
        </div>
        <form>
          {props.print && (
            <div>
              <select
                name=''
                id=''
                style={{ marginLeft: '6%' }}
                value={bidOption}
                onChange={(e) => setBidOption(e.target.value)}
              >
                <option>Select an Option </option>
                <option value='service'>Service </option>
                <option value='printDelivery'>Print+ Delivery </option>
              </select>
            </div>
          )}
          <Input
            grey
            element='input'
            id='price'
            type='text'
            label='Claimed Price'
            errorText='Please enter a valid price'
            onInput={inputHandler}
            initialValue={props.claimedPrice}
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
          />
          <Input
            large
            grey
            onInput={inputHandler}
            element='textarea'
            id='comment'
            type='text'
            className='textArea'
            errorText='Please enter comment not exceeding 300 characters'
            label='Comment to Client'
            placeholder='Optional Message to Client'
            initialValid={true}
            validators={[VALIDATOR_MAXLENGTH(300)]}
            initialValue={props.comment}
          />
          <div className='abit__space'>{response}</div>

          <div className='button--wrapper'>
            {!props.edit && (
              <Button
                onClick={sendBidRequest}
                buttonStyle='btn--apply'
                isDisabled={!formState.isValid ? 'true' : ''}
                type='submit'
              >
                Confirm Bid
              </Button>
            )}
            {props.edit && (
              <Button
                onClick={editBid}
                buttonStyle='btn--apply'
                isDisabled={!formState.isValid ? 'true' : ''}
                type='submit'
              >
                Update
              </Button>
            )}
          </div>
        </form>
      </React.Fragment>
    </Modal>
  );
};

export default ApplyBid;
