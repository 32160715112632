import React, { useEffect, useState, useContext } from "react";
import "./ExploreItems.css";
import JobCard from "../../components/card/JobCard";
import { useHttpClient } from "../../hooks/http-hook";
import ErrorModal from "../../components/util/ErrorModal";
import LoadingSpinner from "../../components/util/LoadingSpinner";
import SidebarOption from "../../components/filter/SidebarOption";
import { useParams, useHistory } from "react-router-dom";
import ProviderCard from "../../components/card/ProviderCard";
import { AuthContext } from "../../context/auth-context";
import TinyOption from "../../components/filter/TinyOption";

const ExploreItems = (props) => {
  const auth = useContext(AuthContext);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSub, setSelectedSub] = useState("");
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { category } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedData, setLoadedData] = useState();
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const increasePage = () => {
    let newPage = page + 1;
    setPage(newPage);
    window.scrollTo(0, 406);
  };
  const decreasePage = () => {
    if (page > 1) {
      let newPage = page - 1;
      setPage(newPage);
      window.scrollTo(0, 406);
    }
  };

  useEffect(() => {
    setPage(1);
    const fetchSubCategories = async () => {
      setSelectedSub("");
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/get_subcategories/?name=${category}`
        );
        setSubcategories(responseData);
      } catch (err) {}
    };
    category !== "null" && fetchSubCategories();
    //eslint-disable-next-line
  }, [category]);

  const fetchData = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${
          props.jobs
            ? "/user/available_jobs/"
            : "/user/get_providers_by_category/"
        }?category=${
          category === "all" ? "" : selectedSub ? selectedSub : category
        }&page=${page}`
      );
      setLoadedData(responseData);
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, [page, category, props.jobs, selectedSub]);

  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="jobs__container">
        <div className="filter__container">
          <p>SELECT A CATEGORY</p>
          {auth.categories &&
            auth.categories.data.map((cat) => (
              <SidebarOption
                key={cat._id}
                onClick={() => {
                  props.jobs &&
                    history.push("/explore/" + cat.name.toLowerCase());
                  props.providers &&
                    history.push("/providers/" + cat.name.toLowerCase());
                }}
                active={category === cat.name.toLowerCase() && "true"}
                icon={`${process.env.REACT_APP_BACKEND_URL}/${cat.logo}`}
                text={cat.name}
              />
            ))}
        </div>
        <div className="job__container">
          <div className="tinyoption__container">
            {auth.categories &&
              auth.categories.data.map((cat) => (
                <TinyOption
                  key={cat._id}
                  onClick={() => {
                    props.jobs &&
                      history.push("/explore/" + cat.name.toLowerCase());
                    props.providers &&
                      history.push("/providers/" + cat.name.toLowerCase());
                  }}
                  active={category === cat.name.toLowerCase() && "true"}
                  icon={`${process.env.REACT_APP_BACKEND_URL}/${cat.logo}`}
                  text={cat.name}
                />
              ))}
          </div>

          <div className="selector__container">
            <div>
              <div className="work_post_for">
                <h3>
                  Work Posts: <span>for {category}</span>
                </h3>
              </div>
              {subcategories && subcategories.length > 0 && (
                <select
                  value={selectedSub}
                  onChange={(e) => {
                    setSelectedSub(e.target.value);
                  }}
                >
                  <option
                    className="lebel_bold"
                    label="Select a Subcategory"
                  ></option>
                  {subcategories &&
                    subcategories.length > 0 &&
                    subcategories.map((option) => {
                      return (
                        <option key={option._id} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>

          <div className="jobcard__container">
            {props.jobs &&
              loadedData &&
              loadedData.map((job) => {
                return (
                  <JobCard
                    detail
                    key={job._id}
                    id={job._id}
                    title={job.title}
                    category={job.parent_category}
                    subCategory={job.category}
                    postDate={new Date(job.createdAt).toLocaleDateString(
                      undefined,
                      dateOptions
                    )}
                    description={job.description}
                    budget={job.max_budget}
                    clientName={job.client_}
                    clientProfilePic={job.client_profile_pic}
                    attachments={job.attachments}
                    deadline={new Date(job.deadline).toLocaleDateString(
                      undefined,
                      dateOptions
                    )}
                    upvote={job.upvote}
                    downvote={job.downvote}
                  />
                );
              })}
          </div>
          <div className="provider__container">
            {props.providers &&
              loadedData &&
              loadedData.map((provider) => {
                return (
                  <ProviderCard
                    key={provider.id}
                    id={provider._id}
                    name={provider.fullname}
                    profilePic={`${provider.profile_pic}`}
                    rating={provider.provider_rating}
                    completed={provider.total_deal}
                    expertise={provider.expertise}
                  />
                );
              })}
          </div>
          <div>
            <div className="navigate__page">
              <div className="select__name">
                <label htmlFor="select-name">Sort By Name</label>
                <select name="" id="select-name" className="select__name">
                  <option value="">Hi</option>
                  <option value="">Je</option>
                </select>
              </div>

              <div className="select__name">
                <label htmlFor="select-item">Show Item 10</label>
                <select name="" id="select-item" className="">
                  <option value="">Hi</option>
                  <option value="">Je</option>
                </select>
              </div>
              <button onClick={decreasePage} className="left_nav">
                prev
              </button>
              <div className="page_no">{page}</div>
              <button onClick={increasePage} className="right_nav">
                next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreItems;
