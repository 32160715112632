import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import axios from "axios";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const BasicInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [nid, setNid] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [load, setLoad] = useState(false);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [notificationDest, setNotificationDest] = useState("lol");
  const [show, setShow] = useState(false);
  const [Otp, setOtp] = useState();

  useEffect(() => {
    setUpdateUser();
  }, [auth.user]);

  const setUpdateUser = () => {
    if (auth.user) {
      setName(auth.user.fullname);
      setDob(auth.user.date_of_birth);
      setNid(auth.user.nid);
      setNotificationDest(auth.user.notification_destination.destination);
    }
  };

  const handleVerification = async () => {
    try {
      const token = localStorage.getItem("auth_token");

      console.log(token);
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/user/get_verification_code_for_notification_destination",
        {
          body: "test",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      setShow(true);
      toast.success("Otp has been sent!");
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const handleOtp = async () => {
    try {
      const token = localStorage.getItem("auth_token");
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          "/user/verify_notification_destination",
        {
          verification_code: Otp,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      setShow(false);
      toast.success("Otp verification successful!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const confirmUpdate = () => {
    if (!name) {
      notify("err", "Name must be provided");
      return;
    }
    if (!dob) {
      notify("err", "Date of birth must be provided");
      return;
    }
    if (
      nid?.toString().length < 10 &&
      nid?.toString().length !== 0 &&
      nid !== undefined
    ) {
      notify("err", "NID or birth certificate no. at least 10 characters long");
      return;
    }
    setConfirmModal(true);
  };

  const updateUser = async () => {
    setLoad(true);
    setSpinnerStatus(true);
    try {
      let dataToSend;
      dataToSend = {
        fullname: name,
        date_of_birth: dob && dob.slice(0, 10),
        nid: nid && nid.toString(),
        password: oldPassword,
        notification_destination: notificationDest,
      };
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/update_info",
        "POST",

        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.success === "yes") {
        auth.setUser();
        setOldPassword("");
        setLoad(false);
        notify("success", "Profile updated successfully!");
        setSpinnerStatus(false);
        setConfirmModal(false);
      } else
        throw new Error(responseData?.data?.msg || "Something Went Wrong!");
    } catch (err) {
      setLoad(false);
      notify("err", err?.response?.data?.msg || err.message);
      setSpinnerStatus(false);
      setConfirmModal(false);
    }
  };

  return (
    <div className="my-5">
      <div className="form-section">
        <label htmlFor="fname">
          {" "}
          {auth.language === "en" ? "Display Name" : "প্রদর্শন নাম "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          autoComplete="off"
          type="text"
          name="name"
          placeholder={
            auth.language === "en"
              ? "Your name wil be displayed everywhere, Use full name."
              : "আপনার নাম সর্বত্র প্রদর্শিত হবে, পুরো নাম ব্যবহার করুন।"
          }
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <div className="form-section">
        <label for="bdate">
          {" "}
          {auth.language === "en" ? "Birthday" : "জন্মদিন"}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          autoComplete="off"
          type="date"
          value={dob ? dob.slice(0, 10) : ""}
          onChange={(e) => {
            setDob(e.target.value);
          }}
          name="bdate"
        />
      </div>

      <div className="form-section">
        <label for="nid">
          {" "}
          {auth.language === "en"
            ? "NID or Birth Certificate No"
            : "এনআইডি বা জন্ম সনদ নং"}{" "}
        </label>
        <input
          autoComplete="off"
          type="number"
          name="nid"
          placeholder={
            auth.language === "en"
              ? "Be careful, do not make mistakes"
              : "সাবধান, ভুল করবেন না           "
          }
          value={nid}
          onChange={(e) => setNid(e.target.value)}
        />
      </div>

      <div className="form-section">
        <label htmlFor="notifcation_dest">
          {auth.language === "en"
            ? "Notification Destination"
            : "বিজ্ঞপ্তির গন্তব্য"}{" "}
          {!auth.user.notification_destination.verified && (
            <button style={{ color: "red" }} onClick={handleVerification}>
              {auth.language === "en" ? "(Verify Now)" : "(ভেরিফাই করুন)"}
            </button>
          )}
        </label>
        <div>
          <input
            type="text"
            id="notificationDest"
            name="notificationDest"
            value={notificationDest}
            onChange={(e) => setNotificationDest(e.target.value)}
            placeholder={
              auth.language === "en" ? "email or phone" : "ইমেইল বা ফোন"
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-center flex-wrap submit-btn">
        <div className="">
          <button
            className="btn fw-bold my-2"
            style={{
              backgroundColor: "var(--primary_color)",
              width: "15rem",
              color: "white",
            }}
            onClick={confirmUpdate}
          >
            {auth.language === "en"
              ? "Update My Profile"
              : "আমার বৃত্তান্ত আপডেট করুন"}
          </button>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-section">
            <input
              type="text"
              required
              name="otp"
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleVerification}>
            Send Again
          </Button>
          <Button
            type="button"
            className="btn fw-bold"
            style={{ backgroundColor: "var(--primary_color)" }}
            onClick={handleOtp}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="form-section">
            <h4 className="fw-bold mb-3">
              {" "}
              {auth.language === "en"
                ? "Enter Current Password To Update Changes"
                : "পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন"}
            </h4>
            <input
              type="password"
              required
              name=""
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            {auth.language === "en" ? "Close" : "বন্ধ"}
          </Button>
          <Button
            type="button"
            className="btn fw-bold"
            style={{ backgroundColor: "var(--primary_color)" }}
            onClick={updateUser}
          >
            {auth.language === "en"
              ? " Save Changes "
              : "পরিবর্তনগুলোর সংরক্ষন"}{" "}
            {spinnerStatus && (
              <Spinner animation="border" size="sm" className="text-white" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BasicInfo;
