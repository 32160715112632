import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UploadImage from "../shared/UploadImage/UploadFormImage";
import { useHttpClient } from "../../hooks/http-hook";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai"; // Cross icon for delete button
import countryList from "country-list";
import Universities from "./Universities";

const ApplicationModal = ({
  open,
  onCancel,
  language,
  user,
  data,
  uniData,
  consultancyID,
}) => {
  const [listFields, setListFields] = useState([]);
  const [formData, setFormData] = useState(data);
  const { sendRequest } = useHttpClient();
  const [compositeListFields, setCompositeListFields] = useState([]);
  const [universityData, setUniversityData] = useState();
  const [countries, setCountries] = useState();

  // Load all available countries initially
  useEffect(() => {
    setCountries(countryList.getNames()); // List of all countries
  }, []);

  const notify = (type, msg) => {
    type === "err"
      ? toast.error(`${msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(`${msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  };

  useEffect(() => {
    if (user?.form_builder) {
      const fields = user.form_builder
        .map((field) => {
          const dataField = data?.[field.field_name];
          if (field.field_type === "List" && dataField?.listData?.length > 0) {
            return dataField;
          }
        })
        .filter(Boolean); // Filters out any undefined entries

      // if(Array.isArray(fields[fields.length-1])
      //   setListFields([...fields),{name:fields[fields.length-1]};
      setListFields(fields);
      console.log('lists',fields);
    }
  }, [user, data]);

  
  const handleListInputChange = (e, parentField, idx, child, field_name) => {
    if (!e || !e.target) return;
    const value = e.target.value;

    setListFields((prev) => {
      const updatedList = [...prev];

      // Find the target parent field
      const parentIndex = updatedList.findIndex(
        (listField) => listField.name === parentField.name
      );

      if (parentIndex > -1) {
        // Update the child within the children array of the target parent
        updatedList[parentIndex].listData[idx] = {
          ...updatedList[parentIndex].listData[idx],
          children: updatedList[parentIndex].listData[idx].children?.map((c) =>
            c.field_name === child.field_name ? { ...c, value: value || "" } : c
          ),
        };

        // console.log("mew", updatedList);
      }

      setFormData((prev) => {
        let updatedFormData = { ...prev };

        // Push each item from listFields individually
        for (const item of updatedList) {
          updatedFormData = { ...updatedFormData, [item.name]: item };
        }

        return updatedFormData;
      });

      return updatedList;
    });
  };

  // Updates the file upload URL for a specific child field
  const handleListFileUpload = (url, parentField, idx, child, field_name) => {
    setListFields((prev) => {
      const updatedList = [...prev];

      // Find the target parent field
      const parentIndex = updatedList.findIndex(
        (listField) => listField.name === parentField.name
      );

      if (parentIndex > -1) {
        // Update the child within the children array of the target parent
        updatedList[parentIndex].listData[idx] = {
          ...updatedList[parentIndex].listData[idx],
          children: updatedList[parentIndex].listData[idx].children.map((c) =>
            c.field_name === child.field_name ? { ...c, value: url || "" } : c
          ),
        };
      }

      console.log(updatedList, field_name);
      const matchingItem = updatedList.find((item) => item.name === field_name);

      if (matchingItem) {
        setFormData((prev) => ({
          ...prev,
          [field_name]: matchingItem,
        }));
      }
      return updatedList;
    });
  };

  const handleListFileRemove = (parentField, idx, child, field_name) => {
    setListFields((prev) => {
      const updatedList = [...prev];

      console.log("previous updated list", updatedList);

      // Find the target parent field
      const parentIndex = updatedList.findIndex(
        (listField) => listField.name === parentField.name
      );

      if (parentIndex > -1) {
        // Update the child within the children array of the target parent
        updatedList[parentIndex].listData[idx] = {
          ...updatedList[parentIndex].listData[idx],
          children: updatedList[parentIndex].listData[idx].children.map((c) =>
            c.field_name === child.field_name ? { ...c, value: [] } : c
          ),
        };
      }

      const matchingItem = updatedList.find((item) => item.name === field_name);

      if (matchingItem) {
        setFormData((prev) => ({
          ...prev,
          [field_name]: matchingItem,
        }));
      }

      console.log("post updated list", updatedList);

      return updatedList;
    });
  };

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const handleRemoveListField = (e, parentIdx, childIdx) => {
    // Add childIdx as a parameter
    e.preventDefault();

    console.log("handleRemoveList Field being called");

    setListFields((prev) => {
      const updatedList = [...prev];

      // Remove the specific child field from the listData array at the parent index
      updatedList[parentIdx].listData.splice(childIdx, 1); // Now childIdx is correctly used

      return updatedList;
    });
  };

  const handleAddFields = (field) => {
    setListFields((prevFields) => {
      // Check if field with the same name already exists in listFields
      const existingField = prevFields.find(
        (item) => item.name === field.field_name
      );

      if (existingField) {
        // If it exists, update the listData by appending the new field to it
        return prevFields.map((item) =>
          item.name === field.field_name
            ? { ...item, listData: [...item.listData, field] }
            : item
        );
      } else {
        // If it doesn't exist, add a new entry in listFields
        return [
          ...prevFields,
          { name: field.field_name, type: "List", listData: [field] },
        ];
      }
    });

    // console.log(listFields);
  };

  const handleInputChange = (e, field) => {
    if (!e || !e.target) return;
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [field.field_name]: {
        ...prev[field.field_name],
        value: value || "",
      },
    }));
  };

  const handleCompositeChange = (
    eOrUrl,
    field,
    childIdx,
    child,
    isFile = false
  ) => {
    const value = isFile ? eOrUrl : eOrUrl?.target?.value;
    if (!value) return;

    setCompositeListFields((prev) => {
      const updatedList = [...prev];
      updatedList[childIdx] = {
        ...updatedList[childIdx],
        [child.field_name]: {
          field_type: child.field_type,
          value: value || "",
        },
      };
      return updatedList;
    });

    setFormData((prev) => {
      const updatedParent = prev[field.field_name] || { children: [] };
      const updatedChildren = [...(updatedParent.children || [])];
      updatedChildren[childIdx] = {
        ...updatedChildren[childIdx],
        [child.field_name]: {
          field_type: child.field_type,
          value: value || "",
        },
      };
      return {
        ...prev,
        [field.field_name]: {
          ...updatedParent,
          children: updatedChildren,
        },
      };
    });
  };

  const handleCompositeRemoveFile = (
    field,
    childIdx,
    child,
    isFile = false
  ) => {
    const value = [];

    setCompositeListFields((prev) => {
      const updatedList = [...prev];
      updatedList[childIdx] = {
        ...updatedList[childIdx],
        [child.field_name]: {
          field_type: child.field_type,
          value: value || "",
        },
      };
      return updatedList;
    });

    setFormData((prev) => {
      const updatedParent = prev[field.field_name] || { children: [] };
      const updatedChildren = [...(updatedParent.children || [])];
      updatedChildren[childIdx] = {
        ...updatedChildren[childIdx],
        [child.field_name]: {
          field_type: child.field_type,
          value: value || "",
        },
      };
      return {
        ...prev,
        [field.field_name]: {
          ...updatedParent,
          children: updatedChildren,
        },
      };
    });
  };

  const handleFileUpload = (url, field) => {
    setFormData((prev) => ({
      ...prev,
      [field.field_name]: {
        ...prev[field.field_name],
        value: url || "",
      },
    }));
  };

  const handleFileRemove = (field) => {
    setFormData((prev) => {
      const updatedFormData = { ...prev };
      delete updatedFormData[field.field_name]; // Remove the field completely
      return updatedFormData;
    });
  };

  const handleRemoveFields = (indexToRemove) => {
    setListFields((prev) => prev.filter((_, idx) => idx !== indexToRemove));
    setFormData((prev) => {
      const updatedForm = { ...prev };
      delete updatedForm[listFields[indexToRemove].field_name];
      return updatedForm;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const agentId = user._id;

    const finalData = {
      id: consultancyID,
      form_data: JSON.stringify(formData),
      universities: JSON.stringify(universityData),
    };

    // console.log(data);
    console.log(formData);
    //console.log(finalData);

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/consultancy/edit",
        "PUT",
        JSON.stringify(finalData),
        { "Content-Type": "application/json" }
      );
      if (responseData === true) {
        onCancel();
        notify("success", "Application Submitted Successfully!");
      } else throw new Error("Something Went Wrong!");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const styles = {
    title: {
      fontSize: "1.5em",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    field: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    listFieldContainer: {
      position: "relative",
      padding: "10px",
      paddingTop: "50px",
      margin: "10px 0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: "#f9f9f9",
    },
    crossButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      color: "red",
      fontSize: "20px",
      cursor: "pointer",
    },
    checkboxLabel: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    },
    checkboxInput: {
      width: "5%",
      height: "20px",
    },
    btn: {
      backgroundColor: "#fff",
      color: "#2A41E8",
      padding: "10px 20px",
      borderRadius: "4px",
      border: "1px solid #2A41E8",
      cursor: "pointer",
      marginRight: "10px",
    },
    submitButton: {
      backgroundColor: "#2A41E8",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "4px",
      border: "none",
      cursor: "pointer",
    },
    modalFooter: {
      border: "none",
    },
    gap: {
      margin: "10px 0",
    },
  };

  return (
    <div className="application-modal">
      <Modal show={open} onHide={onCancel} size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-section">
              <Universities
                preData={uniData}
                universityData={universityData}
                setUniversityData={setUniversityData}
                user={user}
                language={language}
              />
              <p style={styles.title}>
                {language === "en" ? "Fillup The Form" : "ফর্ম পূরণ করুন"}
              </p>
              {user?.form_builder.map((field, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: index % 2 == 0 ? "rgba(0,0,0,.03)" : null,
                    padding: "10px 10px 5px 10px",
                    borderRadius: "5px",
                    border: "1px solid rgba(0,0,0,.2)",
                    margin: "10px 0",
                  }}
                >
                  <label style={{ textTransform: "capitalize !important" }}>
                    {field.field_name}
                    {field.is_required && field.field_type !== "Composite" ? (
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    ) : null}
                  </label>
                  <div>
                    {field.field_type === "Text" ? (
                      <input
                        type="text"
                        defaultValue={
                          data ? data[field.field_name]?.value : null
                        }
                        onChange={(e) => handleInputChange(e, field)}
                        style={styles.field}
                        required={field.is_required}
                      />
                    ) : field.field_type === "Number" ? (
                      <input
                        type="number"
                        defaultValue={
                          data ? data[field.field_name]?.value : null
                        }
                        onChange={(e) => handleInputChange(e, field)}
                        style={styles.field}
                        required={field.is_required}
                      />
                    ) : field.field_type === "Date" ? (
                      <input
                        type="date"
                        defaultValue={
                          data ? data[field.field_name]?.value : null
                        }
                        onChange={(e) => handleInputChange(e, field)}
                        style={styles.field}
                        required={field.is_required}
                      />
                    ) : field.field_type === "Country" ? (
                      <select
                        name={field.field_name}
                        defaultValue={data ? data[field.field_name] : null}
                        className="form-select"
                        required={field.is_required}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [field.field_name]: { value: e.target.value },
                          })
                        }
                      >
                        <option value="">Select a Country</option>
                        {countries?.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    ) : field.field_type === "Dropdown" ? (
                      <select
                        name={field.field_name}
                        defaultValue={data ? data[field.field_name] : null}
                        required={field.is_required}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [field.field_name]: { value: e.target.value },
                          })
                        }
                        className="form-select"
                      >
                        <option value="">Select an option</option>
                        {field.options?.split(",").map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : field.field_type === "Composite" ? (
                      <>
                        {field.children.map((child, childIdx) => (
                          <div key={childIdx} style={{ marginLeft: "10px" }}>
                            <label>
                              {child.field_name}
                              {child.is_required ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "15px",
                                    marginLeft: "3px",
                                  }}
                                >
                                  *
                                </span>
                              ) : null}
                            </label>
                            {child.field_type === "Text" ? (
                              <input
                                type="text"
                                defaultValue={
                                  data[field.field_name]?.children?.[
                                    childIdx
                                  ]?.[child.field_name]?.value || ""
                                }
                                required={child.is_required}
                                onChange={(e) =>
                                  handleCompositeChange(
                                    e,
                                    field,
                                    childIdx,
                                    child
                                  )
                                }
                                style={styles.field}
                                placeholder={child.field_name} // Placeholder for the child field
                              />
                            ) : child.field_type === "Number" ? (
                              <input
                                type="number"
                                defaultValue={
                                  data[field.field_name]?.children?.[
                                    childIdx
                                  ]?.[child.field_name]?.value || ""
                                }
                                required={child.is_required}
                                onChange={(e) =>
                                  handleCompositeChange(
                                    e,
                                    field,
                                    childIdx,
                                    child
                                  )
                                }
                                style={styles.field}
                                placeholder={child.field_name} // Placeholder for the child field
                              />
                            ) : child.field_type === "Dropdown" ? (
                              <select
                                name={child.field_name}
                                defaultValue={
                                  data[field.field_name]?.children?.[
                                    childIdx
                                  ]?.[child.field_name]?.value || ""
                                }
                                required={child.is_required}
                                onChange={(e) =>
                                  handleCompositeChange(
                                    e,
                                    field,
                                    childIdx,
                                    child
                                  )
                                }
                                className="form-select"
                              >
                                <option value="">Select an option</option>
                                {child.options?.split(",").map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : child.field_type === "Country" ? (
                              <select
                                name={child.field_name}
                                defaultValue={
                                  data[field.field_name]?.children?.[
                                    childIdx
                                  ]?.[child.field_name]?.value || ""
                                }
                                required={child.is_required}
                                className="form-select"
                                onChange={(e) =>
                                  handleCompositeChange(
                                    e,
                                    field,
                                    childIdx,
                                    child
                                  )
                                }
                              >
                                <option value="">Select a Country</option>
                                {countries?.map((country) => (
                                  <option key={country} value={country}>
                                    {country}
                                  </option>
                                ))}
                              </select>
                            ) : child.field_type === "Date" ? (
                              <input
                                type="date"
                                defaultValue={
                                  data[field.field_name]?.children?.[
                                    childIdx
                                  ]?.[child.field_name]?.value || ""
                                }
                                required={child.is_required}
                                onChange={(e) =>
                                  handleCompositeChange(
                                    e,
                                    field,
                                    childIdx,
                                    child
                                  )
                                }
                                style={styles.field}
                                placeholder={child.field_name} // Placeholder for the child field
                              />
                            ) : child.field_type === "Single Attachment" ? (
                              <div style={{ position: "relative" }}>
                                <UploadImage
                                  id={childIdx}
                                  required={child.is_required}
                                  defaultValue={
                                    data[field.field_name]?.children?.[
                                      childIdx
                                    ]?.[child.field_name]?.value || ""
                                  }
                                  handleFileRemove={() =>
                                    handleCompositeRemoveFile(
                                      field,
                                      childIdx,
                                      child,
                                      true
                                    )
                                  }
                                  onChange={(url) =>
                                    handleCompositeChange(
                                      url,
                                      field,
                                      childIdx,
                                      child,
                                      true
                                    )
                                  }
                                />
                              </div>
                            ) : child.field_type === "Multiple Attachments" ? (
                              <UploadImage
                                id={childIdx}
                                required={child.is_required}
                                multi={true}
                                defaultValue={
                                  data[field.field_name]?.children?.[
                                    childIdx
                                  ]?.[child.field_name]?.value || ""
                                }
                                handleFileRemove={() =>
                                  handleCompositeRemoveFile(
                                    field,
                                    childIdx,
                                    child,
                                    true
                                  )
                                }
                                onChange={(url) =>
                                  handleCompositeChange(
                                    url,
                                    field,
                                    childIdx,
                                    child,
                                    true
                                  )
                                }
                              />
                            ) : null}
                          </div>
                        ))}
                      </>
                    ) : field.field_type === "Single Attachment" ? (
                      <div style={{ position: "relative" }}>
                        <UploadImage
                          required={field.is_required}
                          defaultValue={
                            data ? data[field.field_name]?.value : null
                          }
                          onChange={(url) => handleFileUpload(url, field)}
                          id={field._id}
                          handleFileRemove={() => handleFileRemove(field)}
                        />
                      </div>
                    ) : field.field_type === "Multiple Attachments" ? (
                      <UploadImage
                        defaultValue={
                          data ? data[field.field_name]?.value : null
                        }
                        onChange={(urls) => handleFileUpload(urls, field)} // Handling multiple file uploads
                        multi={true} // Enable multiple file uploads
                        required={field.is_required}
                        id={field._id}
                        handleFileRemove={() => handleFileRemove(field)}
                      />
                    ) : field.field_type === "List" &&
                      field.children.length > 0 ? (
                      <div>
                        <button
                          style={styles.btn}
                          type="button"
                          onClick={() => handleAddFields(field)}
                        >
                          {language === "en" ? "Add Fields" : "ফিল্ড যোগ করুন"}
                        </button>

                        {listFields.map((listField, idx) => {
                          return (
                            listField.name === field.field_name && (
                              <div key={idx}>
                                {listField.listData.map((child, childIdx) => (
                                  <div
                                    key={childIdx}
                                    style={styles.listFieldContainer}
                                  >
                                    {child.children.map(
                                      (subChild, subChildIdx) => (
                                        <div key={subChildIdx}>
                                          <label>
                                            {subChild.field_name}
                                            {subChild.is_required ? (
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "15px",
                                                  marginLeft: "3px",
                                                }}
                                              >
                                                *
                                              </span>
                                            ) : null}
                                          </label>
                                          {subChild.field_type === "Text" ||
                                          subChild.field_type === "Number" ||
                                          subChild.field_type === "Date" ? (
                                            <input
                                              type={subChild.field_type.toLowerCase()} // Ensure the type matches the input field
                                              required={subChild.is_required}
                                              defaultValue={subChild.value}
                                              onChange={(e) =>
                                                handleListInputChange(
                                                  e,
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                              style={styles.field}
                                              placeholder={subChild.field_name} // Placeholder for each field
                                            />
                                          ) : subChild.field_type ===
                                            "Dropdown" ? (
                                            <select
                                              name={subChild.field_name}
                                              required={subChild.is_required}
                                              defaultValue={subChild.value}
                                              onChange={(e) =>
                                                handleListInputChange(
                                                  e,
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                              className="form-select"
                                            >
                                              <option value="">
                                                Select an option
                                              </option>
                                              {subChild.options
                                                ?.split(",")
                                                .map((option) => (
                                                  <option
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                ))}
                                            </select>
                                          ) : subChild.field_type ===
                                            "Country" ? (
                                            <select
                                              name={subChild.field_name}
                                              defaultValue={subChild.value}
                                              required={subChild.is_required}
                                              className="form-select"
                                              onChange={(e) =>
                                                handleListInputChange(
                                                  e,
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select a Country
                                              </option>
                                              {countries?.map((country) => (
                                                <option
                                                  key={country}
                                                  value={country}
                                                >
                                                  {country}
                                                </option>
                                              ))}
                                            </select>
                                          ) : subChild.field_type ===
                                            "Multiple Attachments" ? (
                                            <UploadImage
                                              required={subChild.is_required}
                                              defaultValue={subChild.value}
                                              style={styles.field}
                                              multi={true}
                                              handleFileRemove={() =>
                                                handleListFileRemove(
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                              onChange={(url) =>
                                                handleListFileUpload(
                                                  url,
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                            />
                                          ) : subChild.field_type ===
                                            "Single Attachment" ? (
                                            <UploadImage
                                              required={subChild.is_required}
                                              defaultValue={subChild.value}
                                              style={styles.field}
                                              handleFileRemove={() =>
                                                handleListFileRemove(
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                              onChange={(url) =>
                                                handleListFileUpload(
                                                  url,
                                                  listField,
                                                  childIdx,
                                                  subChild,
                                                  field.field_name
                                                )
                                              }
                                            />
                                          ) : (
                                            "Unsupported field type"
                                          )}
                                        </div>
                                      )
                                    )}
                                    <button
                                      style={{
                                        ...styles.crossButton,
                                        marginTop: "10px",
                                      }}
                                      onClick={(e) =>
                                        handleRemoveListField(e, idx, childIdx)
                                      } // Pass parent and child index
                                    >
                                      <AiOutlineClose />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer style={styles.modalFooter}>
            <button style={styles.btn} onClick={onCancel} type="button">
              {language === "en" ? "Cancel" : "বাতিল"}
            </button>
            <button style={styles.submitButton} type="submit">
              {language === "en" ? "Submit" : "জমা দিন"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ApplicationModal;
