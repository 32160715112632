import React, { useEffect, useState } from 'react';
import './Terms';
import Editor from '../../../components/Editor/Editor';
import axios from 'axios';

function Terms(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [terms, setTerms] = useState('');
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/static-fields`)
      .then(function (response) {
        // handle success
        const obj = response.data.terms;

        setTerms(obj);
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  return (
    <div
      className='container main-content js-main-content'
      style={{ marginTop: '30px' }}
    >
      <article
        className='mp-box mp-boring-sheet'
        style={{
          padding: '30px 40px',
          border: '1px #ccc solid',
          borderRadius: '5px',
        }}
      >
        <div className='box-row'>
          <Editor readOnly defaultValue={terms} />
        </div>
      </article>
    </div>
  );
}

export default Terms;
