import React, { useState, useEffect,useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "./AuthForm.css";
import "../util/ImageUploader.css";
import Input from "../input/Input";
import LoginWigh from "./LoginWith";
import { AuthContext } from '../../context/auth-context';

import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_CONFIRMPASS,
  VALIDATOR_EMAIL_OR_PHONE,
} from "../../util/Validators";
import { useForm } from "../../hooks/form-hook";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory } from "react-router-dom";
import ErrorModal from "../util/ErrorModal";
import LoadingSpinner from "../util/LoadingSpinner";
import Texts from "../../util/Texts";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const SignUpForm = (props) => {
  const texts = Texts();
  const auth = useContext(AuthContext);
  const recaptchaRef = React.useRef();
  const [showSucces, setShowSuccess] = useState(false);
  let history = useHistory();
  let { search } = useLocation();
  const searchQuery = new URLSearchParams(search);
  const [showReferralInput, setShowReferralInput] = useState(false);

  useEffect(() => {
    const bool = searchQuery.get("referral");

    if (bool) setShowReferralInput(true);
  }, [searchQuery]);

  const onCancel = () => {
    setShowSuccess(false);
  };
  const handleOk = (email) => {
    onCancel();
    history.push(`/verify?email=${email}`);
  };
  const { isLoading, sendRequest } = useHttpClient();
  //login hooks
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },

      password: {
        value: "",
        isValid: false,
      },
      confirm_password: {
        value: "",
        isValid: false,
      },
      referral_token: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const sendSignupRequest = async (event) => {
    event.preventDefault();

    const dataToSend = {
      fullname: formState.inputs.name.value,
      email: formState.inputs.emailorPhone.value,
      password: formState.inputs.password.value,
    };
    if (formState.inputs.referral_token.value) {
      dataToSend.referral_token = formState.inputs.referral_token.value;
    }

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/auth/signup",
        "POST",
        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        notify(
          "success",
          "Success! Check your email or phone to verify your account."
        );
        handleOk(formState.inputs.emailorPhone.value);
      } else {
        notify("err", responseData.message);
      }
    } catch (err) {
      notify("err", err.message);
    }
  };

  const signupComponent = (
    <React.Fragment>
      <div className="auth--wrapper ">
        {isLoading && <LoadingSpinner asOverlay />}
        <h3 className="fw-bold my-4 text-center">
          {" "}
          Let’s Create Your Account!{" "}
        </h3>
        <form style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Input
            element="input"
            id="name"
            type="text"
            label={texts.fullName}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={texts.errName}
            mandatory="true"
            onInput={inputHandler}
            placeholder="Minimum length of name must be 6 character long"
          />
          <Input
            element="input"
            id="emailorPhone"
            type="text"
            label={auth.language="bn"?'ইমেইল বা ফোন নাম্বার':"email or phone"}
            mandatory="true"
            validators={[VALIDATOR_EMAIL_OR_PHONE()]}
            errorText={texts.errEmailorPhone}
            onInput={inputHandler}
            placeholder="Enter a valid email"
          />

          <Input
            element="input"
            id="password"
            type="password"
            label={texts.password}
            mandatory="true"
            validators={[VALIDATOR_MINLENGTH(8)]}
            errorText={texts.errPass}
            onInput={inputHandler}
            placeholder="Minimum length for password is 8 characters"
          />
          <Input
            element="input"
            id="confirm_password"
            type="password"
            mandatory="true"
            label={texts.confirmPass}
            validators={[
              VALIDATOR_CONFIRMPASS(formState.inputs.password.value),
            ]}
            errorText={texts.errPassNoMatch}
            onInput={inputHandler}
          />
          {!searchQuery.get("referral") && (
            <div className="my-3 d-flex justify-content-start align-items-center">
              <input
                type="checkbox"
                name=""
                value={showReferralInput}
                onClick={() => setShowReferralInput(!showReferralInput)}
                className="me-2"
                style={{ height: "18px", width: "18px" }}
              />
              <p className="m-0">Do you want to enter a Referral code?</p>
            </div>
          )}
          {showReferralInput && (
            <Input
              element="input"
              id="referral_token"
              type="text"
              label={texts.referralCode}
              validators={[
                VALIDATOR_CONFIRMPASS(formState.inputs.referral?.value),
              ]}
              initialValue={searchQuery.get("referral")}
              onInput={inputHandler}
            />
          )}

          <div className="button--wrapper">
            {/* <Button
					<Input
						element="input"
						id="password"
						type="password"
						label={texts.password+'*'}
						validators={[VALIDATOR_MINLENGTH(8)]}
						errorText={texts.errPass}
						onInput={inputHandler}
						placeholder="Minimum length for password is 8 characters"
					/>
					<Input
						element="input"
						id="confirm_password"
						type="password"
						label={texts.confirmPass+'*'}
						validators={[
							VALIDATOR_CONFIRMPASS(formState.inputs.password.value),
						]}
						errorText={texts.errPassNoMatch}
						onInput={inputHandler}
					/>
				
					<div className="button--wrapper">
						{/* <Button
              onClick={sendSignupRequest}
              variant='primry'
              isDisabled={!formState.isValid ? 'true' : ''}
              type='submit'
            ></Button> */}
            <button
              isDisabled={!formState.isValid ? "true" : ""}
              type="submit"
              onClick={sendSignupRequest}
              className="btn prime-btn my-4 w-100"
            >
              {texts.createAccount}
            </button>
          </div>
        </form>
        <div className=" pb-3">
          <Link to="/login">
            <p
              style={{
                color: "var(--primary_color)",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              Log in
            </p>
          </Link>
          <p className="text-center"> Or try another way</p>
          <LoginWigh type="signup" />
        </div>

        {/* <div className='bottom--wrapper'>
          <p className='na'>{texts.haveAccount}</p>
          <Link to='/login'>
            <p
              style={{
                color: '#0000D6',
                textDecoration: 'underline',
              }}
            >
              {texts.login}
            </p>
          </Link>
        </div> */}
      </div>
    </React.Fragment>
  );

  return <div>{signupComponent}</div>;
};

export default SignUpForm;
