import React, { useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import ErrorModal from '../../util/ErrorModal';
import LoadingSpinner from '../../util/LoadingSpinner';
import Modal from '../../util/Modal';

const SendMessage = (props) => {
  const [msgToSend, setMsgToSend] = useState('');
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const sendTheMessage = async () => {
    if (msgToSend.length > 0) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/send_message`,
          'POST',
          JSON.stringify({
            receiver_id: props.id,
            content: msgToSend,
          }),
          {
            'Content-Type': 'application/json',
          }
        );
        if (responseData.success === 'yes') {
          setMsgToSend('');
          props.onCancel();
        }
      } catch (err) {}
    }
  };
  return (
    <Modal
      className='top'
      contentOnly
      onCancel={props.onCancel}
      show={props.show}
    >
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='send__container'>
        <img onClick={props.onCancel} src='/assets/cross.svg' alt='' />
        <h3>
          send message to <span>{props.fullname}</span>
        </h3>

        <div className='message__bottom'>
          <input
            value={msgToSend}
            onChange={(e) => {
              setMsgToSend(e.target.value);
            }}
            className='message__box'
            type='text'
            placeholder='Write something to send..'
          />
          <button onClick={sendTheMessage} className='send__message'>
            Send
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SendMessage;
