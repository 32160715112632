import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BidCard from '../../components/card/BidCard';
import JobCard from '../../components/card/JobCard';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import './BidDetails.css';

const BidDetails = () => {
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const [bid, setBid] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { bidID } = useParams();
  const { isLoading, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  useEffect(() => {
    const fetchBid = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/bid/?bid_id=${bidID}`
        );
        setBid(responseData);
      } catch (err) {}
    };
    fetchBid();
    //eslint-disable-next-line
  }, [refresh]);
  const onSuccess = () => {
    setRefresh(!refresh);
  };
  return (
    <div className='deal_detail_container'>
      {isLoading && <LoadingSpinner asOverlay />}
      {bid && (
        <BidCard
          detail
          key={bid._id}
          id={bid._id}
          bidDate={new Date(bid.createdAt).toLocaleDateString(
            undefined,
            dateOptions
          )}
          provider={auth.user && auth.user._id === bid.provider_id._id}
          bidBudget={bid.claimed_budget}
          jobBudget={1000} //static rakhsi
          comment={bid.comment}
          status={bid.state}
          acceptedbyclient={bid.is_accepted_by_client}
          acceptedbyprovider={bid.is_accepted_by_provider}
          onSuccess={onSuccess}
          providerId={bid.provider_id._id}
          providerName={bid.provider_id.fullname}
          providerRating={bid.provider_id.provider_rating}
          providerProfilePic={bid.provider_id.profile_pic}
        >
          <JobCard
            key={bid.job_id}
            id={bid.job_id._id}
            title={bid.job_id.title}
            category={bid.job_id.parent_category}
            subCategory={bid.job_id.category}
            budget={bid.job_id.max_budget}
            postDate={new Date(bid.job_id.createdAt).toLocaleDateString(
              undefined,
              dateOptions
            )}
            description={bid.job_id.description}
          />
        </BidCard>
      )}
    </div>
  );
};

export default BidDetails;
