import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { PAY_PROVIDER } from '../../../lib/api/admin';
import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Modal from '../../shared/Modal/Modal';
import Select from '../../shared/Select/Select';
import './MakePaymentForDueAmountModal.css';

export default function MakePaymentForDueAmountModal({ children, data }) {
  const [open, setOpen] = useState(false);
  const { handleSubmit, register } = useForm({
    defaultValues: useMemo(() => data, [data]),
  });
  const queryClient = useQueryClient();
  const { mutateAsync: payProviderMutation } = useMutation(PAY_PROVIDER);

  const handlePay = useCallback(
    async (values) => {
      try {
        await payProviderMutation(values);
        await queryClient.refetchQueries('GET_DUE_PAYMENTS_BY_ID');
        toast.success('Successfully uploaded the image!');
        setOpen(false);
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [payProviderMutation, queryClient]
  );

  return (
    <Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })}
      <Modal open={open} onClose={setOpen} title='Making Payment'>
        <div className='makePaymentForDueAmountModal'>
          <p>Paying User: </p>
          <h3>{data?.deal_id?.provider_id?.fullname}</h3>
          <form onSubmit={handleSubmit(handlePay)}>
            <Input
              type='number'
              label='Set Amount'
              {...register('total_amount')}
            />
            <Input
              disabled
              type='text'
              label='Deal ID'
              {...register('deal_id')}
            />
            <Input
              type='text'
              label='Transaction ID'
              {...register('transaction_id')}
            />

            <label className='d-block mt-3'>
              <strong>Select Payment Method</strong>
              <Select variant='ghost_white' {...register('transaction_issuer')}>
                <option value=''>Select Payment Method</option>
                {payment_options.map((item, ind) => (
                  <option key={ind} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </label>

            <br />
            <Button type='submit' variant='primary'>
              Save Payment
            </Button>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

const payment_options = [{ label: 'BKASH', value: 'bkash' }];
