import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SetNewPassword from "../components/authentication/SetNewPassword";
import AuthSection from "../sections/authentication/AuthSection";

const TokenizeNew = () => {
  const { token } = useParams();
  useEffect(() => {
    if (token) localStorage.setItem("auth_token", token);
    //eslint-disable-next-line
  }, [token]);
  return (
    <div>
      <AuthSection variant="set_new" />
    </div>
  );
};

export default TokenizeNew;
