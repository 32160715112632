import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import ConsultancyCard from "./ConsultancyCard";

const Consultations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const auth = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(500); // Track total pages from backend
  const [consultancySide, setConsultancySide] = useState("client");
  const [consultancyData, setConsultancyData] = useState([]);
  const [noData, setNoData] = useState(false);
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    if (auth.user) {
      fetchConsultations();
    }
  }, [auth.user, consultancySide, currentPage]);

  const fetchConsultations = async () => {
    try {
      console.log("Fetching consultations for page:", currentPage);
      const url = `${process.env.REACT_APP_BACKEND_URL}/consultancy/get?page=${currentPage}`;
      const responseData = await sendRequest(url);
      setConsultancyData(responseData.consultancy);

      console.log(responseData.consultancy.length);
      if (responseData.consultancy.length === 0) {
        setNoData(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Increment page number
  const handleNextPage = () => {
    if (currentPage) {
      console.log("Next Page:", currentPage + 1);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Decrement page number
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="px-5 deals_page">
      <div style={{ marginBottom: "50px" }}>
        <h1 className="mb-6">My Consultations</h1>
      </div>
      {noData && (
        <div style={{ marginBottom: "10px" }}>No More Consultancy Found!</div>
      )}
      <ConsultancyCard
        consultations={consultancyData}
        withSide={consultancySide}
      />

      {/* Pagination Controls */}
      <div
        className="pagination-controls"
      >
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="page-arrow"
        >
          &laquo; Previous
        </button>
        <span className="page-number">Page {currentPage}</span>
        <button
          onClick={handleNextPage}
          disabled={noData}
          className="page-arrow"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  );
};

export default Consultations;
