import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

import { AuthContext } from "../../context/auth-context";
import { GET_USER_DEAL } from "../../lib/api/admin";

import SideBar from "../../components/SideBar";
import ClientDetailsDealDetails from "./ClientDetailsDealDetails";
import JobDetailsDealDetails from "./JobDetailsDealDetails";
import SubmitWork from "../modals/Profile/SubmitWork";
import ShowFiles from "../modals/Profile/ShowFiles";
import SuccessModal from "../util/SuccessModal";
import Button from "../../components/shared/Button/Button";
import SubmitJonModal from "../../components/modals/SubmitJonModal/SubmitJonModal";

import "./ClientPrintDealDetails.css";

export default function ClientServiceDealDetails(props) {
  const { id } = useParams();
  const { data: dealData } = useQuery(
    [GET_USER_DEAL.name, { deal_id: id }],
    GET_USER_DEAL,
    { enabled: !!id }
  );
  const [files, setFiles] = useState(null);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const history = useHistory();
  const auth = useContext(AuthContext);
  const closedByAdmin = dealData?.data?.closed_by_admin;

  const onCancel = () => setShowSubmitModal(false);
  const onSuccess = () => setShowSubmit(true);

  return (
    <div className="client_deal_details">
      <div className="client_deal_sidebar">
        <SideBar />
      </div>
      <div className="userProfile--container clientPrintDealDetails">
        {closedByAdmin && (
          <div
            style={{
              padding: "15px",
              backgroundColor: "rgba(255,0,0,.2)",
              marginBottom: "20px",
            }}
          >
            Deal has been closed by admin!
          </div>
        )}
        {!closedByAdmin && (
          <SubmitWork
            show={showSubmitModal}
            onCancel={onCancel}
            onSuccess={onSuccess}
            dealId={id}
            submitted={props.clientDeals?.job_submitted}
            closedByAdmin={closedByAdmin}
          />
        )}
        {!closedByAdmin && (
          <SuccessModal
            message="File uploaded successfully."
            show={showSubmit}
            onClear={() => setShowSubmit(false)}
            onSuccess={onSuccess}
            closedByAdmin={closedByAdmin}
          />
        )}
        <ShowFiles
          file={files}
          show={showPreview}
          clientApproval={props.clientDeals?.clientApproval}
          dealId={id}
          onCancel={() => setShowPreview(false)}
          onSuccess={() => setShowPreview(false)}
          providerDeal={props.clientDeals?.providerDeal}
          closedByAdmin={closedByAdmin}
        />

        <div className="clientPrintDealDetails__top">
          <button
            style={{ cursor: closedByAdmin ? "not-allowed" : "pointer" }}
            onClick={() => history.push(`/profile/${auth.user._id}`)}
          >
            Back to profile
          </button>{" "}
          Service Deal Details
        </div>
        
        <ClientDetailsDealDetails
          property={props.clientDeals}
          deal_id={dealData?.data?._id}
          deal={dealData?.data}
          closedByAdmin={closedByAdmin}
        />

        {dealData?.data?.client_id?._id !== auth.user._id && (
          <div className="clientPrintDealDetails__submitWork">
            <h3>Submit Your Work</h3>
            <div className="clientPrintDealDetails__divider" />
            <br />
            {!closedByAdmin && (
              <h4>
                Submit Work...
                <div style={{ marginLeft: "10px" }}>
                  <SubmitJonModal
                    deal_id={id}
                    delivery={dealData?.data?.job_id?.delivery}
                  >
                    <Button variant="primary" type="button">
                      Submit
                    </Button>
                  </SubmitJonModal>
                </div>
              </h4>
            )}
            <p>
              This is the final work client will be getting after he/she has
              completed his payment. Remember, you getting paid depends on the
              client paying the system.
            </p>
          </div>
        )}
        <JobDetailsDealDetails
          property={props.clientDeals}
          deal={dealData?.data}
          closedByAdmin={closedByAdmin}
        />
      </div>
    </div>
  );
}
