import axios from "axios";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import "./NotificationItem.css";
const NotificationItem = ({ notification, log }) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const timeOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const getWhereToRedirect = () => {
    switch (log ? notification.action : notification.type) {
      case "JOB": {
        return `/${
          auth.isAdmin || auth.isManager ? "approve-job" : "my-post"
        }/${notification[log ? "action_id" : "content_id"]}`;
      }
      case "JOB_SUBMISSION": {
        return `/deal/${notification[log ? "action_id" : "content_id"]}`;
      }
      case "DEAL": {
        return `/deal/${notification[log ? "action_id" : "content_id"]}`;
      }

      case "BID": {
        return `/bid/${notification[log ? "action_id" : "content_id"]}`;
      }
      default: {
        break;
      }
    }
  };
  const setAsSeen = async () => {
    try {
      const res = axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `/notifications/${notification._id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      console.log(res);
      if (res.status !== 200) throw new Error(res?.data?.msg);
    } catch (error) {}
  };
  console.log(notification);
  return (
    <div
      className="not_item_container"
      onClick={() => {
        setAsSeen();
        auth.setRefresh();
      }}
    >
      <div>
        <p
          style={{
            margin: "0px",
            fontWeight: !notification.is_seen ? "600" : "normal",
            color: !notification.is_seen ? "black" : null,
          }}
        >
          {auth.language === "en"
            ? notification.message
            : notification.message_bn}
        </p>
        <p className="date_p">
          {new Date(notification.createdAt)
            .toLocaleDateString(undefined, timeOptions)
            .toLowerCase()}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
