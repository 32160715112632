import React, { useContext } from 'react';
import JobPostCard from './JobPostCard';
import { AuthContext } from '../../../context/auth-context';

function ShowjobPosts({ jobPosts, loading }) {
  const auth = useContext(AuthContext);

  if (loading) {
    return <h2> {auth.language === 'en' ? 'Loading... ' : 'লোড হচ্ছে... '}</h2>;
  }

  return (
    <div className=''>
      <div
        className='my-5'
        style={{
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
        }}
      >
        {jobPosts.map((item) => (
          <div className='viewCards' key={item._id}>
            <JobPostCard jobPost={item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShowjobPosts;
