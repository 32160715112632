import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import Payment from '../../components/modals/admin/Payment';
import Paginator from '../../components/util/Paginator';
import { Link } from 'react-router-dom';
const GetDues = () => {
  const [dues, setDues] = useState(null);
  const [selectedDue, setSelectedDue] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const { isLoading, sendRequest } = useHttpClient();
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const onCancel = () => {
    setPaymentModal(false);
  };
  const onSuccess = () => {
    setRefresh(!refresh);
  };
  useEffect(() => {
    const fetchDues = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_due_payments/?page=${page}`
        );
        setDues(responseData);
      } catch (err) {}
    };
    fetchDues();
    //eslint-disable-next-line
  }, [page, refresh]);

  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}
      {
        <Payment
          show={paymentModal}
          onCancel={onCancel}
          onSuccess={onSuccess}
          deal_id={selectedDue}
        />
      }
      <h3>Due Payments</h3>
      <div className='manage__box blue'>
        <Link to='/successful-payments'>
          <p className='refund__p'>
            <u>successful payments</u>
          </p>
        </Link>
        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td>Job Title</td>
              <td>Provider Name</td>
              <td>Previous Fine</td>
              <td>Due Amount (With Fine)</td>
              <td>Mobile Banking Number</td>
              <td>Platform</td>
              <td>Deal</td>
              <td>Status</td>
            </tr>
            {dues &&
              dues.map((due, ind) => {
                return (
                  <tr className='t_bottom' key={ind}>
                    <td
                      onClick={() => {
                        window
                          .open(`/job-details/${due.deal_id.job_id._id}`)
                          .focus();
                      }}
                    >
                      <u className='provider_name'>
                        {due.deal_id.job_id.title}
                      </u>
                    </td>
                    <td
                      onClick={() => {
                        window
                          .open(`/profile/${due.deal_id.provider_id._id}`)
                          .focus();
                      }}
                    >
                      <u className='provider_name'>
                        {due.deal_id.provider_id.fullname}
                      </u>
                    </td>
                    <td>{due.deal_id.provider_id.fine} BDT</td>
                    <td>
                      {due.provider_due - due.deal_id.provider_id.fine} BDT
                    </td>
                    <td>
                      {due.deal_id.provider_id.mobile_banking_phone_number}
                    </td>
                    <td>{due.deal_id.provider_id.mobile_banking_provider}</td>
                    <td
                      className='last_td_gn'
                      onClick={() => {
                        window.open(`/deal/${due.deal_id._id}`).focus();
                      }}
                    >
                      {<u className='provider_name'>view Deal</u>}
                    </td>
                    {
                      <td className='pay_provider_u'>
                        {due.provider_paid ? (
                          <p>PAID</p>
                        ) : (
                          <button
                            className='pay__btn'
                            onClick={() => {
                              setPaymentModal(true);
                              setSelectedDue(due.deal_id._id);
                            }}
                          >
                            Pay
                          </button>
                        )}
                      </td>
                    }
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default GetDues;
