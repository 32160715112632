import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../../button/Button";
import AcceptBid from "./AcceptBid";
import "./ProviderList.css";
const ProviderListItem = (props) => {
  const history = useHistory()
  const [showAcceptBid, setShowAcceptBid] = useState(false);
  const onCancel = () => {
    setShowAcceptBid(false);
  };
  return (
    <div className="providerlist__item">
      <AcceptBid
        onSuccess={props.onSuccess}
        id={props.bid_id}
        show={showAcceptBid}
        onCancel={onCancel}
      />
      <div className="list__header">
        {/* <Link
          target="new"
          style={{ textDecoration: "none", color: "black" }}
          to={`/profile/${props.id}`}
        > */}
          <div onClick={() => history.push(`/profile/${props.id}`)}
          className="profile--div">
            {props.name}
            <img src="/assets/newtab.svg" alt="" />
          </div>
        {/* </Link> */}
        <Button
          isDisabled={props.accepted}
          onClick={() => {
            setShowAcceptBid(true);
          }}
          buttonStyle="btn--apply"
          style={{float:"right"}}
        >
          {props.accepted ? "Accepted" : "Accept Bid"}
        </Button>
      </div>
      <p>
        <span>{props.updatedAt}</span>
      </p>
      <p>
        <span>Budget:</span> <strong>{props.budget} BDT</strong>
      </p>
      <p>
        <span>Comment:</span> {props.comment}
      </p>

      <hr />
    </div>
  );
};

export default ProviderListItem;
