import React from "react";
import "./Card.css";
import SkillItem from "../items/SkillItem";
const AccBackground = (props) => {
  return (
    <div className="personalinfo--container">
      <div className="div--personalinfo">
        <h4>Expertise</h4>
        {props.expertise && props.expertise.length === 0 && (
          <p className="nothing__toshow">nothing to show</p>
        )}
        <div className="container--infos">
          {props.expertise.map((item) => {
            return <SkillItem key={item} text={item} />;
          })}
        </div>

        <h4>Achievement</h4>
        {props.achievement && props.achievement.length === 0 && (
          <p className="nothing__toshow">nothing to show</p>
        )}
        <div className="container--infos">
          {props.achievement &&
            props.achievement.map((item) => {
              return (
                <div className="items">
                  <p className="item--p1">{item.name}</p>
                  <p className="item--p2">{item.description}</p>
                </div>
              );
            })}
        </div>
        <h4>School</h4>
        {props.school && props.school.length === 0 && (
          <p className="nothing__toshow">nothing to show</p>
        )}
        <div className="container--infos">
          {props.school &&
            props.school.map((item) => {
              return (
                <div className="items">
                  <p className="item--p1">{item.school}</p>
                  <p className="item--p2">
                    {item.degree + ",passing " + item.passing_year}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AccBackground;
