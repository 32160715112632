import React, { useState } from "react";
import DetailedConsultancy from "./DetailedConsultancy";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ConsultancyCard = ({ consultations, withSide }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedConsultation, setSelectedConsultation] = useState(null);

  const handleShow = (consultation) => {
    setSelectedConsultation(consultation);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedConsultation(null);
  };

  return (
    <div>
      {consultations.map((consultation, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginBottom: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <div style={{ display: "flex", gap: "10px" }}>
              <strong>Agent Name :</strong> {consultation.agent_id.fullname}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <strong>Student Name :</strong> {consultation.client_id.fullname}
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>
              Created At:{" "}
              {new Date(consultation.createdAt).toLocaleDateString()}
            </div>
            <Link to={`/consultations/${consultation._id}/${withSide}`}>
              <div
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                View Details
              </div>
            </Link>
          </div>
        </div>
      ))}
      {selectedConsultation && (
        <DetailedConsultancy
          show={showModal}
          handleClose={handleClose}
          consultation={selectedConsultation}
        />
      )}
    </div>
  );
};

export default ConsultancyCard;
