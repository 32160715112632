import React from 'react';

export default function QComponent({ status, error, children }) {
  if (status === "loading") return (
    <div className="d-flex p-5 justify-content-center align-items-center">
      <img src="/assets/new/loading.svg" width="35" alt="loading..." />
    </div>
  )
  if (status === "error") return (
    <div className="alert alert-danger" role="alert">
      <strong>Error: </strong> {error?.response?.data.msg || error?.message || "Something went wrong, please try again later."}
    </div>
  )
  return children;
}