import React, { useEffect } from "react";
import AdminManagement from "../sections/admin/AdminManagement";
const ManageAdmins = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <AdminManagement />;
};

export default ManageAdmins;
