import React, { useRef, useEffect, useContext } from 'react';
import './ImageUploader.css';
import { AuthContext } from '../../context/auth-context';

const FileUploader = ({ updatestate }) => {
  const fileInputRef = useRef();

  const auth = useContext(AuthContext);

  return (
    <div className='image__uploader file__uploader'>
      <div
        className='custom-uploader-btn btn'
        onClick={(event) => {
          fileInputRef.current.click();
        }}
      >
        <span className='upload_txt'>
          {' '}
          {auth.language === 'en' ? 'Upload' : 'আপলোড করুন'}
        </span>{' '}
      </div>
      <input
        multiple
        type='file'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event.target.files;
          if (file) {
            updatestate(file);
            console.log('fileuploader',file);
          } else {
            updatestate(null);
          }
        }}
      />
    </div>
  );
};

export default FileUploader;
