import React, { useCallback, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./Pagination.css";

const PAGE_NUM_LIMIT = 5;
export default function Pagination({ total, page, prev, next, setPage }) {
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

  const handlePageNumClick = useCallback((p) => {
    setPage(+p);
  }, [setPage]);
  const handleNextNumClick = useCallback(() => {
    setPage(page => page + 1);

    if (page === maxPageNumberLimit) {
      setMinPageNumberLimit(value => value + PAGE_NUM_LIMIT);
      setMaxPageNumberLimit(value => value + PAGE_NUM_LIMIT);
    }
  }, [setPage, page, maxPageNumberLimit]);
  const handlePrevNumClick = useCallback(() => {
    setPage(page => page - 1);

    if (page === minPageNumberLimit + 1) {
      setMinPageNumberLimit(value => value - PAGE_NUM_LIMIT);
      setMaxPageNumberLimit(value => value - PAGE_NUM_LIMIT);
    }
  }, [setPage, page, minPageNumberLimit]);

  return total > 0 ? (
    <div className="pagination">
      <button type="button" disabled={page === 1} onClick={handlePrevNumClick}><FaChevronLeft /></button>
      {page > PAGE_NUM_LIMIT && <button type="button">...</button>}
      {new Array(total).fill("").map((_, ind) => (ind + 1) <= maxPageNumberLimit && (ind + 1) > minPageNumberLimit ? (
        <button type="button" className={ind + 1 === page ? "current" : ""} onClick={() => handlePageNumClick(ind + 1)}>{ind + 1}</button>
        ) : null)}
      {page - 1 < total - PAGE_NUM_LIMIT && <button type="button">...</button>}
      <button disabled={page === total} type="button" onClick={handleNextNumClick}><FaChevronRight /></button>
    </div>
  ) : null;
}