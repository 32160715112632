import React, { useEffect, useState } from "react";
import "../components/util/ImageUploader.css";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../hooks/http-hook";
import LoadingSpinner from "../components/util/LoadingSpinner";
import { useHistory } from "react-router-dom";
import Modal from "../components/util/Modal";
const VerifyEmail = () => {
  const history = useHistory();
  const { token } = useParams();
  const { isLoading, sendRequest } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const onCancel = () => {
    setShowSuccess(false);
  };
  const handleOk = () => {
    onCancel();
    history.push("/login");
  };
  useEffect(() => {
    const verify = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/verify_email`,
          "POST",
          JSON.stringify({
            token: token.toString(),
          }),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.success === "yes") {
          setShowSuccess(true);
          history.push("/");
        }
      } catch (err) {}
    };
    verify();
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      {isLoading && <LoadingSpinner asOverlay />}
      <Modal contentOnly onCancel={onCancel} show={showSuccess}>
        <div className="confirmation_box">
          <h3>Your email has been verified successfully ! </h3>
          <button onClick={handleOk}>okay</button>
        </div>
      </Modal>
    </div>
  );
};

export default VerifyEmail;
