import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DotMenu from '../../components/admin/DotMenu';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import Paginator from '../../components/util/Paginator';
import { useHttpClient } from '../../hooks/http-hook';
import './AdminManagement.css';

const ManageJobs = () => {
  const history = useHistory();
  const [jobs, setJobs] = useState(null);
  const { isLoading, sendRequest } = useHttpClient();
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const fetchUnapprovedJobs = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/get_unapproved_jobs/?page=${page}`
      );
      setJobs(responseData);
    } catch (err) {}
  };

  useEffect(() => {
    fetchUnapprovedJobs();
    //eslint-disable-next-line
  }, [refresh, page]);
  const viewd = () => {
    history.push('/manage-jobs/d');
  };
  const refreshJobs = () => {
    setRefresh(!refresh);
  };

  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>Jobs Panel</h3>
      <div className='manage__box blue'>
        <p className='pboxe'>
          These Jobs need Approval. Review and Approve them as soon as possible.
        </p>
        <p onClick={viewd} className='view_smth'>
          View Jobs Dissaproved by Admin
        </p>

        <p className='view_smth'></p>

        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td> Name</td>
              <td>Description</td>
              <td>Category-SubCategory</td>
              <td></td>
            </tr>
            {jobs &&
              jobs.map((job) => {
                return (
                  <tr className='t_bottom'>
                    <td>{job.title}</td>
                    <td>{job.description.substring(0, 20) + '...'}</td>
                    <td>
                      {(job.parent_category.length > 0
                        ? job.parent_category + ' - '
                        : '') + job.category[0]}
                    </td>
                    <td className='last_td_gn'>
                      {
                        <Link to={`/${'approve-job'}/${job._id}`}>
                          <u>view Job</u>
                        </Link>
                      }

                      <DotMenu approve jobID={job._id} refresh={refreshJobs} />
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default ManageJobs;
