import React, { useState } from 'react';
import { useHistory } from 'react-router';
import RateUser from '../modals/Profile/RateUser';
import SuccessModal from '../util/SuccessModal';
import './UserCard.css';
const UserCard = (props) => {
  const history = useHistory();
  const [showRateModal, setShowRateModal] = useState(false);
  const [showRateSuccess, setShowRateSuccess] = useState(false);
  return (
    <React.Fragment>
      <RateUser
        show={showRateModal}
        providerId={props.providerId}
        clientId={props.clientId}
        provider={props.provider}
        dealId={props.dealId}
        onSuccess={() => {
          setShowRateSuccess(true);
        }}
        onCancel={() => {
          setShowRateModal(false);
        }}
      />
      <SuccessModal
        message='Rating was successful. Thank you for your feedback.'
        show={showRateSuccess}
        onClear={() => {
          setShowRateSuccess(false);
        }}
      />
      <div
        onClick={() => {
          !props.self && window.open(`/profile/${props.id}`);
          props.self && history.push(`/profile/${props.id}`);
        }}
      >
        <div className='user__card__tiny'>
          {/* Dynamically --> service or print  */}
          {/* {props.detailed && <p className="green__detail">Service or print Provider</p>} */}
          <div className='head__section'>
            <img src={props.picture} alt='' />
            <div className='bottom--text'>
              <p>
                <strong>{props.provider ? 'Provider' : 'Client'} </strong>
                {props.name}
              </p>
              <div className='rate__user'>
                <p>
                  <strong>
                    {props.provider ? 'Provider' : 'Client'} Rating:{' '}
                  </strong>
                  {props.rating}
                </p>
                {props.rate && (
                  <div
                    className='rate__p1'
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowRateModal(true);
                    }}
                  >
                    Rate User
                  </div>
                )}
              </div>
            </div>
          </div>
          {props.detailed && (
            <div className='user__card__detailed'>
              {/* <p>
                <span className="strong_text">Bid Date: </span>
                {props.bidDate}
              </p> */}
              <p>
                <span className='strong_text'>Claimed Budget: </span>
                <span className='green__text'>{props.claimedBudget} BDT</span>
              </p>
              {/* <p className="comment_box">
                {props.comment && (
                  <span className="strong_text">Provider's Message: </span>
                )}
                {props.comment && (
                  <span className="grey_text"> {props.comment}</span>
                )}
              </p> */}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserCard;
