import React, { useEffect } from "react";
import NewNavBar from "../components/navigation/NewNavBar";
import NewFooter from "../components/footer/NewFooter";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function Unsubscribe(props) {
  const { token } = useParams();
  useEffect(async () => {
    console.log(token);
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + `/unsubscribe?token=${token}`
    );
  }, []);
  return (
    <div style={{ height: "100vh" }}>
      <NewNavBar />
      <div
      
        style={{
          width: "100vw",
          height: "85%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "green",
          fontSize: "1.5rem",
          textAlign: "center",
          lineHeight: "2rem",
        }}
      >
        You Have Successfully Unsubscribed!
      </div>
      <NewFooter />
    </div>
  );
}

export default Unsubscribe;
