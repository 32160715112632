import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./AuthForm.css";
import Input from "../input/Input";
import { Button } from "../button/Button";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL_OR_PHONE,
} from "../../util/Validators";
import { useForm } from "../../hooks/form-hook";
import LoginWith from "./LoginWith";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { useHistory } from "react-router-dom";
import ErrorModal from "../util/ErrorModal";
import LoadingSpinner from "../util/LoadingSpinner";
import { withRouter } from "react-router-dom";
import Texts from "../../util/Texts";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import { toast } from "react-toastify";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const LoginForm = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const texts = Texts();
  let history = useHistory();
  const auth = useContext(AuthContext);
  const recaptchaRef = React.useRef();
  const [isVerified, setIsVerified] = useState(false);
  const [errorM, setErrorM] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [url, setUrl] = useState();

  const [formState, inputHandler] = useForm(
    {
      emailorPhone: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const globals = async () => {
    try {
      const res = await axios.get("https://somadha.com/api/ui-config", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });
      setUrl(res?.data?.auth_background?.login_background_image);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    globals();
    if (localStorage.getItem("auth_token")) {
      history.go(-1);
    }
  }, []);

  //send fetch request
  const sendLoginRequest = async (event) => {
    event.preventDefault();
    const returnValue = await handleReCaptchaVerify();
    if (returnValue === false) {
      return;
    }

    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/auth/signin",
        {
          email: formState.inputs.emailorPhone.value,
          password: formState.inputs.password.value,
        },
        {
          "x-access-token": localStorage.getItem("auth_token"),
        }
      );

      if (responseData.data.amar_shomadhan_token.length > 0) {
        localStorage.setItem(
          "auth_token",
          responseData.data.amar_shomadhan_token
        );

        console.log("token", localStorage.getItem("auth_token"));

        auth.login();
        history.push("/");
        props.setupSocket();
        auth.setUser();
        notify("success", "Successfully logged in!");
      } else {
        console.log("asda", responseData);
        throw new Error(responseData);
      }
    } catch (err) {
      if (err.response.data.msg === "Email not verified")
        history.push(`/verify?email=${formState?.inputs.emailorPhone.value}`);
      notify("err", err?.response?.data?.msg || "Something Went Wrong!");
      // setErrorM(true);
    }
  };

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      return;
    }

    try {
      const token = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action: "login" }
      );

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/recaptcha_verify",
        "POST",
        JSON.stringify({
          response: token,
          secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
        }),
        {
          secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data

      if (responseData.success === true) {
        return true;
      } else return false;
    } catch (err) {
      // return false;
    }
  };

  const loginComponent = (
    <React.Fragment>
      <div className="auth--wrapper">
        {isLoading && <LoadingSpinner asOverlay />}
        <h3 className="fw-bold my-4 text-center">Login To Explore!</h3>
        <form style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            size='invisible'
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          /> */}
          <Input
            element="input"
            id="emailorPhone"
            type="text"
            label={
              auth.language === "en" ? "Email or Phone" : "ইমেইল বা ফোন নাম্বার"
            }
            mandatory="true"
            validators={[VALIDATOR_EMAIL_OR_PHONE()]}
            errorText={texts.errEmailorPhone}
            onInput={inputHandler}
            placeholder="Enter a valid email or phone number"
          />
          <Input
            element="input"
            id="password"
            type="password"
            label={texts.password}
            mandatory="true"
            validators={[VALIDATOR_MINLENGTH(8)]}
            errorText={texts.errPass}
            onInput={inputHandler}
          />
          <div className="button--wrapper">
            <button
              isDisabled={!formState.isValid ? "true" : ""}
              type="submit"
              onClick={sendLoginRequest}
              className="btn prime-btn my-4 w-100"
            >
              {texts.login}
            </button>
          </div>
        </form>
        <div className=" pb-4">
          <Link to="/forgot-password">
            <p
              style={{
                color: "var(--primary_color)",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              {texts.forgotPassword}
            </p>
          </Link>
          <Link to="/sign-up">
            <p
              style={{
                color: "var(--primary_color)",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              Create a new account
            </p>
          </Link>
          <p className="text-center">Or Try another way</p>
          <LoginWith props={props.setupSocket} type="login" />
        </div>
      </div>
    </React.Fragment>
  );

  return <div>{loginComponent} </div>;
};

export default withRouter(LoginForm);
