import FileSaver from 'file-saver';
import React, { useState } from 'react';
import '../ClientPrintDealDetails.css';
import { ImagePreviewModal } from '../ImagePreviewModal/ImagePreviewModal';
export default function JobDetailsProvider(props) {
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => setIsOpen(true);
  const saveFile = (url) => {
    FileSaver.saveAs(process.env.REACT_APP_BACKEND_URL + '/' + url, url);
  };
  return (
    <div className='clientPrintDealDetails__jobDetails'>
      <h3 style={{ fontSize: '32px' }}>Job Details</h3>
      <div className='clientPrintDealDetails__divider' />
      <br />
      <h2>{props.jobDetails?.title}</h2>
      <span>
        {props.jobDetails?.category.length > 0
          ? props.jobDetails?.category[0] + ' - '
          : '' + props.jobDetails?.subCategory[0]}
      </span>
      <span>Posted on {props.jobDetails?.createdAt.substring(0, 10)}</span>
      <span>Budget {props.jobDetails?.max_budget} BDT</span>
      <p>{props.jobDetails?.description}</p>

      <div className='clientPrintDealDetails__attachments'>
        <div className='attachment'>
          <img src='/assets/attachments.svg' alt='' />
          Attachments
        </div>
        {props.jobDetails?.attachments &&
          props.jobDetails?.attachments.length === 0 && (
            <p className='p6'>no attachments available</p>
          )}
        <table style={{ display: 'flex' }}>
          {props.jobDetails?.attachments &&
            props.jobDetails?.attachments.map((attachment, i) => {
              return (
                <tr key={i}>
                  <td>
                    {/* <a
                          target="_new"
                          href={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                        >
                          <p className="p6">{attachment.substr(6)}</p>
                        </a> */}

                    <img
                      className='img_preview'
                      // onClick={() => {
                      //   saveFile(attachment);
                      // }}
                      onClick={showModal}
                      src={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                      alt=''
                    />

                    {isOpen && (
                      <ImagePreviewModal
                        src={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                        alt='snow'
                        onClose={() => setIsOpen(false)}
                      />
                    )}
                  </td>
                  <td>
                    <img
                      className='download__icon'
                      onClick={() => {
                        saveFile(attachment);
                      }}
                      src='/assets/download.svg'
                      alt=''
                    />
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
}
