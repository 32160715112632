import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import { Link } from 'react-router-dom';
import DotMenu from '../../components/admin/DotMenu';
import ErrorModal from '../../components/util/ErrorModal';
import Paginator from '../../components/util/Paginator';
const AdminManagement = () => {
  const [admins, setAdmins] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const { error, clearError, isLoading, sendRequest } = useHttpClient();
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_all_admin_and_managers/?page=${page}`
        );
        setAdmins(responseData);
      } catch (err) {}
    };
    fetchAdmins();
    //eslint-disable-next-line
  }, [refresh, page]);
  const onSuccess = () => {
    setRefresh(!refresh);
    searchForUser();
  };
  const searchForUser = async () => {
    if (searchText && searchText.length > 0) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_user/?email=${searchText}`
        );

        setSearchResult(responseData);
      } catch (err) {}
    }
  };
  const clearResult = () => {
    setSearchResult(null);
    setSearchText('');
  };
  return (
    <div className='manage__container'>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>Manage Administrators</h3>
      <div className='manage__box pink'>
        <p className='pbox'>
          You have administrative privilage. Your Level is "Admin". Which means
          along with all permissions, you can manage other admins and moderators
          too. <u>learn more...</u>
        </p>

        <div className='adsearch__box'>
          <span>Search User</span>
          <input
            type='text'
            placeholder='Type Email...'
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <button className='search__btn' onClick={searchForUser}>
            <img src='/assets/right_arrow.svg' alt='' />
          </button>
          <button className='search__btn' onClick={clearResult}>
            <img src='/assets/reload.svg' alt='' />
          </button>
        </div>
        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td> Name</td>
              <td>Email</td>
              <td>Role</td>
              <td></td>
            </tr>
            {!searchResult &&
              admins &&
              admins.map((admin) => {
                return (
                  <tr className='t_bottom'>
                    <td>{admin.fullname}</td>
                    <td>{admin.email}</td>
                    <td>{admin.user_type}</td>
                    <td className='last_td_gn'>
                      {
                        <Link target='_blank' to={`/profile/${admin._id}`}>
                          <u>veiw profile</u>
                        </Link>
                      }
                      <DotMenu
                        onSuccess={onSuccess}
                        role={admin.user_type}
                        id={admin._id}
                      />
                    </td>
                  </tr>
                );
              })}
            {searchResult && (
              <tr className='t_bottom'>
                <td>{searchResult.fullname}</td>
                <td>{searchResult.email}</td>
                <td>{searchResult.user_type}</td>
                <td className='last_td_gn'>
                  {
                    <Link to={`/profile/${searchResult._id}`}>
                      <u>visit profile</u>
                    </Link>
                  }
                  <DotMenu
                    onSuccess={onSuccess}
                    role={searchResult.user_type}
                    id={searchResult._id}
                  />
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default AdminManagement;
