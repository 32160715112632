
import React, { useState } from "react"
import "./ImagePreviewModal.css"

export const ImagePreviewModal = ({ src, alt, onClose }) => {
  return (
    <div className="modal-img">
      <span className="close-img" onClick={onClose}>
        &times;
      </span>
      <img className="modal-content-img" src={src} alt={alt} />
     
    </div>
  )
}

