import React, { useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import ModalButton from '../../button/ModalButton';
import Modal from '../../util/Modal';
import './EditExpertise.css';
const RateUser = (props) => {
  const [stars, setStars] = useState([1, 2, 3, 4, 5]);
  const [rating, setRating] = useState(0);
  const [hovered, setHovered] = useState(0);
  const { sendRequest } = useHttpClient();

  const changeRating = (newRating) => {
    setRating(newRating);
  };

  const hoverRating = (rating) => {
    setHovered(rating);
  };

  const rateUser = async () => {
    try {
      let data;
      props.provider
        ? (data = {
            provider_id: props.providerId,
            deal_id: props.dealId,
            rating: rating,
          })
        : (data = {
            client_id: props.clientId,
            deal_id: props.dealId,
            rating: rating,
          });
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/${props.provider ? 'client' : 'provider'}/rate_${
            props.provider ? 'provider' : 'client'
          }`,
        'POST',
        JSON.stringify(data),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onCancel();
        props.onSuccess();
      }
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      <div className='rating__container'>
        <img
          className='exit_edit'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2>Rate User</h2>
        {/* <p className="rate__p">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when{" "}
        </p> */}

        <div className='stars__container'>
          {stars.map((star) => {
            return (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  changeRating(star);
                }}
                onMouseEnter={() => {
                  hoverRating(star);
                }}
                onMouseLeave={() => {
                  hoverRating(0);
                }}
              >
                <img
                  className='rating__stars'
                  src={`/assets/${
                    rating < star
                      ? hovered < star
                        ? 'empty_star.svg'
                        : 'full_star.svg'
                      : 'full_star.svg'
                  }`}
                  alt=''
                />
              </span>
            );
          })}
        </div>
        <div className='report__issue__btn'>
          <ModalButton onClick={rateUser}>Submit</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default RateUser;
