import axios from "axios";

export const http = axios.create({
  baseURL:  process.env.REACT_APP_BACKEND_URL || "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  }
});

http.interceptors.request.use((config) => ({ ...config, headers: { "x-access-token": localStorage.getItem("auth_token") } }), (err) => err);