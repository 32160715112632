import React, { useState } from "react";
import "./Dropdown.css";

export default function Dropdown({ label, options }) {
  const [show, setShow] = useState(false)
  // if(!show) return null
  return <div className="dropdown">
    <span onClick={() => setShow((value) => !value)}>{label || "..."}</span>
    {show && (
    <ul className="dropdown__options">
      {options.map((opt, ind) => <li key={ind} onClick={opt.onClick}>{opt.label}</li>)}
    </ul>
    )}
  </div>
};
