import React, { useContext } from 'react';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth-context';
import { useHttpClient } from '../../../hooks/http-hook';

const notify = (type, msg) => {
  type === 'err'
    ? toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const Security = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypePassword, setReTypePassword] = useState('');
  const auth = useContext(AuthContext);
  const [load, setLoad] = useState(false);
  const { sendRequest } = useHttpClient();
  const [spinnerStatus, setSpinnerStatus] = useState(false);

  const updateUser = async () => {
    if (newPassword !== reTypePassword) {
      notify('err', 'New password didnt match width re-typed password');
      return;
    }

    setSpinnerStatus(true);
    setLoad(true);
    try {
      let dataToSend;

      dataToSend = {
        new_password: newPassword,
        password: oldPassword,
      };

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/update_info',
        'POST',

        JSON.stringify(dataToSend),
        {
          'Content-Type': 'application/json',
        }
      );

      if (responseData.success === 'yes') {
        setSpinnerStatus(false);
        auth.setUser();
        setOldPassword('');
        setNewPassword('');
        setLoad(false);
        setReTypePassword('');
        notify('success', 'Password updated successfully!');
      } else
        throw new Error(responseData?.data?.msg || 'Something Went Wrong!');
    } catch (err) {
      setLoad(false);
      setSpinnerStatus(false);
      setOldPassword('');
      setNewPassword('');
      setLoad(false);
      setReTypePassword('');
      notify('err', err?.response?.data?.msg || err.message);
    }
  };

  return (
    <div className='my-5'>
      <h3 className='mb-3'>
        {' '}
        <strong style={{ color: 'var(--primary_color)' }}>
          {' '}
          {auth.language === 'en' ? 'Update Password' : 'পাসওয়ার্ড আপডেট করুন'}
        </strong>
      </h3>
      <div className='form-section'>
        <label for=''>
          {' '}
          {auth.language === 'en'
            ? 'Current Password'
            : 'বর্তমান পাসওয়ার্ড'}{' '}
          <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='password'
          required
          name=''
          value={oldPassword}
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
        />
      </div>
      <div className='form-section'>
        <label for=''>
          {auth.language === 'en' ? 'New Password' : 'নতুন পাসওয়ার্ড'}{' '}
          <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='password'
          name=''
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
      </div>
      <div className='form-section'>
        <label for=''>
          {' '}
          {auth.language === 'en'
            ? 'Re-type New Password'
            : 'নতুন পাসওয়ার্ড পুনরায় টাইপ করুন'}{' '}
          <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='password'
          name=''
          value={reTypePassword}
          onChange={(e) => {
            setReTypePassword(e.target.value);
          }}
        />
      </div>

      <Button
        type='button'
        className='btn fw-bold'
        style={{ backgroundColor: 'var(--primary_color)' }}
        onClick={updateUser}
      >
        {auth.language === 'en' ? 'Save Changes' : 'পরিবর্তনগুলোর সংরক্ষন'}
        {spinnerStatus && (
          <Spinner animation='border' size='sm' className='text-white ml-1' />
        )}
      </Button>
    </div>
  );
};

export default Security;
