export default {
  jobpostpage_banner_header_en: "Trying to get a work Done?",
  jobpostpage_banner_header_font_en: "Roboto",
  jobpostpage_banner_desc_en:
    " Find something to attract users to post a job",
  jobpostpage_banner_desc_font_en: "Lato",
  jobpostpage_banner_header_bn: "কিছু  করন লাগবো?",
  jobpostpage_banner_header_font_bn: "Hind Siliguri",
  jobpostpage_banner_desc_bn: "এইখানে হিজি বিজি হিজি বিজি",
  jobpostpage_banner_desc_font_bn: "Mini",
};
