import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClientPrintDealDetails from '../components/ClientPrintDealDetails';
import ClientServiceDealDetails from '../components/ClientServiceDealDetails';
import { AuthContext } from '../context/auth-context';
import { useHttpClient } from '../hooks/http-hook';

export default function DemoPage() {
  const { id } = useParams();
  const [clientDeals, setClientDeals] = useState(null);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [refresh, setRefresh] = useState(false);
  const onSuccess = () => {
    setRefresh(!refresh);
  };
  useEffect(() => {
    const fetchClientDeals = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/deal?deal_id=${id}`
        );
        setClientDeals(responseData);
      } catch (err) {}
    };
    if (auth.user) {
      fetchClientDeals();
    }
    //eslint-disable-next-line
  }, [auth.user, refresh]);
  return (
    <>
      {clientDeals?.job_id.print ? (
        <ClientPrintDealDetails clientDeals={clientDeals} deal_id={id} />
      ) : (
        <ClientServiceDealDetails deal_id={id} clientDeals={clientDeals} />
      )}
    </>
  );
}
