import React, { useEffect, useState } from "react";
import axios from "axios";

function Terms() {
  useEffect(() => {
    window.scrollTo(100, 0);
  }, []);

  const [terms, setTerms] = useState("");
  const [iframeHeight, setIframeHeight] = useState("2000px"); // Initial height

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/static-fields`)
      .then((response) => {
        setTerms(response.data.consultation);
      })
      .catch((error) => {
        console.error("Error fetching consultation terms:", error);
      });
  }, []);

  const handleIframeLoad = (event) => {
    const iframe = event.target;
    if (iframe.contentDocument?.body) {
      const height = iframe.contentDocument.body.scrollHeight;
      setIframeHeight(`${height}px`);
    }
  };

  return (
    <div
      className="main-content js-main-content"
      style={{ marginTop: "30px" }}
    >
      <article>
        <div className="box-row">
          <iframe
            title="Consultation Terms"
            srcDoc={terms} // Load the raw HTML
            onLoad={handleIframeLoad}
            style={{
              width: "100%",
              height: iframeHeight,
              border: "none",
            }}
          />
        </div>
      </article>
    </div>
  );
}

export default Terms;