import React, { useCallback, useState } from "react";
import { FaEdit, FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DELETE_CATEGORY, UPDATE_CATEGORY } from "../../../lib/api/admin";
import { deleteConfirmation } from "../../../lib/sweetAlert";
import AddEditCategoryModal from "../../modals/AddEditCategoryModal/AddEditCategoryModal";
import "./AdminCategoryCard.css";

export default function AdminCategoryCard({
  src,
  category_name,
  onClick,
  active,
  category,
  parent,
}) {
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const { mutateAsync: updateCategory } = useMutation(UPDATE_CATEGORY);
  const { mutateAsync: deleteCategory } = useMutation(DELETE_CATEGORY);

  const handleCategoryUpdate = async () => {
    try {
      console.log("hello");
      await updateCategory({
        cat_id: category ? category._id : null,
        values: { is_disabled: category?.is_disabled?false:true },
      });
      await queryClient.refetchQueries(
        parent
          ? parent._id
            ? "GET_SUB_CATEGORIES"
            : "GET_CATEGORIES"
          : "GET_CATEGORIES"
      );
      toast.success(
        `${
          parent ? (parent._id ? "Sub Category" : "Category") : "Category"
        } updated successfully`
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const handleClose = (id) => {
    setShow(false);

    if (id) handleCategoryDeletion(id);
  };

  const handleCategoryDeletion = async (id) => {
    try {
      await deleteCategory(id);
      await queryClient.refetchQueries("GET_CATEGORIES");
      await queryClient.refetchQueries("GET_SUB_CATEGORIES");
      toast.success("Category deleted successfully");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  return (
    <div
      className={`adminCategoryCard ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="adminCategoryCard__left">
        {src && <img src={src} alt="" />} {category_name}
      </div>
      <div className="adminCategoryCard__right d-flex gap-4 align-items-center text-secondary">
        <label style={{ cursor: "pointer", marginBottom: 0 }}>
          <input
            type="checkbox"
            hidden
            checked={category?.is_disabled || false}
            onChange={({ target }) => handleCategoryUpdate(target.checked)}
          />
          <button title="Show/Hide Category" onClick={handleCategoryUpdate}>
            {category?.is_disabled ? <FaEyeSlash /> : <FaEye />}
          </button>
        </label>
        <AddEditCategoryModal
          category={category}
          edit
          {...(!src ? { subCat: parent?.name } : {})}
        >
          <button title="Edit Category">
            <FaEdit />
          </button>
        </AddEditCategoryModal>
        <button
          title="Delete Category"
          onClick={() => {
            setShow(true);
            setId(category._id);
          }}
        >
          <FaTrash />
        </button>
      </div>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="danger" onClick={() => handleClose(id)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
