import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// import './ExploreJob.css';
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ShowWorkProvider from "./ShowWorkProvider";
import { useHttpClient } from "../../../hooks/http-hook";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../Pagination";
import ProviderCardNew from "../../../components/HomeComponent/TopProvider/ProviderCardNew";
import DisplayProviderCard from "./DisplayProviderCard";
import ShowTopProvider from "../../../components/HomeComponent/TopProvider/ShowTopProvider";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const WorkProviderNew = () => {
  const [allProviders, setallProviders] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [selectedCat, setSelectedCat] = useState("all");
  const [searchKey, setSearchKey] = useState("");
  const [rangeValue, setRangeValue] = useState({
    value: { min: 0, max: 0 },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const { sendRequest, clearError } = useHttpClient();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [allSubCate, setAllSubCate] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [location, setLocation] = useState("");
  const [total, setTotal] = useState(0);
  const { category, key } = useParams();
  let history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    if (selectedCat === "") {
      setSelectedCat(category);
    }

    if (searchKey === "") {
      if (key === "all" || key === undefined || key === null) {
        setSearchKey("");
      } else {
        setSearchKey(key);
      }
    }

    getAllProviders();
  }, [selectedCat, searchKey, currentPage, selectedSubCat, location]);

  useEffect(() => {
    setSelectedSubCat("");
  }, [selectedCat]);

  const getAllProviders = async () => {
    console.log("get providers called");
    setSpinnerStatus(true);
    let url = `${
      process.env.REACT_APP_BACKEND_URL
    }/user/get_providers_by_category?page=${currentPage}&category=${
      selectedSubCat !== "" ? selectedSubCat : selectedCat
    }`;
    if (selectedSubCat === "") {
      setAllSubCate([]);
      getAllSubCate(selectedCat);
    }

    if (searchKey !== "" && searchKey !== null && searchKey !== undefined) {
      console.log("search key availble", location);
      if (location.length > 0) {
        url += `&filter=${searchKey},&location=${location}`;

        console.log(`search key availble= ${url}`);
      } else {
        url += `&filter=${searchKey}`;
        console.log(`hahahahahahah= ${url}`);
      }
    } else {
      if (location.length > 0) {
        url += `&location=${location}`;
      }
    }

    try {
      const response = await axios.get(url);
      setallProviders(response.data.data);
      console.log("found data", response.data);
      setTotal(response.data.total);

      setSpinnerStatus(false);
    } catch (error) {
      setSpinnerStatus(false);
      console.error(error);
    }
  };
  const getAllCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_parent_categories`
      );
      setAllCategory(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllSubCate = async (selectedcate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_subcategories?name=${selectedcate}`
      );
      if (response.status === 200) {
        setAllSubCate(response.data);
      } else {
        throw new Error("Something Went Wrong!");
      }
    } catch (error) {
      notify("err", error);
    }
  };

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const optionTextStyle = {
    color: "var(--grey_one, #333)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  const inputStyle = {
    height: "50px",
    padding: "15px",
    borderRadius: "4px",
    background: "var(--WhiteBG, #FFF)",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    border: "none",
    cursor: "pointer",
    appearance: "none",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'><path fill='none' stroke='gray' stroke-width='2' d='M6 9l6 6 6-6'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 15px center",
  };

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <div
        className="py-3 px-3"
        style={{
          maxWidth: "1440px",
          margin: "auto",
        }}
      >
        <p
          className="hide-on-pc"
          style={{
            ...optionTextStyle,
            fontSize: "28px",
            marginBottom: "10px",
          }}
        >
          {" "}
          {auth.language === "en" ? " Providers" : "ফ্রিল্যান্সার"}
        </p>
        <div className="my-3 d-flex justify-content-between align-items-start parent gap-3">
          <div className="right px-2">
            <label for="" style={optionTextStyle}>
              {auth.language === "en"
                ? "Select Category"
                : "বিভাগ নির্বাচন করুন"}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              style={inputStyle}
              onChange={(e) => setSelectedCat(e.target.value)}
            >
              {" "}
              <option>{auth.language === "en" ? "all" : "সব"}</option>
              {allCategory?.map((c, idx) => (
                <option value={c.name} key={idx}>
                  {auth.language === "en" ? c?.name : c?.name_bn}
                </option>
              ))}
            </select>
            <label for="" style={optionTextStyle}>
              {auth.language === "en"
                ? "Select Sub-Category"
                : "সাব-ক্যাটাগরি নির্বাচন করুন "}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              style={inputStyle}
              onChange={(e) => setSelectedSubCat(e.target.value)}
            >
              <option value="" selected>
                {auth.language === "en" ? "Not selected" : "অনির্বাচিত"}
              </option>
              {Array.isArray(allSubCate?.data) &&
                allSubCate?.data?.map((dt, idx) => (
                  <option key={idx} value={dt.name}>
                    {dt.name}
                  </option>
                ))}
            </select>
            <div style={{ marginBottom: "20px" }}>
              <label for="" style={optionTextStyle}>
                {" "}
                {auth.language === "en" ? "Location" : "অবস্থান"}
              </label>
              <input
                type="text"
                placeholder={
                  auth.language === "en"
                    ? "search a location"
                    : "একটি অবস্থান অনুসন্ধান করুন"
                }
                onChange={(e) => setLocation(e.target.value)}
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  background: "white",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                  padding: "15px",
                }}
                value={location}
              />
            </div>

            {/* <div className='my-5 me-3'>
              <label for='' className='mb-3'>
                {auth.language === 'en' ? 'Budget(Tk)' : 'বাজেট (টাকা)'}
              </label>

              <InputRange
                maxValue={10000}
                minValue={0}
                value={rangeValue.value}
                onChange={(value) => setRangeValue({ value })}
                style={{ padding: '0px 10px' }}
              />
            </div> */}
            <button
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFCECC",
                borderRadius: "4px",
                padding: "10px",
              }}
              onClick={() => {
                setSelectedCat("all");
                setSelectedSubCat("");
                setLocation("");
              }}
            >
              <img
                src="/assets/cross_red.svg"
                alt="Cross Icon"
                style={{
                  height: "16px",
                  width: "16px",
                  position: "absolute",
                  left: "20px",
                }}
              />
              <p
                style={{
                  ...optionTextStyle,
                  color: "#FF3B33",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Reset All Filters
              </p>
            </button>
          </div>
          <div style={{ width: "100%" }}>
            <p
              className="hide-on-mobile"
              style={{
                ...optionTextStyle,
                fontSize: "28px",
                marginBottom: "10px",
              }}
            >
              {" "}
              {auth.language === "en" ? " Providers" : "ফ্রিল্যান্সার"}
            </p>
            <label htmlFor="" style={optionTextStyle}>
              {" "}
              {auth.language === "en"
                ? " Search a Provider"
                : "নাম, ইমেল ইত্যাদি ব্যবহার করে ফ্রিল্যান্সার অনুসন্ধান করুন।"}
            </label>
            <input
              type="text"
              style={{
                width: "100%",
                borderRadius: "4px",
                background: "white",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                padding: "15px",
              }}
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder={
                auth.language === "en"
                  ? "search providers using name, email etc."
                  : "একটি অবস্থান অনুসন্ধান করুন"
              }
            />

            <Spinner
              animation="border"
              role="status"
              style={{
                display: spinnerStatus ? "block" : "none",
                margin: "10rem auto",
              }}
            />
            {spinnerStatus === false && (
              <div className="py-5" style={{ width: "100%" }}>
                {/* <div className='col-lg-12 col-md-12'>
                  <ShowWorkProvider providers={allProviders?.data} />
                </div> */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(275px, 1fr))",
                    gridGap: "10px",
                    margin: "20px auto",
                    width: "100%",
                  }}
                >
                  {allProviders.length > 0 ? (
                    allProviders.map((dt, idx) => (
                      <ProviderCardNew provider={dt} key={idx} noProvider />
                    ))
                  ) : (
                    <h2 className="p-5 m-5 text-center text-secondary">
                      No Data Found!
                    </h2>
                  )}
                </div>
              </div>
            )}
            <Pagination
              itemsPerPage={10}
              totalPage={total}
              paginate={paginate}
              pageNumber={currentPage}
              style={{ backgroundColor: "var(--primary_color)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkProviderNew;
