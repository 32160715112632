import React, { useState, useEffect, useContext } from 'react';
import ShowWorkProvider from './ShowWorkProvider';
import axios from 'axios';
import Pagination from '../../Pagination';
import { useParams, useHistory, Link } from 'react-router-dom';
import { AuthContext } from '../../../context/auth-context';
import { useHttpClient } from '../../../hooks/http-hook';
import ProviderCardNew from '../../../components/HomeComponent/TopProvider/ProviderCardNew';

function WorkProvider(props) {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [ExpJobs, setExpJobs] = useState([]);
  const { category } = useParams();
  // const [category, setCategory] = useState('cleaning');
  const [selectedSub, setSelectedSub] = useState('');
  const auth = useContext(AuthContext);
  const [cat, setCat] = useState('all');

  // useEffect(() => {
  //   const fetchProviders = async () => {
  //     setLoading(true);
  //     const res = await axios.get('https://jsonplaceholder.typicode.com/users');
  //     setProviders(res.data);
  //     setLoading(false);
  //   };

  //   fetchProviders();
  // }, []);

  useEffect(() => {
    const fetchExpJobs = async () => {
      setLoading(true);

      const res = await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}${
            props.jobs
              ? '/user/available_jobs/'
              : '/user/get_providers_by_category'
          }?category=${
            category === 'all' ? 'all' : selectedSub ? selectedSub : category
          }`
        )
        .catch(function (error) {
          // handle error
        });
      setProviders(res.data.data);
      // setLoading(false);
    };

    fetchExpJobs();
  }, [props.jobs, category, selectedSub]);
  const [subcategories, setSubcategories] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchSubCategories = async () => {
      setSelectedSub('');
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/get_subcategories/?name=${
            cat ? cat : category
          }`
        );
        setSubcategories(responseData);
      } catch (err) {}
    };
    category !== 'null' && fetchSubCategories();
    //eslint-disable-next-line
  }, [category, cat]);
  //for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProviders = providers.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='' style={{ maxWidth: '1440' }}>
      <div className=' py-5' style={{ width: '100%' }}>
        {/* <div className='col-lg-9 col-md-12'>
          <ShowWorkProvider providers={currentProviders} loading={loading} />
        </div> */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
            gridGap: '10px',
            margin: '20px auto',
            width: '100%',
          }}
        >
          <ProviderCardNew provider={currentProviders} />)
        </div>
      </div>
    </div>
  );
}

export default WorkProvider;
