export default {
  homepage_search_job_banner_header_en: "Catch Phrase",
  homepage_search_job_banner_header_font_en: "Roboto",
  homepage_search_job_banner_desc_en:
    "Freelanch your way out of your problems. Find something to attract providers to search for jobs",
  homepage_search_job_banner_desc_font_en: "Lato",
  homepage_search_job_banner_header_bn: "যায়গা মতো আসছেন",
  homepage_search_job_banner_header_font_bn: "Hind Siliguri",
  homepage_search_job_banner_desc_bn:
    "এইতো আপনার লেগাই ওয়েট করতেসিলাম পিলিয ফাইন্ড সাম ওয়ার্ক এন্ড ডু মি উদ্ধার",
  homepage_search_job_banner_desc_font_bn: "Mini",
};
