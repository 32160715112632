import React from 'react';
import { Link } from 'react-router-dom';
import './TopProviderCard.css';
const TopProviderCard = ({ provider }) => {
  return (
    <div className='top_container'>
      <div className='top_wrapper'>
        <div className='top_provider'>
          <div className='top_card'>
            <img className='img-top' src='/assets/top-img.png' alt='' />

            <div className='top_content_wrapper'>
              <p className='success_wrapper'>
                Success Ratio <span>80%</span>
              </p>
              <p className='success_wrapper'>
                Provider Rating{' '}
                <span style={{ color: 'var(--text)' }}>
                  {provider.provider_id.provider_rating.toFixed(2)}
                </span>{' '}
                <img src='/assets/rater-start.png' alt='' />
              </p>
              <div className='location'>
                <img src='/assets/location.png' alt='' />
                <strong style={{ marginTop: '5px', marginLeft: '20px' }}>
                  Dhaka
                </strong>
              </div>
            </div>
          </div>
          <div className='top_name'>
            <h4>{provider.provider_id.fullname}</h4>
            <p className='des'>Professional Copyrighter</p>
            <button className='top_button' type='button'>
              <Link to={`/profile/${provider.provider_id._id}`}>
                <span style={{ color: 'var(--text)' }}>
                  View Profile
                </span>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopProviderCard;
