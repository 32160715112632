import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AdminLayout from "../../components/AdminLayout";
import AdminTableView from "../../components/AdminLayout/AdminComponentView/AdminTableView";
import Button from "../../components/shared/Button/Button";
import { useHttpClient } from "../../hooks/http-hook";
import { Modal } from "react-bootstrap";

export default function ManageAgents() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [dataModal, setDataModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [user, setUser] = useState(null);
  const [viewMode, setViewMode] = useState("universities");
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [sort, setSort] = useState({ fullname: "asc" });

  const { sendRequest } = useHttpClient();

  const fetchUser = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/admin/get_agent_request"
      );
      setUsersData(responseData.data);
      setFilteredUsersData(responseData.data);
    } catch (err) {}
  };

  const approveOrRejectUser = async (data) => {
    try {
      const newStatus = data.status === "Pending" ? true : false;

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/set_agent_status`,
        "PUT",
        JSON.stringify({
          id: data._id,
          agent_status: newStatus,
        }),
        { "Content-Type": "application/json" }
      );

      toast.success(`User status has been updated to ${newStatus.toString()}`);

      fetchUser();
    } catch (err) {
      toast.error(err.message || "Failed to update status.");
    } finally {
      setDataModal(false);
      setConfirmationModal(false);
    }
  };

  const renderUniversitiesTable = () => {
    return (
      <div>
        <h5 style={{ marginBottom: "1rem" }}>University Details</h5>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <th style={tableHeaderStyle}>Name</th>
              <th style={tableHeaderStyle}>Country</th>
              <th style={tableHeaderStyle}>Campus</th>
              <th style={tableHeaderStyle}>Course</th>
              <th style={tableHeaderStyle}>Session</th>
              <th style={tableHeaderStyle}>Year</th>
            </tr>
          </thead>
          <tbody>
            {user?.university?.map((u, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 0 ? "#ffffff" : "#f8f8f8",
                }}
              >
                <td style={tableCellStyle}>{u.name}</td>
                <td style={tableCellStyle}>{u.country}</td>
                <td style={tableCellStyle}>{u.campus}</td>
                <td style={tableCellStyle}>{u.course}</td>
                <td style={tableCellStyle}>{u.session}</td>
                <td style={tableCellStyle}>{u.year}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderFormBuilderDetails = () => {
    return (
      <div>
        <h5 style={{ marginBottom: "1rem" }}>Form Builder Details</h5>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <th style={tableHeaderStyle}>Field Name</th>
              <th style={tableHeaderStyle}>Field Type</th>
            </tr>
          </thead>
          <tbody>
            {user?.form_builder?.map((field, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 0 ? "#ffffff" : "#f8f8f8",
                }}
              >
                <td style={tableCellStyle}>{field.field_name}</td>
                <td style={tableCellStyle}>{field.field_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (Array.isArray(usersData)) {
      const filteredData = search
        ? usersData.filter((user) => {
            return (
              user.fullname.toLowerCase().includes(search.toLowerCase()) ||
              user.email.toLowerCase().includes(search.toLowerCase())
            );
          })
        : usersData;

      setFilteredUsersData(filteredData);
    }
  }, [search, usersData]);

  const tableHeaderStyle = {
    padding: "0.75rem",
    textAlign: "left",
    fontWeight: "bold",
    borderBottom: "2px solid #ddd",
  };

  const tableCellStyle = {
    padding: "0.75rem",
    borderBottom: "1px solid #ddd",
  };

  return (
    <>
      {/* Display University and formBuilder information in the table */}
      <Modal
        show={dataModal}
        onHide={() => {
          setDataModal(false);
          setViewMode("universities"); // Reset view mode when modal closes
        }}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Agent Information</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          {user && (user?.university || user?.form_builder) ? (
            <div style={{ width: "100%" }}>
              {viewMode === "universities" && renderUniversitiesTable()}
              {viewMode === "formBuilder" && renderFormBuilderDetails()}
            </div>
          ) : (
            <div>No Information Found</div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          {viewMode === "universities" ? (
            <>
              <div></div>
              <Button
                variant="primary"
                onClick={() => setViewMode("formBuilder")}
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                onClick={() => setViewMode("universities")}
              >
                Back
              </Button>
              <Button
                variant="primary"
                onClick={() => setConfirmationModal(true)}
              >
                Approve
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* Confirmation Modal */}
      <Modal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to approve this agent?</Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          <Button
            variant="outline-secondary"
            onClick={() => setConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => approveOrRejectUser(user)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <AdminLayout>
        <h3 style={{ fontWeight: "bold" }}>Manage Agents</h3>

        <AdminTableView
          //   query={{ status, error }}
          search={{
            title: "Search User",
            placeholder: "Search user by name, email...",
            btnText: "Search User",
            onSearch: (value) => {
              setSearch(value);
            },
          }}
          sort={{
            title: "Sort By",
            selects: [
              {
                name: "Sort By",
                options: [
                  { label: "Name (ASC)", value: "fullname:1" },
                  { label: "Name (DESC)", value: "fullname:-1" },
                  { label: "Email (ASC)", value: "email:1" },
                  { label: "Email (DESC)", value: "email:-1" },
                  { label: "Country (ASC)", value: "universities.country:1" },
                  { label: "Country (DESC)", value: "universities.country:-1" },
                  { label: "University (ASC)", value: "universities.name:1" },
                  { label: "University (DESC)", value: "universities.name:-1" },
                  { label: "Status (Approved)", value: "approved_agent:1" },
                  { label: "Status (Pending)", value: "approved_agent:-1" },
                ],
                onSort: ({ target }) => {
                  const [key, value] = target?.value?.split(":");
                  setSort(() => ({ [key]: +value }));
                },
              },
            ],
          }}
          table={{
            headers: [
              { name: "Name", dotsMenu: [], key: "name" },
              { name: "Email Address", dotsMenu: [], key: "email" },
              { name: "Country", dotsMenu: [], key: "country" },
              { name: "Universities", dotsMenu: [], key: "universities" },
              { name: "Status", dotsMenu: [], key: "status" },
              {
                name: "Actions",
                dotsMenu: [
                  {
                    name: "Approve",
                    onClick: (data) => {
                      setUser(data);
                      setConfirmationModal(true);
                    },
                  },
                  {
                    name: "View Form",
                    onClick: (data) => {
                      setDataModal(true);
                      setUser(data);
                    },
                  },
                  {
                    name: "View Profile",
                    onClick: (data) => history.push(`/profile/${data?._id}`),
                  },
                ],
                key: "actions",
              },
            ],
            data: filteredUsersData?.map((u) => ({
              _id: u._id || "",
              name: u?.fullname || "N/A",
              email: u?.email || "N/A",
              country: u?.universities[0]?.country || "N/A",
              universities: u?.universities[0]?.name || "N/A",
              form_builder: u?.form_builder || "N/A",
              university: u?.universities || "N/A",
              status: u?.approved_agent === true ? "Approved" : "Pending",
              actions: "",
            })),
          }}
        />
      </AdminLayout>
    </>
  );
}
