import React from "react";
import NewNavBar from "../components/navigation/NewNavBar";
import Button from "../components/shared/Button/Button";
import { Link } from "react-router-dom";

function PaymentDone(props) {
  const searchParams = new URLSearchParams(document.location.search);
  const jobID = searchParams.get('job_id');
  const error =  searchParams.get('error');
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NewNavBar />
      <div style={{ flex: "1 1 auto", display: "flex",flexDirection:"column", alignItems: "center",justifyContent:"center",gap:"2rem" }}>
        <div style={{color:"red",fontWeight:"bold",flexDirection:"column",fontSize:"2rem"}}>Payment Failed!</div>
        <div style={{fontSize:"1rem",color:"gray",maxWidth:"40rem"}} className="payment_error">{error}</div>
        <Link to={`/job-details/${jobID}`}>
        <Button className="btn prime-btn">Go to job page</Button>
        </Link>
      </div>
    </div>
  );
}

export default PaymentDone;