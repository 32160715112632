import React from 'react';
import AdminLayout from '../../../components/AdminLayout';
import JobCategoriesView from '../../../components/AdminLayout/JobCategoriesView/JobCategoriesView';

export default function JobCategories() {
  return (
    <AdminLayout>
      <JobCategoriesView />
    </AdminLayout>
  )
}