import React, { useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Card from "../../../components/util/Card";
import SkillItem from "../../items/SkillItem";
import { Button, Modal, Spinner } from "react-bootstrap";
import { BUCKET_URL,S3_PREASSIGNED_POST_URL,CLOUDFRONT_URL } from "../../../config";
import "./Edit_Profile.css";
import axios from "axios";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const MoreInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const [expertises, setExpertises] = useState(null);
  const [achievements, setAchievements] = useState(null);
  const [degrees, setDegrees] = useState(null);
  const [showAddDegree, setShowAddDegree] = useState(false);

  const [files, setFiles] = useState([]);

  const [addEdu, setAddEdu] = useState(false);
  const handleEduClose = () => setAddEdu(false);
  const handleEduShow = () => setAddEdu(true);

  const [addAchievement, setAddAchievement] = useState(false);
  const handleAchievementClose = () => setAddAchievement(false);
  const handleAchievementShow = () => setAddAchievement(true);

  const [achTitle, setAchTitle] = useState("");
  const [achDesc, setAchDesc] = useState("");

  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [passing_year, setPassing_year] = useState("");
  const [addedExpertise, setAddedExpertise] = useState("");
  const fileInputRef = useRef();
  const [tagline, setTagline] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [location, setLocation] = useState("");
  const [filesUrl, setFilesUrl] = useState([]);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);



  useEffect(() => {
    setUpdateUser();
  }, [auth.user]);

  const setUpdateUser = () => {
    if (auth.user) {
      console.log(auth.user);
      setExpertises(auth.user.expertise);
      setAchievements(auth.user.achievement);
      setDegrees(auth.user.degree);
      setTagline(auth.user?.tagline);
      setAboutMe(auth.user?.about_me);
      setLocation(auth.user?.location);
      setFilesUrl(auth.user?.portfolio);
    }
  };

  const addExtraInfo = async () => {
    setSpinnerStatus(true);

    let dataToSend = {
      tagline: tagline,
      location: location,
      about_me: aboutMe,
      portfolio: filesUrl,
    };
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_extra_infos",
        "POST",

        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        setSpinnerStatus(false);
        auth.setUser(responseData);
        notify("success", "Information Updated");
        setTagline("");
        setAboutMe("");
        setOldPassword("");
        setConfirmModal(false);
      } else
        throw new Error(
          responseData?.data?.msg || "Something went wrong, Try again later"
        );
    } catch (err) {
      setSpinnerStatus(false);
      setConfirmModal(false);
      setTagline("");
      setAboutMe("");
      setOldPassword("");
      notify(
        "err",
        err?.response?.data?.msg || "Something went wrong, Try again later"
      );
    }
  };

  const addExpertise = async (e) => {
    e.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_expertise",
        "PUT",
        JSON.stringify({
          expertise: [addedExpertise],
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        auth.setUser();
        notify("success", "Skills Added!");
      } else {
        notify("err", "Something Went Wrong!");
      }
      setAddedExpertise("");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const deleteAchievement = async (title, desc) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/delete_achievement",
        "PUT",
        JSON.stringify({
          name: title,
          description: desc,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        notify("success", "Achievement Deleted!");
      } else throw new Error("Something Went Wrong!");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const saveAchievement = async (e) => {
    e.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_achievement",
        "PUT",
        JSON.stringify({
          name: achTitle,
          description: achDesc,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        handleAchievementClose();
        notify("success", "Achievement Added! ");
      } else {
        notify("err", "Something Went Wrong!");
      }
      setAchTitle("");
      setAchDesc("");
    } catch (err) {
      notify("err", err.message);
    }
  };


  const deleteDegree = async (school, degree, passing_year) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/delete_degree",
        "PUT",
        JSON.stringify({
          school: school,
          degree: degree,
          passing_year: passing_year,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        notify("success", "Degree Deleted!");
      } else {
        notify("err", "Something Went Wrong!");
      }
    } catch (err) {
      notify("err", err.message);
    }
  };




  const saveDegree = async (e) => {
    e.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_degree",
        "PUT",
        JSON.stringify({
          school: school,
          degree: degree,
          passing_year: passing_year,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        handleEduClose();
        notify("success", "Degree Added!");
      } else {
        notify("err", "Something Went Wrong!");
      }
      setSchool("");
      setDegree("");
      setPassing_year("");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const fileUpload = async (fileList) => {
    try {
      let baseformat = [];
      console.log('hello');
      if (fileList.length > 0) {
        for (const file of fileList) {
          const data = await axios.get(
            `${S3_PREASSIGNED_POST_URL}?size=${globalSettings?.max_file_size}&fileExtension=${file.name.split('.').pop()}`
          );
    
          const formData = new FormData();
          Object.entries(data.data[0].fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", file);
    
          const uploadResponse = await axios.post('https://somadha.s3.amazonaws.com', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          
          baseformat.push(BUCKET_URL+ `${data.data[0].fields.Key}`);

          console.log(process.env.BUCKET_URL);
        }
      }
      
      setFilesUrl([...filesUrl, ...baseformat]);


    } catch (err) {
      toast.error(`Max file upload size is ${globalSettings?.max_file_size}MB`);
    }
  };

  const confirmUpdate = () => {
    setConfirmModal(true);
  };

  return (
    <div className="my-5">
      <div className="form-section">
        <label for="ftag">
          {" "}
          {auth.language === "en" ? "Tagline" : "ট্যাগলাইন"}{" "}
        </label>
        <input
          type="text"
          name="tag"
          placeholder={
            auth.language === "en"
              ? "Keep it short, recommended to be keywords."
              : "সংক্ষিপ্ত রাখুন, কীওয়ার্ড ব্যাবহারের করুন"
          }
          value={tagline}
          onChange={(e) => {
            setTagline(e.target.value);
          }}
        />
      </div>
      <div className="form-section">
        <label for="fdesc">
          {" "}
          {auth.language === "en" ? "About Me" : "আমার সম্পর্কে"}{" "}
        </label>
        <textarea
          placeholder={
            auth.language === "en"
              ? "A Short Description about you, this will be public, be creative!"
              : "আপনার সম্পর্কে একটি সংক্ষিপ্ত বিবরণ, এটি সর্বজনীন হবে, সৃজনশীল হবেন!"
          }
          rows="5"
          className="textareaa"
          value={aboutMe}
          onChange={(e) => {
            setAboutMe(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="form-section">
        <label for="loc">
          {" "}
          {auth.language === "en" ? "Location" : "অবস্থান"}{" "}
        </label>
        <input
          type="text"
          name="loc"
          placeholder={
            auth.language === "en"
              ? "Select your City/ Town/ Village."
              : "আপনার শহর/শহর/গ্রাম নির্বাচন করুন।"
          }
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
      </div>
      <div className="form-section">
        <label for="cv">
          {" "}
          {auth.language === "en"
            ? "Portfolio/CV Upload"
            : "পোর্টফোলিও/সিভি আপলোড"}
        </label>
        <div className="inforight">
          <div className="image__uploader file__uploader">
            <div
              className="px-5 py-1"
              style={{
                backgroundColor: "var(--primary_light_color)",
                color: "var(--primary_color)",
                borderRadius: "4px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={(event) => {
                fileInputRef.current.click();
              }}
            >
              <span
                className="upload_txt"
                style={{ color: "var(--primary_color)" }}
              >
                {" "}
                {auth.language === "en" ? "Upload" : "আপলোড করুন"}
              </span>{" "}
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              multiple
              onChange={(event) => {
                let newFiles = Array.from(event.target.files);
                setFiles((prevFiles) => [...prevFiles, ...newFiles]);
                fileUpload(newFiles);
              }}
            />
          </div>

          {/* <FileUploader
            updatestate={(childFile) => {
              setFiles(childFile);
              
            }}
          /> */}


          {
            filesUrl.map((file, index) => (
              <div
                key={index}
                className="d-inline-flex justify-content-center align-items-center px-2 my-2"
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: "4px",
                  marginRight: "20px",
                }}
              >
                <img
                  src={file}
                  alt=""
                  height="50px"
                  width="50px"
                  className="mx-2"
                  style={{ cursor: "pointer" }}
                />
                <img
                  onClick={() => {
                    setFilesUrl((prevFiles) =>
                      prevFiles.filter((f) => f !== file)
                    );
                  }}
                  src="/assets/cross.svg"
                  alt=""
                  height="20px"
                  width="20px"
                  className="mx-2"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>
      </div>

      <div className="form-section">
        <form onSubmit={addExpertise}>
          <label for="skill">
            {" "}
            {auth.language === "en" ? "Skills" : "দক্ষতা"}
          </label>
          <span className="d-flex justify-content-center align-items-center">
            <input
              type="text"
              name="skill"
              placeholder={
                auth.language === "en"
                  ? "Type you skill name"
                  : "আপনার দক্ষতার নাম টাইপ করুন"
              }
              value={addedExpertise}
              onChange={(e) => setAddedExpertise(e.target.value)}
              className="from-control"
            />

            <button
              type="submit"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                position: "relative",
                left: "-30px",
                color: "#2A41E8",
              }}
            >
              <FontAwesomeIcon icon={faPlus} />{" "}
            </button>
          </span>
        </form>
        <Card>
          {expertises &&
            expertises.map((skill, index) => {
              return <SkillItem key={index} delete text={skill} />;
            })}
        </Card>
      </div>

      <div className="form-section">
        <label for="achievement">
          {" "}
          {auth.language === "en" ? "Achievements" : "অর্জন"}
        </label>
        {achievements &&
          achievements.map((achievement, index) => {
            return (
              <Card key={index}>
                <div
                  className=" px-3 py-2 rounded"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <p
                      className=""
                      style={{ fontSize: "18px", textAlign: "start" }}
                    >
                      <strong>{achievement.name}</strong>
                    </p>
                    <img
                      src="/assets/cross.svg"
                      alt=""
                      onClick={() => {
                        deleteAchievement(
                          achievement.name,
                          achievement.description
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="m-0" style={{ fontSize: "18px" }}>
                    {achievement.description}
                  </p>
                </div>
              </Card>
            );
          })}
        <button
          type="button"
          className="btn fw-bold"
          style={{ color: "var(--primary_color)" }}
          onClick={handleAchievementShow}
        >
          {" "}
          {auth.language === "en" ? "+ Add New" : "+ নতুন যোগ করুন"}
        </button>
      </div>

      {/* achievement modal */}
      <Modal
        show={addAchievement}
        onHide={handleAchievementClose}
        backdrop="static"
        dialogClassName="modal-50w"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ color: "var(--primary_color)" }}>
              {auth.language === "en"
                ? "+ Add New Achievement  "
                : "+ নতুন অর্জন যোগ করুন"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={saveAchievement}>
            <div className="form-section">
              <label for="addAchv">
                {" "}
                {auth.language === "en" ? "Achievement Title" : "শিরোনাম"}
              </label>
              <input
                type="text"
                name="addAchv"
                value={achTitle}
                onChange={(e) => setAchTitle(e.target.value)}
                required
              />

              <p style={{ color: "var(--grey_two)" }}>
                Example: Won a contest, wrote a book, anything professional and
                you're proud of!
              </p>
            </div>

            <div className="form-section">
              <label for="addDesc">
                {" "}
                {auth.language === "en"
                  ? "Achievent Description"
                  : "অর্জনের বিবরণ"}
              </label>
              <textarea
                placeholder={
                  auth.language === "en"
                    ? "Describe what this is"
                    : "এটি কী তা বর্ণনা করুন"
                }
                rows="5"
                className="textareaa"
                value={achDesc}
                onChange={(e) => setAchDesc(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="d-flex justify-content-end align-items-center p-2">
              <Button
                type="submit"
                className="btn fw-bold"
                style={{
                  backgroundColor: "var(--primary_color)",
                  width: "15rem",
                }}
              >
                {auth.language === "en" ? "Save" : "সংরক্ষণ"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="form-section">
        <label for="education">
          {" "}
          {auth.language === "en" ? "Education" : "শিক্ষা"}
        </label>
        {degrees &&
          degrees.map((degree, index) => {
            return (
              <Card key={index}>
                <div
                  className=" px-3 py-2 rounded"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <p
                      className=""
                      style={{ fontSize: "18px", textAlign: "start" }}
                    >
                      <strong>{degree.school}</strong>
                    </p>
                    <img
                      src="/assets/cross.svg"
                      alt=""
                      onClick={() => {
                        deleteDegree(
                          degree.school,
                          degree.degree,
                          degree.passing_year
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="m-0" style={{ fontSize: "18px" }}>
                    {degree.degree + ", Passing " + degree.passing_year}
                  </p>
                </div>

                {/* <div className='acd_hold'>
                  <img
                    src='/assets/cross.svg'
                    alt=''
                    onClick={() => {
                      deleteDegree(
                        degree.school,
                        degree.degree,
                        degree.passing_year
                      );
                    }}
                  />
                  <p className='p_header'>
                    <strong>{degree.school}</strong>
                  </p>
                  <p className='p_description'>
                    {degree.degree + ', Passing Year: ' + degree.passing_year}
                  </p>
                </div> */}
              </Card>
            );
          })}
        <button
          type="button"
          className="btn btn-outline fw-bold"
          style={{ color: "var(--primary_color)" }}
          onClick={handleEduShow}
        >
          {" "}
          {auth.language === "en" ? "+ Add New" : "+ নতুন যোগ করুন"}
        </button>
      </div>

      {/* Edu modal */}
      <Modal
        show={addEdu}
        onHide={handleEduClose}
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ color: "var(--primary_color)" }}>
              {auth.language === "en"
                ? " Add New Degree"
                : "নতুন ডিগ্রী যোগ করুন"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-section">
              <label for="">
                {" "}
                {auth.language === "en" ? "Institute Name" : "প্রতিষ্ঠানের নাম"}
              </label>
              <input
                type="text"
                name=""
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                required
              />
            </div>
            <div className="form-section">
              <label for="">
                {auth.language === "en" ? "Degree Name " : "ডিগ্রির নাম"}
              </label>
              <input
                type="text"
                name=""
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                required
              />
            </div>
            <div className="form-section">
              <label for="">
                {" "}
                {auth.language === "en"
                  ? "Major/Field of Study"
                  : "অধ্যয়নের ক্ষেত্র"}
              </label>
              <input type="text" name="" />
            </div>
            <div className="form-section">
              <label for="">
                {auth.language === "en" ? "Passing Year" : "পাসিং বছর"}
              </label>
              <input
                type="text"
                name=""
                value={passing_year}
                onChange={(e) => setPassing_year(e.target.value)}
                required
              />
            </div>

            <div className="d-flex justify-content-end align-items-center p-2">
              <Button
                type="submit"
                className="btn fw-bold"
                style={{
                  backgroundColor: "var(--primary_color)",
                  width: "15rem",
                }}
                onClick={saveDegree}
              >
                {auth.language === "en" ? "Save" : "সংরক্ষণ"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-start align-items-center flex-wrap submit-btn">
        <div className="">
          <button
            className="btn fw-bold m-2"
            style={{
              backgroundColor: "var(--primary_color)",
              width: "15rem",
              color: "white",
            }}
            onClick={confirmUpdate}
          >
            {auth.language === "en"
              ? " Save Changes "
              : "পরিবর্তনগুলোর সংরক্ষন"}{" "}
          </button>
        </div>
      </div>

      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="form-section">
            <h4 className="fw-bold mb-3">
              {" "}
              {auth.language === "en"
                ? "Enter Current Password To Update Changes"
                : "পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন"}
            </h4>
            <input
              type="password"
              required
              name=""
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            {auth.language === "en" ? "Close" : "বন্ধ"}
          </Button>
          <Button
            type="button"
            className="btn fw-bold"
            style={{ backgroundColor: "var(--primary_color)" }}
            onClick={addExtraInfo}
          >
            {auth.language === "en"
              ? " Save Changes "
              : "পরিবর্তনগুলোর সংরক্ষন"}
            {spinnerStatus && (
              <Spinner animation="border" size="sm" className="text-white" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MoreInfo;
