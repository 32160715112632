import React from 'react';
import './AcceptBid.css';
import Modal from '../../util/Modal';
import LoadingSpinner from '../../util/LoadingSpinner';
import { useHttpClient } from '../../../hooks/http-hook';
const AcceptBid = (props) => {
  const { isLoading, error, sendRequest } = useHttpClient();
  const acceptBid = async (event) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/client/accept_bid/?bid_id=${props.id}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
        props.onCancel();
      }
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      <div className='accept__container'>
        {isLoading && <LoadingSpinner asOverlay />}
        <h2>Accept this provider for the job?</h2>
        {/* <p>
          Make sure you have talked with the provider and informed him about all
          the requirements. Lorem ipsum dolor set amet...
        </p> */}
        {error && <p>{error.msg}</p>}
        <div className='btns__container'>
          <button onClick={props.onCancel} className='cancel_btn'>
            Cancel
          </button>
          <button onClick={acceptBid} className='confirm_btn'>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AcceptBid;
