import React, { useEffect } from 'react';
import AuthSection from '../sections/authentication/AuthSection';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

const Login = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <AuthSection variant='login' setupSocket={props.setupSocket} />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Login;
