export default {
  homepage_post_job_banner_header_en: "Catch Phrase",
  homepage_post_job_banner_header_font_en: "Roboto",
  homepage_post_job_banner_desc_en:
    "Find something to attract users to post a job",
  homepage_post_job_banner_desc_font_en: "Lato",
  homepage_post_job_banner_header_bn: "আবারো যায়গা মতো আসছেন",
  homepage_post_job_banner_header_font_bn: "Hind Siliguri",
  homepage_post_job_banner_desc_bn:
    "এইখানে কাজ দিয়া আপ্নের জীবন সুন্দর করুন, বাংলা কিছু লিখুন এইখানে হিজি বিজি হিজি বিজি",
  homepage_post_job_banner_desc_font_bn: "Mini",
};
