import React, { useContext } from "react";
import Input from "../components/shared/Input/Input";
import { useForm } from "react-hook-form"; // Import the useForm hook
import axios from "axios";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import NewNavBar from "./navigation/NewNavBar";
import NewFooter from "./footer/NewFooter";
import Texts from "../util/Texts";

function VerifyEmail(props) {
  const history = useHistory();
  const location = useLocation();
  const texts = Texts();
  const { register, handleSubmit } = useForm(); // Initialize the useForm hook
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const verifyOtp = async (data) => {
    console.log(email);
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/auth/verify_email",
        {
          email: email,
          verification_code: data.otp,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      history.push("/login");
      toast.success("Account verified successfully");
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const resendOtp = async () => {
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/auth/forgot_password",
        {
          email: email,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      toast.success("Otp has been resent.");
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <NewNavBar />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding:"100px 0"
        }}
      >
        <div className="auth--wrapper" style={{ margin: 0 }}>
          <h3 className="fw-bold my-4 text-center">Verify Account</h3>
          <form onSubmit={handleSubmit(verifyOtp)}>
            {" "}
            {/* Pass verifyOtp to handleSubmit */}
            <Input label={texts.otp} type="number" {...register("otp")} />{" "}
            {/* Use register from useForm */}
            <div className="button--wrapper">
              <button type="submit" className="btn prime-btn my-4 w-100">
                Verify
              </button>
            </div>
          </form>
          <div className="pb-4">
            <p style={{ textAlign: "center" }}>
              Haven't received any OTP?{" "}
              <span
                style={{ color: "var(--primary_color)" }}
                onClick={resendOtp}
              >
                Resend
              </span>
            </p>
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
  );
}

export default VerifyEmail;
