import React, { useState, useEffect, useContext } from "react";
import "./Notification.css";
import { useHttpClient } from "../../hooks/http-hook";
import NotificationItem from "../../components/items/NotificationItem";
import { Link, NavLink } from "react-router-dom";
import LoadingSpinner from "../../components/util/LoadingSpinner";
import Paginator from "../../components/util/Paginator";
import { AuthContext } from "../../context/auth-context";
import { useQuery } from "react-query";
import { GET_NOTIFICATIONS } from "../../lib/api/admin";
import Pagination from "../../components/shared/Pagination/Pagination";
import Sidebar from "../../components/SideBar";
import { Route, useHistory } from "react-router-dom";

const Notification = () => {
  const { isLoading, sendRequest } = useHttpClient();
  const [notifications, setNotifications] = useState([]);
const  history = useHistory();
  const [page, setPage] = useState(1);
  const auth = useContext(AuthContext);
  const { data: notificationsData } = useQuery(
    [
      GET_NOTIFICATIONS.name,
      {
        page,
      },
    ],
    GET_NOTIFICATIONS
  );

  const fetchNotifications = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/user/get_all_notifications/?page=${page}`
      );
      setNotifications(responseData);

    } catch (err) {}
  };

  const markAllasRead = async () => {
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL +
        "/user/all_notifications_make_as_read",
      "PUT",
      JSON.stringify({}),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success === "yes") {
      // fetchNotifications();
      // auth.setRefresh();
      window.location.reload();

    }
  };
  useEffect(() => {
    fetchNotifications();
    //eslint-disable-next-line
  }, [page]);

  return (
    <div className="notification_sidebar">
      <div className="noti_sidebar">
        <Sidebar />
      </div>
      <div className="notification__container">
        {isLoading && <LoadingSpinner asOverlay />}
        <div className="notification__head">
          <h2 className="fw-bolder  ">
            {auth.language === "en" ? "Notifications" : "বিজ্ঞপ্তি"}
          </h2>
          <u
            style={{ color: "var(--primary_color)", cursor: "pointer" }}
            onClick={markAllasRead}
          >
            {auth.language === "en"
              ? "mark all as read"
              : "সবগুলো পঠিত বলে সনাক্ত কর"}
          </u>
        </div>

        <p>
          <Link to="/activity-log" className="activity_log">
            {auth.language === "en" ? "See Activity Log" : "কার্যকলাপ লগ দেখুন"}{" "}
          </Link>
        </p>
        <div className="not_items_container">
          {notificationsData?.data?.data?.length > 0 ? (
            notificationsData?.data?.data?.map((notification) => {
              return (
                <div onClick={() => {
                  history.replace(notification.link);
                  window.location.reload();
                }}>
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                  />
                </div>
              );
            })
          ) : (
            <h3 className="my-5 py-5 text-secondary">No Notification Found!</h3>
          )}
        </div>
        <Pagination
          total={notificationsData?.data?.total}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default Notification;
