import React, { useEffect, useContext, useState } from 'react';
import './FAQ.css';
import axios from 'axios';
import Editor from '../../../components/Editor/Editor';
import { Accordion, Spinner } from 'react-bootstrap';
import { useHttpClient } from '../../../hooks/http-hook';
import { AuthContext } from '../../../context/auth-context';
import { ToastContainer, toast } from 'react-toastify';

const notify = (type, msg) => {
  type === 'err'
    ? toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

function FAQ(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendRequest } = useHttpClient();
  const [faqData, setFaqData] = useState([]);
  const auth = useContext(AuthContext);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getData();
  }, [auth.language]);

  const getData = async () => {
    setSpinner(true);
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/faqs?lang=${auth.language}`
      );
      setFaqData(responseData);
      setSpinner(false);
    } catch (err) {
      notify('err', err?.response?.data?.msg);
      setSpinner(false);
    }
  };

  return (
    <div className='accor-customization container-sm	mx-auto  px-2 my-5'>
      {/* <Editor readOnly defaultValue={faqData} /> */}

      <h2 className='pb-5 text-center'>
        {auth.language === 'en'
          ? 'Frequently Asked Questions'
          : 'সচরাচর জিজ্ঞাস্য'}
        {spinner && (
          <Spinner
            animation='border'
            size='sm'
            className='ms-2'
            style={{ fontWeight: '300' }}
          />
        )}
      </h2>
      {faqData.length > 0 ? (
        faqData.map((dt, idx) => (
          <Accordion key={idx} className='faq-accordion'>
            <Accordion.Item eventKey={idx}>
              <Accordion.Header>
                {auth.language === 'en' ? dt?.ques_en : dt?.ques_bn}
              </Accordion.Header>
              <Accordion.Body className='px-3'>
                {auth.language === 'en' ? (
                  <Editor readOnly defaultValue={dt?.ans_en} />
                ) : (
                  <Editor readOnly defaultValue={dt?.ans_bn} />
                )}
                {/* {auth.language === 'en' ? dt.ans_en : dt.ans_bn} */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))
      ) : (
        <h1 style={{ textAlign: 'center', color: 'dimGray' }}>
          No Data Found!
        </h1>
      )}
    </div>
  );
}

export default FAQ;
