import React from 'react';
import './WorkProviderCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

// let provider = {
//   id: 1,
//   name: 'Leanne Graham',
//   username: 'Bret',
//   email: 'Sincere@april.biz',
//   address: {
//     street: 'Kulas Light',
//     suite: 'Apt. 556',
//     city: 'Gwenborough',
//     zipcode: '92998-3874',
//     geo: {
//       lat: '-37.3159',
//       lng: '81.1496',
//     },
//   },
//   image: 'https://via.placeholder.com/150/92c952',
//   successRatio: '95%',
//   providerRating: '90%',
//   profession: 'Professional Copywriter',
//   location: 'Dhaka',
// };

function WorkProviderCard({ provider, image }) {
  let history = useHistory();

  const goToProfile = (id) => {
    history.push(`/profile/${id}`);
  };

  return (
    <div className='pcard'>
      <div className='d-flex flex-row '>
        <div>
          <img
            style={{ height: '90px', width: '90px' }}
            src={
              !`${provider.profile_pic}`
                ? `${provider.profile_pic}`
                : image
            }
            alt='test_image'
          />
        </div>
        <div className='profile-right'>
          <div>
            <p style={{ color: 'var(--grey_one)' }}>
              Success Ratio{' '}
              <span style={{ color: 'var(--text)' }}>
                {Number(provider.client_rating.toFixed(2))}%
              </span>
            </p>
          </div>
          <div>
            <p style={{ color: 'var(--grey_one)' }}>
              Provider Rating{' '}
              <span style={{ color: 'black' }}>
                {provider.provider_rating}
                <FontAwesomeIcon
                  style={{
                    color: 'var(--primary_light_color)',
                    marginLeft: '4px',
                  }}
                  icon={faStar}
                />
              </span>{' '}
            </p>
          </div>
          <div>
            <span>
              <i className='fas fa-map-marker-alt'></i>{' '}
              {provider?.address?.city || 'Dhaka'}
            </span>
          </div>
        </div>
      </div>
      <div className='profile-bottom'>
        <strong>{provider.fullname}</strong>
        {/* <p>{provider.email}</p> */}
      </div>
      <button
        className='mt-2 w-100 btn prime-btn'
        type='button'
        onClick={() => goToProfile(provider._id)}
      >
        View Profile
      </button>
    </div>
  );
}

export default WorkProviderCard;
