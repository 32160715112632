import React from "react";
import { Link } from "react-router-dom";
import useWindowWidth from "../../hooks/window-width";

const VARIANT = ["explore--jobs", "post--job", "post--work"];

const Banner = (props) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const checkVariant = VARIANT.includes(props.variant)
    ? props.variant
    : VARIANT[0];

  let leftSectionOrder = 2;
  let rightSectionOrder = 1;
  let textAlign = "left";

  if (checkVariant === "post--job") {
    leftSectionOrder = 1;
    rightSectionOrder = 2;
    textAlign = "right";
  } else if (checkVariant === "post--work") {
    textAlign = "center";
  }

  const bannerStyles = {
    container: {
      paddingTop: checkVariant === "post--job" ? "20px" : "40px",
      margin: "auto",
      width: isMobile ? "92vw" : "80vw",
      maxWidth: "1400px",
      paddingBottom: checkVariant === "post--job" ? "40px" : "20px",
    },
    banner: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: isMobile ? "column" : "row",
      gap: isMobile ? "20px" : "0px",
      height: checkVariant === "post--work" ? "380px" : "auto",
      borderRadius: checkVariant === "post--work" ? "5px" : "0px",
      boxShadow:
        checkVariant === "post--work"
          ? "0 0px 15px 0px rgba(0, 0, 0, 0.15)"
          : "none",
      padding: checkVariant === "post--work" ? "2px 35px" : "0",
    },
    leftSection: {
      display: "flex",
      justifyContent: checkVariant === "post--job" ? "start" : "end",
      flex: 1,
      maxHeight: "400px",
      objectFit: "scale-down",
      order: leftSectionOrder,
    },
    rightSection: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      textAlign: textAlign,
      gap: "20px",
      order: rightSectionOrder,
      justifyContent: checkVariant === "post--work" ? "center" : "flex-start",
      height: checkVariant === "post--work" ? "100%" : "auto",
    },
    titleText: {
      fontFamily: "Nunito, sans-serif",
      fontSize: "32px",
      color: "var(--grey_one)",
      fontWeight: "700",
      fontStyle: "normal",
      marginBottom: checkVariant === "post--work" ? "0" : "0.5rem",
    },
    descriptionText: {
      fontFamily: "Nunito, sans-serif",
      color: "var(--grey_two)",
      fontSize: "24px",
      fontWeight: "400",
      fontStyle: "normal",
    },
    bannerBtns: {
      display: "flex",
      columnGap: "40px",
      justifyContent:
        checkVariant === "explore--jobs" ? "flex-start" : "flex-end",
      flexDirection: checkVariant === "post--work" ? "column" : "row",
      rowGap: checkVariant === "post--work" ? "20px" : "0px",
    },
    button: {
      padding: "10px 40px",
      minWidth: "160px",
      color: "white",
      fontSize: "1.1rem",
      fontWeight: "400",
      borderRadius: "4px",
      backgroundColor: "#2A41E8",
      border: "none",
      cursor: "pointer",
      transition: "transform 250ms ease-out",
    },
    buttonHover: {
      transform: "scale(1.04)",
    },
    buttonAuth: {
      padding: "10px 26px",
      color: "white",
      fontSize: "1.6rem",
      fontWeight: "bold",
      borderRadius: "4px",
      marginTop: "20px",
      boxShadow: "0 5px 3px 0px rgba(0, 0, 0, 0.15)",
      backgroundColor: "#2A41E8",
    },
    buttonDisabled: {
      backgroundColor: "#ccc",
      color: "#979797",
      cursor: "not-allowed",
    },
  };

  return (
    <div style={bannerStyles.container}>
      <div style={bannerStyles.banner}>
        <div style={bannerStyles.leftSection}>
          <img
            src={props.imgSource}
            alt=""
            style={{ width: "480px", height: isMobile ? "222px" : "270px" }}
          />
        </div>
        <div style={bannerStyles.rightSection}>
          <p style={bannerStyles.titleText}>
            {props.title &&
              JSON.parse(props.title)?.blocks[0]?.data?.text.toUpperCase()}
          </p>
          <p style={bannerStyles.descriptionText}>
            {props.text && JSON.parse(props.text)?.blocks[0]?.data?.text}
          </p>
          <div style={bannerStyles.bannerBtns}>
            <Link to={props.linkTo} className="fw-bold">
              <button
                style={bannerStyles.button}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform =
                    bannerStyles.buttonHover.transform)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
                disabled={props.disabled}
              >
                {props.buttonText}
              </button>
            </Link>
            {checkVariant === VARIANT[2] && (
              <Link to={props.secondlink}>
                <button
                  style={bannerStyles.button}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.transform =
                      bannerStyles.buttonHover.transform)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                  disabled={props.disabled}
                >
                  Find A Work Provider
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
