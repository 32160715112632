import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
	CREATE_PAYMENT_PROVIDER,
	UPDATE_PAYMENT_PROVIDER,
	UPLOAD_IMAGE,
} from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import Modal from "../../shared/Modal/Modal";
import "../AddEditCategoryModal/AddEditCategoryModal.css";
import UploadImage from "../../shared/UploadImage/UploadImage";

const DEFAULT_VALUES = {
	logo: "",
	name: "",
	number: "",
};
export default function AddEditPaymentProviderModal({ children, data }) {
	const [open, setOpen] = useState(false);
	const queryClient = useQueryClient();
	const { register, handleSubmit, reset, setValue, watch } = useForm({
		defaultValues: useMemo(() => (data?._id ? data : DEFAULT_VALUES), [data]),
	});
	const { mutateAsync: uploadImageMutation } = useMutation(UPLOAD_IMAGE);
	const { mutateAsync: createPaymentProvider } = useMutation(
		CREATE_PAYMENT_PROVIDER
	);
	const { mutateAsync: updatePaymentProvider } = useMutation(
		UPDATE_PAYMENT_PROVIDER
	);

	const uploadImage = useCallback(
		async (key, image) => {
			try {
				const formData = new FormData();
				formData.append("file", image);
				const {
					data: { url },
				} = await uploadImageMutation(formData);
				toast.success("Successfully uploaded the image!");
				return url;
			} catch (err) {
				console.error(err);
				toast.error(err?.response?.data?.msg || err?.message);
			}
		},
		[uploadImageMutation]
	);
	const handleNewPAYMENTProviderCreate = useCallback(
		async (values) => {
			try {
				await createPaymentProvider(values);
				await queryClient.refetchQueries("GET_PAYMENT_PROVIDERS");
				toast.success(`Payment provider has been created successfully`);
				setOpen(false);
				reset();
			} catch (err) {
				console.error(err);
				toast.error(err?.response?.data?.msg || err?.message);
			}
		},
		[createPaymentProvider, queryClient, reset]
	);
	const handlePaymentProviderUpdate = useCallback(
		async ({ _id, ...values }) => {
			try {
				await updatePaymentProvider({ _id, values });
				await queryClient.refetchQueries("GET_PAYMENT_PROVIDERS");
				toast.success(`Payment provider has been updated successfully`);
				setOpen(false);
				reset();
			} catch (err) {
				console.error(err);
				toast.error(err?.response?.data?.msg || err?.message);
			}
		},
		[queryClient, reset, updatePaymentProvider]
	);

	useEffect(() => {
		reset(data);
	}, [data, reset]);

	return (
		<Fragment>
			{children &&
				React.cloneElement(children, {
					onClick: (e) => {
						e.preventDefault();
						e.stopPropagation();
						setOpen(true);
						if (data?._id) reset(data);
					},
				})}

			<Modal
				open={open}
				onClose={() => {
					setOpen(false);
					reset();
				}}
				title={`${!data?._id ? "Add New" : "Update"} Delivery Provider`}
			>
				<form
					onSubmit={handleSubmit(
						data?._id
							? handlePaymentProviderUpdate
							: handleNewPAYMENTProviderCreate
					)}
					className="addEditCategoryModal"
					key={`AddEditCategoryModal__${open}`}
				>
					<UploadImage
						label="Provider Logo"
						image={watch("logo")}
						btnTextClass="px-6"
						onChange={(image) =>
							uploadImage("logo", image).then((url) => setValue("logo", url))
						}
					/>
					<Input label="Name" {...register("name")} />
					<Input label="Number" {...register("number")} />
					<br />
					<Button type="submit" variant="primary">
						Save
					</Button>
				</form>
			</Modal>
		</Fragment>
	);
}
