/* eslint-disable react/react-in-jsx-scope */
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import { GET_ADMIN_REFERRALS } from "../../../lib/api/admin";
import React from "react";

export default function AllReferrals() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({});
  const [filter, setFilter] = useState({});
  const {
    data: referralsData,
    status,
    error,
  } = useQuery(
    [
      "GET_ADMIN_REFERRALS",
      {
        page,
        ...(search ? { search } : {}),
        sort: JSON.stringify(sort),
        filter: JSON.stringify(filter),
      },
    ],
    GET_ADMIN_REFERRALS
  );

  return (
    <AdminLayout>
      <AdminTableView
        query={{ status, error }}
        title="Referrals"
        search={{
          title: "Search Refers",
          placeholder: "Search by name, email...",
          btnText: "Search...",
          onSearch: (value) => setSearch(value),
        }}
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Name (DESC)", value: "referrer.fullname:-1" },
                { label: "Name (ASC)", value: "referrer.fullname:1" },
                { label: "Email (DESC)", value: "referrer.email:-1" },
                { label: "Email (ASC)", value: "referrer.email:1" },
                { label: "Total Post (DESC)", value: "total_post:-1" },
                { label: "Total Post (ASC)", value: "total_post:1" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: +value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Fullname", dotsMenu: [], key: "fullname" },
            { name: "Email", dotsMenu: [], key: "email" },
            { name: "Total Post", dotsMenu: [], key: "total_post" },
            { name: "", dotsMenu: [], key: "actions" },
          ],
          data: referralsData?.data?.data.map((data) => ({
            _id: data?._id,
            fullname: data?.referrer?.fullname || "N/A",
            email: data?.referrer?.email || "N/A",
            total_post: data?.total_post || "N/A",
            actions: "",
          })),
          pagination: {
            total: referralsData?.data?.total,
            page,
            prev: referralsData?.data?.prev,
            next: referralsData?.data?.next,
            setPage,
          },
        }}
      />
    </AdminLayout>
  );
}
