import './App.css';

import React from 'react';
import NotificationItem from './uicomponents/items/NotificationItem';
import WorkProvider from './uicomponents/userPanel/workProvider/WorkProvider';
import WorkProviderCard from './uicomponents/userPanel/workProvider/WorkProviderCard';
import ShowWorkProvider from './uicomponents/userPanel/workProvider/ShowWorkProvider';
import BidCard from './uicomponents/userPanel/bids/BidCard';
import Bids from './uicomponents/userPanel/bids/Bids';
import DealCardClient from './uicomponents/userPanel/deals/DealCardClient';
import DealCardProvider from './uicomponents/userPanel/deals/DealCardProvider';
import Deals from './uicomponents/userPanel/deals/Deals';
import JobPostCard from './uicomponents/userPanel/jobPosts/JobPostCard';
import JobCard from './uicomponents/userPanel/exploreJob/JobCard';
import JobDetails from './uicomponents/userPanel/jobDetails/JobDetails';
import JobPosts from './uicomponents/userPanel/jobPosts/JobPosts';
import ExploreJobs from './uicomponents/userPanel/exploreJob/ExploreJob';

import ProfileSec from './uicomponents/userPanel/section/profile/ProfileSec';
import ProfileSection from './sections/profile/ProfileSection';
import ProfileSelf from './uicomponents/userPanel/ProfileView/ProfileSelf';
import Postjob from './uicomponents/userPanel/PostJob/Postjob';
import EditProfileN from './uicomponents/userPanel/editprofile.js/EditProfileN';
import Test from './uicomponents/userPanel/Test';
import ClientServiceDealDetails from './uicomponents/DealWithClient/ClientServiceDealDetails';

function TestApp() {
  let notification = {
    createdAt: 'August 7,2021',
    message: 'New Job Posted',
    is_seen: false,
  };

  return (
    <div className='App'>
      {/* <NotificationItem notification={notification} log={false}/> */}
      {/* <JobDetails/> */}
      {/* <JobCard/> */}
      {/* <ProfileOther/> */}

      {/* <Bids/> */}
      {/* <ProfileSelf/> */}
      {/* <Postjob/> */}
      {/* <EditProfileN/> */}
      {/* <WorkProvider/> */}
      {/* <ClientDetailsDealDetails/> */}
      {/* <ClientServiceDealDetails/> */}
      {/* <ClientServiceDealDetails/> */}
      {/* <Deals/> */}
    </div>
  );
}

export default TestApp;
