import React, { useContext, useEffect } from 'react';
import Banner from '../components/banner/Banner';
import { AuthContext } from '../context/auth-context';
// import ExploreItems from "../sections/Explore/ExploreItems";
import ExploreJobs from '../uicomponents/userPanel/exploreJob/ExploreJob';
import ExploreJobsNew from '../uicomponents/userPanel/exploreJob/ExploreJobNew.jsx';

const Explore = () => {
  const auth = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ExploreJobsNew />
    </div>
  );
};

export default Explore;
