import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";
const PersonalInfo = (props) => {
  return (
    <div className="personalinfo--container">
      <div className="personal--info">
        <div className="info--name">
          <p>Email</p>
          <p>Birthday</p>
          <p>NID/Birth Certificate</p>
          <p>Mobile Banking</p>
          <Link to="/activity-log">
            <p className="profile__log">See Activity Log</p>
          </Link>
        </div>
        <div className="info--value">
          <p>{props.email}</p>
          <p>{props.birthDate}</p>
          <p>{props.nid}</p>
          <p>{props.mobileBank}</p>
        </div>
      </div>
      <h1 className="user__name">{props.name}</h1>

      <img src={props.image} alt="" />
    </div>
  );
};

export default PersonalInfo;
