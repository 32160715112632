import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../../hooks/http-hook";
import ApplicationModalEdit from "../../../components/util/ApplicationModalEdit";
import SideBar from "../../../components/SideBar";
import { AuthContext } from "../../../context/auth-context";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Register a custom font for the PDF
Font.register({ family: "Helvetica", src: "path/to/Helvetica.ttf" });

const styles = StyleSheet.create({
  container: { padding: 20 },
  section: { marginBottom: 20 },
  title: { fontSize: 24, marginBottom: 10, textAlign: "center" },
  subTitle: { fontSize: 18, marginBottom: 5 },
  table: { display: "table", width: "100%", marginBottom: 10 },
  tableRow: { display: "table-row" },
  tableCell: {
    display: "table-cell",
    padding: 5,
    border: "1px solid #ccc",
    textAlign: "left",
  },
  field: { marginBottom: 10 },
  strong: { fontWeight: "bold" },
  image: { width: 100, height: 100, marginTop: 10 },
  list: { listStyleType: "none", paddingLeft: 0 },
  listItem: {
    backgroundColor: "#f8f9fa",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
});

const DetailedConsultancy = () => {
  const { consultancyID, side } = useParams();
  const [consultancy, setConsultancy] = useState(null);
  const [applicationModal, setApplicationModal] = useState(false);
  const { sendRequest } = useHttpClient();
  const componentRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [statusEdit, setStatusEdit] = useState(false);
  const auth = useContext(AuthContext);
  const [selectedStatus, setSelectedStatus] = useState();
  const [userAgent, setUserAgent] = useState();

  const getUser = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_BACKEND_URL + "/user", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });
      setUserAgent(res.data.agent);
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  // Fetch consultancy data
  useEffect(() => {
    const fetchConsultancy = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/consultancy/get?id=${consultancyID}`;
        const responseData = await sendRequest(url);
        setConsultancy(responseData.consultancy);
        console.log(JSON.parse(responseData.consultancy[0].form_data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchConsultancy();
    getUser();
  }, [consultancyID]);

  // Fetch status timeline
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/consultancy/status/get?consultancy_id=${consultancyID}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("auth_token"),
            },
          }
        );
        setStatuses(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchStatuses();
  }, [consultancyID]);

  if (!consultancy) return <div>Loading...</div>;

  const renderField = (key, field) => {
    // console.log("field", field);

    if (field.listData)
      return (
        <div key={key}>
          <strong>{key}</strong>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.01)",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <div>{renderField(field.field_name, field.listData)}</div>
          </div>
        </div>
      );

    if (Array.isArray(field.children)) {
      // Render a list type field with children
      return (
        <div key={key}>
          <strong>{key}</strong>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.01)",

              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            {field.children.map((child, index) => (
              <div key={index}>{renderField(child.field_name, child)}</div>
            ))}
          </div>
        </div>
      );
    } else if (Array.isArray(field)) {
      // Handle array type fields directly
      return (
        <div key={key}>
          <strong>{key}</strong>
          {field.map((item, idx) => (
            <div
              key={idx}
              style={{
                backgroundColor: "rgba(0,0,0,0.03)",
                padding: "15px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              {item.children?.map((subItem, index) => (
                <div key={index}>
                  {renderField(subItem.field_name, subItem)}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    } else if (typeof field.value === "object" && field.value !== null) {
      // Handle object values (e.g., attachments or complex data)
      return (
        <div
          key={key}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "4px",
          }}
        >
          <strong>{key}:</strong>
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            {Object.entries(field.value).map(([subKey, subValue], index) => {
              const isImageUrl =
                typeof subValue === "string" &&
                /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(subValue);

              return (
                <div key={index}>
                  {isImageUrl ? (
                    <img
                      src={subValue}
                      alt={`${key}-${subKey}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginTop: "10px",
                      }}
                    />
                  ) : (
                    <div>
                      <strong>{subKey}:</strong> {subValue}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      // Handle basic field types and direct values
      switch (field.field_type) {
        case "Text":
        case "Number":
        case "Date":
        case "Country":
        case "Dropdown":
          return (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <strong>{key}:</strong> {field.value}
            </div>
          );
        case "Single Attachment":
          const isSingleImageUrl =
            typeof field.value === "string" &&
            /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(field.value);
          return (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <strong>{key}:</strong>
              {isSingleImageUrl ? (
                <img
                  src={field.value}
                  alt={key}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    marginTop: "10px",
                  }}
                />
              ) : (
                <div>{field.value}</div>
              )}
            </div>
          );
        case "Multiple Attachments":
          if (!field.value) return null;
          return (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <strong>{key}:</strong>
              <div style={{ display: "flex", gap: "10px" }}>
                {field.value.map((url, idx) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`${key}-${idx}`}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      marginTop: "10px",
                    }}
                  />
                ))}
              </div>
            </div>
          );
        default:
          return (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <strong>{key}:</strong> {field.value}
            </div>
          );
      }
    }
  };

  const EditStatus = (status) => {
    setStatusEdit(true);
    setSelectedStatus(status);
  };

  const renderFormData = (data) => {
    return Object.entries(data)?.map(([key, value]) => {
      if (value.children) {
        return (
          <div
            key={key}
            style={{
              marginBottom: "10px",
            }}
          >
            <strong>{key}</strong>
            <div
              style={{
                padding: "10px",
                marginTop: "10px",
                backgroundColor: "rgba(0,0,0,.03)",
                borderRadius: "5px",
              }}
            >
              {value?.children?.map(
                (child, index) =>
                  child && ( // Ensure `child` is not null or undefined
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",

                        borderRadius: "5px",
                      }}
                    >
                      {Object.entries(child).map(([subKey, subValue]) =>
                        renderField(subKey, subValue)
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        );
      }
      return (
        <div key={key} style={{ marginBottom: "10px" }}>
          {renderField(key, value)}
        </div>
      );
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleStatusSubmit = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/consultancy/status/create`,
        {
          consultancy_id: consultancyID,
          name: status,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      setStatus(""); // Reset status input
      handleCloseModal(); // Close the modal
      toast.success("Status updated successfully");
    } catch (error) {}
  };
  const handleStatusEdit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/consultancy/status/edit`,
        { id: selectedStatus._id, name: selectedStatus.name },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      setSelectedStatus(""); // Reset status input
      setStatusEdit(false); // Close the modal
      toast.success("Status updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <div className="client_deal_sidebar">
        <SideBar />
      </div>
      <div style={{ padding: "20px", width: "100%" }} ref={componentRef}>
        <div style={{ display: "flex" }}>
          <h1 className="mr-4" style={{ marginRight: "20px" }}>
            Consultancy Details
          </h1>

          {userAgent && (
            <button
              className="btn btn-primary no-print "
              onClick={handleOpenModal}
            >
              Add Status
            </button>
          )}
          <button
            className="btn btn-primary no-print"
            onClick={() => setApplicationModal(true)}
          >
            Edit Consultancy Details
          </button>
        </div>

        <div style={{ display: "flex", gap: "20px", marginTop: "50px" }}>
          <div
            style={{
              marginBottom: "20px",
              backgroundColor: "#f8f9fa",
              padding: "15px",
              borderRadius: "8px",
              marginBottom: "15px",
              border: "1px solid #ddd",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3 className="mb-2">Application Details:</h3>
            <div
              style={{
                backgroundColor: "#f8f9fa",
                padding: "10px",
                borderRadius: "5px",
                width: "500px",
              }}
            >
              {renderFormData(JSON.parse(consultancy[0]?.form_data))}
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#f8f9fa",
              padding: "15px",
              borderRadius: "8px",
              marginBottom: "15px",
              border: "1px solid #ddd",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ul
              style={{
                listStyleType: "none",
                paddingLeft: "0",
                width: "400px",
              }}
            >
              <h3 className="mb-2">Universities:</h3>
              {JSON.parse(consultancy[0].universities)?.map(
                (university, index) => (
                  <li key={index} style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      {university.varsity}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <strong>Country:</strong>
                      <span>{university.country}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <strong>Session:</strong>
                      <span>{university.session}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <strong>Intake:</strong>
                      <span>{university.intake}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <strong>Major:</strong>
                      <span>{university.major}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <strong>Location:</strong>
                      <span>{university.location}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <strong>Program:</strong>
                      <span>{university.program}</span>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>

        {/* Status timeline */}
        {statuses.length > 0 && (
          <div style={{ maxWidth: "1000px" }}>
            <h3 style={{ margin: "30px 0" }}>Status Timeline:</h3>
            <div className="timeline">
              {statuses?.map((statusItem, index) => (
                <React.Fragment key={index}>
                  <div
                    className="timeline-step"
                    onClick={() => userAgent && EditStatus(statusItem)}
                  >
                    <div className="timeline-marker">{index + 1}</div>
                    <div className="timeline-content">
                      <strong>
                        {new Date(statusItem.updatedAt).toLocaleDateString()}
                      </strong>
                      <span>{statusItem.name}</span>
                    </div>
                  </div>
                  {index < statuses.length - 1 && (
                    <div className="timeline-connector"></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}

        {/* Modal for Editing Status */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Consultancy Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="status">Enter new status</label>
              <input
                type="text"
                className="form-control"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleStatusSubmit}>
              Save Status
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={statusEdit} onHide={() => setStatusEdit(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Consultancy Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="status"
                value={selectedStatus?.name}
                onChange={(e) =>
                  setSelectedStatus({ ...selectedStatus, name: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setStatusEdit(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleStatusEdit}>
              Save Status
            </Button>
          </Modal.Footer>
        </Modal>

        <ApplicationModalEdit
          open={applicationModal}
          onCancel={() => setApplicationModal(false)}
          language={auth.language}
          user={consultancy[0]?.agent_id}
          data={JSON.parse(consultancy[0]?.form_data || "[]")}
          uniData={JSON.parse(consultancy[0]?.universities || "[]")}
          consultancyID={consultancyID}
        />
      </div>
    </div>
  );
};

export default DetailedConsultancy;
