import axios from "axios";
import ShowBids from "../bids/ShowBids";
import Pagination from "../../Pagination";
import LoadingSpinner from "../../../components/util/LoadingSpinner";

import React, { useEffect, useState, useContext } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { AuthContext } from "../../../context/auth-context";

import { useHistory } from "react-router";
import ShowjobPosts from "./ShowJobPosts";

function JobPosts(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loadedposts, setLoadedPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const auth = useContext(AuthContext);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();

  const [refr, setRefr] = useState(false);
  const [page, setPage] = useState(1);
  const onSuccess = () => {
    setRefr(!refr);
  };

  // const responseData = await sendRequest("https://api.npoint.io/ce37fd137c6998494665"); //tested for pagination

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/user/get_my_jobs/?page=${page}`
        );
        // const responseData = await sendRequest(
        //   "https://api.npoint.io/06263859277cd218ceae"
        // );
        setLoadedPosts(responseData);
        setLoading(false);
      } catch (err) {}
    };
    if (auth.user) {
      fetch();
    }
    //eslint-disable-next-line
  }, [auth.user, refr, page]);

  //for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPosts = loadedposts.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container">
      {/* {isLoading && <LoadingSpinner asOverlay />} */}
      <h3 className="mb-3" style={{ fontWeight: "bold" }}>
        {auth.language === "en" ? "My Job Post" : "আমার কাজের পোস্ট"}
      </h3>
      {currentPosts.length > 0 ? (
        <ShowjobPosts jobPosts={currentPosts} loading={loading} />
      ) : (
        <h2>No job is posted!</h2>
      )}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={loadedposts.length}
        paginate={paginate}
        pageNumber={currentPage}
      />
    </div>
  );
}

export default JobPosts;
