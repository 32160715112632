import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ExploreJobs from "../../../uicomponents/userPanel/exploreJob/ExploreJob";

import { useHttpClient } from "../../../hooks/http-hook";
import ShowExpJobs from "../../../uicomponents/userPanel/exploreJob/ShowExpJobs";
import ShowTopProvider from "./ShowTopProvider";
import { AuthContext } from "../../../context/auth-context";

function TopProvider(props) {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [topProviders, setTopProviders] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState();
  const auth = useContext(AuthContext);
  useEffect(() => {
    fetchExpJobs();
  }, [limit]);

  const fetchExpJobs = async () => {
    setLoading(true);
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/top_providers?limit=${limit}`
      );
      setTopProviders(responseData);
      setLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setLimit(response?.data?.homepage_top_provider_limit);
        console.log("limit", response.data.homepage_top_provider_limit);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  const header = {
    color: "#000",
    fontFamily: "Nunito",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  };

  return (
    <div className="jobCard_container">
      <p style={header}>
        {auth.language === "en"
          ? " Our Top Providers"
          : "আমাদের শীর্ষ ফ্রিল্যান্সার"}
      </p>

      {topProviders?.data?.length > 0 ? (
        <ShowTopProvider providers={topProviders} loading={loading} />
      ) : (
        <h3 style={{ color: "var(--grey_three)" }}>No top provider found!</h3>
      )}
    </div>
  );
}

export default TopProvider;
