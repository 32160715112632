import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import FeedbackDetailsModal from "../../../components/modals/FeedbackDetailsModal/FeedbackDetailsModal";
import { GET_FEEDBACKS, UPDATE_FEEDBACK } from "../../../lib/api/admin";
import { deleteConfirmation } from "../../../lib/sweetAlert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Feedbacks() {
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState(null);
  const [feedbackId, setFeedbackId] = useState();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ issue: "asc" });
  const {
    data: feedbacks,
    status,
    error,
    refetch,
  } = useQuery(
    [
      "GET_FEEDBACKS",
      { page, ...(search ? { search } : {}), sort: JSON.stringify(sort) },
    ],
    GET_FEEDBACKS
  );
  const { mutateAsync: updateFeedback } = useMutation(UPDATE_FEEDBACK);

  const resolveFeedbacks = async () => {
    try {
      console.log(feedbackId);
      await updateFeedback({
        _id: feedbackId,
        state: "RESOLVED",
      });
      await refetch();
	  setShow(false);
      toast.success("Issue resolved successfully!");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  return (
    <AdminLayout>
      {/* Modals */}
      <FeedbackDetailsModal
        open={feedbackDetails !== null}
        onClose={() => setFeedbackDetails(null)}
        feedbackDetails={feedbacks?.data?.data?.find(
          (i) => i._id === feedbackDetails?._id
        )}
      />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to initiate the deal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => resolveFeedbacks(feedbackDetails?._id)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <AdminTableView
        query={{ status, error }}
        title="Feedbacks"
        table_name="Feedback"
        deleteOpt={true}
        search={{
          title: "Search Feedbacks",
          placeholder: "Search by title, name & email...",
          btnText: "Search",
          onSearch: (value) => setSearch(value),
        }}
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Name (DESC)", value: "name:desc" },
                { label: "Name (ASC)", value: "name:asc" },
                { label: "Email (DESC)", value: "email:desc" },
                { label: "Email (ASC)", value: "email:asc" },
                { label: "Status (DESC)", value: "state:asc" },
                { label: "Status (ASC)", value: "state:asc" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Title", dotsMenu: [], key: "title" },
            { name: "Description", dotsMenu: [], key: "description" },
            { name: "Name", dotsMenu: [], key: "name" },
            { name: "Email", dotsMenu: [], key: "email" },
            { name: "Status", dotsMenu: [], key: "status" },
            {
              name: "Actions",
              dotsMenu: [
                {
                  name: "View Feedback",
                  onClick: (data) => setFeedbackDetails(data),
                },
                {
                  name: "Resolve",
                  onClick: (data) => {
                    setFeedbackId(data?._id);
                    setShow(true);
                    console.log(data);
                  },
                },
                { name: "Delete" },
              ],
              key: "actions",
            },
          ],
          data: feedbacks?.data?.data.map((feedback) => ({
            _id: feedback._id,
            title: feedback?.title,
            description: feedback?.description,
            name: feedback?.name,
            email: feedback?.email,
            status: feedback?.state,
            actions: "",
          })),
          pagination: {
            total: feedbacks?.data?.total,
            page,
            prev: feedbacks?.data?.prev,
            next: feedbacks?.data?.next,
            setPage,
          },
        }}
      />
    </AdminLayout>
  );
}
