import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  GET_PAYMENT_PROVIDERS,
  PAY_FOR_DEAL,
  PAY_PROVIDER,
  RATE_CLIENT,
  RATE_PROVIDER,
} from "../../../lib/api/admin";
import ConfirmPayment from "../../payment/ConfirmPayment";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import Modal from "../../shared/Modal/Modal";
import Select from "../../shared/Select/Select";
import StarRatings from "../../StarRatings";

export default function RateModal({
  children,
  refetch,
  type,
  deal_id,
  provider_id,
  client_id,
}) {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const { mutateAsync: rateProvider } = useMutation(RATE_PROVIDER);
  const { mutateAsync: rateClient } = useMutation(RATE_CLIENT);

  const rateProviderFn = useCallback(async () => {
    try {
      await rateProvider({
        provider_id,
        deal_id,
        rating,
      });
      await refetch?.();
      toast.success("Rated successful");
      setOpen(false);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  }, [deal_id, provider_id, rateProvider, rating, refetch]);
  const rateClientFn = useCallback(async () => {
    try {
      await rateClient({
        client_id,
        deal_id,
        rating,
      });
      await refetch?.();
      toast.success("Rated successful");
      setOpen(false);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  }, [client_id, deal_id, rateClient, rating, refetch]);

  return (
    <Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })}
      <Modal open={open} onClose={setOpen} title={`Rate ${type}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <StarRatings
            rating={rating}
            size="50px"
            onChange={(value) => setRating(value)}
          />

          <p
            style={{
              fontSize: "20px",
              color:
                rating === 0
                  ? "gray"
                  : rating === 3
                  ? "#FF8CB9"
                  : rating <= 2
                  ? "#FF0002"
                  : "#24CC00",
              fontWeight: "bold",
              marginBottom: "2rem",
            }}
          >
            {rating === 0
              ? "Select Rating"
              : rating === 1
              ? " Bad!"
              : rating === 2
              ? " Reasonable!"
              : rating === 3
              ? " Average!"
              : rating === 4
              ? " Good!"
              : " Excellent!"}
          </p>

          <Button
            onClick={type === "Provider" ? rateProviderFn : rateClientFn}
            variant="primary"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
}
