import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import AdminLayout from '../../../components/AdminLayout';
import AdminTableView from '../../../components/AdminLayout/AdminComponentView/AdminTableView';
import IssueDetailsModal from '../../../components/modals/IssueDetailsModal/IssueDetailsModal';
import { GET_ISSUES } from '../../../lib/api/admin';

export default function UnresolvedIssues() {
  const [page, setPage] = useState(1);
  const [issueDetails, setIssueDetails] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ issue: "asc" });
  const [filter, setFilter] = useState({ is_resolved: false });
  const { data: unresolvedIssues, status, error } = useQuery(["GET_ISSUES", { page, ...search?{search}:{}, sort: JSON.stringify(sort), filter: JSON.stringify(filter) }], GET_ISSUES);

  return (
    <AdminLayout>
      {/* Modals */}
      <IssueDetailsModal
        open={issueDetails !== null} 
        onClose={() => setIssueDetails(null)}
        issueDetails={unresolvedIssues?.data?.data?.find(i => i._id === issueDetails?._id)} 
      />

      <AdminTableView
        query={{ status, error }}
        title="Unresolved Issues"
        table_name="Issue"
				deleteOpt={true}
        
        filters={[
          { name: "Unresolved Issues", value: "", active: filter.is_resolved === false, onClick: () => setFilter(values => ({ ...values, is_resolved: false })) },
          { name: "Resolved Issues", value: "", active: filter.is_resolved === true, onClick: () => setFilter(values => ({ ...values, is_resolved: true })) },
        ]}
        search={{
          title: "Search Issue",
          placeholder: "Search by issue, issued by...",
          btnText: "Search Issue",
          onSearch: (value) => setSearch(value)
        }}
        sort={{
          title: "Sort By",
          selects: [
            { 
              name: "Sort By", 
              options: [
                { label: "Issues by (DESC)", value: "user_id.fullname:desc" }, 
                { label: "Issues by (ASC)", value: "user_id.fullname:asc" },
                { label: "Issues at (DESC)", value: "createdAt:desc" },
                { label: "Issues at (ASC)", value: "createdAt:asc" },
              ], 
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: value }));
              }
            }
          ]
        }}
        table={{
          headers: [
            { name: "issue", dotsMenu: [], key: "issue" },
            { name: "Issued By", dotsMenu: [], key: "issued_by" },
            { name: "Issued At", dotsMenu: [], key: "issued_at" },
            { name: "", dotsMenu: [
              { name: "View Issue", onClick: (data) => setIssueDetails(data) },
              { name: "Delete" }
            ], key: "actions" }
          ],
          data: unresolvedIssues?.data?.data.map(issue => ({
            _id: issue._id,
            issue: issue?.issue || "N/A",
            issued_by: issue?.user_id?.fullname || "N/A",
            issued_at: moment(issue?.createdAt).format("YYYY-MM-DD") || "N/A",
            actions: ""
          })),
          pagination: { total: unresolvedIssues?.data?.total, page, prev: unresolvedIssues?.data?.prev, next: unresolvedIssues?.data?.next, setPage }
        }}
      />
    </AdminLayout>
  )
}