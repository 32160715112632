import React from "react";

const Pagination = ({
  itemsPerPage,
  totalPage,
  paginate,
  pageNumber,
  closed_by_admin,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ margin: "1rem 0" }}>
      <ul style={{ display: "flex", listStyleType: "none", padding: 0 }}>
        {pageNumbers.map((number, index) => (
          <li
            key={number}
            style={{
              marginLeft: index > 0 ? "0.5rem" : "0",
              display: "inline-block",
            }}
          >
            <button
              onClick={() => {
                paginate(number);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "4px",
                background:
                  number === pageNumber
                    ? "var(--color-primary)"
                    : "var(--color-grey-six)",
                border: "none",
                outline: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color:
                  number === pageNumber
                    ? "var(--color-white-bg)"
                    : "var(--color-grey-one)",
                cursor: "pointer",
              }}
              onMouseOver={(e) => {
                if (number !== pageNumber) {
                  e.currentTarget.style.background = "var(--color-primary)";
                  e.currentTarget.style.color = "var(--color-white-bg)";
                }
              }}
              onMouseOut={(e) => {
                if (number !== pageNumber) {
                  e.currentTarget.style.background = "var(--color-grey-six)";
                  e.currentTarget.style.color = "var(--color-grey-one)";
                }
              }}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
