import React, { useEffect } from "react";
import FailedBids from "../sections/admin/FailedBids";

const AdminFailedBids = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <FailedBids />
    </div>
  );
};

export default AdminFailedBids;
