import React from "react";
import { Link } from "react-router-dom";
import "./SidebarOption.css";
const TinyOption = (props) => {
  return (
    <Link to={props.to}>
      <div
        onClick={props.onClick}
        className={`tiny__option ${
          props.active === "true" && "sidebarOption--active"
        }`}
      >
        <img src={props.icon} alt="" />
        <h2>{props.text}</h2>
      </div>
    </Link>
  );
};

export default TinyOption;
