import React, { useEffect } from "react";
import ManageJobs from "../sections/admin/ManageJobs";

const AdminJobs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <ManageJobs />;
};

export default AdminJobs;
