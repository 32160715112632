import React, { useRef, useState, useEffect } from 'react';
import './CategoryEditor.css';
import '../../components/util/ImageUploader.css';
import Modal from '../../components/util/Modal';
import { Button } from '../button/Button';
import { useHttpClient } from '../../hooks/http-hook';
import ErrorModal from '../util/ErrorModal';
import LoadingSpinner from '../util/LoadingSpinner';

const CategoryEditor = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const fileInputRef = useRef();

  const [inp1, setInp1] = useState('');
  const [inp2, setInp2] = useState('');
  const [inp3, setInp3] = useState('');
  const [inp4, setInp4] = useState('');
  const [inp5, setInp5] = useState('');
  const [inp6, setInp6] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [printDelivery, setPrintDelivery] = useState('');
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  useEffect(() => {
    if (props.editCat) {
      props.editCat.logo &&
        setPreview(
          `${process.env.REACT_APP_BACKEND_URL}/${props.editCat.logo}`
        );
      setInp1(props.editCat.name);
      setInp3(props.editCat.min_charge_client);
      setInp2(props.editCat.max_charge_client);
      setInp5(props.editCat.min_charge_provider);
      setInp4(props.editCat.max_charge_provider);
      setInp6(props.editCat.delay);
      setIsDisabled(props.editCat.is_disabled);
    }
    //eslint-disable-next-line
  }, [props.editCat]);

  const clearInputs = () => {
    setImage(null);
    setPreview(null);
    setInp1('');
    setInp2('');
    setInp3('');
    setInp4('');
    setInp5('');
    setInp6('');
    setIsDisabled(false);
  };
  const addCategory = async () => {
    try {
      const formData = new FormData();
      formData.append('logo', image[0]);
      formData.append('name', inp1);
      formData.append('min_charge_client', inp3);
      formData.append('max_charge_client', inp2);
      formData.append('min_charge_provider', inp5);
      formData.append('max_charge_provider', inp4);
      formData.append('delay', inp6);
      formData.append(
        'print',
        printDelivery === 'printWithDelivery'
          ? true
          : printDelivery === 'delivery'
          ? false
          : false
      );
      formData.append(
        'delivery',
        printDelivery === 'printWithDelivery'
          ? true
          : printDelivery === 'delivery'
          ? true
          : false
      );
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/add_category',
        'POST',
        formData
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
        clearInputs();
      }
    } catch (err) {}
  };

  const addSubCategory = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/add_category',
        'POST',
        JSON.stringify({
          name: inp1,
          parent: props.selected.name,
          min_charge_client: inp3,
          max_charge_client: inp2,
          min_charge_provider: inp5,
          max_charge_provider: inp4,
          delay: inp6,
          print:
            printDelivery === 'printWithDelivery'
              ? true
              : printDelivery === 'delivery'
              ? false
              : false,
          delivery:
            printDelivery === 'printWithDelivery'
              ? true
              : printDelivery === 'delivery'
              ? true
              : false,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
        clearInputs();
      }
    } catch (err) {}
  };

  const editCategory = async () => {
    try {
      // print:printDelivery === "printWithDelivery" ? true : printDelivery === "delivery" ? false : false,
      // delivery:printDelivery === "printWithDelivery" ? true : printDelivery === "delivery" ? true : false
      const formData = new FormData();
      image && formData.append('logo', image[0]);
      formData.append('name', inp1);
      formData.append('min_charge_client', inp3);
      formData.append('max_charge_client', inp2);
      formData.append('min_charge_provider', inp5);
      formData.append('max_charge_provider', inp4);
      formData.append('delay', inp6);
      formData.append(
        'print',
        printDelivery === 'printWithDelivery'
          ? true
          : printDelivery === 'delivery'
          ? false
          : false
      );
      formData.append(
        'delivery',
        printDelivery === 'printWithDelivery'
          ? true
          : printDelivery === 'delivery'
          ? true
          : false
      );
      formData.append('is_disabled', isDisabled);
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          '/admin/update_category/?category_id=' +
          props.editCat._id,
        'PUT',
        formData
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
      }
    } catch (err) {}
  };

  return (
    <Modal contentOnly show={props.show} onCancel={props.onCancel}>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='editor__modal'>
        <img
          className='cross_btn'
          src='/assets/cross.svg'
          alt=''
          onClick={() => {
            props.onCancel();
          }}
        />
        {props.imgedit && (
          <div className='split_imgbtn'>
            {preview ? (
              <img className='img_prev' src={preview} alt='' />
            ) : (
              <div className='empty_img'></div>
            )}
            <div className='image__uploader'>
              <p>
                Category Icon <span>(svg preferred)</span>
              </p>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef.current.click();
                }}
              >
                <span>Upload</span> <img src='/assets/upload.svg' alt='' />
              </button>
              <input
                type='file'
                className='invisible_inp'
                ref={fileInputRef}
                accept='image/*'
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file && file.type.substr(0, 5) === 'image') {
                    setImage(file);
                  } else {
                    setImage(null);
                  }
                }}
              />
            </div>
          </div>
        )}
        <div className='inputs__container'>
          <div className='inp_item'>
            {props.cat && <p className='label__item'>Category Name</p>}
            {props.subcat && <p className='label__item'>Subcategory Name</p>}
            <input
              type='text'
              value={inp1}
              onChange={(e) => setInp1(e.target.value)}
            />
          </div>
          <div className='inp_item'>
            <p className='label__item'>Max Client Charge</p>
            <input
              type='text'
              value={inp2}
              onChange={(e) => setInp2(e.target.value)}
            />
            <p>Tk.</p>
          </div>

          <div className='inp_item'>
            <p className='label__item'>Min Client Charge</p>
            <input
              type='text'
              value={inp3}
              onChange={(e) => setInp3(e.target.value)}
            />
            <p>Tk.</p>
          </div>
          <div className='inp_item'>
            <p className='label__item'>Max Provider Charge</p>
            <input
              type='text'
              value={inp4}
              onChange={(e) => setInp4(e.target.value)}
            />
            <p>Tk.</p>
          </div>
          <div className='inp_item'>
            <p className='label__item'>Min Provider Charge</p>
            <input
              type='text'
              value={inp5}
              onChange={(e) => setInp5(e.target.value)}
            />
            <p>Tk.</p>
          </div>

          <div className='inp_item'>
            <p className='label__item'>Delay</p>
            <input
              type='text'
              value={inp6}
              onChange={(e) => setInp6(e.target.value)}
            />
            <p>Minutes</p>
          </div>

          <div className='inp_item'>
            <p className='label__item'>Print/Delivery status</p>
            <select
              type='text'
              style={{ padding: '8px 5px', marginTop: '12px' }}
              onChange={(e) => setPrintDelivery(e.target.value)}
              value={printDelivery}
            >
              <option value=''>Select Type</option>
              <option value='printWithDelivery'>
                Printing + Delivery Both Mandatory
              </option>
              <option value='delivery'>Only Delivery Mandatory </option>
              <option value='none'>Neither are Mandatory </option>
            </select>
          </div>

          {props.edit && (
            <div className='inp_item'>
              <p className='label__item'>Is Disabled</p>
              <input
                className='checkbox'
                onClick={() => setIsDisabled(!isDisabled)}
                checked={isDisabled}
                type='checkbox'
              />
            </div>
          )}
        </div>

        <div className='btn_cont'>
          {!props.edit && props.cat && (
            <Button
              onClick={addCategory}
              buttonStyle='btn--apply'
              isDisabled={!image}
            >
              Add Category
            </Button>
          )}
          {!props.edit && props.subcat && (
            <Button onClick={addSubCategory} buttonStyle='btn--apply'>
              Add Subcategory
            </Button>
          )}
          {props.edit && !props.subcat && (
            <Button onClick={editCategory} buttonStyle='btn--apply'>
              save
            </Button>
          )}
          {props.edit && props.subcat && (
            <Button onClick={editCategory} buttonStyle='btn--apply'>
              save
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CategoryEditor;
