import React, { Fragment, useEffect, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import Modal from '../../util/Modal';
import Paginator from '../../util/Paginator';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './ProviderList.css';
import ProviderListItem from './ProviderListItem';
const ProviderList = (props) => {
  const timeOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedBidders, setLoadedBidders] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showListOf, setShowListOf] = useState('');
  const showHandler = (bid_type) => {
    const filterBidders = loadedBidders?.filter(
      (pd) => pd.bid_type === bid_type
    );

    setShowListOf(filterBidders.map((i) => i));
  };

  useEffect(() => {
    const fetchBidders = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/client/get_bids/?job_id=${props.id}&page=${page}`
        );
        setLoadedBidders(responseData);
      } catch (err) {}
    };
    fetchBidders();
  }, [sendRequest, props.id, refresh, page]);

  return (
    <Modal
      className='wider'
      contentOnly
      onCancel={props.onCancel}
      show={props.show}
    >
      <div className='providerlist__container'>
        {isLoading && <LoadingSpinner asOverlay />}
        <img
          className='cross__btn'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h1 className='headline'>Potential Providers who bid for this job</h1>
        <div className='below_headline'>
          <div className='providerlist--header'>
            {/* <div className="providerlist--headerleft">
              <JobCard
                mypost
                tiny
                id={props.id}
                key={props.id}
                title={props.title}
                category={props.category}
                subCategory={props.subCategory}
                postDate={new Date(props.postDate).toLocaleDateString(
                  undefined,
                  timeOptions
                )}
                budget={props.budget}
              />
            </div>
           */}
            <div className='providerlist--headerright'>
              {/* <p>
								Lorem Ipsum Instructions for the client user what to do. Lorem
								ipsum dolor sit amet, co.
							</p> */}
            </div>
          </div>
          {loadedBidders?.length > 0 && (
            <Fragment>
              <div className='row'>
                <div className='col-12'>
                  <div className='mt-4 mb-5'>
                    <button
                      className={
                        showListOf?.length > 0
                          ? showListOf?.map((bid) =>
                              bid.bid_type === 'NORMAL'
                                ? 'listReqBtn active'
                                : bid.bid_type === 'PRINT'
                                ? 'listReqBtn'
                                : 'listReqBtn'
                            )
                          : 'listReqBtn'
                      }
                      // className="listReqBtn"
                      onClick={() => showHandler('NORMAL')}
                    >
                      Only Service
                    </button>
                    <button
                      // onClick={() => showHandler("Sell")}
                      className={
                        showListOf?.length > 0
                          ? showListOf?.map((bid) =>
                              bid.bid_type === 'PRINT'
                                ? 'listReqBtn active'
                                : bid.bid_type === 'NORMAL'
                                ? 'listReqBtn'
                                : 'listReqBtn'
                            )
                          : 'listReqBtn'
                      }
                      // className="listReqBtn"
                      onClick={() => showHandler('PRINT')}
                    >
                      Print + Delivery
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <hr />
          <div className='provider__list__items'>
            {loadedBidders && loadedBidders.length === 0 && (
              <p>no bid available</p>
            )}
            {showListOf
              ? showListOf?.map((bidder) => {
                  return (
                    <ProviderListItem
                      accepted={bidder.is_accepted_by_client}
                      onSuccess={() => {
                        setRefresh(!refresh);
                      }}
                      key={bidder._id}
                      name={bidder.provider_id.fullname}
                      id={bidder.provider_id._id}
                      bid_id={bidder._id}
                      updatedAt={new Date(bidder.updatedAt).toLocaleDateString(
                        undefined,
                        timeOptions
                      )}
                      budget={bidder.claimed_budget}
                      comment={bidder.comment}
                    />
                  );
                })
              : loadedBidders?.map((bidder) => {
                  return (
                    <ProviderListItem
                      accepted={bidder.is_accepted_by_client}
                      onSuccess={() => {
                        setRefresh(!refresh);
                      }}
                      key={bidder._id}
                      name={bidder.provider_id.fullname}
                      id={bidder.provider_id._id}
                      bid_id={bidder._id}
                      updatedAt={new Date(bidder.updatedAt).toLocaleDateString(
                        undefined,
                        timeOptions
                      )}
                      budget={bidder.claimed_budget}
                      comment={bidder.comment}
                    />
                  );
                })}
            {/* {!isLoading &&
              loadedBidders &&
              loadedBidders.map((bidder) => {
                return (
                  <ProviderListItem
                    accepted={bidder.is_accepted_by_client}
                    onSuccess={() => {
                      setRefresh(!refresh);
                    }}
                    key={bidder._id}
                    name={bidder.provider_id.fullname}
                    id={bidder.provider_id._id}
                    bid_id={bidder._id}
                    updatedAt={new Date(bidder.updatedAt).toLocaleDateString(
                      undefined,
                      timeOptions
                    )}
                    budget={bidder.claimed_budget}
                    comment={bidder.comment}
                  />
                );
              })} */}
          </div>
          <Paginator page={page} setPage={setPage} />
        </div>
      </div>
    </Modal>
  );
};

export default ProviderList;
