import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./DealCardClient.css";
import { AuthContext } from "../../../context/auth-context";
import moment from "moment";
let deal = {
  id: "1",
  profession: "Designing- Logo Design",
  postDate: "July 16th, 2021",
  bidFor: "Service Only",
  dealCondition: "Ongoing",
  dealCreated: "July 16th, 2021",
  deadline: "September 16th, 2021",
  claimedBudget: "2000 BDT",
};

function DealCardClient(props) {
  const auth = useContext(AuthContext);
  const { deal } = props;

  return (
    <div className="row dcard" style={{ marginBottom: "10px" }}>
      <div className="col-lg-7 col-md-12">
        <h5>
          <strong>{props?.deal?.job_id?.title}</strong>
        </h5>

        {deal.state === "ONGOING" ? (
          <div className="dealStatusA mt-4">
            <p>{auth.language === "en" ? "Ongoing Deal" : "চলমান চুক্তি"}</p>
          </div>
        ) : (
          <div className="mt-4">
            <p>{deal?.state}</p>
          </div>
        )}
      </div>

      <div className="col-lg-5 col-md-12 ">
        {/* <div className="d-flex flex-row ">
					<div style={{ marginRight: "5px" }}>
						<i className="far fa-clock"></i>
					</div>
					<div>
						<p>
							{auth.language === "en" ? "Deal Created On" : "চুক্তি তৈরি হয়েছে"}{" "}
							{deal.dealCreated}
						</p>
					</div>
				</div> */}
        <div className="d-flex flex-row ">
          <div style={{ marginRight: "5px" }}>
            <i className="far fa-clock" style={{ color: "red" }}></i>
          </div>
          <div>
            <p>
              {auth.language === "en" ? "Deadline" : " সময়সীমা "}{" "}
              {moment(deal.deadline).format("DD-MM-YYYY")}
            </p>
          </div>
        </div>

        <div className="d-flex flex-row ">
          <div style={{ marginRight: "5px" }}>
            <i className="fas fa-search-dollar"></i>
          </div>
          <div>
            <p>
              {auth.language === "en" ? "Claimed Budget: " : "দাবিকৃত বাজেট: "}

              <span style={{ color: "green", fontWeight: "bold" }}>
                {deal.claimed_budget}
              </span>
            </p>
          </div>
        </div>

        <div className="d-grid gap-2">
          <a href={`/servicedeal-detail/${deal._id}`}>
            <button className="btn btn-primary" type="button">
              {auth.language === "en"
                ? "View Deal Details"
                : "চুক্তির বিবরণ দেখুন"}
            </button>
          </a>
        </div>
      </div>

      {/* <div className="col-5 rightContent">
        <p>
          <strong>Payments</strong>
        </p>
        <div className="col-5">
          <div className="d-flex flex-row justify-content-between">
            <p>Charge</p>
            <p style={{ color: "#159000", fontWeight: "bold" }}>800 BDT</p>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <p>Paid</p>
            <p style={{ color: "#159000", fontWeight: "bold" }}>500 BDT</p>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <p>Due</p>
            <p style={{ color: "#159000", fontWeight: "bold" }}>300 BDT</p>
          </div>
        </div>

        <div className="d-grid gap-2">
          <button className="btn btn-primary" type="button">
            View Deal Details
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default DealCardClient;
