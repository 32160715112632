import {
  faArrowRight,
  faEnvelope,
  faPhone,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import Select from "../shared/Select/Select";
import axios from "axios";
import "./FewFooter.css";

const NewFooter = () => {
  const auth = useContext(AuthContext);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
        //console.log("data", response.data, response.data.contact_phones);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  return (
    <div id="footer">
      <div className="top-section">
        <div
          class="container "
          style={{
            marginBottom: "0px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <img
              src={auth.uiConfig?.logo}
              alt="logo"
              className=""
              height="70px"
            />
            <div className="d-flex justify-content-center flex-wrap social-parent">
              <div className="social-link">
                {globalSettings?.fb_link && (
                  <a href={globalSettings?.fb_link} target="_blank">
                    <img
                      src="/assets/new/facebook-icon.svg"
                      alt="facebook"
                      className="m-2"
                    />
                  </a>
                )}
                {globalSettings?.instagram_link && (
                  <a href={globalSettings?.instagram_link} target="_blank">
                    <img
                      src="/assets/new/instagram-icon.svg"
                      alt="instagram"
                      className="m-2"
                    />
                  </a>
                )}
                {globalSettings?.youtube_link && (
                  <a href={globalSettings?.youtube_link} target="_blank">
                    <img
                      src="/assets/new/youtube-icon.svg"
                      alt="youtube"
                      className="m-2"
                    />
                  </a>
                )}
                {globalSettings?.twitter_link && (
                  <a href={globalSettings?.twitter_link} target="_blank">
                    <img
                      src="/assets/new/twitter-icon.svg"
                      alt="twitter"
                      className="m-2"
                    />
                  </a>
                )}
                {globalSettings?.linkedin_link && (
                  <a href={globalSettings?.linkedin_link} target="_blank">
                    <img
                      src="/assets/new/linkedin-icon.svg"
                      alt="linkedin"
                      className="m-2"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-middle-section">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="footer-links">
                <h3>
                  {auth.language === "en"
                    ? "For Providers"
                    : "ফ্রিল্যান্সারদের জন্য"}
                </h3>
                <ul>
                  <li>
                    <Link to="/explore/all">
                      <span>
                        {auth.language === "en" ? "Browse Jobs" : "কাজ খুঁজুন"}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/deals">
                      <span>
                        {auth.language === "en"
                          ? "Browse Deals"
                          : "চুক্তি খুঁজুন"}
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to=''>
                      <span>
                        {auth.language === 'en'
                          ? 'Job Alerts'
                          : 'কাজ সম্পর্কে অবহিতকরণ'}{' '}
                      </span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to=''>
                      <span>
                        {auth.language === 'en'
                          ? 'My Bookmarks'
                          : 'আমার বুকমার্ক'}{' '}
                      </span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="footer-links">
                <h3>
                  {" "}
                  {auth.language === "en"
                    ? "For Employers"
                    : "গ্রাহকদের জন্য"}{" "}
                </h3>
                <ul>
                  <li>
                    <Link to="/providers/all">
                      <span>
                        {auth.language === "en"
                          ? "Browse Providers"
                          : "ফ্রিল্যান্সার খুঁজুন"}{" "}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/postJob">
                      <span>
                        {auth.language === "en"
                          ? "Post a Job"
                          : "কাজ প্রদান করুন"}{" "}
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to=''>
                      <span>
                        {auth.language === 'en'
                          ? 'Post a Task'
                          : 'একটি টাস্ক পোস্ট করুন'}{' '}
                      </span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to=''>
                      <span>
                        {auth.language === 'en'
                          ? 'Plans & Pricing'
                          : 'পরিকল্পনা এবং মূল্য নির্ধারণ'}{' '}
                      </span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="footer-links">
                <h3>
                  {" "}
                  {auth.language === "en" ? "Helpful Links" : "দরকারী লিঙ্ক"}
                </h3>
                <ul>
                  <li>
                    <Link to="/about">
                      <span>
                        {auth.language === "en"
                          ? "About us"
                          : "আমাদের সম্পর্কে"}{" "}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy">
                      <span>
                        {auth.language === "en"
                          ? "Privacy Policy"
                          : "গোপনীয়তা নীতি"}{" "}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms">
                      <span>
                        {auth.language === "en"
                          ? "Terms of Services"
                          : "পরিষেবার শর্তাবলী"}{" "}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/payment-terms">
                      <span>
                        {auth.language === "en"
                          ? "Payment Terms"
                          : "লেনদেনের শর্ত"}{" "}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <span>
                        {auth.language === "en" ? "FAQs" : "সচরাচর জিজ্ঞাস্য"}{" "}
                      </span>
                    </Link>
                  </li>
                  {/*<li>
                    {auth?.user?._id ? (
                      <Link to="/login" onClick={() => auth.logout()}>
                        <span>
                          {auth.language === "en"
                            ? "Sign Out"
                            : "প্রস্থান করুন"}{" "}
                        </span>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <span>
                          {auth.language === "en" ? "Log in" : "লগ ইন করুন"}{" "}
                        </span>
                      </Link>
                    )}
                    </li>*/}

                  {auth?.user?._id && (
                    <li>
                      <Link to="/user/profile">
                        <span>
                          {auth.language === "en"
                            ? "My Account"
                            : "আমার অ্যাকাউন্ট"}
                        </span>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/send-feedback">
                      <span>
                        {auth.language === "en"
                          ? "Contact Us"
                          : " যোগাযোগ করুন"}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="footer-links">
                <h3>
                  {auth.language === "en" ? "Contact Info" : "যোগাযোগ তথ্য"}
                </h3>

                <>
                  <div style={{ marginBottom: "20px" }}>
                    <ul>
                      {globalSettings?.contact_emails?.map((email, index) => (
                        <li key={index}>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{
                              color: index === 0 ? "white" : "transparent",
                              marginRight: "10px",
                            }}
                          />
                          <span>{email}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <ul>
                      {globalSettings?.contact_phones?.map((number, index) => (
                        <li key={index}>
                          <FontAwesomeIcon
                            icon={faPhone}
                            style={{
                              color: index === 0 ? "white" : "transparent",
                              marginRight: "10px",
                            }}
                          />
                          <span>{number}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <FontAwesomeIcon
                          icon={faMapMarker}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                        <span>{globalSettings?.contact_address}</span>
                      </li>
                    </ul>
                  </div>
                </>
              </div>
            </div>

            {/* <div className='col-xl-4 col-lg-4 col-md-12'>
              <h3>
                <i className='icon-feather-mail'></i>
                {auth.language === 'en'
                  ? 'Sign Up For a Newsletter'
                  : 'সর্বশেষ কাজ বা অফার সম্পর্কে জানতে সাইন আপ করুন'}{' '}
              </h3>
              <p className='text-light'>
                {auth.language === 'en'
                  ? ' Weekly breaking news, analysis and cutting edge advices on job searching.'
                  : 'কাজের বিষয়ে সাপ্তাহিক ব্রেকিং নিউজ, বিশ্লেষণ এবং যুগোপযোগী পরামর্শ অনুসন্ধান'}
              </p>
              <form method='get' className='newsletter'>
                <input
                  type='text'
                  name='fname'
                  placeholder={
                    auth.language === 'en'
                      ? 'Enter your email address'
                      : ' আপনার ইমেইলটি লিখুন'
                  }
                />
                <button
                  type='submit'
                  className='btn  prime-btn d-flex justify-content-center align-items-center'
                  style={{ height: '55px' }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </form>
            </div> */}
          </div>
        </div>
      </div>

      <div className="footer-bottom-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 text-secondary">
              Somadha 2022 © All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
