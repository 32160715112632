import React, { Fragment, useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import FileUploader from "../../../components/util/FileUploader";
import axios from "axios";
import { PROVIDER_SUBMIT_JOB } from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import Modal from "../../shared/Modal/Modal";
import {
  BUCKET_URL,
  S3_PREASSIGNED_POST_URL,
  CLOUDFRONT_URL,
} from "../../../config";

export default function SubmitJonModal({ children, deal_id, delivery }) {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [demoFiles, setDemoFiles] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [globalSettings, setGlobalSettings] = useState({});
  const { watch, handleSubmit } = useForm();
  const { mutateAsync: providerSubmitJob } = useMutation(PROVIDER_SUBMIT_JOB);
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/user/get_globals", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        setGlobalSettings(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  useEffect(() => {
    if (confirmModal) setOpen(false);
  }, [confirmModal]);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmitJob = async () => {
    setConfirmModal(false);
    const attachments = [];
    const attachments_overview = [];
    let uploadAttachments = [];
    let uploadAttachmentOverview = [];

    if (files.length > 0) {
      for (const file of files) {
        const data = await axios.get(
          `${S3_PREASSIGNED_POST_URL}?size=${
            globalSettings?.max_file_size
          }&fileExtension=${file.name.split(".").pop()}`
        );

        const formData = new FormData();
        Object.entries(data.data[0].fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        const uploadResponse = await axios.post(
          "https://somadha.s3.amazonaws.com",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        uploadAttachments.push(BUCKET_URL + `${data.data[0].fields.Key}`);
      }
    }

    if (demoFiles.length > 0) {
      for (const file of demoFiles) {
        const data = await axios.get(
          `${S3_PREASSIGNED_POST_URL}?size=${
            globalSettings?.max_file_size
          }&fileExtension=${file.name.split(".").pop()}`
        );

        const formData = new FormData();
        Object.entries(data.data[0].fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        const uploadResponse = await axios.post(
          "https://somadha.s3.amazonaws.com",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        uploadAttachmentOverview.push(
          BUCKET_URL + `${data.data[0].fields.Key}`
        );
      }
    }

    try {
      console.log("lol");
      const res = await axios.post(
        "https://somadha.com/api/provider/submit_job",
        {
          attachments_overview: [...uploadAttachmentOverview],
          attachments: [...uploadAttachments],
          deal_id: deal_id,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      toast.success("Submitted Successfully!");
      setOpen(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.msg ||
          err?.message ||
          `Max file upload size is ${globalSettings?.max_file_size}MB`
      );
    }
  };

  return (
    <Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })}

      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        title="Confirm Submission"
        style={{ height: "350px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p>
            You haven't included deliverables properly. Are you sure you want to
            submit the job?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="primary"
              type="submit"
              style={{ padding: ".5rem 3rem", margin: "1rem auto" }}
              onClick={() => {
                handleSubmitJob();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={open} onClose={setOpen} title="Submit Job">
        <div className="makePaymentForDueAmountModal">
          <div className="confirmPayment__form">
            {globalSettings?.job_submit_prompt && (
              <div>
                {JSON.parse(globalSettings.job_submit_prompt).blocks.map(
                  (block, index) => (
                    <div
                      key={index}
                      className="formatted-content"
                      dangerouslySetInnerHTML={{ __html: block.data.text }}
                    />
                  )
                )}
              </div>
            )}

            {/*<p>
              {delivery && (
                <p>
                  "Please be reminded that the job requires you to send hard
                  copies via postage. If you don’t post them in a timely manner,
                  there will be a penalty." <br /> <br />
                </p>
              )}

              <p>
                Please complete your{" "}
                <strong className="text-primary">
                  {watch("payment_method")}
                </strong>{" "}
                job information to submit your work
              </p>
              </p>*/}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div class="form-group">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2" style={{ zIndex: "10000" }}>
                      Acceptable attachments are png, jpeg, jpg, css, cvs, ico,
                      pdf,otf,rtf,txt,tif,tiff,ttf,webp,woff,woff2,xml,epub,psd,ai,dwg,doc,docx,ppt,pptx,xls,xlsx,svg,azw
                    </Tooltip>
                  }
                >
                  <label for="attachments">Deliverable</label>
                </OverlayTrigger>

                <FileUploader
                  updatestate={(childFile) => {
                    setFiles(childFile);
                  }}
                />
                {files.length !== 0 && <p>{files.length} file/s chosen</p>}
              </div>
              <div class="form-group" style={{ marginBottom: "10px" }}>
                <label for="attachments_overview">Demo Deliverable</label>
                <FileUploader
                  updatestate={(childFile) => {
                    setDemoFiles(childFile);
                  }}
                />
                {demoFiles.length !== 0 && (
                  <p>{demoFiles.length} file/s chosen</p>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="primary"
                type="submit"
                style={{ padding: ".5rem 3rem", margin: "1rem auto" }}
                onClick={() =>
                  files.length === 0 || demoFiles.length === 0
                    ? setConfirmModal(true)
                    : handleSubmitJob()
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}
