import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ViewDuePaymentDetailsModal from "../../../components/modals/ViewDuePaymentDetailsModal/ViewDuePaymentDetailsModal";
import {
  ADMIN_APPROVE_DEAL,
  ADMIN_INIT_DEAL,
  GET_CLIENT_PAYMENTS,
} from "../../../lib/api/admin";
import { deleteConfirmation } from "../../../lib/sweetAlert";

export default function ClientPayments() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    createdAt: -1,
  });
  const [search, setSearch] = useState(null);
  const {
    data: clientPayments,
    status,
    error,
    refetch,
  } = useQuery(
    [
      GET_CLIENT_PAYMENTS.name,
      {
        page,
        sort: JSON.stringify(sort),
        ...(search ? { search } : {}),
      },
    ],
    GET_CLIENT_PAYMENTS
  );
  const [viewDetailsState, setViewDetailsState] = useState(null);
  const { mutateAsync: adminInitDeal } = useMutation(ADMIN_INIT_DEAL);
  const { mutateAsync: adminApproveDeal } = useMutation(ADMIN_APPROVE_DEAL);
  const [show, setShow] = useState(false);
  const [refundData, setRefundData] = useState();
  const [amount, setAmount] = useState(0);

  const initiateDeal = useCallback(
    async (id) => {
      try {
        const { isConfirmed } = await deleteConfirmation();
        if (!isConfirmed) return;
        await adminInitDeal({
          id,
        });
        await refetch();
        toast.success("Deal initiated successfully");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [adminInitDeal, refetch]
  );

  const handleRefund = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/admin/bkash-refund", // Replace with your actual API endpoint
        {
          amount: amount,
          paymentId: refundData._id,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
	  toast.success("Refund Successful");
      setShow(false);
      //   console.log(res.data);
    } catch (err) {
      setShow(false);
	  toast.error(err?.response?.data?.msg || err?.message);
      console.error("Error during refund:", error);
    }
  };

  const handleModal = (data) => {
    setShow(true);
    setRefundData(data);
    console.log(data);
  };

  const approveDeal = useCallback(
    async (id) => {
      try {
        const { isConfirmed } = await deleteConfirmation();
        if (!isConfirmed) return;
        await adminApproveDeal({
          id,
        });
        await refetch();
        toast.success("Deal Approved successfully");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [adminApproveDeal, refetch]
  );

  return (
    <AdminLayout>
      {/* Modals */}
      <ViewDuePaymentDetailsModal
        open={!!viewDetailsState}
        onClose={() => setViewDetailsState(null)}
        title="Due Payment Details"
        data={viewDetailsState}
      />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="refundAmount">Amount:</label>
          <input
            type="number"
            id="refundAmount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter refund amount"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleRefund()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <AdminTableView
        title="Client Payments"
        query={{ status, error }}
        search={{
          title: "Search Payments",
          placeholder: "Search by transaction id...",
          btnText: "Search",
          onSearch: (value) => setSearch(value),
        }}
        sort={{
          title: "Sort By",
          width: "250px",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Created At (DESC)", value: "createdAt:-1" },
                { label: "Created At (ASC)", value: "createdAt:1" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: +value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Transaction ID", dotsMenu: [], key: "transaction_id" },
            { name: "Job Title", dotsMenu: [], key: "job_title" },
            { name: "Provider Phone", dotsMenu: [], key: "mobile_no" },
            { name: "Method Type", dotsMenu: [], key: "method_type" },
            { name: "Amount", dotsMenu: [], key: "amount" },
            { name: "Status", dotsMenu: [], key: "state" },
            {
              name: "Actions",
              dotsMenu: [
                {
                  name: "Initiate Deal",
                  onClick: (data) => initiateDeal(data._id),
                  show: "type:INITIATE",
                },
                {
                  name: "Approve Deal",
                  onClick: (data) => approveDeal(data._id),
                  show: "type:APPROVE",
                },
                {
                  name: "Refund",
                  onClick: (data) => handleModal(data),
                },
              ],
              key: "actions",
            },
          ],
          data: clientPayments?.data?.data.map((item) => ({
            _id: item._id,
            transaction_id: item.transaction_id,
            job_title: item.job_id?.title || "N/A",
            mobile_no: item.mobile_no,
            method_type: item.method?.name || "N/A",
            state: item.state,
            type: item.type,
            amount: item.amount || "N/A",
            actions: "",
          })),
          pagination: { total: clientPayments?.data?.total, page, setPage },
        }}
      />
    </AdminLayout>
  );
}
