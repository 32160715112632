import React, { useEffect, useState, useContext } from 'react';
import Colors from '../../util/Colors';
import './UiConfig.css';
import ColorPicker from '../../components/util/ColorPicker';
import { useHttpClient } from '../../hooks/http-hook';
import ModalButton from '../../components/button/ModalButton';
import { AuthContext } from '../../context/auth-context';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import ErrorModal from '../../components/util/ErrorModal';
import ImageUploader from '../../components/util/ImageUploader';
import Banner1 from '../../util/Banner1';
import Banner2 from '../../util/Banner2';
import Banner3 from '../../util/Banner3';
import SuccessModal from '../../components/util/SuccessModal';
const enFonts = [
  'Roboto',
  'Ubuntu',
  'Parisienne',
  'Open Sans',
  'Nunito',
  'Lato',
];
const bnFonts = ['Hind Siliguri', 'Mini'];
const UiConfig = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  //states
  const [colorState, setColorState] = useState(Colors);
  const [banner1, setBanner1] = useState(Banner1);
  const [banner2, setBanner2] = useState(Banner2);
  const [banner3, setBanner3] = useState(Banner3);
  const [showSuccess, setShowSuccess] = useState(false);
  const [globalEnFont, setGlobalEnFont] = useState('Roboto');
  const [globalBnFont, setGlobalBnFont] = useState('Hind Siliguri');
  const colorNames = Object.keys(Colors);
  //initialize
  useEffect(() => {
    if (auth.uiConfig) {
      if (auth.uiConfig.color_theme) setColorState(auth.uiConfig.color_theme);
      if (auth.uiConfig.global_font_bn) {
        setGlobalBnFont(auth.uiConfig.global_font_bn);
      }
      if (auth.uiConfig.global_font_en) {
        setGlobalEnFont(auth.uiConfig.global_font_en);
      }
      if (auth.uiConfig.homepage_search_job_banner_header_en) {
        let bannerFields = Object.keys(banner1);
        let bannerToSave = Banner1;
        bannerFields.forEach((item) => {
          bannerToSave[item] = auth.uiConfig[item];
        });
        setBanner1(bannerToSave);
      }
      if (auth.uiConfig.homepage_post_job_banner_header_en) {
        let bannerFields = Object.keys(banner2);
        let bannerToSave = Banner2;
        bannerFields.forEach((item) => {
          bannerToSave[item] = auth.uiConfig[item];
        });
        setBanner2(bannerToSave);
      }
      if (auth.uiConfig.homepage_search_job_banner_header_en) {
        let bannerFields = Object.keys(banner3);
        let bannerToSave = Banner3;
        bannerFields.forEach((item) => {
          bannerToSave[item] = auth.uiConfig[item];
        });
        setBanner3(bannerToSave);
      }
    }
    //eslint-disable-next-line
  }, [auth.uiConfig]);
  //requests
  const saveConfig = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/ui-config',
        'POST',
        JSON.stringify({
          color_theme: colorState,
          global_font_en: globalEnFont,
          global_font_bn: globalBnFont,
          homepage_search_job_banner_header_en:
            banner1.homepage_search_job_banner_header_en,
          homepage_search_job_banner_header_font_en:
            banner1.homepage_search_job_banner_header_font_en,
          homepage_search_job_banner_desc_en:
            banner1.homepage_search_job_banner_desc_en,
          homepage_search_job_banner_desc_font_en:
            banner1.homepage_search_job_banner_desc_font_en,
          homepage_search_job_banner_header_bn:
            banner1.homepage_search_job_banner_header_bn,
          homepage_search_job_banner_header_font_bn:
            banner1.homepage_search_job_banner_header_font_bn,
          homepage_search_job_banner_desc_bn:
            banner1.homepage_search_job_banner_desc_bn,
          homepage_search_job_banner_desc_font_bn:
            banner1.homepage_search_job_banner_desc_font_bn,

          homepage_post_job_banner_header_en:
            banner2.homepage_post_job_banner_header_en,
          homepage_post_job_banner_header_font_en:
            banner2.homepage_post_job_banner_header_font_en,
          homepage_post_job_banner_desc_en:
            banner2.homepage_post_job_banner_desc_en,
          homepage_post_job_banner_desc_font_en:
            banner2.homepage_post_job_banner_desc_font_en,
          homepage_post_job_banner_header_bn:
            banner2.homepage_post_job_banner_header_bn,
          homepage_post_job_banner_header_font_bn:
            banner2.homepage_post_job_banner_header_font_bn,
          homepage_post_job_banner_desc_bn:
            banner2.homepage_post_job_banner_desc_bn,
          homepage_post_job_banner_desc_font_bn:
            banner2.homepage_post_job_banner_desc_font_bn,

          jobpostpage_banner_header_en: banner3.jobpostpage_banner_header_en,
          jobpostpage_banner_header_font_en:
            banner3.jobpostpage_banner_header_font_en,
          jobpostpage_banner_desc_en: banner3.jobpostpage_banner_desc_en,
          jobpostpage_banner_desc_font_en:
            banner3.jobpostpage_banner_desc_font_en,
          jobpostpage_banner_header_bn: banner3.jobpostpage_banner_header_bn,
          jobpostpage_banner_header_font_bn:
            banner3.jobpostpage_banner_header_font_bn,
          jobpostpage_banner_desc_bn: banner3.jobpostpage_banner_desc_bn,
          jobpostpage_banner_desc_font_bn:
            banner3.jobpostpage_banner_desc_font_bn,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData._id) {
        setShowSuccess(true);
      }
    } catch (err) {}
  };
  const handleInputChangeBanner1 = (e) => {
    const { name, value } = e.target;
    setBanner1({
      ...banner1,
      [name]: value,
    });
  };
  const handleInputChangeBanner2 = (e) => {
    const { name, value } = e.target;
    setBanner2({
      ...banner2,
      [name]: value,
    });
  };
  const handleInputChangeBanner3 = (e) => {
    const { name, value } = e.target;
    setBanner3({
      ...banner3,
      [name]: value,
    });
  };
  return (
    <div className='config__container'>
      <SuccessModal
        message='Configurations Updated Successfully'
        show={showSuccess}
        onClear={() => {
          setShowSuccess(false);
        }}
      />

      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError} />
      <h3>UI Configuration</h3>
      <div className='config__box'>
        <p className='divider__header'>Select UI Colors</p>
        <hr className='divider__config' />
        <div className='color__inputs'>
          {colorState &&
            colorNames.map((color) => {
              return (
                <ColorPicker
                  name={color}
                  colorState={colorState}
                  setColorState={setColorState}
                />
              );
            })}
        </div>
        <p className='divider__header'>Select Preferred Global Fonts</p>
        <hr className='divider__config' />
        <div className='language__inputs'>
          <div className='language__inpg'>
            <p>English</p>
            <select
              value={globalEnFont}
              onChange={(e) => {
                setGlobalEnFont(e.target.value);
              }}
            >
              {enFonts.map((font) => {
                return <option value={font}>{font}</option>;
              })}
            </select>
          </div>
          <div className='language__inpg'>
            <p>বাংলা</p>
            <select
              value={globalBnFont}
              onChange={(e) => {
                setGlobalBnFont(e.target.value);
              }}
            >
              {bnFonts.map((font) => {
                return <option value={font}>{font}</option>;
              })}
            </select>
          </div>
        </div>
        <p className='divider__header'>Select Logo</p>
        <hr className='divider__config' />
        <ImageUploader
          url='/ui-config'
          title='Upload New Logo'
          fieldName='logo'
        />

        <p className='divider__header'>Select Authentication Backgrounds</p>
        <hr className='divider__config' />
        <div className='multiple__uploader'>
          <ImageUploader
            url='/ui-config'
            title='Upload Signup Background'
            fieldName='signup_background_image'
          />
          <ImageUploader
            url='/ui-config'
            title='Upload Login Background'
            fieldName='login_background_image'
          />
        </div>
        {Banner1 && (
          <div>
            <p className='divider__header'>Home Page Explore Jobs Banner</p>
            <hr className='divider__config' />
            <p className='label'>Header</p>
            <div className='banner__section'>
              <div className='banner__inpg'>
                <p>English</p>
                <input
                  className='banner__input'
                  type='text'
                  value={banner1.homepage_search_job_banner_header_en}
                  name='homepage_search_job_banner_header_en'
                  onChange={handleInputChangeBanner1}
                />
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='bangla'
                  value={banner1.homepage_search_job_banner_header_bn}
                  name='homepage_search_job_banner_header_bn'
                  onChange={handleInputChangeBanner1}
                />
              </div>
              <p className='label'>Header font</p>
              <div className='banner__inpg'>
                <p>English</p>
                <select
                  value={banner1.homepage_search_job_banner_header_font_en}
                  name='homepage_search_job_banner_header_font_en'
                  onChange={handleInputChangeBanner1}
                >
                  {enFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <select
                  value={banner1.homepage_search_job_banner_header_font_bn}
                  name='homepage_search_job_banner_header_font_bn'
                  onChange={handleInputChangeBanner1}
                >
                  {bnFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className='banner__section'>
              <p className='label'>description</p>
              <div className='banner__inpg'>
                <p>English</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='english'
                  name='homepage_search_job_banner_desc_en'
                  value={banner1.homepage_search_job_banner_desc_en}
                  onChange={handleInputChangeBanner1}
                />
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='bangla'
                  value={banner1.homepage_search_job_banner_desc_bn}
                  name='homepage_search_job_banner_desc_bn'
                  onChange={handleInputChangeBanner1}
                />
              </div>
              <p className='label'>Description font</p>
              <div className='banner__inpg'>
                <p>English</p>
                <select
                  value={banner1.homepage_search_job_banner_desc_font_en}
                  name='homepage_search_job_banner_desc_font_en'
                  onChange={handleInputChangeBanner1}
                >
                  {enFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <select
                  value={banner1.homepage_search_job_banner_desc_font_bn}
                  name='homepage_search_job_banner_desc_font_bn'
                  onChange={handleInputChangeBanner1}
                >
                  {bnFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
            </div>
            <p>Banner Image</p>
            <ImageUploader
              url='/ui-config'
              title='Upload Banner Background'
              fieldName='homepage_search_job_banner'
            />
          </div>
        )}

        {Banner2 && (
          <div>
            <p className='divider__header'>Home Page Post Work Banner</p>
            <hr className='divider__config' />
            <p className='label'>Header</p>
            <div className='banner__section'>
              <div className='banner__inpg'>
                <p>English</p>
                <input
                  className='banner__input'
                  type='text'
                  value={banner2.homepage_post_job_banner_header_en}
                  name='homepage_post_job_banner_header_en'
                  onChange={handleInputChangeBanner2}
                />
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='bangla'
                  value={banner2.homepage_post_job_banner_header_bn}
                  name='homepage_post_job_banner_header_bn'
                  onChange={handleInputChangeBanner2}
                />
              </div>
              <p className='label'>Header font</p>
              <div className='banner__inpg'>
                <p>English</p>
                <select
                  value={banner2.homepage_post_job_banner_header_font_en}
                  name='homepage_post_job_banner_header_font_en'
                  onChange={handleInputChangeBanner2}
                >
                  {enFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <select
                  value={banner2.homepage_post_job_banner_header_font_bn}
                  name='homepage_post_job_banner_header_font_bn'
                  onChange={handleInputChangeBanner2}
                >
                  {bnFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className='banner__section'>
              <p className='label'>description</p>
              <div className='banner__inpg'>
                <p>English</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='english'
                  name='homepage_post_job_banner_desc_en'
                  value={banner2.homepage_post_job_banner_desc_en}
                  onChange={handleInputChangeBanner2}
                />
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='bangla'
                  value={banner2.homepage_post_job_banner_desc_bn}
                  name='homepage_post_job_banner_desc_bn'
                  onChange={handleInputChangeBanner2}
                />
              </div>
              <p className='label'>Description font</p>
              <div className='banner__inpg'>
                <p>English</p>
                <select
                  value={banner2.homepage_post_job_banner_desc_font_en}
                  name='homepage_post_job_banner_desc_font_en'
                  onChange={handleInputChangeBanner2}
                >
                  {enFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <select
                  value={banner2.homepage_post_job_banner_desc_font_bn}
                  name='homepage_post_job_banner_desc_font_bn'
                  onChange={handleInputChangeBanner2}
                >
                  {bnFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
            </div>
            <p>Banner Image</p>
            <ImageUploader
              url='/ui-config'
              title='Upload Banner Background'
              fieldName='homepage_post_job_banner'
            />
          </div>
        )}

        {Banner3 && (
          <div>
            <p className='divider__header'>Explore Jobs Top Banner</p>
            <hr className='divider__config' />
            <p className='label'>Header</p>
            <div className='banner__section'>
              <div className='banner__inpg'>
                <p>English</p>
                <input
                  className='banner__input'
                  type='text'
                  value={banner3.jobpostpage_banner_header_en}
                  name='jobpostpage_banner_header_en'
                  onChange={handleInputChangeBanner3}
                />
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='bangla'
                  value={banner3.jobpostpage_banner_header_bn}
                  name='jobpostpage_banner_header_bn'
                  onChange={handleInputChangeBanner3}
                />
              </div>
              <p className='label'>Header font</p>
              <div className='banner__inpg'>
                <p>English</p>
                <select
                  value={banner3.jobpostpage_banner_header_font_en}
                  name='jobpostpage_banner_header_font_en'
                  onChange={handleInputChangeBanner3}
                >
                  {enFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <select
                  value={banner3.jobpostpage_banner_header_font_bn}
                  name='jobpostpage_banner_header_font_bn'
                  onChange={handleInputChangeBanner3}
                >
                  {bnFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className='banner__section'>
              <p className='label'>description</p>
              <div className='banner__inpg'>
                <p>English</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='english'
                  name='jobpostpage_banner_desc_en'
                  value={banner3.jobpostpage_banner_desc_en}
                  onChange={handleInputChangeBanner3}
                />
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <input
                  className='banner__input'
                  type='text'
                  placeholder='bangla'
                  value={banner3.jobpostpage_banner_desc_bn}
                  name='jobpostpage_banner_desc_bn'
                  onChange={handleInputChangeBanner3}
                />
              </div>
              <p className='label'>Description font</p>
              <div className='banner__inpg'>
                <p>English</p>
                <select
                  value={banner3.jobpostpage_banner_desc_font_en}
                  name='jobpostpage_banner_desc_font_en'
                  onChange={handleInputChangeBanner3}
                >
                  {enFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
              <div className='banner__inpg'>
                <p>Bangla</p>
                <select
                  value={banner3.jobpostpage_banner_desc_font_bn}
                  name='jobpostpage_banner_desc_font_bn'
                  onChange={handleInputChangeBanner3}
                >
                  {bnFonts.map((font) => {
                    return <option value={font}>{font}</option>;
                  })}
                </select>
              </div>
            </div>
            <p>Banner Image</p>
            <ImageUploader
              url='/ui-config'
              title='Upload Banner Background'
              fieldName='jobpostpage_banner'
            />
          </div>
        )}
        <div className='savebtn__container'>
          <ModalButton onClick={saveConfig}>Save</ModalButton>
        </div>
      </div>
    </div>
  );
};

export default UiConfig;
