import React, { useEffect } from "react";
export const NotFound = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "80vw",
        maxWidth: "1400px",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <img
        style={{ height: "auto", width: "50%", marginTop: "10vh" }}
        src="/assets/404.jpg"
        alt=""
      />{" "}
      <p
        style={{
          fontSize: "2rem",
          color: "#aaa",
          fontWeight: "300",
          marginBottom: "600px",
        }}
      >
        The page you requested was not found.
      </p>
    </div>
  );
};
export default NotFound;
