import React from 'react';
import FileSaver from 'file-saver';
import './EditExpertise.css';
import Modal from '../../util/Modal';
import ModalButton from '../../button/ModalButton';
import { useHttpClient } from '../../../hooks/http-hook';
import ErrorModal from '../../util/ErrorModal';
import LoadingSpinner from '../../util/LoadingSpinner';

const ShowFiles = ({
  onCancel,
  onSuccess,
  show,
  providerDeal,
  file,
  dealId,
  clientApproval,
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const saveFile = (url) => {
    FileSaver.saveAs(process.env.REACT_APP_BACKEND_URL + '/' + url, url);
  };

  const makePayment = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/client/approve_deal/?deal_id=${dealId}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.ssl_url) {
        onCancel();
        window
          .open(
            responseData.ssl_url,
            '',
            ` scrollbars=yes,menubar=no,height=680 ,width=520, left=${
              window.screen.width / 2 - 260
            } top=${
              window.screen.height / 2 - 340
            }resizable=yes,toolbar=no,location=no,status=no"`
          )
          .focus();
      }
      if (!responseData.ssl_url) {
        onSuccess();
        onCancel();
      }
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={onCancel} show={show}>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='previewfiles__container'>
        <img
          className='exit_edit'
          onClick={onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2 className='submit_header'>
          {providerDeal ? 'Work Submitted By Provider' : 'Submitted Work'}
        </h2>
        <div className='emp_p'>
          <p>Preview Work</p>

          <table>
            {file &&
              file.attachments_overview &&
              file.attachments_overview.map((attachment) => {
                return (
                  <tr>
                    <td key={attachment}>
                      <a
                        target='_new'
                        href={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                      >
                        <p className='p6'>{attachment.substr(6)}</p>
                      </a>
                    </td>
                    <td>
                      <img
                        className='download__icon'
                        onClick={() => {
                          saveFile(attachment);
                        }}
                        src='/assets/download.svg'
                        alt=''
                      />
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
        <div className='emp_p'>
          {file && file.attachments && <p>Complete Work</p>}
          <table>
            {file &&
              file.attachments &&
              file.attachments.map((attachment) => {
                return (
                  <tr>
                    <td key={attachment}>
                      <a
                        target='_new'
                        href={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                      >
                        <p className='p6'>{attachment.substr(6)}</p>
                      </a>
                    </td>
                    <td>
                      <img
                        className='download__icon'
                        onClick={() => {
                          saveFile(attachment);
                        }}
                        src='/assets/download.svg'
                        alt=''
                      />
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
        {file && !file.attachments && !clientApproval && (
          <div>
            <p className='make__p'>
              We are showing you sample of work the provider has done. You need
              to approve the deal to get all the files.
            </p>
            <div className='work__submitbtn'>
              <ModalButton onClick={makePayment}>Approve Deal</ModalButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ShowFiles;
