import React, { Fragment, useContext, useState } from 'react';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import ReportIssue from '../modals/Profile/ReportIssue';
import ShowFiles from '../modals/Profile/ShowFiles';
import SubmitWork from '../modals/Profile/SubmitWork';
import LoadingSpinner from '../util/LoadingSpinner';
import './DealCard.css';
import JobCard from './JobCard';
import UserCard from './UserCard';
import UserCardTwo from './UserCardTwo';

const DealCard = (props) => {
  const auth = useContext(AuthContext);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showIssueSubmitModal, setShowIssueSubmitModal] = useState(false);
  const [files, setFiles] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const { isLoading, sendRequest } = useHttpClient();
  const [statusForClient, setStatusForClient] = useState('');
  const [statusForService, setStatusForService] = useState('');
  const [statusForPrint, setStatusForPrint] = useState('');
  const [statusCloseJob, setStatusForCloseJob] = useState('');
  const [statusForCloseDeal, setStatusForCloseDeal] = useState('');
  const [refundClientProvider, setRefundClientProvider] = useState(0);
  const [fineClientProvider, setFineClientProvider] = useState(0);
  const [payServiceProvider, setPayServiceProvider] = useState(0);
  const [fineServiceProvider, setFineServiceProvider] = useState(0);
  const [payPrintProvider, setPayPrintProvider] = useState(0);
  const [finePrintProvider, setFinePrintProvider] = useState(0);

  const seeDeal =
    statusForClient === 'doNothing' &&
    statusForService === 'doNothing' &&
    statusForPrint === 'doNothing';
  const onCancel = () => {
    setShowSubmitModal(false);
    setShowIssueSubmitModal(false);
  };
  const initiateDeal = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/client/initiate_deal/?bid_id=${props.bidId}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.ssl_url) {
        window
          .open(
            responseData.ssl_url,
            '',
            ` scrollbars=yes,menubar=no,height=680 ,width=520, left=${
              window.screen.width / 2 - 260
            } top=${
              window.screen.height / 2 - 340
            }resizable=yes,toolbar=no,location=no,status=no"`
          )
          .focus();
      }
    } catch (err) {}
  };

  const handleRefundClient = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/admin/add_client_to_refund?deal_id=${props.dealId}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
      }
    } catch (err) {}
  };
  const handleFineClient = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/admin/fine_user`,
        'POST',
        JSON.stringify({
          user_id: props.clientId,
          fine_amount: fineClientProvider,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setFineClientProvider('');
      }
    } catch (err) {}
  };
  const handlePayServiceProvider = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/admin/assign_provider_due`,
        'POST',
        JSON.stringify({
          deal_id: props.dealId,
          amount: payServiceProvider,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setPayServiceProvider('');
      }
    } catch (err) {}
  };
  const handlePayPrintProvider = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/admin/assign_provider_due`,
        'POST',
        JSON.stringify({
          deal_id: props.dealId,
          amount: payPrintProvider,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setPayPrintProvider('');
      }
    } catch (err) {}
  };
  const handleFineServiceProvider = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/admin/fine_user`,
        'POST',
        JSON.stringify({
          user_id: props.providerId,
          fine_amount: fineServiceProvider,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setFineServiceProvider('');
      }
    } catch (err) {}
  };
  const handleFinePrintProvider = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/admin/fine_user`,
        'POST',
        JSON.stringify({
          user_id: props.providerId,
          fine_amount: finePrintProvider,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setFinePrintProvider('');
      }
    } catch (err) {}
  };
  const handleRePost = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/admin/allow_repost?deal_id=${props.dealId}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
      }
    } catch (err) {}
  };
  const handleReInitiateDeal = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/admin/reinitiate_deal?deal_id=${props.dealId}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
      }
    } catch (err) {}
  };
  const rePost = () => (statusCloseJob === 'allowRePost' ? handleRePost() : '');
  const reInitiate = () =>
    statusForCloseDeal === 'reInitiateDeal' ? handleReInitiateDeal() : '';
  const handleAction = (e) => {
    e.preventDefault();
    reInitiate();
    rePost();
    handleRefundClient();
    handleFineClient();
    handlePayServiceProvider();
    handlePayPrintProvider();
    handleFineServiceProvider();
    handleFinePrintProvider();
  };

  const fetchFiles = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/${
            props.providerDeal ? 'client' : 'provider'
          }/get_submitted_job/?deal_id=${props.dealId}`
      );
      setFiles(responseData);
    } catch (err) {}
  };
  const viewFileHandler = () => {
    fetchFiles();
    setShowPreview(true);
  };
  return (
    <div className='dealcard__container'>
      <SubmitWork
        show={showSubmitModal}
        onCancel={onCancel}
        dealId={props.dealId}
        onSuccess={props.onSuccess}
        submitted={props.submitted}
      />
      <ShowFiles
        file={files}
        show={showPreview}
        clientApproval={props.clientApproval}
        dealId={props.dealId}
        onCancel={() => {
          setShowPreview(false);
        }}
        onSuccess={() => {
          setShowPreview(false);
          props.onSuccess();
        }}
        providerDeal={props.providerDeal}
      />
      <ReportIssue
        show={showIssueSubmitModal}
        onCancel={onCancel}
        deal_id={props.dealId}
      />

      {isLoading && <LoadingSpinner asOverlay />}
      <div className='dealcard__header'>
        <div className='dealcard__header__left'>
          <UserCard
            rate={props.rate && props.status !== 'ACCEPTED'}
            name={props.providerDeal ? props.providerName : props.clientName}
            rating={
              props.providerDeal ? props.providerRating : props.clientRating
            }
            id={props.providerDeal ? props.providerId : props.clientId}
            clientId={props.clientId}
            providerId={props.providerId}
            detailed={props.providerDeal ? true : false}
            picture={`${process.env.REACT_APP_BACKEND_URL}/${
              props.providerDeal
                ? props.providerProfilePic
                : props.clientProfilePic
            }`}
            provider={props.providerDeal ? true : false}
            bidDate={props.bidDate}
            claimedBudget={props.claimedBudget}
            comment={props.comment}
            dealId={props.dealId}
          />
          {props.dealpage && (
            <UserCard
              rate={props.rate && !props.status === 'ACCEPTED'}
              provider
              clientId={props.clientId}
              providerId={props.providerId}
              id={props.providerId}
              name={props.providerName}
              rating={props.providerRating}
              picture={`${process.env.REACT_APP_BACKEND_URL}/${props.providerProfilePic}`}
              dealId={props.dealId}
            />
          )}
          {props.clientDetailsWith && (
            <UserCardTwo
              dealsDetailsClients
              id={props.jobId}
              title={props.title}
              category={props.category}
              subCategory={props.subCategory}
              budget={props.budget}
              postDate={props.jobPostDate}
              deadline={props.deadline}
              status={props.status}
              providerId={props.providerId}
              bidDate={props.bidDate}
              job={props.job}
            />
          )}
        </div>
        <div className='dealcard__header__right'>
          <div>
            {/* <Tooltip
              width="230px"
              tip={
                props.status === "REFUNDED"
                  ? "refunded"
                  : props.status === "FAILED"
                  ? "Deal Failed"
                  : props.status === "COMPLETE"
                  ? "Deal completed Successfully"
                  : props.status === "ACCEPTED"
                  ? "Provider accepted your deal. Click on the initiate deal button to make payment and initiate the deal."
                  : props.providerDeal
                  ? props.submitted
                    ? "Provider has submitted his work"
                    : "Wait for provider to finish the work"
                  : props.submitted
                  ? "Waiting for Client to approve your work"
                  : "Submit your work before deadline"
              }
            >
              <div className={`status ${props.status}`}>
                {props.status === "REFUNDED"
                  ? "REFUNDED"
                  : props.status === "FAILED"
                  ? "FAILED"
                  : props.submitted
                  ? props.status === "COMPLETE"
                    ? "COMPLETE"
                    : "SUBMITTED"
                  : props.status}
              </div>
            </Tooltip> */}
            {/* {!auth.isManager && !auth.isAdmin && props.reportable && (
              <p
                onClick={() => {
                  setShowIssueSubmitModal(true);
                }}
                className="issue__p"
              >
                Report an Issue
              </p>
            )} */}
            {/* {(auth.isManager || auth.isAdmin) &&
              props.status !== "FAILED" &&
              props.status !== "REFUNDED" &&
              props.status !== "COMPLETE" && (
                <p onClick={markAsFailed} className="issue__p">
                  Mark Deal as Failed
                </p>
              )} */}
            {/* {(auth.isManager || auth.isAdmin) && props.status === "FAILED" && (
              <p onClick={Refund} className="issue__p">
                Refund
              </p>
            )} */}
            {/* <p>Deadline: {props.deadline}</p> */}
            {/* {props.providerDeal &&
              props.status !== "ONGOING" &&
              props.status !== "REFUNDED" &&
              props.status !== "COMPLETE" &&
              props.status !== "FAILED" && (
                <div className="file__btns">
                  <button onClick={initiateDeal} className="View_files_btn">
                    Initiate Deal
                  </button>
                </div>
              )} */}
            {/* {!auth.isManager &&
              !auth.isAdmin &&
              (props.status === "ONGOING" || props.status === "COMPLETE") && (
                <div className="file__btns">
                  {!props.providerDeal && (
                    <button
                      onClick={() => {
                        setShowSubmitModal(true);
                      }}
                      className="submit_work_btn"
                    >
                      {props.submitted ? "Resubmit" : "Submit your Work"}
                    </button>
                  )}
                  {props.submitted && (
                    <button
                      onClick={viewFileHandler}
                      className="View_files_btn"
                    >
                      View Files
                    </button>
                  )}
                </div>
              )} */}
          </div>
          {props.providerDeal && (
            <JobCard
              dealsDetails
              dealDetails={props.dealDetails}
              providerNow
              tiny
              id={props.jobId}
              dealId={props.dealId}
              title={props.title}
              category={props.category}
              subCategory={props.subCategory}
              budget={props.budget}
              postDate={props.jobPostDate}
              status={props.status}
              providerId={props.providerId}
            />
          )}
          {!props.providerDeal && (
            <JobCard
              dealsDetailsClients
              clientNow
              tiny
              dealDetails={props.dealDetails}
              dealId={props.dealId}
              _id={props._id}
              id={props.jobId}
              title={props.title}
              category={props.category}
              subCategory={props.subCategory}
              budget={props.budget}
              postDate={props.jobPostDate}
              deadline={props.deadline}
              status={props.status}
              providerId={props.providerId}
              bidDate={props.bidDate}
              job={props.job}
            />
          )}
        </div>
      </div>

      {/* {!props.providerDeal && (
        <div className="dealcard__footer">
          <div className="dealcard__footer__left">
            <JobCard
              tiny
              id={props.jobId}
              title={props.title}
              category={props.category}
              subCategory={props.subCategory}
              budget={props.budget}
              postDate={props.jobPostDate}
            />
          </div>

          <div className="dealcard__footer__right">
            <p>Bid Date: {props.bidDate}</p>
            <p>
              Claimed Budget:{" "}
              <span className="green">{props.claimedBudget} BDT</span>
            </p>
            {props.comment && (
              <p>
                {auth.isManager || auth.isAdmin
                  ? "Providers Message"
                  : "Comment"}
                : <span className="comment">{props.comment}</span>
              </p>
            )}
          </div>
        </div>
      )} */}

      {props.status === 'FAILED' && (auth.isManager || auth.isAdmin) && (
        <div className='bid--action_wrapper'>
          <div className='action-take'>
            <h4 className='heading-action'>Take Action</h4>
            <div className='tab-wrapper'>
              <h5 className='tab-head'>For The Client</h5>
              <div className='btn-wrapper'>
                <button
                  className={`${
                    statusForClient === 'doNothing'
                      ? 'btn-tab active-tab'
                      : 'btn-tab'
                  }`}
                  onClick={() => setStatusForClient('doNothing')}
                >
                  Do Nothing
                </button>
                <button
                  className={`${
                    statusForClient === 'refundClient'
                      ? 'btn-tab active-tab'
                      : 'btn-tab'
                  }`}
                  onClick={() => setStatusForClient('refundClient')}
                >
                  Refund Client
                </button>
                <button
                  className={`${
                    statusForClient === 'fineClient'
                      ? 'btn-tab active-tab'
                      : 'btn-tab'
                  }`}
                  onClick={() => setStatusForClient('fineClient')}
                >
                  Fine Client
                </button>
              </div>
              {statusForClient === '' && (
                <p style={{ color: '#FF0000' }}>You Must Choose an Option</p>
              )}

              {statusForClient === 'fineClient' && (
                <Fragment>
                  <label htmlFor=''>Please Specify PAYABLE Amount</label>
                  <input
                    type='text'
                    value={fineClientProvider}
                    onChange={(e) => setFineClientProvider(e.target.value)}
                  />
                </Fragment>
              )}
            </div>
            <div className='tab-wrapper'>
              <h5 className='tab-head'>For Service The Provider</h5>
              <div className='btn-wrapper'>
                <button
                  className={`${
                    statusForService === 'doNothing'
                      ? 'btn-tab active-tab'
                      : 'btn-tab'
                  }`}
                  onClick={() => setStatusForService('doNothing')}
                >
                  Do Nothing
                </button>
                <button
                  className={`${
                    statusForService === 'payProvider'
                      ? 'btn-tab active-tab'
                      : 'btn-tab'
                  }`}
                  onClick={() => setStatusForService('payProvider')}
                >
                  Pay Provider
                </button>
                <button
                  className={`${
                    statusForService === 'FineProvider'
                      ? 'btn-tab active-tab'
                      : 'btn-tab'
                  }`}
                  onClick={() => setStatusForService('FineProvider')}
                >
                  Fine Provider
                </button>
              </div>
              {statusForService === 'payProvider' && (
                <Fragment>
                  <label htmlFor=''>Please Specify PAYABLE Amount</label>
                  <input
                    type='text'
                    value={payServiceProvider}
                    onChange={(e) => setPayServiceProvider(e.target.value)}
                  />
                </Fragment>
              )}
              {statusForService === 'FineProvider' && (
                <Fragment>
                  <label htmlFor=''>Please Specify PAYABLE Amount</label>
                  <input
                    type='text'
                    value={fineServiceProvider}
                    onChange={(e) => setFineServiceProvider(e.target.value)}
                  />
                </Fragment>
              )}
            </div>
            {props.jobDetails.print && (
              <div className='tab-wrapper'>
                <h5 className='tab-head'>For Print The Provider</h5>
                <div className='btn-wrapper'>
                  <button
                    className={`${
                      statusForPrint === 'doNothing'
                        ? 'btn-tab active-tab'
                        : 'btn-tab'
                    }`}
                    onClick={() => setStatusForPrint('doNothing')}
                  >
                    Do Nothing
                  </button>
                  <button
                    className={`${
                      statusForPrint === 'refundClient'
                        ? 'btn-tab active-tab'
                        : 'btn-tab'
                    }`}
                    onClick={() => setStatusForPrint('refundClient')}
                  >
                    Pay Provider
                  </button>
                  <button
                    className={`${
                      statusForPrint === 'fineClient'
                        ? 'btn-tab active-tab'
                        : 'btn-tab'
                    }`}
                    onClick={() => setStatusForPrint('fineClient')}
                  >
                    Fine Provider
                  </button>
                </div>
                {statusForPrint === 'refundClient' && (
                  <Fragment>
                    <label htmlFor=''>Please Specify PAYABLE Amount</label>
                    <input
                      type='text'
                      value={payPrintProvider}
                      onChange={(e) => setPayPrintProvider(e.target.value)}
                    />
                  </Fragment>
                )}
                {statusForPrint === 'fineClient' && (
                  <Fragment>
                    <label htmlFor=''>Please Specify PAYABLE Amount</label>
                    <input
                      type='text'
                      value={finePrintProvider}
                      onChange={(e) => setFinePrintProvider(e.target.value)}
                    />
                  </Fragment>
                )}
              </div>
            )}

            {seeDeal && (
              <Fragment>
                <div className='tab-wrapper'>
                  <h5 className='tab-head'>For the Job Post</h5>
                  <div className='btn-wrapper'>
                    <button
                      className={`${
                        statusCloseJob === 'closeJob'
                          ? 'btn-tab active-tab'
                          : 'btn-tab'
                      }`}
                      onClick={() => setStatusForCloseJob('closeJob')}
                    >
                      Close Job Post
                    </button>
                    <button
                      className={`${
                        statusCloseJob === 'allowRePost'
                          ? 'btn-tab active-tab'
                          : 'btn-tab'
                      }`}
                      onClick={() => setStatusForCloseJob('allowRePost')}
                    >
                      Allow Re-posting Job
                    </button>
                  </div>
                  {statusCloseJob === '' ? (
                    <p style={{ color: '#FF0000' }}>
                      You Must Choose an Option
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div className='tab-wrapper'>
                  <h5 className='tab-head'>For the Deal</h5>
                  <div className='btn-wrapper'>
                    <button
                      className={`${
                        statusForCloseDeal === 'closeDeal'
                          ? 'btn-tab active-tab'
                          : 'btn-tab'
                      }`}
                      onClick={() => setStatusForCloseDeal('closeDeal')}
                    >
                      Close Deal
                    </button>
                    <button
                      className={`${
                        statusForCloseDeal === 'reInitiateDeal'
                          ? 'btn-tab active-tab'
                          : 'btn-tab'
                      }`}
                      onClick={() => setStatusForCloseDeal('reInitiateDeal')}
                    >
                      Allow Re-initiate Deal
                    </button>
                  </div>
                  {statusForCloseDeal === '' ? (
                    <p style={{ color: '#FF0000' }}>
                      You Must Choose an Option
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </Fragment>
            )}

            <button className='confirm-deal' onClick={handleAction}>
              Confirm Action on Failed Bid
            </button>
          </div>
        </div>
      )}
      {/* {props.status === "FAILED" && (auth.isManager || auth.isAdmin) && (
        <div className="actions">
          <h2>Take Action</h2>
          <p className="init_p">Select an Option</p>
          <div className="table__view">
            <div className="table__option">
              <p>Do Nothing</p>
            </div>
            <div
              onClick={() => setSelected("refund_client")}
              className={`table__option ${
                selected === "refund_client" && "selected_option"
              }`}
            >
              <p>Refund Client</p>
            </div>
            <div
              onClick={() => setSelected("fine_client")}
              className={`table__option ${
                selected === "fine_client" && "selected_option"
              }`}
            >
              <p>Fine Client</p>
            </div>
            <div
              onClick={() => setSelected("pay_provider")}
              className={`table__option ${
                selected === "pay_provider" && "selected_option"
              }`}
            >
              <p>Pay provider</p>
            </div>
            <div
              onClick={() => setSelected("fine_provider")}
              className={`table__option__last ${
                selected === "fine_provider" && "selected_option"
              }`}
            >
              <p>Fine Provider</p>
            </div>
          </div>
          <div className="amount_inp">
            <p>Please Specify Amount</p>
            <input
              autoComplete="off"
              type="text"
              value={""}
              className="info__input"
              onChange={(e) => {
                //setName(e.target.value);
              }}
            />
          </div>
          <ModalButton onClick={handleAction}>Confirm Action</ModalButton>
        </div>
      )} */}
    </div>
  );
};

export default DealCard;
