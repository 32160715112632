import React, { useContext, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { AuthContext } from "./context/auth-context";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  const [render, setRender] = useState();
  const history = useHistory();

  useEffect(() => {
    if (auth?.user?._id) return setRender(children);
    else
      setTimeout(() => {
        history.push("/login");
      }, 2000);
  }, [auth?.user?._id]);

  return <Route {...rest} render={() => render} />;
};

export default PrivateRoute;
