import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./../components/navigation/NewNavBar"
import Footer from './../components/footer/NewFooter'

function Blocked() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const msg = params.get("msg");

  return (
    <div>
      <Header />
      <div style={{height:"600px", color:"red",display:"flex",justifyContent:"center",alignItems:"center"}}>{msg || "You are blocked"}</div>
      <Footer />
    </div>
  );
}

export default Blocked;