import React, { useCallback, useMemo, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import "./Dropdown.css";

export default function Dropdown({ children, options = [], elChange }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const { elementRef } = useOutsideClick(() => setShowDropdown(false));
  const handleSelectedValue = useCallback((item) => {
    setSelectedValue(item);
    setShowDropdown(false);
  }, []);
  const modifiedOptions = useMemo(() => {
    return options.filter(item => searchStr ? String(item.label).toLowerCase().includes(String(searchStr).toLowerCase()) : true);
  }, [options, searchStr]);

  return (
    <div className="dropdown" ref={elementRef} key={`Dropdown_${selectedValue?.label}`}>
      <div className="dropdown__trigger" onClick={() => setShowDropdown(value => !value)}>
        {React.cloneElement(children, {
          ...children.type === "input" ? {
            defaultValue: selectedValue?.label,
            onChange: (e) => {
              setSearchStr(e.target.value);
              elChange(e);
            }
          } : {}
        })}
      </div>
      {!!options.length && <div className={`dropdown__options ${showDropdown ? "show" : ""}`}>
        {modifiedOptions.map((item, ind) => (
          <span key={ind} onClick={() => handleSelectedValue(item)}>{item?.label}</span>
        ))}
      </div>}
    </div>
  )  
}