import FileSaver from "file-saver";
import React, { useContext, useState, useEffect } from "react";
import "./ClientPrintDealDetails.css";
import moment from "moment";
import { AuthContext } from "../../context/auth-context";
import { useHistory } from "react-router-dom";
import isImage from "../util/isImage";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import {
  GET_CLIENT_DUE_PAYMENTS,
  GET_SUBMITTED_JOB,
  GET_SUBMITTED_JOB_BY_PROVIDER,
} from "../../lib/api/admin";

import Button from "../../components/shared/Button/Button";
import RateModal from "../../components/modals/RateModal/RateModal";
import { useParams } from "react-router-dom";
import PayForDealModal from "../../components/modals/PayForDealModal/PayForDealModal";
import axios from "axios";

export default function JobDetailsDealDetails(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => setShow(true);
  const closeModal = () => setShow(false);
  const { id } = useParams();
  const [payment_method, setPayment_method] = useState("BKASH");
  const history = useHistory();
  const { data: dealDuePayments, refetch: dealDuePaymentsRefetch } = useQuery(
    [GET_CLIENT_DUE_PAYMENTS, { deal_id: id }],
    GET_CLIENT_DUE_PAYMENTS,
    { enabled: !!id }
  );
  const { deal } = props;
  const saveFile = (url) => {
    FileSaver.saveAs(process.env.REACT_APP_BACKEND_URL + "/" + url, url);
  };
  const auth = useContext(AuthContext);
  const { data: submitedJobs } = useQuery(
    [
      GET_SUBMITTED_JOB.name,
      {
        deal_id: deal?._id,
      },
    ],
    GET_SUBMITTED_JOB,
    {
      enabled: !!deal?._id,
    }
  );
  const { data: submitedJobs1 } = useQuery(
    [
      GET_SUBMITTED_JOB_BY_PROVIDER.name,
      {
        deal_id: deal?._id,
      },
    ],
    GET_SUBMITTED_JOB_BY_PROVIDER,
    {
      enabled: !!deal?._id,
    }
  );

  useEffect(() => {
    globals();
  }, []);

  const globals = async () => {
    try {
      const res = await axios.get("https://somadha.com/api/user/get_globals", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });
      setPayment_method(res?.data?.payment_method);
    } catch (e) {
      console.log(e);
    }
  };

  const handleBkash = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://somadha.com/api/create_payment",
        {
          method: "62a89430e0a31793bea91ba3",
          job_id: deal?.job_id?._id,
          type: "APPROVE",
          bid_id: deal?.bid_id?._id,
          print_bid_id: deal?.print_bid_id?._id,
          deal_id: id,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );

      if (res.status === 200) {
        setLoading(false);
        const url = res?.data?.bkashURL;
        window.location.replace(url);
      }
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="clientPrintDealDetails__jobDetails">
      <h3>
        Job Details{" "}
        {/*<Dropdown
          options={[
            { label: "Mark Deal as Failed", onClick: () => {} },
            { label: "Update Deadline", onClick: () => {} },
            { label: "Close Deal Successfully", onClick: () => {} },
          ]}
        />*/}
      </h3>
      <div className="clientPrintDealDetails__divider" />
      <br />

      <div className="row" style={{ marginTop: "-30px" }}>
        <div className="col-12" style={{ paddingTop: "30px" }}>
          <Link to={`/job-details/${deal?.job_id?._id}`}>
            <h2 style={{ textDecoration: "underline" }} className="mb-2">
              {deal?.job_id?.title}
            </h2>
          </Link>

          <span>{deal?.job_id?.category[0]}</span>
          <span>
            Posted on {moment(deal?.job_id?.createdAt).format("DD-MM-YYYY")}
          </span>
          {
            <span className="d-flex gap-2">
              Provider Rating{" "}
              <span>({deal?.provider_id?.provider_rating.toFixed(2)}/5)</span>
            </span>
          }
          <span>Budget {deal?.claimed_budget} BDT</span>

          {deal?.job_submitted &&
            auth.user._id === deal.client_id?._id &&
            payment_method === "MANUAL" && (
              <span>
                Due {dealDuePayments?.data?.client_due} BDT
                {!props.closedByAdmin &&
                  dealDuePayments?.data?.client_due > 0 && (
                    <PayForDealModal
                      deal={deal}
                      job={deal.job_id}
                      bidId={deal?.bid_id?._id}
                      printBidId={deal?.print_bid_id?._id}
                      type="APPROVE"
                      refetch={dealDuePaymentsRefetch}
                    >
                      <button
                        className="btn prime-btn ml-3 btn-sm"
                        type="button"
                        style={{ marginLeft: "1rem" }}
                      >
                        {auth.language === "en"
                          ? "Pay Due Amount"
                          : "বকেয়া পরিমাণ পরিশোধ করুন"}
                      </button>
                    </PayForDealModal>
                  )}
              </span>
            )}
          <p>{deal?.job_id?.description}</p>

          <div className="my-5">
            {deal?.job_id?.attachments &&
            deal?.job_id?.attachments?.length === 0 ? (
              <p className="">
                {" "}
                <img
                  src="/assets/attachments.svg"
                  alt=""
                  style={{ height: "15px", width: "15px", marginRight: "10px" }}
                />
                {auth.language === "en"
                  ? "No attachments available"
                  : "কোন সংযুক্তি নাই"}
              </p>
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                {deal?.job_id?.attachments?.map((dt, idx) => (
                  <a
                    href={dt}
                    target="_blank"
                    style={{
                      backgroundColor: "var(--grey_six)",
                      padding: ".75rem 2rem",
                      borderRadius: "4px",
                      display: "inline-block",
                    }}
                  >
                    <img
                      style={{
                        width: "50px",
                        objectFit: "cover",
                        marginRight: "1rem",
                      }}
                      src={
                        isImage(dt)
                          ? dt
                          : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                      }
                      alt=""
                    />
                    {auth.language === "en"
                      ? "view attachment"
                      : "সংযুক্তি দেখুন"}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <h3>Delivery </h3>
      <div className="clientPrintDealDetails__divider" />

      <div className="row">
        <div className="col-sm-12 col-md-6 attachment-border">
          {deal?.job_submitted && submitedJobs?.data && (
            <div>
              <div className="attachment">
                <img src="/assets/attachments.svg" alt="" />
                {auth.language === "en"
                  ? "Demo Deliverables"
                  : "ডেমো ডেলিভারেবল"}
              </div>

              <div className="my-5">
                {submitedJobs?.data?.attachments_overview &&
                submitedJobs?.data?.attachments_overview.length === 0 ? (
                  <p className="p6">
                    {" "}
                    {auth.language === "en"
                      ? "No attachments available"
                      : "কোন সংযুক্তি নাই"}
                  </p>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    {submitedJobs?.data?.attachments_overview?.map(
                      (dt, idx) => (
                        <a
                          href={dt}
                          target="_blank"
                          style={{
                            backgroundColor: "var(--grey_six)",
                            padding: ".75rem 2rem",
                            borderRadius: "4px",
                            display: "inline-block",
                          }}
                        >
                          <img
                            style={{
                              width: "50px",
                              objectFit: "cover",
                              marginRight: "1rem",
                            }}
                            src={
                              isImage(dt)
                                ? dt
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                            }
                            alt=""
                          />
                          {auth.language === "en"
                            ? "view attachment"
                            : "সংযুক্তি দেখুন"}
                        </a>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {!submitedJobs?.data &&
            deal?.job_submitted &&
            submitedJobs1?.data && (
              <div>
                <div className="attachment">
                  <img src="/assets/attachments.svg" alt="" />
                  {auth.language === "en"
                    ? "Demo Deliverables"
                    : "ডেমো ডেলিভারেবল"}
                </div>

                <div className="my-5">
                  {/* <h3 style={{ fontWeight: "bold" }} className="mb-3">
            {" "}
            {auth.language === "en" ? "Attachments" : "সংযুক্তি"}
          </h3> */}
                  {submitedJobs1?.data?.attachments_overview &&
                  submitedJobs1?.data?.attachments_overview.length === 0 ? (
                    <p className="p6">
                      {" "}
                      {auth.language === "en"
                        ? "No attachments available"
                        : "কোন সংযুক্তি নাই"}
                    </p>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      {submitedJobs1?.data?.attachments_overview?.map(
                        (dt, idx) => (
                          <a
                            href={dt}
                            target="_blank"
                            style={{
                              backgroundColor: "var(--grey_six)",
                              padding: ".75rem 2rem",
                              borderRadius: "4px",
                              display: "inline-block",
                            }}
                          >
                            <img
                              style={{
                                width: "50px",
                                objectFit: "cover",
                                marginRight: "1rem",
                              }}
                              src={
                                isImage(dt)
                                  ? dt
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                              }
                              alt=""
                            />
                            {auth.language === "en"
                              ? "view attachment"
                              : "সংযুক্তি দেখুন"}
                          </a>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
        <div className="col-sm-12 col-md-6">
          {deal?.job_submitted && submitedJobs?.data && (
            <div>
              <div className="attachment">
                <img src="/assets/attachments.svg" alt="" />
                {auth.language === "en" ? "Deliverables" : "ডেলিভারেবল"}
              </div>

              {!props.closeModal &&
                deal?.job_submitted &&
                auth.user._id === deal.client_id?._id &&
                payment_method === "BKASH" && (
                  <div className="bkash_btn mt-4 gap-2">
                    Due {dealDuePayments?.data?.client_due} BDT
                    {!props.closedByAdmin && dealDuePayments?.data?.client_due > 0 && (
                      <button
                        className="btn prime-btn btn-sm"
                        type="button"
                        onClick={
                          deal?.job_id?.delivery ? showModal : handleBkash
                        }
                      >
                        {auth.language === "en" && loading === false
                          ? "Pay Due Amount With Bkash"
                          : null}
                        {auth.language !== "en" && loading === false
                          ? "বিকাশে বকেয়া পরিমাণ পরিশোধ করুন"
                          : null}

                        {auth.language === "en" && loading === true
                          ? "Loading..."
                          : null}
                        {auth.language !== "en" && loading === true
                          ? "লোডিং..."
                          : null}
                      </button>
                    )}
                  </div>
                )}

              {!props.closedByAdmin &&
                dealDuePayments?.data?.client_due > 0 && (
                  <p style={{ fontWeight: "semibold" }}>
                    Note : Pay the remaining amount (
                    {dealDuePayments?.data?.client_due} BDT) to see the full
                    deliverable.
                  </p>
                )}

              <div className="my-5">
                {submitedJobs?.data?.attachments &&
                submitedJobs?.data?.attachments.length === 0 ? (
                  <p className="p6">
                    {" "}
                    {auth.language === "en"
                      ? "No attachments available"
                      : "কোন সংযুক্তি নাই"}
                  </p>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    {submitedJobs?.data?.attachments?.map((dt, idx) => (
                      <a
                        href={dt}
                        target="_blank"
                        style={{
                          backgroundColor: "var(--grey_six)",
                          padding: ".75rem 2rem",
                          borderRadius: "4px",
                          display: "inline-block",
                        }}
                      >
                        <img
                          style={{
                            width: "50px",
                            objectFit: "cover",
                            marginRight: "1rem",
                          }}
                          src={
                            isImage(dt)
                              ? dt
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                          }
                          alt=""
                        />
                        {auth.language === "en"
                          ? "view attachment"
                          : "সংযুক্তি দেখুন"}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {!submitedJobs?.data &&
            deal?.job_submitted &&
            submitedJobs1?.data && (
              <div>
                <div className="attachment">
                  <img src="/assets/attachments.svg" alt="" />
                  {auth.language === "en" ? "Deliverables" : "ডেলিভারেবল"}
                </div>

                {!props.closedByAdmin &&
                  deal?.job_submitted &&
                  auth.user._id === deal.client_id?._id &&
                  payment_method === "BKASH" && (
                    <span>
                      Due {dealDuePayments?.data?.client_due} BDT
                      {!props.closedByAdmin && dealDuePayments?.data?.client_due > 0 && (
                        <button
                          className="btn prime-btn ml-3 btn-sm"
                          type="button"
                          style={{ marginLeft: "1rem" }}
                          onClick={
                            deal?.job_id?.delivery ? showModal : handleBkash
                          }
                        >
                          {auth.language === "en" && loading === false
                            ? "Pay Due Amount With Bkash"
                            : null}
                          {auth.language !== "en" && loading === false
                            ? "বিকাশে বকেয়া পরিমাণ পরিশোধ করুন"
                            : null}

                          {auth.language === "en" && loading === true
                            ? "Loading..."
                            : null}
                          {auth.language !== "en" && loading === true
                            ? "লোডিং..."
                            : null}
                        </button>
                      )}
                    </span>
                  )}

                <div className="my-5">
                  {submitedJobs1?.data?.attachments &&
                  submitedJobs1?.data?.attachments.length === 0 ? (
                    <p className="p6">
                      {" "}
                      {auth.language === "en"
                        ? "No attachments available"
                        : "কোন সংযুক্তি নাই"}
                    </p>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      {submitedJobs1?.data?.attachments?.map((dt, idx) => (
                        <a
                          href={dt}
                          target="_blank"
                          style={{
                            backgroundColor: "var(--grey_six)",
                            padding: ".75rem 2rem",
                            borderRadius: "4px",
                            display: "inline-block",
                          }}
                        >
                          <img
                            style={{
                              width: "50px",
                              objectFit: "cover",
                              marginRight: "1rem",
                            }}
                            src={
                              isImage(dt)
                                ? dt
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                            }
                            alt=""
                          />
                          {auth.language === "en"
                            ? "view attachment"
                            : "সংযুক্তি দেখুন"}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>

      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please inform us via the complaint section{" "}
          <a
            style={{ textDecoration: "underlines", color: "blue" }}
            href="https://somadha.com/send-feedback?fbclid=IwAR2_AdvnUS_NTGngcHZqIXuJhpHEGHZwROF3qU7WL079t-dlBfTaXgmGZI0"
          >
            (here),{" "}
          </a>
          if you did not receive the requested hard copies by the next 3 working
          days. Otherwise, we will assume that your hard copies are delivered.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleBkash();
              closeModal();
            }}
          >
            {auth.language === "en" && loading === false ? "Pay" : null}
            {auth.language !== "en" && loading === false ? "পরিশোধ করুন" : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
