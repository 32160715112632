import React from "react";
import { BsChevronDown } from "react-icons/bs";
import "./Select.css";

const Select = ({ children, width, style, variant, ...rest }, ref) => {
  switch (variant) {
    case "ghost_white":
      return (
        <label htmlFor="ghost_white_select" className="select ghost_white" style={style || { width: width || "100%" }}>
          <select id="ghost_white_select" {...rest} ref={ref}>
            {children}
          </select>
          <BsChevronDown />
        </label>
      )
    default:
      return (
        <label htmlFor="select" className="select" style={style || { width: width || "100%" }}>
          <select id="select" {...rest} ref={ref}>
            {children}
          </select>
          <BsChevronDown />
        </label>
      )
  }
}

export default React.forwardRef(Select);