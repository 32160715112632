import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ExploreJobs from "../../../uicomponents/userPanel/exploreJob/ExploreJob";
import "./HomeJobCard.css";
import { useHttpClient } from "../../../hooks/http-hook";
import ShowExpJobs from "../../../uicomponents/userPanel/exploreJob/ShowExpJobs";
import { AuthContext } from "../../../context/auth-context";

const HomeJobCard = (props) => {
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [ExpJobs, setExpJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // const { category } = useParams();
  const [selectedSub, setSelectedSub] = useState("");
  const category = "all"; //for testing
  useEffect(() => {
    const fetchExpJobs = async () => {
      setLoading(true);
      try {
        const responseData = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/jobs/`
        );
        setExpJobs(responseData.data.data);
        setLoading(false);
      } catch (err) {}
    };
    fetchExpJobs();
  }, []);

  return (
    <>
      <div className="job_card_container_home mb-3">
        <div className="jobCard_container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <p
              style={{
                color: "var(--text)",
                fontSize: "28px",
                fontWeight: "normal",
              }}
            >
              {auth.language === "en"
                ? "Explore Work Posts"
                : "কাজের পোস্টগুলি অন্বেষণ করুন৷"}
            </p>
            <p className="m-0">
              <Link to="/explore/all">
                <span
                  style={{
                    color: "var(--primary_color)",
                    fontSize: "24px",
                    fontWeight: "normal",
                  }}
                >
                  {auth.language === "en" ? (
                    <>
                      Browse all Work Posts{" "}
                      <img src="./assets/newIcons/rightArrow.svg" alt="arrow" />
                    </>
                  ) : (
                    <>
                      সব কাজের পোস্ট খুঁজুন{" "}
                      <img src="./assets/newIcons/rightArrow.svg" alt="arrow" />
                    </>
                  )}
                  <i class="fas fa-arrow-right"></i>
                </span>
              </Link>
            </p>
          </div>
          <div className="">
            {ExpJobs?.length > 0 ? (
              <ShowExpJobs ExpJobs={ExpJobs.slice(0, 5)} loading={loading} />
            ) : (
              <h3 style={{ color: "var(--grey_three)" }}>
                No jobs found!
              </h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeJobCard;
