import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import AdminResetPasswordModal from "../../../components/modals/AdminResetPasswordModal/AdminResetPasswordModal";
import { GET_USERS, IMPERSONATE_USER } from "../../../lib/api/admin";
import Button from "react-bootstrap/Button";
import Input from "../../../../src/components/shared/Input/Input";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

export default function Users() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ fullname: "asc" });
  const [adminUsers, setAdminUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const { register, handleSubmit, reset, setValue, control } = useForm();
  const {
    data: users,
    status,
    error,
    refetch,
  } = useQuery(
    [
      "GET_USERS",
      { page, ...(search ? { search } : {}), sort: JSON.stringify(sort) },
    ],
    GET_USERS
  );
  const { mutateAsync: impersonateUser } = useMutation(IMPERSONATE_USER);
  const [resetPasswordUser, setResetPasswordUser] = useState(null);

  useEffect(() => {
    getUsers();
  }, []);

  const handleImpersonateUser = useCallback(
    async (id) => {
      try {
        const { data } = await impersonateUser(id);
        window.localStorage.setItem("auth_token", data.url.split("/")[2]);
        window.location.href = "/";
        toast.success("Successfully impersonated the user!");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [impersonateUser]
  );

  const getUsers = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/admin/export_users",
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      setAdminUsers(JSON.parse(res?.data.data));
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const onSubmit = async (values) => {
    try {
      const res = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/admin/update_user_profile",
        {
          id: data._id,
          client_credit: values?.client_credit || data.client_credit,
          provider_credit: values?.provider_credit || data.provider_credit,
          blocked_until: values?.blocked_until,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      toast.success("User data updated successfully!");
      reset();
      setShow(false); // Close the modal after successful submission
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  return (
    <AdminLayout>
      <AdminResetPasswordModal
        open={!!resetPasswordUser}
        data={resetPasswordUser}
        onClose={() => setResetPasswordUser(null)}
      />

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Manage Users</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="addEditCategoryModal"
          >
            <Input
              label="Client Credit"
              placeholder={data?.client_credit}
              {...register("client_credit")}
            />
            <Input
              label="Provider Credit"
              placeholder={data?.provider_credit}
              {...register("provider_credit")}
            />
            <Input
              type="date"
              label="Blocked Until"
              placeholder={data?.blocked_until}
              {...register("blocked_until")}
            />
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <AdminTableView
        query={{ status, error }}
        userList={adminUsers}
        title="Users"
        search={{
          title: "Search User",
          placeholder: "Search by name, email...",
          btnText: "Search User",
          onSearch: (value) => setSearch(value),
        }}
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Name (ASC)", value: "fullname:asc" },
                { label: "Name (DESC)", value: "fullname:desc" },
                { label: "Client Rating (ASC)", value: "client_rating:asc" },
                { label: "Client Rating (DESC)", value: "client_rating:desc" },
                {
                  label: "Provider Rating (ASC)",
                  value: "provider_rating:asc",
                },
                {
                  label: "Provider Rating (DESC)",
                  value: "provider_rating:desc",
                },
                { label: "Fine (ASC)", value: "fine:asc" },
                { label: "Fine (DESC)", value: "fine:desc" },
                { label: "Total Deal (ASC)", value: "total_deal:asc" },
                { label: "Total Deal (DESC)", value: "total_deal:desc" },
                { label: "Total Post (ASC)", value: "total_post:asc" },
                { label: "Total Post (DESC)", value: "total_post:desc" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort((values) => ({ ...values, [key]: value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Name", dotsMenu: [], key: "fullname" },
            { name: "Email Address", dotsMenu: [], key: "email" },
            { name: "DOB", dotsMenu: [], key: "date_of_birth" },
            { name: "Account Verified", dotsMenu: [], key: "status" },
            { name: "Blocked Until", dotsMenu: [], key: "blocked_until" },
            { name: "Registered At", dotsMenu: [], key: "createdAt" },
            { name: "Role", dotsMenu: [], key: "user_type" },
            { name: "Language", dotsMenu: [], key: "language" },
            { name: "NID", dotsMenu: [], key: "nid" },
            {
              name: "",
              dotsMenu: [
                {
                  name: "See Profile",
                  onClick: (data) => history.push(`/profile/${data?._id}`),
                },
                {
                  name: "Impersonate",
                  onClick: (data) => handleImpersonateUser(data?._id),
                },
                {
                  name: "Reset Password",
                  onClick: (data) => setResetPasswordUser(data),
                },
                {
                  name: "Manage User",
                  onClick: (data) => {
                    setData(data);
                    setShow(true);
                    console.log(data);
                  },
                },
              ],
              key: "actions",
            },
          ],
          data: users?.data?.data.map((user) => {
            console.log(user);
            return {
              _id: user._id,
              fullname: user?.fullname || "N/A",
              createdAt: moment(user?.createdAt).format("YYYY-MM-DD") || "N/A",
              email: user?.email || "N/A",
              client_credit: user?.client_credit || 0,
              status: user?.is_email_verified?"verified":"not verified" || "N/A",
              provider_credit: user?.provider_credit || 0,
              blocked_until: user?.blocked_until?.slice(0, 10) || "Active",
              date_of_birth:
                (user?.date_of_birth !== null &&
                  moment(user?.date_of_birth).format("YYYY-MM-DD")) ||
                "N/A",
              user_type: user?.user_type || "N/A",
              language: user?.language || "N/A",
              nid: String(user?.nid || "") || "N/A",
              actions: "",
            };
          }),
          pagination: {
            total: users?.data?.total,
            page,
            prev: users?.data?.prev,
            next: users?.data?.next,
            setPage,
          },
        }}
      />
    </AdminLayout>
  );
}
