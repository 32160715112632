import React from 'react';
import WorkProviderCard from './WorkProviderCard';
import './ShowWorkProvider.css';

function ShowWorkProvider({ providers }) {
  return (
    <div className='row'>
      {providers?.length > 0 ? (
        providers.map((provider, idx) => (
          <div className='col-sm-12 col-md-6 col-lg-4 viewCards' key={idx}>
            <WorkProviderCard
              provider={provider}
              image='https://tinyurl.com/2e7vj99k'
            />
          </div>
        ))
      ) : (
        <h2 style={{ color: 'dimgray', marginTop: '10px' }}>No Data Found!</h2>
      )}
    </div>
  );
}

export default ShowWorkProvider;
