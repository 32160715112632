import React, { Fragment, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import ErrorModal from "../../../components/util/ErrorModal";
import FileUploader from "../../../components/util/FileUploader";
import LoadingSpinner from "../../../components/util/LoadingSpinner";
import { AuthContext } from "../../../context/auth-context";
import { useForm } from "../../../hooks/form-hook";
import { useHttpClient } from "../../../hooks/http-hook";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../../util/Validators";
import "./Postjob.css";
const paperOptions = [
  "A0",
  "A1",
  "A2",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "letter",
  "legal",
];
function Postjob(props) {
  const auth = useContext(AuthContext);
  const { providerID } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  let history = useHistory();
  const [files, setFiles] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [print, setPrint] = useState(props.paper ? true : false);
  const [paper, setPaper] = useState(props.paper ? props.paper : "");
  const [addr, setAddr] = useState(props.address ? props.address : "");
  const [bw, setBw] = useState(props.isBW ? props.isBW : false);
  const [double, setDouble] = useState(props.isDP ? props.isDP : false);
  const [successModal, setSuccessModal] = useState(false);
  const [print_deadline, setPrint_deadline] = useState(false);
  const [successModalEdit, setSuccessModalEdit] = useState(false);
  const [successModalPayment, setSuccessModalPayment] = useState(false);
  const [successM, setSuccessM] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      category: {
        value: "",
        isValid: false,
      },
      // subcategory: {
      //   value: "",
      //   isValid: true,
      // },
      budget: {
        value: "",
        isValid: false,
      },
      deadline: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  window.addEventListener("storage", function (e) {
    if (e.key === "P_AD") {
      setSuccessModalPayment(true);
    }
  });
  const selectedCategory = auth.categories?.data?.find?.(
    (cat) => cat.name === formState.inputs.category.value
  );
  // useEffect(() => {
  //   if(formState.inputs.category) {

  //   }
  //     }, [formState, auth.categories, selectedCategory])
  useEffect(() => {
    const fetchSubOptions = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/get_subcategories/?name=${formState.inputs.category.value}`
        );
        setSubCategoryOptions(responseData);
      } catch (err) {}
    };
    formState.inputs.category.value.length > 0 && fetchSubOptions();
    //eslint-disable-next-line
  }, [formState.inputs.category.value]);
  const handleEdit = async () => {
    try {
      const category = [
        formState.inputs.subcategory.value
          ? formState.inputs.subcategory.value
          : formState.inputs.category.value,
      ];
      if (formState.inputs.category.value === "print") {
        category.pop();
        category.push("print");
      }
      if (print) {
        category.push("print");
      }
      let dataToSend;
      print || formState.inputs.category.value === "print"
        ? (dataToSend = {
            title: formState.inputs.title.value,
            description: formState.inputs.description.value,
            category: category,
            max_budget: formState.inputs.budget.value,
            deadline: formState.inputs.deadline.value,
            paper: paper,
            mailing_address: addr,
            is_black_and_white: bw,
            is_double_page: double,
          })
        : (dataToSend = {
            title: formState.inputs.title.value,
            description: formState.inputs.description.value,
            category: category,
            max_budget: formState.inputs.budget.value,
            deadline: formState.inputs.deadline.value,
          });
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/client/update_job/?job_id=${props.id}`,
        "PUT",
        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        setSuccessModalEdit(true);
      }
    } catch (err) {}
  };

  const handleCancel = () => {
    history.goBack();
  };
  const handleDelete = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          "/client/delete_job/?job_id=" +
          props.id,
        "DELETE"
      );
      if (responseData.success === "yes") {
        history.goBack();
      }
    } catch (err) {}
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);
      //formData.append("attachments", file);
      if (files) {
        for (const file of files) {
          formData.append("attachments", file);
        }
      }

      formData.append(
        "category[0]",
        formState.inputs.subcategory
          ? formState.inputs.subcategory.value
          : formState.inputs.category.value
      );
      formData.append("max_budget", formState.inputs.budget.value);
      formData.append("deadline", formState.inputs.deadline.value);

      if (selectedCategory.print) {
        formData.append("print", true);
        formData.append("print_deadline", print_deadline);
        formData.append("delivery", true);
        formData.append("paper", paper);
        formData.append("is_black_and_white", bw);
        formData.append("is_double_page", double);
        formData.append("mailing_address", addr);
        // formData.append("category[1]", "print");
      }
      if (selectedCategory.delivery) {
        formData.append("delivery", true);
        formData.append("mailing_address", addr);
        // formData.append("category[1]", "print");
      }

      if (print || formState.inputs.category.value === "print") {
        formData.append("print", true);
        formData.append("print_deadline", print_deadline);
        formData.append("delivery", true);
        formData.append("paper", paper);
        formData.append("is_black_and_white", bw);
        formData.append("is_double_page", double);
        formData.append("mailing_address", addr);
      }

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/client/create_job",
        "POST",
        formData
      );
      if (responseData.success === "yes") {
        // setSuccessModal(true);
        setSuccessM(true);
        history.push("/user/jobPosts");
      }
    } catch (err) {}
  };

  const createDirectDeal = async () => {
    try {
      const formData = new FormData();
      formData.append("provider_id", providerID);
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);
      //formData.append("attachments", file);
      if (files) {
        for (const file of files) {
          formData.append("attachments", file);
        }
      }
      formData.append(
        "category[0]",
        formState.inputs.subcategory.value
          ? formState.inputs.subcategory.value
          : formState.inputs.category.value
      );
      formData.append("max_budget", formState.inputs.budget.value);
      formData.append("deadline", formState.inputs.deadline.value);

      if (print || formState.inputs.category.value === "print") {
        formData.append("paper", paper);
        formData.append("is_black_and_white", bw);
        formData.append("is_double_page", double);
        formData.append("mailing_address", addr);
        formData.append("category[1]", "print");
      }

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/client/initiate_direct_deal/",
        "POST",
        formData
      );
      if (responseData.ssl_url) {
        window
          .open(
            responseData.ssl_url,
            "",
            ` scrollbars=yes,menubar=no,height=680 ,width=520, left=${
              window.screen.width / 2 - 260
            } top=${
              window.screen.height / 2 - 340
            }resizable=yes,toolbar=no,location=no,status=no"`
          )
          .focus();
      }
    } catch (err) {}
  };
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {/* <SuccessModal
        onClear={() => {
          setSuccessModal(false);
          history.push("/explore/all");
        }}
        show={successModal}
        message="Job was posted successfully. Wait for admin to approve this post."
      /> */}
      <Modal
        size="sm"
        show={successM}
        onHide={() => setSuccessM(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {" "}
            {auth.language === "en" ? "Success" : "সফলতা"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p align="center">
            {auth.language === "en"
              ? "Job was posted successfully. Wait for admin to approve this post."
              : "কাজ সফলভাবে পোস্ট করা হয়েছে. এই পোস্টটি অনুমোদন করার জন্য অ্যাডমিনের জন্য অপেক্ষা করুন।"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" class="btn" onClick={() => setSuccessM(false)}>
            {auth.language === "en" ? "OK" : "ঠিক আছে"}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        {isLoading && <LoadingSpinner asOverlay />}
        {/* <h4>Create a New Job Post</h4> */}
        {props.editor && (
          <h4>
            {" "}
            {auth.language === "en" ? "Edit Post" : "পোস্ট সম্পাদনা করুন"}
          </h4>
        )}
        {!props.editor && !props.direct && (
          <h3 style={{ fontWeight: "bold" }}>
            {" "}
            {auth.language === "en"
              ? "Create a new Post"
              : "একটি নতুন পোস্ট তৈরি করুন"}
          </h3>
        )}

        <div className="input--wrapper">
          <Input
            editor
            onInput={inputHandler}
            element="input"
            id="title"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Title must be at least 6 characters."
            label={auth.language === "en" ? "Work Title" : "কাজের শিরোনাম"}
            initialValue={props.editor && props.title}
            initialValid={props.editor}
            isRequired={true}
            placeholder={
              auth.language === "en"
                ? "Be concise and short, eg: logo for a Bakery Company"
                : "ছোট এবং সংক্ষিপ্ত দিন, যেমন: একটি কোম্পানির লোগো দরকার"
            }
          />

          <Input
            editor
            large
            onInput={inputHandler}
            element="textarea"
            id="description"
            type="text"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(100)]}
            errorText="Description should be minimum 100 characters."
            initialValue={props.editor && props.description}
            initialValid={props.editor}
            label={
              auth.language === "en"
                ? "Detailed Description *"
                : "বিস্তারিত বিবরণ *"
            }
            subLabel=""
          />
          {!props.editor && (
            <div className="fileup__container">
              <div className="infoleft">
                <p>
                  <span style={{ fontSize: "1.1rem", fontWeight: "400" }}>
                    {" "}
                    {auth.language === "en" ? "Attachment" : "সংযুক্তি"}
                  </span>
                </p>
              </div>
              <div className="inforight">
                <FileUploader
                  updatestate={(childFile) => {
                    setFiles(childFile);
                  }}
                />
                {files && (
                  <p>
                    {files.length}{" "}
                    {auth.language === "en"
                      ? "file/s chosen"
                      : "ফাইল/গুলি বেছে নেওয়া হয়েছে"}
                  </p>
                )}
              </div>
            </div>
          )}

          <Input
            editor
            onInput={inputHandler}
            element="input"
            id="deadline"
            type="date"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter deadline."
            label={auth.language === "en" ? "Deadline *" : "সময়সীমা  *"}
            initialValue={props.editor && props.deadline.substring(0, 10)}
            initialValid={props.editor}
            subLabel=""
          />
        </div>

        {selectedCategory?.print && (
          <Fragment>
            <h5
              style={{
                marginBottom: "20px",
                textAlign: "center",
                marginTop: "10px",
                fontSize: "15px",
              }}
            >
              {" "}
              {auth.language === "en"
                ? " Printing and Delivery Both are Mandatory for this category"
                : "মুদ্রণ এবং বিতরণ উভয়ই এই বিভাগের জন্য বাধ্যতামূলক"}
            </h5>
            <div className="print--details">
              <div className="input_group">
                <label htmlFor="paper">
                  {" "}
                  {auth.language === "en" ? "Paper" : "কাগজ"}
                </label>
                <select
                  id="paper"
                  className="select-filed"
                  type="text"
                  value={paper}
                  onChange={(e) => setPaper(e.target.value)}
                >
                  <option className="first__option" value="">
                    {" "}
                    {auth.language === "en"
                      ? "Select Paper Size"
                      : "কাগজের আকার নির্বাচন করুন"}
                  </option>
                  {paperOptions.map((paper) => {
                    return <option value={paper}>{paper}</option>;
                  })}
                </select>
              </div>
              <div className="input_group">
                <p>
                  {" "}
                  {auth.language === "en"
                    ? "Printing Deadline"
                    : "মুদ্রণের সময়সীমা "}
                </p>
                <input
                  className="input-field"
                  type="date"
                  value={print_deadline}
                  onChange={(e) => setPrint_deadline(e.target.value)}
                />
              </div>
              <div className="input_group">
                <p>
                  {" "}
                  {auth.language === "en"
                    ? "Mailing Address"
                    : "চিঠি পাঠানোর ঠিকানা"}
                </p>
                <textarea
                  className="textarea-field"
                  type="text"
                  value={addr}
                  onChange={(e) => {
                    setAddr(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="row">
                <div className="input_group_line">
                  <div className="col-lg-2 col-sm-5">
                    <p>
                      {" "}
                      {auth.language === "en" ? "Black and White" : "সাদাকালো"}
                    </p>
                  </div>
                  <div className="col-lg-1 col-sm-2">
                    <input
                      type="checkbox"
                      checked={bw}
                      onChange={(e) => {
                        setBw(e.target.checked);
                      }}
                      className="chkbox"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="input_group_line">
                  <div className="col-lg-2 col-sm-5">
                    <p> {auth.language === "en" ? "Both side" : "উভই পাশে"}</p>
                  </div>
                  <div className="col-lg-1 col-sm-2">
                    <input
                      type="checkbox"
                      checked={double}
                      onChange={(e) => {
                        setDouble(e.target.checked);
                      }}
                      className="chkbox"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {selectedCategory?.delivery && !selectedCategory?.print && (
          <Fragment>
            <h5
              style={{
                marginBottom: "20px",
                textAlign: "center",
                marginTop: "10px",
                fontSize: "15px",
              }}
            >
              {" "}
              {auth.language === "en"
                ? "Delivery is Mandatory for this category"
                : "এই বিভাগের জন্য ডেলিভারি বাধ্যতামূলক"}
            </h5>
            <div className="print--details">
              <div className="input_group">
                <p>
                  {" "}
                  {auth.language === "en"
                    ? " Mailing Address"
                    : "চিঠি পাঠানোর ঠিকানা"}
                </p>
                <textarea
                  className="textarea-field"
                  type="text"
                  value={addr}
                  onChange={(e) => {
                    setAddr(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </Fragment>
        )}

        <div className="footer--text">
          {!props.paper &&
            !selectedCategory?.print &&
            !selectedCategory?.delivery && (
              <p>
                {" "}
                {auth.language === "en"
                  ? "Physical printed copy?"
                  : "মুদ্রিত কপি প্রয়োজন?"}
              </p>
            )}
          {!props.paper &&
            !selectedCategory?.print &&
            !selectedCategory?.delivery && (
              <input
                className="chkbox"
                type="checkbox"
                checked={
                  formState.inputs.category.value === "print" ? true : print
                }
                onChange={(e) => {
                  setPrint(e.target.checked);
                }}
              ></input>
            )}
          {props.print && (
            <p>
              {" "}
              {auth.language === "en"
                ? " Enter print details below"
                : "নীচে প্রিন্ট বিবরণ লিখুন"}
            </p>
          )}
        </div>

        {(formState.inputs.category.value === "print" || print) &&
          !props.print && (
            <div className="print--details">
              <div className="input_group">
                <label>{auth.language === "en" ? "Paper" : "কাগজ"}</label>
                <select
                  className="select-filed"
                  type="text"
                  value={paper}
                  onChange={(e) => setPaper(e.target.value)}
                >
                  <option className="first__option" value="">
                    {" "}
                    {auth.language === "en"
                      ? " Select Paper Size"
                      : "কাগজের আকার নির্বাচন করুন"}
                  </option>
                  {paperOptions.map((paper) => {
                    return <option value={paper}>{paper}</option>;
                  })}
                </select>
              </div>
              <div className="input_group" style={{ marginTop: "-30px" }}>
                <label>
                  {" "}
                  {auth.language === "en"
                    ? " Printing Deadline"
                    : "মুদ্রণের সময়সীমা "}
                </label>
                <input
                  className="input-field"
                  type="date"
                  value={print_deadline}
                  onChange={(e) => setPrint_deadline(e.target.value)}
                />
              </div>
              <div className="input_group">
                <label>
                  {" "}
                  {auth.language === "en"
                    ? "Mailing Address"
                    : "চিঠি পাঠানোর ঠিকানা"}
                </label>
                <textarea
                  className="textarea-field"
                  type="text"
                  value={addr}
                  onChange={(e) => {
                    setAddr(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="row">
                <div className="input_group_line">
                  <div className="col-lg-2 col-sm-5">
                    <p>
                      {" "}
                      {auth.language === "en" ? " Black and White" : "সাদাকালো"}
                    </p>
                  </div>
                  <div className="col-lg-1 col-sm-2">
                    <input
                      type="checkbox"
                      checked={bw}
                      onChange={(e) => {
                        setBw(e.target.checked);
                      }}
                      className="chkbox"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="input_group_line">
                  <div className="col-lg-2 col-sm-5">
                    <p> {auth.language === "en" ? "Both side" : "উভই পাশে"}</p>
                  </div>
                  <div className="col-lg-1 col-sm-2">
                    <input
                      type="checkbox"
                      checked={double}
                      onChange={(e) => {
                        setDouble(e.target.checked);
                      }}
                      className="chkbox"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          )}

        <div
          className="footer--text "
          style={{ color: "var(--negetive_color)" }}
        >
          {" "}
          {auth.language === "en"
            ? "Your whole profile including your name, rating past history will be"
            : "আপনার নাম সহ আপনার পুরো বৃত্তান্ত, রেটিং অতীত ইতিহাস থাকবে"}
          <br />{" "}
          {auth.language === "en"
            ? " visible to anyone who can view the posts"
            : "যারা পোস্ট দেখতে পারেন তাদের কাছে দৃশ্যমান"}
        </div>

        <div className="footer--btn">
          <div className="editor--btns">
            {props.editor && (
              <button
                onClick={handleCancel}
                className="btn__Cancel"
                color="red"
              >
                {auth.language === "en" ? "Cancel" : "বাতিল করুন"}
              </button>
            )}
            {props.editor && (
              <button
                onClick={handleDelete}
                className="btn__delete"
                color="red"
              >
                {auth.language === "en" ? "Delete Post" : "পোস্ট মুছুন"}
              </button>
            )}

            {props.editor && (
              <button onClick={handleEdit} className="btn__save">
                {auth.language === "en" ? "Save Post" : "পোস্ট সংরক্ষণ করুন"}
              </button>
            )}
          </div>
          {!props.editor && !props.direct && (
            <Button
              buttonStyle="btn--banner"
              onClick={handleSubmit}
              isDisabled={formState.isValid}
            >
              {/* {!formState.isValid ? (
                <Tooltip width='180px' tip='Please fill all the information'>
                  Post This Job
                </Tooltip>
              ) : (
                'Post This Job'
              )} */}
              {auth.language === "en" ? " Post this Job" : "এই কাজ পোস্ট করুন"}
            </Button>
          )}
          {props.direct && (
            <Button buttonStyle="btn--banner" onClick={createDirectDeal}>
              {auth.language === "en" ? "Create Deal" : "চুক্তি তৈরি করুন"}
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Postjob;
