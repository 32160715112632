import React, { useEffect, useState } from 'react';
import './EditExpertise.css';
import Modal from '../../util/Modal';
import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import ModalButton from '../../button/ModalButton';

const RePostJob = (props) => {
  const { isLoading, sendRequest } = useHttpClient();

  const handleRePost = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/admin/allow_repost?deal_id=${props.deal_id}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
        props.fetchDealDetails();
      }
    } catch (err) {}
  };

  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='edit_exp_container big_one small_one'>
        <img
          className='exit_edit'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2>Are You Sure?</h2>
        <div className='report__issue__btn'>
          <ModalButton onClick={handleRePost}>Re-post job</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default RePostJob;
