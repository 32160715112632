export default {
  primary: "#6002EE",
  primary_light: "#F0E5FF",
  secondary: "#9965F4",
  link_text: "#0000D6",
  positive_feedback: "#59C36A",
  negative_feedback: "#E84857",
  job_post: "#F6FBFF",
  deal: "#FBFFF6",
  bid: "#FFFFF1",
  client: "#FFF6F6",
  provider: "#F6F6FF",
  background: "#FFFFFF",
  text: "#242222",
};
