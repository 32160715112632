import React from "react";
import "./Referrals.css";
function Referrals(props) {
    return (
        <div
            className="container"
            style={{
                marginTop: "30px",
                padding: "30px 40px",
                border: "1px #ccc solid",
                borderRadius: "5px",
            }}
        >
            <h1 classname="alt tos-title">Somadha&#39;s Referral Policy</h1>
            <p>
               Referral Policy
            </p>


        </div>
    );
}

export default Referrals;
