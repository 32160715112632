import React, { useState } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import { Link, useHistory } from 'react-router-dom';
import ErrorModal from '../../components/util/ErrorModal';
const Impersonate = () => {
  const [searchText, setSearchText] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const { error, clearError, isLoading, sendRequest } = useHttpClient();
  const history = useHistory();
  const searchForUser = async () => {
    if (searchText && searchText.length > 0) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_user/?email=${searchText}`
        );

        setSearchResult(responseData);
      } catch (err) {}
    }
  };
  const ImpersonateUser = async (id) => {
    if (searchText && searchText.length > 0) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/impersonate_user?user_id=${id}`
        );
        if (responseData.url) {
          history.push(responseData.url);
        }
      } catch (err) {}
    }
  };
  return (
    <div className='manage__container'>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>Impersonate</h3>
      <div className='manage__box pink'>
        <p className='pbox'>Explain what this feature is</p>

        <div className='adsearch__box'>
          <span>Search User</span>
          <input
            type='text'
            placeholder='Type Email...'
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <button className='search__btn' onClick={searchForUser}>
            <img src='/assets/right_arrow.svg' alt='' />
          </button>
          <button className='search__btn'>
            <img
              onClick={() => {
                setSearchText('');
              }}
              src='/assets/reload.svg'
              alt=''
            />
          </button>
        </div>
        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td> Name</td>
              <td>Email</td>
              <td>Role</td>
              <td></td>
            </tr>
            {searchResult && (
              <tr className='t_bottom'>
                <td>{searchResult.fullname}</td>
                <td>{searchResult.email}</td>
                <td>{searchResult.user_type}</td>
                <td className='last_td_imp'>
                  <Link target='_blank' to={`/profile/${searchResult._id}`}>
                    <u>visit profile</u>
                  </Link>
                  <u
                    className='imp_text'
                    onClick={() => {
                      ImpersonateUser(searchResult._id);
                    }}
                  >
                    impersonate
                  </u>
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Impersonate;
