import React, { useReducer, useEffect } from "react";
import "./InputBid.css";
import { validate } from "../../../util/Validators";

//hook for managing input and validations
const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
const InputBid = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  });

  //send data to the place i use input
  const { id, onInput } = props;
  const { value, isValid } = inputState;
  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };
  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };
  const element =
    props.element === "input" ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    ) : (
      <textarea
        id={props.id}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        placeholder={props.placeholder}
      />
    );

  const selectorElement = (
    <select
      className="select_box"
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      onChange={changeHandler}
      onBlur={touchHandler}
      value={inputState.value}
    >
      <option className="lebel_bold" label={props.optionText}></option>

      {props.options &&
        props.options.length > 0 &&
        props.options.map((option) => {
          return <option value={option.name}>{option.name}</option>;
        })}
    </select>
  );
  return (
    <div
      className={`${props.editor ? "form--editor" : "form--control"} ${
        props.large ? "form--large" : ""
      }
      ${props.grey ? "form--grey" : ""} ${
        !inputState.isValid && inputState.isTouched && "form--control--invalid"
      }`}
    >
      <div className="lebel--container">
        <label style={{ fontWeight: "bold" }} htmlFor={props.id}>
          {props.label}
        </label>
        <p className="sub--label">{props.subLabel}</p>
      </div>
      <div className="box_bot" style={{ marginTop: "-15px" }}>
        {props.select ? selectorElement : element}
        {!inputState.isValid && inputState.isTouched && (
          <p>{props.errorText}</p>
        )}
      </div>
    </div>
  );
};
export default InputBid;
