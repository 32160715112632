import React, { useEffect } from "react";
import PostJob from "../sections/post/PostJob";
const Post = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PostJob direct />
    </div>
  );
};

export default Post;
