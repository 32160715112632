import React, { useContext, useEffect, useState } from "react";
import "./jobPostCard.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";

function JobPostCard({ jobPost }) {
  const auth = useContext(AuthContext);
  const [state, setState] = useState();
  const [bids, setBids] = useState();

  useEffect(() => {
    setState(jobPost?.state);
    const number = jobPost?.bids?.length;
    setBids(number);
    console.log(jobPost);
  }, [jobPost]);

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <Link to={`/job-details/${jobPost._id}`}>
      <div className="row jcard">
        <div className="col-lg-9 col-sm-12">
          <p>
            <strong>{jobPost.title}</strong>
          </p>
          <p>
            {auth.language === "en" ? "id: " : "আইডি: "} {jobPost._id}
          </p>
          <div className="d-flex flex-row">
            <div style={{ marginRight: "15px" }}>
              <p>
                <i className="fab fa-gg-circle"></i>{" "}
                {(jobPost.parent_category.length > 0
                  ? jobPost.parent_category + " - "
                  : "") + jobPost.category[0]}
              </p>
            </div>
            <div style={{ marginRight: "15px" }}>
              <p>
                <i className="far fa-clock"></i>{" "}
                {auth.language === "en" ? "Posted On" : "পোস্ট করা হয়েছে "}{" "}
                {" " +
                  new Date(jobPost.createdAt).toLocaleDateString(
                    undefined,
                    dateOptions
                  )}
              </p>
            </div>
            <div>
              <p>
                <i className="fas fa-search-dollar"></i>{" "}
                {auth.language === "en" ? "Budget " : "বাজেট "}{" "}
                {jobPost.max_budget}
                {auth.language === "en" ? "BDT " : " টাকা "}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <Link to={`/job-details/${jobPost._id}`}>
                {/* <button className="bttn-blue" type=""> */}
                <span className="bttn-blue d-grid gap-2">
                  {auth.language === "en" ? "View Details " : "বিস্তারিত দেখুন"}
                </span>
                {/* </button> */}
              </Link>
            </div>
            {!jobPost.closed_by_admin && (
              <div className="col-lg-6 col-sm-12">
                <a href={`/edit-post/${jobPost._id}`}>
                  <span className="bttn-white d-grid gap-2">
                    {auth.language === "en"
                      ? "Edit Post "
                      : "পোস্ট সম্পাদনা করুন "}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-sm-12 ">
          {jobPost.is_approved_by_admin ? (
            <div className="col-md-12 postStatusA">
              <strong>
                {auth.language === "en" ? "Approved " : "অনুমোদিত "}
              </strong>
            </div>
          ) : (
            <div className="col-md-12 postStatusN">
              <strong>
                {auth.language === "en" ? "Not Approved " : "অনুমোদিত না "}
              </strong>
            </div>
          )}
          {
            <div
              className="bidsPlaced"
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
            >
              {state === "BIDDING" &&
                (auth.language === "en" ? state : "দরপত্র আহ্বান")}
              {jobPost?.deals?.length > 0 &&
                (auth.language === "en" ? "Deal Ongoing" : "চুক্তি শুরু হয়েছে")}
            </div>
          }
          {jobPost?.deals?.length === 0 && (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#2A40E8",
              }}
            >
              {auth.language === "en"
                ? "Total Bid Placed"
                : "মোট দরপত্র স্থাপিত হয়েছে"}{" "}
              : {bids}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default JobPostCard;
