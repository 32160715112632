import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import "./DisplayProviderCard.css";

const DisplayProviderCard = ({ provider }) => {
  const auth = useContext(AuthContext);

  return (
    <div
      className="py-3 px-2 provider-card d-flex justify-content-between align-items-start flex-column"
      style={{
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="d-flex  align-items-start">
        <img
          src={provider?.profile_pic || "/assets/top-img.png"}
          alt="profile pic"
          className="me-2"
          width="110px"
          height="110px"
        />
        <div className="ms-2">
          <p style={{ color: "var(--grey_two)" }}>
            {auth.language === "en" ? "Success Ratio " : "সাফল্যের অনুপাত "}
            <span style={{ color: "var(--grey_one)" }}>
              {Number(provider.success_ratio)}
              {"%"}
            </span>
          </p>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            <p
              style={{ margin: "0px", color: "var(--grey_two)" }}
              className="me-2"
            >
              {auth.language === "en"
                ? "Provider Rating"
                : "ফ্রিল্যান্সার রেটিং"}
            </p>
            <div className="d-flex align-items-center justify-content-start">
              <p style={{ margin: "0px", color: "var(--grey_one)" }}>
                {" "}
                {provider?.provider_rating.toFixed(2)} {"  "}
              </p>
              <img src="/assets/rater-start.png" alt="" className="ms-2" />
            </div>
          </div>
          {provider?.location && (
            <p style={{ color: "var(--grey_two)" }}>
              <img src="/assets/location.png" alt="" /> {provider?.location}
            </p>
          )}
        </div>
      </div>
      <div>
        <h2
          style={{
            marginTop: "1rem",
            fontWeight: "bold",
            color: "var(--grey_one)",
          }}
        >
          {provider?.fullname}
        </h2>
        {provider?.tagline && (
          <h4 className="mb-2" style={{ color: "var(--grey_two)" }}>
            {provider?.tagline}
          </h4>
        )}
      </div>
      <button className="btn prime-btn mt-1 d-block" style={{ width: "100%" }}>
        <Link to={`/profile/${provider?._id}`}>
          <span style={{ color: "var(--text)" }}>
            {auth.language === "en" ? "View Profile" : "বৃত্তান্ত দেখুন"}
          </span>
        </Link>
      </button>
    </div>
  );
};

export default DisplayProviderCard;
