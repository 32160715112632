import React from "react";
import { Link } from "react-router-dom";
import "./CatalogCard.css";
import "../../public/assets/study.svg";
const CatalogCard = (props) => {
  return (
    <Link style={{ textDecoration: "none" }} to={props.to}>
      {/* `url('${props.imgSource}')` */}
      <div
        className="card"
        style={{
          // backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .1), rgba(0,0,0, 1)));`,
          backgroundColor: "var(--primary_light_color)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "4px",
        }}
      >
        {props.imgSource ? (
          <img
            src={props.imgSource}
            alt="Service"
            width="100%"
            height="100%"
            style={{
              aspectRatio: "1.5/1",
              filter: "contrast(80%) brightness(80%)",
              borderRadius: "4px",
            }}
          />
        ) : (
          <div
            style={{
              aspectRatio: "1.5/1",
              filter: "contrast(70%) brightness(50%)",
              backgroundColor: "var(--primary_color)",
              height: "100%",
              width: "100%",
              borderRadius: "4px",
            }}
          ></div>
        )}
        <p
          style={{
            color: "white",
            lineHeight: "1.25",
            fontSize: "30px",
            fontWeight: "normal",
            margin: "auto",
            position: "absolute",
            zIndex: 10,
            textAlign: "center",
          }}
        >
          {props.text}
        </p>
        {/* <p
          style={{
            color: 'var(--primary_light_color)',
            fontSize: '18px',
            margin: '0',
            position: 'absolute',

            bottom: '2.8rem',
            backgroundColor: 'var(--primary_color)',
            padding: '.1rem 1rem',
            borderRadius: '4px',
            zIndex: 10,
          }}
        >
          {props?.jobs_posted || 0}
        </p> */}
        <div
          className="shade"
          style={{
            aspectRatio: "1.5/1",
            filter: "contrast(70%) brightness(50%)",
          }}
        ></div>
      </div>
    </Link>
  );
};

export default CatalogCard;
