import React, { Fragment, useState } from "react";
import "./ClientPrintDealDetails.css";
import Dropdown from "./Dropdown/Dropdown";
import RateUser from "./modals/Profile/RateUser";
import ReportIssue from "./modals/Profile/ReportIssue";
import SuccessModal from "./util/SuccessModal";

export default function ClientDetailsDealDetails(props) {
  const [showRateModal, setShowRateModal] = useState(false);
  const [showRateSuccess, setShowRateSuccess] = useState(false);
  const [showIssueSubmitModal, setShowIssueSubmitModal] = useState(false);
  const [rePortIssueModal, setRePortIssueModal] = useState(false);

  const onCancel = () => {
    setShowIssueSubmitModal(false);
  };

  const onSuccess = () => {
    setRePortIssueModal(true);
  };
  return (
    <Fragment>
      <RateUser
        show={showRateModal}
        clientId={props.property?.client_id?._id}
        providerId={props.property?.provider_id?._id}
        provider={props.property?.provider_id}
        deal_id={props?.deal_id}
        onSuccess={() => {
          setShowRateSuccess(true);
        }}
        onCancel={() => {
          setShowRateModal(false);
        }}
      />
      <SuccessModal
        message="Rating was successful. Thank you for your feedback."
        show={showRateSuccess}
        onClear={() => {
          setShowRateSuccess(false);
        }}
      />
      <ReportIssue
        show={showIssueSubmitModal}
        // deal_id={props.dealId}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />

      <SuccessModal
        message="Report was successful. Thank you for your feedback."
        show={rePortIssueModal}
        onClear={() => {
          setRePortIssueModal(false);
        }}
      />
      <div className="clientPrintDealDetails__clientDetails">
        <h3>Client Details</h3>
        <div className="clientPrintDealDetails__divider" />
        <p>Bidders who confirmed your deal after you accepted their bids.</p>

        <div className="clientPrintDealDetails__clientDetailsContainer">
          <h4>
            Client Details{" "}
            <span>
              <Dropdown
                options={[
                  {
                    label: "Rate this Provider",
                    onClick: (e) => {
                      e.stopPropagation();
                      setShowRateModal(true);
                    },
                  },
                  {
                    label: "Report this Provider",
                    onClick: (e) => {
                      e.stopPropagation();
                      setShowIssueSubmitModal(true);
                    },
                  },
                ]}
              />
            </span>
          </h4>
          <div>
            <img
              src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars.png"
              alt=""
            />
            <span>
              <p>
                Client: <strong>{props.property?.client_id?.fullname}</strong>
              </p>
              <p>
                Client Rating:{" "}
                <strong>{props.property?.client_id?.client_rating}/5.00</strong>
              </p>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
