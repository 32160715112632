import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/button/Button";
import Input from "../../components/input/Input";
import ErrorModal from "../../components/util/ErrorModal";
import FileUploader from "../../components/util/FileUploader";
import LoadingSpinner from "../../components/util/LoadingSpinner";
import SuccessModal from "../../components/util/SuccessModal";
import Tooltip from "../../components/util/Tooltip";
import { AuthContext } from "../../context/auth-context";
import { useForm } from "../../hooks/form-hook";
import { useHttpClient } from "../../hooks/http-hook";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "../../util/Validators";
import "./PostJob.css";
import axios from "axios";
import { BUCKET_URL,S3_PREASSIGNED_POST_URL,CLOUDFRONT_URL } from "../../config";
import { toast } from "react-toastify";

const paperOptions = [
  "A0",
  "A1",
  "A2",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "letter",
  "legal",
];
const PostJob = (props) => {
  const auth = useContext(AuthContext);
  const { providerID } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  let history = useHistory();
  const [files, setFiles] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [print, setPrint] = useState(props.paper ? true : false);
  const [paper, setPaper] = useState(props.paper ? props.paper : "");
  const [addr, setAddr] = useState(props.address ? props.address : "");
  const [bw, setBw] = useState(props.isBW ? props.isBW : false);
  const [double, setDouble] = useState(props.isDP ? props.isDP : false);
  const [successModal, setSuccessModal] = useState(false);
  const [print_deadline, setPrint_deadline] = useState(false);
  const [successModalEdit, setSuccessModalEdit] = useState(false);
  const [successModalPayment, setSuccessModalPayment] = useState(false);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);


  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      category: {
        value: "",
        isValid: false,
      },
      subcategory: {
        value: "",
        isValid: true,
      },
      budget: {
        value: "",
        isValid: false,
      },
      deadline: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  window.addEventListener("storage", function (e) {
    if (e.key === "P_AD") {
      setSuccessModalPayment(true);
    }
  });

  // let selectedCategory;
  // const [selectedCategory, setSelectedCategory] = useState('')
  const selectedCategory = auth.categories.data.find(
    (cat) => cat.name === formState.inputs.category.value
  );
  useEffect(() => {
    if (formState.inputs.category) {
    }
  }, [formState, auth.categories, selectedCategory]);

  useEffect(() => {
    const fetchSubOptions = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/get_subcategories/?name=${formState.inputs.category.value}`
        );
        setSubCategoryOptions(responseData);
      } catch (err) {}
    };
    formState.inputs.category.value.length > 0 && fetchSubOptions();
    //eslint-disable-next-line
  }, [formState.inputs.category.value]);

  const handleEdit = async () => {
    try {
      const category = [
        formState.inputs.subcategory.value
          ? formState.inputs.subcategory.value
          : formState.inputs.category.value,
      ];
      if (formState.inputs.category.value === "print") {
        category.pop();
        category.push("print");
      }
      if (print) {
        category.push("print");
      }
      let dataToSend;
      print || formState.inputs.category.value === "print"
        ? (dataToSend = {
            title: formState.inputs.title.value,
            description: formState.inputs.description.value,
            category: category,
            max_budget: formState.inputs.budget.value,
            deadline: formState.inputs.deadline.value,
            paper: paper,
            mailing_address: addr,
            is_black_and_white: bw,
            is_double_page: double,
          })
        : (dataToSend = {
            title: formState.inputs.title.value,
            description: formState.inputs.description.value,
            category: category,
            max_budget: formState.inputs.budget.value,
            deadline: formState.inputs.deadline.value,
          });
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/client/update_job/?job_id=${props.id}`,
        "PUT",
        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        setSuccessModalEdit(true);
      }
    } catch (err) {}
  };
  const handleCancel = () => {
    history.goBack();
  };
  const handleDelete = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          "/client/delete_job/?job_id=" +
          props.id,
        "DELETE"
      );
      if (responseData.success === "yes") {
        history.goBack();
      }
    } catch (err) {}
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    try {
      const data = {};
      const files = [...files];
      let uploadAttachmentOverview;

      data.title = formState.inputs.title.value;
      data.description = formState.inputs.description.value;
      data.category[0] = formState.inputs.subcategory.value
        ? formState.inputs.subcategory.value
        : formState.inputs.category.value;

      data.max_budget = formState.inputs.max_budget.value;
      data.deadline = formState.inputs.deadline.value;

      if (files.length > 0) {
        for (const file of files) {
          const baseformat = convertFileToBase64(file);
          files.push(baseformat);
        }

        uploadAttachmentOverview = await axios.post(BUCKET_URL, {
          file: files,
        });
      }

      if (selectedCategory.print) {
        data.raw = true;
        data.print_deadline = print_deadline;
        data.delivery = true;
        data.paper = paper;
        data.is_black_and_white = bw;
        data.is_double_page = double;
        data.mailing_address = addr;
      }

      if (selectedCategory.delivery) {
        data.raw = true;
        data.mailing_address = addr;
      }

      if (print || formState.inputs.category.value === "print") {
        data.raw = true;
        data.print_deadline = print_deadline;
        data.delivery = true;
        data.paper = paper;
        data.is_black_and_white = bw;
        data.is_double_page = double;
        data.mailing_address = addr;
      }

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/client/create_job",
        "POST",
        data
      );
      if (responseData.success === "yes") {
        setSuccessModal(true);
      }
    } catch (err) {}
  };

  const createDirectDeal = async () => {
    try {
      const data = {};
      const files = [...files];

      data.provider_id = providerID;
      data.title = formState.inputs.title.value;
      data.description = formState.inputs.description.value;
      data.category[0] =
        formState.inputs.subcategory.value || formState.inputs.category.value;
      data.max_budget = formState.inputs.budget.value;
      data.deadline = formState.inputs.deadline.value;

      // Handle file uploads
      if (files?.length > 0) {
        const fileUrls = [];
        for (const file of files) {
          try {
            const data = await axios.get(
              `${S3_PREASSIGNED_POST_URL}?size=${globalSettings?.max_file_size}&fileExtension=${file.name.split('.').pop()}`
            );
  
            const formData = new FormData();
            Object.entries(data.data[0].fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", file);
  
            const uploadResponse = await axios.post(BUCKET_URL, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
  
            fileUrls.push(CLOUDFRONT_URL + `${data.data[0].fields.Key}`);
          } catch (uploadError) {
            toast.error("Error during file upload:", error);
            return;
          }
        }
  
        data.attachments = [...fileUrls];
      }
  

      // Handle print category
      if (print || formState.inputs.category.value === "print") {
        data.paper = paper;
        data.is_black_and_white = bw;
        data.is_double_page = double;
        data.mailing_address = addr;
        data.category[1] = "print";
      }

      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/client/initiate_direct_deal/",
        "POST",
        data
      );

      if (responseData.ssl_url) {
        window
          .open(
            responseData.ssl_url,
            "",
            ` scrollbars=yes,menubar=no,height=680,width=520,left=${
              window.screen.width / 2 - 260
            }top=${
              window.screen.height / 2 - 340
            }resizable=yes,toolbar=no,location=no,status=no`
          )
          .focus();
      }
    } catch (err) {
      // Handle errors
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <SuccessModal
        onClear={() => {
          setSuccessModal(false);
          history.push("/explore/all");
        }}
        show={successModal}
        message="Job was posted successfully. Wait for admin to approve this post."
      />
      <SuccessModal
        onClear={() => {
          setSuccessModalEdit(false);
          history.goBack();
        }}
        show={successModalEdit}
        message="Job was updated successfully."
      />
      <SuccessModal
        onClear={() => {
          setSuccessModalPayment(false);
          history.goBack();
        }}
        show={successModalPayment}
        message="Deal has been initiated successfully."
      />

      <div className="post--container">
        {isLoading && <LoadingSpinner asOverlay />}

        {props.editor && <h1>Edit Post</h1>}
        {!props.editor && !props.direct && <h1>Create a new Post</h1>}
        {props.direct && <h1>Create a Direct Deal</h1>}
        <img src="/assets/906794.svg" alt="" />
        <div className="input--wrapper">
          <Input
            editor
            onInput={inputHandler}
            element="input"
            id="title"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Title must be at least 6 characters."
            label="Work Title *"
            initialValue={props.editor && props.title}
            initialValid={props.editor}
            placeholder="Be concise and short, eg: logo for a Bakery Company"
          />

          <Input
            editor
            large
            onInput={inputHandler}
            element="textarea"
            id="description"
            type="text"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(100)]}
            errorText="Description should be minimum 100 characters."
            initialValue={props.editor && props.description}
            initialValid={props.editor}
            label="Detailed Description *"
            subLabel=""
          />
          {!props.editor && (
            <div className="fileup__container">
              <div className="infoleft">
                <h3> attachment</h3>
              </div>
              <div className="inforight">
                <FileUploader
                  updatestate={(childFile) => {
                    setFiles(childFile);
                  }}
                />
                {files && <p>{files.length} files choosen</p>}
              </div>
            </div>
          )}
          <Input
            editor
            select
            optionText="Select a Category"
            onInput={inputHandler}
            options={auth.categories}
            id="category"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter category."
            label="Category *"
            subLabel=""
            initialValue={
              props.editor
                ? props.category.length > 0
                  ? props.category[0]
                  : props.subCategory[0]
                : ""
            }
            initialValid={props.editor}
          />
          {(subCategoryOptions.length > 0 ||
            (props.editor && !props.subCategory[0] === "print")) && (
            <Input
              editor
              select
              onInput={inputHandler}
              options={subCategoryOptions}
              id="subcategory"
              optionText="Select a Subcategory"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter subcategory."
              initialValue={props.editor && props.subCategory[0]}
              initialValid={true}
              label="Subcategory"
              subLabel=""
            />
          )}
          <Input
            editor
            onInput={inputHandler}
            element="input"
            id="budget"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter Max Budget."
            initialValue={props.editor && props.budget}
            initialValid={props.editor}
            label="Max Budget *"
            subLabel=""
          />
          <Input
            editor
            onInput={inputHandler}
            element="input"
            id="deadline"
            type="date"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter deadline."
            label="Deadline *"
            initialValue={props.editor && props.deadline.substring(0, 10)}
            initialValid={props.editor}
            subLabel=""
          />
        </div>

        {selectedCategory?.print && (
          <Fragment>
            <h5
              style={{
                marginBottom: "20px",
                textAlign: "center",
                marginTop: "10px",
                fontSize: "15px",
              }}
            >
              {" "}
              Printing and Delivery Both are Mandatory for this category
            </h5>
            <div className="print--details">
              <div className="input_group">
                <label htmlFor="paper">Paper</label>
                <select
                  id="paper"
                  className="select-filed"
                  type="text"
                  value={paper}
                  onChange={(e) => setPaper(e.target.value)}
                >
                  <option className="first__option" value="">
                    Select Paper Size
                  </option>
                  {paperOptions.map((paper) => {
                    return <option value={paper}>{paper}</option>;
                  })}
                </select>
              </div>
              <div className="input_group">
                <p>Printing Deadline</p>
                <input
                  className="input-field"
                  type="date"
                  value={print_deadline}
                  onChange={(e) => setPrint_deadline(e.target.value)}
                />
              </div>
              <div className="input_group">
                <p>Mailing Address</p>
                <textarea
                  className="textarea-field"
                  type="text"
                  value={addr}
                  onChange={(e) => {
                    setAddr(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="input_group_line">
                <p>Black and White</p>
                <input
                  className="input-field"
                  type="checkbox"
                  checked={bw}
                  onChange={(e) => {
                    setBw(e.target.checked);
                  }}
                ></input>
              </div>
              <div className="input_group_line">
                <p>Both side</p>
                <input
                  type="checkbox"
                  checked={double}
                  onChange={(e) => {
                    setDouble(e.target.checked);
                  }}
                ></input>
              </div>
            </div>
          </Fragment>
        )}
        {selectedCategory?.delivery && !selectedCategory?.print && (
          <Fragment>
            <h5
              style={{
                marginBottom: "20px",
                textAlign: "center",
                marginTop: "10px",
                fontSize: "15px",
              }}
            >
              {" "}
              Delivery is Mandatory for this category
            </h5>
            <div className="print--details">
              <div className="input_group">
                <p>Mailing Address</p>
                <textarea
                  className="textarea-field"
                  type="text"
                  value={addr}
                  onChange={(e) => {
                    setAddr(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </Fragment>
        )}
        <div className="footer--text">
          {!props.paper &&
            !selectedCategory?.print &&
            !selectedCategory?.delivery && <p>Physical printed copy?</p>}
          {!props.paper &&
            !selectedCategory?.print &&
            !selectedCategory?.delivery && (
              <input
                type="checkbox"
                checked={
                  formState.inputs.category.value === "print" ? true : print
                }
                onChange={(e) => {
                  setPrint(e.target.checked);
                }}
              ></input>
            )}
          {(formState.inputs.category.value === "print" || print) && (
            <p>enter print details below</p>
          )}
          {props.print && <p>enter print details below</p>}
        </div>
        {(formState.inputs.category.value === "print" || print) &&
          !props.print && (
            <div className="print--details">
              <div className="input_group">
                <label>Paper</label>
                <select
                  className="select-filed"
                  type="text"
                  value={paper}
                  onChange={(e) => setPaper(e.target.value)}
                >
                  <option className="first__option" value="">
                    Select Paper Size
                  </option>
                  {paperOptions.map((paper) => {
                    return <option value={paper}>{paper}</option>;
                  })}
                </select>
              </div>
              <div className="input_group">
                <p>Printing Deadline</p>
                <input
                  className="input-field"
                  type="date"
                  value={print_deadline}
                  onChange={(e) => setPrint_deadline(e.target.value)}
                />
              </div>
              <div className="input_group">
                <p>Mailing Address</p>
                <textarea
                  className="textarea-field"
                  type="text"
                  value={addr}
                  onChange={(e) => {
                    setAddr(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="input_group_line">
                <p>Black and White</p>
                <input
                  type="checkbox"
                  checked={bw}
                  onChange={(e) => {
                    setBw(e.target.checked);
                  }}
                ></input>
              </div>
              <div className="input_group_line">
                <p>Both side</p>
                <input
                  type="checkbox"
                  checked={double}
                  onChange={(e) => {
                    setDouble(e.target.checked);
                  }}
                ></input>
              </div>
            </div>
          )}
        <div className="footer--text">
          Your whole profile including your name, rating past history will be
          <br />
          visible to anyone who can view the posts
        </div>
        <div className="footer--btn">
          <div className="editor--btns">
            {props.editor && (
              <button
                onClick={handleCancel}
                className="btn__Cancel"
                color="red"
              >
                Cancel
              </button>
            )}
            {props.editor && (
              <button
                onClick={handleDelete}
                className="btn__delete"
                color="red"
              >
                Delete Post
              </button>
            )}

            {props.editor && (
              <button onClick={handleEdit} className="btn__save">
                Save Post
              </button>
            )}
          </div>
          {!props.editor && !props.direct && (
            <Button
              buttonStyle="btn--banner"
              onClick={handleSubmit}
              isDisabled={!formState.isValid}
            >
              {!formState.isValid ? (
                <Tooltip width="180px" tip="Please fill all the information">
                  Post This Job
                </Tooltip>
              ) : (
                "Post This Job"
              )}
            </Button>
          )}

          {props.direct && (
            <Button buttonStyle="btn--banner" onClick={createDirectDeal}>
              Create Deal
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostJob;
