// src/components/HomeComponent/Consultants/Consultants.js

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../context/auth-context";
import { Spinner } from "react-bootstrap";
import ConsultantCard from "../../../components/HomeComponent/Consultants/ConsultantCard";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../Pagination";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const Consultants = () => {
  const [allConsultants, setAllConsultants] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [total, setTotal] = useState(0);
  const auth = useContext(AuthContext);

  useEffect(() => {
    getAllConsultants();
  }, []);

  // Fetch all consultants

  const getAllConsultants = async () => {
    setSpinnerStatus(true);
    let url = `${process.env.REACT_APP_BACKEND_URL}/user/consultant_list`;

    try {
      const response = await axios.get(url);
      setAllConsultants(response.data);
      setTotal(response.data.total);

      setSpinnerStatus(false);
    } catch (error) {
      setSpinnerStatus(false);
      console.error(error);
      notify("err", "Failed to fetch consultants.");
    }
  };

  const optionTextStyle = {
    color: "var(--grey_one, #333)",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <ToastContainer />
      <div
        className="py-3 px-3"
        style={{
          maxWidth: "1440px",
          margin: "auto",
        }}
      >
        <p
          className="hide-on-pc"
          style={{
            ...optionTextStyle,
            fontSize: "28px",
            marginBottom: "10px",
          }}
        >
          {auth.language === "en" ? "Consultants" : "পরামর্শদাতা"}
        </p>
        <div className="my-3 d-flex justify-content-between align-items-start parent gap-3">
          <div style={{ width: "100%" }}>
            <p
              className="hide-on-mobile"
              style={{
                ...optionTextStyle,
                fontSize: "28px",
                marginBottom: "10px",
              }}
            >
              {auth.language === "en" ? "Consultants" : "পরামর্শদাতা"}
            </p>
            <label htmlFor="" style={optionTextStyle}>
              {auth.language === "en"
                ? "Search a Consultant"
                : "নাম, ইমেল ইত্যাদি ব্যবহার করে পরামর্শদাতা অনুসন্ধান করুন।"}
            </label>
            <input
              type="text"
              style={{
                width: "100%",
                borderRadius: "4px",
                background: "white",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                padding: "15px",
              }}
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder={
                auth.language === "en"
                  ? "Search consultants using name, email etc."
                  : "নাম, ইমেল ইত্যাদি ব্যবহার করে পরামর্শদাতা অনুসন্ধান করুন।"
              }
              value={searchKey}
            />

            <Spinner
              animation="border"
              role="status"
              style={{
                display: spinnerStatus ? "block" : "none",
                margin: "10rem auto",
              }}
            />

            {!spinnerStatus && (
              <div className="py-5" style={{ width: "100%" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(275px, 1fr))",
                    gridGap: "10px",
                    margin: "20px auto",
                    width: "100%",
                  }}
                >
                  {allConsultants?.length > 0 ? (
                    allConsultants.map((dt, idx) => (
                      <ConsultantCard consultant={dt} key={idx} />
                    ))
                  ) : (
                    <h2 className="p-5 m-5 text-center text-secondary">
                      {auth.language === "en"
                        ? "No Data Found!"
                        : "কোন ডেটা পাওয়া যায়নি!"}
                    </h2>
                  )}
                </div>
              </div>
            )}

            <Pagination
              itemsPerPage={10}
              totalPage={total}
              paginate={paginate}
              pageNumber={currentPage}
              style={{ backgroundColor: "var(--primary_color)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultants;
