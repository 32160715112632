import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import Issue from '../../components/modals/admin/Issue';
import Paginator from '../../components/util/Paginator';
const timeOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
const Issues = () => {
  const [issues, setIssues] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const { isLoading, sendRequest } = useHttpClient();
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_unresolved/?page=${page}`
        );
        setIssues(responseData);
      } catch (err) {}
    };
    fetchIssues();
    //eslint-disable-next-line
  }, [refresh, page]);
  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}
      {issues && (
        <Issue
          show={showModal}
          issue={selectedIssue}
          onCancel={() => {
            setShowModal(false);
          }}
          onSuccess={() => {
            setRefresh(!refresh);
          }}
        ></Issue>
      )}
      <h3>Unresolved Issues</h3>
      <div className='manage__box pink'>
        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td> Issued By</td>
              <td>Issue Description</td>
              <td>Date-Time</td>
              <td></td>
              <td></td>
            </tr>
            {issues &&
              issues.map((issue) => {
                return (
                  <tr className='t_bottom'>
                    <td>{issue.user_id.fullname}</td>
                    <td>{issue.issue.substring(0, 30) + '...'}</td>
                    <td>
                      {new Date(issue.createdAt).toLocaleDateString(
                        undefined,
                        timeOptions
                      )}
                    </td>
                    <td>
                      <p
                        onClick={() => {
                          window.open(`/deal/${issue.deal_id}`).focus();
                        }}
                        className='view_issue'
                      >
                        view Deal
                      </p>
                    </td>
                    <td>
                      <p
                        onClick={() => {
                          setSelectedIssue(issue);
                          setShowModal(true);
                        }}
                        className='view_issue'
                      >
                        view Issue
                      </p>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default Issues;
