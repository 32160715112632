import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { CREATE_PROMOTIONS } from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import Modal from "../../shared/Modal/Modal";
import axios from "axios";
import Select from "../../shared/Select/Select";

export default function AddEditPromotionModal({
  open,
  toggle,
  promotion,
  setBulkSmsForm,
  bulkSmsForm,
}) {
  const queryClient = useQueryClient();
  const { handleSubmit, register, reset } = useForm();
  const { mutateAsync: createPromotionMutation } =
    useMutation(CREATE_PROMOTIONS);

  const handlePromotionCreate = useCallback(
    async (values) => {
      try {
        await createPromotionMutation(values);
        await queryClient.refetchQueries("GET_PROMOTIONS");
        toast.success("Successfully created a promotion!");
        reset();
        toggle();
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [toggle, createPromotionMutation, queryClient, reset]
  );

  const handleBulkSms = useCallback(async (data) => {
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/admin/send_bulk_sms",
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      toast.success("Bulk SMS sent successfully.");
      setBulkSmsForm(false);
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  }, []);

  const handlePromotionUpdate = useCallback(() => {}, []);

  return (
    <div style={{ marginBottom: "2rem", display: "flex", gap: "1rem" }}>
      <Button variant="primary" onClick={toggle}>
        Add Promotion
      </Button>
      <Button variant="primary" onClick={() => setBulkSmsForm(!bulkSmsForm)}>
        Bulk SMS
      </Button>

      <Modal
        open={open}
        onClose={toggle}
        title={`${!promotion ? "Add New" : "Update"} Promotion`}
      >
        <form
          onSubmit={handleSubmit(
            promotion ? handlePromotionUpdate : handlePromotionCreate
          )}
        >
          <Input
            label="English Title"
            type="string"
            {...register("title_eng")}
          />
          <Input label="Bangla Title" type="string" {...register("title_bn")} />
          <Input label="Start Date" type="date" {...register("start_date")} />
          <Input label="End Date" type="date" {...register("end_date")} />
          <Input
            label="Target Amount"
            type="number"
            {...register("target_amount")}
          />
          <Input
            label="Offered Point"
            type="number"
            {...register("offered_point")}
          />
          <label className="d-block mt-3">
            <strong>Target User</strong>
            <Select variant="ghost_white" {...register("target_user")}>
              <option value="">Select</option>
              <option value="CLIENT">Client</option>
              <option value="PROVIDER">Provider</option>
            </Select>
          </label>

          <br />
          <Button type="submit" variant="primary">
            Save
          </Button>
        </form>
      </Modal>

      <Modal
        open={bulkSmsForm}
        onClose={() => setBulkSmsForm(false)}
        title="Send Bulk SMS"
      >
        <form onSubmit={handleSubmit(handleBulkSms)}>
          <Input
            label="Phone Numbers"
            type="string"
            {...register("phone_numbers")}
          />
          <Input label="Message" type="string" {...register("message")} />
          <br />
          <Button type="submit" variant="primary">
            Save
          </Button>
        </form>
      </Modal>
    </div>
  );
}
