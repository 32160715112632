import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../footer/Footer';
import NewFooter from '../../footer/NewFooter';
import Navbar from '../../navigation/Navbar';
import NewNavBar from '../../navigation/NewNavBar';

export default function Page({ socket, children }) {
  return (
    <>
      <NewNavBar socket={socket} />
      {children}
      <NewFooter />
      {/* <Footer /> */}
    </>
  );
}
