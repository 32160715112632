/* eslint-disable no-unused-expressions */
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ADMIN_RESET_PASSWORD } from '../../../lib/api/admin';
import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Modal from "../../shared/Modal/Modal";

export default function AdminResetPasswordModal({ open, onClose, data }) {
  const { register, handleSubmit } = useForm();
  const { mutateAsync: resetPasswordMutation } = useMutation(ADMIN_RESET_PASSWORD);

  const resetPassword = useCallback(async (values) => {
    if (values.password !== values.repeat_password) return toast.error("Password's not matched!");
    try {
      await resetPasswordMutation({ user_id: data._id, values })
      toast.success("Successfully reset password!");
      onClose?.();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  }, [data, onClose, resetPasswordMutation]);

  return  (
    <Modal open={open} onClose={onClose} title="Reset Password">
      <form onSubmit={handleSubmit(resetPassword)}>
        <Input label="Password" type="password" {...register("password")} />
        <Input label="Confirm Password" type="password" {...register("repeat_password")} />

        <Button variant="primary" type="submit">RESET</Button>
      </form>
    </Modal>
  )
}