import React, { useState } from 'react';
import './EditExpertise.css';
import Modal from '../../util/Modal';

import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import FileUploader from '../../util/FileUploader';
import ModalButton from '../../button/ModalButton';
import ErrorModal from '../../util/ErrorModal';

const SubmitWork = (props) => {
  const [completeFiles, setCompleteFiles] = useState(null);
  const [previewFiles, setPreviewFiles] = useState(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const token = localStorage.getItem('x-access-token');

  console.log(token);


  const submit = async () => {
    try {
      const formData = new FormData();
      for (const file of completeFiles) {
        formData.append('attachments', file);
      }
      for (const file of previewFiles) {
        formData.append('attachments_overview', file);
      }
      !props.submitted && formData.append('deal_id', props.dealId);
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/provider/${
            props.submitted ? 'resubmit_job' : 'submit_job'
          }/?deal_id=${props.dealId}`,
        'POST',
        formData,
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      //maybe check for response data
      if (responseData.success === 'yes') {
        props.onCancel();
        props.onSuccess();
      }
      setCompleteFiles(null);
      setPreviewFiles(null);
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}

      <div className='edit_exp_container '>
        <img
          className='exit_edit'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2 className='submit_header'>Submit Work</h2>
        <div className='emp_p'>
          <p>Submit Preview Work</p>
          <p className='submit_info'>
            Submit a part of your work here, keep it incomplete or blurred out
            or anyting you feel should give the client an idea about the quality
            of your work. Until Client completes the payment, we will show the
            client only this preview for the shake of Payment Ensurance...
          </p>

          <FileUploader
            updatestate={(childFile) => {
              setPreviewFiles(childFile);
            }}
          />
        </div>{' '}
        {previewFiles && (
          <p className='files__choosen'>{previewFiles.length} files choosen</p>
        )}
        <div className='emp_p'>
          <p>Submit Complete Work</p>
          <p className='submit_info'>
            This is the final work client will be getting after he/she has
            completed his payment. Remember, you getting paid depends on the
            client paying the system.
          </p>
          <FileUploader
            updatestate={(childFile) => {
              setCompleteFiles(childFile);
            }}
          />
        </div>
        {completeFiles && (
          <p className='files__choosen'>{completeFiles.length} files choosen</p>
        )}
        <div className='work__submitbtn'>
          <ModalButton onClick={submit}>Submit</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitWork;
