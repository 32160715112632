import React, { useEffect, useState } from 'react';
import './Privacy.css';
import axios from 'axios';
import Editor from '../../../components/Editor/Editor';

function Privacy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/static-fields`)
      .then(function (response) {
        // handle success

        const obj = response.data.privacy_policy;

        setPrivacy(obj);
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  return (
    <div
      className='container'
      style={{
        marginTop: '30px',
        padding: '30px 40px',
        border: '1px #ccc solid',
        borderRadius: '5px',
      }}
    >
      <Editor readOnly defaultValue={privacy} />
    </div>
  );
}

export default Privacy;
