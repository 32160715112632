import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { useHttpClient } from "../../hooks/http-hook";

const ApplicationModal = ({
  user,
  language,
  university,
  setUniversityData,
  preData,
}) => {
  const [universitySelections, setUniversitySelections] = useState(
    preData || [{ id: Date.now() }]
  );
  const [universities, setUniversities] = useState([]);
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    setUniversities(user?.universities || []);
  }, [user]);

  useEffect(() => {
    setUniversityData(universitySelections);
  }, [universitySelections]);

  const handleAddUniversity = () => {
    setUniversitySelections([...universitySelections, { id: Date.now() }]);
  };

  const handleRemoveUniversity = (id) => {
    setUniversitySelections(universitySelections.filter((u) => u.id !== id));
  };

  const handleSelectionChange = (id, field, value) => {
    const updatedSelections = universitySelections.map((u) =>
      u.id === id ? { ...u, [field]: value } : u
    );
    setUniversitySelections(updatedSelections);
  };

  const styles = {
    title: { fontSize: "1.5em", fontWeight: "bold", marginBottom: "20px" },
    field: { width: "100%", padding: "10px", marginBottom: "10px" },
    btn: {
      backgroundColor: "#2A41E8",
      color: "#fff",
      padding: "10px 20px",
      cursor: "pointer",
    },
  };

  return (
    <div className="form-section uni_dropdown">
      <p style={styles.title}>
        {language === "en"
          ? "Select Universities"
          : "বিশ্ববিদ্যালয় নির্বাচন করুন"}
      </p>

      {universitySelections.map((selection) => (
        <div
          key={selection.id}
          style={{
            marginBottom: "20px",
            backgroundColor: "#F9F9F9",
            border: "1px solid rgba(0,0,0,.1)",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {/* Country Select */}
            <select
              value={selection.country || ""}
              required
              onChange={(e) =>
                handleSelectionChange(selection.id, "country", e.target.value)
              }
            >
              <option value="">Select Country</option>
              {universities?.map((u, idx) => (
                <option key={idx} value={u.country[0]?.name}>
                  {u.country[0]?.name}
                </option>
              ))}
            </select>

            {/* Location Select */}
            <select
              value={selection.location || ""}
              required
              onChange={(e) =>
                handleSelectionChange(selection.id, "location", e.target.value)
              }
              disabled={!selection.country}
            >
              <option value="">Select Location</option>
              {universities
                ?.find((u) => u.country[0]?.name === selection.country)
                ?.country[0]?.location?.map((loc, idx) => (
                  <option key={idx} value={loc?.name}>
                    {loc?.name}
                  </option>
                ))}
            </select>

            {/* Varsity Select */}
            <select
              value={selection.varsity || ""}
              required
              onChange={(e) =>
                handleSelectionChange(selection.id, "varsity", e.target.value)
              }
              disabled={!selection.location}
            >
              <option value="">Select Varsity</option>
              {universities
                ?.find((u) => u.country[0]?.name === selection.country)
                ?.country[0]?.location.find(
                  (l) => l?.name === selection.location
                )
                ?.varsity.map((v, idx) => (
                  <option key={idx} value={v?.name}>
                    {v?.name}
                  </option>
                ))}
            </select>

            {/* Major Select */}
            <select
              value={selection.major || ""}
              required
              onChange={(e) =>
                handleSelectionChange(selection.id, "major", e.target.value)
              }
              disabled={!selection.varsity}
            >
              <option value="">Select Major</option>
              {universities
                ?.find((u) => u.country[0]?.name === selection.country)
                ?.country[0]?.location.find(
                  (l) => l.name === selection.location
                )
                ?.varsity.find((v) => v.name === selection.varsity)
                ?.majors.map((m, idx) => (
                  <option key={idx} value={m?.name}>
                    {m?.name}
                  </option>
                ))}
            </select>

            {/* Program Select */}
            <select
              value={selection.program || ""}
              required
              onChange={(e) =>
                handleSelectionChange(selection.id, "program", e.target.value)
              }
              disabled={!selection.major}
            >
              <option value="">Select Program</option>
              {universities
                ?.find((u) => u.country[0]?.name === selection.country)
                ?.country[0]?.location.find(
                  (l) => l.name === selection.location
                )
                ?.varsity.find((v) => v.name === selection.varsity)
                ?.majors.find((m) => m.name === selection.major)
                ?.programs.map((p, idx) => (
                  <option key={idx} value={p}>
                    {p}
                  </option>
                ))}
            </select>

            {/* Session Input */}
            <input
              type="text"
              placeholder="Session"
              required
              value={selection.session || ""}
              onChange={(e) =>
                handleSelectionChange(selection.id, "session", e.target.value)
              }
              style={{ padding: "5px", width: "120px" }}
            />

            {/* Intake Input */}
            <input
              type="text"
              placeholder="Intake"
              required
              value={selection.intake || ""}
              onChange={(e) =>
                handleSelectionChange(selection.id, "intake", e.target.value)
              }
              style={{ padding: "5px", width: "120px" }}
            />
            <div className="cross-btn">
              {/* Remove Button */}
              <AiOutlineClose
                size={24}
                color="red"
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveUniversity(selection.id)}
              />
            </div>
          </div>
        </div>
      ))}

      <button style={styles.btn} onClick={handleAddUniversity}>
        {language === "en"
          ? "Add University"
          : "অন্য একটি বিশ্ববিদ্যালয় যোগ করুন"}
      </button>
    </div>
  );
};

export default ApplicationModal;
