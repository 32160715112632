import React from 'react';
import './Message.css';
const Message = (props) => {
  const timeOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return (
    <React.Fragment>
      <div
        className={
          props.me ? 'message__me msg_thread' : 'message__other msg_thread'
        }
        style={{border:props.new?"1px solid red":null}}
      >
        {props.text}
      </div>
      <p
        className={
          props.me ? 'date__me date_thread' : 'date__other date_thread'
        }
      >
        {new Date(props.timestamp)
          .toLocaleDateString(undefined, timeOptions)
          .toLowerCase()}
      </p>
    </React.Fragment>
  );
};

export default Message;
