import React, { useEffect } from "react";
import GetPayments from "../sections/admin/GetPayments";

const GetSuccessfulPayments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <GetPayments />;
};

export default GetSuccessfulPayments;
