import React from "react";
import "./AuthForm.css";
import Input from "../input/Input";
import { Button } from "../button/Button";
import {
  VALIDATOR_CONFIRMPASS,
  VALIDATOR_MINLENGTH,
} from "../../util/Validators";
import { useForm } from "../../hooks/form-hook";
import ErrorModal from "../util/ErrorModal";
import LoadingSpinner from "../util/LoadingSpinner";
import { useHttpClient } from "../../hooks/http-hook";
import Texts from "../../util/Texts";
import { useHistory } from "react-router";

const SetNewPassword = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const texts = Texts();
  const history = useHistory();
  const [formState, inputHandler] = useForm(
    {
      password: {
        value: "",
        isValid: false,
      },
      confirm_password: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const setPassword = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          "/user/set_password_for_new_oauth_user",
        "PUT",
        JSON.stringify({
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        history.push("/");
      }
    } catch (err) {}
  };

  const resetComponent = (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth--wrapper">
        {isLoading && <LoadingSpinner asOverlay />}
        <h2>You need to enter a new password to continue</h2>
        <form>
          <Input
            element="input"
            id="password"
            type="password"
            label="New Password"
            validators={[VALIDATOR_MINLENGTH(8)]}
            errorText={texts.errPass}
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="confirm_password"
            type="password"
            label={texts.confirmPass}
            validators={[
              VALIDATOR_CONFIRMPASS(formState.inputs.password.value),
            ]}
            errorText={texts.errPassNoMatch}
            onInput={inputHandler}
          />
          <div className="button--wrapper">
            <Button
              buttonStyle="btn--auth"
              onClick={setPassword}
              isDisabled={!formState.isValid}
              type="submit"
            >
              Set New Password
            </Button>
          </div>
        </form>
        <div className="space__eu"></div>
      </div>
    </React.Fragment>
  );

  return <div>{resetComponent}</div>;
};

export default SetNewPassword;
