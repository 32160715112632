import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import axios from "axios";
import { MdClose } from "react-icons/md";

const initialData = [
  {
    country: [
      {
        name: "",
        location: [
          {
            name: "",
            varsity: [
              {
                name: "",
                majors: [
                  {
                    name: "",
                    programs: [""],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default function DynamicForm() {
  const [data, setData] = useState(initialData);
  const auth = useContext(AuthContext);
  const language = auth.language;
  const [password, setPassword] = useState("");
  const { sendRequest } = useHttpClient();
  const [confirmModal, setConfirmModal] = useState(false);

  const structuredClone = (obj) => JSON.parse(JSON.stringify(obj));

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const resUser = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/user",
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      setData(resUser.data.universities);
    } catch (err) {
      console.log(err.message);
    }
  };

  const update = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/update_info`,
        "POST",
        JSON.stringify({
          universities: data,
          password: password,
        }), // Send the updated data
        { "Content-Type": "application/json" }
      );

      if (responseData.success === "yes") {
        setPassword("");
        setConfirmModal(false);
        toast.success("Profile updated successfully!");
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateState = (path, value) => {
    const newData = structuredClone(data); // Create a deep copy of the current data
    let obj = newData; // Start with the newData object

    // Navigate through the object based on the path
    path.forEach((key, index) => {
      if (index === path.length - 1) {
        // If it's the last key in the path, update the value
        obj[key] = value;
      } else {
        // Otherwise, move deeper into the structure
        obj = obj[key];
      }
    });

    setData(newData); // Update the state with the modified data
  };

  const addCountry = () => {
    setData((prev) => [
      ...prev,
      {
        country: [
          {
            name: "",
            location: [
              {
                name: "",
                varsity: [
                  {
                    name: "",
                    majors: [
                      {
                        name: "",
                        programs: [""],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ]);
  };

  const addLocation = (idx, countryIdx) => {
    const newData = structuredClone(data);
    newData[idx].country[countryIdx].location.push({
      name: "",
      varsity: [],
    });
    setData(newData);
  };

  const addVarsity = (idx, countryIdx, locIdx) => {
    const newData = structuredClone(data);
    newData[idx].country[countryIdx].location[locIdx].varsity.push({
      name: "",
      majors: [],
    });
    setData(newData);
  };

  const addMajor = (idx, countryIdx, locIdx, varIdx) => {
    const newData = structuredClone(data);
    newData[idx].country[countryIdx].location[locIdx].varsity[
      varIdx
    ].majors.push({ name: "", programs: [""] });
    setData(newData);
  };

  const addProgram = (idx, countryIdx, locIdx, varIdx, majorIdx) => {
    const newData = structuredClone(data);
    newData[idx].country[countryIdx].location[locIdx].varsity[varIdx].majors[
      majorIdx
    ].programs.push("");
    setData(newData);
  };

  const deleteItem = (path) => {
    // Log the current data before modification
    console.log("Before deletion:", data, path);

    // Clone the original data
    const newData = structuredClone(data);

    // Check if the deletion is at the country level
    if (path.length==2) {
      newData.splice(path[0], 1);
    } else {
      // For other deletions (not at the country level)
      let parent = newData;

      // Traverse to the parent of the item to delete
      path.slice(0, -1).forEach((key) => {
        parent = parent[key]; // Move to the next level
      });

      // Delete the item at the specified index
      const itemIndex = path.at(-1);
      if (
        Array.isArray(parent) &&
        itemIndex >= 0 &&
        itemIndex < parent.length
      ) {
        parent.splice(itemIndex, 1); // Ensure we're deleting a valid index
        console.log("Deleted item at index", itemIndex);
      }
    }

    // Set the new data state
    setData(newData);

    // Log the new data after modification
    console.log("After deletion:", newData);
  };

  return (
    <div
      className="form-container"
      style={{
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <h1>University Form</h1>
      {data.map((uni, idx) =>
        uni.country.map((c, countryIdx) => (
          <div
            key={`${idx}-${countryIdx}`}
            style={{
              backgroundColor: "#e8f0fe",
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <input
                type="text"
                placeholder="Country Name"
                value={c.name}
                onChange={(e) =>
                  updateState(
                    [idx, "country", countryIdx, "name"],
                    e.target.value
                  )
                }
              />
              <button
                onClick={() => deleteItem([idx, "country"])}
                style={{
                  background: "none",
                  border: "none",
                  color: "maroon",
                  cursor: "pointer",
                }}
              >
                <MdClose />
              </button>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => addLocation(idx, countryIdx)}
            >
              Add Location
            </button>
            {c.location.map((loc, locIdx) => (
              <div
                key={locIdx}
                style={{
                  backgroundColor: "#d0e9fc",
                  padding: "10px",
                  margin: "10px 0",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Location Name"
                    value={loc.name}
                    onChange={(e) =>
                      updateState(
                        [
                          idx,
                          "country",
                          countryIdx,
                          "location",
                          locIdx,
                          "name",
                        ],
                        e.target.value
                      )
                    }
                  />
                  <button
                    onClick={() =>
                      deleteItem([
                        idx,
                        "country",
                        countryIdx,
                        "location",
                        locIdx,
                      ])
                    }
                    style={{
                      background: "none",
                      border: "none",
                      color: "maroon",
                      cursor: "pointer",
                    }}
                  >
                    <MdClose />
                  </button>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => addVarsity(idx, countryIdx, locIdx)}
                >
                  Add Varsity
                </button>
                {loc.varsity.map((v, varIdx) => (
                  <div
                    key={varIdx}
                    style={{
                      backgroundColor: "#c6e0fa",
                      padding: "10px",
                      margin: "10px 0",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Varsity Name"
                        value={v.name}
                        onChange={(e) =>
                          updateState(
                            [
                              idx,
                              "country",
                              countryIdx,
                              "location",
                              locIdx,
                              "varsity",
                              varIdx,
                              "name",
                            ],
                            e.target.value
                          )
                        }
                      />
                      <button
                        onClick={() =>
                          deleteItem([
                            idx,
                            "country",
                            countryIdx,
                            "location",
                            locIdx,
                            "varsity",
                            varIdx,
                          ])
                        }
                        style={{
                          background: "none",
                          border: "none",
                          color: "maroon",
                          cursor: "pointer",
                        }}
                      >
                        <MdClose />
                      </button>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => addMajor(idx, countryIdx, locIdx, varIdx)}
                    >
                      Add Major
                    </button>
                    {v.majors.map((m, majorIdx) => (
                      <div
                        key={majorIdx}
                        style={{
                          backgroundColor: "#b0d4f6",
                          padding: "10px",
                          margin: "10px 0",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Major Name"
                            value={m.name}
                            onChange={(e) =>
                              updateState(
                                [
                                  idx,
                                  "country",
                                  countryIdx,
                                  "location",
                                  locIdx,
                                  "varsity",
                                  varIdx,
                                  "majors",
                                  majorIdx,
                                  "name",
                                ],
                                e.target.value
                              )
                            }
                          />
                          <button
                            onClick={() =>
                              deleteItem([
                                idx,
                                "country",
                                countryIdx,
                                "location",
                                locIdx,
                                "varsity",
                                varIdx,
                                "majors",
                                majorIdx,
                              ])
                            }
                            style={{
                              background: "none",
                              border: "none",
                              color: "maroon",
                              cursor: "pointer",
                            }}
                          >
                            <MdClose />
                          </button>
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            addProgram(
                              idx,
                              countryIdx,
                              locIdx,
                              varIdx,
                              majorIdx
                            )
                          }
                        >
                          Add Program
                        </button>
                        {m.programs.map((p, programIdx) => (
                          <div
                            key={programIdx}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <input
                              type="text"
                              className="mb-2"
                              placeholder="Program Name"
                              value={p}
                              onChange={(e) =>
                                updateState(
                                  [
                                    idx,
                                    "country",
                                    countryIdx,
                                    "location",
                                    locIdx,
                                    "varsity",
                                    varIdx,
                                    "majors",
                                    majorIdx,
                                    "programs",
                                    programIdx,
                                  ],
                                  e.target.value
                                )
                              }
                            />
                            <button
                              onClick={() =>
                                deleteItem([
                                  idx,
                                  "country",
                                  countryIdx,
                                  "location",
                                  locIdx,
                                  "varsity",
                                  varIdx,
                                  "majors",
                                  majorIdx,
                                  "programs",
                                  programIdx,
                                ])
                              }
                              style={{
                                background: "none",
                                border: "none",
                                color: "maroon",
                                cursor: "pointer",
                              }}
                            >
                              <MdClose />
                            </button>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))
      )}
      <button className="btn btn-primary" onClick={addCountry}>
        Add Country
      </button>
      <button className="btn btn-success" onClick={() => setConfirmModal(true)}>
        Save Changes
      </button>
      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="form-section mb-0">
            {language === "en"
              ? "Enter Current Password To Update Changes"
              : "পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন"}
            <input
              type="password"
              required
              className="form-control mb-0"
              placeholder={
                language === "en"
                  ? "Enter your password"
                  : "আপনার পাসওয়ার্ড লিখুন"
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-secondary"
            onClick={() => setConfirmModal(false)}
          >
            {language === "en" ? "Close" : "বন্ধ"}
          </Button>
          <Button variant="primary" type="button" onClick={update}>
            {language === "en" ? "Save Changes" : "পরিবর্তনগুলোর সংরক্ষন"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
