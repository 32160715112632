import React, { useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { GET_SYSTEM_LOGS } from "../../../lib/api/admin";
import Input from "../../shared/Input/Input";
import AdminTableView from "../AdminComponentView/AdminTableView";
import Select from "../../shared/Select/Select";
import { CSVLink } from "react-csv";
import Button from "../../shared/Button/Button";

const today = new Date();
const FromDate = new Date(new Date('2022-03-06').setFullYear(new Date('2022-03-06').getFullYear() - 5)).toDateString();

const getNextDate = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
};

const systemLogsSortOptions = [
  { label: "IP (DESC)", value: "ip:desc" },
  { label: "IP (ASC)", value: "ip:asc" },
  { label: "URL (DESC)", value: "url:desc" },
  { label: "URL (ASC)", value: "url:asc" },
  { label: "Location (DESC)", value: "location:desc" },
  { label: "Location (ASC)", value: "location:asc" },
  { label: "Message (DESC)", value: "message:desc" },
  { label: "Message (ASC)", value: "message:asc" },
  { label: "Created Date (DESC)", value: "createdAt:desc" },
  { label: "Created Date (ASC)", value: "createdAt:asc" },
];

export default function SystemLogsView() {
  const [from, setFrom] = useState(FromDate);
  const [to, setTo] = useState(getNextDate(today));
  const [exportReport, setExportReport] = useState(false);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(null);
  const {
    data: systemLogs,
    status,
    error,
  } = useQuery(
    [
      "GET_SYSTEM_LOGS",
      {
        page,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
        ...(search ? { search } : {}),
        ...(sort ? { sort } : {}),
      },
    ],
    GET_SYSTEM_LOGS
  );

  const handleDownloadJSON = () => {
    const jsonData = JSON.stringify(systemLogs?.data?.data || [], null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="systemReportView">
      <h3
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        System Logs
        <span>
          {!exportReport && (
            <Button
              variant="primary"
              onClick={() => setExportReport(true)}
              type="button"
            >
              Export Logs
            </Button>
          )}
          {exportReport && (
            <Button
              variant="primary"
              onClick={() => {
                setExportReport(false);
                handleDownloadJSON();
              }}
              type="button"
            >
              Download JSON
            </Button>
          )}
        </span>
      </h3>

      <div className="adminTableView__sort">
        <label>Sort By</label>
        <div>
          <Select
            width="200px"
            variant="ghost_white"
            onChange={({ target }) =>
              setSort({
                [target.value.split(":")[0]]: target.value.split(":")[1],
              })
            }
          >
            {systemLogsSortOptions.map((opt, index) => (
              <option key={index} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSearch(e.target.search.value);
        }}
        className="adminTableView__search"
      >
        <label>Search Logs</label>
        <div>
          <input
            type="text"
            name="search"
            className="form-control"
            placeholder="Search by ip, url, location..."
          />
          <Button variant="primary" type="submit">
            Search
          </Button>
        </div>
      </form>

      <div className="systemReportView__filters">
        <Input
          label="From"
          type="date"
          value={moment(from).format("YYYY-MM-DD")}
          onChange={({ target }) => setFrom(target.value)}
        />
        <Input
          label="To"
          type="date"
          value={moment(to).format("YYYY-MM-DD")}
          onChange={({ target }) => setTo(target.value)}
        />
      </div>

      <div className="systemReportView__data">
        <AdminTableView
          query={{ status, error }}
          title=""
          table_name="Log"
          deleteOpt={true}
          table={{
            headers: [
              { name: "IP", dotsMenu: [], key: "ip" },
              { name: "Fullname", dotsMenu: [], key: "fullname" },
              { name: "URL", dotsMenu: [], key: "url" },
              { name: "Email", dotsMenu: [], key: "email" },
              { name: "Created Date", dotsMenu: [], key: "createdAt" },
              {
                name: "Actions",
                dotsMenu: [{ name: "Delete" }],
                key: "actions",
              },
            ],
            data: systemLogs?.data?.data?.map((log) => ({
              ...log,
              fullname: log?.user_id?.fullname || "N/A",
              location: log?.location?.city || log?.location || "N/A",
              createdAt: moment(log?.createdAt).format("YYYY-MM-DD") || "N/A",
              email: log?.email,
              ip: log?.ip,
            })),
            pagination: {
              total: systemLogs?.data?.total,
              page,
              prev: systemLogs?.data?.prev,
              next: systemLogs?.data?.next,
              setPage,
            },
          }}
        />
      </div>
    </div>
  );
}
