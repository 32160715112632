import React from 'react';
import AdminLayout from '../../../components/AdminLayout';
import SystemLogsView from '../../../components/AdminLayout/SystemLogsView/SystemLogsView';

export default function SystemLogs() {
  return (
    <AdminLayout>
      <SystemLogsView />
    </AdminLayout>
  )
}