import React, { useContext, useState } from 'react';
import { Button } from '../button/Button';
import ThreeDot from '../modals/bid/ThreeDot';
import './BidCard.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../util/LoadingSpinner';
import ErrorModal from '../util/ErrorModal';
import Tooltip from '../util/Tooltip';
import SuccessModal from '../util/SuccessModal';
import { AuthContext } from '../../context/auth-context';
import UserCard from './UserCard';
import AcceptBid from '../modals/bid/AcceptBid';
const BidCard = (props) => {
  const { isLoading, error, clearError, sendRequest } = useHttpClient();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAcceptBid, setShowAcceptBid] = useState(false);
  const [successModalPayment, setSuccessModalPayment] = useState(false);
  const onCancel = () => {
    setShowSuccessModal(false);
    setShowAcceptBid(false);
  };
  const auth = useContext(AuthContext);
  const confirmDeal = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/provider/accept_bid/?bid_id=${props.id}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        props.onSuccess();
        setShowSuccessModal(true);
      }
    } catch (err) {}
  };
  const initiateDeal = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/client/initiate_deal/?bid_id=${props.id}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.ssl_url) {
        window
          .open(
            responseData.ssl_url,
            '',
            ` scrollbars=yes,menubar=no,height=680 ,width=520, left=${
              window.screen.width / 2 - 260
            } top=${
              window.screen.height / 2 - 340
            }resizable=yes,toolbar=no,location=no,status=no"`
          )
          .focus();
      }
    } catch (err) {}
  };
  window.addEventListener('storage', function (e) {
    if (e.key === 'P_AD') {
      props.onSuccess();
      setSuccessModalPayment(true);
    }
  });

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <AcceptBid
        onSuccess={props.onSuccess}
        id={props.id}
        show={showAcceptBid}
        onCancel={onCancel}
      />
      <SuccessModal
        show={showSuccessModal}
        onClear={onCancel}
        message='You have accepted the deal. We will notify you when the deal is initiated'
      />
      <SuccessModal
        onClear={() => {
          setSuccessModalPayment(false);
        }}
        show={successModalPayment}
        message='Payment completed successfully. Deal was initiated.'
      />
      <div className='bidcard'>
        {isLoading && <LoadingSpinner asOverlay />}
        <div className='bidcard__left'>{props.children}</div>
        <div className='bidcard__right'>
          {props.detail && !props.provider && (
            <div className='contain__user'>
              <UserCard
                tiny
                provider
                id={props.providerId}
                name={props.providerName}
                rating={props.providerRating}
                picture={`${process.env.REACT_APP_BACKEND_URL}/${props.providerProfilePic}`}
              />
            </div>
          )}
          <p>
            <span className='span__bold'>Bid Date: {props.bidDate}</span>
          </p>
          <p>
            <span className='span__bold'>Claimed Budget: </span>{' '}
            <span className='span__bdgt'>{props.bidBudget} BDT</span>
          </p>
          <p>
            <span className='span__bold'>Comment: </span>{' '}
            <span className='span__grey'>{props.comment}</span>
          </p>

          <div className='bottom__info'>
            <p>
              <span className='span__bold'>Status: </span>
              <Tooltip
                position='top'
                width='300px'
                tip={
                  props.detail && !props.acceptedbyclient
                    ? 'Accept the bid to initiate a deal'
                    : props.status === 'ACCEPTED'
                    ? props.provider
                      ? 'Client has accepted this bid'
                      : 'Click on the initiate deal button to make payment and initiate the deal.'
                    : props.status === 'PENDING'
                    ? 'Waiting for client to accept this bid'
                    : 'Bid was successful'
                }
              >
                <span
                  className={
                    props.status === 'ACCEPTED' ? 'span__green' : 'span__orange'
                  }
                >
                  {props.status}
                </span>
              </Tooltip>
            </p>
            {/* {props.acceptedbyclient &&
              props.acceptedbyprovider &&
              !props.provider &&
              props.status !== "SUCCESSFUL" && (
                <ModalButton onClick={initiateDeal}>Initiate Deal</ModalButton>
              )} */}
            {props.acceptedbyclient &&
              !props.acceptedbyprovider &&
              props.provider && (
                <Button onClick={confirmDeal} buttonStyle='btn--apply'>
                  Confirm Deal
                </Button>
              )}
            {!props.acceptedbyclient &&
              !props.acceptedbyProvider &&
              !props.provider && (
                <Button
                  isDisabled={props.acceptedbyclient}
                  onClick={() => {
                    setShowAcceptBid(true);
                  }}
                  buttonStyle='btn--apply'
                >
                  {props.acceptedbyclient ? 'Accepted' : 'Accept Bid'}
                </Button>
              )}
          </div>
        </div>

        {props.provider &&
          props.status === 'PENDING' &&
          !auth.isManager &&
          !auth.isAdmin && (
            <ThreeDot
              id={props.id}
              bidBudget={props.bidBudget}
              comment={props.comment}
              jobBudget={props.jobBudget}
              refresh={props.refresh}
              onSuccess={props.onSuccess}
            />
          )}
      </div>
    </React.Fragment>
  );
};

export default BidCard;
