import React, { useEffect } from "react";
import ManageCategories from "../sections/admin/ManageCategories";
const EditCategories = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <ManageCategories />;
};

export default EditCategories;
