import React, { useState, useContext } from 'react';
import './EditExpertise.css';
import Modal from '../../util/Modal';
import { AuthContext } from '../../../context/auth-context';
import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import ModalButton from '../../button/ModalButton';
const AddAchiement = (props) => {
  const auth = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const { isLoading, sendRequest } = useHttpClient();
  const addAchievement = async () => {
    if (title.length > 0 && desc.length > 0) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/user/add_achievement',
          'PUT',
          JSON.stringify({
            name: title,
            description: desc,
          }),
          {
            'Content-Type': 'application/json',
          }
        );
        //maybe check for response data
        if (responseData.success === 'yes') {
          auth.setUser();
          setTitle('');
          setDesc('');
          props.onCancel();
        }
      } catch (err) {}
    }
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      <div className='edit_exp_container'>
        {isLoading && <LoadingSpinner asOverlay />}
        <img
          className='exit_edit'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2>Add Achievements</h2>
        <div className='ach__inpg'>
          <p>Achievement Title</p>
          <input
            className='inp_com'
            type='text'
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Example: Won a contest, Wrote a book, something you're proud of"
          />
          <p>description</p>
          <textarea
            className='inp_com'
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
        </div>
        <div className='work__submitbtn'>
          <ModalButton onClick={addAchievement}>save</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddAchiement;
