import { http } from "../axios";

export const GET_ADMIN_MANAGERS = ({ queryKey }) => {
  const [, { page, search, sort }] = queryKey;
  return http.get(
    `/admin/get_all_admin_and_managers?page=${page}`
      .concat(search ? `&search=${search}` : "")
      .concat(sort ? `&sort=${sort}` : "")
  );
};

export const GET_UNAPPROVED_JOBS = ({ queryKey }) => {
  const [, { page, search, sort, filter }] = queryKey;
  return http.get(
    `/admin/get_unapproved_jobs?page=${page}`
      .concat(search ? `&search=${search}` : "")
      .concat(sort ? `&sort=${sort}` : "")
      .concat(filter ? `&filter=${filter}` : "")
  );
};

export const GET_UNRESOLVED_ISSUES = ({ queryKey }) => {
  const [, { page, search, sort, filter }] = queryKey;
  return http.get(
    `/admin/get_unresolved?page=${page}`
      .concat(search ? `&search=${search}` : "")
      .concat(sort ? `&sort=${sort}` : "")
      .concat(filter ? `&filter=${filter}` : "")
  );
};

export const GET_ISSUES = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/admin/issues",
    params: args,
  });
};

export const GET_ADMIN_REFERRALS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/admin/get_referral_stats",
    params: args,
  });
};

export const GET_FEEDBACKS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/feedbacks",
    params: args,
  });
};

export const GET_USERS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/admin/users/v2",
    params: args,
  });
};

export const GET_FAILED_BIDS = ({ queryKey }) => {
  const [, { page, search, sort, filter }] = queryKey;
  return http.get(
    `/admin/bids?page=${page}`
      .concat(search ? `&search=${search}` : "")
      .concat(sort ? `&sort=${sort}` : "")
      .concat(filter ? `&filter=${filter}` : "")
  );
};

export const GET_FAILED_DEALS = ({ queryKey }) => {
  const [, { page, search, sort, filter }] = queryKey;
  return http.get(
    `/admin/get_failed_deals?page=${page}`
      .concat(search ? `&search=${search}` : "")
      .concat(sort ? `&sort=${sort}` : "")
      .concat(filter ? `&filter=${filter}` : "")
  );
};

export const GET_DEALS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/admin/deals",
    params: args,
  });
};

export const GET_DUE_PAYMENTS = ({ queryKey }) => {
  const [, { page, search, sort, filter }] = queryKey;
  return http.get(
    `/admin/get_due_payments_by_provider?page=${page}`
      .concat(search ? `&search=${search}` : "")
      .concat(sort ? `&sort=${sort}` : "")
      .concat(filter ? `&filter=${filter}` : "")
  );
};

export const GET_CLIENT_PAYMENTS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/admin/client_payments",
  });
};

export const GET_DUE_PAYMENTS_BY_ID = ({ queryKey }) => {
  const [, { id }] = queryKey;
  return http.get(`/admin/get_due_payments_by_provider/${id}`);
};

export const GET_SYSTEM_REPORTS = ({ queryKey }) => {
  const [, { from, to }] = queryKey;
  return http.get(
    `/admin/system_report?`
      .concat(from ? `&from=${from}` : "")
      .concat(to ? `&to=${to}` : "")
  );
};

export const GET_SYSTEM_LOGS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/admin/logs",
  });
};

export const GET_UI_CONFIG = () => {
  return http.get("/ui-config");
};

export const UPDATE_UI_CONFIG = (values) => {
  return http.post("/ui-config", values);
};

export const PAY_PROVIDER = (values) => {
  return http.post("/admin/pay_provider", values);
};

export const PAY_FOR_DEAL = (values) => {
  return http.post("/client/pay_for_deal", values);
};

export const PROVIDER_SUBMIT_JOB = async (values) => {
  const formData = new FormData();
  for (const key of Object.keys(values)) {
    formData.append(key, values[key]);
  }

  try {
    const response = await fetch("/provider/submit_job", {
      method: "POST",
      body: formData,
      headers: {
        "x-access-token": localStorage.getItem("auth_token"),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to submit job");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error submitting job:", error);
    throw error;
  }
};

export const GET_GLOBAL_SETTINGS = () => {
  return http.get("/admin/get_globals");
};

export const UPDATE_GLOBAL_SETTINGS = (values) => {
  console.log(values);
  const newValues = {
    ...values,
    pay_policy: {
      system: values.pay_policy.system,
      pay_day: values.pay_policy.pay_day,
    },
  };
  return http.put("/admin/update_globals", newValues);
};

export const GET_CATEGORIES = () => {
  return http.get("/get_parent_categories");
};

export const CREATE_CATEGORY = (values) => {
  return http.post("/admin/add_category", values);
};

export const UPDATE_CATEGORY = ({ cat_id, values }) => {
  return http.put(`/admin/update_category?category_id=${cat_id}`, values);
};

export const DELETE_CATEGORY = (id) => {
  return http.delete(`/admin/delete_category/${id}`);
};

export const GET_SUB_CATEGORIES = ({ queryKey }) => {
  const [, { cat_name }] = queryKey;
  return http.get(`/get_subcategories?name=${cat_name}`);
};

export const IMPERSONATE_USER = (user_id) => {
  return http.get(`/admin/impersonate_user?user_id=${user_id}`);
};

export const ELEVATE_USER = (values) => {
  return http.put("/admin/elevate_user", values);
};

export const ADMIN_RESET_PASSWORD = ({ user_id, values }) => {
  return http.post(`/admin/reset_password?user_id=${user_id}`, values);
};

export const MARK_ISSUE_AS_RESOLVED = ({ issue_id }) => {
  return http.post(`/admin/set_resolved?issue_id=${issue_id}`);
};

export const GET_JOBS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/admin/jobs",
    params: args,
  });
};

export const GET_PROMOTIONS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    url: "/admin/promotions",
    params: args,
  });
};

export const UPLOAD_IMAGE = (values) => {
  console.log('this is the upload',values);
  return http.post("/upload", values);
};

export const CREATE_PROMOTIONS = (values) => {
  return http.post("/admin/add_promotion", values);
};

export const GET_STATIC = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    url: "/static-fields",
    params: args,
  });
};

export const CREATE_STATIC = (values) => {
  return http.post("/static-fields", values);
};

export const ADD_BANNER_IMAGE = (values) => {
  return http.post("/admin/add_banner_image", values);
};

export const REMOVE_BANNER_IMAGE = (values) => {
  return http.post("/admin/remove_banner_image", values);
};

export const GET_FAQS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    url: "/admin/faqs",
    params: args,
  });
};

export const GET_CLIENT_DUE_PAYMENTS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    url: "/client/due_payments",
    params: args,
  });
};

export const ADD_FAQ = (values) => {
  return http.post("/admin/faqs", values);
};

export const UPDATE_FAQ = ({ id, ...values }) => {
  return http.put(`/admin/faqs/${id}`, values);
};

export const DELETE_FAQ = ({ id }) => {
  return http.delete(`/admin/faqs/${id}`);
};

export const APPROVE_JOB = (id) => {
  return http.post(`/admin/approve_job?job_id=${id}`);
};

export const DISAPPROVE_JOB = (id) => {
  return http.post(`/admin/disapprove_job?job_id=${id}`);
};

export const DELETE_JOB = (id) => {
  return http.delete(`/admin/delete_job?job_id=${id}`);
};
export const DELETE_OLD_DATA = (id, table_name) => {
  return http.post(`/admin/delete-old-data?id=${id}`, {
    table_name: table_name,
  });
};

export const UNDELETE_JOB = (id) => {
  return http.post(`/admin/undelete_job?job_id=${id}`);
};

export const GET_DELIVERY_PROVIDERS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/admin/delivery_providers",
  });
};

export const GET_PAYMENT_PROVIDERS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/transaction_methods",
  });
};

export const GET_ALL_DELIVERY_PROVIDERS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/delivery_providers",
  });
};

export const CREATE_DELIVERY_PROVIDER = (values) => {
  return http({
    method: "POST",
    data: values,
    url: "/admin/delivery_providers",
  });
};

export const CREATE_PAYMENT_PROVIDER = (values) => {
  return http({
    method: "POST",
    data: values,
    url: "/admin/transaction_methods",
  });
};

export const UPDATE_DELIVERY_PROVIDER = ({ _id, values }) => {
  delete values.createdAt;
  delete values.updatedAt;
  delete values.__v;
  return http({
    method: "PUT",
    data: values,
    url: `/admin/delivery_providers/${_id}`,
  });
};

export const UPDATE_PAYMENT_PROVIDER = ({ _id, values }) => {
  delete values.createdAt;
  delete values.updatedAt;
  delete values.__v;
  return http({
    method: "PUT",
    data: values,
    url: `/admin/transaction_methods/${_id}`,
  });
};

export const DELETE_DELIVERY_PROVIDER = ({ _id }) => {
  return http({
    method: "DELETE",
    url: `/admin/delivery_providers/${_id}`,
  });
};

export const DELETE_PAYMENT_PROVIDER = ({ _id }) => {
  return http({
    method: "DELETE",
    url: `/admin/transaction_methods/${_id}`,
  });
};

export const CHANGE_PROFILE_PICTURE = (files) => {
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  let images = [];
  for (const file of files) {
    const baseformat = convertFileToBase64(file);
    images.push(baseformat);
  }
  return http({
    method: "POST",
    data: images,
    url: "/user/change_profile_picture",
  });
};

export const GET_NOTIFICATIONS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/user/get_all_notifications",
  });
};

export const RESET_COLOR_PALETTE = (values) => {
  return http({
    method: "POST",
    data: values,
    url: "/admin/reset_default_color_palette",
  });
};

export const GENERATE_REFERRAL_CODE = (values) => {
  return http({
    method: "GET",
    params: values,
    url: "/user/generate_referral_token",
  });
};

export const GET_BIDS = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/client/get_bids",
  });
};

export const PROVIDER_ACCEPT_BID = (values) => {
  return http({
    method: "POST",
    params: values,
    url: "/provider/accept_bid",
  });
};

export const CLIENT_ACCEPT_BID = (values) => {
  return http({
    method: "POST",
    params: values,
    url: "/client/accept_bid",
  });
};

export const ADMIN_INIT_DEAL = (values) => {
  return http({
    method: "POST",
    params: values,
    url: "/admin/initiate_deal",
  });
};

export const ADMIN_APPROVE_DEAL = (values) => {
  return http({
    method: "POST",
    params: values,
    url: "/admin/approve_deal",
  });
};

export const RATE_PROVIDER = (values) => {
  return http({
    method: "POST",
    data: values,
    url: "/client/rate_provider",
  });
};

export const RATE_CLIENT = (values) => {
  return http({
    method: "POST",
    data: values,
    url: "/provider/rate_client",
  });
};

export const GET_USER_DEAL = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/deal",
  });
};

export const GET_SUBMITTED_JOB = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/client/get_submitted_job",
  });
};

export const GET_SUBMITTED_JOB_BY_PROVIDER = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/provider/get_submitted_job",
  });
};

export const UPDATE_FEEDBACK = ({ _id, ...values }) => {
  return http({
    method: "PUT",
    data: values,
    url: `/feedbacks/${_id}`,
  });
};

export const GET_PAYMENT_CALCULATION = ({ queryKey }) => {
  const [, args] = queryKey;
  return http({
    method: "GET",
    params: args,
    url: "/client/payment_calculations",
  });
};
