import React, { useState, useEffect } from 'react';
import './ManageCategories.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import CategoryEditor from '../../components/admin/CategoryEditor';
const ManageCategories = () => {
  const { isLoading, sendRequest } = useHttpClient();
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [selected, setSelected] = useState(null);
  const [editCat, setEditCat] = useState(null);
  const [showCatagoryAdder, setShowCategoryAdder] = useState(false);
  const [showSubCatagoryAdder, setShowSubCategoryAdder] = useState(false);
  const [showCategoryEditor, setShowCategoryEditor] = useState(false);
  const [showSubCategoryEditor, setShowSubCategoryEditor] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const onCancel = () => {
    setShowCategoryAdder(false);
    setShowSubCategoryAdder(false);
    setShowCategoryEditor(false);
    setShowSubCategoryEditor(false);
  };
  const onSuccess = () => {
    setShowCategoryAdder(false);
    setShowSubCategoryAdder(false);
    setShowCategoryEditor(false);
    setShowSubCategoryEditor(false);
    setRefresh(!refresh);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/get_parent_categories'
        );
        setCategories(responseData);
      } catch (err) {}
    };
    fetchCategories();
    //eslint-disable-next-line
  }, [refresh]);

  const fetchSubCategories = async (category) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/get_subcategories/?name=${category}`
      );
      setSubCategories(responseData);
    } catch (err) {}
  };

  return (
    <div className='categories__container'>
      {isLoading && <LoadingSpinner asOverlay />}
      <CategoryEditor
        imgedit
        cat
        show={showCatagoryAdder}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
      <CategoryEditor
        subcat
        selected={selected}
        show={showSubCatagoryAdder}
        onCancel={onCancel}
        onSuccess={() => {
          onSuccess();
          fetchSubCategories(selected.name);
        }}
      />
      <CategoryEditor
        imgedit
        cat
        edit
        editCat={editCat}
        show={showCategoryEditor}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
      <CategoryEditor
        subcat
        edit
        editCat={editCat}
        show={showSubCategoryEditor}
        onCancel={onCancel}
        onSuccess={() => {
          onSuccess();
          fetchSubCategories(selected.name);
        }}
      />
      <h3>Job Categories</h3>
      <div className='categories__box'>
        <p className='pbox'>
          Be careful removing existing categories. Here you can add more
          categories too.
          <br /> Click on a category to expand Sub-Categories.
        </p>
        <div className='show__categories'>
          <div className='categories_list'>
            {categories && (
              <table>
                <tbody>
                  {categories.data.map((category) => {
                    return (
                      <tr>
                        <td
                          className={`${
                            selected &&
                            selected.name === category.name &&
                            'selected'
                          }`}
                        >
                          <div
                            onClick={() => {
                              setSelected(category);
                              fetchSubCategories(category.name);
                            }}
                            className='categoty__item'
                          >
                            <img
                              className='category__logo'
                              src={`${process.env.REACT_APP_BACKEND_URL}/${category.logo}`}
                              alt=''
                            />
                            <span>{category.name}</span>
                            <img
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowCategoryEditor(true);
                                setEditCat(category);
                              }}
                              className='category__edit'
                              src='/assets/84380.svg'
                              alt=''
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className='td__end'>
                      <div
                        onClick={() => {
                          setShowCategoryAdder(true);
                        }}
                        className='categoty__item last_td'
                      >
                        <p>+ Add new Category</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className='subcategories_list'>
            {!subCategories && <p className='scp'>Select a Category</p>}
            {subCategories && (
              <table>
                <tbody>
                  {Array.isArray(subCategories) &&
                    subCategories.map((subcategory) => {
                      return (
                        <tr>
                          <td>
                            <div className='categoty__item'>
                              <span>{subcategory.name}</span>
                              <img
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEditCat(subcategory);
                                  setShowSubCategoryEditor(true);
                                }}
                                className='category__edit'
                                src='/assets/84380.svg'
                                alt=''
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    {selected.name !== 'print' && (
                      <td className='td__end'>
                        <div
                          onClick={() => setShowSubCategoryAdder(true)}
                          className='categoty__item last_td'
                        >
                          <p>+ Add new Subcategory</p>
                        </div>
                      </td>
                    )}
                    {selected.name === 'print' && (
                      <p style={{ textAlign: 'center' }}>
                        print shouldn't have any subcategory.
                      </p>
                    )}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCategories;
