import React, { useState, useEffect, useContext } from "react";
import BiddingCard from "../uicomponents/userPanel/jobDetails/BiddingCard";
import ClientCard from "../uicomponents/userPanel/jobDetails/ClientCard";
import "../uicomponents/userPanel/jobDetails/JobDetails.css";
import { ToggleButton } from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import { useHttpClient } from "../hooks/http-hook";
import LoadingSpinner from "../components/util/LoadingSpinner";
import ErrorModal from "../components/util/ErrorModal";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import { Link, useHistory } from "react-router-dom";

const ApproveJobNew = () => {
  const { isLoading, error, clearError, sendRequest } = useHttpClient();
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { jobID } = useParams();

  const [loadedJob, setLoadedJob] = useState({});
  useEffect(() => {
    const fetchJob = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/get_job/?job_id=${jobID}`
        );
        setLoadedJob(responseData);
      } catch (err) {}
    };
    fetchJob();
    //eslint-disable-next-line
  }, [jobID]);

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const approveJob = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/approve_job/?job_id=${jobID}`,
        "POST",
        JSON.stringify({}),
        {
          "Content-Type": "application/json",
        }
      );
      // history.goBack();
    } catch (err) {}
  };

  const dissaproveJob = async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/delete_job/?job_id=${jobID}`,
        "DELETE"
      );
      // history.goBack();
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div>
        {isLoading && <LoadingSpinner asOverlay />}
        {!isLoading && loadedJob && (
          <div>
            <div id="topBanner">
              <div id="bannerContent">
                <div className="row">
                  <h3>{loadedJob["title"]}</h3>

                  <div
                    className="col"
                    style={{ backgroundColor: "", marginRight: "" }}
                  >
                    <p>
                      <i className="fab fa-gg-circle"></i> {loadedJob?.category}
                    </p>
                  </div>
                  <div
                    className="col"
                    style={{ backgroundColor: "", marginRight: "" }}
                  >
                    <p>
                      <i className="far fa-clock"></i> Posted On{" "}
                      {" " +
                        new Date(loadedJob?.createdAt).toLocaleDateString(
                          undefined,
                          dateOptions
                        )}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <i className="fas fa-map-marker-alt"></i>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row profileBody">
              <div className="col-lg-7 col-md-10" style={{ marginLeft: "5%" }}>
                <div style={{ marginBottom: "60px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>
                      Job Post Description
                    </span>
                  </h5>

                  <p
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    {loadedJob?.description}
                  </p>
                </div>

                <div style={{ marginBottom: "60px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Attachments</span>
                  </h5>
                  {!loadedJob?.attachments ? (
                    <p className="p6">No attachments available</p>
                  ) : (
                    <a
                      href={
                        process.env.REACT_APP_BACKEND_URL +
                        "/" +
                        loadedJob?.attachments[0]
                      }
                      target="_blank"
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: " 5px 10px",
                          backgroundColor: "#e8eafd",
                          color: "#2a41e8",
                          borderRadius: "4px",
                          flexWrap: "wrap",
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            loadedJob?.attachments[0]
                          }
                          alt=""
                        />
                        <p
                          style={{
                            margin: "0px",
                            padding: "0 15px",
                          }}
                        >
                          {" "}
                          Attachment
                        </p>
                      </div>
                    </a>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-md-10 clientContent">
                <div>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Client Details</span>
                  </h5>
                  <ClientCard
                    clientId={loadedJob?.client_id?._id}
                    clientName={loadedJob?.client_id?.fullname}
                    cilentProfilePic={loadedJob?.profile_pic}
                    clientRating={loadedJob?.client_rating}
                  />
                </div>

                <div style={{ marginTop: "50px" }}>
                  <h5>
                    <span style={{ fontWeight: "bold" }}>Bidding</span>
                  </h5>
                  {/* <BiddingCard
                    serviceDeadline=''
                    printDeadline=''
                    budget={loadedJob?.max_budget}
                  /> */}
                  <div className="pcard">
                    <p>
                      <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Service Deadline
                      </span>
                      <h4>
                        {" "}
                        {new Date(loadedJob?.deadline).toLocaleDateString(
                          undefined,
                          dateOptions
                        )}
                      </h4>
                    </p>

                    <p>
                      <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Print Deadline
                      </span>
                      <h4>{loadedJob?.printDeadline}</h4>
                    </p>

                    <p>
                      <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Budget
                      </span>
                      <h4>BDT {loadedJob?.max_budget}</h4>
                    </p>

                    <div className="d-grid gap-2">
                      <button
                        className={
                          loadedJob?.is_approved_by_admin
                            ? "btn btn-success "
                            : " btn prime-btn"
                        }
                        type="button"
                        onClick={() => approveJob()}
                      >
                        {loadedJob?.is_approved_by_admin === false
                          ? "Approve"
                          : "Approved"}
                      </button>
                      {loadedJob?.is_approved_by_admin === false && (
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => dissaproveJob()}
                        >
                          Reject
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ApproveJobNew;
