import swal from "sweetalert2";
import React from "react";

export const deleteConfirmation = (title, text) => {
  swal.fire({
    title: title || "Are you sure?",
    text: text || "you will not be able to retrieve this.",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    customClass: {
      content: "max-height-modal", // Add a custom class to the content (text) container
    },
  });
};
