import React, { useCallback, useMemo, useState, useEffect } from "react";
import "./UploadImage.css";
import axios from "axios";
import {
  BUCKET_URL,
  S3_PREASSIGNED_POST_URL,
  CLOUDFRONT_URL,
} from "../../../config";
import { toast } from "react-toastify";

export default function UploadImage({
  label,
  images: propsImages = [],
  onChange,
  btnText,
  btnTextClass,
  multi=false,
  id
}) {
  const [images, setImages] = useState(propsImages);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  const handleChange = useCallback(
    async ({ target }) => {
      const files = Array.from(target.files); // Get all selected files
      const uploadedImages = []; // Collect uploaded image URLs

      // Use Promise.all to handle all file uploads asynchronously
      await Promise.all(
        files.map(async (file) => {
          try {
            const data = await axios.get(
              S3_PREASSIGNED_POST_URL +
                `?size=${
                  globalSettings?.max_file_size
                }&fileExtension=${file.name.split(".").pop()}`
            );

            const formData = new FormData();
            Object.entries(data.data[0].fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", file);

            const uploadResponse = await axios.post(BUCKET_URL, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            const uploadedUrl = CLOUDFRONT_URL + `${data.data[0].fields.Key}`;
            uploadedImages.push(uploadedUrl);
          } catch (error) {
            toast.error(
              `Max file upload size is ${globalSettings?.max_file_size}MB`
            );
          }
        })
      );

      // After all files are uploaded, update the component state and call onChange
      setImages((prevImages) => [...prevImages, ...uploadedImages]);
      onChange([...images, ...uploadedImages], "logo"); // Pass all uploaded images to the parent
    },
    [onChange, globalSettings]
  );

  const labelStr = useMemo(
    () => String(label).toLowerCase().split(" ").join("_"),
    [label]
  );

  return (
    <div className="uploadImage__image">
      <div
        className="uploaded-images"
        style={{ display: "flex", gap: "10px", width: "100%" }}
      >
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Uploaded ${index + 1}`}
            width="200"
          />
        ))}
      </div>
      <strong>{label}</strong>
      {multi && <input
        id={id || `uploadImage_${labelStr}`}
        type="file"
        multiple={multi} // Correctly allow multiple file uploads
        hidden
        onChange={handleChange}
      />}
      {!multi && <input
        id={id || `uploadImage_${labelStr}`}
        type="file"
        hidden
        onChange={handleChange}
      />}
      <label htmlFor={id || `uploadImage_${labelStr}`} className={btnTextClass}>
        {btnText || "Upload"}
      </label>
    </div>
  );
}
