import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../../hooks/http-hook";
import LoadingSpinner from "../../../components/util/LoadingSpinner";
import ErrorModal from "../../../components/util/ErrorModal";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal } from "react-bootstrap";
import "./Profile.css";
import StatCard from "./StatCard";
import OverviewCard from "./OverviewCard";
import ApplicationModal from "../../../components/util/ApplicationModal";

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const ProfileOther = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [sendMessage, setSendMessage] = useState(false);
  const [loadedUser, setLoadedUser] = useState();
  let { userID } = useParams();
  const [galleryItems, setGalleryItems] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const auth = useContext(AuthContext);

  const openGallery = (index) => {
    const items = loadedUser.portfolio.map((link) => {
      if (link.endsWith(".pdf")) {
        return {
          original: `https://docs.google.com/gview?url=${link}&embedded=true`,
          thumbnail: "https://via.placeholder.com/150",
          embedUrl: `https://docs.google.com/gview?url=${link}&embedded=true`,
          renderItem: () => (
            <iframe
              src={`https://docs.google.com/gview?url=${link}&embedded=true`}
              style={{ width: "100%", height: "80vh" }}
              title="PDF Content"
            ></iframe>
          ),
        };
      } else {
        return {
          original: link,
          thumbnail: link,
        };
      }
    });
    setGalleryItems(items);
    setActiveIndex(index);
    setShowGallery(true);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/?user_id=${userID}`
        );
        setLoadedUser(responseData);
      } catch (err) {}
    };
    fetchUser();
  }, [userID]);

  const [show, setShow] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [msgToSend, setMsgToSend] = useState("");
  const sendTheMessage = async () => {
    if (msgToSend.length > 0) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/send_message`,
          "POST",
          JSON.stringify({
            receiver_id: userID,
            content: msgToSend,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.success === "yes") {
          setMsgToSend("");
          props.onCancel();
          handleClose();
        }
      } catch (err) {
        handleClose();
      }
    }
  };

  const styles = {
    topBanner: {
      height: "200px",
      background:
        "linear-gradient(90deg, #f8f8f8 39.93%, rgba(255, 255, 255, 0) 100%), url('/assets/banner8.png') lightgray 50% / cover no-repeat",
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "100%",
      margin: "0px",
    },
    bannerContent: {
      maxWidth: "1440px",
      width: "80vw",
      margin: "auto",
    },
    profilePic: {
      height: "128px",
      width: "128px",
      borderRadius: "100%",
    },
    portfolioThumbnail: {
      width: "150px",
      height: "150px",
      cursor: "pointer",
      margin: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #ccc",
    },
    tiles: {
      display: "flex",
      width: "900px",
      padding: "16px",
      flexDirection: "column",
      alignItems: "start",
      gap: "10px",
      borderRadius: "4px",
      background: "#F8F8F8",
    },
    textarea: {
      width: "100%",
      borderRadius: "4px",
      padding: "8px",
      boxSizing: "border-box",
    },
    modalFooter: {
      border: "none",
    },
    btn: {
      backgroundColor: "var(--white_background)",
      color: "var(--primary_color)",
    },
    primeBtn: {
      backgroundColor: "var(--primary_color)",
      color: "var(--white_background)",
    },
    textStyle: {
      color: "var(--grey_one, #333)",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: 0,
    },
    buttonStyle: {
      width: "100%",
      borderRadius: "4px",
      padding: "10px",
      backgroundColor: "#2A41E8",
      border: "none",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      color: "white",
      fontFamily: "Nunito",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "normal",
    },
  };

  return (
    <div>
      {isLoading && <LoadingSpinner asOverlay />}
      {loadedUser && (
        <div>
          <div style={styles.topBanner}>
            <div style={styles.bannerContent}>
              <div className="d-flex flex-row">
                <div>
                  <img
                    src={
                      (loadedUser.profile_pic && `${loadedUser.profile_pic}`) ||
                      "https://via.placeholder.com/150/92c952"
                    }
                    alt="user_image"
                    style={styles.profilePic}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      ...styles.textStyle,
                      fontSize: "26px",
                      fontWeight: 700,
                    }}
                  >
                    {loadedUser.fullname}
                  </p>
                  <p
                    style={{
                      ...styles.textStyle,
                      fontSize: "20px",
                      color: "var(--grey_two, #666)",
                      fontWeight: 400,
                    }}
                  >
                    {loadedUser?.tagline || "N/A"}
                  </p>
                  <button
                    type="button"
                    style={{
                      ...styles.buttonStyle,
                      width: "240px",
                      padding: "5px",
                      marginBottom: "5px",
                    }}
                    onClick={handleShow}
                  >
                    <img src="/assets/send.svg" alt="message" />
                    <p style={{ margin: "0px" }}>
                      {auth.language === "en"
                        ? "Send a Message"
                        : "বার্তা পাঠান"}
                    </p>
                  </button>
                  <button
                    type="button"
                    style={{
                      ...styles.buttonStyle,
                      width: "240px",
                      padding: "5px",
                    }}
                    onClick={() => setApplicationModal(true)}
                  >
                    <p style={{ margin: "0px" }}>
                      {auth.language === "en"
                        ? "Create an Application"
                        : "একটি আবেদন তৈরি করুন"}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "80vw",
              margin: "40px auto",
              maxWidth: "1440px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <p style={{ ...styles.textStyle, fontSize: "20px" }}>
                  {auth.language === "en" ? "About Me" : "আমার সম্পর্কে"}
                </p>

                <p
                  style={{
                    ...styles.textStyle,
                    color: "var(--grey_two, #666)",
                  }}
                >
                  {loadedUser?.about_me || "N/A"}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <p style={{ ...styles.textStyle, fontSize: "20px" }}>
                  {auth.language === "en" ? "Portfolio" : "পোর্টফোলিও"}
                </p>
                {loadedUser.portfolio.length > 0 ? (
                  <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {loadedUser.portfolio.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => openGallery(index)}
                          style={styles.portfolioThumbnail}
                        >
                          {item.endsWith(".pdf") ? (
                            <span>PDF {index + 1}</span>
                          ) : (
                            <img
                              src={item}
                              alt="Portfolio Thumbnail"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <Modal
                      show={showGallery}
                      onHide={() => setShowGallery(false)}
                      size="lg"
                    >
                      <Modal.Body>
                        <ImageGallery
                          items={galleryItems}
                          startIndex={activeIndex}
                        />
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : (
                  <p
                    style={{
                      ...styles.textStyle,
                      color: "var(--grey_two, #666)",
                    }}
                  >
                    {auth.language === "en"
                      ? "no attachment available"
                      : "কোন সংযুক্তি নাই"}
                  </p>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <p style={{ ...styles.textStyle, fontSize: "20px" }}>
                  {auth.language === "en" ? "Skills" : "দক্ষতা"}
                </p>

                <div className="d-flex flex-row flex-wrap gap-4">
                  {loadedUser.expertise.length > 0 ? (
                    loadedUser.expertise.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#E8EAFD",
                          borderRadius: 4,
                          padding: "8px 16px",
                        }}
                      >
                        <p style={{ ...styles.textStyle, color: "#2A41E8" }}>
                          {item}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        ...styles.textStyle,
                        color: "var(--grey_two, #666)",
                      }}
                    >
                      {auth.language === "en"
                        ? "No skills found!"
                        : "কোন দক্ষতা পাওয়া যায়নি!"}
                    </p>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <p style={{ ...styles.textStyle, fontSize: "20px" }}>
                  {auth.language === "en" ? "Achievements" : "অর্জন"}
                </p>

                <div className="d-flex flex-column gap-4">
                  {loadedUser.achievement.length > 0 ? (
                    loadedUser.achievement.map((item, index) => (
                      <div key={index} style={styles.tiles}>
                        <p
                          style={{
                            ...styles.textStyle,
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          {item.name}
                        </p>
                        <p
                          style={{
                            ...styles.textStyle,
                            color: "var(--grey_two, #666)",
                            fontSize: "14px",
                          }}
                        >
                          {item.description}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        ...styles.textStyle,
                        color: "var(--grey_two, #666)",
                      }}
                    >
                      {auth.language === "en"
                        ? "No achievement found!"
                        : "কোন কৃতিত্ব পাওয়া যায়নি!"}
                    </p>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <p style={{ ...styles.textStyle, fontSize: "20px" }}>
                  {auth.language === "en" ? "Education" : "শিক্ষা"}
                </p>

                <div className="d-flex flex-column gap-4">
                  {loadedUser.degree.length > 0 ? (
                    loadedUser.degree.map((item, index) => (
                      <div key={index} style={styles.tiles}>
                        <p
                          style={{
                            ...styles.textStyle,
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          {item?.school}
                        </p>
                        <p
                          style={{
                            ...styles.textStyle,
                            color: "var(--grey_two, #666)",
                            fontSize: "14px",
                          }}
                        >
                          {item.degree}
                          {", Passing Year: "}
                          {item.passing_year}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        ...styles.textStyle,
                        color: "var(--grey_two, #666)",
                      }}
                    >
                      {auth.language === "en"
                        ? "No edu data available!"
                        : "কোন শিক্ষাগত তথ্য পাওয়া যায়নি!"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                width: "100%",
              }}
            >
              <div>
                <p
                  style={{
                    ...styles.textStyle,
                    fontSize: "20px",
                    marginBottom: "16px",
                  }}
                >
                  {auth.language === "en"
                    ? "User Stats"
                    : "ব্যবহারকারীর পরিসংখ্যান"}
                </p>
                <StatCard
                  c_rating={loadedUser.client_rating.toString().substr(0, 4)}
                  p_rating={loadedUser.provider_rating.toString().substr(0, 4)}
                  s_ratio={
                    (auth.user?.total_deal / auth.user?.total_bid) * 100 || 0
                  }
                />
              </div>

              <div>
                <p
                  style={{
                    ...styles.textStyle,
                    fontSize: "20px",
                    marginBottom: "16px",
                  }}
                >
                  {auth.language === "en"
                    ? "User Overview"
                    : "ব্যবহারকারীর ওভারভিউ"}
                </p>
                <OverviewCard
                  placed_bids={loadedUser.total_bid}
                  created_provider_deal={loadedUser.total_deal}
                  posted_jobs={loadedUser.total_post}
                  account_creation_date={new Date(
                    loadedUser.createdAt
                  ).toLocaleDateString(undefined, dateOptions)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="form-section">
            <p style={{ ...styles.textStyle, fontSize: "20px" }}>
              {auth.language === "en" ? "Send message to" : "বার্তা পাঠান"}{" "}
              <span
                style={{
                  ...styles.textStyle,
                  fontSize: "20px",
                  color: "#2A41E8",
                }}
              >
                {auth.language === "en"
                  ? loadedUser?.fullname
                  : `${loadedUser?.fullname} কে`}
              </span>
            </p>
            <textarea
              rows="5"
              style={styles.textarea}
              value={msgToSend}
              onChange={(e) => {
                setMsgToSend(e.target.value);
              }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer style={styles.modalFooter}>
          <button className="btn" style={styles.btn} onClick={handleClose}>
            {auth.language === "en" ? "Cancel" : "বাতিল করুন"}
          </button>
          <button
            className="btn prime-btn"
            style={styles.primeBtn}
            onClick={() => sendTheMessage()}
          >
            {auth.language === "en" ? "Send Message" : "বার্তা পাঠান"}
          </button>
        </Modal.Footer>
      </Modal>
      <ApplicationModal
        open={applicationModal}
        onCancel={() => setApplicationModal(false)}
        language={auth.language}
        user={loadedUser}
      />
    </div>
  );
};

export default ProfileOther;
