import React from 'react';
import AdminLayout from '../../../components/AdminLayout';
import GlobalSettingsView from '../../../components/AdminLayout/GlobalSettingsView/GlobalSettingsView';

export default function GlobalSettings() {
  return (
    <AdminLayout>
      <GlobalSettingsView />
    </AdminLayout>
  )
}