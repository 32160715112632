import React, { useContext, useState } from 'react';

import ClientDetailsDealDetails from './ClientDetailsDealDetails';
import './ClientPrintDealDetails.css';
import JobDetailsDealDetails from './JobDetailsDealDetails';
import PrintDetailsDealDetails from './PrintDetailsDealDetails';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';
import { useHttpClient } from '../hooks/http-hook';
export default function ClientPrintDealDetails(props) {
  const [files, setFiles] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const { isLoading, sendRequest } = useHttpClient();
  const fetchFiles = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/${
            props.providerDeal ? 'client' : 'provider'
          }/get_submitted_job/?deal_id=${props.deal_d}`
      );
      setFiles(responseData);
    } catch (err) {}
  };
  const viewFileHandler = () => {
    fetchFiles();
    setShowPreview(true);
  };
  const history = useHistory();
  const auth = useContext(AuthContext);
  return (
    <div className='userProfile--container clientPrintDealDetails'>
      <div className='clientPrintDealDetails__top'>
        <button
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/profile/${auth.user._id}`)}
        >
          Back to profile
        </button>{' '}
        Print & Delivery Deal Details with Your Client
      </div>
      <ClientDetailsDealDetails
        property={props.clientDeals}
        deal_id={props.deal_id}
      />

      <div className='clientPrintDealDetails__work'>
        <h3>Your Work</h3>
        <div className='clientPrintDealDetails__divider' />
        <br />
        <p>The Other Provider Has not Submitted the Printable Files Yet</p>
        <p>Estimated Date : 2021-16-05</p>
        <p>The Other Provider Has Submitted the Printable Files</p>
        {/* <button>View Printable Files</button> */}

        {props.clientDeals.job_submitted && (
          <button
            style={{ cursor: 'pointer' }}
            onClick={viewFileHandler}
            className='View_files_btn'
          >
            View Files
          </button>
        )}
      </div>

      
      <PrintDetailsDealDetails
        property={props.clientDeals}
        deal_id={props.deal_id}
      />
      <JobDetailsDealDetails
        property={props.clientDeals}
        deal_id={props.deal_id}
      />
    </div>
  );
}
