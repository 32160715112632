import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  ADD_BANNER_IMAGE,
  GET_UI_CONFIG,
  REMOVE_BANNER_IMAGE,
  RESET_COLOR_PALETTE,
  UPDATE_UI_CONFIG,
  UPLOAD_IMAGE,
} from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import QComponent from "../../shared/QComponent/QComponent";
import Select from "../../shared/Select/Select";
import UploadImage from "../../shared/UploadImage/UploadImage";
import { FaPlus, FaQuestionCircle, FaTimes } from "react-icons/fa";
import "./UiConfigurationsView.css";
import ReactTooltip from "react-tooltip";
import Editor from "../../Editor/Editor";
import axios from "axios";
import { BUCKET_URL,S3_PREASSIGNED_POST_URL,CLOUDFRONT_URL } from "../../../config";

export default function UiConfigurationsView() {
  const {
    data: uiConfig,
    status,
    error,
    refetch,
  } = useQuery("GET_UI_CONFIG", GET_UI_CONFIG, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const { mutateAsync: updateUiConfigMutation } = useMutation(UPDATE_UI_CONFIG);
  const { mutateAsync: resetColorPalate, isLoading: resetColorPalateLoading } =
    useMutation(RESET_COLOR_PALETTE);
  const { mutateAsync: uploadImageMutation } = useMutation(UPLOAD_IMAGE);
  const { mutateAsync: addBannerImage } = useMutation(ADD_BANNER_IMAGE);
  const { mutateAsync: removeBannerImage } = useMutation(REMOVE_BANNER_IMAGE);
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: useMemo(() => uiConfig?.data, [uiConfig]),
  });
  const [photos, setPhotos] = useState({
    logo: "",
    explore_jobs_section_background_image: "",
    post_jobs_section_background_image: "",
    auth_background: {
      login_background_image: "",
      register_background_image: "",
    },
    cover_photos: {
      default_user_cover_photo: "",
      default_job_post_cover_photo: "",
    },
  });
  const [globalSettings, setGlobalSettings] = useState(null);



  useEffect(()=>{
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        // console.log(error);
      }
    };

    getGlobals();
  },[])

    


  useEffect(() => {
    if (uiConfig?.data) {
      setPhotos({
        logo: uiConfig?.data?.logo,
        explore_jobs_section_background_image:
          uiConfig?.data?.explore_jobs?.section_background_image,
        post_jobs_section_background_image:
          uiConfig?.data?.post_jobs?.section_background_image,
        auth_background: {
          login_background_image:
            uiConfig?.data?.auth_background?.login_background_image,
          register_background_image:
            uiConfig?.data?.auth_background?.register_background_image,
        },
        cover_photos: {
          default_user_cover_photo:
            uiConfig?.data?.cover_photos?.default_user_cover_photo,
          default_job_post_cover_photo:
            uiConfig?.data?.cover_photos?.default_job_post_cover_photo,
        },
      });
    }
  }, [uiConfig]);



  const uploadImage = async (key, image, type) => {
    if (type !== "banner") return image[0];
    try {
      const reader = new FileReader();
      reader.readAsDataURL(image);
  
      return new Promise((resolve, reject) => {
        reader.onload = async () => {
          try {
            console.log(globalSettings);
            const data = await axios.get(
              `${S3_PREASSIGNED_POST_URL}?size=${globalSettings?.max_file_size}&fileExtension=${image.name.split('.').pop()}`
            );
  
            const formData = new FormData();
            Object.entries(data.data[0].fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", image);
  
            const uploadResponse = await axios.post(BUCKET_URL, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            toast.success("Successfully uploaded the image!");
            resolve(CLOUDFRONT_URL + `${data.data[0].fields.Key}`);
          } catch (err) {
            reject(err);
          }
        };
      });
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.msg ||
          err?.message ||
          `Max file upload size is ${globalSettings?.max_file_size}MB`
      );
    }
  };
  

  const updateUiConfig = useCallback(
    async (values) => {
      try {
        const {
          color_palette: { _id, __v, createdAt, updatedAt, name, ...rest },
          global_font_face,
          homepage,
          hero_text_en,
          hero_text_bn,
          default_lang,
        } = values;
        const {
          logo,
          auth_background,
          cover_photos,
          explore_jobs_section_background_image,
          post_jobs_section_background_image,
        } = photos;
        await updateUiConfigMutation({
          logo,
          color_palette: rest,
          global_font_face,
          auth_background,
          cover_photos,
          hero_text_en,
          hero_text_bn,
          default_lang,
          homepage: {
            ...homepage,
            explore_jobs: {
              ...homepage.explore_jobs,
              section_background_image: explore_jobs_section_background_image,
            },
            post_jobs: {
              ...homepage.post_jobs,
              section_background_image: post_jobs_section_background_image,
            },
          },
        });
        await refetch();
        toast.success("Successfully updated ui configs!");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [photos, refetch, updateUiConfigMutation]
  );
  const handleDefaultColorPaletteReset = useCallback(
    async (values) => {
      try {
        await resetColorPalate({});
        await refetch();
        toast.success("Successfully reset the color palette!");
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [refetch, resetColorPalate]
  );

  useEffect(() => {
    reset(uiConfig?.data);
  }, [reset, uiConfig]);

  return (
    <QComponent status={status} error={error}>
      <ReactTooltip />
      <form
        onSubmit={handleSubmit(updateUiConfig)}
        className="uiConfigurationsView"
      >
        <h3 style={{ fontWeight: "bold" }}>UI Configurations</h3>
        <UploadImage
          label="Upload Logo"
          image={uiConfig?.data?.logo}
          btnTextClass="px-6"
          onChange={(image, type) =>
            uploadImage("logo", image, type).then((url) => {
              setPhotos((values) => ({ ...values, logo: url }));
            })
          }
        />

        <h2>Banners</h2>
        <div className="uiConfigurationsView__banners">
          <div>
            <label>
              <input
                type="file"
                hidden
                onChange={({ target }) => {
                  uploadImage("logo", target.files[0], "banner").then(
                    async (url) => {
                      await addBannerImage({ url });
                      await refetch();
                      console.log(url);
                    }
                  );
                }}
              />{" "}
              <FaPlus />
            </label>
          </div>
          {uiConfig?.data?.banner_image_array?.map((img, ind) => (
            <div key={ind}>
              <span
                onClick={async () => {
                  await removeBannerImage({ url: img });
                  await refetch();
                }}
              >
                <FaTimes />
              </span>
              <img src={`${img}`} alt="" />
            </div>
          ))}
        </div>

        <h2>Global Language</h2>
        <div className="d-flex gap-3">
          <label className="d-block mt-3" style={{ width: "100%" }}>
            <strong>Default Language</strong>
            <Select variant="ghost_white" {...register("default_lang")}>
              <option value="">Select Language</option>
              {global_languages.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
        </div>

        <h2>Global Font Faces</h2>
        <div className="d-flex gap-3">
          <label className="d-block mt-3" style={{ width: "100%" }}>
            <strong>Default Bangla Font</strong>
            <Select
              variant="ghost_white"
              {...register("global_font_face.default_bangla_font")}
            >
              <option value="">Select Font</option>
              {global_font_bn_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <label className="d-block mt-3" style={{ width: "100%" }}>
            <strong>Default English Font</strong>
            <Select
              variant="ghost_white"
              {...register("global_font_face.default_english_font")}
            >
              <option value="">Select Font</option>
              {global_font_en_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
        </div>

        <h2>Hero Text</h2>
        <div className="d-flex gap-3">
          <div className="d-block mt-3" style={{ flex: "1" }}>
            <strong>Default English Text</strong>
            <Editor
              defaultValue={getValues("hero_text_en")}
              onChange={(_, data) =>
                setValue("hero_text_en", JSON.stringify(data))
              }
            />
          </div>
          <div className="d-block mt-3" style={{ flex: "1" }}>
            <strong>Default Bangla Text</strong>
            <Editor
              defaultValue={getValues("hero_text_bn")}
              onChange={(_, data) =>
                setValue("hero_text_bn", JSON.stringify(data))
              }
            />
          </div>
        </div>

        <h2
          onClick={handleDefaultColorPaletteReset}
          className="d-flex align-items-center justify-content-between mb-3"
        >
          <span>Color Palette</span>
          <button type="button" className="btn btn-secondary btn-sm">
            {resetColorPalateLoading ? "Loading..." : "Reset Palette"}
          </button>
        </h2>
        <div className="uiConfigurationsView__palates">
          <label className="d-block">
            <strong>
              Primary Color
              <span data-tip="Affects button background colors, Link texts, Primary level text labels.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.primary_color")} />
          </label>
          <label className="d-block">
            <strong>
              Primary Light
              <span data-tip="Should be a lighter shade of the primary color.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input
              type="color"
              {...register("color_palette.primary_light_color")}
            />
          </label>
          <label className="d-block">
            <strong>
              Positive Color
              <span data-tip="should be a shade of green.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.positive_color")} />
          </label>
          <label className="d-block">
            <strong>
              Positive Light
              <span data-tip="should be a lighter shade of green.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input
              type="color"
              {...register("color_palette.positive_light_color")}
            />
          </label>
          <label className="d-block">
            <strong>
              Negative Color
              <span data-tip="should be a shade of red.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.negetive_color")} />
          </label>
          <label className="d-block">
            <strong>
              Negative Light
              <span data-tip="should be a lighter shade of red.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input
              type="color"
              {...register("color_palette.negetive_light_color")}
            />
          </label>
          <label className="d-block">
            <strong>
              Grey One
              <span data-tip="Afftects Secondary level texts (descriptions, secondary titles), hard border colors.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.grey_one")} />
          </label>
          <label className="d-block">
            <strong>
              Grey Two
              <span data-tip="Affects Tertiary level texts, Textbox placeholders.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.grey_two")} />
          </label>
          <label className="d-block">
            <strong>
              Grey Three
              <span data-tip="Affects disabled text, background for disabled elements.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.grey_three")} />
          </label>
          <label className="d-block">
            <strong>
              Grey Four
              <span data-tip="Affects soft outlines & drop-shadows.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.grey_four")} />
          </label>
          <label className="d-block">
            <strong>
              Grey Five
              <span data-tip="Affects grey backgrounds & some outlines.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.grey_five")} />
          </label>
          <label className="d-block">
            <strong>
              Grey Six
              <span data-tip="Affects grey backgrounds of some components.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input type="color" {...register("color_palette.grey_six")} />
          </label>
          <label className="d-block">
            <strong>
              Grey Background
              <span data-tip="Affects grey backgrounds of some components.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input
              type="color"
              {...register("color_palette.grey_background")}
            />
          </label>
          <label className="d-block">
            <strong>
              White Background
              <span data-tip="Affects the general background of most of the site.">
                <FaQuestionCircle />
              </span>
            </strong>
            <input
              type="color"
              {...register("color_palette.white_background")}
            />
          </label>
          {/* <label className='d-block'>
            <strong>Link Text
              <span data-tip="hello world"><FaQuestionCircle /></span>
            </strong>
            <input
              type='color'
              {...register('color_palette.link_text')}
            />
          </label>
          <label className='d-block'>
            <strong>Text
              <span data-tip="hello world"><FaQuestionCircle /></span>
            </strong>
            <input
              type='color'
              {...register('color_palette.text')}
            />
          </label> */}
        </div>

        <h2>Authentication Background</h2>
        <div className="d-flex gap-3">
          <UploadImage
            label="Login Page Background"
            image={uiConfig?.data?.auth_background?.login_background_image}
            btnTextClass="px-6"
            onChange={(image) =>
              uploadImage("login_background_image", image).then((url) =>
                setPhotos((values) => ({
                  ...values,
                  auth_background: {
                    ...values.auth_background,
                    login_background_image: url,
                  },
                }))
              )
            }
          />
          <UploadImage
            label="Signup Page Background"
            image={uiConfig?.data?.auth_background?.register_background_image}
            btnTextClass="px-6"
            onChange={(image) =>
              uploadImage("signup_background_image", image).then((url) =>
                setPhotos((values) => ({
                  ...values,
                  auth_background: {
                    ...values.auth_background,
                    register_background_image: url,
                  },
                }))
              )
            }
          />
        </div>

        <h2>Homepage: Explore Jobs Section</h2>
        <div className="uiConfigurationsView__section">
          <div className="d-block mt-3">
            <strong>Bangla Title</strong>
            <Editor
              defaultValue={getValues("homepage.explore_jobs.bangla_title")}
              onChange={(_, data) =>
                setValue(
                  "homepage.explore_jobs.bangla_title",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>Bangla Title Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.explore_jobs.bangla_title_font")}
            >
              <option value="">Select Font</option>
              {global_font_bn_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <div className="d-block mt-3">
            <strong>English Title</strong>
            <Editor
              defaultValue={getValues("homepage.explore_jobs.english_title")}
              onChange={(_, data) =>
                setValue(
                  "homepage.explore_jobs.english_title",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>English Title Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.explore_jobs.english_title_font")}
            >
              <option value="">Select Font</option>
              {global_font_en_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <div className="d-block mt-3">
            <strong>Bangla Description</strong>
            <Editor
              defaultValue={getValues(
                "homepage.explore_jobs.bangla_description"
              )}
              onChange={(_, data) =>
                setValue(
                  "homepage.explore_jobs.bangla_description",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>Bangla Description Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.explore_jobs.bangla_description_font")}
            >
              <option value="">Select Font</option>
              {global_font_bn_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <div className="d-block mt-3">
            <strong>English Description</strong>
            <Editor
              defaultValue={getValues(
                "homepage.explore_jobs.english_description"
              )}
              onChange={(_, data) =>
                setValue(
                  "homepage.explore_jobs.english_description",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>English Description Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.explore_jobs.english_description_font")}
            >
              <option value="">Select Font</option>
              {global_font_en_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
        </div>
        <br />
        <UploadImage
          label="Explore Jobs Section Background"
          image={
            uiConfig?.data?.homepage?.explore_jobs?.section_background_image
          }
          btnTextClass="px-6"
          onChange={(image) =>
            uploadImage(
              "homepage.explore_jobs.section_background_image",
              image
            ).then((url) =>
              setPhotos((values) => ({
                ...values,
                explore_jobs_section_background_image: url,
              }))
            )
          }
        />

        <h2>Homepage: Post Jobs Section</h2>
        <div className="uiConfigurationsView__section">
          {/* <Input
            label='Bangla Title'
            {...register('homepage.post_jobs.bangla_title')}
          /> */}
          <div className="d-block mt-3">
            <strong>Bangla Title</strong>
            <Editor
              defaultValue={getValues("homepage.post_jobs.bangla_title")}
              onChange={(_, data) =>
                setValue(
                  "homepage.post_jobs.bangla_title",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>Bangla Title Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.post_jobs.bangla_title_font")}
            >
              <option value="">Select Font</option>
              {global_font_bn_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <div className="d-block mt-3">
            <strong>English Title</strong>
            <Editor
              defaultValue={getValues("homepage.post_jobs.english_title")}
              onChange={(_, data) =>
                setValue(
                  "homepage.post_jobs.english_title",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>English Title Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.post_jobs.english_title_font")}
            >
              <option value="">Select Font</option>
              {global_font_en_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <div className="d-block mt-3">
            <strong>Bangla Description</strong>
            <Editor
              defaultValue={getValues("homepage.post_jobs.bangla_description")}
              onChange={(_, data) =>
                setValue(
                  "homepage.post_jobs.bangla_description",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>Bangla Description Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.post_jobs.bangla_description_font")}
            >
              <option value="">Select Font</option>
              {global_font_bn_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
          <div className="d-block mt-3">
            <strong>English Description</strong>
            <Editor
              defaultValue={getValues("homepage.post_jobs.english_description")}
              onChange={(_, data) =>
                setValue(
                  "homepage.post_jobs.english_description",
                  JSON.stringify(data)
                )
              }
            />
          </div>
          <label className="d-block mt-3">
            <strong>English Description Font</strong>
            <Select
              variant="ghost_white"
              {...register("homepage.post_jobs.english_description_font")}
            >
              <option value="">Select Font</option>
              {global_font_en_options.map((item, ind) => (
                <option key={ind} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </label>
        </div>
        <br />
        <UploadImage
          label="Post Jobs Section Background"
          image={uiConfig?.data?.homepage?.post_jobs?.section_background_image}
          btnTextClass="px-6"
          onChange={(image) =>
            uploadImage(
              "homepage.post_jobs.section_background_image",
              image
            ).then((url) =>
              setPhotos((values) => ({
                ...values,
                post_jobs_section_background_image: url,
              }))
            )
          }
        />

        <h2>Cover Photos</h2>
        <UploadImage
          image={uiConfig?.data?.cover_photos?.default_user_cover_photo}
          label="Default User Cover Photo  (Recommended 1440*300)"
          btnTextClass="px-6"
          onChange={(image) =>
            uploadImage("cover_photos.default_user_cover_photo", image).then(
              (url) =>
                setPhotos((values) => ({
                  ...values,
                  cover_photos: {
                    ...values.cover_photos,
                    default_user_cover_photo: url,
                  },
                }))
            )
          }
        />
        <UploadImage
          image={uiConfig?.data?.cover_photos?.default_job_post_cover_photo}
          label="Default Job Post Cover Photo  (Recommended 1440*300)"
          btnTextClass="px-6"
          onChange={(image) =>
            uploadImage(
              "cover_photos.default_job_post_cover_photo",
              image
            ).then((url) =>
              setPhotos((values) => ({
                ...values,
                cover_photos: {
                  ...values.cover_photos,
                  default_job_post_cover_photo: url,
                },
              }))
            )
          }
        />
        <br />
        <br />
        <div className="d-flex justify-content-end">
          <Button variant="primary" type="submit">
            SAVE CONFIG
          </Button>
        </div>
      </form>
    </QComponent>
  );
}

const global_font_en_options = [
  { label: "Roboto", value: "Roboto" },
  { label: "Ubuntu", value: "Ubuntu" },
  { label: "Parisienne", value: "Parisienne" },
  { label: "Open Sans", value: "Open Sans" },
  { label: "Open Sans", value: "Open Sans" },
  { label: "Nunito", value: "Nunito" },
  { label: "Lato", value: "Lato" },
];

const global_font_bn_options = [
  { label: "Hind Siliguri", value: "Hind Siliguri" },
  { label: "Mini", value: "Mini" },
];

const global_languages = [
  { label: "English", value: "en" },
  { label: "Bangla", value: "bn" },
];
