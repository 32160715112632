import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DealCard from "../../components/card/DealCard";
import LoadingSpinner from "../../components/util/LoadingSpinner";
import SuccessModal from "../../components/util/SuccessModal";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import "./DealDetails.css";

const DealDetails = () => {
  const auth = useContext(AuthContext);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [successModalPayment, setSuccessModalPayment] = useState(false);
  const [deal, setDeal] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { dealID } = useParams();

  const { isLoading, sendRequest } = useHttpClient();
  const onSuccess = () => {
    setRefresh(!refresh);
  };
  window.addEventListener("storage", function (e) {
    if (e.key === "P_AD") {
      onSuccess();
      setSuccessModalPayment(true);
    }
  });
  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/deal/?deal_id=${dealID}`
        );
        setDeal(responseData);
      } catch (err) {}
    };
    fetchDeal();
    //eslint-disable-next-line
  }, [refresh]);

  return (
    <div className="deal_detail_container">
      {isLoading && <LoadingSpinner asOverlay />}
      <SuccessModal
        onClear={() => {
          setSuccessModalPayment(false);
        }}
        show={successModalPayment}
        message="Payment complete. You can now access the files."
      />
      {deal && (
        <DealCard
          dealpage={auth.isManager || auth.isAdmin}
          providerDeal={
            !(auth.isManager || auth.isAdmin) &&
            auth.user &&
            auth.user._id === deal.client_id?._id
          }
          key={deal._id}
          dealId={deal._id}
          bidId={deal.bid_id}
          jobId={deal.job_id._id}
          jobDetails={deal.job_id}
          clientId={deal.client_id?._id}
          clientName={deal.client_id?.fullname}
          clientRating={deal.client_id?.client_rating}
          clientProfilePic={deal.client_id?.profile_pic}
          providerId={deal.provider_id._id}
          submitted={deal.job_submitted}
          providerName={deal.provider_id.fullname}
          providerRating={deal.provider_id.provider_rating}
          providerProfilePic={deal.provider_id.profile_pic}
          status={deal.state}
          deadline={new Date(deal.deadline).toLocaleDateString(
            undefined,
            dateOptions
          )}
          title={deal.job_id.title}
          category={deal.job_id.parent_category}
          subCategory={deal.job_id.category}
          budget={deal.job_id.max_budget}
          bidDate={new Date(deal.createdAt).toLocaleDateString(
            undefined,
            dateOptions
          )}
          claimedBudget={deal.claimed_budget}
          comment={deal.bid_id.comment}
          jobPostDate={new Date(deal.job_id.createdAt).toLocaleDateString(
            undefined,
            dateOptions
          )}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default DealDetails;
