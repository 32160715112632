import React from 'react';
import './AuthForm.css';

const LoginWith = ({ type, ...props }) => {
  return (
    <div className='icons--wrapper'>
      <div
        className='my-2 p-2 divLink '
        style={{ border: '1px solid #CCCCCC', borderRadius: '4px' }}
      >
        <a
          href={process.env.REACT_APP_BACKEND_URL + '/auth/google'}
          className='d-flex justify-content-center align-items-center'
        >
          <img
            className='mx-3'
            alt=''
            src='assets/google.svg'
            height='30px'
            width='30px'
          />
          <p style={{ color: '#666666', margin: '0px' }}>
            Sign {type === 'signup' ? 'up' : 'in'} with Google
          </p>
        </a>
      </div>
      <div
        className='my-2 p-2 divLink'
        style={{ border: '1px solid #CCCCCC', borderRadius: '4px' }}
      >
        <a
          href={process.env.REACT_APP_BACKEND_URL + '/auth/linkedin'}
          className='d-flex justify-content-center align-items-center'
          // onClick={() => loginRequest('linkedin')}
          // style={{ cursor: 'pointer' }}
        >
          <img
            className='mx-3'
            src='assets/linkedin.svg'
            alt=''
            height='30px'
            width='30px'
          />
          <p style={{ color: '#666666', margin: '0px' }}>
            Sign {type === 'signup' ? 'up' : 'in'} with Linkedin
          </p>
        </a>
      </div>
      <div
        className=' my-2 p-2 divLink '
        style={{ border: '1px solid #CCCCCC', borderRadius: '4px' }}
      >
        <a
          href={process.env.REACT_APP_BACKEND_URL + '/auth/facebook'}
          className='d-flex justify-content-center align-items-center'
          // onClick={() => loginRequest('facebook')}
          // style={{ cursor: 'pointer' }}
        >
          <img
            className='mx-3'
            src='assets/fb.svg'
            alt=''
            height='30px'
            width='30px'
          />
          <p style={{ color: '#666666', margin: '0px' }}>
            Sign {type === 'signup' ? 'up' : 'in'} with Facebook
          </p>
        </a>
      </div>
    </div>
  );
};

export default LoginWith;
