import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import './NotificationItem.css';
const NotificationItem = ({ notification, log }) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const timeOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const getWhereToRedirect = () => {
    switch (log ? notification.action : notification.type) {
      case 'JOB': {
        return `/${
          auth.isAdmin || auth.isManager ? 'approve-job' : 'my-post'
        }/${notification[log ? 'action_id' : 'content_id']}`;
      }
      case 'JOB_SUBMISSION': {
        return `/deal/${notification[log ? 'action_id' : 'content_id']}`;
      }
      case 'DEAL': {
        return `/deal/${notification[log ? 'action_id' : 'content_id']}`;
      }

      case 'BID': {
        return `/bid/${notification[log ? 'action_id' : 'content_id']}`;
      }
      default: {
        break;
      }
    }
  };

  const setAsSeen = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/notifications/${notification._id}`
      );
    } catch (err) {}
  };

  return (
    <div
      className='notification-item'
      onClick={() => {
        //setAsSeen();
        //history.push(getWhereToRedirect());
        //auth.setRefresh();
      }}
    >
      <span className='notification-text'>{notification.message}</span>
      <div>
        <p className='notification-time'>
          {new Date(notification.createdAt)
            .toLocaleDateString(undefined, timeOptions)
            .toLowerCase()}
        </p>
        <span className='notification-icon'>
          {!log && (
            <div className='notification-badge'>
              {!notification.is_seen && (
                <img src='/assets/chat_circle.png' alt='' />
              )}
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
