import React, { useEffect, useState } from 'react';
import './PaymentTerms.css';
import axios from 'axios';
import Editor from '../../../components/Editor/Editor';

function PaymentTerms(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [PaymentTerms, setPaymentTerms] = useState('');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/static-fields`)
      .then(function (response) {
        // handle success

        const obj = response.data.payment_policy;

        setPaymentTerms(obj);
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  return (
    <div
      className='container'
      style={{
        marginTop: '30px',
        padding: '30px 40px',
        border: '1px #ccc solid',
        borderRadius: '5px',
      }}
    >
      <Editor readOnly defaultValue={PaymentTerms} />

      {/* <h1 classname="alt tos-title">Somadha&#39;s Payment Terms</h1>
            <p>
               Payment Terms
            </p> */}
    </div>
  );
}

export default PaymentTerms;
