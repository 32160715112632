import React, { useContext } from "react";
import DealCardClient from "./DealCardClient";
import DealCardProvider from "./DealCardProvider";
import "./ShowDeals.css";
import { AuthContext } from "../../../context/auth-context";

function ShowDeals({ deals, loading, client }) {
  const auth = useContext(AuthContext);

  if (loading) {
    return <h2>{auth.language === "en" ? "Loading..." : "লোড হচ্ছে..."}</h2>;
  }

  return (
    <div className="">
      {deals.length > 0 ? (
        deals.map((deal) => (
          <div className="viewCards" key={deal.id}>
            {client ? (
              <DealCardClient deal={deal} />
            ) : (
              <DealCardProvider deal={deal} />
            )}
          </div>
        ))
      ) : (
        <h2 className=" text-secondary fw-bold mx-auto my-5 py-5">
          {auth.language === "en"
            ? "No Deals Found!"
            : "কোন চুক্তি পাওয়া যায়নি!"}
        </h2>
      )}
    </div>
  );
}

export default ShowDeals;
