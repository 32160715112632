import moment from 'moment';
import React from 'react';
import Modal from "../../shared/Modal/Modal";
import "./FeedbackDetailsModal.css";

export default function FeedbackDetailsModal({ open, onClose, feedbackDetails }) {
  // const history = useHistory();
  // const queryClient = useQueryClient();
  // const { mutateAsync: markIssueAsResolvedMutation } = useMutation(MARK_ISSUE_AS_RESOLVED);

  // const markIssueAsResolved = useCallback(async () => {
  //   try {
  //     await markIssueAsResolvedMutation({ issue_id: feedbackDetails?._id });
  //     await queryClient.refetchQueries("GET_ISSUES");
  //     toast.success("Successfully marked issue as resolved!");
  //     // eslint-disable-next-line no-unused-expressions
  //     onClose?.();
  //   } catch (err) {
  //     console.error(err);
  //     toast.error(err?.response?.data?.msg || err?.message);
  //   }
  // }, [feedbackDetails, markIssueAsResolvedMutation, onClose, queryClient]);

  return  (
    <Modal open={open} onClose={onClose} title="Feedback Details">
      <label className="d-block">
        <strong>Title</strong>
        <p className="py-2 px-3">{feedbackDetails?.title}</p>
      </label>
      <label className="d-block">
        <strong>Description</strong>
        <p className="py-2 px-3">{feedbackDetails?.description}</p>
      </label>
      <label className="d-block">
        <strong>By</strong>
        <p className="py-2 px-3">{feedbackDetails?.name}</p>
      </label>
      <label className="d-block">
        <strong>Email</strong>
        <p className="py-2 px-3">{feedbackDetails?.email}</p>
      </label>
      <label className="d-block">
        <strong>State</strong>
        <p className="py-2 px-3">{feedbackDetails?.state}</p>
      </label>

      <p>Posted on {moment(feedbackDetails?.createdAt).format("Do MMMM, YYYY. h:mmA")}</p>

      <div className="issueDetailsModal__btnGroup">
        {/* <Button variant="primary" onClick={() => history.push("/user/send_message")}>Contact Issuer</Button> */}
        {/* <Button variant="primary_outlined" onClick={markIssueAsResolved}>Mark as Resolved</Button> */}
      </div>
    </Modal>
  )
}