import React, { useEffect } from "react";

const PaymentSuccess = () => {
  useEffect(() => {
    localStorage.setItem("P_AD", Math.random());
    window.close();
  }, []);
  return <div></div>;
};

export default PaymentSuccess;
