import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  ADD_FAQ,
  UPDATE_FAQ,
} from '../../../lib/api/admin';
import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Modal from '../../shared/Modal/Modal';
import Editor from '../../../components/Editor/Editor';
import '../AddEditCategoryModal/AddEditCategoryModal.css';

export default function AddEditFaqModal({
  children,
  faq,
}) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: useMemo(() => faq, [faq]),
  });
  const { mutateAsync: createFaq } = useMutation(ADD_FAQ);
  const { mutateAsync: updateFaq } = useMutation(UPDATE_FAQ);

  const handleNewFaqCreate = useCallback(
    async (values) => {
      try {
        await createFaq(values);
        await queryClient.refetchQueries("GET_FAQS");
        toast.success(
          `FAQ has been created successfully`
        );
        setOpen(false);
        reset();
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [createFaq, queryClient, reset]
  );
  const handleFaqUpdate = useCallback(
    async ({ _id, ans_bn, ans_en, ques_bn, ques_en }) => {
      try {
        await updateFaq({ id: _id, ans_bn, ans_en, ques_bn, ques_en });
        await queryClient.refetchQueries("GET_FAQS");
        toast.success(
          `FAQ has been updated successfully`
        );
        setOpen(false);
        reset();
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [queryClient, reset, updateFaq]
  );

  useEffect(() => {
    reset(faq);
  }, [faq, reset]);

  return (
    <Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          },
        })}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        title={`${!faq?._id ? 'Add New' : 'Update'} FAQ`}
      >
        <form
          onSubmit={handleSubmit(
            faq?._id ? handleFaqUpdate : handleNewFaqCreate
          )}
          className='addEditCategoryModal'
          key={`AddEditCategoryModal__${open}`}
        >
          <Input label='Question EN' {...register('ques_en')} />
          <Input label='Question BN' {...register('ques_bn')} />
          <strong
            style={{
              fontSize: "17px"
            }}
          >Answer EN</strong>
          <div
            style={{
              padding: "0.375rem 0.75rem",
              fontWeight: "400",
              backgroundColor: "#fff",
              border: "1px solid #ced4da",
              borderRadius: "0.25rem",
            }}
          >
            <Editor
              defaultValue={faq?.ans_en}
              onChange={(_, data) => setValue("ans_en", JSON.stringify(data))}
            />
          </div>
          <br />
          <strong
            style={{
              fontSize: "17px"
            }}
          >Answer BN</strong>
          <div
            style={{
              padding: "0.375rem 0.75rem",
              fontWeight: "400",
              backgroundColor: "#fff",
              border: "1px solid #ced4da",
              borderRadius: "0.25rem",
            }}
          >
            <Editor
              defaultValue={faq?.ans_bn}
              onChange={(_, data) => setValue("ans_bn", JSON.stringify(data))}
            />
          </div>
          <br />
          <Button type='submit' variant='primary'>
            Save
          </Button>
        </form>
      </Modal>
    </Fragment>
  );
}
