import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { Alert, Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { UPLOAD_IMAGE } from "../../lib/api/admin";
import { toast } from "react-toastify";
import { FaPlus, FaTimes } from "react-icons/fa";

const SendFeedBack = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [show, setShow] = useState(false);
  const { mutateAsync: uploadImageMutation, isLoading } =
    useMutation(UPLOAD_IMAGE);
  const [screenshots, setScreenshots] = useState([]);

  const uploadImage = useCallback(
    async (key, image) => {
      try {
        const formData = new FormData();
        formData.append("file", image);
        const {
          data: { url },
        } = await uploadImageMutation(formData);
        toast.success("Successfully uploaded the image!");
        return url;
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [uploadImageMutation]
  );

  const postUser = async () => {
    if (!title) {
      toast.error("Title is required");
      return;
    }
    if (!description) {
      toast.error("Description is required");
      return;
    }

    setSpinnerStatus(true);

    try {
      let dataToSend = {
        name: auth.user?.fullname,
        title: title,
        email: auth.user?.email,
        description: description,
        images: screenshots,
      };
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/feedbacks",
        "POST",

        JSON.stringify(dataToSend),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData) {
        setTitle("");
        setDescription("");
        setScreenshots([]);
        setSpinnerStatus(false);
        toast.success("Feedback sent successfully");
      }
    } catch (err) {
      toast.error(err.message);

      setSpinnerStatus(false);
    }
  };

  return (
    <div className="my-5 p-5 w-50 mx-auto">
      <h2 className="fw-bold mb-5">
        {auth.language === "en" ? "Contact Us" : "অভিযোগ/পরামর্শ"}
      </h2>
      <label htmlFor="">{auth.language === "en" ? "Name*" : "নাম*"}</label>
      <input type="text" value={auth.user?.fullname} readOnly />
      <label htmlFor="">{auth.language === "en" ? "Email*" : "ইমেইল*"}</label>
      <input type="text" value={auth.user?.email} readOnly />
      <label htmlFor="">{auth.language === "en" ? "Title*" : "শিরোনাম*"}</label>
      <input
        type="text"
        value={title}
        placeholder={
          auth.language === "en" ? "subject of the topic" : "বিষয়ের বিষয়"
        }
        onChange={(e) => setTitle(e.target.value)}
      />

      <label htmlFor="">
        {auth.language === "en" ? "Description*" : "বর্ণনা*"}
      </label>
      <textarea
        className="w-100"
        style={{ border: "1px solid #CCCCCC", borderRadius: "4px" }}
        rows="7"
        placeholder={
          auth.language === "en"
            ? "Say in details what happened"
            : "কি হয়েছে বিস্তারিত বলুন"
        }
        onChange={(e) => setDescription(e.target.value)}
        value={description}
      />

      <label htmlFor="">
        {auth.language === "en" ? "Screenshots" : "স্ক্রিনশট"}
      </label>
      <div className="uiConfigurationsView__banners mt-1">
        <div>
          <label>
            <input
              type="file"
              hidden
              onChange={({ target }) => {
                uploadImage("logo", target.files[0]).then(async (url) => {
                  setScreenshots((values) => [...values, url]);
                });
              }}
            />{" "}
            {isLoading ? <center>Uploading...</center> : <FaPlus />}
          </label>
        </div>
        {screenshots?.map((img, ind) => (
          <div key={ind}>
            <span
              onClick={async () => {
                setScreenshots((values) =>
                  values.filter((_, idx) => idx !== ind)
                );
              }}
            >
              <FaTimes />
            </span>
            <img src={`${process.env.REACT_APP_BACKEND_URL}/${img}`} alt="" />
          </div>
        ))}
      </div>

      <button
        className="my-3 btn prime-btn"
        type="submit"
        disabled={isLoading}
        onClick={() => postUser()}
      >
        {auth.language === "en" ? "Submit" : "জমা দিন"}
        {spinnerStatus && (
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className="ms-1"
          />
        )}
      </button>
    </div>
  );
};

export default SendFeedBack;
