import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth-context';
import { useHttpClient } from '../../../hooks/http-hook';

const notify = (type, msg) => {
  type === 'err'
    ? toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const PaymentInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const mobileBankProviders = ['BKASH', 'NAGAD', 'ROCKET'];
  const [mobile, setMobile] = useState(null);
  const [mobileBank, setMobileBank] = useState(mobileBankProviders[0]);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');

  useEffect(() => {
    setUpdateUser();
  }, [auth.user]);

  const setUpdateUser = () => {
    if (auth.user) {
      setMobile(auth.user.mobile_banking_phone_number);
      setMobileBank(auth.user.mobile_banking_provider);
    }
  };

  const mobileBankingInfo = async () => {
    if (mobile.toString().length !== 11) {
      notify('err', 'Mobile number must be 11 character long!');
      return;
    }

    setSpinnerStatus(true);
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/update_mobile_banking_info',
        'PUT',
        JSON.stringify({
          mobile_banking_phone_number: mobile,
          mobile_banking_provider: mobileBank
            ? mobileBank
            : mobileBankProviders[0],
          password: oldPassword,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        notify('success', 'Payment Info updated successfully');
        auth.setUser();
        setSpinnerStatus(false);
        setMobile(null);
        setConfirmModal(false);
        setOldPassword('');
      } else
        throw new Error(
          responseData?.data?.msg ||
            'something wrong happened, please try again'
        );
    } catch (err) {
      notify('err', err?.response?.data?.msg || err.message);
      setSpinnerStatus(false);
      setMobile(null);
      setConfirmModal(false);
      setOldPassword('');
    }
  };

  return (
    <div className='my-5'>
      <h3
        className='mb-3'
        style={{ fontWeight: 'bold', color: 'var(--primary_color)' }}
      >
        Update Payment Info
      </h3>
      <div className='form-section'>
        <label for='mobile'>
          {' '}
          {auth.language === 'en'
            ? 'Mobile Banking Info'
            : 'মোবাইল ব্যাংকিং তথ্য'}{' '}
          <span style={{ color: 'red' }}>*</span>
        </label>
        <div className='d-flex acc-wrapper'>
          <input
            className='acc-input '
            autoComplete='off'
            type='number'
            maxLength='14'
            name='mobile'
            placeholder={
              auth.language === 'en'
                ? 'Mobile Banking Number must be 11 characters long'
                : 'মোবাইল ব্যাঙ্কিং নম্বর ১১ অক্ষর দীর্ঘ হতে হবে'
            }
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <select
            id='selectt'
            value={mobileBank}
            onChange={(e) => {
              setMobileBank(e.target.value);
            }}
            className='acc-serach'
          >
            {mobileBankProviders.map((provider) => {
              return (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='d-flex justify-content-start align-items-center flex-wrap submit-btn'>
        <div className=''>
          <button
            className='btn fw-bold my-2'
            style={{
              backgroundColor: 'var(--primary_color)',
              width: '15rem',
              color: 'white',
            }}
            onClick={() => setConfirmModal(true)}
          >
            {auth.language === 'en' ? 'Save Changes' : 'পরিবর্তনগুলোর সংরক্ষন'}
          </button>
        </div>
      </div>
      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className='form-section'>
            <h4 className='fw-bold mb-3'>
              {' '}
              {auth.language === 'en'
                ? 'Enter Current Password To Update Changes'
                : 'পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন'}
            </h4>
            <input
              type='password'
              required
              name=''
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setConfirmModal(false)}>
            {auth.language === 'en' ? 'Close' : 'বন্ধ'}
          </Button>
          <Button
            type='button'
            className='btn fw-bold'
            style={{ backgroundColor: 'var(--primary_color)' }}
            onClick={mobileBankingInfo}
          >
            {auth.language === 'en'
              ? ' Save Changes '
              : 'পরিবর্তনগুলোর সংরক্ষন'}{' '}
            {spinnerStatus && (
              <Spinner animation='border' size='sm' className='text-white' />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentInfo;
