import React, { useEffect } from "react";
import DealDetails from "../sections/deal/DealDetails";

const Deal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <DealDetails />;
};

export default Deal;
