import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminLayout from '../../../components/AdminLayout';
import AdminTableView from '../../../components/AdminLayout/AdminComponentView/AdminTableView';
import AdminResetPasswordModal from '../../../components/modals/AdminResetPasswordModal/AdminResetPasswordModal';
import Button from '../../../components/shared/Button/Button';
import Dropdown from '../../../components/shared/Dropdown/Dropdown';
import QComponent from '../../../components/shared/QComponent/QComponent';
import {
  ELEVATE_USER,
  GET_USERS,
  IMPERSONATE_USER,
} from '../../../lib/api/admin';

export default function ManageAdmins() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchUser, setSearchUser] = useState(null);

  const [sort, setSort] = useState({ fullname: 'asc' });
  const {
    data: usersData,
    status,
    error,
    refetch,
  } = useQuery(
    [
      'GET_ADMIN_MANAGERS',
      {
        page,
        ...(search ? { search } : {}),
        sort: JSON.stringify(sort),
        filter: JSON.stringify({ user_type: { $ne: 'USER' } }),
      },
    ],
    GET_USERS
  );
  const { data: allUsersData } = useQuery(
    [
      'GET_USERS',
      {
        filter: JSON.stringify({ user_type: 'USER' }),
        ...(searchUser ? { search: searchUser } : {}),
      },
    ],
    GET_USERS
  );
  const { mutateAsync: impersonateUser } = useMutation(IMPERSONATE_USER);
  const { mutateAsync: elevateUser } = useMutation(ELEVATE_USER);
  const [resetPasswordUser, setResetPasswordUser] = useState(null);

  const handleImpersonateUser = useCallback(
    async (id) => {
      try {
        const { data } = await impersonateUser(id);
        window.localStorage.setItem('auth_token', data.url.split('/')[2]);
        toast.success('Successfully impersonated the user!');
        window.location.href = '/';
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [impersonateUser]
  );
  const downgradeToManager = useCallback(
    async (id) => {
      try {
        await elevateUser({ user_id: id, rank: 'MANAGER' });
        await refetch();
        toast.success('Successfully downgraded to manager!');
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [elevateUser, refetch]
  );
  const upgradeToAdmin = useCallback(
    async (id) => {
      try {
        await elevateUser({ user_id: id, rank: 'ADMIN' });
        await refetch();
        toast.success('Successfully upgraded to admin!');
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [elevateUser, refetch]
  );
  const downgradeToUser = useCallback(
    async (id) => {
      try {
        await elevateUser({ user_id: id, rank: 'USER' });
        await refetch();
        toast.success('Successfully downgraded to user!');
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [elevateUser, refetch]
  );
  const handleMakeAdmin = useCallback(
    async (e) => {
      e.preventDefault();
      e.persist();
      try {
        const value = e.target?.create_admin_id?.value;
        const rank = e.target?.rank?.value;
        const user_id = allUsersData?.data?.data.find(
          (u) => u.email === value
        )?._id;
        await elevateUser({ user_id, rank });
        if (e.target?.create_admin_id) {
          e.target.create_admin_id.value = '';
        }
        await refetch();
        toast.success('Successfully created a new admin!');
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [allUsersData, elevateUser, refetch]
  );

  return (
    <>
      {/* Modals */}
      <AdminResetPasswordModal
        open={!!resetPasswordUser}
        data={resetPasswordUser}
        onClose={() => setResetPasswordUser(null)}
      />

      <AdminLayout>
        <h3 style={{ fontWeight: 'bold' }}>Manage Admin</h3>

        <form onSubmit={handleMakeAdmin} className='adminTableView__search'>
          <label>Create Admin</label>
          <div className='manageAdmins__makeAdmin'>
            <Dropdown
              options={allUsersData?.data?.data.map((u) => ({
                label: u.email,
                value: u._id,
              }))}
              elChange={({ target }) => setSearchUser(target.value)}
            >
              <input
                type='text'
                name='create_admin_id'
                className='form-control'
                placeholder='Search User by email...'
              />
            </Dropdown>
            <select name='rank' className='form-control'>
              <option value=''>Select Role</option>
              <option value='ADMIN'>Admin</option>
              <option value='MANAGER'>Manager</option>
            </select>
            <Button variant='primary' type='submit'>
              Add
            </Button>
          </div>
        </form>

        <AdminTableView
          query={{ status, error }}
          search={{
            title: 'Search User',
            placeholder: 'Search user by name, email...',
            btnText: 'Search User',
            onSearch: (value) => setSearch(value),
          }}
          sort={{
            title: 'Sort By',
            selects: [
              {
                name: 'Sort By',
                options: [
                  { label: 'Name (ASC)', value: 'fullname:1' },
                  { label: 'Name (DESC)', value: 'fullname:-1' },
                  { label: 'Email (ASC)', value: 'email:1' },
                  { label: 'Email (DESC)', value: 'email:-1' },
                  { label: 'Role (ASC)', value: 'user_type:1' },
                  { label: 'Role (DESC)', value: 'user_type:-1' },
                ],
                onSort: ({ target }) => {
                  const [key, value] = target?.value?.split(':');
                  setSort((values) => ({ [key]: +value }));
                },
              },
            ],
          }}
          table={{
            headers: [
              { name: 'Name', dotsMenu: [], key: 'name' },
              { name: 'Email Address', dotsMenu: [], key: 'email' },
              { name: 'Role', dotsMenu: [], key: 'role' },
              {
                name: '',
                dotsMenu: [
                  {
                    name: 'View Profile',
                    onClick: (data) => history.push(`/profile/${data?._id}`),
                  },
                  {
                    name: 'Impersonate',
                    onClick: (data) => handleImpersonateUser(data._id),
                  },
                  {
                    name: 'Reset Password',
                    onClick: (data) => setResetPasswordUser(data),
                  },
                  {
                    name: 'Downgrade to Manager',
                    onClick: (data) => downgradeToManager(data?._id),
                    show: 'role:ADMIN',
                  },
                  {
                    name: 'Upgrade to Admin',
                    onClick: (data) => upgradeToAdmin(data?._id),
                    show: 'role:MANAGER',
                  },
                  {
                    name: 'Remove Role (Make User)',
                    onClick: (data) => downgradeToUser(data?._id),
                  },
                ],
                key: 'actions',
              },
            ],
            data: usersData?.data?.data.map((u) => ({
              _id: u._id || '',
              name: u?.fullname || 'N/A',
              email: u?.email || 'N/A',
              role: u?.user_type || 'N/A',
              actions: '',
            })),
            pagination: {
              total: usersData?.data?.total,
              page,
              prev: usersData?.data?.prev,
              next: usersData?.data?.next,
              setPage,
            },
          }}
        />
      </AdminLayout>
    </>
  );
}
