import FileSaver from 'file-saver';
import React, { useState } from 'react';
import './ClientPrintDealDetails.css';
import Dropdown from './Dropdown/Dropdown';
import { ImagePreviewModal } from './ImagePreviewModal/ImagePreviewModal';

export default function JobDetailsDealDetails(props) {
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => setIsOpen(true);
  const saveFile = (url) => {
    FileSaver.saveAs(process.env.REACT_APP_BACKEND_URL + '/' + url, url);
  };
  return (
    <div className='clientPrintDealDetails__jobDetails'>
      <h3>
        Job Details{' '}
        <Dropdown
          options={[
            { label: 'Mark Deal as Failed', onClick: () => {} },
            { label: 'Update Deadline', onClick: () => {} },
            { label: 'Close Deal Successfully', onClick: () => {} },
          ]}
        />
      </h3>
      <div className='clientPrintDealDetails__divider' />
      <br />
      <h2>{props.property?.job_id.title}</h2>
      <span>{props.property?.job_id.category[0]}</span>
      <span>Posted on {props.property?.job_id.createdAt.substring(0, 10)}</span>
      <span>Budget {props.property?.claimed_budget} BDT</span>
      <p>{props.property?.job_id.description}</p>

      {/* <div className="clientPrintDealDetails__attachments">
				<h3>
					<FaPaperclip /> Attachments
				</h3>
				<p>No Attachments for this post</p>
			</div> */}
      <div className='attachment'>
        <img src='/assets/attachments.svg' alt='' />
        Attachments
      </div>
      {props.property?.job_id.attachments &&
        props.property?.job_id.attachments.length === 0 && (
          <p className='p6'>no attachments available</p>
        )}
      <table>
        {props.property?.job_id.attachments &&
          props.property?.job_id.attachments.map((attachment) => {
            return (
              <tr>
                <td key={attachment._id}>
                  {/* <a
                          target="_new"
                          href={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                        >
                          <p className="p6">{attachment.substr(6)}</p>
                        </a> */}
                  <img
                    className='img_preview'
                    style={{ height: '70px' }}
                    // onClick={() => {
                    //   saveFile(attachment);
                    // }}
                    onClick={showModal}
                    src={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                    alt=''
                  />

                  {isOpen && (
                    <ImagePreviewModal
                      src={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                      alt='snow'
                      onClose={() => setIsOpen(false)}
                    />
                  )}
                </td>
                <td>
                  <img
                    className='download__icon'
                    onClick={() => {
                      saveFile(attachment);
                    }}
                    src='/assets/download.svg'
                    alt=''
                  />
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
}
