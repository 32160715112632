import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { AuthContext } from "../../context/auth-context";
import Referal from "../modals/Profile/Referal";
const Footer = () => {
  const [showReferal, setShowReferal] = useState(false);
  const auth = useContext(AuthContext);
  return (
    <div className="footer">
      <div className="main-container">
        <div className="footer__left">
          <img src="/assets/new/somadha_logo.svg" alt="logo" />

          <h3>Contact</h3>
          <p>contact@email.address</p>
          <p>+8801234567890</p>
          <br />
          <h3>Follow us on Social Media</h3>
          <div className="social-link">
            <a href="/" target="_blank">
              <img
                src="/assets/new/facebook-icon.svg"
                alt="facebook"
                className="m-2"
              />
            </a>
            <a href="/" target="_blank">
              <img
                src="/assets/new/instagram-icon.svg"
                alt="instagram"
                className="m-2"
              />
            </a>
            <a href="/" target="_blank">
              <img
                src="/assets/new/youtube-icon.svg"
                alt="youtube"
                className="m-2"
              />
            </a>
            <a href="/" target="_blank">
              <img
                src="/assets/new/twitter-icon.svg"
                alt="twitter"
                className="m-2"
              />
            </a>
            <a href="/" target="_blank">
              <img
                src="/assets/new/linkedin-icon.svg"
                alt="linkedin"
                class="m-2"
              />
            </a>
          </div>
        </div>

        <div className="footer__right">
          <div className="footer__column">
            <h3>Somadha</h3>
            <Link to="/">Homepage</Link>
            <Link to="/explore/all">Find Job Posts</Link>
            <Link to="/providers/all">Find Providers</Link>
            <Link to="/user/profile">My Profile</Link>
            <Link to="/about">About us</Link>
            {/* <Link to="/referrals">Referrals</Link> */}
          </div>
          <div className="footer__column">
            <h3>Docs</h3>
            <Link to="/terms">Terms of Services</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/payment-terms">Payment Terms</Link>
            <Link to="/faq">FAQs</Link>
            <Link to="/user/profile">Report an Issue</Link>
            <Link to="/send-feedback">Contact Us</Link>
          </div>
        </div>
      </div>
      <div className="footer__copy">
        <p>Somadha 2021 © All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
