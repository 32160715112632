import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import "../../hireo_assets/css/style.css";
import { useHttpClient } from "../../hooks/http-hook";
import Texts from "../../util/Texts";
import "./NEwNavBar.css";
import Select from "../shared/Select/Select";
import { useRef } from "react";
import { useQuery } from "react-query";
import { GET_NOTIFICATIONS } from "../../lib/api/admin";
import axios from "axios";
import { toast } from "react-toastify";
import Offcanvas from "react-bootstrap/Offcanvas";

//TODO: Responsive

const NewNavBar = ({ socket }) => {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  //hooks for nav drawer stuffs
  let texts = Texts();
  const [click, setClick] = useState(false);
  const [drawer, setDrawer] = useState(true);
  const [dropShadow, setDropShadow] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [lang, setLang] = useState();
  const [userData, setUserData] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data: notificationsData } = useQuery(
    [
      GET_NOTIFICATIONS.name,
      {
        page: 1,
      },
    ],
    GET_NOTIFICATIONS,
    {
      enabled: !!auth?.user,
    }
  );

  const LoggedInSidebarMenu = [
    {
      icon: "/setting-2.svg",
      name: "Control Panel",
      bangla: "নিয়ন্ত্রণ প্যানেল",
      url: "/admin/system-report",
      access: "private",
      loggedIn: true,
    },
    {
      icon: "/element-3.svg",
      name: "Browse Jobs",
      bangla: "চাকরি ব্রাউজ করুন",
      url: "/explore/all",
      loggedIn: "both",
    },
    {
      icon: "/global.svg",
      name: "Find Providers",
      bangla: "প্রদানকারীরা খুঁজুন",
      url: "/providers/:all",
      loggedIn: "both",
    },
    {
      icon: "/share.svg",
      name: "Language",
      bangla: "ভাষা",
      loggedIn: "both",
    },
    {
      icon: "/profile-circle.svg",
      name: "Profile",
      bangla: "বৃত্তান্ত",
      url: "/user/profile",
      loggedIn: true,
    },
    {
      icon: "/profile-circle.svg",
      name: "Edit Profile",
      bangla: "বৃত্তান্ত সম্পাদনা",
      url: "/user/edit_profile",
      loggedIn: true,
    },
    {
      icon: "/message.svg",
      name: "Message",
      bangla: "বার্তা",
      url: "/messages",
      loggedIn: true,
    },
    {
      icon: "/notification.svg",
      name: "Notifications",
      bangla: "বিজ্ঞপ্তি",
      url: "/notifications",
      loggedIn: true,
    },
    {
      icon: "/note.svg",
      name: "Job Posts",
      bangla: "চাকরির পোস্ট",
      url: "/user/jobPosts",
      loggedIn: true,
    },
    {
      icon: "/add-square.svg",
      name: "Post Job",
      bangla: "চাকরি পোস্ট করুন",
      url: "/user/postJob",
      loggedIn: true,
    },
    {
      icon: "/task-square.svg",
      name: "My Bids",
      bangla: "আমার বিড",
      url: "/user/bids",
      loggedIn: true,
    },
    {
      icon: "/clipboard-tick.svg",
      name: "My Deals",
      bangla: "আমার চুক্তি",
      url: "/user/deals",
      loggedIn: true,
    },
    {
      icon: "/empty-wallet.svg",
      name: "Payments",
      bangla: "পেমেন্টস",
      url: "/user/payment",
      loggedIn: true,
    },
    {
      icon: "/logout.svg",
      name: "Logout",
      bangla: "লগআউট",
      url: "/login",
      loggedIn: true,
    },
    {
      icon: "/logout.svg",
      name: "Log in",
      bangla: "লগইন",
      url: "/login",
      loggedIn: false,
    },
    {
      icon: "/logout.svg",
      name: "Sign Up",
      bangla: "সাইন আপ",
      url: "/signup",
      loggedIn: false,
    },
  ];

  let hamburgerRef = useRef(null);

  useEffect(() => {
    setUserData({ name: auth?.user?.fullname, email: auth?.user?.email });
  }, [auth]);

  const closeMobileMenu = () => setClick(false);

  const globals = async () => {
    try {
      const res = await axios.get("https://somadha.com/api/ui-config", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    globals();
    getLang();

    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
  }, []);

  const getLang = async () => {
    const resUser = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/user",
      {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      }
    );
    setLang(resUser.data.language);
  };

  const langFromStorage = localStorage.getItem("lang");

  if (auth.user) {
    const languageMapping = { Bangla: "en", English: "bn" };
    if (lang && auth.language === languageMapping[lang]) {
      auth.changeLanguage();
    }
  } else if (
    (langFromStorage === "en" && auth.language === "bn") ||
    (langFromStorage === "bn" && auth.language === "en")
  ) {
    auth.changeLanguage();
  }

  const showDrawer = () => {
    if (window.innerWidth <= 960) {
      setDrawer(false);
    } else {
      setDrawer(true);
    }
  };

  useEffect(() => {
    showDrawer();
    auth.user && checkNewMsg();
    //eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    auth.user && checkNewNotification();
    //eslint-disable-next-line
  }, [auth.user, auth.refresh]);

  useEffect(() => {
    if (socket) {
      const handleMessage = (data) => {
        setHasNewMessage(true);
        toast.info(
          <div>
            <span style={{ fontWeight: "bold" }}>{data.msg.sender_name}</span>
            <br />
            {data.msg.content}
          </div>
        );
      };

      const handleNotification = (data) => {
        setHasNewNotification(true);
        toast.info(
          <div>
            <span style={{ fontWeight: "bold" }}>New Notification</span>
            <br />
            {data.message}
          </div>
        );
      };

      socket.on("new_message", handleMessage);
      socket.on("notification", handleNotification);

      // Clean up the event listeners when the component unmounts
      return () => {
        socket.off("new_message", handleMessage);
        socket.off("notification", handleNotification);
      };
    }
  }, [socket]);

  window.addEventListener("resize", showDrawer);
  const changeDropShadow = () => {
    if (window.scrollY >= 72) {
      setDropShadow(true);
    } else {
      setDropShadow(false);
    }
  };
  window.addEventListener("scroll", changeDropShadow);

  const checkNewMsg = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/has_new_messages`
      );
      setHasNewMessage(responseData);
    } catch (err) {}
  };
  const checkNewNotification = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/has_new_notifications`
      );
      setHasNewNotification(responseData);
    } catch (err) {}
  };

  const languageHandler = async () => {
    let lang = "English";
    if (auth.language === "en") {
      lang = "Bangla";
    }

    try {
      if (auth.user) {
        console.log("landuage changed");
        const res = await axios.put(
          process.env.REACT_APP_BACKEND_URL + "/user/change_language",
          {
            language: lang,
          },
          {
            headers: {
              "x-access-token": localStorage.getItem("auth_token"),
            },
          }
        );
      }

      if (localStorage.getItem("lang") === "en") {
        localStorage.setItem("lang", "bn");
      } else {
        localStorage.setItem("lang", "en");
      }
      auth.changeLanguage();
      getLang();
    } catch (error) {
      // Handle errors here
      console.error("Error changing language:", error);
    }
  };

  const [notifications, setNotifications] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (auth?.user) {
      fetchNotifications();
      fetchAllMessages();
    }
  }, [auth.user]);

  const fetchNotifications = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/user/get_all_notifications/?page=${1}`
      );
      setNotifications(responseData.data);
    } catch (err) {}
  };

  const fetchAllMessages = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/find_all_message_threads"
      );
      setMessages(responseData);
    } catch (err) {}
  };

  const handleNotification = () => {
    setShowNotification(!showNotification);
    setShowMessages(false);
  };
  const handleMessage = () => {
    setShowNotification(false);
    setShowMessages(!showMessages);
  };

  function useDisappearNotificationBlock(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowNotification(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const notificationRef = useRef(null);
  useDisappearNotificationBlock(notificationRef);

  const messageRef = useRef(null);
  function useDisappearMessageBlock(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMessages(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useDisappearMessageBlock(messageRef);

  function useDisappearHamburgerBlock(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeMobileMenu();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useDisappearHamburgerBlock(hamburgerRef);
  // useEffect(()=>{
  //   console.log(auth.uiConfig?.logo);
  // })
  return (
    <header
      id="header-container"
      className="fullwidth"
      style={{ position: "sticky" }}
    >
      <div id="header">
        <div className="container">
          <div className="left-side d-flex">
            <div id="logo">
              <a href="/">
                <img src={auth.uiConfig?.logo} alt="somadha logo" />
              </a>
            </div>
            <nav id="navigation">
              <ul id="responsive">
                <li>
                  <a href="#" style={{ color: "rgba(0,0,0,.8)" }}>
                    {" "}
                    {auth.language === "en"
                      ? "For Providers"
                      : "ফ্রিল্যান্সারদের জন্য"}
                  </a>
                  <ul className="dropdown-nav">
                    <li>
                      <a href="/user/deals">
                        {auth.language === "en"
                          ? "Browse Deals"
                          : "চুক্তি খুঁজুন"}
                      </a>
                    </li>

                    <li>
                      <a href="/explore/all">
                        {auth.language === "en" ? " Browse Jobs" : "কাজ খুঁজুন"}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" style={{ color: "rgba(0,0,0,.8)" }}>
                    {auth.language === "en" ? "For Clients" : "গ্রাহকদের জন্য"}
                  </a>
                  <ul className="dropdown-nav">
                    <li>
                      <a href="/consultants/:all">
                        {auth.language === "en"
                          ? "Find Consultant"
                          : "পরামর্শদাতা খুঁজুন"}
                      </a>
                    </li>
                    <li>
                      <a href="/providers/:all">
                        {auth.language === "en"
                          ? "Find Provider"
                          : "ফ্রিল্যান্সার খুঁজুন"}
                      </a>
                    </li>
                    <li>
                      <a href="/user/postJob">
                        {auth.language === "en"
                          ? "Post A Job"
                          : "কাজ প্রদান করুন"}
                      </a>
                    </li>

                    <li>
                      <a href="/user/jobPosts">
                        {auth.language === "en"
                          ? "Posted Job"
                          : "প্রদত্ত কাজসমূহ"}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="navbar-lang-sec d-flex justify-content-center align-items-center mx-2">
              <Select className="" onChange={languageHandler}>
                {auth.language === "en" ? (
                  <>
                    <option
                      className=""
                      value="ENGLISH"
                      selected
                      style={{ backgroundColor: "var(--grey_six)" }}
                    >
                      English
                    </option>
                    <option
                      className=""
                      value="বাংলা"
                      style={{ backgroundColor: "var(--grey_six)" }}
                    >
                      বাংলা
                    </option>
                  </>
                ) : (
                  <>
                    {" "}
                    <option value="ENGLISH" className="">
                      English
                    </option>
                    <option value="বাংলা" selected className="">
                      বাংলা
                    </option>
                  </>
                )}
              </Select>
            </div>

            <div className="clearfix"></div>
          </div>

          <div className="right-side" ref={hamburgerRef}>
            {!auth.isLoggedIn && (
              <>
                <nav id="navigation">
                  <ul id="responsive">
                    <li className="">
                      <a
                        href="/about"
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        <div className="nav--item--detail">
                          <p>
                            {auth.language === "en"
                              ? "About Us"
                              : "আমাদের সম্পর্কে"}{" "}
                          </p>
                        </div>
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="/login"
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        <div className="nav--item--detail">
                          <p>{texts.login}</p>
                        </div>
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="/sign-up"
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        <div className="nav--item--detail">
                          <p>
                            {auth.language === "en" ? "SignUp" : "নিবন্ধন করুন"}
                          </p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </nav>
              </>
            )}
            {auth.isLoggedIn && (
              <>
                <div className="header-widget hide-on-mobile">
                  <div className="header-notifications">
                    <div
                      className="header-notifications-trigger"
                      onClick={handleNotification}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={"/assets/newIcons/notifications.svg"}
                        alt="notification"
                      />
                      {hasNewNotification && (
                        <span
                          style={{
                            backgroundColor: "var(--primary_color)",
                            color: "var(--text)",
                          }}
                        ></span>
                      )}
                    </div>

                    <div className="header-notifications-dropdown">
                      <div className="header-notifications-headline">
                        <h4>
                          {auth.language === "en"
                            ? "Notifications"
                            : "বিজ্ঞপ্তি"}
                        </h4>
                        <button
                          className="mark-as-read ripple-effect-dark"
                          title="Mark all as read"
                          data-tippy-placement="left"
                        >
                          <i className="icon-feather-check-square"></i>
                        </button>
                      </div>
                    </div>
                    {showNotification && (
                      <div ref={notificationRef}>
                        <div className="notification-dropdown-custom">
                          <h3
                            style={{
                              borderBottom: "1px solid #e6e6e6",
                              padding: "1rem 2rem",
                            }}
                          >
                            {auth.language === "en"
                              ? "Notifications"
                              : "বিজ্ঞপ্তি"}
                          </h3>
                          <ul
                            className="m-0 p-0"
                            style={{ maxHeight: "13rem", overflowY: "scroll" }}
                          >
                            {notificationsData?.data?.data?.length > 0 ? (
                              notificationsData?.data?.data?.map((dt, idx) => (
                                <li
                                  className=""
                                  key={idx}
                                  onClick={handleNotification}
                                >
                                  <a href="/notifications">
                                    {" "}
                                    <strong>{dt.type}:</strong>{" "}
                                    <span>
                                      {auth.language === "en"
                                        ? dt.message
                                        : dt.message_bn}
                                    </span>
                                  </a>
                                </li>
                              ))
                            ) : (
                              <h2
                                className="text-center p-2"
                                style={{ color: "var(--text)" }}
                              >
                                {auth.language === "en"
                                  ? " No Notification!"
                                  : "কোন বিজ্ঞপ্তি নেই!"}
                              </h2>
                            )}
                          </ul>
                          {notificationsData?.data?.data?.length > 0 && (
                            <a
                              href="/notifications"
                              onClick={handleNotification}
                            >
                              <button
                                className="btn w-100 "
                                style={{
                                  backgroundColor: "var(--primary_color)",
                                  borderRadius: "0",
                                  color: "var(--text)",
                                  fontWeight: "bold",
                                }}
                              >
                                {auth.language === "en" ? " See All" : "সবগুলো"}
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="header-notifications">
                    <div
                      className="header-notifications-trigger"
                      onClick={handleMessage}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={"/assets/newIcons/messages.svg"}
                        alt="message"
                      />
                      {hasNewMessage && (
                        <span
                          style={{
                            backgroundColor: "var(--primary_color)",
                            color: "var(--text)",
                          }}
                        ></span>
                      )}
                    </div>

                    <div className="">
                      {showMessages && (
                        <div ref={messageRef}>
                          <div className="message-dropdown-custom">
                            <h3
                              style={{
                                borderBottom: "1px solid #e6e6e6",
                                textAlign: "center",
                                padding: "1rem 0",
                              }}
                            >
                              {auth.language === "en" ? "Messages" : "বার্তা"}
                            </h3>
                            <ul
                              className="p-0"
                              style={{
                                flex: "1",
                                maxHeight: "12rem",
                                overflowY: "scroll",
                                marginBottom: "0",
                              }}
                            >
                              {messages.length > 0 ? (
                                messages.map((dt, idx) => (
                                  <li
                                    className=""
                                    key={idx}
                                    onClick={handleMessage}
                                    style={{
                                      padding: "1rem",
                                    }}
                                  >
                                    <a href="/messages">
                                      {" "}
                                      {/* <strong>{dt.type}:</strong>{' '} */}
                                      <div className="d-flex flex-column">
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            color: "rgba(0,0,0,.7)",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {dt.member[1].fullname}
                                        </div>
                                        <span
                                          style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            fontSize: "13px",
                                            color: "rgba(0,0,0,.5)",
                                          }}
                                        >
                                          {dt.last_message}
                                        </span>
                                      </div>
                                    </a>
                                  </li>
                                ))
                              ) : (
                                <h3
                                  className="text-center p-2"
                                  style={{ color: "var(--text)" }}
                                >
                                  {auth.language === "en"
                                    ? "No Messages!"
                                    : "কোন বার্তা নেই!"}
                                </h3>
                              )}
                            </ul>
                            <a href="/messages" onClick={handleMessage}>
                              <button
                                className="btn w-100 "
                                style={{
                                  backgroundColor: "var(--primary_color)",
                                  borderRadius: "0",
                                  color: "var(--text)",
                                  fontWeight: "bold",
                                }}
                              >
                                {auth.language === "en"
                                  ? "View All Messages"
                                  : "সমস্ত বার্তা দেখুন"}
                              </button>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="header-widget">
                  <div className="header-notifications user-menu">
                    <div className="header-notifications-trigger dropdown-nav">
                      <div className="user-avatar status-online">
                        <img
                          src={
                            (auth.user?.profile_pic &&
                              auth?.user?.profile_pic) ||
                            "/assets/profile.svg"
                          }
                          placeholder="/assets/profile.svg"
                          alt=""
                          style={{ color: "#333333" }}
                          height="42px"
                          width="42px"
                        />
                      </div>
                      <ul className="user-menu-small-nav-custom">
                        <li
                          style={{
                            color: "white",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {userData?.name}
                        </li>
                        <li
                          style={{
                            color: "white",
                            fontSize: "15px",
                            marginBottom: "1rem",
                          }}
                        >
                          {userData?.email}
                        </li>
                        <li>
                          {(auth?.user?.user_type === "ADMIN" ||
                            auth?.user?.user_type === "MANAGER") && (
                            <a href="/admin">
                              {auth.language === "en"
                                ? "Control Panel"
                                : "কন্ট্রোল প্যানেল"}
                            </a>
                          )}
                        </li>

                        {/* <li>
                            <a href='/user/profile'>
                              {' '}
                              {auth.language === 'en' ? 'Profile' : 'বৃত্তান্ত'}
                            </a>
                          </li> */}

                        <li className="">
                          <a href="/user/profile">
                            {/* <i className='icon-material-outline-dashboard me-2'></i>{' '} */}
                            {auth.language === "en" ? "Profile" : "বৃত্তান্ত"}
                          </a>
                        </li>
                        <li>
                          <a href="/user/edit_profile">
                            {/* <i className='icon-material-outline-settings me-2'></i>{' '} */}
                            {auth.language === "en"
                              ? "Edit Profile"
                              : "বৃত্তান্ত সম্পাদনা"}
                          </a>
                        </li>
                        <li>
                          <a href="/login" onClick={() => auth.logout()}>
                            {/* <i className='icon-material-outline-power-settings-new me-2'></i> */}

                            {auth.language === "en" ? "Logout" : "প্রস্থান"}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}

            <img
              className="mmenu-trigger hamburger hamburger--collapse"
              onClick={handleShow}
              src={require("./assets/hamburger.svg")}
              alt=""
            />
            {auth.isLoggedIn && click && (
              <div>
                <ul className="nav-mobile-view">
                  <li>
                    <a href="/notifications">
                      {auth.language === "en"
                        ? "Notification"
                        : "বিজ্ঞপ্তি                                "}
                    </a>
                  </li>

                  <li>
                    <a href="/messages">
                      {auth.language === "en" ? "Messages" : "বার্তা"}
                    </a>
                  </li>
                </ul>
              </div>
            )}

            {!auth.isLoggedIn && click && (
              <div ref={hamburgerRef}>
                <ul className="mobile-view-custom">
                  <li className="">
                    <a
                      href="/about"
                      className="nav-links"
                      onClick={closeMobileMenu}
                      style={{ color: "var(--grey_five)" }}
                    >
                      {auth.language === "en" ? "About" : "সম্পর্কিত"}
                    </a>
                  </li>

                  <li className="">
                    <a
                      href="/login"
                      className="nav-links"
                      onClick={closeMobileMenu}
                      style={{ color: "var(--grey_five)" }}
                    >
                      {auth.language === "en" ? "Login" : "নিবন্ধন করুন"}
                    </a>
                  </li>

                  <li className="">
                    <a
                      href="/sign-up"
                      className="nav-links"
                      onClick={closeMobileMenu}
                      style={{ color: "var(--grey_five)" }}
                    >
                      {auth.language === "en" ? "SignUp" : "নিবন্ধন করুন"}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            style={{ padding: "1rem" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      (auth.user?.profile_pic && auth?.user?.profile_pic) ||
                      "/assets/profile.svg"
                    }
                    placeholder="/assets/profile.svg"
                    alt=""
                    style={{ color: "#333333", borderRadius: "1000px" }}
                    height="60px"
                    width="60px"
                  />
                  <div>
                    <p
                      style={{
                        color: "black",
                        textTransform: "capitalize",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "0",
                      }}
                    >
                      {userData?.name}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "15px",
                        marginBottom: "0rem",
                      }}
                    >
                      {userData?.email}
                    </p>
                  </div>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {auth.user &&
                LoggedInSidebarMenu?.map((menu) => {
                  if (
                    (auth?.user?.user_type !== "ADMIN" &&
                      auth?.user?.user_type !== "MANAGER" &&
                      menu.name === "Control Panel") ||
                    menu.loggedIn === false
                  ) {
                    return null;
                  }
                  return (
                    <a
                      href={menu?.url || "#"}
                      onClick={() =>
                        menu.name === "Logout" ? auth.logout() : null
                      }
                    >
                      <div
                        key={menu.link}
                        className="sidebar-option"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={"/assets/sidebarSvg" + menu.icon}
                            placeholder="/assets/profile.svg"
                            alt="Profile"
                            style={{ color: "#333333" }}
                            height="30px"
                            width="30px"
                          />
                          <p style={{ marginBottom: "0" }}>
                            {auth.language === "en" ? menu.name : menu.bangla}
                          </p>
                        </div>

                        {menu.name === "Language" && (
                          <div>
                            <Select
                              className=""
                              onChange={languageHandler}
                              style={{ width: "120px" }}
                            >
                              {auth.language === "en" ? (
                                <>
                                  <option
                                    className=""
                                    value="ENGLISH"
                                    selected
                                    style={{
                                      backgroundColor: "var(--grey_six)",
                                    }}
                                  >
                                    English
                                  </option>
                                  <option
                                    className=""
                                    value="বাংলা"
                                    style={{
                                      backgroundColor: "var(--grey_six)",
                                    }}
                                  >
                                    বাংলা
                                  </option>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <option value="ENGLISH" className="">
                                    English
                                  </option>
                                  <option value="বাংলা" selected className="">
                                    বাংলা
                                  </option>
                                </>
                              )}
                            </Select>
                          </div>
                        )}
                      </div>
                    </a>
                  );
                })}

              {!auth.user &&
                LoggedInSidebarMenu?.map((menu) => {
                  if (
                    (auth?.user?.user_type !== "ADMIN" &&
                      auth?.user?.user_type !== "MANAGER" &&
                      menu.name === "Control Panel") ||
                    menu.loggedIn === true
                  ) {
                    return null;
                  }
                  return (
                    <a
                      href={menu?.url || "#"}
                      onClick={() => {
                        if (menu.name === "Logout") {
                          setShow(false);
                          auth.logout();
                        }
                      }}
                    >
                      <div
                        key={menu.link}
                        className="sidebar-option"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={"/assets/sidebarSvg" + menu.icon}
                            placeholder="/assets/profile.svg"
                            alt="Profile"
                            style={{ color: "#333333" }}
                            height="30px"
                            width="30px"
                          />
                          <p style={{ marginBottom: "0" }}>
                            {auth.language === "en" ? menu.name : menu.bangla}
                          </p>
                        </div>

                        {menu.name === "Language" && (
                          <div>
                            <Select
                              className=""
                              onChange={languageHandler}
                              style={{ width: "120px" }}
                            >
                              {auth.language === "en" ? (
                                <>
                                  <option
                                    className=""
                                    value="ENGLISH"
                                    selected
                                    style={{
                                      backgroundColor: "var(--grey_six)",
                                    }}
                                  >
                                    English
                                  </option>
                                  <option
                                    className=""
                                    value="বাংলা"
                                    style={{
                                      backgroundColor: "var(--grey_six)",
                                    }}
                                  >
                                    বাংলা
                                  </option>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <option value="ENGLISH" className="">
                                    English
                                  </option>
                                  <option value="বাংলা" selected className="">
                                    বাংলা
                                  </option>
                                </>
                              )}
                            </Select>
                          </div>
                        )}
                      </div>
                    </a>
                  );
                })}
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </header>
  );
};

export default NewNavBar;
