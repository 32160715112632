import React, { useContext } from "react";
import BidCard from "./BidCard";
import "./ShowBids.css";
import { AuthContext } from "../../../context/auth-context";

function ShowBids({ bids, loading, refetch}) {
  const auth = useContext(AuthContext);

  if (loading) {
    return <h2>{auth.language === "en" ? "Loading..." : "লোড হচ্ছে..."}</h2>;
  }

  return (
    <div className="">
      {bids.length > 0 ? (
        bids.map((bid) => (
          <div key={bid.id}>
            <BidCard bid={bid} refetch={refetch}/>
          </div>
        ))
      ) : (
        <h2 className=" text-secondary fw-bold mx-auto my-5 py-5">
          {auth.language === "en"
            ? "No Bids Found! "
            : "কোন দরপত্র  পাওয়া যায়নি!"}{" "}
        </h2>
      )}
    </div>
  );
}

export default ShowBids;
