import './Backdrop.css';

const Backdrop = (props) => {
  // return ReactDOM.createPortal(
  //   <div
  //     className={props.whitebg ? "backdrop--whitebg" : "backdrop"}
  //     onClick={props.onClick}
  //   ></div>,
  //   document.getElementById("backdrop-hook")
  // );
  return null;
};

export default Backdrop;
