import React, { useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import ModalButton from '../../button/ModalButton';
import ErrorModal from '../../util/ErrorModal';

import LoadingSpinner from '../../util/LoadingSpinner';
import Modal from '../../util/Modal';
import './Issue.css';

const AddPromotion = ({ show, onCancel, onSuccess }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [targetUser, setTargetUser] = useState('CLIENT');
  const [startingTime, setStartingTime] = useState('');
  const [endingTime, setEndingTime] = useState('');
  const [target, setTarget] = useState('');
  const [point, setPoint] = useState('');
  const createPromotion = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/add_promotion`,
        'POST',
        JSON.stringify({
          target_user: targetUser,
          start_date: startingTime,
          end_date: endingTime,
          target_amount: target,
          offered_point: point,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        onCancel();
        onSuccess();
      }
    } catch (err) {}
  };
  return (
    <Modal show={show} onCancel={onCancel}>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError} />
      <div className='promotion__container'>
        <img
          className='cancel__btn'
          onClick={onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2>Add New Promotion</h2>
        <p>Target User</p>
        <select
          value={targetUser}
          onChange={(e) => {
            setTargetUser(e.target.value);
          }}
        >
          <option value='CLIENT'>CLIENT</option>
          <option value='PROVIDER'>PROVIDER</option>
        </select>
        <div className='date_pickers'>
          <input
            type='date'
            placeholder='Starting Time'
            onChange={(e) => {
              //setStartingTime(e.target.value);
              setStartingTime(new Date(e.target.value).toISOString());
            }}
          />
          <input
            type='date'
            placeholder='Ending Time'
            onChange={(e) => {
              //setEndingTime(e.target.value);
              setEndingTime(new Date(e.target.value).toISOString());
            }}
          />
        </div>
        <p>Minimum Deal Amount (BDT)</p>
        <input
          type='text'
          value={target}
          onChange={(e) => {
            setTarget(e.target.value);
          }}
        />
        <p>Reward Credit (BDT)</p>
        <input
          type='text'
          value={point}
          onChange={(e) => {
            setPoint(e.target.value);
          }}
        />
      </div>
      <div className='promotionbtn__container'>
        <ModalButton onClick={createPromotion}>Create Promotion</ModalButton>
      </div>
    </Modal>
  );
};

export default AddPromotion;
