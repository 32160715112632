import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./DealCardProvider.css";
import { AuthContext } from "../../../context/auth-context";
import moment from "moment";
import axios from "axios";

let deal = {
  id: "1",
  profession: "Designing- Logo Design",
  postDate: "July 16th, 2021",
  bidFor: ["Service Only", "Print & Delivery"],
  dealCondition: "Ongoing",
  dealCreated: "July 16th, 2021",
  deadline: "September 16th, 2021",
  claimedBudget: "2000 BDT",
};
function DealCardProvider(props) {
  const auth = useContext(AuthContext);
  const [globalsVar, setGlobalsVar] = useState();
  const { deal } = props;

  return (
    <div className="row dcard" style={{ marginBottom: "10px" }}>
      <div className="col-lg-7 col-md-12">
        <h5>
          <strong>{props?.deal?.job_id?.title}</strong>
        </h5>

        <div className="d-flex flex-row">
          {/* <div style={{ marginRight: "10px" }}>
						<p>
							<i className="fab fa-gg-circle"></i> {deal.profession}
						</p>
					</div>
					<div style={{ marginRight: "10px" }}>
						<p>
							<i className="far fa-clock"></i>{" "}
							{auth.language === "en" ? " Posted On" : "পোস্ট করা হয়েছে"}{" "}
							{deal.postDate}
						</p>
					</div> */}
          <div style={{ marginRight: "10px" }}>
            <p>
              <i className="far fa-clock"></i>{" "}
              {auth.language === "en" ? "Deadline" : " সময়সীমা "}{" "}
              {moment(deal.deadline).format("DD-MM-YYYY")}
            </p>
          </div>
        </div>

        {/* <div className="d-flex flex-row">
					{deal.bidFor.map((item) => (
						<div className="bidFor">
							<p>{item}</p>
						</div>
					))}
				</div> */}

        {deal.state === "ONGOING" ? (
          <div className="dealStatusA">
            <p>{auth.language === "en" ? "Ongoing Deal" : "চলমান চুক্তি"} </p>
          </div>
        ) : (
          <div className="">
            <p>{deal?.state} </p>
          </div>
        )}
      </div>

      <div className="col-lg-5 col-md-12 ">
        <p>
          <strong>{auth.language === "en" ? "Payments" : "পেমেন্ট"} </strong>
        </p>
        <div className="col-5" style={{ padding: "0rem" }}>
          {/* <div
						className="d-flex flex-row justify-content-between"
						style={{ minWidth: "150px" }}
					>
						<p> {auth.language === "en" ? "Charge" : "চার্জ"} </p>
						<p style={{ color: "#159000", fontWeight: "bold" }}>
							800 {auth.language === "en" ? "BDT" : "টাকা"}{" "}
						</p>
					</div>
					<div
						className="d-flex flex-row justify-content-between"
						style={{ minWidth: "150px" }}
					>
						<p> {auth.language === "en" ? "Paid" : "পেড"} </p>
						<p style={{ color: "#159000", fontWeight: "bold" }}>
							500 {auth.language === "en" ? "BDT" : "টাকা"}{" "}
						</p>
					</div> */}
          <div
            className="d-flex flex-row justify-content-between"
            style={{ minWidth: "150px" }}
          >
            <p className="margin-right-10">
              {" "}
              {auth.language === "en" ? "Due" : "বাকি"}{" "}
            </p>
            <p style={{ color: "#159000", fontWeight: "bold" }}>
              {deal.client_due} {auth.language === "en" ? "BDT" : "টাকা"}{" "}
            </p>
          </div>
        </div>

        <div className="d-grid gap-2">
          <a href={`/servicedeal-detail/${deal._id}`}>
            <button className="btn btn-primary" type="button">
              {" "}
              {auth.language === "en"
                ? "View Deal Details"
                : "চুক্তির বিবরণ দেখুন"}{" "}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default DealCardProvider;
