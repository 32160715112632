import React from "react";
import "./ClientCard.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import { useContext } from "react";

function ClientCard(props) {
  const auth = useContext(AuthContext);

  const textStyle = {
    color: "var(--grey_one, #333)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  return (
    <div
      style={{
        marginTop: "20px",
        marginBottom: "40px",
        padding: "20px",
        borderRadius: "4px",
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="d-flex justify-content-start align-items-start gap-4">
        <img
          src={
            props.clientProfilePic || "https://via.placeholder.com/150/92c952"
          }
          alt="client_image"
          style={{ borderRadius: "4px" }}
          height="80"
          width="80"
        />
        <div>
          <p style={{ ...textStyle, fontSize: "20px", fontWeight: 700 }}>
            {props.clientName}
          </p>
          <p style={{ ...textStyle, color: "var(--grey_two, #666)" }}>
            {auth.language === "en" ? "Profession" : "পেশা"}
          </p>
          <div className="d-flex align-items-center justify-content-start gap-2">
            <p
              style={{
                ...textStyle,
                color: "var(--grey_two, #666)",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {auth.language === "en" ? "Client Rating" : "ক্লায়েন্ট রেটিং"}
            </p>
            <img
              src="/assets/rater-start.png"
              alt=""
              style={{
                width: "15px",
                height: "15px",
              }}
            />
            <p style={{ ...textStyle, fontSize: "14px" }}>
              {props.clientRating}{" "}
            </p>
          </div>
        </div>
      </div>
      <button
        style={{
          width: "100%",
          borderRadius: "4px",
          padding: "10px",
          backgroundColor: "#EEE",
          border: "none",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "var(--grey_two, #666)",
          fontFamily: "Nunito",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "normal",
          marginTop: "16px",
        }}
      >
        {auth.language === "en"
          ? "Visit Client Profile"
          : "ক্লায়েন্ট প্রোফাইল দেখুন"}
      </button>
    </div>
  );
}

export default ClientCard;
