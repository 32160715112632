import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState, useCallback } from "react";
import { useQuery } from "react-query";
import AdminLayout from "../../../components/AdminLayout";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import { GET_DEALS } from "../../../lib/api/admin";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

export default function FailedDeals() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ createdAt: -1 });
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({
    id: null,
    postNewJob: false,
  });

  const {
    data: deals,
    status,
    error,
  } = useQuery(
    [
      "GET_DEALS",
      {
        page,
        sort: JSON.stringify(sort),
        filter: JSON.stringify(filter),
        ...(search ? { search } : {}),
      },
    ],
    GET_DEALS
  );

  const toggleModal = () => setShowModal(!showModal);

  const handleCancelDeal = useCallback(async () => {
    try {
      const { id: deal_id, postNewJob } = selectedDeal;
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/admin/close_deal",
        { deal_id, post_new_job: postNewJob },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      toggleModal();
      // Add your refetch or state update logic here if needed
      toast.success("Deal cancelled successfully!");
    } catch (error) {
      console.error("Failed to cancel deal:", error);
      toast.error("Error cancelling deal.");
    }
  }, [selectedDeal]);

  const handleCheckboxChange = (event) => {
    setSelectedDeal((prev) => ({ ...prev, postNewJob: event.target.checked }));
  };

  const openCancelDealModal = (dealId) => {
    setSelectedDeal({ id: dealId, postNewJob: false });
    toggleModal();
  };

  return (
    <AdminLayout>
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="postNewJob">
              <Form.Check
                type="checkbox"
                label="Post New Job"
                checked={selectedDeal.postNewJob}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCancelDeal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <AdminTableView
        query={{ status, error }}
        title="Deals"
        search={{
          title: "Search",
          placeholder: "Search by job title...",
          btnText: "Search",
          onSearch: (value) => setSearch(value),
        }}
        filters={
          [
            // Your existing filters...
          ]
        }
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Job Title (DESC)", value: "job_id.title:-1" },
                { label: "Job Title (ASC)", value: "job_id.title:1" },
                { label: "Deal Date (DESC)", value: "createdAt:-1" },
                { label: "Deal Date (ASC)", value: "createdAt:1" },
                { label: "Deadline (DESC)", value: "deadline:-1" },
                { label: "Deadline (ASC)", value: "deadline:1" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: +value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Job Title", dotsMenu: [], key: "job_title" },
            { name: "Deal Started", dotsMenu: [], key: "createdAt" },
            { name: "Deadline", dotsMenu: [], key: "deadline" },
            { name: "Actions", dotsMenu: [], key: "actions" },
          ],
          data: deals?.data?.data.map((deal) => ({
            _id: deal._id,
            job_title: deal?.job_id?.title || "N/A",
            createdAt: moment(deal?.createdAt).format("YYYY-MM-DD") || "N/A",
            deadline: moment(deal?.deadline).format("YYYY-MM-DD") || "N/A",
            state: deal?.state || "N/A",
            actions: (
              <Button
                variant="danger"
                onClick={() => openCancelDealModal(deal._id)}
              >
                Cancel Deal
              </Button>
            ),
          })),
          pagination: {
            total: deals?.data?.total,
            page,
            prev: deals?.data?.prev,
            next: deals?.data?.next,
            setPage,
          },
        }}
      />
    </AdminLayout>
  );
}
