import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AuthForm.css";
import Input from "../input/Input";
import {
  VALIDATOR_EMAIL_OR_PHONE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_CONFIRMPASS,
} from "../../util/Validators";
import { useForm } from "../../hooks/form-hook";
import ErrorModal from "../util/ErrorModal";
import LoginWith from "./LoginWith";
import LoadingSpinner from "../util/LoadingSpinner";
import { useHttpClient } from "../../hooks/http-hook";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Texts from "../../util/Texts";

const ResetPasswordForm = (props) => {
  const texts = Texts();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [reqisSent, setReqisSent] = useState(false);
  let history = useHistory();
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      otp: {
        value: "",
        isValid: false,
      },
      confirm_password: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const sendResetRequest = async (event) => {
    event.preventDefault();

    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/auth/forgot_password",
        {
          email: formState.inputs.email.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (responseData.data.success === "yes") {
        setReqisSent(true);
        toast.success("Otp has been sent.");
      }
    } catch (err) {
      // Handle error
      console.error("Error:", err);
    }
  };

  const handleNewPassword = async (event) => {
    event.preventDefault();

    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/auth/verify_forgot_password",
        {
          email: formState.inputs.email.value,
          otp: formState.inputs.otp.value,
          password: formState.inputs.password.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (responseData.data.success === "yes") {
        setReqisSent(true);
        toast.success("Password has been reset.");
      }
      history.push("/login");
    } catch (err) {
      // Handle error
      console.error("Error:", err.message);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };
  const resetComponent = (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth--wrapper">
        {isLoading && <LoadingSpinner asOverlay />}
        <h1>Reset Password</h1>
        {!reqisSent && (
          <form>
            <Input
              element="input"
              id="email"
              type="text"
              label={texts.emailorPhone}
              mandatory="true"
              validators={[VALIDATOR_EMAIL_OR_PHONE()]}
              errorText={texts.errEmailorPhone}
              onInput={inputHandler}
              placeholder="Enter a valid email or phone number"
            />
            <div className="button--wrapper">
              <button
                type="submit"
                className="btn prime-btn my-4 w-100"
                onClick={sendResetRequest}
                isDisabled={!formState.isValid ? "true" : ""}
              >
                Reset Password
              </button>
            </div>
          </form>
        )}
        {reqisSent && (
          <React.Fragment>
            <form
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Input
                element="input"
                id="otp"
                type="text"
                label={texts.otp}
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Otp needs to 6 digits long"
                onInput={inputHandler}
                mandatory="true"
              />
              <Input
                element="input"
                id="password"
                type="password"
                label={texts.password}
                mandatory="true"
                validators={[VALIDATOR_MINLENGTH(8)]}
                errorText={texts.errPass}
                onInput={inputHandler}
                placeholder="Minimum length for password is 8 characters"
              />
              <Input
                element="input"
                id="confirm_password"
                type="password"
                mandatory="true"
                label={texts.confirmPass}
                validators={[
                  VALIDATOR_CONFIRMPASS(formState.inputs.password.value),
                ]}
                errorText={texts.errPassNoMatch}
                onInput={inputHandler}
              />
              <div className="button--wrapper">
                <button
                  type="submit"
                  className="btn prime-btn my-4 w-100"
                  onClick={handleNewPassword}
                  isDisabled={!formState.isValid ? "true" : ""}
                >
                  Reset Password
                </button>
              </div>
            </form>
          </React.Fragment>
        )}
        <div className=" text-center mt-3">
          <Link to="/login">
            <p
              className=""
              style={{
                color: "var(--primary_color)",
                textDecoration: "underline",
              }}
            >
              Back to Login
            </p>
          </Link>
          <div className="d-flex justify-content-center align-items-center">
            <p className="me-2">No Account?</p>
            <Link to="/sign-up">
              <p
                className=""
                style={{
                  color: "var(--primary_color)",
                  textDecoration: "underline",
                }}
              >
                Create Account
              </p>
            </Link>
          </div>
          <p className="sw">or Sign in with...</p>
        </div>
        <LoginWith />
      </div>
    </React.Fragment>
  );

  return <div>{resetComponent}</div>;
};

export default ResetPasswordForm;
