import moment from "moment";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { GET_SYSTEM_REPORTS } from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import QComponent from "../../shared/QComponent/QComponent";
import "./SystemReportView.css";

const today = new Date();

const getNextDate = (date) => {
	const newDate = new Date(date);
	newDate.setDate(newDate.getDate() + 1);
	return newDate;
};

export default function SystemReportView() {
	const [from, setFrom] = useState(today);
	const [exportReport, setExportReport] = useState(false);
	const [checkedItems, setCheckedItems] = useState([]);
	const [to, setTo] = useState(getNextDate(today));
	const {
		data: systemReports,
		status,
		error,
	} = useQuery(
		[
			"GET_SYSTEM_REPORTS",
			{
				from: moment(from).format("YYYY-MM-DD"),
				to: moment(to).format("YYYY-MM-DD"),
			},
		],
		GET_SYSTEM_REPORTS
	);

	return (
		<div className="systemReportView">
			<h3
				style={{
					fontWeight: "bold",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				System Report
				<span>
					{!exportReport && (
						<Button
							variant="primary"
							onClick={() => setExportReport(true)}
							type="button"
						>
							Export Report
						</Button>
					)}
					{exportReport && (
						<CSVLink
							filename="data.csv"
							headers={checkedItems.map(({ label, key }) => ({ label, key }))}
							data={[
								checkedItems
									.map(({ label, key, ...rest }) => rest)
									.reduce((acc, val) => (acc = { ...acc, ...val }), {}),
							]}
							onClick={() => {
								setCheckedItems([]);
								setExportReport(false);
							}}
						>
							<Button variant="primary" type="button">
								Download
							</Button>
						</CSVLink>
					)}
				</span>
			</h3>

			<div className="systemReportView__filters">
				<Input
					label="From"
					type="date"
					value={moment(from).format("YYYY-MM-DD")}
					onChange={({ target }) => setFrom(target.value)}
				/>
				<Input
					label="To"
					type="date"
					value={moment(to).format("YYYY-MM-DD")}
					onChange={({ target }) => setTo(target.value)}
				/>
			</div>

			<QComponent status={status} error={error}>
				<div className="systemReportView__data">
					<p>
						System Report from {moment(from).format("YYYY-MM-DD")} to{" "}
						{moment(to).format("YYYY-MM-DD")}
					</p>

					<div className="systemReportView__dataWrapper">
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_admins",
													label: "No Of Admins",
													no_of_admins: systemReports?.data?.no_of_admins,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of admin users
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_admins}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_managers",
													label: "No Of Managers",
													no_of_managers: systemReports?.data?.no_of_managers,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of non-admin manager users
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_managers}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_providers",
													label: "No Of Providers",
													no_of_providers: systemReports?.data?.no_of_providers,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of provider
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_providers}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_deals",
													label: "No Of Clients",
													no_of_deals: systemReports?.data?.no_of_deals,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of clients
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_deals}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_admins_added",
													label: "No Of Admins Added",
													no_of_admins_added: systemReports?.data?.no_of_admins_added,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of admin users added
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_admins_added}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_managers_added",
													label: "No Of Managers Added",
													no_of_managers_added: systemReports?.data?.no_of_managers_added,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of non-admin manager users added
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_managers_added}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_providers_added",
													label: "No Of Providers Added",
													no_of_providers_added: systemReports?.data?.no_of_providers_added,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of provider added
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_providers_added}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_clients_added",
													label: "No Of Clients Added",
													no_of_clients_added: systemReports?.data?.no_of_clients_added,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of clients added
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_clients_added}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_refunds",
													label: "No Of Refund Requests",
													no_of_refunds: systemReports?.data?.no_of_refunds,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Number of refund requests
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_refunds}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "amount_of_refunds_to_be_paid",
													label: "Amount of refunds to be paid",
													amount_of_refunds_to_be_paid: systemReports?.data?.amount_of_refunds_to_be_paid,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Amount of refunds to be paid
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.amount_of_refunds_to_be_paid}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "amount_of_refunds_paid",
													label: "Amount of refund paid",
													amount_of_refunds_paid: systemReports?.data?.amount_of_refunds_paid,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Amount of refund paid
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.amount_of_refunds_paid}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "amount_paid_to_providers",
													label: "Amount paid to to providers",
													amount_paid_to_providers: systemReports?.data?.amount_paid_to_providers,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Amount paid to to providers
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.amount_paid_to_providers}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "amount_to_be_paid_to_providers",
													label: "Amount to be paid to providers",
													amount_to_be_paid_to_providers: systemReports?.data?.amount_to_be_paid_to_providers,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Amount to be paid to providers
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.amount_to_be_paid_to_providers}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "amount_paid_by_client",
													label: "Amount paid by clients",
													amount_paid_by_client: systemReports?.data?.amount_paid_by_client,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Amount paid by clients
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.amount_paid_by_client}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_promotions",
													label: "Number of promotions",
													no_of_promotions: systemReports?.data?.no_of_promotions,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Number of promotions
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_promotions}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_deals",
													label: "Number of deals made",
													no_of_deals: systemReports?.data?.no_of_deals,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Number of deals made
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_deals}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_successful_deals",
													label: "Number of deals successful",
													no_of_successful_deals: systemReports?.data?.no_of_successful_deals,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Number of deals successful
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_successful_deals}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_failed_deals",
													label: "Number of deals failed",
													no_of_failed_deals: systemReports?.data?.no_of_failed_deals,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Number of deals failed
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_failed_deals}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_ongoing_deals",
													label: "Number of deals ongoing",
													no_of_ongoing_deals: systemReports?.data?.no_of_ongoing_deals,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Number of deals ongoing
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_ongoing_deals}
							</div>
						</div>
						<div className="systemReportView__row">
							<div className="systemReportView__column">
								{exportReport && (
									<input
										type="checkbox"
										onChange={({ target }) =>
											target.checked &&
											setCheckedItems((values) => [
												...values,
												{
													key: "no_of_blocked_users",
													label: "Total number of blocked users",
													no_of_blocked_users: systemReports?.data?.no_of_blocked_users,
												},
											])
										}
										style={{ width: "20px", marginRight: "1rem" }}
										className="form-check-input"
									/>
								)}{" "}
								Total number of blocked users
							</div>
							<div className="systemReportView__column">
								{systemReports?.data?.no_of_blocked_users}
							</div>
						</div>
					</div>
				</div>
			</QComponent>
		</div>
	);
}
