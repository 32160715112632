import React from "react";
import "./Card.css";
const AccOverview = (props) => {
  return (
    <div className="personalinfo--container">
      <div className="personal--info">
        <div className="info--name">
          <p>Bids Placed</p>
          <p>Total Deals</p>
          <p>Jobs Posted</p>
          <p>Fine</p>
          <p className="thin_p">Account created at {props.created}</p>
        </div>
        <div className="info--value">
          <p>{props.bidsPlaced}</p>
          <p>{props.dealsCompleted}</p>
          <p>{props.jobsPosted}</p>
          <p>{props.fine} BDT</p>
        </div>
      </div>
    </div>
  );
};

export default AccOverview;
