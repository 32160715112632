import React from "react";
import { useQuery } from "react-query";
import { GET_DUE_PAYMENTS_BY_ID } from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Modal from "../../shared/Modal/Modal";
import QComponent from "../../shared/QComponent/QComponent";
import MakePaymentForDueAmountModal from "../MakePaymentForDueAmountModal/MakePaymentForDueAmountModal";
import "./ViewDuePaymentDetailsModal.css";

export default function ViewDuePaymentDetailsModal({
  open,
  onClose,
  title,
  data,
}) {
  const {
    data: duePaymentsData,
    status,
    error,
  } = useQuery(
    ["GET_DUE_PAYMENTS_BY_ID", { id: data?.provider_id?._id }],
    GET_DUE_PAYMENTS_BY_ID,
    {
      enabled: !!data?.provider_id?._id,
    }
  );

  return (
    <Modal open={open} onClose={onClose} title={title} size="lg">
      <div className="viewDuePaymentDetailsModal">
        <h3>{data?.provider_id?.fullname}</h3>
        <p>
          Total Payable: <span>৳ {data?.total_due}</span>
        </p>

        <QComponent status={status} error={error}>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
            }}
          >
            <table className="adminTableView__table">
              <thead>
                <tr>
                  <th>Deal</th>
                  <th>Due</th>
                  <th>Status</th>
                  <th>Client Total</th>
                  <th>Provider Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {duePaymentsData?.data?.data?.map((item) => (
                  <tr key={item._id}>
                    <td>{item.deal_id}</td>
                    <td>{item.provider_due}</td>
                    <td>{item.state}</td>
                    <td>{item.total_payable_amount_client}</td>
                    <td>{item.total_deliverable_amount_provider}</td>
                    <td>
                      <MakePaymentForDueAmountModal
                        data={{
                          total_amount: item.provider_due,
                          deal_id: item.deal_id,
                        }}
                      >
                        <Button variant="primary">Pay Now</Button>
                      </MakePaymentForDueAmountModal>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </QComponent>
      </div>
    </Modal>
  );
}
