import React from 'react';
import AdminLayout from '../../../components/AdminLayout';
import UiConfigurationsView from '../../../components/AdminLayout/UiConfigurationsView/UiConfigurationsView';

export default function UiConfigurations() {
  return (
    <AdminLayout>
      <UiConfigurationsView />
    </AdminLayout>
  )
}