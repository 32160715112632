import React, { useRef } from "react";
import "./ImageUploader.css";
const FileUploader = ({ updatestate }) => {
  const fileInputRef = useRef();

  return (
    <div className="image__uploader file__uploader">
      <div
        className="uploader__btn"
        onClick={(event) => {
          fileInputRef.current.click();
        }}
      >
        <span className="upload_txt">Upload</span>{" "}
        <img src="/assets/upload.svg" alt="" />
      </div>
      <input
        multiple
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event.target.files;
          if (file) {
            updatestate(file);
          } else {
            updatestate(null);
          }
        }}
      />
    </div>
  );
};

export default FileUploader;
