import React, { useEffect } from "react";
import UiConfig from "../sections/admin/UiConfig";

const UiConfiguration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <UiConfig />
    </div>
  );
};

export default UiConfiguration;
