import React, { useContext, useEffect, useState, Suspense } from "react";
import Banner from "../components/banner/Banner";
import BecomeClientProvider from "../components/HomeComponent/BecomeClientProvider/BecomeClientProvider";
import HomeJobCard from "../components/HomeComponent/HomeJobCard/HomeJobCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/util/Home.css";
import { AuthContext } from "../context/auth-context";
import Catalogs from "../sections/catalogs/Catalogs";
import Texts from "../util/Texts";
import { useHttpClient } from "../hooks/http-hook";
import LoadingSpinner from "../components/util/LoadingSpinner";
import TopProvider from "../components/HomeComponent/TopProvider/TopProvider";
import { useHistory } from "react-router-dom";
import "./TopSearchBar.css";
import CountUp from "react-countup";
import Editor from "../components/Editor/Editor";

const StatSection = React.lazy(() =>
  import("../uicomponents/statSection/StatSection")
);

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: 10 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: 10, zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const Home = () => {
  const auth = useContext(AuthContext);
  let texts = Texts();
  let history = useHistory();

  const slides = auth?.uiConfig?.banner_image_array || [];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isLoading} = useHttpClient();
  const [searchKey, setSearchKey] = useState("");
  const [category, setCategory] = useState("all");

  const handleSearch = () => {
    let url = `/explore/${category}`;
    if (searchKey !== "" && searchKey !== null && searchKey !== undefined) {
      url += `/${searchKey}`;
    }
    history.push(url);
  };

  const [stats, setStats] = useState({
    jobs_posted: 0,
    jobs_completed: 0,
    freelancers: 0,
  });

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/stats`)
      .then((res) => res.json())
      .then((data) => setStats(data));
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="mobile_carousel">
        {slides.length > 0 && (
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <div
                  className="carousel__slide"
                  style={{
                    backgroundImage: `url(${slide})`,
                    height: "450px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            ))}
          </Slider>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            width: "92vw",
            margin: "0 auto",
            marginTop: "10px",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "0px",
            }}
          >
            <label
              for=""
              style={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "600",
                fontStyle: "normal",
                borderRadius: "3px",
                marginBottom: "0px",
              }}
            >
              {auth.language === "en" ? "Job Category" : "কাজের বিভাগ"}
            </label>
            <select
              style={{
                width: "100%",
                height: "50px",
                border: "1px solid #DDD",
                borderRadius: "4px",
                margin: "0px",
                padding: "5px",
                boxShadow: "none",
                cursor: "pointer",
                outlineWidth: "0px",
              }}
              name="cat"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <option disabled selected>
                Select Category
              </option>
              <option value="">all</option>
              {auth.categories &&
                auth.categories.data.map((option, idx) => {
                  return (
                    <option key={idx} value={option.name}>
                      {auth.language === "en" ? option?.name : option?.name_bn}
                    </option>
                  );
                })}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "0px",
            }}
          >
            <label
              for=""
              style={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "600",
                fontStyle: "normal",
                borderRadius: "3px",
                marginBottom: "0px",
              }}
            >
              {auth.language === "en" ? "Job Title" : "কাজের টাইটেল"}
            </label>
            <input
              type="text"
              name="title"
              placeholder="Search with Keywords"
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>

          <div>
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2A41E8",
                color: "white",
                padding: "10px 8px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
              type="button"
              onClick={() => handleSearch()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="carousel__container">
          {slides.length > 0 && (
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div key={index}>
                  <div
                    className="carousel__slide"
                    style={{
                      backgroundImage: `url(${slide})`,
                      height: "450px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </div>
              ))}
            </Slider>
          )}
          <div className="carousel__content container">
            <div>
              <h2 className=" text-white fw-bold">
                {auth.language === "en" ? (
                  <Editor
                    readOnly
                    defaultValue={auth?.uiConfig?.hero_text_en}
                  />
                ) : (
                  <Editor
                    readOnly
                    defaultValue={auth?.uiConfig?.hero_text_bn}
                  />
                )}
              </h2>
            </div>

            <div
              className="searchComp d-flex  justify-content-between align-items-end"
              style={{ backgroundColor: "white", padding: " 15px" }}
            >
              <div className="col-4" style={{ paddingLeft: 0 }}>
                <label
                  for=""
                  style={{
                    color: "var(--text)",
                    fontWeight: "normal",
                    borderRadius: "3px",
                  }}
                >
                  {auth.language === "en" ? "Category" : "বিভাগ"}
                </label>
                <select
                  name="cat"
                  style={{
                    maxHeight: "100px",
                    overflow: "scroll",
                    width: "100%",
                    height: "50px",
                    border: "1px solid white",
                    borderRadius: "3px",
                    backgroundColor: "#F8F8F8",
                    margin: "0px",
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  <option value="">
                    {auth.language === "en" ? "all" : "সব"}
                  </option>
                  {auth.categories &&
                    auth.categories.data.map((option, idx) => {
                      if (option.is_disabled) return null;
                      return (
                        <option key={idx} value={option.name}>
                          {auth.language === "en"
                            ? option.name
                            : option?.name_bn}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-6" style={{ paddingLeft: 0 }}>
                <label
                  for=""
                  style={{
                    color: "var(--text)",
                    borderRadius: "3px",
                    fontWeight: "normal",
                  }}
                >
                  {auth.language === "en"
                    ? " Job Title Keyword"
                    : "কাজ খোজার কীওয়ার্ড"}
                </label>
                <input
                  type="text"
                  className=""
                  name="title"
                  style={{
                    maxHeight: "100px",
                    overflow: "scroll",
                    width: "100%",
                    height: "50px",
                    border: "1px solid white",
                    borderRadius: "3px",
                    backgroundColor: "#F8F8F8",
                    margin: "0px",
                    boxShadow: "none",
                    cursor: "pointer",
                    outlineWidth: "0px",
                  }}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
              <button
                className="col-2"
                onClick={() => handleSearch()}
                style={{
                  color: "white",
                  backgroundColor: "#2A41E8",
                  height: "50px",
                  borderRadius: "3px",
                }}
              >
                <span>{auth.language === "en" ? "Search" : "খুজুন"}</span>
              </button>
            </div>
            <div
              style={{
                height: "100px",
                width: "100%",
              }}
              className="d-flex justify-content-start align-items-end"
            >
              <div
                className="pb-1 px-4"
                style={{ borderRight: "1px solid #ffffff" }}
              >
                <CountUp
                  start={0}
                  end={stats.jobs_posted}
                  duration={1.25}
                  separator=""
                  decimal=","
                  style={{
                    color: "white",
                    fontSize: "34px",
                    fontWeight: "bold",
                  }}
                />
                <h3 style={{ color: "white" }}>
                  {" "}
                  {auth.language === "en"
                    ? "Job Posted"
                    : "কাজ পোস্ট করা হয়েছে"}
                </h3>
              </div>
              <div
                className="pb-1 px-4"
                style={{ borderRight: "1px solid white " }}
              >
                <CountUp
                  start={0}
                  end={stats.jobs_completed}
                  duration={1.25}
                  style={{
                    color: "white",
                    fontSize: "34px",
                    fontWeight: "bold",
                  }}
                />
                <h3 style={{ color: "white" }}>
                  {" "}
                  {auth.language === "en" ? "Job Completed" : "কাজ শেষ"}
                </h3>
              </div>
              <div
                className="pb-1 px-4"
                style={{ borderRight: "1px solid white " }}
              >
                <CountUp
                  start={0}
                  end={stats.freelancers}
                  duration={1.25}
                  style={{
                    color: "white",
                    fontSize: "34px",
                    fontWeight: "bold",
                  }}
                />
                <h3 style={{ color: "white" }}>
                  {" "}
                  {auth.language === "en" ? "Freelancers" : "ফ্রিল্যান্সার"}
                </h3>
              </div>
              <div className="pb-1 px-4">
                <CountUp
                  start={0}
                  end={stats.users}
                  duration={1.25}
                  style={{
                    color: "white",
                    fontSize: "34px",
                    fontWeight: "bold",
                  }}
                />
                <h3 style={{ color: "white" }}>
                  {" "}
                  {auth.language === "en" ? "Users" : "ব্যবহারকারী"}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <Catalogs />

        <HomeJobCard />
        <Banner
          headerFont={
            auth.uiConfig &&
            auth.uiConfig.homepage.explore_jobs.bangla_title_font +
              ", " +
              auth.uiConfig.homepage.explore_jobs.english_title_font
          }
          descriptionFont={
            auth.uiConfig &&
            auth.uiConfig.homepage.explore_jobs.bangla_description_font +
              ", " +
              auth.uiConfig.homepage.explore_jobs.english_description_font
          }
          linkTo="/explore/all"
          imgSource={
            (auth.uiConfig?.homepage?.explore_jobs.section_background_image &&
              auth.uiConfig.homepage.explore_jobs.section_background_image) ||
            "/assets/explore_jobs.jpg"
          }
          buttonText={texts.exploreJobs}
          title={
            auth.uiConfig && auth.language === "en"
              ? auth.uiConfig?.homepage.explore_jobs.english_title
              : auth.uiConfig?.homepage.explore_jobs.bangla_title
          }
          text={
            auth.uiConfig && auth.language === "en"
              ? auth.uiConfig?.homepage.explore_jobs.english_description
              : auth.uiConfig?.homepage.explore_jobs.bangla_description
          }
        />

        <Banner
          headerFont={
            auth.uiConfig &&
            auth.uiConfig.homepage.post_jobs.bangla_title_font +
              ", " +
              auth.uiConfig.homepage.post_jobs.english_title_font
          }
          descriptionFont={
            auth.uiConfig &&
            auth.uiConfig.homepage.post_jobs.bangla_description_font +
              ", " +
              auth.uiConfig.homepage.post_jobs.english_description_font
          }
          imgSource={
            auth.uiConfig?.homepage?.explore_jobs.section_background_image ||
            "/assets/post_job.jpg"
          }
          linkTo={auth.isLoggedIn ? "/user/postJob" : "/login"}
          variant="post--job"
          title={
            auth.uiConfig && auth.language === "en"
              ? auth.uiConfig?.homepage.post_jobs.english_title
              : auth.uiConfig?.homepage.post_jobs.bangla_title
          }
          text={
            auth.uiConfig && auth.language === "en"
              ? auth.uiConfig?.homepage.post_jobs.english_description
              : auth.uiConfig?.homepage.post_jobs.bangla_description
          }
          buttonText={texts.postJob}
        />
      </div>
      <div
        style={{
          backgroundColor: "#F8F8F8",
          padding: "40px 0",
        }}
      >
        <TopProvider />
      </div>
      <BecomeClientProvider />
      <div
        style={{
          backgroundColor: "#F8F8F8",
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <StatSection stats={stats} />
        </Suspense>
      </div>
    </React.Fragment>
  );
};

export default Home;
