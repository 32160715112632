import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import FileUploader from "../../components/util/FileUploader";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import {
  GET_ALL_DELIVERY_PROVIDERS,
  GET_GLOBAL_SETTINGS,
} from "../../lib/api/admin";
import Prompt from "../../components/prompt";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BUCKET_URL,S3_PREASSIGNED_POST_URL,CLOUDFRONT_URL } from "../../config";

const paperOptions = [
  "A0",
  "A1",
  "A2",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "letter",
  "legal",
];

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

function CreateNewPost() {
  const [globalSettings, setGlobalSettings] = useState({});
  let { search } = useLocation();
  const searchQuery = new URLSearchParams(search);

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    // console.log(token);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/user/get_globals", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        setGlobalSettings(response);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(globalSettings);
  }, []);
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  let history = useHistory();

  // editing by za4
  const [workTitle, setWorkTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCat, setSelectedCat] = useState();
  const [allCategory, setAllCategory] = useState([]);
  const [categoryDelivery,setCategoryDelivery]=useState(false);
  const [selectedSubCat, setSelectedSubCat] = useState();
  const [allSubCate, setAllSubCate] = useState([]);
  const [files, setFiles] = useState(null);
  const [maxBudget, setMaxBudget] = useState(0);
  const [deadline, setDeadline] = useState("");
  const [print, setPrint] = useState(false);
  const [paper, setPaper] = useState("");
  const [addr, setAddr] = useState("");
  const [bw, setBw] = useState(false);
  const [double, setDouble] = useState(false);
  const [printingDeadline, setPrintingDeadline] = useState("");
  const [deliveryProvider, setDeliveryProvider] = useState("");
  const [show, setShow] = useState(false);

  const { data: deliveryProviders } = useQuery(
    [GET_ALL_DELIVERY_PROVIDERS.name, {}],
    GET_ALL_DELIVERY_PROVIDERS
  );
  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    setSelectedCat(searchQuery.get("cat"));
  }, []);

  useEffect(() => {
    setAllSubCate([]);
    setSelectedSubCat("");
    getAllSubCate(selectedCat);

    setCategoryDelivery(allCategory.filter(cat=>cat.name==selectedCat)[0]?.delivery);
  }, [selectedCat]);

  useEffect(() => {
    if (selectedCat === searchQuery.get("cat"))
      setSelectedSubCat(searchQuery.get("subcat"));
  }, [allSubCate]);

  const getAllCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_parent_categories`
      );
      setAllCategory(response.data.data);
    } catch (error) {
      notify("err", error);
    }
  };

  const getAllSubCate = async (selectedcate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_subcategories?name=${selectedcate}`
      );
      if (response.status === 200) {
        setAllSubCate(response.data);
      } else {
        throw new Error("Something Went Wrong!");
      }
    } catch (error) {
      notify("err", error + "ss");
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    if (workTitle.length < 6) {
      notify("err", "Work Title must be at least 6 characters long!");
      return;
    }
    if (!selectedCat || selectedCat === "all") {
      notify("err", "Selected Category cannot be empty or all!");
      return;
    }
    if (!deadline) {
      notify("err", "Deadline must be provided!");
      return;
    }

    try {

      const data = {
        title: workTitle,
        description: description,
        category: [
          `${selectedSubCat.length > 0 ? selectedSubCat : selectedCat}`,
        ],
        max_budget: maxBudget,
        deadline: deadline,
        delivery: print,
      };

      if (print) {
        if (!addr) {
          notify("err", "Mailing Address must be provided!");
          return;
        }

        data.delivery = true;
        data.mailing_address = addr;
      }

      if (files?.length > 0) {
        const fileUrls = [];
        for (const file of files) {
          try {
            const data = await axios.get(
              `${S3_PREASSIGNED_POST_URL}?size=${globalSettings?.max_file_size}&fileExtension=${file.name.split('.').pop()}`
            );
  
            const formData = new FormData();
            Object.entries(data.data[0].fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", file);
  
            const uploadResponse = await axios.post(BUCKET_URL, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
  
            fileUrls.push(CLOUDFRONT_URL + `${data.data[0].fields.Key}`);
          } catch (uploadError) {
            toast.error(`Max file upload size is ${globalSettings?.max_file_size}MB`);
            return;
          }
        }
  
        data.attachments = [...fileUrls];
      }
  

      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/client/create_job",
        data,
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );

      console.log(responseData);

      if (responseData.data.success === "yes") {
        notify("success", "Job Created Successfully!");
        // history.push("/user/jobPosts");
        setWorkTitle("");
        setDescription("");
        setSelectedCat("all");
        // setAllCategory([]);
        setSelectedSubCat("");
        // setAllSubCate([]);
        setFiles(null);
        setMaxBudget(0);
        setPrint(false);
        setPaper("");
        setAddr("");
        setBw(false);
        setDouble(false);
      } else {
        notify("err", "Something went wrong!");
        setWorkTitle("");
        setDescription("");
        setSelectedCat("all");
        // setAllCategory([]);
        setSelectedSubCat("");
        // setAllSubCate([]);
        setFiles(null);
        setMaxBudget(0);
        setPrint(false);
        setPaper("");
        setAddr("");
        setBw(false);
        setDouble(false);
      }
    } catch (error) {
      notify("err", error);
      setWorkTitle("");
      setDescription("");
      setSelectedCat("all");
      // setAllCategory([]);
      setSelectedSubCat("");
      // setAllSubCate([]);
      setFiles(null);
      setMaxBudget(0);
      setPrint(false);
      setPaper("");
      setAddr("");
      setBw(false);
      setDouble(false);
      setPrintingDeadline("");
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="container mb-5">
          <h2 className="fw-bold">
            {auth.language === "en"
              ? "Create a New Job Post"
              : "নতুন কাজের পোস্ট সম্পাদনা করুন"}
          </h2>
          <div className="my-3">
            <div className="form-section">
              <label htmlFor="fname">
                {" "}
                {auth.language === "en" ? "Work Title" : "কাজের শিরোনাম"}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                autoComplete="off"
                type="text"
                name="workTitle"
                placeholder={
                  auth.language === "en"
                    ? "Be concise and short. eg: Logo for a bakery company."
                    : "সংক্ষিপ্ত. যেমন: একটি বেকারি কোম্পানির লোগো। "
                }
                value={workTitle}
                onChange={(e) => {
                  setWorkTitle(e.target.value);
                }}
              />
            </div>
            <div className="form-section">
              <label for="fdesc">
                {" "}
                {auth.language === "en"
                  ? "Detailed Description"
                  : "বিস্তারিত বিবরণ"}
              </label>
              <textarea
                placeholder={
                  auth.language === "en"
                    ? "Explain your task in details"
                    : "আপনার কাজটি বিশদভাবে ব্যাখ্যা করুন"
                }
                rows="5"
                className="textareaa"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <label for="">
                  {auth.language === "en"
                    ? "Select Category"
                    : "বিভাগ নির্বাচন করুন"}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="selectt"
                  className="form-select"
                  aria-label="Default select example"
                  style={{ cursor: "pointer" }}
                  value={selectedCat}
                  onChange={(e) => setSelectedCat(e.target.value)}
                >
                  {" "}
                  <option>
                    {auth.language === "en" ? "not selected" : "অনির্বাচিত"}{" "}
                  </option>
                  {allCategory?.map(
                    (c, idx) =>
                      c.is_disabled === false && (
                        <option value={c.name} key={idx}>
                          {auth.language === "en" ? c.name : c?.name_bn}
                        </option>
                      )
                  )}
                </select>
              </div>
              <div className="col-lg-6 col-md-12">
                {" "}
                {/* {selectedCat !== 'all' && selectedCat !== '' && ( */}
                <div>
                  <label for="">
                    {auth.language === "en"
                      ? "Select Sub-Category"
                      : "সাব-ক্যাটাগরি নির্বাচন করুন "}
                  </label>
                  <select
                    id="selectt"
                    className="form-select"
                    aria-label="Default select example"
                    style={{ cursor: "pointer" }}
                    value={selectedSubCat}
                    onChange={(e) => setSelectedSubCat(e.target.value)}
                  >
                    <option value="" selected>
                      {auth.language === "en" ? "Not selected" : "অনির্বাচিত"}
                    </option>
                    {Array.isArray(allSubCate?.data) &&
                      allSubCate?.data?.map(
                        (dt, idx) =>
                          dt.is_disabled === false && (
                            <option key={idx} value={dt.name}>
                              {auth.language === "en" ? dt.name : dt?.name_bn}
                            </option>
                          )
                      )}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-section col-md-12 col-lg-6">
                <label htmlFor="fname">
                  {" "}
                  {auth.language === "en" ? "Maximum Budget" : "সর্বোচ্চ বাজেট"}
                </label>
                <input
                  autoComplete="off"
                  type="number"
                  name="workTitle"
                  placeholder="in BDT"
                  value={maxBudget}
                  min={0}
                  onChange={(e) => {
                    setMaxBudget(e.target.value);
                  }}
                />
              </div>
              <div className="form-section col-md-12 col-lg-6">
                <label for="bdate">
                  {" "}
                  {auth.language === "en" ? "Deadline" : "সময়সীমা"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  autoComplete="off"
                  type="date"
                  value={deadline ? deadline.slice(0, 10) : ""}
                  onChange={(e) => {
                    setDeadline(e.target.value);
                  }}
                  name="bdate"
                />
              </div>
            </div>
            <div className="inforight mb-4">
              <h4 className="fw-bold mb-2">
                {auth.language === "en" ? "File Attachment" : "নথি সংযুক্তি"}{" "}
              </h4>
              <FileUploader
                updatestate={(childFile) => {
                  setFiles(childFile);
                }}
              />
              {files && (
                <p>
                  {files.length}{" "}
                  {auth.language === "en"
                    ? "file/s chosen"
                    : "ফাইল/গুলি বেছে নেওয়া হয়েছে"}
                </p>
              )}
            </div>
            {categoryDelivery && <div className="d-flex justify-content-start align-items-center my-5">
              <input
                checked={print ? true : false}
                autoComplete="off"
                type="checkbox"
                onChange={() => {
                  setPrint(!print);
                }}
                name="bdate"
                style={{ width: "25px", height: "25px", marginRight: "10px" }}
              />{" "}
              <p style={{ marginBottom: "0px" }}>
                {" "}
                {auth.language === "en"
                  ? "Need Delivery?"
                  : "ডেলিভারি প্রয়োজন?"}
              </p>
            </div>}
            {print && (
              <div>
                <div className="form-section">
                  <label for="fdesc">
                    {" "}
                    {auth.language === "en"
                      ? "Mailing Address"
                      : "চিঠি পাঠানোর ঠিকানা"}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    placeholder={
                      auth.language === "en"
                        ? "House, Road, Post Office, City, Town, District..."
                        : "বাড়ি, রাস্তা, ডাকঘর, শহর, শহর, জেলা.."
                    }
                    rows="5"
                    className="textareaa"
                    value={addr}
                    onChange={(e) => {
                      setAddr(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            )}
            <Button variant="primary" onClick={() => setShow(!show)}>
              {auth.language === "en" ? "Create Job " : "কাজ তৈরি করুন"}
            </Button>
          </div>
        </div>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: "scroll", maxHeight: "400px" }}>
            {globalSettings?.data?.job_post_prompt && (
              <div>
                {JSON.parse(globalSettings.data.job_post_prompt).blocks.map(
                  (block, index) => (
                    <div
                      key={index}
                      className="formatted-content"
                      dangerouslySetInnerHTML={{ __html: block.data.text }}
                    />
                  )
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleSubmit();
                setShow(false);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default CreateNewPost;
