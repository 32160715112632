import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHttpClient } from '../../../hooks/http-hook';
import Backdrop from '../../util/Backdrop';
import LoadingSpinner from '../../util/LoadingSpinner';
import Modal from '../../util/Modal';
import SendMessage from '../Profile/SendMessage';
import './Issue.css';
const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};
const Issue = ({ show, onCancel, issue, onSuccess }) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [showMsgBox, setShowMsgBox] = useState(false);
  const hideMsgBox = () => {
    setShowMsgBox(false);
  };
  const setAsResolved = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/set_resolved/?issue_id=${issue._id}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        onCancel();
        onSuccess();
      }
    } catch (err) {}
  };
  return (
    <Modal show={show} onCancel={onCancel}>
      {isLoading && <LoadingSpinner asOverlay />}
      {issue && (
        <div className='issue__container'>
          {showMsgBox && <Backdrop onClick={hideMsgBox} />}
          <SendMessage
            onCancel={hideMsgBox}
            show={showMsgBox}
            fullname={issue.user_id.fullname}
            id={issue.user_id._id}
          />
          <img
            className='cancel__btn'
            onClick={onCancel}
            src='/assets/cross.svg'
            alt=''
          />
          <p className='issue__header'>Issue Description:</p>
          <p className='issue__desctiption'>{issue.issue}</p>
          <div className='issue__bottom'>
            <p>
              <span className='issue__header'>Issued By: </span>
              <span>
                <Link target='_blank' to={`/profile/${issue.user_id._id}`}>
                  <u>{issue.user_id.fullname}</u>
                </Link>
              </span>
            </p>
            <p className='issue__header'>
              {new Date(issue.createdAt).toLocaleDateString(
                undefined,
                timeOptions
              )}
            </p>
            <div className='footer__btns'>
              <div
                onClick={() => {
                  setShowMsgBox(true);
                }}
                className='contact__btn primary'
              >
                <img src='/assets/Send_Message.svg' alt='' />
                <p>Contact Issuer</p>
              </div>
              <div onClick={setAsResolved} className='contact__btn secondary'>
                <p>Set As Resolved</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Issue;
