import React, { useEffect } from "react";
import Issues from "../sections/admin/Issues";
const UnresolvedIssues = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Issues />
    </div>
  );
};

export default UnresolvedIssues;
