import React from 'react';
import { Link } from 'react-router-dom';
import './SidebarOption.css';

const SidebarOption = (props) => {
  return (
    <a href={props.to}>
      <div
        onClick={props.onClick}
        className={`sidebarOption ${
          props.active === 'true' && 'sidebarOption--active'
        }`}
      >
        {/* <img src={props.icon} alt='' /> */}
        <span>{props.icon}</span>
        <h2>{props.text}</h2>
      </div>
    </a>
  );
};

export default SidebarOption;
