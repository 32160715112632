import { useEffect, useRef } from "react";

export default function useOutsideClick(cb) {
  const elementRef = useRef(null);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!elementRef.current?.contains(e.target)) {
        // eslint-disable-next-line no-unused-expressions
        cb?.();
      }
    }) 
  })

  return { elementRef };
}