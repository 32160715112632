import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import useOutsideClick from "../../../hooks/useOutsideClick";
import "./ThreeDotDropdown.css";

export default function ThreeDotDropdown({
  additionalData,
  className,
  options,
  handleDelete,
  deleteOpt
}) {
  const [active, setActive] = useState(false);
  const { elementRef } = useOutsideClick(() => setActive(false));

  return (
    <div ref={elementRef} className={`threeDotDropdown ${className || ""}`}>
      <button type="button" onClick={() => setActive((value) => !value)}>
        <BsThreeDots />
      </button>

      <ul className={`threeDotDropdown__list ${active ? "active" : ""}`}>
        {options.map((opt, idx) => {
          const condition = opt?.show;
          const key = condition?.split(":")[0];
          const value = condition?.split(":")[1];

          if (condition && additionalData[key] !== value) return null;
          return (
            <li
              key={idx}
              onClick={() => {
                setActive(false);

                if (handleDelete && opt.name==='Delete') {
                  handleDelete(additionalData._id);
                }
                // eslint-disable-next-line no-unused-expressions
                opt.onClick?.({ ...additionalData });
              }}
            >
              {opt.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
