import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';
import { toast } from 'react-toastify';

const notify = (type, msg) => {
  type === 'err'
    ? toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

const Tokenize = () => {
  const auth = useContext(AuthContext);
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    const setup = async () => {
      if (token) {
        // auth.loginWithProviders(token);
        localStorage.setItem('auth_token', token);
        auth.login();
        auth.setUser();
        notify('success', 'Successfully logged in!');
        history.push('/');
      }
    };
    setup();
  }, [token]);
  return <div></div>;
};

export default Tokenize;
