import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import Dropdown from '../Dropdown/Dropdown';
import RateUser from '../modals/Profile/RateUser';
import ReportIssue from '../modals/Profile/ReportIssue';
import SuccessModal from '../util/SuccessModal.js';
import './DealWithProviderDetails.css';
import { useHistory } from 'react-router-dom';
import JobDetailsProvider from './JobDetailsProvider';
import UpdateDeadline from '../modals/Profile/UpdateDeadline';
import MarkDealFailed from '../modals/Profile/MarkDealFailed';
import RePostJob from '../modals/Profile/RePostJob';
import ReInitiateDeal from '../modals/Profile/ReInitiateDeal';
const DealWithProviderDetails = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [providerDetails, setProviderDetails] = useState('');

  const [showRateModal, setShowRateModal] = useState(false);
  const [showRateSuccess, setShowRateSuccess] = useState(false);
  const [showIssueSubmitModal, setShowIssueSubmitModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showRePostModal, setShowRePostModal] = useState(false);
  const [showReInitiateModal, setShowReInitiateModal] = useState(false);
  const [rePortIssueModal, setRePortIssueModal] = useState(false);
  const [updateDeadlineModal, setUpdateDeadlineModal] = useState(false);
  const [DeadlineModal, setDeadlineModal] = useState(false);
  const [markAsFailedModal, setMarkAsFailedModal] = useState(false);
  const [showSuccessFailed, setShowSuccessFailed] = useState(false);
  const [reInitiateDealModal, setReInitiateDealModal] = useState(false);
  const [reInitiateDealSuccess, setReInitiateDealSuccess] = useState(false);
  const [rePostJobModal, setRePostJobModal] = useState(false);
  const [rePostJobSuccess, setRePostJobSuccess] = useState(false);
  const history = useHistory();
  const onCancel = () => {
    setShowSubmitModal(false);
    setShowIssueSubmitModal(false);
  };

  const onSuccess = () => {
    setRePortIssueModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const fetchDealDetails = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/deal?deal_id=${props.match.params.id}`
      );
      setProviderDetails(responseData);
    } catch (err) {}
  };
  // useEffect(() => {

  // 	fetchDealDetails();
  // }, [props.match.params.id, sendRequest]);
  return (
    <Fragment>
      <RateUser
        show={showRateModal}
        providerId={providerDetails.provider_id?._id}
        clientId={providerDetails?.client_id?._id}
        provider={providerDetails.provider_id}
        // dealId={props.match.params.id}
        onSuccess={() => {
          setShowRateSuccess(true);
        }}
        onCancel={() => {
          setShowRateModal(false);
        }}
      />
      <SuccessModal
        message='Rating was successful. Thank you for your feedback.'
        show={showRateSuccess}
        onClear={() => {
          setShowRateSuccess(false);
        }}
      />
      <SuccessModal
        message='Job Re-posted successfully'
        show={showRePostModal}
        onClear={() => {
          setShowRePostModal(false);
        }}
      />
      <SuccessModal
        message='Deal Re-initiated Successfully'
        show={showReInitiateModal}
        onClear={() => {
          setShowReInitiateModal(false);
        }}
      />

      <ReportIssue
        show={showIssueSubmitModal}
        // deal_id={props.dealId}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />

      <SuccessModal
        message='Report was successful. Thank you for your feedback.'
        show={rePortIssueModal}
        onClear={() => {
          setRePortIssueModal(false);
        }}
      />

      <MarkDealFailed
        show={markAsFailedModal}
        //   deal_id={props.match.params.id}
        onSuccess={() => setShowSuccessFailed(true)}
        onCancel={() => setMarkAsFailedModal(false)}
      />
      <SuccessModal
        message='Deal Mark as failed successfully'
        show={showSuccessFailed}
        onClear={() => {
          setShowSuccessFailed(false);
        }}
      />
      <RePostJob
        fetchDealDetails={fetchDealDetails}
        // deal_id={props.match.params.id}
        show={rePostJobModal}
        onSuccess={() => setRePostJobSuccess(true)}
        onCancel={() => setRePostJobModal(false)}
      />
      <SuccessModal
        message='Job Re-posted successfully'
        show={rePostJobSuccess}
        onClear={() => {
          setRePostJobSuccess(false);
        }}
      />

      <ReInitiateDeal
        fetchDealDetails={fetchDealDetails}
        //   deal_id={props.match.params.id}
        show={reInitiateDealModal}
        onSuccess={() => setReInitiateDealSuccess(true)}
        onCancel={() => setReInitiateDealModal(false)}
      />

      <SuccessModal
        message='Deal Re-initiated successfully'
        show={reInitiateDealSuccess}
        onClear={() => {
          setReInitiateDealSuccess(false);
        }}
      />

      <UpdateDeadline
        fetchDealDetails={fetchDealDetails}
        //   deal_id={props.match.params.id}
        show={updateDeadlineModal}
        onSuccess={() => setDeadlineModal(true)}
        onCancel={() => setUpdateDeadlineModal(false)}
      />
      <SuccessModal
        message='Deadline updated successfully'
        show={DeadlineModal}
        onClear={() => {
          setDeadlineModal(false);
        }}
      />

      <div className='Profile--container'>
        <div className='providerDealDetails__top'>
          <button
            className='profile-btn-go'
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/profile/${auth.user._id}`)}
          >
            Back to profile
          </button>
          <h5 className='info-'>Deal with Provider</h5>
          <div className='status-wrapped'>
            <h6 className='status-info'>{providerDetails.state}</h6>
          </div>
        </div>
        <div className='dealDetails1 '>
          <h5>
            Deal Details{' '}
            {providerDetails?.state === 'COMPLETE' ? (
              ''
            ) : providerDetails?.state === 'FAILED' ? (
              <span>
                <Dropdown
                  options={[
                    providerDetails.allow_repost && {
                      label: 'RePost This Job',
                      onClick: (e) => {
                        e.stopPropagation();
                        setRePostJobModal(true);
                      },
                    },
                    {
                      label: 'Re-Initiate Deal',
                      onClick: (e) => {
                        e.stopPropagation();
                        setReInitiateDealModal(true);
                      },
                    },
                  ]}
                />
              </span>
            ) : (
              <span>
                <Dropdown
                  options={[
                    auth.isAdmin && {
                      label: 'Mark Deal as Failed',
                      onClick: (e) => {
                        e.stopPropagation();
                        setMarkAsFailedModal(true);
                      },
                    },
                    {
                      label: 'Update Deadline',
                      onClick: (e) => {
                        e.stopPropagation();
                        setUpdateDeadlineModal(true);
                      },
                    },
                  ]}
                />
              </span>
            )}
          </h5>
        </div>
        <hr style={{ marginTop: '10px', marginBottom: '20px' }} />
        <div className='info-details'>
          <h6>
            Job Posted on {providerDetails?.job_id?.createdAt?.substring(0, 10)}
          </h6>
          <h6>
            Deal Started on {providerDetails?.createdAt?.substring(0, 10)}
          </h6>
          <h6>Deadline: {providerDetails?.deadline?.substring(0, 10)}</h6>
          <h5>
            Total Charge{' '}
            <span className='amount-money'>
              {' '}
              {providerDetails?.bid_id?.total_payable_amount_client} BDT
            </span>
          </h5>
          <h5>
            Paid{' '}
            <span className='amount-money'>
              {' '}
              {providerDetails?.bid_id?.total_payable_amount_client -
                providerDetails?.client_due}{' '}
              BDT
            </span>
          </h5>
          <h5>
            Due{' '}
            <span className='amount-money'>
              {' '}
              {providerDetails?.client_due} BDT
            </span>
          </h5>
        </div>

        <div className='provider--details'>
          <h4>Provider Details</h4>
          <hr style={{ marginTop: '10px', marginBottom: '20px' }} />

          <div className='dealDetails1'>
            <h5>
              Service Provider{' '}
              <span>
                <Dropdown
                  options={[
                    {
                      label: 'Rate this Provider',
                      onClick: (e) => {
                        e.stopPropagation();
                        setShowRateModal(true);
                      },
                    },
                    {
                      label: 'Report this Provider',
                      onClick: (e) => {
                        e.stopPropagation();
                        setShowIssueSubmitModal(true);
                      },
                    },
                  ]}
                />
              </span>
            </h5>
          </div>
          <div className='deal-info-provider'>
            <div style={{ display: 'flex' }}>
              <h5 className='heading-p'>
                {providerDetails?.provider_id?.fullname} (
                {providerDetails?.provider_id?.provider_rating}
                /5.00)
              </h5>
              <img
                src='/assets/edit-p.png'
                alt=''
                style={{
                  height: '34px',
                  width: '34px',
                  marginTop: '18px',
                  marginLeft: '11px',
                }}
              />
            </div>
            {/* <p>10:32 am, 26th September</p> */}
            <h6 className='money-amount'>
              Claimed Price:{' '}
              <span className='money-amount-span'>
                {providerDetails?.bid_id?.claimed_budget} BDT
              </span>
            </h6>
            <p className='comments'>
              Comment: <span>{providerDetails?.bid_id?.comment}</span>
            </p>
            {providerDetails?.bid_id?.job_submitted && (
              <button className='submit-works'>View Submitted Work</button>
            )}
          </div>
        </div>
        {providerDetails?.job_id?.print && (
          <div className='provider--details'>
            <div className='dealDetails1'>
              <h5>
                Print + Delivery Provider{' '}
                <span>
                  <Dropdown
                    options={[
                      {
                        label: 'Rate this Provider',
                        onClick: (e) => {
                          e.stopPropagation();
                          setShowRateModal(true);
                        },
                      },
                      {
                        label: 'Report this Provider',
                        onClick: (e) => {
                          e.stopPropagation();
                          setShowIssueSubmitModal(true);
                        },
                      },
                    ]}
                  />
                </span>
              </h5>
            </div>
            <div className='deal-info-provider'>
              <div style={{ display: 'flex' }}>
                <h5 className='heading-p'>
                  {providerDetails?.print_bid_id.provider_id?.fullname} (
                  {providerDetails?.print_bid_id.provider_id?.provider_rating}
                  /5.00)
                </h5>
                <img
                  src='/assets/edit-p.png'
                  style={{
                    height: '34px',
                    width: '34px',
                    marginTop: '18px',
                    marginLeft: '11px',
                  }}
                  alt=''
                />
              </div>
              <p>{providerDetails?.print_bid_id?.createdAt.substring(0, 10)}</p>
              <h6 className='money-amount'>
                Claimed Price:{' '}
                <span className='money-amount-span'>
                  {providerDetails?.print_bid_id?.claimed_budget} BDT
                </span>
              </h6>
              <p className='comments'>
                Comment: <span>{providerDetails?.print_bid_id?.comment}</span>
              </p>
            </div>
          </div>
        )}

        <JobDetailsProvider jobDetails={providerDetails.job_id} />
      </div>
    </Fragment>
  );
};

export default DealWithProviderDetails;
