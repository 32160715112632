import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth-context";

function PersonalCard({
  email,
  nid,
  birthdate,
  mobilebank,
  location,
  notificationDest,
}) {
  const auth = useContext(AuthContext);
  return (
    <div
      className="p-4 overview-card"
      style={{
        marginTop: "20px",
        marginBottom: "40px",
        boxShadow: "0px 0px 12px rgb(0 0 0 / 10%)",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ flex: "1"}}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ flex: "1" }}>
            <p
              style={{
                fontSize: "14px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {auth.language === "en" ? "Email Address" : "ইমেইল"}
            </p>
          </div>
          <div style={{ flex: "1" }}>
            <h5 style={{ color: "rgba(0,0,0,.7)" }}>{email}</h5>
          </div>
        </div>

        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ flex: "1" }}>
            <p
              style={{
                fontSize: "14px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {auth.language === "en" ? "NID" : "এনআইডি"}
            </p>
          </div>
          <div style={{ flex: "1" }}>
            <h5 style={{ color: "rgba(0,0,0,.7)" }}>{nid}</h5>
          </div>
        </div>

        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ flex: "1" }}>
            <p
              style={{
                fontSize: "14px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {auth.language === "en"
                ? "Notification Destination"
                : "বিজ্ঞপ্তির গন্তব্য"}
            </p>
          </div>
          <div style={{ flex: "1" }}>
            <h5 style={{ color: "rgba(0,0,0,.7)" }}>
              {auth?.user?.notification_destination?.destination ||
                "Not Set Yet"}
              {!auth?.user?.notification_destination?.verified && (
                <a href="/user/edit_profile" style={{color:"red"}}>
                  {auth.language === "en" ? " (Not Verified)" : " (ভেরিফাইড নয়))"}
                </a>
              )}
            </h5>
          </div>
        </div>
      </div>

      <div style={{ flex: "1"}}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ flex: "1" }}>
            <p
              style={{
                fontSize: "14px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {auth.language === "en" ? "Date of Birth" : "জন্ম তারিখ"}
            </p>
          </div>
          <div style={{ flex: "1" }}>
            <h5 style={{ color: "rgba(0,0,0,.7)" }}>{birthdate}</h5>
          </div>
        </div>

        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ flex: "1" }}>
            <p
              style={{
                fontSize: "14px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {auth.language === "en"
                ? "Mobile Banking Number"
                : "মোবাইল ব্যাংকিং নম্বর"}
            </p>
          </div>
          <div style={{ flex: "1" }}>
            <h5 style={{ color: "rgba(0,0,0,.7)" }}>{mobilebank}</h5>
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ flex: "1" }}>
            <p
              style={{
                fontSize: "14px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {auth.language === "en" ? "Location" : "অবস্থান"}
            </p>
          </div>
          <div style={{ flex: "1" }}>
            <h5 style={{ color: "rgba(0,0,0,.7)" }}>{location}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalCard;
