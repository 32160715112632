import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import axios from "axios";

const AgentInfo = () => {
  const auth = useContext(AuthContext);
  const language = auth.language;
  const [password, setPassword] = useState("");
  const [universities, setUniversities] = useState([]);
  const [fields, setFields] = useState([]);
  const [userData, setUserData] = useState(null);
  const { sendRequest } = useHttpClient();
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    console.log("fields", fields);
  });

  const fetchUser = async () => {
    try {
      const resUser = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/user",
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      setUserData(resUser.data);
      setUniversities(resUser.data.universities || []);
      setFields(resUser.data.form_builder || []);
    } catch (error) {
      notify("err", "Failed to fetch user data.");
    }
  };

  const notify = (type, msg) => {
    type === "err"
      ? toast.error(`${msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      : toast.success(`${msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
  };

  const handleAddField = () => {
    setFields([
      ...fields,
      {
        _id: fields.length + 1,
        field_name: "",
        field_type: "Text",
        options: "",
        is_required: true,
        children: [],
      },
    ]);
  };

  const handleFieldChange = (fieldId, key, value, isDropdown) => {
    setFields((prevFields) =>
      prevFields.map(
        (field) =>
          field._id === fieldId
            ? isDropdown
              ? { ...field, options: value } // Split the value by commas if it's a dropdown
              : { ...field, [key]: value } // Otherwise, update the field with the given key and value
            : field // If the field ID doesn't match, return the field as is
      )
    );
  };

  const handleChildrenChange = (
    fieldId,
    childrenId,
    key,
    value,
    isDropdown
  ) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field._id === fieldId
          ? {
              ...field,
              children: field.children.map((child) =>
                child._id === childrenId
                  ? {
                      ...child,
                      ...(isDropdown ? { options: value } : { [key]: value }),
                    }
                  : child
              ),
            }
          : field
      )
    );
  };

  const handleRemoveField = (id) => {
    setFields(fields.filter((field) => field._id !== id));
  };

  const formatData = () => {
    const formattedFields = fields.map((field) => ({
      field_name: field.field_name,
      field_type: field.field_type || "Text",
      options: field.options,
      is_required: field.is_required,
      children: field.children
        ? field.children.map((children) => ({
            field_name: children.field_name,
            field_type: children.field_type || "Text",
            options: children.options,
            is_required: children.is_required,
          }))
        : [],
    }));

    console.log(formattedFields);

    return {
      password: password || null,
      form_builder: formattedFields,
    };
  };

  const updateUser = async () => {
    console.log("API", JSON.stringify(formatData()));
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/update_info",
        "POST",
        JSON.stringify(formatData()),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        setPassword("");
        setConfirmModal(false);
        notify("success", "Profile updated successfully!");
      } else throw new Error("Something Went Wrong!");
    } catch (err) {
      notify("err", err.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const inputStyle = {
    width: "100%",
    height: "50px",
    padding: "8px",
    margin: "0",
    borderRadius: "4px",
    background: "var(--WhiteBG, #FFF)",
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.10)",
    cursor: "pointer",
    appearance: "none",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'><path fill='none' stroke='gray' stroke-width='2' d='M6 9l6 6 6-6'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 8px center",
    border: "1px solid #CCC",
  };

  return (
    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <>
        {fields?.map((field) => (
          <div key={field._id} style={{ marginBottom: "30px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "10px",
                gap: "10px",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div style={{ flex: "1", display: "flex", width: "100%" }}>
                <input
                  type="text"
                  placeholder="field name"
                  value={field.field_name}
                  onChange={(e) =>
                    handleFieldChange(field._id, "field_name", e.target.value)
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #CCC",
                  }}
                />
                <select
                  value={field.field_type}
                  onChange={(e) =>
                    handleFieldChange(field._id, "field_type", e.target.value)
                  }
                  style={{ ...inputStyle, marginLeft: "10px" }}
                >
                  <option value="Text">Text</option>
                  <option value="Number">Number</option>
                  <option value="Date">Date</option>
                  <option value="Country">Country</option>
                  <option value="Dropdown">Dropdown</option>
                  <option value="Composite">Composite</option>
                  <option value="List">List</option>
                  <option value="Single Attachment">Single Attachment</option>
                  <option value="Multiple Attachments">
                    Multiple Attachments
                  </option>
                </select>
                {field.field_type == "Dropdown" && (
                  <input
                    type="text"
                    placeholder="Options (use comma for multiple)"
                    value={field.options}
                    onChange={(e) =>
                      handleFieldChange(
                        field._id,
                        "null",
                        e.target.value,
                        "dropdown"
                      )
                    }
                    style={{
                      width: "100%",
                      padding: "8px",
                      marginLeft: "10px",
                      borderRadius: "4px",
                      border: "1px solid #CCC",
                    }}
                  />
                )}
                {(field.field_type!=="Composite" && field.field_type!=="List") && <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={field.is_required}
                    onChange={(e) =>
                      handleFieldChange(
                        field._id,
                        "is_required",
                        e.target.checked
                      )
                    }
                    style={{ marginRight: "5px" }}
                  />
                  Required
                </label>}
              </div>

              <button
                onClick={() => handleRemoveField(field._id)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  color: "#D8534E",
                  fontSize: "24px",
                  marginTop: "-5px",
                }}
              >
                &times;
              </button>
            </div>
            {field.children && field.children.length > 0 && (
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <h5>{language === "en" ? "Sub Fields" : "উপ ক্ষেত্র"}</h5>
                {field.children.map((children) => (
                  <div
                    key={children._id}
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Field Name"
                      value={children.field_name}
                      onChange={(e) =>
                        handleChildrenChange(
                          field._id,
                          children._id,
                          "field_name",
                          e.target.value
                        )
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #CCC",
                      }}
                    />

                    <select
                      value={children.field_type}
                      onChange={(e) =>
                        handleChildrenChange(
                          field._id,
                          children._id,
                          "field_type",
                          e.target.value
                        )
                      }
                      style={inputStyle}
                    >
                      <option value="Text">
                        {language === "en" ? "Text" : "টেক্সট"}
                      </option>
                      <option value="Number">
                        {language === "en" ? "Number" : "সংখ্যা"}
                      </option>
                      <option value="Date">
                        {language === "en" ? "Date" : "তারিখ"}
                      </option>
                      <option value="Country">
                        {language === "en" ? "Country" : "দেশ"}
                      </option>
                      <option value="Dropdown">
                        {language === "en" ? "Dropdown" : "ড্রপডাউন"}
                      </option>
                      <option value="Single Attachment">
                        {language === "en"
                          ? "Single Attachment"
                          : "একক সংযুক্তি"}
                      </option>
                      <option value="Multiple Attachments">
                        {language === "en"
                          ? "Multiple Attachments"
                          : "বহু সংযুক্তি"}
                      </option>
                    </select>
                    {children.field_type == "Dropdown" && (
                      <input
                        type="text"
                        placeholder="Options (use comma for multiple)"
                        value={children.options}
                        onChange={(e) =>
                          handleChildrenChange(
                            field._id,
                            children._id,
                            "options",
                            e.target.value,
                            true
                          )
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginLeft: "10px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    )}
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={children.is_required}
                        onChange={(e) =>
                          handleChildrenChange(
                            field._id,
                            children._id,
                            "is_required",
                            !children.is_required
                          )
                        }
                        style={{ marginRight: "5px" }}
                      />
                      Required
                    </label>
                    <button
                      onClick={() =>
                        handleFieldChange(field._id, "children", [
                          ...field.children.filter(
                            (sf) => sf._id !== children._id
                          ),
                        ])
                      }
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        color: "#D9534F",
                        fontSize: "24px",
                      }}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            )}
            {["Composite", "List"].includes(field.field_type) && (
              <Button
                onClick={() =>
                  handleFieldChange(field._id, "children", [
                    ...field.children,
                    {
                      _id: field.children.length + 1,
                      field_name: "",
                      field_type: "Text",
                      is_required:true,
                    },
                  ])
                }
                style={{ marginLeft: "10px" }}
              >
                {language === "en" ? "Add Sub Field" : "উপ ক্ষেত্র যোগ করুন"}
              </Button>
            )}
          </div>
        ))}
        <Button onClick={handleAddField} style={{ marginBottom: "30px" }}>
          {language === "en" ? "Add Field" : "ক্ষেত্র যোগ করুন"}
        </Button>
      </>
      <div>
        <Button variant="primary" onClick={() => setConfirmModal(true)}>
          {language === "en" ? "Save Changes" : "পরিবর্তনগুলোর সংরক্ষন"}
        </Button>
      </div>

      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="form-section mb-0">
            {language === "en"
              ? "Enter Current Password To Update Changes"
              : "পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন"}
            <input
              type="password"
              required
              className="form-control mb-0"
              placeholder={
                language === "en"
                  ? "Enter your password"
                  : "আপনার পাসওয়ার্ড লিখুন"
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-secondary"
            onClick={() => setConfirmModal(false)}
          >
            {language === "en" ? "Close" : "বন্ধ"}
          </Button>
          <Button variant="primary" type="button" onClick={updateUser}>
            {language === "en" ? "Save Changes" : "পরিবর্তনগুলোর সংরক্ষন"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgentInfo;
