import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { AuthContext } from '../../context/auth-context';
import NewFooter from '../footer/NewFooter';
import NewNavBar from '../navigation/NewNavBar';
import "./AdminLayout.css";
import AdminSidebar from './AdminSidebar/AdminSidebar';

export default function AdminLayout({ children }) {
  const { user } = useContext(AuthContext);

  if (!user) return null;
  if (user && (user?.user_type === "USER")) return <Redirect to="/" />;
  return (
    <div className="adminLayout">
      {/* <Header /> */}
      <NewNavBar />
      <div className="adminLayout__content">
        <AdminSidebar />
        <div className="adminLayout__body">
          {children}
        </div>
      </div>
      {/* <Footer /> */}
      <NewFooter />
    </div>
  )
}