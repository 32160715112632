import FileSaver from 'file-saver';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import { Button } from '../button/Button';
import { ImagePreviewModal } from '../ImagePreviewModal/ImagePreviewModal';
import ApplyBid from '../modals/bid/ApplyBid';
import ProviderList from '../modals/bid/ProviderList';
import ErrorModal from '../util/ErrorModal';
import SuccessModal from '../util/SuccessModal';
import './Card.css';
import UserCard from './UserCard';
const VARIANT = ['small', 'detailed', 'editor'];
const JobCard = (props) => {
  const { error, clearError, sendRequest } = useHttpClient();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [showProviders, setShowProviders] = useState(false);
  const [showBidSuccess, setShowBidSuccess] = useState(false);
  const [showDealSuccess, setShowDealSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => setIsOpen(true);
  const saveFile = (url) => {
    FileSaver.saveAs(process.env.REACT_APP_BACKEND_URL + '/' + url, url);
  };

  const [printBidId, setPrintBidId] = useState('');
  const [serviceBidId, setServiceBidId] = useState('');

  const [bids, setBids] = useState([]);
  const handleInitialDeal = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/client/initiate_deal`,
        'POST',
        JSON.stringify({
          bid_id: serviceBidId,
          print_bid_id: printBidId,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.ssl_url) {
        window
          .open(
            responseData.ssl_url,
            '',
            ` scrollbars=yes,menubar=no,height=680 ,width=520, left=${
              window.screen.width / 2 - 260
            } top=${
              window.screen.height / 2 - 340
            }resizable=yes,toolbar=no,location=no,status=no"`
          )
          .focus();
      }
    } catch (err) {}
  };
  useEffect(() => {
    const fetchBids = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/client/get_potential_deals?job_id=${props.id}`
        );
        setBids(responseData);
      } catch (err) {}
    };
    fetchBids();
    //eslint-disable-next-line
  }, [props.id]);

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const applyForBid = () => {
    setModal(true);
  };
  const onCancel = () => {
    setModal(false);
    setShowProviders(false);
  };

  const onSuccess = () => {
    setShowBidSuccess(true);
  };
  const upvote = async () => {
    if (!props.upvote.includes(auth.user._id)) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/upvote/?job_id=${props.id}`,
          'PUT',
          JSON.stringify({}),
          {
            'Content-Type': 'application/json',
          }
        );
        if (responseData.success === 'yes') {
          props.onSuccess();
        }
      } catch (err) {}
    }
  };
  const downvote = async () => {
    if (!props.downvote.includes(auth.user._id)) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/downvote/?job_id=${props.id}`,
          'PUT',
          JSON.stringify({}),
          {
            'Content-Type': 'application/json',
          }
        );
        if (responseData.success === 'yes') {
          props.onSuccess();
        }
      } catch (err) {}
    }
  };

  const checkVariant = VARIANT.includes(props.variant)
    ? props.variant
    : VARIANT[0];

  let render;
  switch (checkVariant) {
    case VARIANT[1]:
      render = (
        <React.Fragment>
          <ErrorModal error={error} onClear={clearError} />

          <ApplyBid
            onCancel={onCancel}
            onSuccess={onSuccess}
            show={modal}
            budget={props.budget}
            id={props.id}
            delivery={props.delivery}
            print={props.print}
          />
          <SuccessModal
            show={showBidSuccess}
            message={'Bid was successful. Wait for client to accept your bid.'}
            onClear={() => {
              setShowBidSuccess(false);
            }}
          />
          <SuccessModal
            show={showDealSuccess}
            message={'Deal Initiated successful'}
            onClear={() => {
              setShowDealSuccess(false);
            }}
          />
          <div className='jobcard--detailed'>
            {props.mypost && (
              <ProviderList
                show={showProviders}
                onCancel={onCancel}
                id={props.id}
                title={props.title}
                postDate={props.postDate}
                category={props.category}
                subCategory={props.subCategory}
                budget={props.budget}
              />
            )}
            <div className='clientPrintDealDetails__top'>
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/profile/${auth.user._id}`)}
              >
                Back to profile
              </button>{' '}
              Job details
            </div>
            <div className='clientPrintDealDetails__top'>
              <h2>Job post Details</h2>
              <div
                style={{ borderTop: '1px solid #707070', margin: '1rem 0' }}
              />
              {/* <h4>Job post Details <h6><Dropdown options={[
          { label: "Rate this Provider", onClick: () => {} },
          { label: "Report this Provider", onClick: () => {} }
        ]} /></h6></h4> */}
            </div>
            <h3>{props.title}</h3>
            <p className='p1'>
              {(props.category.length > 0 ? props.category + ' - ' : '') +
                props.subCategory[0]}
            </p>
            <p className='p2'>
              Posted on
              {' ' +
                new Date(props.postDate).toLocaleDateString(
                  undefined,
                  dateOptions
                )}
            </p>
            <p className='p4'>Budget {props.budget} BDT</p>
            <p className='p5'>{props.description}</p>
            <div className='attachment_preview'>
              <div className='attachment'>
                <img src='/assets/attachments.svg' alt='' />
                Attachments
              </div>
              {props.attachments && props.attachments.length === 0 && (
                <p className='p6'>no attachments available</p>
              )}
              <table className='attachment_table' style={{ display: 'flex' }}>
                {props.attachments &&
                  props.attachments.map((attachment) => {
                    return (
                      <tr>
                        <td key={attachment._id}>
                          {/* <a
                          target="_new"
                          href={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                        >
                          <p className="p6">{attachment.substr(6)}</p>
                        </a> */}
                          <img
                            className='img_preview'
                            // onClick={() => {
                            //   saveFile(attachment);
                            // }}
                            onClick={showModal}
                            src={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                            alt=''
                          />

                          {isOpen && (
                            <ImagePreviewModal
                              src={`${process.env.REACT_APP_BACKEND_URL}/${attachment}`}
                              alt='snow'
                              onClose={() => setIsOpen(false)}
                            />
                          )}
                        </td>
                        <td>
                          <img
                            style={{ marginRight: '12px' }}
                            className='download__icon'
                            onClick={() => {
                              saveFile(attachment);
                            }}
                            src='/assets/download.svg'
                            alt=''
                          />
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            {props.paper && (
              <div>
                <div className='attachment'>
                  <img src='/assets/print_details.svg' alt='' />
                  Print Details
                </div>
                <table className='print__table'>
                  <tr>
                    <td>Page:</td>
                    <td className='gap__left'>{props.paper}</td>
                  </tr>
                  <tr>
                    <td>Mailing Address:</td>
                    <td className='gap__left'>{props.address}</td>
                  </tr>
                  <tr>
                    <td>Black And White</td>
                    <td className='gap__left'>
                      <input type='checkbox' checked={props.isBW} />
                    </td>
                  </tr>
                  <tr>
                    <td>Double Page</td>
                    <td className='gap__left'>
                      <input type='checkbox' checked={props.isDP} />
                    </td>
                  </tr>
                </table>
              </div>
            )}
            {auth.isLoggedIn && !props.approval && (
              <div className='vote--container'>
                <div className='vote--item'>
                  <span>{props.upvote && props.upvote.length}</span>
                  <img
                    onClick={upvote}
                    src={
                      auth.user &&
                      props.upvote &&
                      props.upvote.includes(auth.user._id)
                        ? '/assets/liked.svg'
                        : '/assets/like.svg'
                    }
                    alt=''
                  />
                </div>
                <div className='vote--item'>
                  <span>{props.downvote && props.downvote.length}</span>
                  <img
                    onClick={downvote}
                    src={
                      auth.user &&
                      props.downvote &&
                      props.downvote.includes(auth.user._id)
                        ? '/assets/disliked.svg'
                        : '/assets/dislike.svg'
                    }
                    alt=''
                  />
                </div>
              </div>
            )}
            <div className='card--bottom'>
              <UserCard
                id={props.clientId}
                self
                name={props.clientName}
                rating={props.clientRating}
                picture={`${process.env.REACT_APP_BACKEND_URL}/${props.clientProfilePic}`}
              />

              <div className='bottom--bid'>
                <p className='bid--deadline'>
                  {' '}
                  Deadline:{' '}
                  {new Date(props.deadline).toLocaleDateString(
                    undefined,
                    dateOptions
                  )}
                </p>

                {auth.isLoggedIn &&
                  !auth.isAdmin &&
                  !auth.isManager &&
                  !props.mypost && (
                    <Button onClick={applyForBid} buttonStyle='btn--apply'>
                      Apply for Bid
                    </Button>
                  )}
                {/* {!auth.isAdmin &&
                  !auth.isManager &&
                  props.mypost &&
                  props.approved && (
                    <Button
                      onClick={() => {
                        setModal(true)
                        setShowProviders(true);
                      }}
                      color="deepViolet"
                      buttonStyle="btn--apply"
                    >
                      View Bidders
                    </Button>
                  )} */}
              </div>
            </div>

            {!auth.isAdmin &&
              !auth.isManager &&
              props.mypost &&
              props.approved && (
                <Fragment>
                  <div className='confirm_bidders'>
                    <h2 className='titles-1'>Confirmed Bidders</h2>
                    <hr />
                    <p className='info-titles'>
                      Bidders who confirmed your deal after you accepted their
                      bids.{' '}
                    </p>

                    {/* <button 
              onClick={(e) => {
                    e.stopPropagation();
                    props.showProviders();
                  }}
              className="all-bidders">See All Bidders</button> */}
                    {/* {
                   !auth.isAdmin &&
                   !auth.isManager &&
                  props.mypost &&
                  props.approved && (
                    <Button
                      onClick={() => {
                      
                        setShowProviders(true);
                      }}
                      color="deepViolet"
                      buttonStyle="btn--apply"
                    >
                      View Bidders
                    </Button>
                  )} */}
                    {!auth.isAdmin &&
                      !auth.isManager &&
                      props.mypost &&
                      props.approved && (
                        <Button
                          onClick={() => {
                            setShowProviders(true);
                          }}
                          className='all-bidders'
                          //  color="deepViolet"
                          //  buttonStyle="btn--apply"
                        >
                          {' '}
                          See All Bidders
                        </Button>
                      )}

                    <div className='view_bidders_or_provider'>
                      <div className='parent-div'>
                        <div className='service-bid'>
                          <h2 className='service-heads'>Bids for Services</h2>

                          {bids.length > 0 &&
                            bids.map((b) =>
                              b.bid_type === 'NORMAL' ? (
                                <div className='radio-content'>
                                  <input
                                    type='radio'
                                    name='service'
                                    value={b._id}
                                    onChange={(e) =>
                                      setServiceBidId(e.target.value)
                                    }
                                  />
                                  <div className='content'>
                                    <div className='edit-content'>
                                      <h2 className='details-sec'>
                                        {b.provider_id.fullname} (
                                        {b.provider_id.provider_rating}/5.00)
                                      </h2>
                                      <img src='/assets/edit-p.png' alt='' />
                                    </div>

                                    <p className='dates'>
                                      {b.createdAt.substr(0, 10)}
                                    </p>
                                    <p className='budget-sec'>
                                      Budget:{' '}
                                      <span className='money'>
                                        {b.claimed_budget} BDT
                                      </span>
                                    </p>
                                    <h5 className='comments'>
                                      Comment: {b.comment}
                                    </h5>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )
                            )}
                        </div>
                        <div className='print-bid'>
                          <h2 className='service-heads'>
                            Bids for Print + Delivery
                          </h2>
                          {bids.length > 0 &&
                            bids.map((b) =>
                              b.bid_type === 'PRINT' ? (
                                <div className='radio-content'>
                                  <input
                                    type='radio'
                                    name='print'
                                    value={b._id}
                                    onChange={(e) =>
                                      setPrintBidId(e.target.value)
                                    }
                                  />
                                  <div className='content'>
                                    <div className='edit-content'>
                                      <h2 className='details-sec'>
                                        {b.provider_id.fullname} (
                                        {b.provider_id.provider_rating}/5.00)
                                      </h2>
                                      <img src='/assets/edit-p.png' alt='' />
                                    </div>

                                    <p className='dates'>
                                      {b.createdAt.substr(0, 10)}
                                    </p>
                                    <p className='budget-sec'>
                                      Budget:{' '}
                                      <span className='money'>
                                        {b.claimed_budget} BDT
                                      </span>
                                    </p>
                                    <h5 className='comments'>
                                      Comment: {b.comment}
                                    </h5>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    <div className='totalPrice'>
                      <div className=''>
                        <h5>
                          Total Price:{' '}
                          <strong style={{ color: '#239710' }}>900 BDT</strong>
                        </h5>{' '}
                        <br />
                        {/* {printBidId !== '' && serviceBidId !== '' && } */}
                        <button
                          disabled={printBidId === '' && serviceBidId === ''}
                          className={` ${
                            printBidId === '' && serviceBidId === ''
                              ? 'initiate_deal1'
                              : 'initiate_deal'
                          }`}
                          onClick={handleInitialDeal}
                        >
                          Initiate Deal
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
          </div>
        </React.Fragment>
      );
      break;
    case VARIANT[2]:
      render = (
        <React.Fragment>
          <div
            className='jobcard--editor'
            onClick={() => {
              history.push(`/my-post/${props.id}`);
            }}
          >
            <div className='cardheader__tooltip'>
              <h1>{props.title}</h1>

              {/* <h1>{`${props.title.substr(0, 16)}${
                props.title.length > 16 ? "..." : ""
              }`}</h1> */}
              {/* {props.approved ? (
                <Tooltip width="260px" tip="Admin has approved this post">
                  <div className="approved">APPROVED</div>
                </Tooltip>
              ) : (
                <Tooltip
                  width="300px"
                  tip="Waiting for admin to approve this post"
                >
                  <div className="pending">PENDING</div>
                </Tooltip>
              )} */}
              {props.approved ? (
                <h5
                  style={{
                    color: 'green',
                    fontSize: '1rem',
                    fontWeight: 'bolder',
                  }}
                >
                  Approved
                </h5>
              ) : (
                <h5
                  style={{
                    color: 'red',
                    fontSize: '1rem',
                    fontWeight: 'bolder',
                  }}
                >
                  Not Approved
                </h5>
              )}
            </div>
            <p className='p1'>
              {(props.category.length > 0 ? props.category + ' - ' : '') +
                props.subCategory[0]}
            </p>
            <p className='p2'>Posted on{' ' + props.postDate}</p>
            {/* <p className="p3">
              {props.description.length > 200
                ? props.description.substr(0, 199) + "..."
                : props.description}
            </p> */}
            <p className='p4'>Budget {props.budget} BDT</p>
            <div className='post--buttons'>
              <button
                onClick={() => {
                  history.push(`/my-post/${props.id}`);
                }}
                className='post_com edit_post_btn'
              >
                {' '}
                View Details
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  history.push('/edit-post/' + props.id);
                  //const win = window.open("/edit-post/" + props.id, "_blank");
                  //win.focus();
                }}
                className='post_com edit_post_btn'
              >
                {' '}
                Edit Post
              </button>

              {/* {props.approved && (
                <button
                  className="post_com view_bidders_btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.showProviders();
                  }}
                >
                  View Bidders
                </button>
              )} */}
            </div>
          </div>
        </React.Fragment>
      );
      break;

    default:
      render = (
        <React.Fragment>
          {props.detail && (
            <Link
              target={'_blank'}
              style={{ textDecoration: 'none', color: 'black' }}
              to={`/${props.mypost ? 'my-post' : 'job-details'}/${props.id}`}
            >
              <div className={`${'job--card'} ${props.tiny && 'tiny'}  `}>
                <h1>{`${props.title.substr(0, 16)}${
                  props.title.length > 16 ? '...' : ''
                }`}</h1>
                <p className='p1'>
                  {(props.category.length > 0 ? props.category + ' - ' : '') +
                    props.subCategory[0]}
                </p>
                <p className='p2'>Posted on{' ' + props.postDate}</p>
                {!props.tiny && (
                  <p className='p3'>
                    {props.description && props.description.length > 150
                      ? props.description.substr(0, 120) + '...'
                      : props.description}
                  </p>
                )}
                <p className='p4'>Budget {props.budget} BDT</p>
              </div>
            </Link>
          )}
          {props.dealsDetails && (
            <Link
              // target={"_blank"}
              style={{ textDecoration: 'none', color: 'black' }}
              to={`/${props.mypost ? 'my-post' : 'deal-details'}/${
                props.dealId
              }`}
              // to={`/${props.mypost ? "my-post" : "job-details"}/${props.id}`}
            >
              <div className={`${'job--card'} ${props.tiny && 'tiny'}  `}>
                <div style={{ display: 'flex' }}>
                  <h1>{props.title}</h1>
                  <img
                    style={{
                      float: 'right',
                      marginLeft: 'auto',
                      width: '30px',
                    }}
                    src='/assets/three_dots.svg'
                    alt=''
                  />
                </div>
                {/* <h1>{`${props.title.substr(0, 16)}${
                props.title.length > 16 ? "..." : ""
              }`}</h1> */}
                <p className='p1'>
                  {(props.category.length > 0 ? props.category + ' - ' : '') +
                    props.subCategory[0]}
                </p>
                {/* <p className="p2">Posted on{" " + props.postDate}</p> */}
                <p className='p2'>Deal started on {' ' + props.postDate}</p>
                <p className='p2'>Deadline {' ' + props.postDate}</p>
                {/* {!props.tiny && (
                <p className="p3">
                  {props.description && props.description.length > 150
                    ? props.description.substr(0, 120) + "..."
                    : props.description}
                </p>
              )} */}
                <p className='p5'>
                  Charge{' '}
                  <span className='money'>
                    {props.dealDetails.bid_id?.total_payable_amount_client} BDT
                  </span>
                </p>
                <p className='p5'>
                  Paid{' '}
                  <span className='money'>
                    {props.dealDetails.bid_id?.total_payable_amount_client -
                      props?.dealDetails.client_due}{' '}
                    BDT
                  </span>
                </p>
                <p className='p5'>
                  Due{' '}
                  <span className='money'>
                    {props?.dealDetails.client_due} BDT
                  </span>
                </p>
                <p className='p5'></p>
                <p className='p4'>Budget {props.budget} BDT</p>

                <div className='status-showing'>
                  <h5 className='real-status'>
                    {props.status === 'REFUNDED' ? (
                      <div className='approved'>Refunded</div>
                    ) : props.status === 'FAILED' ? (
                      <div className='approved'>FAILED</div>
                    ) : props.status === 'COMPLETE' ? (
                      <div className='approved'>COMPLETE</div>
                    ) : props.status === 'ONGOING' ? (
                      <div className='approved'>ONGOING</div>
                    ) : (
                      ''
                    )}
                  </h5>
                </div>
              </div>
            </Link>
          )}
          {props.dealsDetailsClients && (
            <Link
              // target={"_blank"}
              style={{ textDecoration: 'none', color: 'black' }}
              to={`/${props.mypost ? 'my-post' : 'client-details'}/${
                props._id
              }`}
              // to={`/${props.mypost ? "my-post" : "job-details"}/${props.id}`}
            >
              <div className={`${'job--card'} ${props.tiny && 'tiny'}  `}>
                {/* <h1>{`${props.title.substr(0, 16)}${
                props.title.length > 16 ? "..." : ""
              }`}</h1> */}
                {/* <p className="p1">
                {(props.category.length > 0 ? props.category + " - " : "") +
                  props.subCategory[0]}
              </p> */}
                {/* <p className="p2">Posted on{" " + props.postDate}</p> */}
                <p className='p2'>Bid Date {' ' + props.bidDate}</p>
                <p className='p2'>Deadline {' ' + props.deadline}</p>
                <p className='p4'>Budget {props.budget} BDT</p>

                {props.job?.print ? (
                  <h4
                    style={{
                      color: '#0000D6',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      marginTop: '15px',
                    }}
                  >
                    Print + Delivery Deals
                  </h4>
                ) : (
                  <h4
                    style={{
                      color: '#0000D6',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      marginTop: '15px',
                    }}
                  >
                    Service deals
                  </h4>
                )}

                <div className='status-showing'>
                  <h5 className='real-status'>
                    {props.status === 'REFUNDED' ? (
                      <div className='approved'>Refunded</div>
                    ) : props.status === 'FAILED' ? (
                      <div className='failed'>FAILED</div>
                    ) : props.status === 'COMPLETE' ? (
                      <div className='approved'>COMPLETE</div>
                    ) : props.status === 'ONGOING' ? (
                      <div className='approved'>ONGOING</div>
                    ) : (
                      ''
                    )}
                  </h5>
                </div>
              </div>
            </Link>
          )}
        </React.Fragment>
      );
  }
  return render;
};

export default JobCard;
