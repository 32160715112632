import React from "react";
import "./Card.css";
const Rating = (props) => {
  return (
    <div
      className={
        props.tiny ? "rating--container container--tiny" : "rating--container"
      }
    >
      <div className="top--info">
        <p>{props.text}</p>
        <img src="/assets/906794.svg" alt="" />
      </div>

      <p className="rating">{props.rating}</p>
      {!props.tiny && <p className="bottom--info">{props.description}</p>}
    </div>
  );
};

export default Rating;
