import React, { useState, useEffect } from 'react';
import './Notification.css';
import { useHttpClient } from '../../hooks/http-hook';
import NotificationItem from '../../components/items/NotificationItem';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import Paginator from '../../components/util/Paginator';
const Logs = () => {
  const { isLoading, sendRequest } = useHttpClient();
  const [logs, setLogs] = useState(null);
  const [page, setPage] = useState(1);
  const fetchLogs = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/user/see_logs/?page=${page}`
      );
      setLogs(responseData);
    } catch (err) {}
  };

  useEffect(() => {
    fetchLogs();
    //eslint-disable-next-line
  }, [page]);

  return (
    <div className='notification__container'>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='notification__head'>
        <h2 className='al_h'>Activity Log</h2>
      </div>
      <div className='not_items_container'>
        {logs &&
          logs.map((log) => {
            return <NotificationItem log key={log._id} notification={log} />;
          })}
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default Logs;
