import React, { useState, useEffect, useContext, useRef } from "react";
import "./BiddingCard.css";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import "react-input-range/lib/css/index.css";
import { useHistory } from "react-router-dom";
import { Modal, Spinner, Button, Overlay } from "react-bootstrap";
import { useHttpClient } from "../../../hooks/http-hook";
import axios from "axios";
import { toast } from "react-toastify";

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

function BiddingCard(props) {
  const auth = useContext(AuthContext);
  const [bid, setBid] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);
  const [showBidPlace, setShowBidPlace] = useState(false);
  const handleClose = () => setShowBidPlace(false);
  const handleShow = () => setShowBidPlace(true);
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const [claimedBudget, setClaimedBudget] = useState(0);
  const [bidType, setBidType] = useState("");
  const [comment, setComment] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [globalsVar, setGlobalsVar] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    bids();
    globals();
  }, []);

  useEffect(() => {
    console.log(globalsVar);
  }, [globalsVar]);

  const bids = async () => {
    try {
      const res = await axios.get(
        `https://somadha.com/api/provider/get_bids?job_id=${props.loadedJob?._id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      setBid(res.data[0]);
      console.log("data", res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const globals = async () => {
    try {
      const res = await axios.get("https://somadha.com/api/user/get_globals", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });
      setGlobalsVar(res?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const submitBid = async () => {
    setSpinner(true);
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/provider/create_bid",
        "POST",
        JSON.stringify({
          job_id: props.loadedJob?._id,
          claimed_budget: claimedBudget,
          bid_type: bidType,
          comment: comment,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        setSpinner(false);
        setClaimedBudget(0);
        setShowBidPlace(false);
        handleShow2();
        setBidType("NORMAL");
        setComment("");
      } else {
        throw new Error("Something went wrong! Please Try Again later");
      }
    } catch (err) {
      setSpinner(false);
      setClaimedBudget(0);
      setBidType("NORMAL");
      setShowBidPlace(false);
      setComment("");
      notify("err", err.message);
    }
  };

  const handleBid = (type) => {
    handleShow();
    setBidType(type);
  };

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const keyStyle = {
    color: "var(--grey_two, #666)",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    margin: "0px",
  };

  const valueStyle = {
    color: "var(--grey_one, #333)",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  const modalStyles = {
    header: {
      fontFamily: "Nunito",
      fontSize: "20px",
      fontWeight: 700,
      color: "#333",
    },
    body: {
      fontFamily: "Nunito",
      fontSize: "16px",
      color: "#666",
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };

  const infoItems = {
    [auth.language === "en" ? "Service Deadline" : "পরিষেবার সময়সীমা"]:
      new Date(props.loadedJob?.deadline).toLocaleDateString(
        undefined,
        dateOptions
      ) || "N/A",
    [auth.language === "en" ? "Job Budget" : "কাজের বাজেট"]: `৳${
      props.budget || "N/A"
    }`,
    [auth.language === "en" ? "System Charge" : "সিস্টেম চার্জ"]: `৳${
      bid?.payable_amount_provider?.charge_for_provider || "N/A"
    }`,
    [auth.language === "en"
      ? "Deductible Credit Amount"
      : "বিয়োগযোগ্য ক্রেডিট চার্জ"]: `৳${
      bid?.payable_amount_provider?.deductible_amount_credit || "N/A"
    }`,
    [auth.language === "en" ? "Total Payable" : "পরিশোধযোগ্য পরিমাণ"]: `৳${
      bid?.payable_amount_provider?.total_deliverable_amount_provider || "N/A"
    }`,
  };

  return (
    <div
      style={{
        padding: "20px",
        marginTop: "20px",
        marginBottom: "40px",
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.10)",
        borderRadius: "4px",
      }}
    >
      {Object.entries(infoItems).map(([key, value], index) => (
        <div key={index} style={{ marginBottom: "12px" }}>
          <div style={keyStyle}>{key}</div>
          <div style={valueStyle}>{value}</div>
        </div>
      ))}

      {!props.loadedJob.closed_by_admin && (
        <div className="d-grid gap-2">
          <button
            style={{
              width: "100%",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#2A41E8",
              border: "none",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "normal",
              marginTop: "16px",
            }}
            onClick={() => handleBid("NORMAL")}
            disabled={
              props.loadedJob?.state !== "BIDDING" ||
              bid?.length === 2 ||
              bid[0]?.bid_type === "NORMAL"
            }
          >
            {auth.language === "en" ? "Apply For Bid" : "কাজের জন্য আবেদন করুন"}
          </button>
        </div>
      )}

      {!props.loadedJob.closed_by_admin && props?.loadedJob?.my_bid && (
        <button
          type="button"
          style={{
            width: "100%",
            borderRadius: "4px",
            padding: "10px",
            backgroundColor: "#2A41E8",
            border: "none",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "normal",
            marginTop: "16px",
          }}
          onClick={props.handleShow}
          disabled={props.loadedJob?.my_bid?.state === "SUCCESSFUL"}
        >
          {auth.language === "en" ? "Update Bid" : "দরপত্র হালনাগাদ করুন"}
        </button>
      )}

      {!props.loadedJob.closed_by_admin &&
        props.loadedJob?.my_bid?.is_accepted_by_client && (
          <button
            type="button"
            style={{
              width: "100%",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#E3F9DC",
              border: "none",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#3CA51C",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "normal",
              marginTop: "16px",
            }}
            onClick={() => {
              props.setOpen(true);
              props.setSelectedBid({
                id: props.loadedJob?.my_bid?._id,
              });
            }}
            disabled={props?.loadedJob?.my_bid?.is_accepted_by_provider}
          >
            {auth.language === "en" ? "Accept Bid" : "দরপত্র গ্রহণ করুন"}
          </button>
        )}

      <Modal show={showBidPlace} onHide={handleClose}>
        <Modal.Header closeButton style={modalStyles.header}>
          <Modal.Title>
            {auth.language === "en" ? "Place Bid" : "দরপত্র প্রদান করুন"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyles.body}>
          {globalsVar?.bid_place_prompt && (
            <div>
              {JSON.parse(globalsVar?.bid_place_prompt).blocks.map(
                (block, index) => (
                  <div
                    key={index}
                    className="formatted-content"
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                  />
                )
              )}
            </div>
          )}
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="claimedBudget"
              style={{
                fontFamily: "Nunito",
                fontSize: "16px",
                fontWeight: 700,
                color: "#333",
              }}
            >
              {auth.language === "en"
                ? "Place Your Bid"
                : "আপনার দরপত্র প্রদান করুন"}
            </label>
            <input
              id="claimedBudget"
              type="number"
              min="0"
              value={claimedBudget}
              onChange={(e) => setClaimedBudget(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #CCC",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="comment"
              style={{
                fontFamily: "Nunito",
                fontSize: "16px",
                fontWeight: 700,
                color: "#333",
              }}
            >
              {auth.language === "en" ? "Comment" : "মন্তব্য"}
            </label>
            <textarea
              id="comment"
              value={comment}
              placeholder="Anything you want to say?"
              onChange={(e) => setComment(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #CCC",
                height: "100px",
              }}
            />
          </div>
          <Button
            variant="primary"
            onClick={submitBid}
            style={{
              width: "100%",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#2A41E8",
              border: "none",
              color: "#FFF",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "normal",
            }}
            disabled={spinner}
          >
            {spinner ? (
              <Spinner animation="border" variant="light" />
            ) : auth.language === "en" ? (
              "Submit Bid"
            ) : (
              "দরপত্র জমা দিন"
            )}
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton style={modalStyles.header}>
          <Modal.Title>
            {auth.language === "en" ? "Success" : "সফল"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyles.body}>
          <p>
            {auth.language === "en"
              ? "Your bid has been successfully submitted."
              : "আপনার দরপত্র সফলভাবে জমা হয়েছে।"}
          </p>
        </Modal.Body>
        <Modal.Footer style={modalStyles.footer}>
          <Button variant="secondary" onClick={handleClose2}>
            {auth.language === "en" ? "Close" : "বন্ধ"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BiddingCard;
