import React, { useState } from 'react';
import './AuthForm.css';
import Input from '../input/Input';
import { Button } from '../button/Button';
import { Link } from 'react-router-dom';
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_CONFIRMPASS,
} from '../../util/Validators';
import { useForm } from '../../hooks/form-hook';
import ErrorModal from '../util/ErrorModal';
import LoadingSpinner from '../util/LoadingSpinner';
import { useHttpClient } from '../../hooks/http-hook';
import { useParams } from 'react-router-dom';

const ResetPassword = (props) => {
  const [success, setSuccess] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  let { token } = useParams();
  const [formState, inputHandler] = useForm(
    {
      password: {
        value: '',
        isValid: false,
      },
      confirm: {
        value: '',
        isValid: false,
      },
    },
    false
  );
  const sendResetRequest = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/auth/verify_forgot_password',
        'POST',
        JSON.stringify({
          token: token,
          password: formState.inputs.password.value,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setSuccess(true);
      }
    } catch (err) {}
  };

  const resetComponent = (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className='auth--wrapper'>
        {isLoading && <LoadingSpinner asOverlay />}
        <h1>Reset Password</h1>

        {!success && (
          <form>
            <Input
              element='input'
              id='password'
              type='password'
              label='New Password'
              validators={[VALIDATOR_MINLENGTH(8)]}
              errorText='Please enter a password minimum 8 characters.'
              onInput={inputHandler}
            />
            <Input
              element='input'
              id='confirm'
              type='password'
              label='Confirm New Password'
              validators={[
                VALIDATOR_CONFIRMPASS(formState.inputs.password.value),
              ]}
              errorText='Please enter a password minimum 8 characters.'
              onInput={inputHandler}
            />
            <div className='button--wrapper mtop'>
              <Button
                buttonStyle='btn--auth'
                onClick={sendResetRequest}
                isDisabled={!formState.isValid ? 'true' : ''}
                type='submit'
              >
                Set New Password
              </Button>
            </div>
          </form>
        )}
        {success && (
          <React.Fragment>
            <div className='checkmail--details'>
              <p>
                <strong>Success!</strong>
              </p>
              <p>
                Your password
                <br />
                has been reset.
              </p>
            </div>
          </React.Fragment>
        )}
        <div className='links--wrapper'>
          <Link to='/login' style={{ color: 'var(--primary_color)' }}>
            <p className='bl mtop'>Back to Login</p>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );

  return <div>{resetComponent}</div>;
};

export default ResetPassword;
