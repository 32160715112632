import React, { useEffect, useState, useContext } from 'react';
import './AppliedBids.css';
import { useHttpClient } from '../../hooks/http-hook';
import BidCard from '../../components/card/BidCard';
import JobCard from '../../components/card/JobCard';
import { AuthContext } from '../../context/auth-context';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import { useHistory } from 'react-router';
import Paginator from '../../components/util/Paginator';
const AppliedBids = () => {
  const auth = useContext(AuthContext);
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();
  const [loadedBids, setLoadedBids] = useState();
  const [refr, setRefr] = useState(false);
  const [page, setPage] = useState(1);
  const onSuccess = () => {
    setRefr(!refr);
  };
  useEffect(() => {
    const fetchBids = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/bids/?user_id=${auth.user._id}&page=${page}`
        );
        setLoadedBids(responseData);
      } catch (err) {}
    };
    if (auth.user) {
      fetchBids();
    }
    //eslint-disable-next-line
  }, [auth.user, refr, page]);
  return (
    <div className='appliedbid__container'>
      {isLoading && <LoadingSpinner asOverlay />}
      <h1>Bids you've applied for</h1>
      <p className='lookingbids'>
        Looking for Bids on your posts?{' '}
        <u
          onClick={() => {
            history.push('/user/job_posts');
          }}
        >
          Go Here
        </u>
      </p>
      <div className='appliedbid__cards'>
        {loadedBids &&
          loadedBids.map((bid) => {
            return (
              <BidCard
                key={bid._id}
                id={bid._id}
                bidDate={new Date(bid.createdAt).toLocaleDateString(
                  undefined,
                  dateOptions
                )}
                provider={auth.user._id === bid.provider_id._id}
                bidBudget={bid.claimed_budget}
                jobBudget={1000} //static rakhsi
                comment={bid.comment}
                status={bid.state}
                acceptedbyclient={bid.is_accepted_by_client}
                acceptedbyprovider={bid.is_accepted_by_provider}
                onSuccess={onSuccess}
              >
                <JobCard
                  key={bid.job_id}
                  id={bid.job_id._id}
                  title={bid.job_id.title}
                  category={bid.job_id.parent_category}
                  subCategory={bid.job_id.category}
                  budget={bid.job_id.max_budget}
                  // subCategory={bid.subCategory}
                  postDate={new Date(bid.job_id.createdAt).toLocaleDateString(
                    undefined,
                    dateOptions
                  )}
                  description={bid.job_id.description}
                  //  budget={bid.budget}
                />
              </BidCard>
            );
          })}
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default AppliedBids;
