import React, { useContext } from 'react';
import './ProfileInfo.css';
import { AuthContext } from '../../context/auth-context';
import PersonalInfo from '../../components/card/PersonalInfo';
import AccOverview from '../../components/card/AccOverview';
import Rating from '../../components/card/Rating';
import AccBackground from '../../components/card/AccBackground';
import Texts from '../../util/Texts';

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const ProfileInfo = () => {
  const auth = useContext(AuthContext);
  const texts = Texts();

  return (
    <React.Fragment>
      <div className='profileInfo--container'>
        {auth.user && (
          <div>
            <h1 className='user--name'>{auth.user.fullname}</h1>

            <h2>{texts.personalInfo}</h2>
            <PersonalInfo
              name={auth.user.fullname}
              email={auth.user.email}
              birthDate={
                auth.user.date_of_birth
                  ? new Date(auth.user.date_of_birth).toLocaleDateString(
                      undefined,
                      dateOptions
                    )
                  : 'Not Set Yet'
              }
              nid={auth.user.nid ? auth.user.nid : 'Not Set Yet'}
              mobileBank={
                auth.user.mobile_banking_phone_number
                  ? `${auth.user.mobile_banking_phone_number} (${auth.user.mobile_banking_provider})`
                  : 'Not Set Yet'
              }
              image={`${auth.user.profile_pic}`}
            />
            <h2 className='overview--h2'>{texts.background}</h2>
            <AccBackground
              expertise={auth.user.expertise}
              achievement={auth.user.achievement}
              school={auth.user.degree}
            />
            <h2 className='overview--h2'>{texts.overview}</h2>
            <AccOverview
              bidsPlaced={auth.user.total_bid}
              dealsCompleted={auth.user.total_deal}
              jobsPosted={auth.user.total_post}
              fine={auth.user.fine}
              created={new Date(auth.user.timestamp).toLocaleDateString(
                undefined,
                dateOptions
              )}
            />
            {/*<h2 className="overview--h2">{texts.badges}</h2>
            <div className="badge--container">
              {dummyProfile.badges.map((profile, index) => (
                <Badge key={index} />
              ))}
            </div>*/}
            <h2 className='overview--h2'>{texts.stats}</h2>
            <div className='stats--container'>
              <div className='stats--subcontainer'>
                <Rating
                  text='Provider Rating'
                  rating={auth.user.provider_rating.toString().substr(0, 4)}
                  description={
                    'This Rating is a parameter to represent you as a provider. This rating increases as your past clients rate you.'
                  }
                />
                <Rating
                  text='Client Rating'
                  rating={auth.user.client_rating.toString().substr(0, 4)}
                  description={
                    'This Rating is a parameter to represent you as a client. This rating increases as your past providers rate you.'
                  }
                />
              </div>
              <div className='stats--subcontainer'>
                <Rating
                  text='Provider Credit'
                  rating={auth.user.provider_credit}
                  description={'please provider some info what to put here.'}
                />
                <Rating
                  text='Client Credit'
                  rating={auth.user.client_credit}
                  description={'please provider some info what to put here.'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProfileInfo;
