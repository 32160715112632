import React, { useEffect } from 'react';
import ExploreItems from '../sections/Explore/ExploreItems';
import Banner from '../components/banner/Banner';
import WorkProvider from '../uicomponents/userPanel/workProvider/WorkProvider';
import WorkProviderNew from '../uicomponents/userPanel/workProvider/WorkProviderNew';

const Providers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <WorkProviderNew />
    </div>
  );
};

export default Providers;
