import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth-context";

function OverviewCard({
  placed_bids,
  created_provider_deal,
  posted_jobs,
  account_creation_date,
}) {
  const auth = useContext(AuthContext);

  const overviewData = [
    {
      label:
        auth.language === "en" ? "Bids Placed" : "দরপত্র স্থাপন করা হয়েছে",
      value: placed_bids,
    },
    {
      label:
        auth.language === "en"
          ? "Deals Created as a Provider"
          : "ফ্রিল্যান্সার হিসাবে তৈরি চুক্তি",
      value: created_provider_deal,
    },
    {
      label: auth.language === "en" ? "Jobs Posted" : "কাজ পোস্ট করা হয়েছে",
      value: posted_jobs,
    },
    {
      label:
        auth.language === "en"
          ? "Account Created on"
          : "অ্যাকাউন্ট তৈরি করা হয়েছে",
      value: account_creation_date,
    },
  ];

  const keyStyle = {
    color: "var(--grey_two, #666)",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    margin: "0px",
  };

  const valueStyle = {
    color: "var(--grey_one, #333)",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  return (
    <div
      style={{
        padding: "20px",
        marginTop: "20px",
        marginBottom: "40px",
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.10)",
        borderRadius: "4px",
      }}
    >
      {overviewData.map(
        (item, index) =>
          item.value && (
            <div key={index} style={{ flex: "1", marginBottom: "20px" }}>
              <div style={{ marginBottom: "10px" }}>
                <span style={keyStyle}>{item.label}</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h5 style={valueStyle}>{item.value}</h5>
              </div>
            </div>
          )
      )}
    </div>
  );
}

export default OverviewCard;
