import React, { useState, useContext } from "react";
import "./EditExpertise.css";
import Modal from "../../util/Modal";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import LoadingSpinner from "../../util/LoadingSpinner";
import ModalButton from "../../button/ModalButton";

const AddDegree = (props) => {
  const auth = useContext(AuthContext);
  const [name, setName] = useState("");
  const [degree, setDegree] = useState("");
  const [major, setMajor] = useState("");
  const [year, setYear] = useState("");
  const [err, setErr] = useState(null);

  const { isLoading, sendRequest } = useHttpClient();
  const addDegree = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/add_degree",
        "PUT",
        JSON.stringify({
          school: name,
          degree: degree + "," + major,
          passing_year: year,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      //maybe check for response data
      if (responseData.success === "yes") {
        auth.setUser();
        setName("");
        setDegree("");
        setMajor("");
        setYear("");
        props.onCancel();
      }
    } catch (err) {
      setErr(err.message);
    }
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="edit_exp_container big_one">
        <img
          className="exit_edit"
          onClick={props.onCancel}
          src="/assets/cross.svg"
          alt=""
        />
        <h2>Add new degree</h2>
        <div className="ach__inpg">
          <p>Institution Name</p>
          <input
            className="inp_com"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Use official name of your institution. School / College / University / Academy"
          />
          <p>Degree Name</p>
          <input
            className="inp_com"
            type="text"
            value={degree}
            onChange={(e) => {
              setDegree(e.target.value);
            }}
            placeholder="e.g. B.Sc, B.A, B.Tech, SSC, HSC etc."
          />
          <p>Major / Field of Study</p>
          <input
            className="inp_com"
            type="text"
            value={major}
            onChange={(e) => {
              setMajor(e.target.value);
            }}
            placeholder="e.g. Computer Science, Psychology, finance etc."
          />
          <p>Passing Year</p>
          <input
            className="inp_com"
            type="text"
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
            }}
            placeholder="Students can add future year"
          />
        </div>
        {err && <p className="err_text">{err}</p>}
        <div className="modalbtn__container__degree">
          <ModalButton onClick={addDegree}>save</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddDegree;
