import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import BiddingCard from "./BiddingCard";
import ClientCard from "./ClientCard";
import Modal2 from "react-bootstrap/Modal";

import { Button as Button2 } from "react-bootstrap";
import "./JobDetails.css";
import { useHttpClient } from "../../../hooks/http-hook";
import LoadingSpinner from "../../../components/util/LoadingSpinner";
import ErrorModal from "../../../components/util/ErrorModal";
import { NavLink, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import Button from "../../../components/shared/Button/Button";
import { useHistory } from "react-router-dom";
import isImage from "../../util/isImage";
import { useMutation, useQuery } from "react-query";
import Overlay from "react-bootstrap/Overlay";
import {
  CLIENT_ACCEPT_BID,
  GET_BIDS,
  PROVIDER_ACCEPT_BID,
} from "../../../lib/api/admin";
import { deleteConfirmation } from "./../../../lib/sweetAlert";
import { toast } from "react-toastify";
import Modal from "../../../components/shared/Modal/Modal";
import SideBar from "../../../../src/components/SideBar";
import { Link, useLocation } from "react-router-dom";
import {
  DELETE_DELIVERY_PROVIDER,
  DELETE_PAYMENT_PROVIDER,
  GET_DELIVERY_PROVIDERS,
  GET_GLOBAL_SETTINGS,
  GET_PAYMENT_PROVIDERS,
  UPDATE_GLOBAL_SETTINGS,
} from "../../../lib/api/admin";
import axios from "axios";
import { text } from "@fortawesome/fontawesome-svg-core";

let prompt;

const JobDetailsNew = (props) => {
  const { jobID } = useParams();
  const [open, setOpen] = useState(false);
  const target = useRef(null);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedBid, setSelectedBid] = useState({});
  const [loading, setLoading] = useState(false);

  const { isLoading, error, clearError, sendRequest } = useHttpClient();
  const [bidId, setBidId] = useState(null);
  const [printBidId, setPrintBidId] = useState(null);
  const [payment_method, setPayment_method] = useState("BKASH");
  const [globalsVar, setGlobalsVar] = useState();
  const { data: bidsData, refetch: refetchBids } = useQuery(
    [
      GET_BIDS.name,
      {
        job_id: jobID,
      },
    ],
    GET_BIDS,
    {
      enabled: !!jobID,
    }
  );
  const { mutateAsync: acceptBidMutation } = useMutation(PROVIDER_ACCEPT_BID);
  const { mutateAsync: acceptBidMutation2 } = useMutation(CLIENT_ACCEPT_BID);
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loadedJob, setLoadedJob] = useState();
  const [refresh, setRefresh] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const onSuccess = () => {
    setRefresh(!refresh);
  };
  const { data: globalSettings, status } = useQuery(
    "GET_GLOBAL_SETTINGS",
    GET_GLOBAL_SETTINGS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [previousClaimedBudget, setPreviousClaimedBudget] = React.useState(0);
  const [previousComment, setPreviousComment] = React.useState("");

  useEffect(() => {
    setPreviousClaimedBudget(loadedJob?.my_bid?.claimed_budget);
    setPreviousComment(loadedJob?.my_bid?.comment);
  }, [loadedJob?.my_bid]);

  const notify = (type, msg) => {
    type === "err"
      ? toast.error(`${msg}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(`${msg}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  };

  const updateBid = async () => {
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/provider/update_bid",
        {
          bid_id: loadedJob?.my_bid?._id,
          claimed_budget: previousClaimedBudget,
          comment: previousComment,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );

      if (responseData.status === 200) {
        notify("success", "Bid updated!");
        // refetch();
        handleClose();
      } else throw new Error(responseData);
    } catch (err) {
      notify("err", err?.response?.data?.msg || "Something Went Wrong!");
    }
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${
            props.deleted ? "/admin/get_deleted_job/" : "/job"
          }?job_id=${jobID}`
        );
        console.log("job", responseData);
        setLoadedJob(responseData);
        setStat(responseData?.state);
      } catch (err) {}
    };
    fetchJob();
  }, [refresh]);

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const globals = async () => {
    console.log("globals called");
    try {
      const res = await axios.get("https://somadha.com/api/user/get_globals", {
        headers: {
          "x-access-token": localStorage.getItem("auth_token"),
        },
      });

      if (res.status === 200) {
        setGlobalsVar(res?.data);
        setPayment_method(res?.data?.payment_method);
      }
      prompt = res?.data?.bid_accept_prompt;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    globals();
  }, []);

  const handleBkash = async (bidId) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://somadha.com/api/create_payment",
        {
          method: "62a89430e0a31793bea91ba3",
          job_id: jobID,
          type: "INITIATE",
          bid_id: bidId,
          print_bid_id: printBidId,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        const url = res?.data?.bkashURL;
        window.location.replace(url);
      }
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const upvote = async () => {
    if (!loadedJob?.upvote.includes(auth.user._id)) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/upvote?job_id=${loadedJob?._id}`,
          "PUT",
          JSON.stringify(auth?.user),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.success === "yes") {
          onSuccess();
        }
      } catch (err) {}
    }
  };

  const downvote = async () => {
    if (!loadedJob?.downvote.includes(auth.user._id)) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/user/downvote/?job_id=${loadedJob?._id}`,
          "PUT",
          JSON.stringify({}),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.success === "yes") {
          onSuccess();
        }
      } catch (err) {}
    }
  };

  const [stat, setStat] = React.useState("");
  const acceptBid = async (bid_id, role) => {
    console.log("bid_id", bid_id);
    try {
      if (loadedJob?.my_bid) {
        await acceptBidMutation({
          bid_id,
        });
      } else {
        await acceptBidMutation2({
          bid_id,
        });
      }
      toast.success("Bidder accepted successfully");
      setOpen(false);
      window.location.reload();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const textStyle = {
    color: "var(--grey_one, #333)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={setOpen} title="Accept Bid">
        <div className="makePaymentForDueAmountModal">
          <div className="confirmPayment__form">
            {globalSettings?.data?.bid_accept_prompt && (
              <div>
                {JSON.parse(globalSettings.data.bid_accept_prompt).blocks.map(
                  (block, index) => (
                    <div
                      key={index}
                      className="formatted-content"
                      dangerouslySetInnerHTML={{ __html: block.data.text }}
                    />
                  )
                )}
              </div>
            )}

            {/*<p>
              {delivery && (
                <p>
                  "Please be reminded that the job requires you to send hard
                  copies via postage. If you don’t post them in a timely manner,
                  there will be a penalty." <br /> <br />
                </p>
              )}

              <p>
                Please complete your{" "}
                <strong className="text-primary">
                  {watch("payment_method")}
                </strong>{" "}
                job information to submit your work
              </p>
              </p>*/}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="primary"
                type="submit"
                style={{ padding: ".5rem 3rem", margin: "1rem auto" }}
                onClick={() => acceptBid(selectedBid?.id)}
              >
                Accept
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ display: "flex", overflowX: "hidden" }}>
        <ErrorModal error={error} onClear={clearError} />
        <div style={{ LeftMargin: "30rem", flex: "1" }}>
          {isLoading && <LoadingSpinner asOverlay />}
          {!isLoading && loadedJob && (
            <div style={{ margin: "auto" }}>
              <div className="d-flex flex-row  flex-wrap my-2">
                {/* <div className="job_sidebar">
                  <SideBar />
                </div> */}
                <div style={{ flex: "1" }}>
                  <div className="job_details_banner">
                    <div
                      style={{
                        width: "80vw",
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                        gap: "16px",
                      }}
                    >
                      <p
                        style={{
                          ...textStyle,
                          fontSize: "32px",
                        }}
                      >
                        {loadedJob?.title}
                      </p>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            width="20px"
                            height="20px"
                            src="/assets/designing.png"
                            alt=""
                          />
                          <p style={{ ...textStyle, marginRight: "16px" }}>
                            {loadedJob?.parent_category} {loadedJob?.category}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <img
                            width="20px"
                            height="20px"
                            src="/assets/clock.png"
                            alt=""
                          />
                          <p style={{ ...textStyle, marginRight: "16px" }}>
                            {new Date(loadedJob?.createdAt).toLocaleDateString(
                              undefined,
                              dateOptions
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="job_details_content"
                    style={{
                      width: "80vw",
                      margin: "auto",
                      display: "flex",
                      padding: "40px 0px",
                    }}
                  >
                    <div className="" style={{ flex: "1" }}>
                      {loadedJob?.closed_by_admin && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "12px",
                            padding: "8px 12px",
                            backgroundColor: "#FFEBEB",
                            marginBottom: "20px",
                            borderRadius: "4px",
                          }}
                        >
                          <img src="/assets/info.svg" alt="" />
                          <p
                            style={{
                              ...textStyle,
                              fontSize: "14px",
                              fontWeight: 600,
                              color: "#CC0000",
                            }}
                          >
                            Job has been cancelled by admin!
                          </p>
                        </div>
                      )}
                      <div>
                        <p
                          style={{
                            ...textStyle,
                            fontSize: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          {auth.language === "en"
                            ? "Job Post Descriptions"
                            : "কাজের পোস্টের বিবরণ"}
                        </p>

                        <p
                          style={{
                            ...textStyle,
                            color: "var(--grey_two, #666)",
                            textAlign: "justify",
                            fontSize: "16px",
                            marginBottom: "40px",
                          }}
                        >
                          {loadedJob?.description || "No description available"}
                        </p>
                        {loadedJob?.delivery && (
                          <div style={{ marginTop: "50px" }}>
                            <h3 className="mb-4" style={{ fontWeight: "bold" }}>
                              Delivery Details
                            </h3>
                            <p>
                              Mailing Address:{" "}
                              <strong>{loadedJob?.mailing_address}</strong>
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="my-5">
                        <p
                          style={{
                            ...textStyle,
                            fontSize: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          {auth.language === "en" ? "Attachments" : "সংযুক্তি"}
                        </p>
                        {loadedJob?.attachments &&
                        loadedJob?.attachments.length === 0 ? (
                          <p className="">
                            {" "}
                            {auth.language === "en"
                              ? "No attachments available"
                              : "কোন সংযুক্তি নাই"}
                          </p>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                            }}
                          >
                            {loadedJob?.attachments.map((dt, idx) => (
                              <a
                                href={dt}
                                target="_blank"
                                style={{
                                  backgroundColor: "var(--grey_six)",
                                  padding: ".75rem 2rem",
                                  borderRadius: "4px",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  style={{
                                    width: "50px",
                                    objectFit: "cover",
                                    marginRight: "1rem",
                                  }}
                                  src={
                                    isImage(dt)
                                      ? dt
                                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                                  }
                                  alt=""
                                />
                                {auth.language === "en"
                                  ? "view attachment"
                                  : "সংযুক্তি দেখুন"}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                      {/*
                      {!loadedJob?.deal_id?.[0]?._id &&
                        !!bidsData?.data?.filter(
                          (bid) =>
                            bid.is_accepted_by_client &&
                            bid.is_accepted_by_provider
                        )?.length && (
                          <div className="my-5">
                            <h3>
                              {" "}
                              {auth.language === "en"
                                ? "Confirmed Bidders"
                                : "নিশ্চিত দরদাতা"}
                            </h3>

                            <div
                              className="w-100 mt-2"
                              style={{ border: "1px solid lightgrey" }}
                            >
                              <div
                                className="d-flex"
                                style={{ borderBottom: "1px solid lightgrey" }}
                              >
                                <span
                                  className="flex-grow-1 p-2"
                                  style={{
                                    borderRight: "1px solid lightgrey",
                                    flex: 1,
                                  }}
                                >
                                  {auth.language === "en"
                                    ? "Bids for Services"
                                    : "পরিষেবার জন্য দরপত্র "}
                                </span>
                              </div>
                              <div className="d-flex">
                                <div
                                  className="flex-grow-1 p-2"
                                  style={{
                                    borderRight: "1px solid lightgrey",
                                    flex: 1,
                                  }}
                                >
                                  <div>
                                    {bidsData?.data
                                      ?.filter(
                                        (bid) =>
                                          bid.bid_type === "NORMAL" &&
                                          bid.is_accepted_by_client &&
                                          bid.is_accepted_by_provider
                                      )
                                      ?.map((bid) => (
                                        <div class="form-check" key={bid._id}>
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="bid_id"
                                            value={bid?._id}
                                            id={"bid_id" + bid._id}
                                            onChange={({ target }) =>
                                              setBidId(target.value)
                                            }
                                          />
                                          <label
                                            class="form-check-label"
                                            for={"bid_id" + bid._id}
                                          >
                                            <h3 style={{ fontSize: "16px" }}>
                                              {bid?.provider_id?.fullname}
                                            </h3>
                                            <p
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "normal",
                                                marginBottom: 0,
                                              }}
                                            >
                                              <span
                                                style={{ color: "darkgrey" }}
                                              >
                                                Budget:
                                              </span>{" "}
                                              {bid?.claimed_budget} BDT
                                            </p>
                                            <p
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "normal",
                                              }}
                                            >
                                              <span
                                                style={{ color: "darkgrey" }}
                                              >
                                                Comment:
                                              </span>{" "}
                                              {bid?.comment}
                                            </p>
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {bidId &&
                        stat === "BIDDING" &&
                        payment_method === "MANUAL" && (
                          <PayForDealModal
                            job={loadedJob}
                            bidId={bidId}
                            printBidId={printBidId}
                            // refetch={refetchBids}
                          >
                            <button className="btn prime-btn" type="button">
                              {auth.language === "en"
                                ? "Initiate Deal"
                                : "চুক্তি শুরু করুন"}
                            </button>
                          </PayForDealModal>
                        )}
                      {bidId &&
                        stat === "BIDDING" &&
                        payment_method === "BKASH" && (
                          <button
                            className="btn prime-btn"
                            style={{ backgroundColor: "pink" }}
                            type="button"
                            onClick={handleBkash}
                          >
                            {auth.language === "en" && loading === false
                              ? "Bkash Payment"
                              : null}
                            {auth.language !== "en" && loading === false
                              ? "বিকাশ পেমেন্ট"
                              : null}
                            {auth.language === "en" && loading === true
                              ? "loading..."
                              : null}
                            {auth.language !== "en" && loading === true
                              ? "লোডিং"
                              : null}
                          </button>
                        )}{" "}
                      */}
                      {loadedJob?.deal_id?.[0]?._id && (
                        <Link
                          to={`/servicedeal-detail/${loadedJob?.deal_id?.[0]?._id}`}
                        >
                          <Button2 variant="primary">View Deal</Button2>
                        </Link>
                      )}
                    </div>

                    <div
                      className="job_rightpanel"
                      style={{ padding: "0rem", maxWidth: "100%" }}
                    >
                      <NavLink to={`/profile/${loadedJob?.client_id?._id}`}>
                        <div className="jobdetails_margin">
                          <p style={{ ...textStyle, fontSize: "20px" }}>
                            {auth.language === "en"
                              ? "Client Details"
                              : "ক্লায়েন্ট বিবরণ"}
                          </p>
                          <ClientCard
                            clientId={loadedJob?.client_id?._id}
                            clientName={loadedJob?.client_id?.fullname}
                            clientProfilePic={loadedJob?.client_id?.profile_pic}
                            clientRating={loadedJob?.client_id?.client_rating}
                          />
                        </div>
                      </NavLink>

                      {selectedBid && !loadedJob?.my_job && (
                        <div className="jobdetails_margin">
                          <p style={{ ...textStyle, fontSize: "20px" }}>
                            {auth.language === "en" ? "Bidding" : "বিডিং"}
                          </p>

                          <BiddingCard
                            serviceDeadline=""
                            printDeadline=""
                            budget={loadedJob?.max_budget}
                            loadedJob={loadedJob}
                            setOpen={setOpen}
                            setSelectedBid={setSelectedBid}
                            auth={auth}
                            handleShow={handleShow}
                          />
                        </div>
                      )}

                      {!!bidsData?.data?.length && (
                        <div style={{ marginTop: "50px" }}>
                          <h4 style={{ fontWeight: "bold" }}>
                            {auth.language === "en" ? "Bidders" : "দরদাতা"}
                          </h4>

                          <div
                            className="d-flex flex-column p-4 gap-4"
                            style={{
                              marginTop: "20px",
                              marginBottom: "40px",
                              boxShadow: "0px 0px 12px rgb(0 0 0 / 10%)",
                            }}
                          >
                            {bidsData?.data
                              ?.filter((bid) => bid.bid_type === "NORMAL")
                              ?.map((bid) => (
                                <div
                                  key={bid._id}
                                  className="d-flex flex-column gap-4"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="row mb-2">
                                    <div className="col-12">
                                      <h3>{bid.provider_id.fullname}</h3>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {" "}
                                        {auth.language === "en"
                                          ? "Claimed Budget"
                                          : "দাবীকৃত বাজেট"}
                                      </p>
                                    </div>
                                    <div className="col-3">
                                      <p className="mb-0">
                                        ৳{bid?.claimed_budget}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {" "}
                                        {auth.language === "en"
                                          ? "System Charge"
                                          : "সিস্টেম চার্জ"}
                                      </p>
                                    </div>
                                    <div className="col-3">
                                      <p className="mb-0">
                                        ৳
                                        {
                                          bid?.payable_amount_client
                                            ?.charge_for_client
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {" "}
                                        {auth.language === "en"
                                          ? "Deductible Credit Amount"
                                          : "বিয়োগযোগ্য ক্রেডিট চার্জ"}
                                        <Button
                                          ref={target}
                                          onClick={() => {
                                            console.log(!showTooltip);
                                            setShowTooltip(!showTooltip);
                                          }}
                                          style={{
                                            borderRadius: "80%",
                                            padding: "3px 8px",
                                            marginLeft: "5px",
                                            backgroundColor: "gray",
                                            fontSize: "15px",
                                            color: "white",
                                            transform: "scale(.75)",
                                          }}
                                        >
                                          ?
                                        </Button>
                                      </p>

                                      <Overlay
                                        target={target.current}
                                        show={showTooltip}
                                        placement="top"
                                      >
                                        {({
                                          placement: _placement,
                                          arrowProps: _arrowProps,
                                          show: _show,
                                          popper: _popper,
                                          hasDoneInitialMeasure:
                                            _hasDoneInitialMeasure,
                                          ...props
                                        }) => (
                                          <div
                                            {...props}
                                            style={{
                                              position: "absolute",
                                              backgroundColor: "#7408E7",
                                              padding: "2px 10px",
                                              color: "white",
                                              borderRadius: 3,
                                              ...props.style,
                                            }}
                                          >
                                            {auth.language === "en"
                                              ? "This credit will be deducted as system charge"
                                              : "এই ক্রেডিট পয়েন্ট সিস্টেম চার্জ হিসাবে কাটা হবে"}
                                          </div>
                                        )}
                                      </Overlay>
                                    </div>
                                    <div className="col-3">
                                      <p className="mb-0">
                                        ৳
                                        {
                                          bid?.payable_amount_client
                                            ?.deductible_amount_credit
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9">
                                      <p className="mb-0">
                                        {auth.language === "en"
                                          ? "Total Payable"
                                          : "পরিশোধযোগ্য পরিমাণ"}
                                      </p>
                                    </div>
                                    <div className="col-3">
                                      <p className="mb-0">
                                        ৳
                                        {
                                          bid?.payable_amount_client
                                            ?.total_payable_amount_client
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div>
                                    {!bid.is_accepted_by_client && (
                                      <button
                                        className="btn prime-btn"
                                        type="button"
                                        onClick={() => {
                                          setOpen(true);
                                          setSelectedBid({
                                            id: bid?._id,
                                          });
                                        }}
                                      >
                                        {auth.language === "en"
                                          ? "Accept Bid"
                                          : "দরপত্র গ্রহণ করুন"}
                                      </button>
                                    )}

                                    {bid.is_accepted_by_client &&
                                      stat === "BIDDING" &&
                                      payment_method === "BKASH" && (
                                        <button
                                          className="btn prime-btn"
                                          style={{ backgroundColor: "pink" }}
                                          disabled={
                                            loading && bid._id === bidId
                                          }
                                          type="button"
                                          onClick={() => {
                                            handleBkash(bid._id);
                                            setBidId(bid._id);
                                          }}
                                        >
                                          {auth.language === "en"
                                            ? "Initiate Deal"
                                            : null}
                                          {auth.language !== "en"
                                            ? "চুক্তি শুরু করুন"
                                            : null}
                                        </button>
                                      )}
                                  </div>
                                </div>
                              ))}
                          </div>

                          {!loadedJob.closed_by_admin && loadedJob.my_bid && (
                            <button
                              type="button"
                              style={{
                                width: "100%",
                              }}
                              className="fw-bold mt-2  text-center prime-btn btn"
                              onClick={handleShow}
                              disabled={
                                loadedJob?.my_bid?.state === "SUCCESSFUL"
                              }
                            >
                              {auth.language === "en"
                                ? "Update Bid"
                                : "দরপত্র হালনাগাদ করুন"}
                            </button>
                          )}

                          {loadedJob?.my_bid?.is_accepted_by_client && (
                            <button
                              type="button"
                              style={{
                                width: "100%",
                              }}
                              onClick={() => {
                                setOpen(true);
                                setSelectedBid({
                                  id: loadedJob?.my_bid?._id,
                                });
                              }}
                              disabled={loadedJob?.is_accepted_by_provider}
                              className="fw-bold text-center prime-btn btn mt-2"
                            >
                              {auth.language === "en"
                                ? "Accept Bid"
                                : "দরপত্র গ্রহণ করুন"}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal2 show={show} onHide={handleClose}>
        <Modal2.Header closeButton>
          <Modal2.Title>Update Bid</Modal2.Title>
        </Modal2.Header>
        <Modal2.Body>
          <label for="">Claimed Budget</label>
          <input
            type="number"
            value={previousClaimedBudget}
            onChange={(e) => setPreviousClaimedBudget(e.target.value)}
          />
          <label for="">Comment</label>
          <input
            type="text"
            value={previousComment}
            onChange={(e) => setPreviousComment(e.target.value)}
          />
        </Modal2.Body>
        <Modal2.Footer>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="button"
              style={{
                width: "100%",
              }}
              onClick={handleClose}
              className="fw-bold text-center prime-btn btn me-2"
            >
              Close
            </button>
            <button
              type="button"
              style={{
                width: "100%",
              }}
              onClick={() => updateBid()}
              className="fw-bold text-center prime-btn btn "
            >
              Update
            </button>
          </div>
        </Modal2.Footer>
      </Modal2>
    </React.Fragment>
  );
};

export default JobDetailsNew;
