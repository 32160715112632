import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth-context";
import OverviewCard from "./OverviewCard";
import StatCard from "./StatCard";
import "./Profile.css";
import PersonalCard from "./PersonalCard";
import { useMutation } from "react-query";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  CHANGE_PROFILE_PICTURE,
  GENERATE_REFERRAL_CODE,
} from "../../../lib/api/admin";
import isImage from "../../util/isImage";
import { toast } from "react-toastify";
import axios from "axios";
import AgentModal from "../../../components/AgentModal";

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const ProfileSelf = () => {
  const auth = useContext(AuthContext);
  const [userTag, setUserTag] = useState();
  const [agentTag, setAgentTag] = useState();
  const [showAgentModal, setShowAgentModal] = useState(false);
  const { mutateAsync: changeProfilePicture } = useMutation(
    CHANGE_PROFILE_PICTURE
  );
  const { mutateAsync: generateReferralCode } = useMutation(
    GENERATE_REFERRAL_CODE
  );

  useEffect(() => {
    handleUser();
  }, []);

  const handleRefferalCodeGenerate = async () => {
    try {
      await generateReferralCode();
      await auth.refetch();
      toast.success("Successfully updated global Settings!");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const handleUser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/user",
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );

      if (!userTag) setUserTag(response.data.tag.includes("PROVIDER"));
      if(!agentTag) setAgentTag(response.data.agent)
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const handleOptInProvider = async () => {
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/user/enlisted_as_provider",
        {
          enlist: userTag ? false : true,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );
      toast.success("Successfully updated!");
      setUserTag(!userTag);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };
  const handleOptInAgent = async () => {
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/update_info",
        {
          agent: agentTag ? false : true,

        },
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }    
      );
      toast.success("Successfully updated!");
      setAgentTag(!agentTag);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  return (
    <div>
      {auth.user && (
        <div className="">
          <div id="topBanner">
            <div id="bannerContent">
              <div className="d-flex flex-row justify-content-start align-items-center">
                <label style={{ marginRight: "20px", display: "flex" }}>
                  <img
                    src={
                      auth.user?.profile_pic ||
                      "https://via.placeholder.com/150/92c952"
                    }
                    alt="test_image"
                  />
                </label>
                <div style={{ marginRight: "20px" }}>
                  <strong>{auth.user.fullname}</strong>
                  <p>{auth.user?.tagline || ""}</p>
                  {/* <button type='button' class='btn prime-btn'>
                    <i class='far fa-paper-plane'></i>{' '}
                    {auth.language === 'en' ? 'Send Message' : 'বার্তা পাঠান'}
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="profileBody" style={{ marginTop: "0px " }}>
            <div className="" style={{ marginLeft: "5%" }}>
              <div style={{ margin: "-1rem 0 1.5rem 0" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={userTag}
                      onClick={handleOptInProvider}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p style={{ fontSize: "1.23rem" }}>
                    {auth.language === "en"
                      ? " Enlist As Provider"
                      : "ফ্রীলান্সার হিসেবে যোগ দিন"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={agentTag}
                      onClick={handleOptInAgent}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p style={{ fontSize: "1.23rem" }}>
                    {auth.language === "en"
                      ? " Enlist As Agent"
                      : "এজেন্ট হিসেবে যোগ দিন"}
                  </p>
                </div>
                
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en" ? "About Me" : "আমার সম্পর্কে"}
                  </span>
                </h4>

                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  {auth.user?.about_me || "Not set yet"}
                </p>
              </div>

              <div style={{ margin: "1.5rem 0" }}>
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en" ? "Refferal Code" : "রেফারেল কোড"}
                  </span>

                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ zIndex: "10000" }}>
                        {auth.language === "en"
                          ? "You can share this code or link with your contacts through social networking or messaging apps to get special offers and benefits."
                          : "বিশেষ অফার এবং সুবিধা পেতে আপনি আপনার পরিচিতিদের সাথে সামাজিক যোগাযোগের মাধ্যমে  বা বার্তা আদান প্রদানের অ্যাপের মাধ্যমে কোড বা লিংকটি বিনিময় করতে পারেন৷"}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        fontSize: ".75rem",
                        backgroundColor: "rgba(0,0,0,.4)",
                        padding: ".1rem .35rem",
                        borderRadius: "50%",
                        color: "white",
                        marginLeft: ".5rem",
                        cursor: "pointer",
                      }}
                    >
                      ?
                    </span>
                  </OverlayTrigger>
                </h4>

                <p
                  id="my-element"
                  data-tooltip-content="hello world"
                  style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                    maxWidth: "30rem",
                  }}
                >
                  {auth.user?.referral_token ? (
                    <div>
                      <div>{auth.user?.referral_token}</div>
                      <div className="alert alert-secondary">
                        {window.location.origin}/sign-up?referral=
                        {auth.user?.referral_token}
                      </div>
                    </div>
                  ) : (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ zIndex: "10000" }}
                        >
                          {auth.language === "en"
                            ? "Click to generate a code or link that you can share with your contacts through social networking or messaging apps to get special offers and benefits."
                            : " বিশেষ অফার এবং সুবিধা পেতে বাটন চেপে একটি কোড বা লিংক তৈরি করুন যা আপনি আপনার পরিচিতিদের সাথে সামাজিক যোগাযোগের মাধ্যমে  বা বার্তা আদান প্রদানের অ্যাপের মাধ্যমে বিনিময় করতে পারেন৷"}
                        </Tooltip>
                      }
                    >
                      <button
                        className="btn fw-bold my-2"
                        style={{
                          backgroundColor: "var(--primary_color)",
                          width: "15rem",
                          color: "white",
                        }}
                        onClick={handleRefferalCodeGenerate}
                      >
                        {auth.language === "en"
                          ? "Generate Code"
                          : "কোড তৈরি করুন"}
                      </button>
                    </OverlayTrigger>
                  )}
                </p>
              </div>

              <div style={{ margin: "2.5rem 0" }}>
                <h4 style={{ margin: "1rem 0" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en" ? "Portfolio" : "পোর্টফোলিও"}
                  </span>
                </h4>
                {auth.user.portfolio.length > 0
                  ? auth.user.portfolio.map((dt, idx) => (
                      <a
                        href={dt}
                        target="_blank"
                        style={{
                          backgroundColor: "var(--grey_six)",
                          padding: ".75rem 2rem",
                          borderRadius: "4px",
                          display: "inline-block",
                          marginRight: "20px",
                        }}
                      >
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            marginRight: "1rem",
                          }}
                          src={
                            isImage(dt)
                              ? dt
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                          }
                          alt=""
                        />
                        {auth.language === "en"
                          ? "view attachment"
                          : "সংযুক্তি দেখুন"}
                      </a>
                      // <a
                      //   className='mx-1'
                      //   key={idx}
                      //   href={process.env.REACT_APP_BACKEND_URL + '/' + dt}
                      //   target='_blank'
                      //   style={{
                      //     backgroundColor: 'var(--grey_six)',
                      //     padding: '.75rem 2rem',
                      //     borderRadius: '4px',
                      //   }}
                      // >
                      //   {auth.language === 'en'
                      //     ? 'view attachment  '
                      //     : 'সংযুক্তি'}
                      // </a>
                    ))
                  : "No attachment"}
              </div>

              <div style={{ margin: "1.5rem 0" }}>
                <h4 style={{ margin: ".5rem 0" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en" ? "Skills" : "দক্ষতা"}
                  </span>
                </h4>

                <div className="d-flex flex-row flex-wrap mb-1">
                  {auth.user.expertise.length > 0 ? (
                    auth.user.expertise.map((item) => (
                      <div
                        className=" skill__items  m-1 "
                        style={{ backgroundColor: "var(--grey_six)" }}
                      >
                        <p>{item}</p>
                      </div>
                    ))
                  ) : (
                    <p>Not set yet</p>
                  )}
                </div>
              </div>

              <div style={{ marginBottom: "60px" }}>
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en" ? "Achievements" : "অর্জন"}
                  </span>
                </h4>

                <div className="d-flex flex-column">
                  {auth.user.achievement.length > 0 ? (
                    auth.user.achievement.map((item) => (
                      <div className="tiles">
                        <p>
                          <strong>{item.name}</strong>
                        </p>
                        <p>{item.description}</p>
                      </div>
                    ))
                  ) : (
                    <p>Not set yet</p>
                  )}
                </div>
              </div>

              <div style={{ marginBottom: "60px" }}>
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en" ? "Education" : "শিক্ষা"}
                  </span>
                </h4>

                <div className="d-flex flex-column">
                  {auth.user.degree.length > 0 ? (
                    auth.user.degree.map((item) => (
                      <div className="tiles">
                        <p>
                          <strong>{item?.school}</strong>
                        </p>
                        <p>
                          {item.degree}
                          {", Passing Year: "}
                          {item.passing_year}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>Not set yet</p>
                  )}
                </div>
              </div>

              <div style={{ marginTop: "50px" }}>
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en"
                      ? "User Overview"
                      : "ব্যবহারকারীর ওভারভিউ"}
                  </span>
                </h4>
                <OverviewCard
                  placed_bids={auth.user.total_bid}
                  created_provider_deal={auth.user?.total_deal}
                  posted_jobs={auth.user.total_post}
                  account_creation_date={new Date(
                    auth.user?.createdAt
                  ).toLocaleDateString(undefined, dateOptions)}
                />
              </div>
              <div>
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en"
                      ? "User Stats"
                      : "ব্যবহারকারীর পরিসংখ্যান"}
                  </span>
                </h4>
                <StatCard
                  c_rating={auth.user.client_rating.toString().substr(0, 4)}
                  p_rating={auth.user.provider_rating.toString().substr(0, 4)}
                  c_credit={auth.user.client_credit}
                  p_credit={auth.user.provider_credit}
                  s_ratio={
                    (auth.user?.total_deal / auth.user?.total_bid) * 100 || 0
                  }
                />
              </div>

              <div>
                <h4>
                  <span style={{ fontWeight: "bold" }}>
                    {auth.language === "en"
                      ? "Personal Info"
                      : "ব্যাক্তিগত তথ্য"}
                  </span>
                </h4>
                <PersonalCard
                  email={auth.user.email}
                  birthdate={
                    auth.user.date_of_birth
                      ? new Date(auth.user.date_of_birth).toLocaleDateString(
                          undefined,
                          dateOptions
                        )
                      : "Not Set Yet"
                  }
                  nid={auth.user.nid ? auth.user.nid : "Not Set Yet"}
                  mobilebank={
                    auth.user.mobile_banking_phone_number
                      ? `${auth.user.mobile_banking_phone_number} (${auth.user.mobile_banking_provider})`
                      : "Not Set Yet"
                  }
                  location={
                    auth?.user?.location ? auth?.user?.location : "Not Set Yet"
                  }
                  notificationDest={
                    auth?.user?.notification_destination || "Not Set Yet"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSelf;
