import moment from 'moment';
import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { MARK_ISSUE_AS_RESOLVED } from '../../../lib/api/admin';
import Button from '../../shared/Button/Button';
import Modal from "../../shared/Modal/Modal";
import "./IssueDetailsModal.css";

export default function IssueDetailsModal({ open, onClose, issueDetails }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutateAsync: markIssueAsResolvedMutation } = useMutation(MARK_ISSUE_AS_RESOLVED);

  const markIssueAsResolved = useCallback(async () => {
    try {
      await markIssueAsResolvedMutation({ issue_id: issueDetails?._id });
      await queryClient.refetchQueries("GET_ISSUES");
      toast.success("Successfully marked issue as resolved!");
      // eslint-disable-next-line no-unused-expressions
      onClose?.();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.msg || err?.message);
    }
  }, [issueDetails, markIssueAsResolvedMutation, onClose, queryClient]);

  return  (
    <Modal open={open} onClose={onClose} title="Issue Details">
      <label className="d-block">
        <strong>Issued By</strong>
        <p className="py-2 px-3">{issueDetails?.user_id?.fullname}</p>
      </label>
      <label className="d-block">
        <strong>Email</strong>
        <p className="py-2 px-3">{issueDetails?.user_id?.email}</p>
      </label>
      <label className="d-block">
        <strong>Phone Number</strong>
        <p className="py-2 px-3">{issueDetails?.user_id?.phone_number}</p>
      </label>
      <label className="d-block">
        <strong>Issue Description</strong>
        <p className="py-2 px-3">{issueDetails?.issue}</p>
      </label>

      <p>Posted on {moment(issueDetails?.issued_at).format("Do MMMM, YYYY. h:mmA")}</p>

      <div className="issueDetailsModal__btnGroup">
        <Button variant="primary" onClick={() => history.push("/user/send_message")}>Contact Issuer</Button>
        <Button variant="primary_outlined" onClick={markIssueAsResolved}>Mark as Resolved</Button>
      </div>
    </Modal>
  )
}