import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { GET_CATEGORIES, GET_SUB_CATEGORIES } from '../../../lib/api/admin';
import AdminCategoryCard from '../../cards/AdminCategoryCard/AdminCategoryCard';
import AddEditCategoryModal from '../../modals/AddEditCategoryModal/AddEditCategoryModal';
import Button from '../../shared/Button/Button';
import QComponent from '../../shared/QComponent/QComponent';
import './JobCategoriesView.css';

export default function JobCategoriesView() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const {
    data: categories,
    status,
    error,
  } = useQuery('GET_CATEGORIES', GET_CATEGORIES);
  const { data: subCategories } = useQuery(
    ['GET_SUB_CATEGORIES', { cat_name: selectedCategory?.name }],
    GET_SUB_CATEGORIES,
    {
      enabled: !!selectedCategory?.name,
    }
  );

  return (
		<QComponent status={status} error={error}>
			<div className="jobCategoriesView">
				<h3 style={{ fontWeight: "bold" }}>Job Categories</h3>

				<div className="jobCategoriesView__list">
					<div className="jobCategoriesView__categories">
						{categories?.data?.data?.map?.((cat) => (
							<AdminCategoryCard
								active={cat?._id === selectedCategory?._id}
								key={cat?._id}
								src={cat?.logo}
								category_name={cat?.name}
								onClick={() => setSelectedCategory(cat)}
								category={cat}
							/>
						))}

						<AddEditCategoryModal>
							<Button variant="primary" fluid>
								Add New Category
							</Button>
						</AddEditCategoryModal>
					</div>
					<div className="jobCategoriesView__subCategories">
						{subCategories?.data?.data?.map?.((cat) => (
							<AdminCategoryCard
								key={cat?._id}
								src={cat?.logo}
								category_name={cat?.name}
								category={cat}
								parent={selectedCategory}
							/>
						))}

						<AddEditCategoryModal subCat={selectedCategory?.name}>
							<Button disabled={!selectedCategory?._id} variant="primary" fluid>
								Add New Sub-Category
							</Button>
						</AddEditCategoryModal>
					</div>
				</div>
			</div>
		</QComponent>
	);
}
