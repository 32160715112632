import React, { useState, useEffect } from 'react';
import './ManageGlobals.css';
import { Button } from '../../components/button/Button';
import { useHttpClient } from '../../hooks/http-hook';
import ErrorModal from '../../components/util/ErrorModal';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import SuccessModal from '../../components/util/SuccessModal';
const ManageGlobals = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [form1, setForm1] = useState('');
  const [form2, setForm2] = useState('');
  const [form3, setForm3] = useState('');
  const [form4, setForm4] = useState('');
  const [form5, setForm5] = useState('');
  const [form6, setForm6] = useState('');
  const [form7, setForm7] = useState('');
  //const [form8, setForm8] = useState("");
  const [form9, setForm9] = useState('');
  const [form10, setForm10] = useState('');
  const [form11, setForm11] = useState('');
  const [form13, setForm13] = useState('');
  const [form12, setForm12] = useState('');
  const [form14, setForm14] = useState('');
  const [form15, setForm15] = useState('');
  const [form16, setForm16] = useState('');
  const [form17, setForm17] = useState('');

  const payDays = [
    'Saturday',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
  ];
  useEffect(() => {
    const fetchGlobals = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/admin/get_globals'
        );

        setForm1(responseData.initial_client_credit);
        setForm2(responseData.initial_provider_credit);
        setForm3(responseData.referral_credit);
        setForm4(responseData.advance_charge);
        setForm5(responseData.low_rating_limit);
        setForm6(responseData.client_low_rating_count_limit);
        setForm7(responseData.provider_low_rating_count_limit);
        // setForm8(responseData.approval_time_limit);
        setForm9(responseData.client_charge_per_deal);
        setForm10(responseData.provider_charge_per_deal);
        setForm11(responseData.low_rating_credit_fine);
        setForm12(responseData.pay_policy.system);
        setForm13(responseData.pay_policy.pay_day);
        setForm14(responseData.min_charge_print_client);
        setForm15(responseData.max_charge_print_client);
        setForm16(responseData.min_charge_print_provider);
        setForm17(responseData.max_charge_print_provider);
      } catch (err) {}
    };
    fetchGlobals();

    //eslint-disable-next-line
  }, []);

  const updateGlobals = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/update_globals',
        'PUT',
        JSON.stringify({
          // min_charge_print_client
          //  max_charge_print_client
          //   min_charge_print_provider
          //    max_charge_print_provider

          initial_client_credit: form1,
          initial_provider_credit: form2,
          referral_credit: form3,
          advance_charge: form4,
          client_low_rating_count_limit: form6,
          provider_low_rating_count_limit: form7,
          //approval_time_limit: form8,
          client_charge_per_deal: form9,
          provider_charge_per_deal: form10,
          low_rating_credit_fine: form11,
          min_charge_print_client: form14,
          max_charge_print_client: form15,
          min_charge_print_provider: form16,
          max_charge_print_provider: form17,
          low_rating_limit: form5,
          pay_policy: {
            system: form12,
            pay_day: form13,
          },
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        setShowSuccess(true);
      }
    } catch (err) {}
  };
  //weekly/ bi weekly/ monthly

  return (
    <div className='global__container'>
      <ErrorModal error={error} onClear={clearError} />
      <SuccessModal
        show={showSuccess}
        onClear={() => {
          setShowSuccess(false);
        }}
        message='Globals were updated successfully.'
      />
      {isLoading && <LoadingSpinner asOverlay />}
      <h3>Global Settings</h3>
      <div className='global__box'>
        <p>
          Manage all global settings here.
          <br />
          global settings here.
        </p>
        {
          <table>
            <tbody>
              <tr>
                <td className='td_left'>Initial Client Credit</td>
                <td className='td_right'>
                  <input
                    value={form1}
                    onChange={(e) => setForm1(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>
              <tr>
                <td className='td_left'>Initial Provider Credit</td>
                <td className='td_right'>
                  <input
                    value={form2}
                    onChange={(e) => setForm2(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>
              <tr>
                <td className='td_left'>Referral Credit</td>
                <td className='td_right'>
                  <input
                    value={form3}
                    onChange={(e) => setForm3(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>
              <tr>
                <td className='td_left'>Advance Charge</td>
                <td className='td_right'>
                  <input
                    value={form4}
                    onChange={(e) => setForm4(e.target.value)}
                  />{' '}
                  %
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Client Low Rating Count Limit</td>
                <td className='td_right'>
                  <input
                    value={form6}
                    onChange={(e) => setForm6(e.target.value)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td className='td_left'>Provider Low Rating Count Limit</td>
                <td className='td_right'>
                  <input
                    value={form7}
                    onChange={(e) => setForm7(e.target.value)}
                  />{' '}
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Low Rating Limit</td>
                <td className='td_right'>
                  <input
                    value={form5}
                    onChange={(e) => setForm5(e.target.value)}
                  />{' '}
                </td>
                <td></td>
              </tr>

              {/*<tr>
                <td className="td_left">Low Rating Limit</td>
                <td className="td_right">
                  <input
                    value={form5}
                    onChange={(e) => setForm5(e.target.value)}
                  />{" "}
                </td>
                <td></td>
              </tr>*/}

              <tr>
                <td className='td_left'>Client Charge Per Deal</td>
                <td className='td_right'>
                  <input
                    value={form9}
                    onChange={(e) => setForm9(e.target.value)}
                  />{' '}
                  %
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Provider Charge Per Deal</td>
                <td className='td_right'>
                  <input
                    value={form10}
                    onChange={(e) => setForm10(e.target.value)}
                  />{' '}
                  %
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Low Rating Credit Fine</td>
                <td className='td_right'>
                  <input
                    value={form11}
                    onChange={(e) => setForm11(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Minimum Printing Charge for Client</td>
                <td className='td_right'>
                  <input
                    value={form14}
                    onChange={(e) => setForm14(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Maximum Printing Charge for Client</td>
                <td className='td_right'>
                  <input
                    value={form15}
                    onChange={(e) => setForm15(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>
                  Minimum Printing Charge for Provider
                </td>
                <td className='td_right'>
                  <input
                    value={form16}
                    onChange={(e) => setForm16(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>
                  Maximum Printing Charge for Provider
                </td>
                <td className='td_right'>
                  <input
                    value={form17}
                    onChange={(e) => setForm17(e.target.value)}
                  />{' '}
                  BDT
                </td>
                <td></td>
              </tr>

              <tr>
                <td className='td_left'>Pay System</td>
                <td className='td_right'>
                  <select
                    value={form12}
                    onChange={(e) => setForm12(e.target.value)}
                  >
                    <option value='WEEKLY'>WEEKLY</option>
                    <option value='BI-WEEKLY'>BI-WEEKLY</option>
                    <option value='HOURLY'>MONTHLY</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className='td_left'>Pay Day</td>
                <td className='td_right'>
                  {form12 === 'WEEKLY' ? (
                    <select
                      value={form13}
                      onChange={(e) => setForm13(e.target.value)}
                    >
                      {payDays.map((day, index) => {
                        return <option value={index}>{day}</option>;
                      })}
                    </select>
                  ) : (
                    <input
                      value={form13}
                      onChange={(e) => setForm13(e.target.value)}
                    />
                  )}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        }
        <div className='btn_below'>
          <Button onClick={updateGlobals} buttonStyle='btn--banner'>
            save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageGlobals;
