import React, { useState } from 'react';
import './EditExpertise.css';
import Modal from '../../util/Modal';

import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import ModalButton from '../../button/ModalButton';

const ReportIssue = (props) => {
  const [issue, setIssue] = useState('');

  const { isLoading, sendRequest } = useHttpClient();
  const sendIssue = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/report_issue',
        'POST',
        JSON.stringify({
          issue: issue,
          // deal_id: props.deal_id,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      //maybe check for response data
      if (responseData.success === 'yes') {
        props.onCancel();
        props.onSuccess();
      }
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='edit_exp_container big_one'>
        <img
          className='exit_edit'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2>Report an Issue</h2>
        <div className='ach__inpg'>
          <p>Issue Description</p>
          <textarea
            className='issue__desc'
            type='text'
            value={issue}
            onChange={(e) => {
              setIssue(e.target.value);
            }}
            placeholder='Explain your issue in details.'
          />
        </div>
        <div className='report__issue__btn'>
          <ModalButton onClick={sendIssue}>Send Issue to Admins</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default ReportIssue;
