/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import "./Input.css";

const Input = ({ label, tooltip, as, ...rest }, ref) => {
  const [rangeValue, setRangeValue] = useState(0);

  useEffect(() => {
    if (rest.type === "range") {
      setRangeValue(rest.value);
    }
  }, [rest]);

  return rest.type === "checkbox" ? (
    <div className="py-2 form-group d-flex align-items-center justify-content-between">
      <strong>{label}</strong>
      <label>
        <input type="checkbox" id="switch" hidden ref={ref} {...rest} />
        <span className="switch" />
      </label>
    </div>
  ) : rest.type === "range" ? (
    <div className="form-group input">
      <ReactTooltip />
      <label className="d-block">
        <strong>
          {label}{" "}
          {tooltip && (
            <span data-tip={tooltip}>
              <FaQuestionCircle />
            </span>
          )}
        </strong>
        <div className="group">
          <input
            type="text"
            onChange={(e) => {
              setRangeValue(e.target.value);
              rest?.onChange?.(e);
            }}
            min="0"
            max="100"
            ref={ref}
            {...rest}
          />
          <input
            type="number"
            value={rangeValue}
            onChange={(e) => {
              setRangeValue(e.target.value);
              rest?.onChange?.(e);
            }}
          />
        </div>
      </label>
    </div>
  ) : as === "textarea" ? (
    <div className="form-group input">
      <ReactTooltip />
      <label className="d-block">
        <strong>
          {label}{" "}
          {tooltip && (
            <span data-tip={tooltip}>
              <FaQuestionCircle />
            </span>
          )}
        </strong>
        <textarea className="form-control" ref={ref} {...rest} />
      </label>
    </div>
  ) : rest.type === "number" ? (
    <div className="form-group input">
      <ReactTooltip />
      <label className="d-block">
        <strong>
          {label}{" "}
          {tooltip && (
            <span data-tip={tooltip}>
              <FaQuestionCircle />
            </span>
          )}
        </strong>
        <input type="number" className="form-control" ref={ref} {...rest} min={0}/>
      </label>
    </div>
  ) : (
    <div className="form-group input">
      <ReactTooltip />
      <label className="d-block">
        <strong>
          {label}{" "}
          {tooltip && (
            <span data-tip={tooltip}>
              <FaQuestionCircle />
            </span>
          )}
        </strong>
        <input type="text" className="form-control" ref={ref} {...rest} />
      </label>
    </div>
  );
};

export default React.forwardRef(Input);
