import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import ModalButton from '../../components/button/ModalButton';
import AddPromotion from '../../components/modals/admin/AddPromotion';
import Paginator from '../../components/util/Paginator';
const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};
const Promo = () => {
  const [promotions, setPromotions] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const { isLoading, sendRequest } = useHttpClient();
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const onSuccess = () => {
    setRefresh(!refresh);
  };
  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_promotions/?page=${page}`
        );
        setPromotions(responseData);
      } catch (err) {}
    };
    fetchPromotions();
    //eslint-disable-next-line
  }, [refresh, page]);

  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}
      <AddPromotion
        show={showAddModal}
        onSuccess={onSuccess}
        onCancel={() => {
          setShowAddModal(false);
        }}
      />
      <h3>Promotions</h3>
      <div className='manage__box blue'>
        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td>Target User</td>
              <td>Status</td>
              <td>Starting Time</td>
              <td>Ending Time</td>
              <td>Minumum Deal Price</td>
              <td>Reward Credit</td>
            </tr>
            {promotions &&
              promotions.map((promotion) => {
                return (
                  <tr className='t_bottom'>
                    <td>{promotion.target_user}</td>
                    <td>{promotion.state}</td>
                    <td>
                      {new Date(promotion.start_date).toLocaleString(
                        undefined,
                        timeOptions
                      )}{' '}
                    </td>
                    <td>
                      {new Date(promotion.end_date).toLocaleString(
                        undefined,
                        timeOptions
                      )}{' '}
                    </td>
                    <td>
                      {promotion.target_amount}
                      {' BDT'}
                    </td>
                    <td>
                      {promotion.offered_point}
                      {' BDT'}
                    </td>
                  </tr>
                );
              })}
          </table>
          <Paginator page={page} setPage={setPage} />
          <div className='promotionadder__btn'>
            <ModalButton
              onClick={() => {
                setShowAddModal(true);
              }}
            >
              Add Promotion
            </ModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
