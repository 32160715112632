import React, { useState, useEffect } from 'react';
import './AdminManagement.css';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import Paginator from '../../components/util/Paginator';
import { Link } from 'react-router-dom';

const timeOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const GetPayments = () => {
  const [dues, setDues] = useState(null);
  const { isLoading, sendRequest } = useHttpClient();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchDues = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/get_provider_transactions/?page=${page}`
        );
        setDues(responseData);
      } catch (err) {}
    };
    fetchDues();
    //eslint-disable-next-line
  }, [page]);

  return (
    <div className='manage__container '>
      {isLoading && <LoadingSpinner asOverlay />}

      <h3>Successful Payments</h3>
      <div className='manage__box blue'>
        <div className='admin__table'>
          <table>
            <tr className='t_head'>
              <td>Transaction Date</td>
              <td>Deal</td>
              <td>Pay Amount</td>
              <td>Transaction ID</td>
              <td>Transaction Issuer</td>
            </tr>
            {dues &&
              dues.map((due) => {
                return (
                  <tr className='t_bottom'>
                    <td>
                      {new Date(due.createdAt).toLocaleDateString(
                        undefined,
                        timeOptions
                      )}
                    </td>
                    <td>
                      <Link to={`deal/${due.deal_id}`}>
                        <u>view deal</u>
                      </Link>
                    </td>
                    <td>{due.total_amount}</td>
                    <td>{due.transaction_id}</td>
                    <td>{due.transaction_issuer}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <Paginator page={page} setPage={setPage} />
    </div>
  );
};

export default GetPayments;
