import React, { useContext, useState, useEffect } from 'react';
import './EditProfile.css';
import { AuthContext } from '../../context/auth-context';
import ImageUploader from '../../components/util/ImageUploader';
import Card from '../../components/util/Card';
import SkillItem from '../../components/items/SkillItem';
import EditExpertise from '../../components/modals/Profile/EditExpertise';
import AddAchiement from '../../components/modals/Profile/AddAchievement';
import { useHttpClient } from '../../hooks/http-hook';
import AddDegree from '../../components/modals/Profile/AddDegree';
import ModalButton from '../../components/button/ModalButton';
import ErrorModal from '../../components/util/ErrorModal';
import LoadingSpinner from '../../components/util/LoadingSpinner';
import SuccessModal from '../../components/util/SuccessModal';
const mobileBankProviders = ['BKASH', 'NAGAD', 'ROCKET'];

const EditProfile = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [nid, setNid] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [expertises, setExpertises] = useState(null);
  const [achievements, setAchievements] = useState(null);
  const [degrees, setDegrees] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [showExpertiseEditor, setShowExpertiseEditor] = useState(false);
  const [showAchievementEditor, setShowAchievementEditor] = useState(false);
  const [showAddDegree, setShowAddDegree] = useState(false);
  const [mobileBank, setMobileBank] = useState(mobileBankProviders[0]);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const onCancel = () => {
    setShowExpertiseEditor(false);
    setShowAchievementEditor(false);
    setShowAddDegree(false);
    setShowSuccess(false);
  };
  useEffect(() => {
    if (auth.user) {
      setName(auth.user.fullname);
      setDob(auth.user.date_of_birth);
      setProfilePic(auth.user.profile_pic);
      setExpertises(auth.user.expertise);
      setAchievements(auth.user.achievement);
      setDegrees(auth.user.degree);
      setNid(auth.user.nid);
      setMobile(auth.user.mobile_banking_phone_number);
      setMobileBank(auth.user.mobile_banking_provider);
    }
  }, [auth.user]);

  const updateUser = async () => {
    try {
      let dataToSend;
      newPassword.length > 0 && passwordValid
        ? (dataToSend = {
            fullname: name,
            date_of_birth: dob && dob.slice(0, 10),
            nid: nid && nid.toString(),
            new_password: newPassword,
            password: password,
          })
        : (dataToSend = {
            fullname: name,
            date_of_birth: dob && dob.slice(0, 10),
            nid: nid && nid.toString(),
            password: password,
          });
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/update_info',
        'POST',

        JSON.stringify(dataToSend),
        {
          'Content-Type': 'application/json',
        }
      );
      let response;
      if (
        mobile !== auth.user.mobile_banking_phone_number ||
        mobileBank !== auth.user.mobile_banking_provider
      ) {
        response = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            '/user/update_mobile_banking_info',
          'PUT',
          JSON.stringify({
            mobile_banking_phone_number: mobile,
            mobile_banking_provider: mobileBank
              ? mobileBank
              : mobileBankProviders[0],
            password: password,
          }),
          {
            'Content-Type': 'application/json',
          }
        );
      }

      if (responseData.success === 'yes' || response.success === 'yes') {
        auth.setUser();
        setPassword('');
        setNewPassword('');
        setConfirmPass('');
        setShowSuccess(true);
      }
    } catch (err) {}
  };

  const deleteAchievement = async (title, desc) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/delete_achievement',
        'PUT',
        JSON.stringify({
          name: title,
          description: desc,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      //maybe check for response data
      if (responseData.success === 'yes') {
        auth.setUser();
      }
    } catch (err) {}
  };

  const deleteDegree = async (school, degree, passing_year) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/delete_degree',
        'PUT',
        JSON.stringify({
          school: school,
          degree: degree,
          passing_year: passing_year,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      //maybe check for response data
      if (responseData.success === 'yes') {
        auth.setUser();
      }
    } catch (err) {}
  };

  return (
    <div className='editprofile__container'>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError} />
      <SuccessModal
        show={showSuccess}
        onClear={onCancel}
        message='Profile was updated successfully.'
      />
      <EditExpertise
        show={showExpertiseEditor}
        onCancel={onCancel}
        expertises={expertises}
      />
      <AddAchiement show={showAchievementEditor} onCancel={onCancel} />
      <AddDegree show={showAddDegree} onCancel={onCancel} />
      <h1>Edit Profile</h1>
      <hr />
      <div className='infostuffs__section'>
        <h2>Basic Information</h2>
        <div className='change__picture'>
          {profilePic && (
            <img
              className='propic'
              src={`${profilePic}`}
              alt=''
            />
          )}

          <div className='change__picright'>
            <h4>Change Profile Picture</h4>
            <p>formal picture required, must be less than 1MB</p>
            <ImageUploader
              profile
              fieldName='image'
              url='/user/change_profile_picture'
              title='Upload New Photo'
            />
          </div>
        </div>

        <p className='input_tag'>Full Name</p>
        <input
          autoComplete='off'
          type='text'
          value={name}
          className='info__input'
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <p className='input_tag'>Date of Birth </p>
        <input
          autoComplete='off'
          type='date'
          value={dob && dob.slice(0, 10)}
          onChange={(e) => {
            setDob(e.target.value);
          }}
          className='info__input'
        />
        <p className='input_tag'>NID/Birth Certificate No.</p>
        <input
          autoComplete='off'
          type='text'
          className='info__input'
          value={nid}
          onChange={(e) => setNid(e.target.value)}
          placeholder='Enter NID/Birth Certificate No.'
        />
        <p className='input_tag'>Mobile Banking Number</p>
        <div className='last_input'>
          <input
            autoComplete='off'
            type='text'
            className='info__input'
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder='Enter Mobile Number'
          />
          <select
            value={mobileBank}
            autoComplete='off'
            onChange={(e) => {
              setMobileBank(e.target.value);
            }}
          >
            {mobileBankProviders.map((provider) => {
              return <option value={provider}>{provider}</option>;
            })}
          </select>
        </div>
        <p className='input_tag'>New Password</p>
        <input
          autoComplete='off'
          type='password'
          className='info__input'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder='Choose a Strong Password'
        />
        <p className='input_tag'>Confirm New Password</p>
        <input
          autoComplete='off'
          type='password'
          className='info__input'
          value={confirmPass}
          onChange={(e) => {
            setConfirmPass(e.target.value);
            e.target.value === newPassword
              ? setPasswordValid(true)
              : setPasswordValid(false);
          }}
          placeholder='Retype New Password'
        />
        {!passwordValid && (
          <p className='error__text'>Password doesn't match</p>
        )}
        <p className='input_tag'>Enter Passsword to Save Changes</p>
        <div className='last_input'>
          <input
            autoComplete='off'
            type='password'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className='info__input info__input--red'
          />
          <div className='save__change'>
            <ModalButton
              onClick={(e) => {
                updateUser();
              }}
            >
              Save Changes
            </ModalButton>
          </div>
        </div>
      </div>
      <hr />
      <div className='education__section'>
        <h2>Education Information</h2>
        <h3>Expertise</h3>
        <Card>
          {expertises &&
            expertises.map((skill, index) => {
              return <SkillItem key={index} delete text={skill} />;
            })}
        </Card>
        <p
          className='p_add'
          onClick={() => {
            setShowExpertiseEditor(true);
          }}
        >
          + Add new expertise...
        </p>
        <h3>Achievements</h3>

        {achievements &&
          achievements.map((achievement, index) => {
            return (
              <Card key={index}>
                <div className='acd_hold'>
                  <img
                    src='/assets/cross.svg'
                    alt=''
                    onClick={() => {
                      deleteAchievement(
                        achievement.name,
                        achievement.description
                      );
                    }}
                  />
                  <p className='p_header'>{achievement.name}</p>
                  <p className='p_description'>{achievement.description}</p>
                </div>
              </Card>
            );
          })}

        <p
          onClick={() => {
            setShowAchievementEditor(true);
          }}
          className='p_add'
        >
          + Add new achievement...
        </p>
        <h3>Degree</h3>

        {degrees &&
          degrees.map((degree, index) => {
            return (
              <Card key={index}>
                <div className='acd_hold'>
                  <img
                    src='/assets/cross.svg'
                    alt=''
                    onClick={() => {
                      deleteDegree(
                        degree.school,
                        degree.degree,
                        degree.passing_year
                      );
                    }}
                  />
                  <p className='p_header'>{degree.school}</p>
                  <p className='p_description'>
                    {degree.degree + ',passing ' + degree.passing_year}
                  </p>
                </div>
              </Card>
            );
          })}

        <p
          onClick={() => {
            setShowAddDegree(true);
          }}
          className='p_add'
        >
          + Add new Degree...
        </p>
      </div>
    </div>
  );
};

export default EditProfile;
