import React, { useEffect } from 'react';
import ProfileSection from '../sections/profile/ProfileSection';
const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ProfileSection />
    </div>
  );
};

export default Profile;
