import moment from "moment";
import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AdminLayout from "../../../components/AdminLayout";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import AdminTableView from "../../../components/AdminLayout/AdminComponentView/AdminTableView";
import {
  APPROVE_JOB,
  DELETE_JOB,
  DISAPPROVE_JOB,
  GET_JOBS,
  UNDELETE_JOB,
} from "../../../lib/api/admin";
import { deleteConfirmation } from "../../../lib/sweetAlert";

export default function UnapprovedJobs() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ createdAt: "desc" });
  const [filter, setFilter] = useState({
    is_deleted: false,
    is_approved_by_admin: true,
  });
  const {
    data: jobs,
    status,
    error,
    refetch,
  } = useQuery(
    [
      "GET_JOBS",
      {
        page,
        ...(search ? { search } : {}),
        sort: JSON.stringify(sort),
        filter: JSON.stringify(filter),
      },
    ],
    GET_JOBS
  );
  const { mutateAsync: approveJob } = useMutation(APPROVE_JOB);
  const { mutateAsync: deleteJob } = useMutation(DELETE_JOB);
  const { mutateAsync: undeleteJob } = useMutation(UNDELETE_JOB);
  const { mutateAsync: disapproveJob } = useMutation(DISAPPROVE_JOB);
  const [show, setShow] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);

  console.log(jobs?.data.data);

  const handleCancelJob = useCallback(
    async (id) => {
      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/admin/close_job",
          { job_id: id },
          {
            headers: {
              "x-access-token": localStorage.getItem("auth_token"),
            },
          }
        );
        toast.success("Job cancelled successfully.");
        refetch();
      } catch (error) {
        console.error("Failed to cancel job:", error);
        toast.error("Error cancelling job.");
      }
    },
    [refetch]
  );

  const deleteAttachment = useCallback(
    async (id) => {
      try {
        await axios.delete(
          process.env.REACT_APP_BACKEND_URL + "/admin/job/delete_job_attachments?job_id=${id}",
          {
            headers: {
              "x-access-token": localStorage.getItem("auth_token"),
            },
          }
        );
        toast.success("Job cancelled successfully.");
        refetch();
      } catch (error) {
        console.error("Failed to cancel job:", error);
        toast.error("Error cancelling job.");
      }
    },
    [refetch]
  );

  const handleJobApprove = useCallback(
    async (id) => {
      try {
        const { isConfirmed } = await deleteConfirmation(
          null,
          "You can change it later."
        );
        if (!isConfirmed) return;
        await approveJob(id);
        await undeleteJob(id);
        await refetch();
        toast.success("Successfully approved job.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        await refetch();
      }
    },
    [approveJob, refetch, undeleteJob]
  );
  const handleJobReject = useCallback(
    async (id) => {
      try {
        await deleteJob(id);
        await disapproveJob(id);
        await refetch();
        toast.success("Job Rejected!");
        setShow(false);
      } catch (err) {
        console.error(err);
        await refetch();
      }
    },
    [deleteJob, disapproveJob, refetch]
  );

  const handleModal = (id) => {
    setSelectedJobId(id);
    setShow(true);
  };

  return (
    <AdminLayout>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>You wont be able to retrieve this!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => handleJobReject(selectedJobId)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <AdminTableView
        query={{ status, error }}
        title="Jobs"
        table_name="Job"
        deleteOpt={true}
        filters={[
          {
            name: "Rejected Jobs",
            value: "REJECTED",
            active: filter.state === "REJECTED",
            onClick: (value) => {
              setPage(1);
              delete filter.state;
              setFilter((values) => ({
                ...values,
                is_deleted: true,
                is_approved_by_admin: false,
              }));
            },
          },
          {
            name: "Failed Jobs",
            value: "FAILED",
            active: filter.state === "FAILED",
            onClick: (value) => {
              setPage(1);
              setFilter((values) => ({
                ...values,
                state: "FAILED",
                is_deleted: false,
                is_approved_by_admin: true,
              }));
            },
          },
          {
            name: "Bidding Jobs",
            value: "BIDDING",
            active: filter.state === "BIDDING",
            onClick: (value) => {
              setPage(1);
              setFilter((values) => ({
                ...values,
                state: "BIDDING",
                is_deleted: false,
                is_approved_by_admin: true,
              }));
            },
          },
          {
            name: "Pending Jobs",
            value: "PENDING",
            active: filter.state === "PENDING",
            onClick: (value) => {
              setPage(1);
              setFilter((values) => ({
                ...values,
                state: "PENDING",
                is_deleted: false,
                is_approved_by_admin: true,
              }));
            },
          },
          {
            name: "Completed Jobs",
            value: "COMPLETED",
            active: filter.state === "COMPLETED",
            onClick: (value) => {
              setPage(1);
              setFilter((values) => ({
                ...values,
                state: "COMPLETED",
                is_deleted: false,
                is_approved_by_admin: true,
              }));
            },
          },
        ]}
        search={{
          title: "Search Jobs",
          placeholder: "Search by title...",
          btnText: "Search Job",
          onSearch: (value) => setSearch(value),
        }}
        sort={{
          title: "Sort By",
          selects: [
            {
              name: "Sort By",
              options: [
                { label: "Title (ASC)", value: "title:asc" },
                { label: "Title (DESC)", value: "title:desc" },
                { label: "Deadline (ASC)", value: "deadline:asc" },
                { label: "Deadline (DESC)", value: "deadline:desc" },
                { label: "Print Deadline (ASC)", value: "print_deadline:asc" },
                {
                  label: "Print Deadline (DESC)",
                  value: "print_deadline:desc",
                },
                { label: "State (ASC)", value: "state:asc" },
                { label: "State (DESC)", value: "state:desc" },
                { label: "Total Bid (ASC)", value: "total_bid:asc" },
                { label: "Total Bid (DESC)", value: "total_bid:desc" },
              ],
              onSort: ({ target }) => {
                const [key, value] = target?.value?.split(":");
                setSort(() => ({ [key]: value }));
              },
            },
          ],
        }}
        table={{
          headers: [
            { name: "Title", dotsMenu: [], key: "title" },
            { name: "Description", dotsMenu: [], key: "description" },
            { name: "Deadline", dotsMenu: [], key: "deadline" },
            { name: "Posted At", dotsMenu: [], key: "createdAt" },
            { name: "Print Deadline", dotsMenu: [], key: "print_deadline" },
            { name: "State", dotsMenu: [], key: "state" },
            { name: "Total Bid", dotsMenu: [], key: "total_bid" },
            {
              name: "Actions",
              dotsMenu: [
                {
                  name: "View Job",
                  onClick: (data) => history.push(`/job-details/${data?._id}`),
                },
                ...(!filter.is_approved_by_admin
                  ? [
                      {
                        name: "Approve Job",
                        onClick: (data) => handleJobApprove(data._id),
                      },
                    ]
                  : []),
                ...(!filter.is_deleted
                  ? [
                      {
                        name: "Reject Job",
                        onClick: (data) => handleModal(data._id),
                      },
                    ]
                  : []),
                {
                  name: "Delete",
                },
                {
                  name: "Cancel Job",
                  onClick: (data) => handleCancelJob(data._id),
                },
                {
                  name: "Delete Attachment",
                  onClick: (data) => deleteAttachment(data._id),
                },
              ],
              key: "actions",
            },
          ],
          data: jobs?.data?.data.map((job) => ({
            _id: job._id,
            title: job?.title || "N/A",
            description: job?.description?.substr(0, 50) || "N/A",
            createdAt: moment(job?.createdAt).format("YYYY-MM-DD") || "N/A",
            deadline: moment(job?.deadline).format("YYYY-MM-DD") || "N/A",
            print_deadline:
              moment(job?.print_deadline).format("YYYY-MM-DD") || "N/A",
            state: job?.state || "N/A",
            total_bid: job?.total_bid || "0",
            actions: "",
          })),
          pagination: {
            total: jobs?.data?.total,
            page,
            prev: jobs?.data?.prev,
            next: jobs?.data?.next,
            setPage,
          },
        }}
      />
    </AdminLayout>
  );
}
