import React, { useEffect, useState, useContext, useRef } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import ErrorModal from "../../components/util/ErrorModal";
import { AuthContext } from "../../context/auth-context";
import Thread from "../../components/chat/Thread";
import Message from "../../components/chat/Message";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Offcanvas, Button } from "react-bootstrap";
import SideBar from "../../components/SideBar";
import "./Inbox.css";

const Inbox = ({ socket }) => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [loadedThreads, setLoadedThreads] = useState(null);
  const [filterMessage, setFilterMessage] = useState([]);
  const [messages, setMessages] = useState(null);
  const [newMessages, setNewMessages] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [lastMessage, setLastMessage] = useState(null);
  const textBox = useRef(null);
  const [fromSearch, setFromSearch] = useState();
  const messagesEndRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  const fetchThreads = async (value) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/find_all_message_threads"
      );
      setLoadedThreads(responseData);
    } catch (err) {}
  };

  const search = async (value) => {
    setSearchValue(value);
    try {
      const responseData = await sendRequest(
        `https://somadha.com/api/user_msg?filter=${value}`
      );
      setFilterMessage(responseData);
      return;
    } catch (err) {}
  };

  const fetchForFirstTime = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/find_all_message_threads"
      );
      setLoadedThreads(responseData);
      if (responseData) {
        responseData[0].member[0]._id === auth.user._id
          ? setReceiver(responseData[0].member[1])
          : setReceiver(responseData[0].member[0]);
        fetchMessages(responseData[0]._id);
      }
    } catch (err) {}
  };
  useEffect(() => {
    auth.user && fetchForFirstTime();
  }, [auth]);

  useEffect(() => {
    socket &&
      socket.on("new_message", (data) => {
        setLastMessage(data);
        console.log(data);
      });
  }, [socket]);

  useEffect(() => {
    if (receiver && lastMessage?.msg.sender_id === receiver._id) {
      let newMessage = {
        content: lastMessage?.msg.content,
        sender_id: {
          _id: lastMessage?.msg.sender_id,
        },
        timeStamp: lastMessage?.chatroom.updatedAt,
      };
      setNewMessages((prevMessages) => {
        if (prevMessages) {
          return [...prevMessages, newMessage];
        } else {
          return [newMessage];
        }
      });
    } else if (receiver) {
      fetchThreads();
    }
  }, [lastMessage, receiver]);

  const scrollToBottom = () => {
    const scroll =
      messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
    messagesEndRef.current.scrollTo(0, scroll);
  };

  useEffect(scrollToBottom, [messages, newMessages]);

  const fetchMessages = async (threadId, receiver) => {
    try {
      if (threadId) {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/find_all_messages_of_a_thread/?room_id=${threadId}`
        );
        setMessages(responseData);
      } else setMessages([]);
      setNewMessages(null);
      if (receiver) setReceiver(receiver);
    } catch (err) {}
  };

  const onSelectingThread = (thread) => {
    handleCloseOffcanvas();
    thread.member[0]._id === auth.user._id
      ? setReceiver(thread.member[1])
      : setReceiver(thread.member[0]);
    fetchMessages(thread._id);
    setNewMessages(null);
  };

  const sendMessage = async () => {
    let msgToSend = textBox.current.value;
    if (msgToSend.length > 0) {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/send_message`,
          "POST",
          JSON.stringify({
            receiver_id: receiver._id,
            content: msgToSend,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        setSearchValue("");
        fetchForFirstTime();

        let newMessage = {
          content: msgToSend,
          sender_id: {
            _id: auth.user._id,
          },
          timeStamp: new Date().toString(),
        };
        if (newMessages) {
          let joined = newMessages.concat(newMessage);
          setNewMessages(joined);
        } else setNewMessages([newMessage]);
      } catch (err) {}
    }
    textBox.current.value = "";
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="inbox__header">
        <span
          onClick={handleShowOffcanvas}
          className="open-chat-list"
          style={{ cursor: "pointer", fontWeight: "bold" }}
        >
          &#8592; Open Chat List
        </span>
      </div>

      <div className="inbox__container">
        <Offcanvas
          show={showOffcanvas}
          onHide={handleCloseOffcanvas}
          placement="end"
          className="d-md-none"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Chats</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="thread__container">
              <input
                type="text"
                placeholder="search"
                onChange={(e) => search(e.target.value)}
                value={searchValue}
                style={{
                  paddingLeft: "1rem",
                }}
              />
              <div style={{ position: "relative", height: "100%" }}>
                {searchValue !== "" && (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    {filterMessage?.map((i) => {
                      console.log(i);
                      return (
                        <div
                          className="filterMessage"
                          style={{
                            padding: ".75rem 1rem",
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(0,0,0,.1)",
                            cursor: "pointer",
                          }}
                          onClick={() => fetchMessages(i.chatroom[0]?._id, i)}
                        >
                          <img
                            src={i.profile_pic}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              borderRadius: "10000px",
                              marginRight: ".5rem",
                            }}
                            alt="pic"
                          />
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "1rem",
                              height: "100%",
                              marginBottom: "0px",
                              color: "black",
                            }}
                          >
                            {i.fullname}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div>
                  {loadedThreads && loadedThreads.length === 0 && (
                    <p className="no_contact text-secondary">No Contacts</p>
                  )}
                  {searchValue === "" &&
                    auth.user &&
                    loadedThreads?.map((thread) => {
                      let currentReceiver;
                      thread.member[0]._id === auth.user._id
                        ? (currentReceiver = thread.member[1])
                        : (currentReceiver = thread.member[0]);
                      return (
                        currentReceiver && (
                          <Thread
                            key={currentReceiver._id}
                            newMessage={!thread.seen_by.includes(auth.user._id)}
                            selected={
                              receiver && receiver._id === currentReceiver._id
                            }
                            onClick={() => {
                              onSelectingThread(thread);
                            }}
                            image={
                              currentReceiver?.profile_pic ||
                              "https://via.placeholder.com/150/92c952"
                            }
                            name={currentReceiver.fullname}
                          />
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <div className="inbox_sidebar d-none d-md-block">
          <div className="thread__container">
            <input
              type="text"
              placeholder="search"
              onChange={(e) => search(e.target.value)}
              value={searchValue}
              style={{
                paddingLeft: "1rem",
              }}
            />
            {searchValue !== "" && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                }}
              >
                {filterMessage?.map((i) => {
                  console.log(i);
                  return (
                    <div
                      className="filterMessage"
                      style={{
                        padding: ".75rem 1rem",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid rgba(0,0,0,.1)",
                        cursor: "pointer",
                      }}
                      onClick={() => fetchMessages(i.chatroom[0]?._id, i)}
                    >
                      <img
                        src={i.profile_pic}
                        style={{
                          height: "2.5rem",
                          width: "2.5rem",
                          borderRadius: "10000px",
                          marginRight: ".5rem",
                        }}
                        alt="pic"
                      />
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "1rem",
                          height: "100%",
                          marginBottom: "0px",
                        }}
                      >
                        {i.fullname}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            <div>
              {loadedThreads && loadedThreads.length === 0 && (
                <p className="no_contact text-secondary">No Contacts</p>
              )}
              {searchValue === "" &&
                auth.user &&
                loadedThreads?.map((thread) => {
                  let currentReceiver;
                  thread.member[0]._id === auth.user._id
                    ? (currentReceiver = thread.member[1])
                    : (currentReceiver = thread.member[0]);
                  return (
                    currentReceiver && (
                      <Thread
                        key={currentReceiver._id}
                        newMessage={!thread.seen_by.includes(auth.user._id)}
                        selected={
                          receiver && receiver._id === currentReceiver._id
                        }
                        onClick={() => {
                          onSelectingThread(thread);
                        }}
                        image={
                          currentReceiver?.profile_pic ||
                          "https://via.placeholder.com/150/92c952"
                        }
                        name={currentReceiver.fullname}
                      />
                    )
                  );
                })}
            </div>
          </div>
        </div>

        <div className="message__container">
          <div className="message__header">
            {receiver && (
              <div className="header__user">
                <Link to={`profile/${receiver._id}`}>
                  <div className="header__child d-flex justify-content-start align-items-center">
                    <img
                      src={
                        receiver.profile_pic ||
                        "https://via.placeholder.com/150/92c952"
                      }
                      alt=""
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "30px",
                        border: "none",
                      }}
                    />
                    <p style={{ margin: "0 5px", color: "black" }}>
                      {receiver.fullname}
                    </p>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div className="message__items" ref={messagesEndRef}>
            {messages &&
              messages.map((message) => {
                return (
                  <Message
                    key={message._id}
                    me={auth.user._id === message.sender_id._id}
                    text={message.content}
                    timestamp={message.createdAt}
                  />
                );
              })}
            {newMessages &&
              newMessages.map((message) => {
                return (
                  <Message
                    key={message.timeStamp}
                    me={auth.user._id === message.sender_id._id}
                    text={message.content}
                    timestamp={message.timeStamp}
                  />
                );
              })}
            <div />
          </div>
          {receiver && (
            <form className="message__bottom">
              <input
                className="message__box"
                type="text"
                name="message"
                placeholder="write something..."
                ref={textBox}
              />
              <button
                type="submit"
                className="send__message"
                onClick={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                SEND
              </button>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Inbox;
