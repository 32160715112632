import React, { useContext, useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import ClientDetailsDealDetails from './ClientDetailsDealDetails';
import './ClientPrintDealDetails.css';
import JobDetailsDealDetails from './JobDetailsDealDetails';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/auth-context';
import SubmitWork from './modals/Profile/SubmitWork';
import ShowFiles from './modals/Profile/ShowFiles';
import SuccessModal from './util/SuccessModal';

export default function ClientServiceDealDetails(props) {
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [files, setFiles] = useState(null);
  const history = useHistory();
  const auth = useContext(AuthContext);

  const token = localStorage.getItem('x-access-token');
  // console.log(token);

  const onCancel = () => {
    setShowSubmitModal(false);
  };
  const onSuccess = () => {
    setShowSubmit(true);
  };
  return (
    <div className='userProfile--container clientPrintDealDetails'>
      <SubmitWork
        show={showSubmitModal}
        onCancel={onCancel}
        onSuccess={onSuccess}
        dealId={props.deal_id}
        submitted={props.clientDeals?.job_submitted}
      />
      <SuccessModal
        message='File uploaded successfully.'
        show={showSubmit}
        onClear={() => {
          setShowSubmit(false);
        }}
        onSuccess={onSuccess}
      />
      <ShowFiles
        file={files}
        show={showPreview}
        clientApproval={props.clientDeals?.clientApproval}
        dealId={props.deal_id}
        onCancel={() => {
          setShowPreview(false);
        }}
        onSuccess={() => {
          setShowPreview(false);
        }}
        providerDeal={props.clientDeals?.providerDeal}
      />

      <div className='clientPrintDealDetails__top'>
        <button
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/profile/${auth.user._id}`)}
        >
          Back to profile
        </button>{' '}
        Service Deal Details
      </div>
      <ClientDetailsDealDetails
        property={props.clientDeals}
        deal_id={props.deal_id}
      />
      <div className='clientPrintDealDetails__submitWork'>
        <h3>Submit Your Work</h3>
        <div className='clientPrintDealDetails__divider' />
        <br />

        <h4>
          Submit Work{' '}
          <button
            style={{ cursor: 'pointer', padding: '6px', marginLeft: '1rem' }}
            onClick={() => {
              setShowSubmitModal(true);
            }}
          >
            Upload
          </button>
        </h4>
        <p>
          This is the final work client will be getting after he/she has
          completed his payment. Remember, you getting paid depends on the
          client paying the system.
        </p>
      </div>
      <JobDetailsDealDetails property={props.clientDeals} />
    </div>
  );
}
