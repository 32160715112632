import React, { useEffect, useState } from 'react';
import './EditExpertise.css';
import Modal from '../../util/Modal';
import moment from 'moment';
import { useHttpClient } from '../../../hooks/http-hook';
import LoadingSpinner from '../../util/LoadingSpinner';
import ModalButton from '../../button/ModalButton';

const UpdateDeadline = (props) => {
  const [deadline, setDeadline] = useState('');
  const [providerDetails, setProviderDetails] = useState('');
  const { isLoading, sendRequest } = useHttpClient();
  const [print_deadline, setPrint_deadline] = useState('');
  const formatter = 'YYYY/MM/DD';
  useEffect(() => {
    const fetchDealDetails = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/deal?deal_id=${props.deal_id}`
        );
        setProviderDetails(responseData);
      } catch (err) {}
    };
    fetchDealDetails();
    setPrint_deadline(providerDetails.deadline?.substring(0, 10));
    setDeadline(providerDetails.deadline?.substring(0, 10));
  }, [props.deal_id, sendRequest, providerDetails.deadline]);

  const sendIssue = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/client/update_deal?deal_id=${props.deal_id}`,
        'PUT',
        JSON.stringify({
          deadline: deadline,
          // deal_id: props.deal_id,
          print_deadline: print_deadline,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      //maybe check for response data
      if (responseData.success === 'yes') {
        props.onCancel();
        props.onSuccess();
        props.fetchDealDetails();
      }
    } catch (err) {}
  };
  return (
    <Modal contentOnly onCancel={props.onCancel} show={props.show}>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className='edit_exp_container big_one'>
        <img
          className='exit_edit'
          onClick={props.onCancel}
          src='/assets/cross.svg'
          alt=''
        />
        <h2>Update Deadline</h2>
        <div className='ach__inpg'>
          <input
            className='deadline__desc'
            type='date'
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
          />
        </div>
        <div className='report__issue__btn'>
          <ModalButton onClick={sendIssue}>Save</ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateDeadline;
