import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ShowExpJobs from './ShowExpJobs';
import Pagination from '../../Pagination';
import { useForm } from '../../../hooks/form-hook';
import './ExploreJob.css';
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from '../../../util/Validators';
// import JobCard from "../../components/card/JobCard";
import { useHttpClient } from '../../../hooks/http-hook';
// import ErrorModal from "../../components/util/ErrorModal";
// import LoadingSpinner from "../../components/util/LoadingSpinner";
// import SidebarOption from "../../components/filter/SidebarOption";
import { useParams, useHistory, Link } from 'react-router-dom';
// import ProviderCard from "../../components/card/ProviderCard";
import { AuthContext } from '../../../context/auth-context';
// import TinyOption from "../../components/filter/TinyOption";
import Input from '../../../components/input/Input';

function ExploreJobs(props) {
  const [ExpJobs, setExpJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { category } = useParams();
  // const [category, setCategory] = useParams();
  const [selectedSub, setSelectedSub] = useState('');
  const auth = useContext(AuthContext);
  const [cat, setCat] = useState('all');
  // const category = "all"; //for testing

  useEffect(() => {
    const fetchExpJobs = async () => {
      setLoading(true);
      const res = await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}${
            props.jobs
              ? '/user/available_jobs/'
              : '/user/get_providers_by_category/'
          }?category=${
            category === 'all' ? '' : selectedSub ? selectedSub : category
          }`
        )
        .catch(function (error) {
          // handle error
        });
      setExpJobs(res.data);
      setLoading(false);
    };

    fetchExpJobs();
  }, [props.jobs, category, selectedSub]);

  const [formState, inputHandler] = useForm(
    {
      title: {
        value: '',
        isValid: false,
      },
      description: {
        value: '',
        isValid: false,
      },
      category: {
        value: '',
        isValid: false,
      },
      // subcategory: {
      //   value: "",
      //   isValid: true,
      // },
      budget: {
        value: '',
        isValid: false,
      },
      deadline: {
        value: '',
        isValid: false,
      },
    },
    false
  );

  // useEffect(() => {
  //   const fetchExpJobs = async () => {
  //     setLoading(true);
  //     const res = await axios
  //       .get(
  //         `${process.env.REACT_APP_BACKEND_URL}${
  //           props.jobs
  //             ? "/user/available_jobs/"
  //             : "/user/get_providers_by_category/"
  //         }?category=${
  //           category === "all" ? "" : selectedSub ? selectedSub : category
  //         }`
  //       )
  //       .catch(function (error) {
  //         // handle error
  //       });
  //     setExpJobs(res.data);
  //     setLoading(false);
  //   };

  //   fetchExpJobs();
  // }, [category, props.jobs, selectedSub]);

  // const auth = useContext(AuthContext);
  const [subcategories, setSubcategories] = useState([]);

  // const [page, setPage] = useState(1);
  // const history = useHistory();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchSubCategories = async () => {
      setSelectedSub('');
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/get_subcategories/?name=${
            cat ? cat : category
          }`
        );
        setSubcategories(responseData);
      } catch (err) {}
    };
    category !== 'null' && fetchSubCategories();
    //eslint-disable-next-line
  }, [category, cat]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         `${process.env.REACT_APP_BACKEND_URL}${
  //           props.jobs
  //             ? "/user/available_jobs/"
  //             : "/user/get_providers_by_category/"
  //         }?category=${
  //           category === "all" ? "" : selectedSub ? selectedSub : category
  //         }&page=${page}`
  //       );
  //       setLoadedData(responseData);
  //     } catch (err) {
  //     }
  //   };
  //   fetchData();
  //   //eslint-disable-next-line
  // }, [page, category, props.jobs, selectedSub]);

  //for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentExpJobs = ExpJobs.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleCatChange = (e) => {
    setCat(e.target.value);
  };
  return (
    <div className='container'>
      <h3 className='text-primary mb-3'>Explore Jobs</h3>
      <div className='row'>
        <div className='col-lg-3 col-md-12 filter'>
          <select
            className='inputField'
            name='cat'
            style={{ maxHeight: '100px', overflow: 'scroll' }}
            onChange={handleCatChange}
          >
            <option value={category}>{category} </option>

            {auth.categories &&
              auth.categories.data.map((option) => {
                if (option.name !== category)
                  return <option value={option.name}>{option.name}</option>;
                else return null;
              })}
            {category !== 'all' && <option value='all'>all</option>}
          </select>

          <select
            className='inputField'
            name='subcat'
            style={{ maxHeight: '100px', overflow: 'scroll' }}
            onChange={handleCatChange}
          >
            <option value=''>Subcategory</option>
            {subcategories &&
              subcategories.length > 0 &&
              subcategories.data.map((option) => {
                return <option value={option.name}>{option.name}</option>;
              })}
          </select>
          <div className='col-lg-3 col-md-12'>
            <Link to={`/explore/${cat}`}>
              <span className='bttn-blue d-grid gap-2'>Search</span>
            </Link>
          </div>
        </div>

        <div className='col-lg-9 col-md-12'>
          <ShowExpJobs ExpJobs={currentExpJobs} loading={loading} />

          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={ExpJobs.length}
            paginate={paginate}
            pageNumber={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default ExploreJobs;
