import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import Texts from '../../util/Texts';
import Select from '../shared/Select/Select';
import Backdrop from '../util/Backdrop';
import './Navbar.css';

function Navbar({ socket }) {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  //hooks for nav drawer stuffs
  let texts = Texts();
  const [click, setClick] = useState(false);
  const [drawer, setDrawer] = useState(true);
  const [dropShadow, setDropShadow] = useState(false);
  //triggering msg and notifications
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);

  const [lan, setLan] = useState(['English', 'বাংলা']);

  //hamburger
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showDrawer = () => {
    if (window.innerWidth <= 960) {
      setDrawer(false);
    } else {
      setDrawer(true);
    }
  };

  useEffect(() => {
    showDrawer();
    auth.user && checkNewMsg();
    //eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    auth.user && checkNewNotification();
    //eslint-disable-next-line
  }, [auth.user, auth.refresh]);

  useEffect(
    () => {
      if (socket) {
        socket.on('new_message', (data) => {
          setHasNewMessage(true);
        });
        socket.on('notification', (data) => {
          setHasNewNotification(true);
        });
      }
    },
    //eslint-disable-next-line
    [auth]
  );

  window.addEventListener('resize', showDrawer);
  const changeDropShadow = () => {
    if (window.scrollY >= 72) {
      setDropShadow(true);
    } else {
      setDropShadow(false);
    }
  };
  window.addEventListener('scroll', changeDropShadow);

  const checkNewMsg = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/has_new_messages`
      );
      setHasNewMessage(responseData);
    } catch (err) {}
  };
  const checkNewNotification = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/has_new_notifications`
      );
      setHasNewNotification(responseData);
    } catch (err) {}
  };

  const languageHandler = () => {
    auth.changeLanguage();
  };

  const [selectedData, setSelectedData] = useState('');

  return (
    <React.Fragment>
      {click && <Backdrop onClick={closeMobileMenu} />}
      <nav className={`navbar ${dropShadow && 'drop-shadow'}`}>
        <div className='navbar-container'>
          {/* <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="/assets/somadha_logo.png" alt="" />
          </Link> */}

          <div className='header__left'>
            <Link to='/'>
              <div className='header__brand'>
                <img src='/assets/new/somadha_logo.svg' alt='logo' />
              </div>
            </Link>
            <div className='exp'>
              <Link className='' to='/explore/all'>
                <span style={{ marginRight: '10px' }} className='expMenu'>
                  {auth.language === 'en'
                    ? ' Explore Works'
                    : 'এক্সপ্লোর ওয়ার্কস'}
                </span>
              </Link>
              <Link className='' to='/providers/all'>
                <span style={{ marginRight: '10px' }} className='expMenu'>
                  {auth.language === 'en'
                    ? ' Find Providers'
                    : 'সরবরাহকারীদের খুঁজুন                    '}
                </span>
              </Link>
            </div>
            <div style={{ marginLeft: '0px' }}>
              <Select className='lang' width='100px' onChange={languageHandler}>
                {/* <option value='ENGLISH'>English</option>
                <option value='বাংলা'>বাংলা</option> */}
                {auth.language === 'en' ? (
                  <>
                    <option value='ENGLISH' selected>
                      English
                    </option>
                    <option value='বাংলা'>বাংলা</option>
                  </>
                ) : (
                  <>
                    {' '}
                    <option value='ENGLISH'>English</option>
                    <option value='বাংলা' selected>
                      বাংলা
                    </option>
                  </>
                )}
              </Select>
            </div>
          </div>

          <div>
            <div className='menu-icon' onClick={handleClick}>
              <img
                className='mob_menu_icon'
                src={
                  click
                    ? require('./assets/cross.svg')
                    : require('./assets/hamburger.svg')
                }
                alt=''
              />
            </div>

            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              {auth.isLoggedIn && (
                <>
                  <li className='nav-item'></li>
                  <li className='nav-item'></li>
                </>
              )}
              {/* {!auth.isLoggedIn && (
                <li className="nav-item">
                  <Link
                    to="/explore/all"
                    className="nav-links-auth"
                    onClick={closeMobileMenu}
                  >
                    <div className="nav--item--extra">
                      <img
                        className="nav-icons"
                        src={require("./assets/NavBar_Explore.svg")}
                        alt=""
                      />
                      <p>{texts.explore}</p>
                    </div>
                  </Link>
                </li>
              )} */}

              {/* {auth.isLoggedIn && (
                <li className="nav-item exp">
                  <Link
                    to="/explore/all"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    <div className="nav--item--detail">
                      <div>Explore Works</div>
                    </div>
                  </Link>
                </li>
              )} */}

              {/* {auth.isLoggedIn && (
                <li className="nav-item exp">
                  <Link
                    to="/providers/all"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    <div className="nav--item--detail">
                      <div>Find Providers</div>
                    </div>
                  </Link>
                </li>
              )} */}

              {auth.isLoggedIn && (
                <li className='nav-item'>
                  <Link
                    to='/notifications'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    <div className='nav--item--detail'>
                      <div className='logo--with--trigger'>
                        <img
                          className='nav-icons'
                          src={require('./assets/NavBar_Notifications.svg')}
                          alt=''
                        />
                        {hasNewNotification && (
                          <div className='trigger__not'></div>
                        )}
                      </div>
                      <div>{texts.notifications}</div>
                    </div>
                  </Link>
                </li>
              )}
              {!auth.isLoggedIn && (
                <li className='nav-item'>
                  <Link
                    to='/about'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    <div className='nav--item--detail'>
                      <div>About</div>
                    </div>
                  </Link>
                </li>
              )}
              {!auth.isLoggedIn && (
                <li className='nav-item'>
                  <Link
                    to='/login'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    <div className='nav--item--detail'>
                      <div>{texts.login}</div>
                    </div>
                  </Link>
                </li>
              )}

              {auth.isLoggedIn && (
                <li className='nav-item'>
                  <Link
                    to='/messages'
                    className='nav-links'
                    onClick={() => {
                      closeMobileMenu();
                      setHasNewMessage(false);
                    }}
                  >
                    <div className='nav--item--detail'>
                      <div className='logo--with--trigger'>
                        <img
                          className='nav-icons'
                          src={require('./assets/inbox.svg')}
                          alt=''
                        />

                        {hasNewMessage && <div className='trigger'></div>}
                      </div>
                      <div>{texts.messages}</div>
                    </div>
                  </Link>
                </li>
              )}

              {!auth.isLoggedIn && (
                <li className='nav-item'>
                  <Link
                    to='/sign-up'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    <div className='nav--item--detail'>
                      <div>SignUp</div>
                    </div>
                  </Link>
                </li>
              )}

              {auth.isLoggedIn && (
                <li className='nav-item'>
                  <Link
                    to='/user/profile'
                    className='nav-links '
                    onClick={closeMobileMenu}
                  >
                    <div className='nav--item--detail  '>
                      <img
                        className='nav-icons'
                        src={require('./assets/profile.svg')}
                        alt=''
                      />
                      <div>{texts.profile}</div>
                    </div>
                    <div class='dropdown-content'>
                      {auth?.user?.user_type === 'ADMIN' && (
                        <Link to='/admin'>Admin</Link>
                      )}
                      <Link to='/user/profile'>Profile</Link>
                      <Link to='/user/jobPosts'>Job Posts</Link>
                      <Link to='/user/postJob'>Post A Job</Link>
                      <Link to='/user/bids'>Bids</Link>
                      <Link to='/user/deals'>Deals</Link>
                      <Link to='/user/edit_profile'>Edit Profile</Link>
                      <Link to='/login' onClick={() => auth.logout()}>
                        Sign Out
                      </Link>
                    </div>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Navbar;
