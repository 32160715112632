import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../src/context/auth-context";
import { Route, useHistory } from "react-router-dom";
import NewNavBar from "../components/navigation/NewNavBar";
import NewFooter from "../components/footer/NewFooter";

function Logout(props) {
  const auth = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    auth.logout();
    history.push("/login");
  }, [auth]);
  return (
    <div>
      <NewNavBar />

      <NewFooter />
    </div>
  );
}

export default Logout;
