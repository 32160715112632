import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import FileUploader from "../components/util/FileUploader";
import { AuthContext } from "../context/auth-context";
import { useHttpClient } from "../hooks/http-hook";
import axios from "axios";
import { toast } from "react-toastify";
import { deleteConfirmation } from "../lib/sweetAlert";
import { useQuery } from "react-query";
import { GET_ALL_DELIVERY_PROVIDERS } from "../lib/api/admin";
import SideBar from "../components/SideBar";

const paperOptions = [
  "A0",
  "A1",
  "A2",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "letter",
  "legal",
];

const notify = (type, msg) => {
  type === "err"
    ? toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};
const EditJob = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isLoading, sendRequest } = useHttpClient();
  const { jobID } = useParams();
  const [loadedJob, setLoadedJob] = useState();
  const [deliveryProvider, setDeliveryProvider] = useState("");
  const { data: deliveryProviders } = useQuery(
    [GET_ALL_DELIVERY_PROVIDERS.name, {}],
    GET_ALL_DELIVERY_PROVIDERS
  );

  const [workTitle, setWorkTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCat, setSelectedCat] = useState("all");
  const [allCategory, setAllCategory] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [allSubCate, setAllSubCate] = useState([]);
  const [files, setFiles] = useState(null);
  const [maxBudget, setMaxBudget] = useState(0);
  const [categoryDelivery, setCategoryDelivery] = useState(false);
  const [deadline, setDeadline] = useState("");
  const [print, setPrint] = useState(false);
  const [paper, setPaper] = useState("");
  const [bw, setBw] = useState(false);
  const [double, setDouble] = useState(false);
  const [printingDeadline, setPrintingDeadline] = useState("");
  const [addr, setAddr] = useState("");

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/get_job/?job_id=${jobID}`
        );

        //console.log(responseData);
        setLoadedJob(responseData);
        setWorkTitle(responseData?.title);
        setDescription(responseData?.description);
        setSelectedCat(responseData?.category[0]);
        setMaxBudget(responseData?.max_budget);
        setDeadline(responseData?.deadline);
        setPrint(responseData?.delivery);
        setPaper(responseData?.paper);
        setDouble(responseData?.is_double_page);
        setBw(responseData?.is_black_and_white);
        setPrintingDeadline(responseData?.print_deadline);
        setAddr(responseData?.mailing_address);
        setDeliveryProvider(responseData?.delivery_provider);
      } catch (err) {
        notify("err", err);
      }
    };
    fetchJob();
    //eslint-disable-next-line
  }, []);

  const auth = useContext(AuthContext);
  let history = useHistory();

  // editing by za4

  useEffect(() => {
    getAllCategory();
  }, []);
  useEffect(() => {
    setAllSubCate([]);
    setSelectedSubCat("");
    getAllSubCate(selectedCat);
    setCategoryDelivery(
      allCategory.filter((cat) => cat.name === selectedCat)[0]?.delivery
    );
  }, [selectedCat]);

  const getAllCategory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_parent_categories`
      );
      setAllCategory(response.data.data);
    } catch (error) {
      notify("err", error);
    }
  };

  const getAllSubCate = async (selectedcate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_subcategories?name=${selectedcate}`
      );
      if (response.status === 200) {
        setAllSubCate(response.data);
      } else {
        throw new Error("Something Went Wrong!");
      }
    } catch (error) {
      notify("err", error);
    }
  };

  const handleSubmit = async () => {
    const dataToSend = {
      title: workTitle,
      description: description,
      category: [`${selectedSubCat.length > 0 ? selectedSubCat : selectedCat}`],
      max_budget: maxBudget,
      delivery: print,
      paper: paper,
      is_black_and_white: bw,
      is_double_page: double,
      mailing_address: addr,
    };

    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          `/client/update_job?job_id=${jobID}`,
        dataToSend,
        {
          headers: {
            "x-access-token": localStorage.getItem("auth_token"),
          },
        }
      );

      if (responseData.status === 200) {
        notify("success", "Job Updated Successfully!");

        history.push("/user/jobPosts");
        setWorkTitle("");
        setDescription("");
        setSelectedCat("all");
        // setAllCategory([]);
        setSelectedSubCat("");
        // setAllSubCate([]);
        setFiles(null);
        setMaxBudget(0);
        setPrint(false);
        setPaper("");
        setAddr("");
        setBw(false);
        setDouble(false);
        setPrintingDeadline("");
        setDeliveryProvider("");
      } else throw new Error(responseData?.data?.msg);
    } catch (error) {
      notify("err", error);
    }
  };

  const handleDelete = async () => {
    const value = await deleteConfirmation();
    if (value?.isConfirmed) {
      try {
        const res = await axios.delete(
          process.env.REACT_APP_BACKEND_URL +
            `/client/delete_job?job_id=${jobID}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("auth_token"),
            },
          }
        );
        if (res.status === 200) {
          notify("success", "Job Deleted Successfully!");
          history.push("/user/jobPosts");
        } else throw new Error(res?.data?.msg);
      } catch (error) {
        notify("error", error?.response?.data?.msg);
      }
    }
  };

  return (
    <div>
      <div className="editjob">
        <div className="editjob_sidebar">
          <SideBar />
        </div>
        <div className="editjob_content">
          <h2>
            {auth.language === "en"
              ? "Edit Job Post"
              : "কাজের পোস্ট সম্পাদনা করুন"}
          </h2>
          <div className="my-3">
            <div className="form-section">
              <label htmlFor="fname">
                {" "}
                {auth.language === "en" ? "Work Title" : "কাজের শিরোনাম"}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                autoComplete="off"
                type="text"
                name="workTitle"
                placeholder={
                  auth.language === "en"
                    ? "Be concise and short. eg: Logo for a bakery company."
                    : "সংক্ষিপ্ত. যেমন: একটি বেকারি কোম্পানির লোগো।"
                }
                value={workTitle}
                onChange={(e) => {
                  setWorkTitle(e.target.value);
                }}
              />
            </div>
            <div className="form-section">
              <label for="fdesc">
                {" "}
                {auth.language === "en"
                  ? "Detailed Description"
                  : "বিস্তারিত বিবরণ"}{" "}
              </label>
              <textarea
                placeholder={
                  auth.language === "en"
                    ? "Explain your task in details, atleast 50 words."
                    : "আপনার কাজটি বিশদভাবে ব্যাখ্যা করুন, কমপক্ষে 100 শব্দ।"
                }
                rows="5"
                className="textareaa"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="row">
              <div className="col">
                <label for="">
                  {auth.language === "en"
                    ? "Select Category"
                    : "বিভাগ নির্বাচন করুন"}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="selectt"
                  className="form-select"
                  aria-label="Default select example"
                  style={{ cursor: "pointer" }}
                  value={selectedCat}
                  onChange={(e) => setSelectedCat(e.target.value)}
                >
                  {" "}
                  <option>{auth.language === "en" ? "all" : "সব"} </option>
                  {allCategory?.map((c, idx) => (
                    <option value={c.name} key={idx}>
                      {auth.language === "en" ? c.name : c?.name_bn}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                {" "}
                {/* {selectedCat !== 'all' && selectedCat !== '' && ( */}
                <div>
                  <label for="">
                    {auth.language === "en"
                      ? "Select Sub-Category"
                      : "সাব-ক্যাটাগরি নির্বাচন করুন "}
                  </label>
                  <select
                    id="selectt"
                    value={selectedSubCat}
                    className="form-select"
                    aria-label="Default select example"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => setSelectedSubCat(e.target.value)}
                  >
                    <option value="" selected>
                      {auth.language === "en" ? "Not selected" : "অনির্বাচিত"}
                    </option>
                    {Array.isArray(allSubCate?.data) &&
                      allSubCate?.data?.map((dt, idx) => (
                        <option key={idx} value={dt.name}>
                          {auth.language === "en" ? dt.name : dt?.name_bn}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-section">
                <label htmlFor="fname">
                  {" "}
                  {auth.language === "en"
                    ? "Maximum Budget"
                    : "সর্বোচ্চ বাজেট"}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  autoComplete="off"
                  type="number"
                  name="workTitle"
                  placeholder="in BDT"
                  value={maxBudget}
                  min={0}
                  onChange={(e) => {
                    setMaxBudget(e.target.value);
                  }}
                />
              </div>
              <div className="form-section">
                <label for="bdate">
                  {" "}
                  {auth.language === "en" ? "Deadline" : "সময়সীমা"}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  autoComplete="off"
                  type="date"
                  value={deadline ? deadline.slice(0, 10) : ""}
                  onChange={(e) => {
                    setDeadline(e.target.value);
                  }}
                  name="bdate"
                />
              </div>
            </div>
            {categoryDelivery && (
              <div className="d-flex justify-content-start align-items-center my-5">
                <input
                  checked={print ? true : false}
                  autoComplete="off"
                  type="checkbox"
                  onChange={() => {
                    setPrint(!print);
                  }}
                  name="bdate"
                  style={{ width: "25px", height: "25px", marginRight: "10px" }}
                />{" "}
                <p style={{ marginBottom: "0px" }}>
                  {" "}
                  {auth.language === "en"
                    ? "Need Delivery?"
                    : "ডেলিভারি প্রয়োজন?"}
                </p>
              </div>
            )}

            {print && (
              <div>
                <div className="form-section">
                  <label for="fdesc">
                    {" "}
                    {auth.language === "en"
                      ? "Mailing Address"
                      : "চিঠি পাঠানোর ঠিকানা"}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    placeholder={
                      auth.language === "en"
                        ? "House, Road, Post Office, City, Town, District..."
                        : "বাড়ি, রাস্তা, ডাকঘর, শহর, শহর, জেলা.."
                    }
                    rows="5"
                    className="textareaa"
                    value={addr}
                    onChange={(e) => {
                      setAddr(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            )}
            <button className="btn prime-btn" onClick={() => handleSubmit()}>
              {auth.language === "en" ? "Update Job " : "আপডেট করুন"}
            </button>
            <button
              className="btn btn-danger ms-3"
              onClick={() => handleDelete()}
            >
              {auth.language === "en" ? "Delete Job " : "ডিলিট করুন"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJob;
