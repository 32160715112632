import React from 'react';
import './JobCard.css';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../context/auth-context';
import ApplyBid from '../../../components/modals/bid/ApplyBid';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from '../../../hooks/form-hook';
import { useHttpClient } from '../../../hooks/http-hook';
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
} from '../../../util/Validators';

import InputBid from '../../../components/modals/bid/InputBid';

function JobCard({ job }) {
  const [modal, setModal] = useState(false);
  const [showBidSuccess, setShowBidSuccess] = useState(false);
  const [bidOption, setBidOption] = useState('');
  const { isLoading, sendRequest } = useHttpClient();
  const [response, setResponse] = useState();
  const auth = useContext(AuthContext);
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const [applyBid, setApplyBid] = useState(false);
  const handleApplyBidClose = () => setApplyBid(false);
  const handlApplyBidShow = () => setApplyBid(true);

  const [sModal, setSModal] = useState(false);
  const [eModal, setEModal] = useState(false);
  // const handleSModalClose = () => setSModal(false);
  // const handlSModalShow = () => setSModal(true);

  const [formState, inputHandler] = useForm(
    {
      price: {
        value: '',
        isValid: false,
      },
      comment: {
        value: '',
        isValid: true,
      },
    },
    false
  );
  const sendBidRequest = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/provider/create_bid',
        'POST',
        JSON.stringify({
          job_id: job._id,
          claimed_budget: formState.inputs.price.value,
          comment: formState.inputs.comment.value,
          bid_type:
            bidOption === 'service'
              ? 'NORMAL'
              : bidOption === 'printDelivery'
              ? 'PRINT'
              : 'NORMAL',
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      if (responseData.success === 'yes') {
        job.onCancel();
        job.onSuccess();
        handleApplyBidClose();
        setSModal(true);
      }
    } catch (err) {
      setResponse(err.message);

      // setEModal(true);
    }
  };
  const editBid = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/provider/update_bid/?bid_id=${job.id}`,
        'POST',
        JSON.stringify({
          //bid_id: props.id,
          claimed_budget: formState.inputs.price.value,
          comment: formState.inputs.comment.value,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
      if (responseData.success === 'yes') {
        job.onCancel();
        job.onSuccess();
      }
    } catch (err) {
      setResponse(err.message);
    }
  };
  return (
    <>
      {/* Success Modal */}
      <Modal
        size='sm'
        show={sModal}
        onHide={() => setSModal(false)}
        aria-labelledby='example-modal-sizes-title-sm'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-sm'>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You have successfully placed bid.</Modal.Body>
      </Modal>
      {/* Error Modal */}
      <Modal
        size='sm'
        show={eModal}
        onHide={() => setEModal(false)}
        aria-labelledby='example-modal-sizes-title-sm'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-sm'>Error!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sorry! Bid unsuccessful.</Modal.Body>
      </Modal>

      {/* apply bid modal */}
      <Modal
        show={applyBid}
        onHide={handleApplyBidClose}
        backdrop='static'
        dialogClassName='modal-50w'
        style={{ top: '0vh' }}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ color: '#2A41E8' }}>Apply for Bid</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <p style={{ color: 'green' }}>
              Budget for Service + Print + Delivery: {job.max_budget}
            </p>
            {job.print && (
              <div>
                <select
                  name=''
                  id='selectt'
                  value={bidOption}
                  onChange={(e) => setBidOption(e.target.value)}
                >
                  <option>Select an Option </option>
                  <option value='service'>Service </option>
                  <option value='printDelivery'>Print+ Delivery </option>
                </select>
              </div>
            )}

            <InputBid
              grey
              element='input'
              id='price'
              type='text'
              label='Claimed Price'
              errorText='Please enter a valid price'
              onInput={inputHandler}
              initialValue={job.claimedPrice}
              initialValid={true}
              validators={[VALIDATOR_REQUIRE()]}
            />
            <p style={{ fontSize: '.8rem' }}>
              You will be charged an amount of system charge on this claimed
              price if deal gets created
            </p>
            <InputBid
              large
              grey
              onInput={inputHandler}
              element='textarea'
              id='comment'
              type='text'
              className='textArea'
              errorText='Please enter comment not exceeding 300 characters'
              label='Comment to Client'
              placeholder='Optional Message to Client'
              initialValid={true}
              validators={[VALIDATOR_MAXLENGTH(300)]}
              initialValue={job.comment}
            />
            <div className='' style={{ marginTop: '15px' }}>
              {!job.edit && (
                <Button
                  onClick={sendBidRequest}
                  buttonStyle='btn--apply'
                  isDisabled={!formState.isValid ? 'true' : ''}
                  type='submit'
                >
                  Confirm Bid
                </Button>
              )}
              {job.edit && (
                <Button
                  onClick={editBid}
                  buttonStyle='btn--apply'
                  isDisabled={!formState.isValid ? 'true' : ''}
                  type='submit'
                >
                  Update
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <div className='jcard'>
        <p>
          <strong>{job.title}</strong>
        </p>
        <div className='row '>
          <div className='col-md-9 col-sm-12'>
            <div className='d-flex flex-row'>
              <div style={{ marginRight: '15px' }}>
                <p>
                  <i className='fab fa-gg-circle'></i>{' '}
                  {(job.parent_category.length > 0
                    ? job.parent_category + ' - '
                    : '') + job.category[0]}
                </p>
              </div>
              <div style={{ marginRight: '15px' }}>
                <p>
                  <i className='far fa-clock'></i> Posted On{' '}
                  {' ' +
                    new Date(job.createdAt).toLocaleDateString(
                      undefined,
                      dateOptions
                    )}
                </p>
              </div>
              <div>
                <p>
                  <i className='fas fa-map-marker-alt'></i> Location{' '}
                </p>
              </div>
            </div>
            <div style={{ textAlign: 'justify' }}>{job.description}</div>
          </div>
          <div className='col-md-3 col-sm-3 '>
            <strong>Budget</strong>
            <p>BDT {job.max_budget}</p>
            <div className='d-grid gap-2'>
              {/* <button className="btn btn-primary" type="button">
              Apply for Bid
            </button> */}
              {auth.isLoggedIn && !auth.isAdmin && !auth.isManager && (
                <button
                  onClick={handlApplyBidShow}
                  buttonStyle='btn--apply'
                  className='bttn-blue'
                >
                  Apply for Bid
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobCard;
