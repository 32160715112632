import React, { useState } from "react";
import { ChromePicker } from "react-color";
import Backdrop from "./Backdrop";
import "./ColorPicker.css";
const ColorPicker = ({ name, colorState, setColorState }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [pickerValue, setPickerValue] = useState(colorState[name]);
  const handleColorChange = (color) => {
    let changeState = colorState;
    changeState[name] = color.hex;
    setColorState(changeState);
  };

  return (
    <div className="picker__container">
      <div className="picker__text">{name}</div>
      <div
        onClick={() => {
          setShowPicker(!showPicker);
        }}
        style={{ backgroundColor: colorState[name] }}
        className="picker__color"
      ></div>
      <div>
        {showPicker && (
          <React.Fragment>
            <Backdrop
              onClick={() => {
                setShowPicker(false);
              }}
            />
            <div className="color__picker">
              <ChromePicker
                color={pickerValue}
                onChange={(updatedColor) => {
                  setPickerValue(updatedColor.hex);
                  handleColorChange(updatedColor);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
