import React, { useState } from "react";
import Button from "../../shared/Button/Button";
import Pagination from "../../shared/Pagination/Pagination";
import QComponent from "../../shared/QComponent/QComponent";
import Select from "../../shared/Select/Select";
import ThreeDotDropdown from "../../shared/ThreeDotDropdown/ThreeDotDropdown";
import "./AdminTableView.css";
import axios from "axios";
import { toast } from "react-toastify";
import CsvDownloadButton from "react-json-to-csv";

export default function AdminTableView({
  title,
  filters,
  search,
  sort,
  table,
  query,
  table_name,
  userList,
  deleteOpt,
}) {
  const [fromDate, setFromDate] = useState(); // Use a function to set default value
  const [toDate, setToDate] = useState(); // Use a function to set default value

  const handleDelete = async (id) => {
    const fromDateStr = fromDate
      ? new Date(fromDate).toISOString().split("T")[0]
      : undefined;
    const toDateStr = toDate
      ? new Date(toDate).toISOString().split("T")[0]
      : undefined;

    try {
      if (table_name === "Job") {
        axios
          .delete(
            process.env.REACT_APP_BACKEND_URL +
              `/admin/delete_job_permanently?job_id=${id}`,
            {
              headers: {
                "x-access-token": localStorage.getItem("auth_token"),
              },
            }
          )
          .then((response) => {
            // Show success toast msg
            toast.success("Delete Successful");
            window.location.reload();
          })
          .catch((err) => {
            toast.error(err?.response?.data?.msg || err?.message);
          });
      } else {
        axios
          .post(
            process.env.REACT_APP_BACKEND_URL + "/admin/delete-old-data",
            {
              table_name: table_name,
              from: fromDateStr,
              till: toDateStr,
              id: id,
            },
            {
              headers: {
                "x-access-token": localStorage.getItem("auth_token"),
              },
            }
          )
          .then((response) => {
            // Show success toast msg
            toast.success(response.data.data);
            // window.location.reload();
          })
          .catch((err) => {
            toast.error(err?.response?.data?.msg || err?.message);
          });
      }
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  return (
    <div className="adminTableView">
      <h3>{title}</h3>
      {search?.title && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // eslint-disable-next-line no-unused-expressions
            search?.onSearch?.(e.target.search.value);
          }}
          className="adminTableView__search"
        >
          <label>{search.title}</label>
          <div>
            <input
              type="text"
              name="search"
              className="form-control"
              placeholder={search.placeholder || "Search..."}
            />
            <Button variant="primary" type="submit">
              {search?.btnText}
            </Button>
          </div>
        </form>
      )}

      <div className="d-flex gap-6">
        {!!filters?.length && (
          <div className="adminTableView__sort">
            <label>Filter By</label>
            <div>
              <select
                style={{
                  width: "200px",
                  height: "40px",
                  padding: "0px",
                  border: "1px solid rgba(0,0,0,.2)",
                  borderRadius: "5px",
                  marginRight: "20px",
                  boxShadow: "none",
                }}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  filters.forEach((item) => {
                    if (item.value === selectedValue && item.onClick) {
                      item.onClick(item.value);
                    }
                  });
                }}
              >
                <option selected disabled>
                  All {title}
                </option>
                {filters.map((item, ind) => (
                  <option
                    key={ind}
                    value={item.value}
                    className={
                      item.variant
                        ? item.variant
                        : item?.active
                        ? "primary_outlined"
                        : "ghost_white"
                    }
                  >
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {sort?.title && (
          <div className="adminTableView__sort" style={{ marginRight: "20px" }}>
            <label>{sort.title}</label>
            <div>
              {sort?.selects.map((item, ind) => (
                <Select
                  width={sort.width || "200px"}
                  variant="ghost_white"
                  key={ind}
                  onChange={item?.onSort}
                >
                  {item.options.map((opt, index) => (
                    <option key={index} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              ))}
            </div>
          </div>
        )}
        {userList && (
          <div className="adminTableView__sort" style={{ marginTop: "25px" }}>
            <label></label>
            <div>
              <CsvDownloadButton
                data={userList}
                filename="somadhaUsers.csv"
                className="btn btn--primary"
                delimiter=","
                style={{ marginLeft: "20px" }}
              >
                Download CSV
              </CsvDownloadButton>
            </div>
          </div>
        )}

        {deleteOpt && table_name !== "Job" && (
          <div className="adminTableView__datePickerRow d-flex gap-6 mt-3">
            <div style={{ margin: "0 20px 0 0" }}>
              <label style={{ marginRight: "1rem" }}>Delete From</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                style={{
                  marginRight: "1rem",
                  padding: "0 4px",
                  height: "40px",
                }}
              />
            </div>
            <div>
              <label style={{ marginRight: "1rem" }}>Delete To</label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                style={{ marginRight: "1rem", height: "40px" }}
              />
            </div>
            <Button
              variant="danger"
              style={{
                height: "40px",
                width: "120px",
                color: "white",
                borderRadius: "5px",
                margin: "36px 20px",
                backgroundColor: "#DE0D3A",
              }}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </div>
        )}
      </div>

      <QComponent status={query?.status} error={query?.error}>
        {!!table?.headers?.length && (
          <div className="adminTableView__tableWrapper">
            <table className="adminTableView__table">
              <thead>
                <tr>
                  {table?.headers?.map((header) => (
                    <th key={header.key}>{header.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table.data?.map((row, rowId) => (
                  <tr
                    key={rowId}
                    style={{
                      backgroundColor: rowId % 2 === 0 ? "white " : "#eeeeee",
                    }}
                  >
                    {Object.keys(row).map(
                      (_, entryId) =>
                        (row[table.headers[entryId]?.key] ||
                          table.headers[entryId]?.key === "actions") && (
                          <td key={entryId}>
                            <div className="d-flex align-items-center justify-content-between">
                              {row[table.headers[entryId]?.key]}{" "}
                              {!!table.headers[entryId]?.dotsMenu?.length && (
                                <ThreeDotDropdown
                                  className="ml-2"
                                  options={table.headers[entryId].dotsMenu}
                                  additionalData={row}
                                  handleDelete={handleDelete}
                                  deleteOpt={deleteOpt}
                                />
                              )}
                            </div>
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              className={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                {table.pagination !== undefined && (
                  <Pagination {...table.pagination} />
                )}
              </div>
            </div>
          </div>
        )}
      </QComponent>
    </div>
  );
}
