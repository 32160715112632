import React from "react";
import Modal from "./Modal";
import ModalButton from "../button/ModalButton";

const ErrorModal = (props) => {
  return (
    <Modal contentOnly onCancel={props.onClear} show={!!props.error}>
      <div className="error__modal">
        <img onClick={props.onClear} src="/assets/cross.svg" alt="" />
        <h2 className="error__header">An Error Occured!</h2>
        <div className="error__content">{props.error}</div>
        <div className="okay__btn">
          <ModalButton buttonStyle="btn--outline" onClick={props.onClear}>
            Okay
          </ModalButton>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
